import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {BasicBar} from 'components/Navigation/TopBar/BasicBar'
import mapIndexed from 'helpers/mapIndexed'
import {prop} from 'ramda'
import Button from 'components/Navigation/components/Button'
import {ChevronLeft as BackIcon} from 'react-feather'
import Perms from '../../Perms/Perms'

const Wrap = styled(BasicBar)`
  background-image: linear-gradient(rgba(0, 0, 0, 0.35),rgba(0, 0, 0, 0.35)), ${({cover}) => (cover && `url(${cover})`)};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: background-image .3s ease-in-out 2s;
`
const TopBar = styled('div')`
  height: 60px;
  padding: 0 15px 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`
const BottomBar = styled('div')`
  height: ${({open, secondHeight}) => open ? `${secondHeight}px` : '0'};
  transition: ${({theme}) => theme.transition.primary}, opacity .2s ease-in-out;
  padding: ${({open}) => open ? '0 15px 10px' : '0 15px'};
  overflow: hidden;
  color: ${({theme, cover}) => cover ? theme.palette.white : theme.palette.black};
  opacity: ${({open}) => open ? 1 : 0};
  div{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-end;
    height: 100%;
    color: inherit !important;
    h5{
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: -0.016em;
    }
    h6{
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const LeftBar = styled('button')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  svg:first-child{
    margin-left: -5px;
    color: ${({theme, cover}) => cover ? theme.palette.white : theme.palette.black};
  }
`
const RightBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 10px;
  }
`
const MiddleBar = styled('h3')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 220px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: ${({theme, cover}) => cover ? theme.palette.white : theme.palette.black};
  opacity: ${({open}) => open ? 0 : 1};
  transition: opacity .3s ease-out;
`

const MainBar = (props) => {
  const {
    title,
    subTitle,
    goBack,
    buttons,
    secondHeight,
    cover
  } = props
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const listener = (ev) => {
      if (window.scrollY >= 100) {
        return setOpen(false)
      }
      return setOpen(true)
    }
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [])

  return (
    <Wrap
      cover={cover}
    >
      <TopBar>
        <LeftBar
          type={'button'}
          onClick={goBack}
          cover={cover}
        >
          <BackIcon size={24} />
        </LeftBar>
        <MiddleBar
          open={secondHeight && open}
          cover={cover}
        >
          {title}
        </MiddleBar>
        <RightBar>
          {buttons && mapIndexed((item, index) => {
            const icon = prop('icon', item)
            const onClick = prop('onClick', item)
            const style = prop('style', item)
            const perms = prop('perms', item)
            const withAlert = prop('withAlert', item)
            const i =
              <Button
                key={index}
                title={icon}
                style={style}
                blur={cover}
              />
            if (perms) {
              return (
                <Perms key={index} perms={perms} withAlert={withAlert} onClick={onClick}>
                  {i}
                </Perms>
              )
            }
            return i
          }, buttons)}
        </RightBar>
      </TopBar>
      <BottomBar
        open={secondHeight ? open : false}
        secondHeight={secondHeight}
        cover={cover}
      >
        <div>
          <h5>{subTitle}</h5>
          <h6>{title}</h6>
        </div>
      </BottomBar>
    </Wrap>
  )
}

MainBar.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  cover: PropTypes.string,
  buttons: PropTypes.array,
  secondHeight: PropTypes.number
}

export default MainBar
