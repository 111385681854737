import React, {useEffect} from 'react'
import Loader from 'components/Loader'
import styled from 'styled-components'
import hexToRgba from 'helpers/hexToRgba'
import {ChevronLeft as Left} from 'react-feather'
import PropTypes from 'prop-types'
import ClipboardIcon from 'components/SvgIcons/CustomIcons/Clipboard'
import {imageViewerAction} from 'containers/Clients/actions/marketsAction'
import {connect, useDispatch} from 'react-redux'
import {path, prop} from 'ramda'
import {compose} from 'redux'
import PinchToZoom from 'react-pinch-and-zoom'
import {useHistory} from 'react-router'
import {parseParams} from 'helpers/url'

const Wrap = styled('div')`
  display: ${props => props.open ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`
const LoaderWrap = styled('div')`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1000;
`
const ImgWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  img{
    width: 100%;
    max-height: 80vh;
  }
`
const NoResultsWrap = styled('div')`
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  z-index: 1000;
`
const Img = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(250, 250, 250, 0.1);
  border-radius: 8px;
  min-width: 44px;
  min-height: 44px;
  margin-bottom: 7px;
  padding: 14px;
`
const Text = styled('p')`
  max-width: 170px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0241176em;
  color: ${({theme}) => theme.palette.white};
`
const BGMusk = styled('div')`
  background-color: ${hexToRgba('#000000', '0.9')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({open}) => open ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({open}) => open ? 'visible' : 'hidden'};
  z-index: 900;
`
const CloseButton = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1000;
`

const enhance = compose(
  connect((state) => {
    const marketsItemPhoto = prop('marketsItemPhoto', state)
    return {
      marketsItemPhoto
    }
  })
)

const ImageViewer = (props) => {
  const {
    imgSrc,
    loading,
    open,
    setOpen,
    marketsItemPhoto
  } = props

  // Data
  const marketsItemPhotoData = prop('data', marketsItemPhoto)
  const marketsItemPhotoLoading = prop('loading', marketsItemPhoto)
  const imageFile = prop('file', marketsItemPhotoData)

  // Redirects
  const dispatch = useDispatch()
  const history = useHistory()
  const search = path(['location', 'search'], history)
  const searchObj = parseParams(search)
  const imageViewerQuery = prop('imageViewer', searchObj)

  // useEffect
  useEffect(() => {
    if (imageViewerQuery) {
      dispatch(imageViewerAction(searchObj))
    }
  // eslint-disable-next-line
  }, [imageViewerQuery])

  // CloseButton
  const closeButton =
    <CloseButton onClick={() => setOpen(false)}>
      <Left size={22} color={'#fff'} />
      <span>Назад</span>
    </CloseButton>

  // Main image
  const image =
    <ImgWrap>
      <PinchToZoom>
        <img src={imgSrc || imageFile} alt={'Ритм.Доставка'} />
      </PinchToZoom>
    </ImgWrap>

  // Loader
  const loader =
    <LoaderWrap onClick={() => setOpen(false)}>
      <Loader />
    </LoaderWrap>

  // No results
  const noResults =
    <NoResultsWrap>
      <Img>
        <ClipboardIcon
          color={'#fff'}
        />
      </Img>
      <Text>
        Ошибка при загрузке
      </Text>
    </NoResultsWrap>

  // Render
  return (
    <React.Fragment>
      <Wrap open={open}>
        {
          (loading || marketsItemPhotoLoading)
            ? loader
            : (imgSrc || imageFile)
              ? image
              : noResults
        }
        {closeButton}
        <BGMusk open={open} onClick={() => setOpen(false)} />
      </Wrap>
    </React.Fragment>
  )
}

ImageViewer.propTypes = {
  open: PropTypes.bool,
  imgSrc: PropTypes.string,
  loading: PropTypes.bool,
  setOpen: PropTypes.func
}

export default enhance(ImageViewer)
