import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled('span')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  width: 23vw;
`
const Icon = styled('span')`
  width: 40px;
  height: 40px;
  background: ${({theme}) => theme.palette.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-bottom: 5px;
  padding: 0 10px;
  svg{
    max-width: 20px;
    max-height: 20px;
    color: ${({theme}) => theme.palette.white};
  }
`
const Text = styled('span')`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-top: 5px;
  color: ${({theme}) => theme.palette.blue};
  letter-spacing: -0.41px;
  text-align: center;
`

const ActionButton = props => {
  const {
    icon,
    text,
    onClick
  } = props

  return (
    <Button
      onClick={onClick}
    >
      <Icon>
        {icon}
      </Icon>
      <Text>
        {text}
      </Text>
    </Button>
  )
}

ActionButton.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string
}

export default ActionButton
