import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {BasicBar} from 'components/Navigation/TopBar/BasicBar'
import {X as ExitIcon, ChevronLeft as BackIcon} from 'react-feather'

const Wrap = styled(BasicBar)`
  height: 60px;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`
const LeftBar = styled('button')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: ${({theme}) => theme.palette.black};
  svg:first-child{
    color: ${({theme}) => theme.palette.black};
  }
`
const RightBar = styled('button')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: ${({theme}) => theme.palette.black};
  margin-left: auto;
  svg:first-child{
    color: ${({theme}) => theme.palette.black};
  }
`
const MiddleBar = styled('h3')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 150px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`

const DetailBar = (props) => {
  const {
    goBack,
    goExit,
    title
  } = props

  return (
    <Wrap>
      {goBack &&
      <LeftBar
        type={'button'}
        onClick={goBack}
      >
        <BackIcon size={24} />
      </LeftBar>}
      <MiddleBar>
        {title}
      </MiddleBar>
      <RightBar
        type={'button'}
        onClick={goExit}
      >
        <ExitIcon size={24} />
      </RightBar>
    </Wrap>
  )
}

DetailBar.propTypes = {
  goBack: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  goExit: PropTypes.func,
  title: PropTypes.string
}

export default DetailBar
