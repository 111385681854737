import {path, prop} from 'ramda'
import moment from 'moment'
import toNumber from '../../../../helpers/toNumber'
import numberWithoutSpaces from '../../../../helpers/numberWithoutSpaces'

const firstDayOfMonth = moment().format('YYYY-MM-01')
const lastDay = moment().daysInMonth()
const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)

export const financesCashierListSerializer = (params) => {
  const search = prop('search', params)
  return {
    search: search
  }
}

export const financesCashierTransactionsListSerializer = ({transId, ...params}, props) => {
  const matchParams = path(['match', 'params'], props)
  const cashboxParam = prop('id', matchParams)
  const defaultParams = {
    ...params,
    cashbox: cashboxParam
  }
  return {...defaultParams}
}

// Finances Accept-cash
export const financesFinancesAcceptCashSerializer = (params) => {
  const search = prop('search', params)
  return {
    search: search
  }
}

// Finances Accept-cash Client-Transactions
export const AcceptCashClientTransactionsSerializer = (params) => {
  const search = prop('search', params)
  const transaction = prop('transaction', params)
  const type = prop('type', params)
  const user = prop('user', params)
  const reloadUI = prop('reloadUI', params)
  const realCurrency = prop('real_currency', params)
  const division = prop('division', params)
  return {
    reloadUI,
    search: search,
    transaction,
    type,
    user,
    real_currency: realCurrency,
    division
  }
}

export const AcceptCashClientTransactionsCreateSerializer = ({query, values}) => {
  const date = prop('date', values)
  const time = prop('time', values)
  const newTime = time ? time + ':00' : moment(time).format('HH:mm:ss')
  const clientTransaction = prop('clientTransaction', values) || null
  if (clientTransaction) {
    return {
      currency: prop('transactionCurrency', query),
      agent: prop('user', query),
      division: prop('transactionDivision', query),
      amount: prop('transactionSum', query),
      cashbox: prop('cashbox', values),
      client_transaction: clientTransaction,
      date: date ? moment(date).format('YYYY-MM-DD') + ' ' + newTime : null
    }
  }
  return {
    currency: prop('real_currency', query),
    agent: prop('user', query),
    division: prop('division', query),
    amount: prop('sum', query),
    cashbox: prop('cashbox', values),
    client_transaction: clientTransaction,
    date: date ? moment(date).format('YYYY-MM-DD') + ' ' + newTime : null
  }
}

export const AcceptCashClientTransactionsUpdateSerializer = ({clientId, ...values}) => {
  return {
    rate_type: 2, // По умолчанию кастомная валюта
    amount: prop('transactionCurrency', values),
    client: clientId,
    comment: prop('comment', values),
    currency: prop('currency', values),
    custom_rate: prop('customRate', values),
    division: prop('division', values),
    payment_type: prop('paymentType', values),
    user: prop('user', values)
  }
}

export const transferCashCreateSerializer = (values, withPersent, amountTo) => {
  const amountFrom = toNumber(numberWithoutSpaces(prop('amountFrom', values)))
  const percentage = toNumber(numberWithoutSpaces(prop('amountTo', values)))
  const fromDate = prop('fromDate', values)
  const toDate = prop('toDate', values)
  const rate = prop('rate', values)
  const amountFromPersent = toNumber(numberWithoutSpaces(prop('amountFromPersent', values)))
  const toCashbox = prop('toCashbox', values)
  const fromDivision = prop('fromDivision', values)
  const toDivision = prop('toDivision', values)
  const comment = prop('comment', values)
  const fromCashbox = prop('fromCashbox', values)
  const date = moment(prop('date', values)).format('YYYY-MM-DD')
  return {
    accumulated_money_begin_date: fromDate || firstDayOfMonth,
    accumulated_money_end_date: toDate || lastDayOfMonth,
    amount_from: withPersent ? amountFromPersent : amountFrom,
    amount_to: amountTo,
    from_cashbox: fromCashbox,
    to_cashbox: toCashbox,
    from_division: fromDivision,
    to_division: toDivision,
    percentage: withPersent ? percentage : 0,
    date,
    comment,
    rate: rate
  }
}

export const convertSerializer = (params) => {
  const defaultParams = {
    amount: '1',
    date: moment().format('YYYY-MM-DD HH:mm'),
    from_currency: 4,
    to_currency: 5
  }
  return {
    ...defaultParams
  }
}
