import React, {useState} from 'react'
import {PhotoBar} from 'components/Navigation/TopBar'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import Container from 'components/Container/Container'
import Button from 'components/Forms/Button'
import styled from 'styled-components'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import {path, prop} from 'ramda'
import ModalConfirm from 'components/Modal/ModalConfirm'
import {TextStatus} from 'components/Status/TextStatus'
import {isAndroid} from 'constants/constants'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  min-height: ${({secondHeight}) => secondHeight && `calc(100vh - ${secondHeight + 60}px)`};
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const ButtonWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  & > *:not(:last-child){
    margin-right: 10px;
  }
`

const UsersDetailGrid = props => {
  const {
    usersDetail,
    usersDelete,
    match,
    goBack,
    goEdit
  } = props

  // useState
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Location
  const userId = path(['params', 'id'], match)

  // Data
  const usersDetailData = prop('data', usersDetail)
  const usersDetailLoading = prop('loading', usersDetail)
  const usersDeleteLoading = prop('loading', usersDelete)

  // Const
  const firstName = prop('firstName', usersDetailData)
  const secondName = prop('secondName', usersDetailData)
  const name = firstName + ' ' + secondName
  const isActive = prop('isActive', usersDetailData)
  const isActiveText = isActive ? 'Активный' : 'Не активный'
  const positionName = path(['position', 'name'], usersDetailData)
  const username = prop('username', usersDetailData)
  const phoneNumber = prop('phoneNumber', usersDetailData)
  const handleCall = () => isAndroid ? window.Android.call(phoneNumber) : (window.location.href = `tel://${phoneNumber}`)
  const coverPhoto = path(['photo', 'file'], usersDetailData)

  // SecondHeight
  const secondHeight = (!usersDetailLoading && coverPhoto) ? 200 : 50

  // Description
  const descriptionContent =
    <TilesList
      title={'Описание'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Статус'} is={<TextStatus bold status={isActive ? 'active' : 'inactive'}>{isActiveText}</TextStatus>} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Права доступа'} is={positionName} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Логин'} is={username} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription
          it={'Телефон'}
          is={phoneNumber && <TextStatus onClick={handleCall} bold status={'primary'}>{phoneNumber}</TextStatus>}
        />
      </TileItem>
    </TilesList>

  // Buttons
  const buttons =
    <ButtonWrap>
      <Button
        text={'Редактировать'}
        onClick={() => goEdit(userId)}
        fullWidth
      />
      <Button
        text={'Удалить'}
        background={'#e66767'}
        onClick={() => setConfirmModalOpen(true)}
        fullWidth
      />
    </ButtonWrap>

  // MainContent
  const mainContent =
    <>
      {descriptionContent}
      {buttons}
    </>

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        onClick={() => usersDelete.onSubmit({id: userId})}
        loading={usersDeleteLoading}
        type={'delete'}
      />
    </>

  // Render
  return (
    <>
      <PhotoBar
        title={(!usersDetailLoading && name) || 'Сотрудник'}
        cover={!usersDetailLoading ? coverPhoto : undefined}
        secondHeight={secondHeight}
        goBack={goBack}
      />
      <Container
        secondHeight={secondHeight}
      >
        <Wrap
          secondHeight={secondHeight}
        >
          {usersDetailLoading
            ? <Loader />
            : mainContent || <NoResults text={'Нет данных'} />}
        </Wrap>
      </Container>
      {modals}
    </>
  )
}

export default UsersDetailGrid
