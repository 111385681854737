import React, {useState} from 'react'
import Container from 'components/Container'
import {groupBy, isEmpty, path, pipe, prop, toPairs} from 'ramda'
import {CreateBar} from 'components/Navigation/TopBar'
import mapIndexed from 'helpers/mapIndexed'
import styled from 'styled-components'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import NoResults from 'components/NoData/NoResults'
import numberFormat from 'helpers/numberFormat'
import ModalConfirm from 'components/Modal/ModalConfirm'
import TileItem from 'components/Grid/Tile/TileItem'
import {Table} from 'components/Table'
import {TextStatus} from 'components/Status/TextStatus'
import TilesList from 'components/Cards/Tile/TilesList'
import sumBy from 'helpers/sumBy'
import CardDescription from 'components/Cards/CardDescription'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0 ${({withButton}) => withButton ? '70px' : '20px'};
`
const ListWrap = styled('div')`
  padding: 0 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`

const StocksSelectionsDetailGrid = (props) => {
  const {
    stocksSelectionsProductsList,
    stocksSelectionsCreateEnd,
    goBack,
    match
  } = props

  // useStates
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Data
  const stocksSelectionsProductsListData = prop('data', stocksSelectionsProductsList)
  const stocksSelectionsProductsListLoading = prop('loading', stocksSelectionsProductsList)
  const stocksSelectionsCreateEndLoading = prop('loading', stocksSelectionsCreateEnd)
  const products = prop('products', stocksSelectionsProductsListData)
  const status = prop('status', stocksSelectionsProductsListData)

  const totalAmount = products && sumBy(products, ['amount'])
  const sortedProducts = products && pipe(
    groupBy(prop('measurement')),
    toPairs
  )(products)

  // Redirects
  const selectionId = path(['params', 'selectionId'], match)

  // Products list
  const mainList =
    <>
      <TilesList title={'Список товаров'}>
        <TileItem
          height={'auto'}
          styles={{'& > div': {padding: '2px 0'}}}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
              </tr>
              {products && mapIndexed((item, index) => {
                const id = prop('id', item)
                const productName = prop('name', item)
                const amount = prop('amount', item)
                const measurement = prop('measurement', item)
                const measurementName = measurement && measurement.slice(0, 3)
                const formatedAmount = numberFormat(amount, measurementName)

                return (
                  <tr key={id}>
                    <td colSpan={'2'}>{(index + 1) + '. ' + productName}</td>
                    <td>{formatedAmount}</td>
                  </tr>
                )
              }, products)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td><TextStatus bold status={'primary'}>{numberFormat(totalAmount, 'ед')}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
      <TilesList
        title={'Итого'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во видов товаров'} is={numberFormat(totalAmount, 'ед')} />
        </TileItem>
        {sortedProducts && mapIndexed((item, index) => {
          const sortName = prop('0', item)
          const arr = prop('1', item)
          const amountSum = sumBy(arr, ['amount'])

          return (
            <TileItem
              height={'auto'}
              key={index}
            >
              <CardDescription it={'Кол-во товаров'} is={numberFormat(amountSum, sortName.slice(0, 3))} />
            </TileItem>
          )
        }, sortedProducts)}
      </TilesList>
    </>

  // Submit
  // Button configs
  const buttonText = 'Завершить подбор'
  const buttonFunc = () => setConfirmModalOpen(true)
  const handleMainSubmit = () => {
    stocksSelectionsCreateEnd.onSubmit({
      id: selectionId
    })
  }

  // Confirm modal
  const confirmModals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Подтвердите ваше действие'}
      loading={stocksSelectionsCreateEndLoading}
      type={'submit'}
      onClick={handleMainSubmit}
    />

  // Main content
  const mainContent =
    <>
      <Wrap
        withButton={status === 'pending'}
      >
        {stocksSelectionsProductsListLoading ? <Loader styles={{marginTop: '20px'}} /> : isEmpty(products) ? <NoResults /> : <ListWrap>{mainList}</ListWrap>}
        {(status === 'pending' && !stocksSelectionsProductsListLoading) &&
        <BottomBar>
          <Button
            type={'button'}
            text={buttonText}
            onClick={() => buttonFunc()}
            fullWidth
          />
        </BottomBar>}
      </Wrap>
    </>

  const titleName = 'Подбор № ' + selectionId

  // Render
  return (
    <>
      <CreateBar
        goExit={goBack}
        title={titleName}
      />
      <Container>
        {mainContent}
        {confirmModals}
      </Container>
    </>
  )
}

export default StocksSelectionsDetailGrid
