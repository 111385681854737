import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withFilter} from 'hocs'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const marketsSalesOrdersList = prop('marketsSalesOrdersList', state)
    const notificationGetNotViewed = prop('notificationGetNotViewed', state)
    const gpsSender = prop('gpsSender', state)
    return {
      marketsSalesOrdersList,
      notificationGetNotViewed,
      gpsSender
    }
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_ORDERS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.ORDERS_CREATE_URL
  }),
  withFilter({
    fields: ['ordersSearch']
  }),
  withHandlers({
    handleSetStatus: props => (status) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({status}, path(['history', 'location', 'search'], props))
      })
    },
    handleSetReloadUI: props => (reloadUI) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({reloadUI}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
