import {openDB} from 'idb'

export const getDb = async () => {
  const db = await openDB('baza', 1, {
    upgrade (db) {
      const store = db.createObjectStore('filters', {
        keyPath: 'api',
        autoIncrement: true
      })
      store.createIndex('api', 'api')

      const storeMarkets = db.createObjectStore('markets', {
        keyPath: 'id',
        autoIncrement: true
      })
      storeMarkets.createIndex('id', 'id')

      const storeLists = db.createObjectStore('lists', {
        keyPath: 'api',
        autoIncrement: true
      })
      storeLists.createIndex('api', 'api')
    }
  })
  return db
}

export const setToDb = async (tableName, value, isDetail = false) => {
  const db = await getDb()
  {
    const tx = db.transaction(tableName, 'readwrite')
    if (isDetail) {
      tx.store.put({
        ...value
      })
    } else {
      value.map(item => tx.store.put({
        ...item
      }))
    }
    await tx.done
  }
  return value
}

export const getFromDb = async (tableName, keyName) => {
  const db = await getDb()
  const tx = db.transaction(tableName, 'readonly')
  return tx.store.get(keyName)
}
