import React, {useState} from 'react'
import {path, prop} from 'ramda'
import Container from 'components/Container/Container'
import DetailBar from 'components/Navigation/TopBar/DetailBar'
import {Trash2 as TrashIcon} from 'react-feather'
import {ModalConfirm} from 'components/Modal'
import styled from 'styled-components'
import EditFormFields from 'containers/Productions/containers/Batches/Grid/Edit/Grid/EditFormFields'
import Form from 'components/Forms/Form'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  padding: 14px 16px;
`

const ProductionsBatchesEditGrid = props => {
  const {
    match,
    goBack,
    manufactureBatchesEdit,
    manufactureBatchesDelete
  } = props

  // Data
  const manufactureBatchesEditLoading = prop('loading', manufactureBatchesEdit)
  const manufactureBatchesDeleteLoading = prop('loading', manufactureBatchesDelete)

  // useState
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  // Location
  const batchesId = path(['params', 'batchesId'], match)

  // InitialValues
  const initialValues = {}

  // Submit
  const handleSubmit = values => {
    manufactureBatchesEdit.onSubmit({
      id: batchesId,
      amount: prop('amount', values)
    })
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <EditFormFields
          manufactureBatchesEditLoading={manufactureBatchesEditLoading || manufactureBatchesDeleteLoading}
        />
      </Form>
    </Wrap>

  // Delete submit
  const handleDelete = () => {
    manufactureBatchesDelete.onSubmit({id: batchesId})
  }

  // Modals
  const modals =
    <>
      <ModalConfirm
        type={'delete'}
        open={deleteConfirm}
        isOpen={setDeleteConfirm}
        onClick={() => handleDelete()}
      />
    </>

  // Render
  return (
    <>
      <DetailBar
        title={'Партия №' + batchesId}
        goBack={() => goBack(batchesId)}
        buttons={[{
          icon: <TrashIcon color={'#ff0000'} />,
          onClick: () => setDeleteConfirm(true)
        }]}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      {modals}
    </>
  )
}

export default ProductionsBatchesEditGrid
