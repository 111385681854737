import {compose} from 'recompose'
import {withFilter, withRedirect} from 'hocs'
import {prop} from 'ramda'
import {connect} from 'react-redux'
import CarsGrid from 'containers/Cars/Grid'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const carsList = prop('carsList', state)
    return {
      carsList
    }
  }),
  withFilter({
    fields: ['search']
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.CARS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    withCurrentParams: true
  })
)(CarsGrid)
