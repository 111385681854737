import {path} from 'ramda'
import {compose, pure, mapPropsStream} from 'recompose'
import {distinctUntilChanged, first} from 'rxjs/operators'
import {connect} from 'react-redux'
import {
  getDataFromState,
  getListParams
} from 'helpers/get'
import {isEqualSearch, DEFAULT_PICK_PARAMS} from 'helpers/isEquals'

const withFetchList = params => {
  const {
    stateName,
    action,
    key = 'list',
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS,
    workOnlyFirstTime = false
  } = params

  const mapStateToProps = state => ({
    [key]: getDataFromState(stateName, state)
  })
  const mapDispatchToProps = {action}

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    mapPropsStream(props$ => {
      if (workOnlyFirstTime) {
        props$
          .pipe(
            first()
          )
          .subscribe(props => props.action(mapper(props, pickParams), props))
      } else {
        props$
          .pipe(
            distinctUntilChanged(
              isEqualSearch(pickParams),
              path(['history', 'location', 'search'])
            )
          )
          .subscribe(props => props.action(mapper(props, pickParams), props))
      }
      return props$
    }),
    pure
  )
}

export default withFetchList
