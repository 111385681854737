import React from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'
import ReactSearchField from 'react-select'
import {find, prop, propEq, map, isEmpty, path, equals} from 'ramda'
import selectStyles from './selectStyles'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import Loader from '../../Loader'
import {isOpenToTop} from '../../../constants/styles'
import Portal from '@material-ui/core/Portal/Portal'
import Fab from '../Fab'
import {X as CloseIcon} from 'react-feather'

const SelectWrapper = styled('div')`
  width: ${(props) => props.fullWidth ? '100%' : '220px'};
  min-width: ${(props) => props.fullWidth ? '100%' : '220px'};
  max-width: 100%;
  color: ${({theme}) => theme.text.primary};
  font-size: 16px;
`

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  border-radius: ${({open}) => open && '12px 12px 0px 0px'};
  padding: ${({open}) => open && '16px 16px 4px 16px'};
`

const Close = styled('div')`
    position: fixed;
    top: ${({focus}) => focus ? '21px' : 'calc(24vh + 9px)'};
    right: 21px;
    transition: top .35s;
`

const LoadWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  & > div {
    margin: 0
  }
`

const BgMask = styled('div')`
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.09);
  z-index: 2001;
  box-sizing: border-box;
`

const Label = styled('label')`
  text-transform: ${({open}) => open ? 'none' : 'uppercase'};
  font-weight: ${({open}) => open && '500'};
  margin-bottom: ${({open}) => open ? '20px' : '8px'};
  font-size: ${({open}) => open ? '17px' : '12px'};
  color: ${({theme, open}) => open ? '#000' : theme.text.secondary}
  :after{
    display: ${({required}) => required ? 'inline-block' : 'none'};
    content: '\u00A0*';
    color: ${({theme}) => theme.palette.red}
  }
`

const noOptionsMessage = ({inputValue}) => {
  if (inputValue) return `Не найдено "${inputValue}"`
  return 'Не найдено'
}
const loadingMessage = ({inputValue}) => {
  return <LoadWrapper><Loader size={0.5} /></LoadWrapper>
}

const enhance = compose(
  connect()
)

class SearchField extends React.Component {
  state = {
    label: '',
    focus: false,
    loading: false,
    menuIsOpen: false,
    dataSource: []
  }

  mount = true;

  fetchList = ({getOptions, getText, getValue, dispatch, input, valueString}) => {
    this.setState({loading: true})
    dispatch(getOptions(this.state.label))
      .then((data) => {
        if (this.mount && data) {
          data = map((item) => {
            return {
              label: getText(item),
              value: valueString ? `${getValue(item)}` : getValue(item),
              valueData: item
            }
          }, data)
          this.setState({dataSource: data, loading: false})
        }
      })
  }

  handleMenuOpen = () => {
    this.setState({
      menuIsOpen: true
    })
    document.body.style.overflow = 'hidden'
    if (isEmpty(this.state.dataSource)) {
      this.fetchList(this.props)
    }
  }

  handleMenuClose = () => {
    this.setState({
      menuIsOpen: false,
      focus: false
    })
    document.body.style = null
  }

  componentDidMount () {
    if (isEmpty(this.state.dataSource)) {
      this.fetchList(this.props)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const {parent, input} = this.props
    const value = prop('value', input)
    const prevValue = path(['input', 'value'], prevProps)
    // const id = path(['dataSource', '0', 'value'], this.state)
    const eq = equals(prevProps.parent, parent)
    if ((value !== prevValue && isEmpty(this.state.dataSource)) || !eq) {
      this.fetchList(this.props)
      return input.onChange('')
    }
  }

  componentWillUnmount () {
    this.mount = false
  }

  selectRef = React.createRef()
  container = React.createRef()
  render () {
    const {
      theme,
      value,
      label,
      onChange,
      disabled,
      type,
      onMenuOpen,
      onMenuClose,
      input,
      meta,
      required,
      placeholder,
      fullWidth,
      valueString,
      withoutLabel = false,
      ...rest
    } = this.props
    const {menuIsOpen, loading, dataSource, focus} = this.state

    // если по умолчанию поставить в state => value: 'string', выйдет баг, toString исправит
    const toStringValue = valueString ? (prop('value', input) && `${prop('value', input)}`) : prop('value', input)
    const selectedValue = find(propEq('value', toStringValue), dataSource) || ''
    return (
      <div ref={this.container}>
        {menuIsOpen &&
        // read only !! not work
        <Wrap open={false}>
          {!withoutLabel &&
          <Label required={required} open={false}>{label}</Label>}
          <SelectWrapper
            fullWidth={fullWidth}
          >
            <ReactSearchField
              value={selectedValue}
              options={dataSource}
              classNamePrefix={'select'}
              styles={selectStyles(theme, {
                menuIsOpen: false, focus: false
              })}
              placeholder={placeholder}
              name={'fakeNameSelect'}
            />
          </SelectWrapper>
        </Wrap>}
        {/* work variant!!!! */}
        <Portal container={menuIsOpen ? document.body : this.container.current}>
          <Wrap style={menuIsOpen ? isOpenToTop(focus) : null} open={menuIsOpen}>
            {(!withoutLabel || menuIsOpen) &&
            <Label required={required} open={menuIsOpen}>{label}</Label>}
            {menuIsOpen &&
            <Close focus={focus}>
              <Fab size="small" background={'rgba(118, 118, 128, 0.12)'} onClick={this.handleMenuClose}>
                <CloseIcon size={18} color={'rgba(60, 60, 67, 0.6)'} />
              </Fab>
            </Close>}
            <SelectWrapper
              fullWidth={fullWidth}
            >
              <ReactSearchField
                isDisabled={disabled}
                ref={this.selectRef}
                value={selectedValue}
                options={dataSource}
                classNamePrefix={'select'}
                styles={selectStyles(theme, {
                  menuIsOpen, focus
                })}
                onInputChange={label => this.setState({label: label})}
                closeMenuOnSearchField={!rest.isMulti}
                menuIsOpen={menuIsOpen}
                onFocus={() => menuIsOpen && this.setState({focus: true})}
                onBlur={() => this.setState({focus: false})}
                openMenuOnClick={type === 'select'}
                onMenuOpen={this.handleMenuOpen}
                onMenuClose={this.handleMenuClose}
                noOptionsMessage={noOptionsMessage}
                loadingMessage={loadingMessage}
                isLoading={loading}
                onChange={(option, action) => {
                  if (typeof onChange === 'function') {
                    onChange(prop('value', option), prop('valueData', option))
                  }
                  if (typeof input.onChange === 'function') {
                    input.onChange(prop('value', option), prop('valueData', option))
                  }
                }}
                placeholder={placeholder}
                {...rest}
              />
            </SelectWrapper>
          </Wrap>
        </Portal>
        {menuIsOpen &&
          <Portal>
            <BgMask onClick={this.handleMenuClose} />
          </Portal>}
      </div>
    )
  }
}

SearchField.defaultGetText = (label) => {
  return (obj) => {
    return prop(label, obj)
  }
}

SearchField.defaultGetValue = (value) => {
  return (obj) => {
    return prop(value, obj)
  }
}

SearchField.propTypes = {
  theme: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    'select',
    'autocomplete'
  ]),
  isMulti: PropTypes.bool,
  valueString: PropTypes.bool,
  options: PropTypes.array,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  getText: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired
}

SearchField.defaultProps = {
  type: 'select',
  placeholder: 'Выберите из списка',
  isClearable: true,
  valueString: true
}

export default enhance(withTheme(SearchField))
