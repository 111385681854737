import {compose, mapProps, withHandlers} from 'recompose'
import {path, prop} from 'ramda'
import {connect} from 'react-redux'
import NotificationGrid from 'containers/Notification/Grid'
import toNumber from '../../helpers/toNumber'
import {appendParamsToUrl, parseParams} from '../../helpers/url'
import {openSnackbarAction} from '../../actions/snackbarAction'
import {openErrorAction} from '../../actions/errorsAction'
import {deleteNotificationAction} from './actions/notificationAction'

const DELETE_NOTIFICATION = 'deleteNotification'
export default compose(
  connect((state, props) => {
    const notificationList = prop('notificationList', state)
    const notificationDelete = prop('notificationDelete', state)
    const query = parseParams(path(['location', 'search'], props))
    return {
      notificationList,
      notificationDelete,
      query
    }
  }),
  withHandlers({
    handleDetailId: props => (detailId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({detailId}, path(['history', 'location', 'search'], props))
      })
    },
    // Delete
    setModalDeleteOpenClientTransaction: props => (open) => {
      props.history.push({
        pathname: props.location.pathname,
        search: appendParamsToUrl({[DELETE_NOTIFICATION]: open}, path(['history', 'location', 'search'], props))
      })
    },
    handleSubmitDeleteNotification: props => () => {
      const {dispatch, query} = props
      const notificationId = toNumber(prop(DELETE_NOTIFICATION, query))
      const reloadUI = Math.random()
      dispatch(deleteNotificationAction(notificationId))
        .then(() => {
          props.history.push({
            search: appendParamsToUrl({[DELETE_NOTIFICATION]: false, reloadUI}, path(['history', 'location', 'search'], props))
          })
          return dispatch(openSnackbarAction({message: 'Успешно удалено'}))
        })
        .catch((e) => {
          return dispatch(openErrorAction({errMessage: e}))
        })
    }
  }),
  mapProps(({
    setModalDeleteOpenClientTransaction,
    handleSubmitDeleteNotification,
    notificationDelete,
    ...props
  }) => {
    // Delete
    const modalDeleteClientTransactionOpen = toNumber(prop([DELETE_NOTIFICATION], props.query)) > 0 && true
    const deleteNotificationDialog = {
      modalDelete: modalDeleteClientTransactionOpen,
      id: toNumber(prop('deleteTransaction', props.query)) || null,
      loading: notificationDelete.loading,
      onOpen: setModalDeleteOpenClientTransaction,
      handleSubmit: handleSubmitDeleteNotification
    }
    return {
      deleteNotificationDialog,
      ...props
    }
  })
)(NotificationGrid)
