import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Menu as MenuIcon, X as CloseIcon, Settings as SettingsIcon} from 'react-feather'
import menus from 'constants/menus'
import {isEmpty, path, prop, range, reject} from 'ramda'
import {NavLink, withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {customStoreClearAction, logoutAction} from 'containers/SignIn/actions/signInAction'
import * as ROUTES from 'constants/routes'
import {withAuth, withRedirect} from 'hocs'
import Perms from '../../Perms/Perms'
import PhotoWithStatus from 'components/PhotoWithStatus'
import mapIndexed from 'helpers/mapIndexed'
import {loader} from 'components/Skeleton/SkeletonKeyframe'
import {paramsToSearch} from 'helpers/url'

const Item = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
`
const MenuTrigger = styled(Item)`
  width: 40px;
  position: relative;
`
const Trigger = styled('i')`
  display: ${({on}) => on ? 'block' : 'none'};
  width: 6px;
  height: 6px;
  position: absolute;
  right: -3px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: ${({theme}) => theme.palette.red};
`
const BGMusk = styled('div')`
  background: ${({theme}) => theme.background.mask};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  transition: all .1s ease-out;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  z-index: 900;
`
const MenuContent = styled('div')`
  background: ${({theme}) => theme.background.primary};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all .2s ease-out;
  transform: translateX(${({isOpen}) => isOpen ? '0' : '-300px'});
  overflow-y: auto;
  max-width: 300px;
  width: 100%;
  z-index: 1000;
`
const Title = styled('div')`
  height: 150px;
  border-bottom: 1px solid #e4e4e6;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: ${({theme}) => theme.nav.primary.background};
`
const UserProfile = styled('div')`
  height: 87px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 19px 16px;
  & > svg{
    margin-left: auto;
  }
  ${({styles}) => styles};
`
const UserName = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 10px;
  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:not(:last-child){
      margin-bottom: 4px;
    }
  }
`
const Name = styled('span')`
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.0241176em;
  color: ${({theme}) => theme.palette.black};
`
const SubName = styled('span')`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme}) => theme.text.secondary};
`
const CloseMenu = styled('span')`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 19px auto 0 16px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MenuList = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 150px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  & > div {
    padding: 11px 0 11px 10px;
    &:not(:first-child){
      border-top: 1px solid #e4e4e6;
    }
    &:first-child{
      flex-grow: 1;
    }
  }
`
const MainMenuList = styled('div')`
  padding: 11px 0 !important;
  & > * {
    margin-left: 10px;
  }
`
const MenuItem = styled(NavLink)`
  position: relative;
  color: ${({theme, color}) => color || theme.palette.black};
  display: block;
  padding: 14px 48px 14px 16px;
  text-decoration: none;
  transition: ${props => props.theme.transition.primary};
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0241176em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.active, &:hover {
    background-color: ${({theme}) => theme.palette.white};
  }
`
const Counter = styled('span')`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  padding: 2px 9px;
  background: ${({theme}) => theme.palette.red};
  color: ${({theme}) => theme.palette.white};
  font-weight: 500;
  border-radius: 10px;
  height: 20px;
  font-size: 12px;
  line-height: 15px;
  display: ${({on}) => on ? 'flex' : 'none'};
`
const SubTitle = styled('h3')`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.grey};
  padding: 20px 16px 12px;
  border-top: 1px solid #e4e4e6;
`
const SubMenusWrap = styled('div')`
  margin: 0;
  padding: 10px 0;
  &:not(:last-child){
    border-bottom: 1px solid #e4e4e6;
  }
  & > a{
    padding-left: 30px;
    margin-left: 10px;
  }
  & + a{
    margin-top: 10px;
  }
  & + div > h3{
    border-top: none !important;
  }
`
const SkeletonBar = styled('div')`
  height: 45px;
  justify-content: space-around;
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  h3:after{
    display: block;
    content: "";
    height: 15px;
    width: 50%;
    background: ${({theme}) => theme.skeleton.primary};
    border-radius: ${({theme}) => theme.borderRadius.primary};
    animation: ${loader} 1s infinite ease-in-out;
  }
  p:after{
    display: block;
    content: "";
    height: 15px;
    width: 90%;
    background: ${({theme}) => theme.skeleton.primary};
    border-radius: ${({theme}) => theme.borderRadius.primary};
    animation: ${loader} 1s infinite ease-in-out;
  }
  p:last-child:after{
    width: 70%;
  }
`
const SkeletonList = styled('div')`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  padding: 25px 0;
  & > div {
    width: calc(100% - 10px);
    position: relative;
    &:after{
      display: block;
      content: "";
      height: 50px;
      width: 100%;
      background: ${({theme}) => theme.palette.white};
      border-radius: 5px 0 0 5px;
      animation: ${loader} 1s infinite ease-in-out;
    }
  }
  & > * {
    margin-left: 10px;
    margin-bottom: 15px;
  }
`
const MenuLogout = styled('span')`
  color: ${({theme}) => theme.palette.red};
  display: block;
  padding: 14px 48px 14px 16px;
  text-decoration: none;
  transition: ${props => props.theme.transition.primary};
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0241176em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.active, &:hover {
    background-color: ${({theme}) => theme.palette.white};
  }
`

const enhance = compose(
  connect((state) => {
    const customStore = prop('customStore', state)
    const notificationGetNotViewed = prop('notificationGetNotViewed', state)
    const gpsSender = prop('gpsSender', state)
    return {
      customStore,
      notificationGetNotViewed,
      gpsSender
    }
  },
  {
    logoutAction,
    customStoreClearAction
  }),
  withRouter,
  withRedirect({
    key: 'goProfile',
    redirectUrl: ROUTES.PROFILE_URL,
    withCurrentParams: false
  }),
  withAuth,
  withHandlers({
    onLogout: props => () => {
      props.customStoreClearAction()
      if (typeof window.Android !== 'undefined') {
        window.Android.logOut()
      }
      localStorage.clear()
      props.history.replace(ROUTES.SIGN_IN_PATH)
    }
  })
)

const MainMenu = props => {
  const {
    customStore,
    notificationGetNotViewed,
    gpsSender,
    goProfile,
    onLogout
  } = props

  // useStates
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  // switcherFunc
  const onMenuOpen = () => setMenuIsOpen(true)
  const onMenuClose = () => setMenuIsOpen(false)

  // useEffect
  useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [menuIsOpen])

  // Data
  const customStoreData = prop('data', customStore)
  const customStoreLoading = prop('loading', customStore)
  const userFN = prop('firstName', customStoreData)
  const userLN = prop('secondName', customStoreData)
  const imgSrc = path(['photo', 'file'], customStoreData)
  const position = path(['position', 'name'], customStoreData)
  const userName = (userFN && userLN) ? userFN + ' ' + userLN : 'Ритм.Доставка'
  const getNotViewedData = prop('data', notificationGetNotViewed)
  const getNotViewedDataNotifications = prop('count', getNotViewedData)
  const gpsCountData = prop('data', gpsSender)
  const gpsCountNotifications = prop('notifications', gpsCountData)
  const realCountNotifications = gpsCountNotifications || getNotViewedDataNotifications || 0
  const activeNotifications = getNotViewedData && reject((item) => +item === 0, getNotViewedData)
  const activeNotificationsWithGps = gpsCountData && reject((item) => +item === 0, gpsCountData)
  const withNotifications = (activeNotificationsWithGps && !isEmpty(activeNotificationsWithGps)) || !isEmpty(activeNotifications)

  // Skeletons
  const userProfileSkeleton =
    <UserProfile>
      <PhotoWithStatus
        size={'62px'}
        skeleton
      />
      <SkeletonBar>
        <h3> </h3>
        <p> </p>
      </SkeletonBar>
    </UserProfile>
  const menuListSkeleton =
    <SkeletonList>
      {mapIndexed((item, index) => {
        return (
          <div key={index}> </div>
        )
      }, range(0, 7))}
    </SkeletonList>

  // UserProfile
  const userProfile =
    <UserProfile
      onClick={() => goProfile()}
    >
      <PhotoWithStatus
        type={'user'}
        status={'none'}
        imgSrc={imgSrc}
        size={'49px'}
      />
      <UserName>
        <Name>{userName}</Name>
        <SubName>{position}</SubName>
      </UserName>
      <SettingsIcon />
    </UserProfile>

  // MenuList
  const menuList =
    <MenuList>
      <MainMenuList>
        <MenuItem
          activeClassName={'active'}
          to={ROUTES.NOTIFICATION_URL}
        >
          <span>Уведомления</span>
          <Counter
            on={(realCountNotifications > 0)}
          >
            {realCountNotifications}
          </Counter>
        </MenuItem>
        {menus.map((item, index) => {
          const url = prop('url', item)
          const title = prop('title', item)
          const perms = prop('perms', item)
          const params = paramsToSearch(prop('params', item))
          const counter = prop('counterName', item)
          const counterValue = gpsCountData ? prop(counter, gpsCountData) : prop(counter, getNotViewedData)
          const subMenus = prop('subMenus', item)
          const i =
            <MenuItem
              key={index}
              to={params ? `${url}?${params}` : url}
              activeClassName={'active'}
            >
              <span>
                {title}
              </span>
              {counter &&
              <Counter
                on={counterValue > 0}
              >
                {counterValue}
              </Counter>}
            </MenuItem>

          const subMenusList =
            <SubMenusWrap
              key={index}
            >
              <SubTitle>
                {title}
              </SubTitle>
              {subMenus && mapIndexed((item, index) => {
                const subTitle = prop('title', item)
                const subUrl = prop('url', item)
                const params = paramsToSearch(prop('params', item))
                const subPerms = prop('perms', item)
                const subCounter = prop('counterName', item)
                const subCounterValue = gpsCountData ? prop(subCounter, gpsCountData) : prop(subCounter, getNotViewedData)
                const subI =
                  <MenuItem
                    key={index}
                    to={params ? `${subUrl}?${params}` : subUrl}
                    activeClassName={'active'}
                  >
                    <span>
                      {subTitle}
                    </span>
                    {subCounter &&
                    <Counter
                      on={subCounterValue > 0}
                    >
                      {subCounterValue}
                    </Counter>}
                  </MenuItem>

                if (subPerms) {
                  return (
                    <Perms perms={subPerms} key={index}>
                      {subI}
                    </Perms>
                  )
                } else {
                  return subI
                }
              }, subMenus)}
            </SubMenusWrap>

          const x = !subMenus
            ? i
            : subMenusList

          if (perms) {
            return (
              <Perms perms={perms} key={index}>
                {x}
              </Perms>
            )
          } else {
            return x
          }
        })}
      </MainMenuList>
      <div>
        <MenuLogout onClick={onLogout}>
          <span>Выход из аккаунта</span>
        </MenuLogout>
      </div>
    </MenuList>

  return (
    <>
      <MenuTrigger onClick={onMenuOpen}>
        <MenuIcon color={'#000'} size={24} />
        <Trigger on={(realCountNotifications > 0) || withNotifications} />
      </MenuTrigger>
      <BGMusk isOpen={menuIsOpen} onClick={onMenuClose} />
      <MenuContent isOpen={menuIsOpen}>
        <Title>
          <CloseMenu onClick={onMenuClose}>
            <CloseIcon size={16} color={'#000'} />
          </CloseMenu>
          {customStoreLoading ? userProfileSkeleton : userProfile}
        </Title>
        {customStoreLoading ? menuListSkeleton : menuList}
      </MenuContent>
    </>
  )
}

export default enhance(MainMenu)
