import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import store from './store'
import routes from './routes'
import MultiRouter from './routes/MultiRouter/MultiRouter'
import {Redirect, Route} from 'react-router'
import NotFoundContainer from 'containers/NotFound/NotFoundContainer'
import * as ROUTES from 'constants/routes'
import {ThemeProvider} from 'styled-components'
import LayoutProvider from 'Layout/Provider/LayoutProvider'
import NormalizeCSS from 'themes/NormalizeCSS'
import MainCSS from 'themes/MainCSS'
import {theme} from 'themes/constants'
import moment from 'moment'
import 'moment/locale/ru'
import {parseParams} from 'helpers/url'
import {storageData} from 'helpers/storage'
import {prop} from 'ramda'
import PushNotification from './notifications/PushNotification'

moment.locale('ru')
moment.updateLocale('ru', {
  monthsShort: [
    'янв', 'фев', 'мар', 'апр', 'май', 'июн',
    'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
  ]
})

window.setImageValues = (key, uid, image) => {
  window.store.dispatch({type:'IMAGE_ADDED', key: key, data: {uid: uid, image: image}})
}

const App = () => {
  const search = window.location.search
  const queryParams = parseParams(search)
  const serverQuery = prop('server', queryParams)
  const tokenQuery = prop('token', queryParams)
  if (serverQuery && tokenQuery) {
    storageData('token').setValue(tokenQuery)
    storageData('server').setValue(serverQuery)
  }

  return (
    <Provider store={store()}>
      <ThemeProvider theme={theme}>
        <>
          <LayoutProvider />
          <PushNotification />
          <NormalizeCSS />
          <MainCSS />
          <Router>
            <Switch>
              <Redirect
                path={ROUTES.ROOT_PATH}
                to={ROUTES.MARKETS_URL}
                exact
              />
              {routes.map((route, key) => (
                <MultiRouter key={key} {...route} />
              ))}
              <Route component={NotFoundContainer} />
            </Switch>
          </Router>
        </>
      </ThemeProvider>
    </Provider>
  )
}

export default App
