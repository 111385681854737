import {compose} from 'recompose'
import * as ROUTES from 'constants/routes'
import {withCreate, withRedirect} from 'hocs'
import CarsOrdersDeleteGrid from 'containers/Cars/Grid/OrdersDelete/Grid/CarsOrdersDeleteGrid'
import {carsOrdersDeleteAction} from 'containers/Cars/actions/carsAction'
import * as STATES from 'constants/states'

export default compose(
  withCreate({
    stateName: [STATES.CARS_ORDERS_CANCEL],
    action: carsOrdersDeleteAction,
    key: [STATES.CARS_ORDERS_CANCEL],
    redirectUrl: ROUTES.CARS_ORDERS_URL,
    withCurrentParams: true,
    propFromParams: 'id',
    successMessage: 'Посещение успешно удалено'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_ORDERS_DETAIL_URL,
    withCurrentParams: true
  })
)(CarsOrdersDeleteGrid)
