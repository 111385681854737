import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {path, prop} from 'ramda'
import {sprintf} from 'sprintf-js'
import {parseParams} from 'helpers/url'

export const manufactureListAction = () => {
  const defaultParams = {
    page_size: 1000
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MANUFACTURE_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_LIST,
      payload
    })
  }
}

export const manufactureProductsDetailAction = (params, props) => {
  const secondId = path(['match', 'params', 'secondId'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MANUFACTURE_PRODUCTS_DETAIL, secondId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_PRODUCTS_DETAIL,
      payload
    })
  }
}

export const manufactureBatchesDetailAction = (params, props) => {
  const batchesId = path(['match', 'params', 'batchesId'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MANUFACTURE_BATCHES_DETAIL, batchesId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_DETAIL,
      payload
    })
  }
}

export const equipmentListAction = (params, props) => {
  const id = path(['match', 'params', 'id'], props)
  const defaultParams = {
    manufacture: id
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.EQUIPMENT_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.EQUIPMENT_LIST,
      payload
    })
  }
}

export const manufactureBatchesCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MANUFACTURE_BATCHES_LIST, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_CREATE,
      payload
    })
  }
}

export const manufactureBatchesAddProductsAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MANUFACTURE_BATCHES_ADD_PRODUCTS, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_ADD_PRODUCTS,
      payload
    })
  }
}

export const manufactureBatchesAddMaterialsAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MANUFACTURE_BATCHES_ADD_MATERIALS, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_ADD_MATERIALS,
      payload
    })
  }
}

// Finish
export const manufactureBatchesFinishCreateAction = (values) => {
  const id = prop('id', values)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.MANUFACTURE_BATCHES_FINISH_CREATE, id), {})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_FINISH_CREATE,
      payload
    })
  }
}

export const manufactureBatchesEditAction = (values) => {
  const id = prop('id', values)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.MANUFACTURE_BATCHES_EDIT, id), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_EDIT,
      payload
    })
  }
}

export const manufactureBatchesDeleteAction = (values) => {
  const id = prop('id', values)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.MANUFACTURE_BATCHES_DELETE, id), {})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_DELETE,
      payload
    })
  }
}

export const manufactureBatchesProductEditAction = (values) => {
  const searchObj = parseParams(window.location.search)
  const productId = prop('productId', searchObj)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.MANUFACTURE_BATCHES_PRODUCT_EDIT, productId), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_PRODUCT_EDIT,
      payload
    })
  }
}

export const manufactureBatchesMaterialEditAction = (values) => {
  const searchObj = parseParams(window.location.search)
  const materialId = prop('materialId', searchObj)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.MANUFACTURE_BATCHES_MATERIAL_EDIT, materialId), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_MATERIAL_EDIT,
      payload
    })
  }
}

export const manufactureBatchesProductDeleteAction = (values) => {
  const searchObj = parseParams(window.location.search)
  const productId = prop('productId', searchObj)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.MANUFACTURE_BATCHES_PRODUCT_DELETE, productId), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_PRODUCT_DELETE,
      payload
    })
  }
}

export const manufactureBatchesMaterialIdDeleteAction = (values) => {
  const searchObj = parseParams(window.location.search)
  const materialId = prop('materialId', searchObj)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.MANUFACTURE_BATCHES_MATERIAL_DELETE, materialId), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MANUFACTURE_BATCHES_MATERIAL_DELETE,
      payload
    })
  }
}
