import {compose} from 'recompose'
import Grid from 'containers/Productions/containers/Batches/Grid/Create/StepOne/Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {manufactureListAction} from 'containers/Productions/actions/ProductionsBatchesAction'
import * as ROUTES from 'constants/routes'

export default compose(
  withFetchList({
    stateName: STATES.MANUFACTURE_LIST,
    action: manufactureListAction,
    key: STATES.MANUFACTURE_LIST
  }),
  withRedirect({
    key: 'goExit',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_URL
  }),
  withRedirect({
    key: 'goNext',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_CREATE_STEP_TWO_URL
  })
)(Grid)
