import {compose, withHandlers} from 'recompose'
import {withFetchList, withFilter} from 'hocs'
import StatisticsGrid from './Grid'
import {path} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'
import * as STATES from 'constants/states'
import {statisticsAgentsByDivisionsAction} from 'containers/Statistics/actions/statisticsAction'

export default compose(
  withFetchList({
    stateName: STATES.STATISTICS_AGENTS_BY_DIVISIONS,
    action: statisticsAgentsByDivisionsAction,
    key: STATES.STATISTICS_AGENTS_BY_DIVISIONS,
    pickParams: ['beginDate', 'endDate']
  }),
  withFilter({
    fields: ['beginDate', 'endDate']
  }),
  withHandlers({
    handleSetPeriod: props => (beginDate, endDate) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({beginDate, endDate}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(StatisticsGrid)
