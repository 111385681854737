import React from 'react'
import {PhotoBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import styled from 'styled-components'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import moment from 'moment'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

const CarsDescriptionGrid = (props) => {
  const {
    carsDetail,
    goBack,
    match
  } = props

  // Data
  const carsDetailData = prop('data', carsDetail)
  const clientName = prop('clientName', carsDetailData)
  const carsDetailLoading = prop('loading', carsDetail)
  const number = prop('number', carsDetailData)
  const phones = prop('phones', carsDetailData)
  const note = prop('note', carsDetailData)
  const engineNumber = prop('engineNumber', carsDetailData)
  const carBrand = path(['carBrand', 'name'], carsDetailData)
  const carBrandParent = path(['carBrand', 'parent', 'name'], carsDetailData)
  const name = carBrandParent ? (carBrandParent + ' ' + carBrand) : carBrand
  const lastAcceptor = prop('lastAcceptor', carsDetailData)
  const lastKm = prop('km', lastAcceptor)
  const lastVisit = prop('finishTime', lastAcceptor)

  // Redirects
  const carId = path(['params', 'id'], match)

  // const carId =
  const secondHeight = 70

  // Car description
  const carDescription =
    <TilesList
      title={'Данные авто'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Последний пробег'} is={numberFormat(lastKm, 'км')} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Последний визит'} is={moment(lastVisit).format('DD MMM YYYY HH:mm')} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Номер двигателя'} is={engineNumber} />
      </TileItem>
    </TilesList>

  // Client description
  const clientDescription =
    <TilesList
      title={'Данные клиента'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Имя клиента'} is={clientName} />
      </TileItem>
      {phones &&
      mapIndexed((item, index) => {
        const num = prop('number', item)

        return (
          <TileItem
            height={'auto'}
            key={index}
          >
            <CardDescription it={'Телефон'} is={<a href={'tel:' + num}>{num}</a>} />
          </TileItem>
        )
      }, phones)}
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Заметка'} is={note} />
      </TileItem>
    </TilesList>

  // Main Content
  const mainContent =
    <>
      {carDescription}
      {clientDescription}
    </>

  return (
    <>
      <PhotoBar
        title={number || 'Авто'}
        subTitle={name || ''}
        secondHeight={secondHeight}
        goBack={() => goBack(+carId)}
      />
      <Container
        secondHeight={secondHeight}
      >
        <Wrap>
          {carsDetailLoading
            ? <Loader styles={{marginTop: '20px'}} />
            : mainContent || 'Нет данных'}
        </Wrap>
      </Container>
    </>
  )
}

export default CarsDescriptionGrid
