import {Field} from 'react-final-form'
import TextField from '../../../../../../components/Forms/TextField'
import UniversalSearchField from '../../../../../../components/Forms/SearchField/UniversalSearchField'
import * as API from '../../../../../../constants/api'
import React from 'react'
import styled from 'styled-components'
import BottomBar from '../../../../../../components/Navigation/BottomBar'
import Button from '../../../../../../components/Forms/Button'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const CreateFormFields = (props) => {
  const {
    id,
    titleContent,
    disabled
  } = props
  return (
    <StyledWrap>
      {titleContent}
      <Field
        name="clientTransaction"
        component={TextField}
        defaultValue={id}
        disabled
        fullWidth
        label={'Транзакция'}
      />
      <Field
        component={UniversalSearchField}
        api={API.FINANCES_CASHIERS_LIST}
        params={{currency: 5, type: 'cash', page_size: '1000'}}
        name={'cashbox'}
        label={'Кассы'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        name={'date'}
        type={'date'}
        label={'Дата'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        name={'time'}
        type={'time'}
        label={'Время'}
        required
        fullWidth
      />
      <BottomBar>
        <Button
          disabled={disabled}
          type={'submit'}
          text={'Создать'}
          fullWidth
        />
      </BottomBar>
    </StyledWrap>
  )
}

export default CreateFormFields
