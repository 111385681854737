import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)``
const RightBar = styled(Bar)``
const Price = styled('h4')`
  color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : theme.palette.black
};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const Division = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`

const AcceptCashItem = props => {
  const {
    onClick,
    agent,
    totalPrice,
    currency,
    division
  } = props

  return (
    <TileItem
      onClick={onClick}
      styles={styles}
    >
      <LeftBar>
        <h3>{agent || 'не определён'}</h3>
        <Division>
          {division || 'не определён'}
        </Division>
      </LeftBar>
      <RightBar>
        <Price
          status={'active'}
        >{numberFormat(totalPrice, currency)}
        </Price>
        <Division />
      </RightBar>
      <Arrow />
    </TileItem>
  )
}

AcceptCashItem.propTypes = {
  onClick: PropTypes.func,
  totalPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string
}

export default AcceptCashItem
