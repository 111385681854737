import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 16 16'} {...props}>
      <path d="M7.99997 14C4.67997 14 1.99997 11.32 1.99997 8C1.99997 4.68 4.67997 2 7.99997 2C9.65331 2 11.1466 2.69333 12.2266 3.77333L9.33331 6.66667H16V0L13.6533 2.34667C12.2 0.906667 10.2133 0 7.99997 0C3.58664 0 0.0133057 3.58667 0.0133057 8C0.0133057 12.4133 3.58664 16 7.99997 16C11.96 16 15.24 13.12 15.8666 9.33333H13.84C13.2266 12 10.8533 14 7.99997 14Z" />
    </SvgIcon>

  )
}
