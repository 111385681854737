import React, {useState} from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from './StocksSelectionsFilter'
import {MainBar} from 'components/Navigation/TopBar'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import Container from 'components/Container'
import {path, prop} from 'ramda'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import * as serializers from 'containers/Stocks/actions/serializers/stocksSerializers'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import SelectionsItem from 'containers/Stocks/components/SelectionsItem/SelectionsItem'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'

const Grid = (props) => {
  const {
    customStore,
    stocksSelections,
    filterActions,
    location,
    goDetail,
    goSelectionCreate
  } = props

  // useStates
  const [openFilter, setOpenFilter] = useState(false)

  // Data
  const customStoreData = prop('data', customStore)
  const stocks = prop('stocks', customStoreData)
  const stocksSelectionsData = prop('data', stocksSelections)

  // Redirect
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Second Height
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Main list
  const mainList = stocksSelectionsData && mapIndexed((item, index) => {
    const id = prop('id', item)
    const endDate = prop('endDate', item)
    const stockName = path(['stock', 'name'], item)
    const createdByFN = path(['createdBy', 'firstName'], item)
    const createdBySN = path(['createdBy', 'secondName'], item)
    const createdByName = createdByFN + ' ' + createdBySN

    return (
      <SelectionsItem
        key={index}
        id={id}
        date={endDate}
        stock={stockName}
        who={createdByName}
        onClick={() => goDetail(id)}
      />
    )
  }, stocksSelectionsData)

  // MainContent
  const mainContent =
    <InfiniteScroll
      api={API.STOCKS_SELECTIONS}
      actionType={actionTypes.STOCKS_SELECTIONS}
      emptyData={<li><NoResults /></li>}
      params={serializers.stocksSelectionsSerializer(searchObj)}
      list={mainList || []}
    />

  // Menus
  const menusArr = [
    {
      title: 'Подбор',
      onClick: () => goSelectionCreate()
    }
  ]

  // Render
  return (
    <>
      <MainBar
        title={'Подборы'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
            initialValues={filterActions.initialValues}
          >
            <Filter
              setOpenFilter={setOpenFilter}
              stocks={stocks}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        {mainContent}
      </Container>
      <SecondMenu
        title={'Дополнительные функции по работе со складом'}
        menusArr={menusArr}
        button={
          <Fab
            text={'Дополнительно'}
            withfixed={'true'}
          >
            <Plus size={20} color={'#fff'} />
          </Fab>
        }
      />
    </>
  )
}

export default Grid
