import React, {memo, useRef} from 'react'
import {YMaps, Map as YandexMap, Placemark, Polyline} from 'react-yandex-maps'
import PropTypes from 'prop-types'
import {API_KEY_YANDEX} from 'constants/constants'
import mapIndexed from 'helpers/mapIndexed'
import {filter, length, map, path, prop, propEq, propOr} from 'ramda'
import hexToRgba from 'helpers/hexToRgba'

const YMap = (props) => {
  const {
    zoom,
    input,
    coords,
    setCoords,
    height,
    onLoad,
    location,
    withWay,
    handleMarketId,
    setOpenDetail
  } = props

  // Amir Temur Square
  const defaultCoords = [41.311174, 69.279757]
  const mapData = {
    center: (coords && coords[0] && coords) || defaultCoords,
    zoom: zoom || 14
  }
  const mapRef = useRef(null)

  const setPlacemark = (e) => {
    const coords = e.get('coords')
    coords && setCoords(coords)
  }
  const locationLength = length(location)
  const isOneAgent = locationLength === 1

  // PlaceMarks
  const placeMarks = mapIndexed((item) => {
    const agent = prop('userFullname', item)
    const id = prop('id', item)
    const color = prop('color', item)
    const lat = path(['liveLocation', 'lat'], item)
    const lon = path(['liveLocation', 'lon'], item)
    const latLon = [lat, lon]
    const plan = propOr([], 'plan', item)
    const wayArr = map(item => {
      return path(['market', 'location'], item)
    }, plan)

    const way = map((x) => {
      const wayLat = prop('lat', x)
      const wayLon = prop('lon', x)
      return [wayLat, wayLon]
    }, wayArr)

    const polyline = withWay &&
      <Polyline
        geometry={way}
        options={{
          strokeColor: hexToRgba(color, 0.7),
          strokeWidth: 3
        }}
      />

    const planMarks = mapIndexed((item, index) => {
      const marketId = path(['market', 'id'], item)
      const wayLat = path(['market', 'location', 'lat'], item)
      const wayLon = path(['market', 'location', 'lon'], item)
      const wayLocation = [wayLat, wayLon]
      const plansArr = propOr([], 'plans', item)
      const donePlans = filter(propEq('status', 'done'), plansArr)
      const donePlansLength = length(donePlans)
      const isDone = (donePlansLength > 0)

      return (
        <Placemark
          geometry={wayLocation}
          key={index}
          onClick={() => {
            handleMarketId(marketId)
            setOpenDetail(true)
          }}
          options={{
            iconColor: isDone ? color : '#ccc',
            preset: isDone ? 'islands#circleDotIcon' : 'islands#circleIcon'
          }}
        />
      )
    }, plan)

    return (
      <React.Fragment key={id}>
        <Placemark
          properties={{iconCaption: agent}}
          geometry={latLon}
          options={{
            iconColor: color,
            zIndex: 10000
          }}
        />
        {isOneAgent && planMarks}
        {isOneAgent && polyline}
      </React.Fragment>
    )
  }, location)

  // Render
  return (
    <YMaps
      ref={mapRef}
      query={{apikey: `${API_KEY_YANDEX}&lang=ru_RU`, kind: 'house'}}
    >
      <YandexMap
        modules={['geocode']}
        defaultState={mapData}
        onClick={input && setPlacemark}
        onLoad={onLoad}
        width={'100%'}
        height={height || 'calc(100vh - 60px)'}
      >
        {placeMarks}
      </YandexMap>
    </YMaps>
  )
}

YMap.propTypes = {
  location: PropTypes.array.isRequired,
  onLoad: PropTypes.func,
  zoom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

YMap.defaultProps = {
  zoom: 12
}

export default memo(YMap)
