import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'

export const ImageUploadAction = (formData) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FILE_UPLOAD, formData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FILE_UPLOAD,
      payload
    })
  }
}
