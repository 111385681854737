import {compose, mapPropsStream, pure, withHandlers} from 'recompose'
import TrackGrid from 'containers/Track/Grid'
import {marketsAgentsLocationAction} from 'containers/Clients/actions/marketsAction'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import {distinctUntilChanged, last, withLatestFrom} from 'rxjs/operators'
import {DEFAULT_PICK_PARAMS, isEqualSearch} from 'helpers/isEquals'
import {getListParams} from 'helpers/get'
import {interval} from 'rxjs'
import {appendParamsToUrl} from 'helpers/url'
import {withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const marketsAgentsLocation = prop('marketsAgentsLocation', state)
    return {
      marketsAgentsLocation
    }
  }, {
    marketsAgentsLocationAction
  }),
  mapPropsStream(props$ => {
    const source = interval(30000)

    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(DEFAULT_PICK_PARAMS),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(
        props => props.marketsAgentsLocationAction(getListParams(props, DEFAULT_PICK_PARAMS), props)
      )

    const timer = source
      .pipe(
        withLatestFrom(props$)
      )
      .subscribe(
        ([index, props]) => props.marketsAgentsLocationAction(getListParams(props, DEFAULT_PICK_PARAMS), props)
      )

    props$.pipe(last()).subscribe(props => timer.unsubscribe())

    return props$
  }),
  pure,
  withRedirect({
    key: 'goReturnsCreate',
    redirectUrl: ROUTES.MARKETS_RETURNS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goVisitsCreate',
    redirectUrl: ROUTES.MARKETS_VISITS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrdersCreate',
    redirectUrl: ROUTES.MARKETS_ORDERS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goPaymentsCreate',
    redirectUrl: ROUTES.MARKETS_PAYMENTS_CREATE_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleAgentId: props => (agentId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({agentId}, path(['history', 'location', 'search'], props))
      })
    },
    handleMarketId: props => (marketId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({marketId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(TrackGrid)
