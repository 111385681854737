import {sprintf} from 'sprintf-js'
import {path, prop} from 'ramda'
import axios, {getPayloadFromError, getPayloadFromSuccess} from '../../../helpers/axios'
import * as API from '../../../constants/api'
import * as actionTypes from '../../../constants/actionTypes'

// Order transfer outcome
export const acceptOrderStockTransferAction = ({id, type, ...values}) => {
  const requestData = (type === 'transfer') ? {
    stock_transfer: prop('transfer_id', values),
    date: prop('date', values)
  } : {
    ...values
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(type === 'transfer' ? API.STOCKS_INCOME_ACCEPT_TRANSFER : sprintf(API.STOCKS_ACCEPT_OUTCOME, id), requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_ACCEPT_OUTCOME,
      payload
    })
  }
}

// Supply
export const acceptSupplyProductIncomeAction = ({id, ...values}) => {
  const requestData = {
    ...values
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.STOCKS_INCOME_ACCEPT_PRODUCTS, id), requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_ACCEPT_OUTCOME,
      payload
    })
  }
}

// Supply
export const stocksAcceptAction = ({id, type, ...values}) => {
  const requestData = (() => {
    switch (type) {
    case 'order_return': return {date: prop('date', values), order_return: id}
    case 'transfer': return {date: prop('date', values), status: 'accepted'}
    case 'shipment': return {accepted_time: prop('date', values)}
    default: return {}
    }
  })()

  const api = (() => {
    switch (type) {
    case 'order_return': return API.STOCKS_INCOME_ACCEPT_ORDER_RETURN
    case 'transfer': return sprintf(API.STOCKS_INCOME_TRANSFER, id)
    case 'shipment': return sprintf(API.MANUFACTURE_SHIPMENT_END, id)
    default: return null
    }
  })()

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(api, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_ACCEPT_OUTCOME,
      payload
    })
  }
}

// details -------------------------------------
export const stockOrdersDetailAction = (id) => {
  const defaultParams = {}
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_SALES_ORDERS_DETAIL, id), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_DETAILS,
      payload
    })
  }
}

export const stockSupplyDetailAction = (params, props) => {
  const id = path(['match', 'params', 'id'], props)
  const defaultParams = {}
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.SUPPLY_DETAIL, id), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_DETAILS,
      payload
    })
  }
}

export const stockTransferDetailAction = (id) => {
  const defaultParams = {}
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.STOCKS_OUTCOME_TRANSFER_DETAIL, id), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_DETAILS,
      payload
    })
  }
}

export const stockSupplyOrderReturnDetail = (id) => {
  const defaultParams = {}
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_RETURNS_ITEM, id), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_DETAILS,
      payload
    })
  }
}

export const shipmentReturnsDetail = (id) => {
  const defaultParams = {}
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MANUFACTURE_BATCHES_DETAIL, id), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_DETAILS,
      payload
    })
  }
}

export const stocksSelectionsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.STOCKS_SELECTIONS, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_SELECTIONS_CREATE,
      payload
    })
  }
}

export const stocksSalesOrdersAction = (params) => {
  const search = prop('search', params)

  const defaultParams = {
    status: 1,
    page_size: 1000,
    search
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKETS_SALES_ORDERS_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_SALES_ORDERS_LIST,
      payload
    })
  }
}

export const stocksSelectionsProductsListAction = (params, props) => {
  const selectionId = path(['match', 'params', 'selectionId'], props)

  const defaultParams = {
    page_size: 1000
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.STOCKS_SELECTIONS_PRODUCTS_LIST, selectionId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_SELECTIONS_PRODUCTS_LIST,
      payload
    })
  }
}

export const stocksSelectionsCreateClearAction = () => {
  return {
    type: `${actionTypes.STOCKS_SELECTIONS_CREATE}_CLEAR`
  }
}

export const stocksSelectionsCreateEndAction = ({id, ...values}) => {
  const requestData = {
    ...values
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.STOCKS_SELECTIONS_CREATE_END, id), requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_SELECTIONS_CREATE_END,
      payload
    })
  }
}
