import {map, mapObjIndexed, prop, propOr} from 'ramda'
import {compose, createEventHandler, mapPropsStream, pure} from 'recompose'
import {map as rxMap, withLatestFrom} from 'rxjs/operators'
import {addParamsRoute} from 'helpers/route'
import {getInitValuesFromHistory} from 'helpers/get'

const getVal = value => propOr(value, 'id', value)
const getIds = map(getVal)

const withFilter = params => {
  const {
    fields,
    mapValues = getIds,
    mapInitValues = mapObjIndexed(value => value)
  } = params

  return compose(
    mapPropsStream(props$ => {
      const {stream: onChange$, handler: onChange} = createEventHandler()

      onChange$
        .pipe(withLatestFrom(props$))
        .subscribe(([values, {history}]) => {
          addParamsRoute({...mapValues(values)}, history)
        })

      return props$.pipe(
        rxMap(props => {
          const history = prop('history', props)

          return {
            ...props,
            filterActions: {
              onChangeFilter: onChange,
              initialValues: mapInitValues(getInitValuesFromHistory(fields, history))
            }
          }
        })
      )
    }),
    pure
  )
}

export default withFilter
