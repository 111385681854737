import {compose} from 'recompose'
import MarketsStatisticsByProductsGrid from 'containers/Clients/Grid/StatisticsByProducts/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withFetchList, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import {marketsStatisticsByProductsAction} from 'containers/Clients/actions/marketsAction'

export default compose(
  withFetchList({
    stateName: STATES.MARKETS_STATISTICS_BY_PRODUCTS,
    action: marketsStatisticsByProductsAction,
    key: [STATES.MARKETS_STATISTICS_BY_PRODUCTS],
    pickParams: ['beginDate', 'endDate', 'search', 'user']
  }),
  withFilter({
    fields: ['beginDate', 'endDate', 'search', 'user']
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goBackStats',
    redirectUrl: ROUTES.STATISTICS_MAIN_URL,
    withCurrentParams: true
  })
)(MarketsStatisticsByProductsGrid)
