import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${({styles}) => styles};
`
const Text = styled('span')`
  display: flex;
  align-items: center;
  letter-spacing: -0.024em;
`
const It = styled(Text)`
  margin-right: 10px;
  max-width: 65%;
  font-size: 17px;
  line-height: 22px;
  color: ${({theme}) => theme.palette.grey};
`
const Is = styled(Text)`
  max-width: 65%;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.024em;
  justify-content: flex-end;
  text-align: right;
  color: ${({theme}) => theme.palette.black};
`
const Nodata = styled('span')`
  color: #8f8e94;
`

const CardDescription = (props) => {
  const {
    it,
    is,
    styles,
    itStyle,
    isStyle,
    onClick
  } = props
  return (
    <Wrap
      onClick={onClick}
      styles={styles}
    >
      <It style={itStyle}>{it || <Nodata>Нет данных</Nodata>}</It>
      <Is style={isStyle}>{is || <Nodata>Нет данных</Nodata>}</Is>
    </Wrap>
  )
}

CardDescription.propTypes = {
  it: PropTypes.any,
  is: PropTypes.any,
  onClick: PropTypes.func
}

export default CardDescription
