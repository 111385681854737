import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  productsCreateAction
} from 'containers/Products/actions/productsActions'

export default compose(
  withCreate({
    stateName: STATES.PRODUCTS_CREATE,
    action: productsCreateAction,
    key: STATES.PRODUCTS_CREATE,
    redirectToCreated: ROUTES.PRODUCTS_URL,
    successMessage: 'Продукт создан'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTS_URL
  })
)(Grid)
