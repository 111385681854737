import {compose} from 'recompose'
import {prop} from 'ramda'
import {withFilter, withRedirect} from 'hocs'
import {connect} from 'react-redux'
import ChecksGrid from './Grid'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const posChecks = prop('posChecks', state)
    const config = prop('config', state)
    return {
      posChecks,
      config
    }
  }),
  withFilter({
    fields: ['search', 'beginDate', 'endDate']
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.CHECKS_DETAIL_URL,
    withCurrentParams: false
  })
)(ChecksGrid)
