import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import moment from 'moment'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import {prop} from 'ramda'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
`
const Date = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const RightBar = styled(Bar)`
  align-items: flex-end;
`
const Price = styled('h4')`
  color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : theme.palette.black
};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%) ${({eq}) => eq ? 'rotate(270deg)' : 'rotate(90deg)'};
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const Detail = styled('li')`
  padding: 12px 0;
  margin: 0 16px;
  display: ${({eq}) => eq ? 'block' : 'none'};
  transition: ${({theme}) => theme.transition.primary};
  border-bottom: ${({theme}) => theme.border.primary};
  & > *:not(:last-child){
    margin-bottom: 5px;
  }
`

const PointsItem = props => {
  const {
    onClick,
    name,
    points,
    date,
    setQuery,
    pointId,
    searchObj,
    children
  } = props

  const pointIdQuery = prop('pointId', searchObj)
  // console.warn(pointId)

  const eq = (+pointIdQuery === +pointId)

  const handleClick = () => {
    !eq ? setQuery(pointId) : setQuery('')
    onClick && onClick()
  }

  return (
    <>
      <TileItem
        onClick={handleClick}
        styles={styles}
      >
        <LeftBar>
          <h3>{name || 'Не определён'}</h3>
        </LeftBar>
        <RightBar>
          <Price
            status={points < 0 ? 'inactive' : 'active'}
          >{numberFormat(points, 'Баллов')}
          </Price>
          <Date>
            {date
              ? moment(date).format('DD MMM YYYY')
              : 'Нет даты'}
          </Date>
        </RightBar>
        <ArrowWrap
          eq={eq}
        >
          <Arrow />
        </ArrowWrap>
      </TileItem>
      <Detail
        eq={eq}
      >
        {children}
      </Detail>
    </>
  )
}

PointsItem.propTypes = {
  onClick: PropTypes.func,
  setQuery: PropTypes.func,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  points: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  pointId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  date: PropTypes.string,
  searchObj: PropTypes.object
}

export default PointsItem
