import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {BasicBar} from 'components/Navigation/TopBar/BasicBar'

const TopBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  height: 60px;
`
const BottomBar = styled('div')`
  height: ${({open, secondHeight}) => open ? `${secondHeight}px` : '0'};
  // transition: {({theme}) => theme.transition.primary};
  padding: ${({open}) => open ? '0 15px 10px' : '0 15px'};
  overflow: hidden;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const Text = styled('h3')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  position: absolute;
  height: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({open}) => open ? 0 : 1};
  transition: opacity .3s ease-out;
`
const SecondText = styled('h1')`
  opacity: ${({open}) => open ? 1 : 0};
  transition: opacity .3s ease-out;
`
const Btn = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 54px;
  height: 60px;
  & > svg{
    color: #000;
  }
`
const Left = styled(Btn)`
  left: 0;
`
const Right = styled(Btn)`
  right: 0;
`

const MainBar = (props) => {
  const {
    title,
    secondTitle,
    firstBtn,
    lastBtn,
    secondContent,
    secondHeight
  } = props
  const [open, setOpen] = useState(true)

  useEffect(() => {
    const listener = () => {
      if (window.scrollY >= (secondHeight || 100)) {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [secondHeight])

  return (
    <BasicBar>
      <TopBar>
        <Left>
          {firstBtn}
        </Left>
        <Text
          open={secondHeight && open}
        >
          {secondTitle || title}
        </Text>
        <Right>
          {lastBtn}
        </Right>
      </TopBar>
      <BottomBar
        open={secondHeight ? open : false}
        secondHeight={secondHeight}
      >
        <SecondText
          open={open}
        >
          {title}
        </SecondText>
        {secondContent}
      </BottomBar>
    </BasicBar>
  )
}

MainBar.propTypes = {
  title: PropTypes.any,
  secondTitle: PropTypes.any,
  firstBtn: PropTypes.any,
  lastBtn: PropTypes.any,
  secondHeight: PropTypes.number,
  secondContent: PropTypes.any
}

export default MainBar
