import React from 'react'
import {Field} from 'react-final-form'
import TextField from '../../../../../components/Forms/TextField'
import styled from 'styled-components'
import ModalFullScreen from '../../../../../components/Modal/ModalFullScreen'
import {head, path, prop} from 'ramda'
import {CardTitleBetween} from '../../../../../components/Cards/CardTitleBetween'
import UniversalSearchField
  from 'components/Forms/SearchField/UniversalSearchField/UniversalSearchField'
import numberFormat from '../../../../../helpers/numberFormat'
import toNumber from '../../../../../helpers/toNumber'
import {ColorPrice} from '../../../../../components/Helpers/ColorPrice'
import * as API from '../../../../../constants/api'
import {CURRENCY_BACKEND} from '../../../../../constants/constants'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const Title = styled('div')`
  display:flex;
  flex-direction: column;
  width: 100%;
`

const SpanEnd = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  line-height: 100%;
  & svg{
    padding-top: 3px;
  }
  & > *:not(:last-child){
    margin-right: 3px;
  }
`

const AcceptCashAllClientTransactionsDialog = (props) => {
  const {allTransactionDialog, data, query} = props
  const list = prop('data', data) || []
  const loading = prop('loading', data)
  const firstElement = head(list)
  const sum = prop('sum', query)
  const currencyId = toNumber(prop('real_currency', query))
  const currency = prop([currencyId], CURRENCY_BACKEND)
  const userName = path(['user', 'firstName'], firstElement) + path(['user', 'secondName'], firstElement)

  const titleContent =
    <Title>
      <CardTitleBetween>
        <span>Агент</span>
        <SpanEnd>
          <span>{userName}</span>
        </SpanEnd>
      </CardTitleBetween>
      <CardTitleBetween style={{marginTop: '7px'}}>
        <span>Сумма</span>
        <SpanEnd>
          <ColorPrice number={toNumber(sum)}>{numberFormat(sum)} {currency}</ColorPrice>
        </SpanEnd>
      </CardTitleBetween>
    </Title>

  return (
    <ModalFullScreen
      open={allTransactionDialog.open}
      handleDialog={allTransactionDialog.onOpen}
      handleSubmit={allTransactionDialog.onSubmit}
      topTitle={'Принять все наличные'}
      title={titleContent}
      loading={loading}
    >
      <StyledWrap>
        <Field
          component={UniversalSearchField}
          api={API.FINANCES_CASHIERS_LIST}
          params={{currency: 5, type: 'cash', page_size: '1000'}}
          type={'number'}
          name={'cashbox'}
          placeholder={'Кассы'}
          required
          fullWidth
        />
        <Field
          component={TextField}
          name={'date'}
          type={'date'}
          placeholder={'Дата'}
          required
          fullWidth
        />
        <Field
          component={TextField}
          name={'time'}
          type={'time'}
          placeholder={'Время'}
          required
          fullWidth
        />
      </StyledWrap>
    </ModalFullScreen>
  )
}

export default AcceptCashAllClientTransactionsDialog
