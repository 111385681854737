import React, {useState} from 'react'
import {PhotoBar} from 'components/Navigation/TopBar'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import Container from 'components/Container/Container'
import Button from 'components/Forms/Button'
import styled from 'styled-components'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import {path, prop, propOr} from 'ramda'
import ModalConfirm from 'components/Modal/ModalConfirm'
import moment from 'moment'
import MapPinIcon from 'components/SvgIcons/CustomIcons/MapPinIcon'
import mapIndexed from 'helpers/mapIndexed'
import Perms from '../../../../../components/Perms/Perms'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  min-height: ${({secondHeight}) => secondHeight && `calc(100vh - ${secondHeight + 60}px)`};
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const ButtonWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  & > *:not(:last-child){
    margin-right: 10px;
  }
`
const Href = styled('span')`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  svg{
    font-size: 24px;
    margin-right: 1px;
  }
`

const BranchesDetailGrid = props => {
  const {
    branchesDetail,
    branchesDelete,
    match,
    goBack,
    goMap,
    goEdit
  } = props

  // useState
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Location
  const branchId = path(['params', 'id'], match)

  // Data
  const branchesDetailData = prop('data', branchesDetail)
  const branchesDetailLoading = prop('loading', branchesDetail)
  const branchesDeleteLoading = prop('loading', branchesDelete)

  // Const
  const branchName = prop('name', branchesDetailData)
  const address = prop('address', branchesDetailData)
  const createdDate = prop('createdDate', branchesDetailData)
  const stockManagerFN = path(['stockManager', 'firstName'], branchesDetailData)
  const stockManagerSN = path(['stockManager', 'secondName'], branchesDetailData)
  const stockManager = stockManagerFN + ' ' + stockManagerSN
  const coverPhoto = path(['image', 'file'], branchesDetailData)
  const comment = prop('comment', branchesDetailData)
  const guide = prop('guide', branchesDetailData)
  const phones = propOr([], 'phones', branchesDetailData)

  // SecondHeight
  const secondHeight = (!branchesDetailLoading && coverPhoto) ? 200 : 50

  // Description
  const descriptionContent =
    <TilesList
      title={'Описание'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Адрес'} is={address} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Заведующий'} is={stockManager} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Описание'} is={comment} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Ориентир'} is={guide} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Дата создания'} is={createdDate && moment(createdDate).format('DD MMM YYYY HH:mm')} />
      </TileItem>
      {mapIndexed((item, index) => {
        return (
          <TileItem
            height={'auto'}
            key={index}
          >
            <CardDescription it={'Телефон'} is={prop('phone', item)} />
          </TileItem>
        )
      }, phones)}
    </TilesList>

  // Buttons
  const buttons =
    <ButtonWrap>
      <Perms
        perms={['change_stock']}
        onClick={() => goEdit(branchId)}
      >
        <Button
          text={'Редактировать'}
          fullWidth
        />
      </Perms>
      <Perms
        perms={['delete_stock']}
        onClick={() => setConfirmModalOpen(true)}
      >
        <Button
          text={'Удалить'}
          background={'#e66767'}
          fullWidth
        />
      </Perms>
    </ButtonWrap>

  const mapIcon =
    <Href>
      <MapPinIcon />
    </Href>

  const handleGoMap = () => {
    goMap(+branchId)
  }

  const navButtons = [
    {
      icon: mapIcon,
      withAlert: true,
      perms: ['add_market'],
      onClick: handleGoMap
    }
  ]

  // MainContent
  const mainContent =
    <>
      {descriptionContent}
      {buttons}
    </>

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        onClick={() => branchesDelete.onSubmit({id: branchId})}
        loading={branchesDeleteLoading}
        type={'delete'}
      />
    </>

  // Render
  return (
    <>
      <PhotoBar
        title={(!branchesDetailLoading && branchName) || 'Филиал'}
        cover={!branchesDetailLoading ? coverPhoto : undefined}
        secondHeight={secondHeight}
        goBack={() => goBack()}
        buttons={navButtons}
      />
      <Container
        secondHeight={secondHeight}
      >
        <Wrap
          secondHeight={secondHeight}
        >
          {branchesDetailLoading
            ? <Loader />
            : mainContent || <NoResults text={'Нет данных'} />}
        </Wrap>
      </Container>
      {modals}
    </>
  )
}

export default BranchesDetailGrid
