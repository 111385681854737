import {compose} from 'recompose'
import StocksOutcomeGrid from './Grid'
import {prop} from 'ramda'
import {withFilter} from 'hocs'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const customStore = prop('customStore', state)
    const stocksOutcomeTasks = prop('stocksOutcomeTasks', state)
    return {
      customStore,
      stocksOutcomeTasks
    }
  }),
  withFilter({
    fields: ['search', 'stock', 'type', 'beginDate', 'endDate', 'withHistory', 'ordering']
  }),
  withRedirect({
    key: 'goSelectionCreate',
    redirectUrl: ROUTES.STOCKS_SELECTIONS_CREATE_URL
  })
)(StocksOutcomeGrid)
