import React, {useState} from 'react'
import Container from 'components/Container'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import styled from 'styled-components'
import {flatten, isEmpty, map, max, prop, propOr} from 'ramda'
import moment from 'moment'
import {parseParams} from 'helpers/url'
import StatisticsFilters from 'containers/Statistics/containers/ByDebts/Grid/StatisticsByDebtsFilters'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import mapIndexed from 'helpers/mapIndexed'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import TilesList from 'components/Cards/Tile/TilesList'
import StatisticsDebtsItem from 'containers/Statistics/components/StatisticsDebtsItem/StatisticsDebtsItem'

const Div = styled('div')`
  position: fixed;
  height: 49px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e4e8;
  top: 61px;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${({theme}) => theme.nav.primary.background};
  & > *:not(:last-child) {
    border-right: ${({theme}) => theme.border.primary};
  }
`
const Wrap = styled('div')`
  display: flex;
  padding: 14px 16px;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const CustomButton = styled('div')`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({active}) => active ? 500 : 400};
  background: ${({active}) => active ? '#ffffff9e' : 'inherit'};
  cursor: pointer;
`
const GraphOptions = styled('li')`
  display: flex;
  flex-flow: column wrap;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.35px;
  color: #000;
  padding: 12px;
  &:not(:first-child){
    border-top: 1px solid #e0e4e8;
  }
`
const Option = styled('span')`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 18px;
  &:before{
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${props => props.color || '#ccc'};
    border-radius: 50%;
  }
`

const StatisticsByDebtsGrid = (props) => {
  const {
    location,
    filterActions,
    handleSetPeriod,
    statisticsAgentsDebts
  } = props

  // useStates
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // Data
  const statisticsAgentsDebtsData = propOr([], 'data', statisticsAgentsDebts)
  const statisticsAgentsDebtsLoading = prop('loading', statisticsAgentsDebts)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const monday = moment().clone().weekday(0).format('YYYY-MM-DD')
  const sunday = moment().clone().weekday(6).format('YYYY-MM-DD')
  const beginDate = prop('beginDate', searchObj) || monday
  const endDate = prop('endDate', searchObj) || sunday
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const isThisWeek = (beginDate === monday) && (endDate === sunday)
  const isMonth = (beginDate === firstDayOfMonth) && (endDate === lastDayOfMonth)

  // Initial values
  filterActions.initialValues = {
    beginDate,
    endDate
  }

  // Period
  const period =
    <>
      <CustomButton
        active={isThisWeek}
        onClick={() => handleSetPeriod(monday, sunday)}
      >
        За неделю
      </CustomButton>
      <CustomButton
        active={isMonth}
        onClick={() => handleSetPeriod(firstDayOfMonth, lastDayOfMonth)}
      >
        За месяц
      </CustomButton>
      <CustomButton
        active={!isThisWeek && !isMonth}
        onClick={() => setFilterIsOpen(true)}
      >
        Своя дата
      </CustomButton>
    </>

  const mappedProgress = map(item => [prop('startDebts', item), prop('endDebts', item)], statisticsAgentsDebtsData)
  const arrProgress = flatten(mappedProgress)
  const maxProgress = max(...arrProgress)

  // GraphInfo
  const graphInfo =
    <TilesList>
      <GraphOptions>
        <Option color={'#e66767'}>Начало периода</Option>
        <Option color={'#fbb432'}>Конец периода</Option>
      </GraphOptions>
    </TilesList>

  // GraphList
  const graphList = mapIndexed((item) => {
    const id = prop('id', item)
    const agent = prop('fullName', item)
    const startDebts = prop('startDebts', item)
    const endDebts = prop('endDebts', item)

    return (
      <StatisticsDebtsItem
        key={id}
        name={agent}
        startDebts={startDebts}
        endDebts={endDebts}
        maxProgress={maxProgress}
      />
    )
  }, statisticsAgentsDebtsData)

  // Main content
  const mainContent =
    <>
      {graphInfo}
      {graphList}
    </>

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
      initialValues={filterActions.initialValues}
    >
      <StatisticsFilters
        setFilterIsOpen={setFilterIsOpen}
        searchObj={searchObj}
        loading={statisticsAgentsDebtsLoading}
        {...filterActions}
      />
    </FiltersBar>

  // Render
  return (
    <>
      <MainBar
        title={'Статистика долгов'}
        firstBtn={
          <MainMenu />
        }
        lastBtn={filters}
      />
      <Container
        secondHeight={48}
      >
        <Div>
          {period}
        </Div>
        <Wrap>
          {statisticsAgentsDebtsLoading
            ? <Loader />
            : !isEmpty(statisticsAgentsDebtsData)
              ? mainContent
              : <NoResults text={'Нет данных за этот период'} />}
        </Wrap>
      </Container>
    </>
  )
}

export default StatisticsByDebtsGrid
