import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 19 31'} {...props}>
      <circle cx="15.5" cy="3.5" r="3.5" transform="rotate(90 15.5 3.5)" fill="#FCC671" />
      <circle cx="15.5" cy="15.5" r="3.5" transform="rotate(90 15.5 15.5)" fill="#FCC671" />
      <circle cx="3.5" cy="3.5" r="3.5" transform="rotate(90 3.5 3.5)" fill="#6770E6" />
      <circle cx="3.5" cy="15.5" r="3.5" transform="rotate(90 3.5 15.5)" fill="#6770E6" />
      <circle cx="3.5" cy="27.5" r="3.5" transform="rotate(90 3.5 27.5)" fill="#6770E6" />
    </SvgIcon>

  )
}
