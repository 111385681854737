import {compose} from 'recompose'
import ChecksDetailGrid from './Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import * as ROUTES from 'constants/routes'
import {checksDetailAction} from 'containers/Checks/actions/checksActions'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const config = prop('config', state)
    return {
      config
    }
  }),
  withFetchList({
    stateName: STATES.POS_CHECKS_DETAIL,
    action: checksDetailAction,
    key: [STATES.POS_CHECKS_DETAIL]
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CHECKS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goDebtsDetail',
    redirectUrl: ROUTES.DEBTS_DETAIL_URL,
    withCurrentParams: true
  })
)(ChecksDetailGrid)
