import React from 'react'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button from 'components/Forms/Button'
import Label from 'components/Forms/Label'
import Switcher from 'components/Forms/Switcher'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import {prop} from 'ramda'
import BottomBar from 'components/Navigation/BottomBar'

const Wrap = styled('div')`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
  button{
    margin-top: 10px;
  }
`

const SettingsFormFields = props => {
  const {
    values,
    loading
  } = props

  // Values
  const deliveryPriceTypeValue = prop('deliveryPriceType', values)
  const langRuValue = prop('lang_ru', values)
  const langUzValue = prop('lang_uz', values)
  const langUzKValue = prop('lang_uz_k', values)
  const langKkValue = prop('lang_kk', values)
  const langEnValue = prop('lang_en', values)

  // Options
  const deliveryPriceTypeOptions = [
    {value: 'fixed', label: 'Статично'},
    {value: 'custom', label: 'По километражу'}
  ]

  // Fields
  const deliveryPrice =
    <Field
      component={TextField}
      name={'deliveryPrice'}
      type={'number'}
      label={'Стоимость доставки за 1 км'}
      fullWidth
    />
  const fixedDeliveryPrice =
    <Field
      component={TextField}
      name={'fixedDeliveryPrice'}
      type={'number'}
      label={'Стоимость доставки'}
      fullWidth
    />

  // Render
  return (
    <>
      <TilesList>
        <TileItem>
          <Wrap>
            <Label>
              Типы оплаты
            </Label>
            <Field
              component={Switcher}
              name={'canCash'}
              label={'Наличные'}
              type={'checkbox'}
            />
            <Field
              component={Switcher}
              name={'canTerminal'}
              label={'Карта'}
              type={'checkbox'}
            />
            <Label>
              Тип доставки
            </Label>
            <Field
              component={Switcher}
              name={'canDelivery'}
              label={'Доставка'}
              type={'checkbox'}
            />
            <Field
              component={Switcher}
              name={'canSelf'}
              label={'Самовывоз'}
              type={'checkbox'}
            />
            <Field
              component={StaticSearchField}
              name={'deliveryPriceType'}
              label={'Тип расчёта стоимости доставки'}
              type={'select'}
              options={deliveryPriceTypeOptions}
              fullWidth
            />
            {deliveryPriceTypeValue === 'fixed'
              ? fixedDeliveryPrice
              : deliveryPrice}
            <Field
              component={TextField}
              name={'orderChannelId'}
              label={'ID канала'}
              fullWidth
            />
            <Field
              component={TextField}
              name={'subtext'}
              label={'Примечание к заказу для пользователей'}
              fullWidth
            />
            <Field
              component={Switcher}
              name={'canSendOrderNotification'}
              label={'Оповещать пользователя при изменении статуса заказа'}
              type={'checkbox'}
              fullWidth
            />
            <Label>
              Языки бота
            </Label>
            <Field
              component={Switcher}
              name={'lang_ru'}
              label={'Русский'}
              type={'checkbox'}
            />
            <Field
              component={Switcher}
              name={'lang_uz'}
              label={'Узбекский'}
              type={'checkbox'}
            />
            <Field
              component={Switcher}
              name={'lang_uz_k'}
              label={'Узбекский (Кирилица)'}
              type={'checkbox'}
            />
            <Field
              component={Switcher}
              name={'lang_kk'}
              label={'Каракалпакский'}
              type={'checkbox'}
            />
            <Field
              component={Switcher}
              name={'lang_en'}
              label={'Английский'}
              type={'checkbox'}
            />
            {langRuValue &&
            <Field
              component={TextField}
              name={'ruAboutText'}
              label={'Описание на русском языке'}
              fullWidth
            />}
            {langUzValue &&
            <Field
              component={TextField}
              name={'uzAboutText'}
              label={'Описание на узбекском языке'}
              fullWidth
            />}
            {langUzKValue &&
            <Field
              component={TextField}
              name={'uzKAboutText'}
              label={'Описание на узбекском языке (Кирилица)'}
              fullWidth
            />}
            {langKkValue &&
            <Field
              component={TextField}
              name={'kkAboutText'}
              label={'Описание на каракалпакском языке'}
              fullWidth
            />}
            {langEnValue &&
            <Field
              component={TextField}
              name={'enAboutText'}
              label={'Описание на английском языке'}
              fullWidth
            />}
          </Wrap>
        </TileItem>
      </TilesList>
      <BottomBar>
        <Button
          text={'Сохранить'}
          disabled={loading}
          fullWidth
        />
      </BottomBar>
    </>
  )
}

export default SettingsFormFields
