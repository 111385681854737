import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {prop} from 'ramda'

const Wrap = styled('div')`
  ${({style}) => style};
`
const SubTitle = styled('h3')`
  color: ${({theme}) => theme.text.secondary};
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
`
const Title = styled('h2')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px;
  position: relative;
  padding-right: ${({button}) => button ? '30px' : '0'};
  ${({stylesTitle}) => stylesTitle};
  svg{
    position: absolute;
    top: 2px;
    right: 0;
    max-height: 24px;
    max-width: 24px;
  }
`
const TextButton = styled('b')`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.35px;
  display: flex;
  align-items: flex-end;
  color: ${({theme, color}) => color || theme.palette.blue};
`
const List = styled('ul')`
  overflow: hidden;
  background: ${({theme, background}) => background || theme.palette.white};
  border-radius: ${({theme}) => theme.borderRadius.primary};
`

const TilesList = props => {
  const {
    children,
    title,
    subTitle,
    style,
    background,
    button,
    textButton,
    stylesTitle,
    onClick
  } = props

  return (
    <Wrap
      style={style}
      onClick={onClick}
    >
      <SubTitle>
        {subTitle}
      </SubTitle>
      <Title
        button={button}
        stylesTitle={stylesTitle}
      >
        <span>
          {title}
        </span>
        {textButton &&
        <TextButton
          color={prop('color', textButton)}
          onClick={prop('onClick', textButton)}
        >
          {prop('text', textButton)}
        </TextButton>}
        {button}
      </Title>
      <List
        background={background}
      >
        {children}
      </List>
    </Wrap>
  )
}

TilesList.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  button: PropTypes.any,
  textButton: PropTypes.object
}

export default TilesList
