import React from 'react'
import {path, prop} from 'ramda'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import mapIndexed from 'helpers/mapIndexed'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {manufactureProductsListSerializer} from 'containers/Productions/actions/serializers/productsBatchesSerializers'

const ProductionsBatchesCreateGrid = props => {
  const {
    manufactureProductsList,
    goExit,
    goBack,
    goNext,
    match
  } = props

  // Redirects
  const matchId = path(['params', 'id'], match)

  // Data
  const manufactureProductsListData = prop('data', manufactureProductsList)

  // MainList
  const mainList = manufactureProductsListData && mapIndexed((item) => {
    const name = prop('name', item)
    const id = prop('id', item)
    const typeName = path(['type', 'name'], item)

    return (
      <ProductsItem
        name={name}
        price={typeName}
        key={id}
        withOutArrow
        onClick={() => goNext(matchId, id)}
      />
    )
  }, manufactureProductsListData)

  // MainContent
  const mainContent =
    <ul>
      <InfiniteScroll
        api={API.MANUFACTURE_PRODUCTS_LIST}
        actionType={actionTypes.MANUFACTURE_PRODUCTS_LIST}
        emptyData={<li><NoResults /></li>}
        params={manufactureProductsListSerializer(props)}
        useWindow
        list={mainList || []}
      />
    </ul>

  // Render
  return (
    <>
      <CreateBar
        title={'Товары'}
        goBack={() => goBack()}
        goExit={() => goExit()}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default ProductionsBatchesCreateGrid
