import React from 'react'
import styled from 'styled-components'
import Snackbar from '../components/Snackbar/Snackbar'
import ModalError from '../components/Modal/ModalError'

const Container = styled('div')`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
`

const Layout = (props) => {
  const {children} = props

  return (
    <Container>
      {children}
      <Snackbar />
      <ModalError />
    </Container>
  )
}

export default Layout
