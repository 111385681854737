import {compose} from 'recompose'
import ProductionsBatchesEditGrid from 'containers/Productions/containers/Batches/Grid/Edit/Grid'
import {withCreate, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  manufactureBatchesDeleteAction,
  manufactureBatchesEditAction
} from 'containers/Productions/actions/ProductionsBatchesAction'

export default compose(
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_EDIT,
    action: manufactureBatchesEditAction,
    key: STATES.MANUFACTURE_BATCHES_EDIT,
    successMessage: 'Изменения применены',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_DETAIL_URL,
    propFromParams: 'batchesId'
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_DELETE,
    action: manufactureBatchesDeleteAction,
    key: STATES.MANUFACTURE_BATCHES_DELETE,
    successMessage: 'Удалено',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_URL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_DETAIL_URL
  })
)(ProductionsBatchesEditGrid)
