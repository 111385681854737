import React from 'react'
import {Field} from 'react-final-form'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField'
import {prop} from 'ramda'
import moment from 'moment'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = () => {
  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'date'}
        name={'beginDate'}
        label={'Период с'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDate'}
        label={'Период до'}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    onChangeFilter,
    setFilterIsOpen,
    initialValues
  } = props

  const handleSubmit = (values) => {
    onChangeFilter({
      beginDate: moment(prop('beginDate', values)).format('YYYY-MM-DD'),
      endDate: moment(prop('endDate', values)).format('YYYY-MM-DD')
    })
    setFilterIsOpen(false)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormFields />
    </Form>
  )
}

export default Filter
