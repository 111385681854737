import React from 'react'
import styled from 'styled-components'
import {path, prop, pipe, filter} from 'ramda'
import PropTypes from 'prop-types'
import Perms from '../../Perms/Perms'
import mapIndexed from '../../../helpers/mapIndexed'

const BGMusk = styled('div')`
  background: ${({theme}) => theme.background.mask};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  z-index: 900;
`
const MenuContent = styled('div')`
  background: transparent;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all .2s ease-out;
  transform: translateY(${({isOpen}) => isOpen ? '0' : '100%'});
  width: calc(100% - 20px);
  z-index: 1000;
  margin: 0 8px;
  text-align: center;
`
const CloseMenu = styled('span')`
  flex-grow: 1;
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 19px;
  background-color: ${({theme}) => theme.palette.white};
  color: ${({theme}) => theme.palette.blue};
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  padding: 17px;
  letter-spacing: -0.408px;
  transition: ${props => props.theme.transition.primary};
  border: none !important;
  box-shadow: ${({theme}) => theme.boxShadow.primary};
  &:hover {
    background-color: #eee;
  }
`
const MenuItemLink = styled('div')`
  background-color: rgba(249,249,249,0.95);
  color: ${({theme}) => theme.palette.blue};
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  transition: ${props => props.theme.transition.primary};
  padding: 17px;
  &:hover {
    background-color: #eee;
  }
`
const MenuList = styled('div')`
  flex-grow: 1;
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 8px;
  border: none !important;
  & > * {
    position: relative;
    &:not(:last-child){
      &:after{
        background: rgba(17,17,17,0.5);
        opacity: 0.5;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
      }
    }
  }
`
const MenuHead = styled('div')`
  background-color: rgba(249,249,249,0.95);
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.078px;
  color: #8f8f8f;
  padding: 14px 9px;
`

const SecondMenu = (props) => {
  const {
    button,
    menusArr,
    title,
    isOpen,
    setIsOpen,
    loading
  } = props

  // useStates
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)

  const onMenuOpen = () => {
    if (!loading) {
      setMenuIsOpen(true)
      isOpen && setIsOpen(true)
    } else {
      // eslint-disable-next-line no-console
      console.log('Please wait')
    }
  }
  const onMenuClose = () => {
    setMenuIsOpen(false)
    isOpen && setIsOpen(false)
  }

  React.useEffect(() => {
    if (menuIsOpen || isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [menuIsOpen, isOpen])

  return (
    <>
      {button &&
      <div onClick={onMenuOpen}>
        {button}
      </div>}
      <BGMusk isOpen={menuIsOpen || isOpen} onClick={onMenuClose} />
      <MenuContent isOpen={menuIsOpen || isOpen}>
        <MenuList>
          {title &&
          <MenuHead>
            {title}
          </MenuHead>}
          {pipe(
            filter((item) => {
              return prop('title', item) || path(['props', 'text'], item)
            }),
            mapIndexed((item, index) => {
              const title = prop('title', item) || path(['props', 'text'], item)
              const onClick = prop('onClick', item) || path(['props', 'onClick'], item)
              const perms = prop('perms', item)
              const withAlert = prop('withAlert', item)
              const i =
                <MenuItemLink onClick={!perms && onClick} key={index}>
                  {title}
                </MenuItemLink>
              if (perms) {
                return (
                  <Perms perms={perms} withAlert={withAlert} onClick={onClick} key={index}>
                    {i}
                  </Perms>
                )
              }
              return i
            })
          )(menusArr)}
        </MenuList>
        <CloseMenu onClick={onMenuClose}>Отмена</CloseMenu>
      </MenuContent>
    </>
  )
}

SecondMenu.propTypes = {
  menusArr: PropTypes.array.isRequired,
  button: PropTypes.any,
  isOpen: PropTypes.bool,
  loading: PropTypes.number,
  setIsOpen: PropTypes.func,
  title: PropTypes.any
}

export default SecondMenu
