import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {
  marketsReportsDeleteAction,
  marketsReportsDetailAction
} from 'containers/Clients/actions/marketsAction'
import * as ROUTES from 'constants/routes'
import {appendParamsToUrl} from 'helpers/url'
import {path} from 'ramda'

export default compose(
  withFetchList({
    stateName: STATES.MARKETS_REPORTS_DETAIL,
    action: marketsReportsDetailAction,
    key: STATES.MARKETS_REPORTS_DETAIL
  }),
  withCreate({
    stateName: STATES.MARKETS_REPORTS_DELETE,
    action: marketsReportsDeleteAction,
    key: STATES.MARKETS_REPORTS_DELETE,
    successMessage: 'Фотоотчёт успешно удалён',
    redirectUrl: ROUTES.MARKETS_REPORTS_URL,
    propFromParams: 'id'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_REPORTS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.MARKETS_REPORTS_EDIT_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetSelectedImage: props => (imageViewer) => {
      props.history.push({
        search: appendParamsToUrl({imageViewer}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
