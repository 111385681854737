import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {prop} from 'ramda'
import {Check as CheckIcon} from 'react-feather'

const Label = styled('label')`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-size: 15px;
  color: ${({theme}) => theme.palette.black};
  border-bottom: 1px solid transparent;
  &:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
  input{
    display: none;
    &:checked + span{
      color: ${({theme}) => theme.palette.blue};
    }
    &:checked ~ b{
      display: flex;
    }
  }
  ${({styles}) => styles};
`
const Checked = styled('b')`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  svg{
    color: ${({theme}) => theme.palette.blue};
    stroke-width: 3px;
  }
`

const Radio = (props) => {
  const {
    label,
    input,
    onChange,
    value,
    styleLabel,
    styles,
    ...defaultProps
  } = props

  const [id, setId] = useState()

  useEffect(() => {
    setId(Math.random())
  }, [])

  return (
    <Label
      styles={styles}
      style={styleLabel}
      htmlFor={id}
    >
      <input
        {...input}
        {...defaultProps}
        onChange={(onChange || prop('onChange', input))}
        type={'radio'}
        id={id}
      />
      <span>{label}</span>
      <Checked>
        <CheckIcon size={20} />
      </Checked>
    </Label>
  )
}

export default Radio
