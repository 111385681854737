import {compose} from 'recompose'
import Grid from 'containers/Productions/containers/Batches/Grid'
import {withFilter} from 'hocs'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const manufactureBatchesList = prop('manufactureBatchesList', state)
    return {
      manufactureBatchesList
    }
  }),
  withFilter({
    fields: ['search']
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_CREATE_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_DETAIL_URL,
    withCurrentParams: false
  })
)(Grid)
