import {prop} from 'ramda'

export const stocksIncomeTasksSerializer = (params) => {
  const search = prop('search', params)
  const stock = prop('stock', params)
  const type = prop('type', params)
  const beginDate = prop('beginDate', params)
  const endDate = prop('endDate', params)
  const withHistory = prop('withHistory', params)
  const ordering = prop('ordering', params) || 'date'

  return {
    search,
    stock,
    type,
    begin_date: beginDate,
    end_date: endDate,
    show_all: withHistory,
    ordering
  }
}

export const stocksOutcomeTasksSerializer = (params) => {
  const search = prop('search', params)
  const stock = prop('stock', params)
  const type = prop('type', params)
  const beginDate = prop('beginDate', params)
  const endDate = prop('endDate', params)
  const withHistory = prop('withHistory', params)
  const ordering = prop('ordering', params) || 'date_request'

  return {
    search,
    stock,
    type,
    begin_date: beginDate,
    end_date: endDate,
    show_all: withHistory,
    ordering
  }
}

export const stocksRestsSerializer = (params) => {
  const search = prop('search', params)
  const stock = prop('stock', params)
  const measurement = prop('measurement', params)
  const type = prop('type', params)
  const subtype = prop('subtype', params)

  return {
    search,
    stock,
    measurement,
    type: subtype || type
  }
}

export const stocksSelectionsSerializer = (params) => {
  const search = prop('search', params)
  const stock = prop('stock', params)
  const beginDate = prop('beginDate', params)
  const endDate = prop('endDate', params)

  return {
    search,
    stock,
    begin_date: beginDate,
    end_date: endDate
  }
}
