import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'

export const carsListSerializer = (params) => {
  const search = prop('carsSearch', params)
  return {
    search: search
  }
}

export const carsModelsListSerializer = (params) => {
  const parent = prop('carsSearch', params)
  return {
    parent
  }
}

export const carsAddProductsSerializer = (params, props) => {
  const {
    config,
    location
  } = props

  const search = prop('search', location)
  const searchObj = parseParams(search)
  const configData = prop('data', config)
  const carsCurrencyId = prop('mOBCARSCURRENCYID', configData)
  const carsPriceListId = prop('mOBCARSPRICELISTID', configData)
  const searchQuery = prop('productsSearch', searchObj)

  const defaultParams = {
    currency: carsCurrencyId,
    price_list: carsPriceListId,
    search: searchQuery
  }
  return {...defaultParams}
}

export const carsAddServicesSerializer = (params, props) => {
  const {
    config,
    location
  } = props

  const search = prop('search', location)
  const searchObj = parseParams(search)
  const configData = prop('data', config)
  const carsCurrencyId = prop('mOBCARSCURRENCYID', configData)
  const carsPriceListId = prop('mOBCARSPRICELISTID', configData)
  const searchQuery = prop('productsSearch', searchObj)

  const defaultParams = {
    currency: carsCurrencyId,
    price_list: carsPriceListId,
    search: searchQuery
  }
  return {...defaultParams}
}

export const carsOrdersListSerializer = (params, props) => {
  const {
    match
  } = props

  const carId = path(['params', 'id'], match)

  const defaultParams = {
    car: carId
  }
  return {...defaultParams}
}
