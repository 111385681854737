import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  productsDetailAction,
  productsEditAction
} from 'containers/Products/actions/productsActions'

export default compose(
  withCreate({
    stateName: STATES.PRODUCTS_EDIT,
    action: productsEditAction,
    key: STATES.PRODUCTS_EDIT,
    redirectToCreated: ROUTES.PRODUCTS_URL,
    successMessage: 'Продукт создан'
  }),
  withFetchList({
    stateName: STATES.PRODUCTS_DETAIL,
    action: productsDetailAction,
    key: STATES.PRODUCTS_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTS_DETAIL_URL
  })
)(Grid)
