import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <path d="M17.6562 2.89062H2.34375C1.04935 2.89062 0 3.93997 0 5.23438V5.44266H20V5.23438C20 3.93997 18.9507 2.89062 17.6562 2.89062Z" />
      <path d="M0 14.8276C0 16.122 1.04935 17.1713 2.34375 17.1713H17.6562C18.9507 17.1713 20 16.122 20 14.8276V7.78641H0V14.8276ZM14.3933 11.6771H16.2761C17.2913 11.6771 18.1172 12.5031 18.1172 13.5182C18.1172 14.5334 17.2913 15.3593 16.2761 15.3593H14.3933C13.378 15.3593 12.5522 14.5334 12.5522 13.5182C12.5522 12.5031 13.378 11.6771 14.3933 11.6771ZM2.46872 11.6771H6.23444C6.55792 11.6771 6.82037 11.9394 6.82037 12.263C6.82037 12.5867 6.55792 12.849 6.23444 12.849H2.46872C2.14508 12.849 1.88278 12.5867 1.88278 12.263C1.88278 11.9394 2.14508 11.6771 2.46872 11.6771ZM2.46872 14.1875H8.11722C8.44086 14.1875 8.70316 14.4498 8.70316 14.7734C8.70316 15.097 8.44086 15.3593 8.11722 15.3593H2.46872C2.14508 15.3593 1.88278 15.097 1.88278 14.7734C1.88278 14.4498 2.14508 14.1875 2.46872 14.1875Z" />
      <path d="M14.3932 14.1875H16.276C16.645 14.1875 16.9453 13.8873 16.9453 13.5182C16.9453 13.1493 16.645 12.849 16.276 12.849H14.3932C14.0241 12.849 13.724 13.1493 13.724 13.5182C13.724 13.8873 14.0241 14.1875 14.3932 14.1875Z" />
    </SvgIcon>

  )
}
