import {compose, mapProps, withHandlers, withState} from 'recompose'
import OrderDetailAddBonusGrid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import {connect} from 'react-redux'
import * as STATES from 'constants/states'
import {marketsSalesOrderCountAction, orderBonusCreateAction, orderBonusListAction} from '../../actions/marketsAction'

export default compose(
  connect((state, props) => ({
    customStore: prop('customStore', state),
    query: parseParams(path(['location', 'search'], props))
  })),
  withFetchList({
    stateName: STATES.BONUS_ORDER_LIST,
    action: orderBonusListAction,
    key: STATES.BONUS_ORDER_LIST
  }),
  withFetchList({
    stateName: STATES.BONUS_ORDER_COUNT,
    action: marketsSalesOrderCountAction,
    key: STATES.BONUS_ORDER_COUNT
  }),
  withCreate({
    stateName: STATES.ORDER_ITEM_ADD_BONUS,
    action: orderBonusCreateAction,
    propFromParams: 'id',
    secondPropFromParams: 'orderId',
    key: STATES.ORDER_ITEM_ADD_BONUS,
    redirectUrl: ROUTES.MARKETS_ORDERS_DETAIL_URL,
    successMessage: 'Успех'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_ORDERS_DETAIL_URL,
    newParams: {redirect: true}
  }),
  withState('modalOpen', 'setModalOpen', false),
  withState('confirmModalOpen', 'setConfirmModalOpen', false),
  withState('confirmBackModalOpen', 'setConfirmBackModalOpen', false),
  withHandlers({
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  mapProps(({
    orderItemAddBonus,
    bonusOrderList,
    bonusOrderCount,
    ...props
  }) => {
    const openConfirm = +prop('openConfirm', props.query) > 0
    const addBonusConfirmModal = {
      open: openConfirm,
      onSubmit: orderItemAddBonus.onSubmit,
      loading: orderItemAddBonus.loading
    }
    const list = {
      countLoading: bonusOrderCount.loading,
      countData: bonusOrderCount.data,
      data: bonusOrderList.data,
      loading: bonusOrderList.loading
    }
    return {
      addBonusConfirmModal,
      list,
      ...props
    }
  })
)(OrderDetailAddBonusGrid)
