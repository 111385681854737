import {compose} from 'recompose'
import Grid from './Grid'
import {withFilter, withRedirect} from 'hocs'
import {connect} from 'react-redux'
import {prop} from 'ramda'
import * as ROUTES from 'constants/routes'

export default compose(
  connect(state => {
    const productsList = prop('productsList', state)
    return {
      productsList
    }
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.PRODUCTS_DETAIL_URL
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.PRODUCTS_CREATE_URL
  }),
  withFilter({
    fields: ['search']
  })
)(Grid)
