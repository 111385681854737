import React from 'react'
import MainBar from 'components/Navigation/TopBar/MainBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'
import Container from 'components/Container/Container'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {branchesListSerializer} from 'containers/Branches/actions/serializers'
import {path, prop, propOr} from 'ramda'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import ProductsItem from 'containers/Products/components/ProductsItem/ProductsItem'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'

const ProductsSearch = styled('div')`
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.nav.primary.background};
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  transition: height .3s, opacity .3s, padding .3s;
`

const UsersGrid = props => {
  const {
    location,
    filterActions,
    usersList,
    goDetail,
    goCreate
  } = props

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Data
  const usersListData = propOr([], 'data', usersList)

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        query={'search'}
        placeholder={'Название'}
      />
    </ProductsSearch>

  // UsersList
  const usersArr = mapIndexed(item => {
    const id = prop('id', item)
    const firstName = prop('firstName', item)
    const secondName = prop('secondName', item)
    const name = firstName + ' ' + secondName
    const isActive = prop('isActive', item)
    const username = prop('username', item)
    const imgSrc = path(['photo', 'file'], item)

    return (
      <ProductsItem
        status={isActive ? 'active' : 'inactive'}
        onClick={() => goDetail(id)}
        key={id}
        title={name}
        subTitle={username}
        imgSrc={imgSrc}
        type={'user'}
      />
    )
  }, usersListData)

  // MainContent
  const mainContent =
    <Container
      secondHeight={56}
      background={'#fff'}
    >
      <InfiniteScroll
        api={API.USERS_LIST}
        actionType={actionTypes.USERS_LIST}
        emptyData={<NoResults />}
        params={branchesListSerializer(searchObj)}
        list={usersArr || []}
      />
    </Container>

  // SecondMenuList
  const menusArr = [
    {
      title: 'Зарегистрировать сотрудника',
      onClick: goCreate,
      withAlert: true,
      perms: ['add_branch']
    }
  ]

  // SecondMenuButton
  const secondMenuButton =
    <Fab
      text={'Дополнительно'}
      withfixed={'true'}
    >
      <Plus size={20} />
    </Fab>

  // SecondMenu
  const secondMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с сотрудниками'}
      menusArr={menusArr}
      button={secondMenuButton}
    />

  // Render
  return (
    <>
      <MainBar
        title={'Сотрудники'}
        firstBtn={
          <MainMenu />
        }
      />
      {debounceSearch}
      {mainContent}
      {secondMenu}
    </>
  )
}

export default UsersGrid
