import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withCreate, withFilter, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'
import * as STATES from 'constants/states'
import {
  carsOrdersAddProductsAction
} from 'containers/Cars/actions/carsAction'

export default compose(
  connect((state) => {
    const config = prop('config', state)
    const customStore = prop('customStore', state)
    const carsServicesList = prop('carsServicesList', state)
    return {
      config,
      customStore,
      carsServicesList
    }
  }),
  withCreate({
    stateName: [STATES.CARS_ORDERS_ADD_PRODUCTS],
    action: carsOrdersAddProductsAction,
    key: [STATES.CARS_ORDERS_ADD_PRODUCTS],
    successMessage: 'Продукты успешно добавлены',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    propFromParams: 'id'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    withCurrentParams: false
  }),
  withFilter({
    fields: ['productsSearch']
  }),
  withHandlers({
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
