import React from 'react'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'

const EditFormFields = props => {
  const {
    manufactureBatchesEditLoading
  } = props

  return (
    <div>
      <Field
        component={TextField}
        name={'amount'}
        label={'Количество'}
        type={'number'}
        fullWidth
        required
      />
      <BottomBar>
        <Button
          text={'Редактировать'}
          disabled={manufactureBatchesEditLoading}
          fullWidth
        />
      </BottomBar>
    </div>
  )
}

export default EditFormFields
