export const ZERO = 0

// Clients orders statuses
export const ORDER_REQUESTED = 0
export const ORDER_READY = 1
export const ORDER_GIVEN = 2
export const ORDER_DELIVERED = 3
export const ORDER_CANCELED = 4
export const ORDER_NOT_CONFIRMED = 5

// Transfers types
export const FROM_TRANSFER = 1
export const TO_TRANSFER = 2
export const ORDER = 3
export const INCOME = 4
export const OUTCOME = 5
export const INCOME_TO_CLIENT = 6
export const OUTCOME_FROM_CLIENT = 7
export const INCOME_FROM_AGENT = 8
export const SUPPLY_EXPENSE = 9
export const OUTCOME_FOR_SUPPLY_EXPENSE = 10
export const CLIENT_CONTRACT_OUT = 12
export const formattedType = {
  1: 'Перевод с кассы ',
  2: 'Перевод на кассу ',
  3: 'Оплата заказа ',
  4: 'Приход ',
  5: 'Расход ',
  6: 'Приход на счет клиента',
  7: 'Снято со счета клиента ',
  8: 'Приемка наличных с агента ',
  9: 'Доп. расход на поставку ',
  10: 'Расход на поставку ',
  12: 'Выдача денег для кэшбека '
}

// Client Confirmations
export const REQUESTED = 'requested'
export const CONFIRMED = 'confirmed'
export const REJECTED = 'rejected'
export const AUTO = 'auto'

// Client Balance Info
export const CLIENT_BALANCE_PAYMENT = 1
export const CLIENT_BALANCE_FIRST_BALANCE = 2
export const CLIENT_BALANCE_ORDER_RETURN = 3
export const CLIENT_BALANCE_ORDER = 4
export const CLIENT_BALANCE_EXPENSE = 5
export const CLIENT_BALANCE_CANCEL = 6
export const CLIENT_BALANCE_CANCEL_ORDER = 7
export const CLIENT_BALANCE_CANCEL_ORDER_RETURN = 8
export const CLIENT_BALANCE_NONE_TYPE = 9
export const CLIENT_BALANCE_ORDER_EDIT = 10
export const CLIENT_BALANCE_ORDER_DISCOUNT = 11
export const CLIENT_BALANCE_CUSTOM_DISCOUNT = 12

// Currency
export const CURRENCY_BACKEND = {
  1: '',
  approved: 'Потвержден',
  given: 'В пути',
  4: 'USD',
  5: 'UZS'
}

// Orders Returns
export const ORDER_RETURN_PENDING = 0
export const ORDER_RETURN_IN_PROGRESS = 1
export const ORDER_RETURN_COMPLETED = 2
export const ORDER_RETURN_CANCELED = 3

// Yandex map
export const API_KEY_YANDEX = 'e8727e86-2913-40e6-a7b9-1cdaa500d138'

// Android const
export const isAndroid = typeof window.Android !== 'undefined'
