import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <path d="M17.6083 1.64573C18.1579 0.995755 17.6959 0 16.8447 0H1.1553C0.304076 0 -0.157926 0.995753 0.391734 1.64573L6.76357 9.18042C6.91623 9.36095 7 9.58972 7 9.82615V15.382C7 15.7607 7.214 16.107 7.55279 16.2764L9.55279 17.2764C10.2177 17.6088 11 17.1253 11 16.382V9.82615C11 9.58972 11.0838 9.36095 11.2364 9.18042L17.6083 1.64573Z" fill="white" />
    </SvgIcon>
  )
}
