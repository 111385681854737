import React from 'react'
import styled from 'styled-components'
import {prop, path} from 'ramda'
import Form from 'components/Forms/Form'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Loader from 'components/Loader'
import {CardTitleBetween} from 'components/Cards/CardTitleBetween'
import {ColorPrice} from 'components/Helpers/ColorPrice'
import toNumber from 'helpers/toNumber'
import numberFormat from 'helpers/numberFormat'
import {CURRENCY_BACKEND} from 'constants/constants'
import {
  AcceptCashClientTransactionsCreateSerializer,
  AcceptCashClientTransactionsUpdateSerializer
} from '../../../actions/serializers/financesSerializer'
import CreateFormFields from './components/CreateFormFields'
import UpdateFormFields from './components/UpdateFormFields'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const LoadWrap = styled('div')`
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 50vh;
    `

const Title = styled('div')`
  display:flex;
  flex-direction: column;
  width: 100%;
`

const SpanEnd = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  line-height: 100%;
  & svg{
    padding-top: 3px;
  }
  & > *:not(:last-child){
    margin-right: 3px;
  }
`

const AcceptCashClientTransactionCreateGrid = props => {
  const {
    goBack,
    requestFundsCreate,
    updateDialog,
    customStore,
    config,
    query,
    paymentsConvert,
    location
  } = props
  // data
  const id = toNumber(prop('open', query)) || null
  const sum = prop('transactionSum', query)
  const currencyId = toNumber(prop('transactionCurrency', query))
  const currency = prop([currencyId], CURRENCY_BACKEND)
  const detailData = prop('state', location)
  const userName = path(['user', 'firstName'], detailData) + ' ' + path(['user', 'secondName'], detailData)
  const clientName = path(['client', 'name'], detailData)
  const clientId = path(['client', 'id'], detailData)
  const disabled = prop('loading', requestFundsCreate)
  const isUpdate = updateDialog.open !== 'false' && updateDialog.open
  // Submit
  const handleMainSubmit = values => {
    const request = AcceptCashClientTransactionsCreateSerializer({query, values})
    const requestUpdate = AcceptCashClientTransactionsUpdateSerializer({id, clientId, values})
    return isUpdate ? updateDialog.onSubmit(requestUpdate) : requestFundsCreate.onSubmit(request)
  }

  const titleContent =
    <Title>
      <CardTitleBetween>
        <b>{isUpdate ? 'Клиент' : 'Агент'}</b>
        <SpanEnd>
          <span>{isUpdate ? clientName : userName}</span>
        </SpanEnd>
      </CardTitleBetween>
      {isUpdate ? null
        : (
          <CardTitleBetween style={{marginTop: '7px'}}>
            <b>Сумма</b>
            <SpanEnd>
              <ColorPrice number={toNumber(sum)}>{numberFormat(sum)} {currency}</ColorPrice>
            </SpanEnd>
          </CardTitleBetween>
        )}
    </Title>
  return (
    <>
      <CreateBar
        title={updateDialog.open ? 'Подтверждение' : 'Создание запроса'}
        goExit={() => goBack(null, null, {openUpdateDialog: false})}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {
            updateDialog.loading
              ? (
                <LoadWrap>
                  <Loader size={2} />
                </LoadWrap>
              )
              : (
                <>
                  <Form
                    keepDirtyOnReinitialize
                    onSubmit={handleMainSubmit}
                    initialValues={updateDialog.open ? updateDialog.initialValues : {}}
                  >
                    {isUpdate
                      ? (
                        <UpdateFormFields
                          updateDialog={updateDialog}
                          titleContent={titleContent}
                          customStore={customStore}
                          config={config}
                          paymentsConvert={paymentsConvert}
                        />
                      )
                      : (
                        <CreateFormFields
                          id={id}
                          titleContent={titleContent}
                          disabled={disabled}
                        />
                      )}
                  </Form>
                </>
              )
          }
        </Wrap>
      </Container>
    </>
  )
}

export default AcceptCashClientTransactionCreateGrid
