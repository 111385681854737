import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Card = styled('li')`
  position: relative;
  transition: ${({theme}) => theme.transition.primary};
  & > div{
    padding: 12px 0;
    margin: 0 16px;
    min-height: 44px;
    height: ${({height}) => height || '73px'};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }
  &:not(:last-child){
    & > div{
      border-bottom: ${({theme}) => theme.border.primary};
    }
  }
  &:hover{
    background: ${({hover}) => hover && '#f7f7f8'};
    transition: ${({theme}) => theme.transition.primary};
  }
  ${({styles}) => styles}
`

const TileItem = props => {
  const {children, onClick, styles, height} = props

  return (
    <Card
      onClick={onClick}
      styles={styles}
      height={height}
      hover={onClick}
    >
      <div>
        {children}
      </div>
    </Card>
  )
}

TileItem.propTypes = {
  children: PropTypes.any,
  height: PropTypes.string,
  styles: PropTypes.object
}

export default TileItem
