import React from 'react'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {prop} from 'ramda'
import {CardFieldsFilter} from 'components/Cards/CardFieldsFilter'
import TextField from 'components/Forms/TextField'
import {storageData} from 'helpers/storage'
import toBoolean from 'helpers/toBoolean'
import moment from 'moment'
import Switcher from 'components/Forms/Switcher'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 14px;
  }
`
// Options
const orderingOptions = [
  {value: 'near_markets', name: 'По ближайщим клиентам'},
  {value: 'debtors', name: 'По долгу'},
  {value: '-created_date', name: 'По новым клиентам'},
  {value: 'created_date', name: 'По старым клиентам'},
  {value: 'is_visible_last_visit', name: 'По посещению'}
]

const FormFields = ({values}) => {
  return (
    <StyledWrap>
      <Field
        component={TextField}
        name={'planDate'}
        type={'date'}
        label={'Дата плана'}
        fullWidth
      />
      <Field
        component={Switcher}
        name={'planHide'}
        type={'checkbox'}
        label={'Только активные задачи'}
        fullWidth
      />
      <Fixed style={{display: 'flex'}}>
        <div style={{marginRight: '10px', width: '100%'}}>
          <Button
            text={'Сбросить'}
            type={'button'}
            fullWidth
            secondary
            onClick={prop('clearFilter', values)}
          />
        </div>
        <div style={{width: '100%'}}>
          <Button
            text={'Применить'}
            fullWidth
          />
        </div>
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    setOpenFilter,
    onChangeFilter,
    initialValues
  } = props

  const debtors = toBoolean(prop('debtors', initialValues)) || true
  const planHideInitial = !(prop('planHide', initialValues) === 'false' || prop('planHide', initialValues) === false)
  const marketTypeValue = storageData('marketsFilterMarketsType').getValue() || null
  const defaultInitialValues = {
    ordering: storageData('ordering').getValue() || orderingOptions[0].value,
    isVisibleBalances: storageData('isVisibleBalances').getValue() || false,
    isVisibleLastVisit: storageData('isVisibleLastVisit').getValue() || false,
    marketType: marketTypeValue,
    marketTypeChild: marketTypeValue ? (storageData('marketsFilterMarketTypeChild').getValue() || null) : null,
    responsibleAgent: storageData('marketsFilterResponsibleAgent').getValue() || null,
    isActive: storageData('marketsFilterIsActive').getValue() || null,
    zone: storageData('marketsZone').getValue() || null,
    planDate: storageData('marketsPlanDate').getValue() || null,
    planHide: storageData('marketsPlanHide').getValue() === null ? true : storageData('marketsPlanHide').getValue()
  }

  const handleSubmit = (values) => {
    setOpenFilter(false)
    const isVisibleBalances = prop('isVisibleBalances', values)
    const isVisibleLastVisit = prop('isVisibleLastVisit', values)
    const ordering = prop('ordering', values)
    const search = prop('search', values)
    const marketType = prop('marketType', values)
    const marketTypeChild = prop('marketTypeChild', values)
    const responsibleAgent = prop('responsibleAgent', values)
    const isActive = prop('isActive', values)
    const zone = prop('zone', values)
    const planDate = prop('planDate', values)
    const todayDate = moment().format('YYYY-MM-DD') === moment(planDate).format('YYYY-MM-DD')
    const planHide = prop('planHide', values)

    storageData('isVisibleBalances').setValue(isVisibleBalances)
    storageData('isVisibleLastVisit').setValue(isVisibleLastVisit)
    storageData('ordering').setValue(ordering)
    storageData('marketsFilterMarketsType').setValue(marketType || null)
    storageData('marketsFilterMarketTypeChild').setValue(marketType ? (marketTypeChild || null) : null)
    storageData('marketsFilterResponsibleAgent').setValue(responsibleAgent || null)
    storageData('marketsFilterIsActive').setValue(isActive || null)
    storageData('marketsZone').setValue(zone || null)
    storageData('marketsPlanDate').setValue(!todayDate ? planDate : null)
    storageData('marketsPlanHide').setValue(planHide)

    onChangeFilter({
      debtors: isVisibleBalances ? prop('debtors', values) : null,
      planDate,
      planHide,
      marketType,
      marketTypeChild: marketType ? marketTypeChild : null,
      responsibleAgent,
      isActive,
      zone,
      search
    })
  }

  const clearFilter = () => {
    setOpenFilter(false)
    const ordering = 'near_markets'
    storageData('marketsFilterMarketsType').setValue(null)
    storageData('marketsFilterMarketTypeChild').setValue(null)
    storageData('marketsFilterResponsibleAgent').setValue(null)
    storageData('marketsFilterIsActive').setValue(null)
    storageData('marketsZone').setValue(null)
    storageData('marketsPlanDate').setValue(null)
    storageData('ordering').setValue(ordering)
    storageData('marketsPlanHide').setValue(true)

    onChangeFilter({
      ordering: null,
      marketsPlanHide: null,
      marketsFilterIsActive: null,
      status: null,
      debtors: null,
      planDate:  null,
      planHide:  null,
      marketType:  null,
      marketTypeChild:  null,
      responsibleAgent:  null,
      isActive:  null,
      zone:  null,
      search:  null
    })
  }

  return (
    <CardFieldsFilter>
      <Form
        initialValues={{...initialValues, planHide: planHideInitial, ...defaultInitialValues, debtors, clearFilter}}
        onSubmit={handleSubmit}
      >
        <FormFields />
      </Form>
    </CardFieldsFilter>
  )
}

export default Filter
