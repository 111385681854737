// Auth
export const AUTH = 'AUTH'
export const CUSTOM_STORE = 'CUSTOM_STORE'
export const CONFIG = 'CONFIG'

// SnackBar
export const SNACKBAR = 'SNACKBAR'
export const SNACKBAR_OPEN = `${SNACKBAR}_OPEN`
export const SNACKBAR_CLOSE = `${SNACKBAR}_CLOSE`

// Image
export const IMAGE_ADDED = 'IMAGE_ADDED'

// Error
export const ERROR = 'ERROR'
export const ERROR_OPEN = `${ERROR}_OPEN`
export const ERROR_CLOSE = `${ERROR}_CLOSE`

// Cashiers
export const CASHIERS_LIST = 'CASHIERS_LIST'

// Divisions
export const DIVISIONS_LIST = 'DIVISIONS_LIST'

// Stocks list
export const STOCKS_LIST = 'STOCKS_LIST'

// Users
export const USERS_LIST_FOR_SELECT = 'USERS_LIST_FOR_SELECT'
export const USERS_DELIVERYMAN_LIST = 'USERS_DELIVERYMAN_LIST'
export const USER_DETAIL = 'USER_DETAIL'
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD'
export const USERS_LIST = 'USERS_LIST'
export const USERS_DETAIL = 'USERS_DETAIL'
export const USERS_DELETE = 'USERS_DELETE'
export const USERS_CREATE = 'USERS_CREATE'
export const USERS_EDIT = 'USERS_EDIT'

// Clients
export const MARKETS_LIST = 'MARKETS_LIST'
export const MARKETS_CREATE = 'MARKETS_CREATE'
export const FILE_UPLOAD = 'FILE_UPLOAD'
export const MARKETS_ITEM = 'MARKETS_ITEM'
export const MARKETS_ITEM_PHOTO = 'MARKETS_ITEM_PHOTO'
export const MARKETS_SALES_CLIENT_BALANCES = 'MARKETS_SALES_CLIENT_BALANCES'
export const MARKETS_SALES_ORDERS_LIST = 'MARKETS_SALES_ORDERS_LIST'
export const MARKETS_SALES_ORDERS_DETAIL = 'MARKETS_SALES_ORDERS_DETAIL'
export const MARKETS_POINTS_LIST = 'MARKETS_POINTS_LIST'
export const MARKETS_PROMOTIONS_LIST = 'MARKETS_PROMOTIONS_LIST'
export const MARKETS_CLIENT_BALANCES = 'MARKETS_CLIENT_BALANCES'
export const MARKETS_RETURNS_PRODUCTS_LIST = 'MARKETS_RETURNS_PRODUCTS_LIST'
export const MARKETS_PRODUCTS_LIST = 'MARKETS_PRODUCTS_LIST'
export const MARKETS_RETURNS_CREATE_PREVIEW = 'MARKETS_RETURNS_CREATE_PREVIEW'
export const MARKETS_RETURNS_CREATE = 'MARKETS_RETURNS_CREATE'
export const MARKETS_RETURNS_LIST = 'MARKETS_RETURNS_LIST'
export const MARKETS_RETURNS_ITEM = 'MARKETS_RETURNS_ITEM'
export const MARKETS_ACCEPT_DELIVERED = 'MARKETS_ACCEPT_DELIVERED'
export const MARKETS_ORDERS_CREATE = 'MARKETS_ORDERS_CREATE'
export const MARKETS_VISITS = 'MARKETS_VISITS'
export const MARKETS_VISIT_CREATE = 'MARKETS_VISIT_CREATE'
export const MARKETS_PRICE_LIST = 'MARKETS_PRICE_LIST'
export const MARKETS_CONTRACTS_LIST = 'MARKETS_CONTRACTS_LIST'
export const MARKETS_CONTRACTS_CREATE = 'MARKETS_CONTRACTS_CREATE'
export const MARKETS_TELEGRAMS = 'MARKETS_TELEGRAMS'
export const MARKETS_TELEGRAMS_CREATE = 'MARKETS_TELEGRAMS_CREATE'
export const MARKETS_STATISTICS_BY_PRODUCTS = 'MARKETS_STATISTICS_BY_PRODUCTS'
export const MARKETS_STATISTICS_BY_AGENTS = 'MARKETS_STATISTICS_BY_AGENTS'
export const MARKETS_STATISTICS_BY_AGENTS_PAYMENTS = 'MARKETS_STATISTICS_BY_AGENTS_PAYMENTS'
export const MARKETS_TOTAL_STATISTICS = 'MARKETS_TOTAL_STATISTICS'
export const MARKETS_REPORTS_CREATE = 'MARKETS_REPORTS_CREATE'
export const MARKETS_REPORTS_EDIT = 'MARKETS_REPORTS_EDIT'
export const MARKETS_REPORTS = 'MARKETS_REPORTS'
export const MARKETS_REPORTS_DETAIL = 'MARKETS_REPORTS_DETAIL'
export const MARKETS_REPORTS_DELETE = 'MARKETS_REPORTS_DELETE'
export const MARKETS_AGENT_PLANS = 'MARKETS_AGENT_PLANS'
export const MARKETS_PLAN = 'MARKETS_PLAN'
export const MARKETS_PLAN_EDIT = 'MARKETS_PLAN_EDIT'
export const MARKETS_PLAN_DELETE = 'MARKETS_PLAN_DELETE'
export const MARKET_OPTIONS_LIST = 'MARKET_OPTIONS_LIST'
export const MARKETS_PLANS_TYPES = 'MARKETS_PLANS_TYPES'
export const MARKETS_AGENTS_LOCATION = 'MARKETS_AGENTS_LOCATION'
export const MARKETS_PRODUCT_DETAIL = 'MARKETS_PRODUCT_DETAIL'
export const MARKETS_AGENTS_PLANS = 'MARKETS_AGENTS_PLANS'
export const MARKETS_SERVICE_TIME_START = 'MARKETS_SERVICE_TIME_START'
export const MARKETS_SERVICE_TIME_END = 'MARKETS_SERVICE_TIME_END'
export const MARKETS_ORDERS_EDIT = 'MARKETS_ORDERS_EDIT'
export const MARKETS_ORDERS_CANCEL = 'MARKETS_ORDERS_CANCEL'

export const MANUFACTURE_BATCHES_EDIT = 'MANUFACTURE_BATCHES_EDIT'
export const MANUFACTURE_BATCHES_DELETE = 'MANUFACTURE_BATCHES_DELETE'

// Plans
export const PLANS_BY_AGENTS_LIST = 'PLANS_BY_AGENTS_LIST'
export const PLANS_BY_AGENTS_DETAIL = 'PLANS_BY_AGENTS_DETAIL'

// add Bonus for Order
export const BONUS_ORDER_COUNT = 'BONUS_ORDER_COUNT'
export const BONUS_ORDER_LIST = 'BONUS_ORDER_LIST'
export const ORDER_ITEM_ADD_BONUS = 'ORDER_ITEM_ADD_BONUS'

// Statistics
export const STATISTICS_PAYMENTS_BY_AGENT = 'STATISTICS_PAYMENTS_BY_AGENT'
export const STATISTICS_BY_AGENTS = 'STATISTICS_BY_AGENTS'
export const STATISTICS_FOR_SUPERVISOR = 'STATISTICS_FOR_SUPERVISOR'
export const STATISTICS_AGENTS_BY_DIVISIONS = 'STATISTICS_AGENTS_BY_DIVISIONS'
export const STATISTICS_AGENTS_DEBTS = 'STATISTICS_AGENTS_DEBTS'

// Cars
export const CARS_LIST = 'CARS_LIST'
export const CARS_BRANDS_LIST = 'CARS_BRANDS_LIST'
export const CARS_CREATE = 'CARS_CREATE'
export const CARS_DETAIL = 'CARS_DETAIL'
export const CARS_ORDERS_CREATE = 'CARS_ORDERS_CREATE'
export const CARS_ORDERS_FINISH_CREATE = 'CARS_ORDERS_FINISH_CREATE'
export const CARS_ORDERS_ADD_PRODUCTS = 'CARS_ORDERS_ADD_PRODUCTS'
export const CARS_ORDERS_ADD_SERVICES = 'CARS_ORDERS_ADD_SERVICES'
export const CARS_SERVICES_LIST = 'CARS_SERVICES_LIST'
export const CARS_ORDERS_LIST = 'CARS_ORDERS_LIST'
export const CARS_ORDERS_DETAIL = 'CARS_ORDERS_DETAIL'
export const CARS_ORDERS_CANCEL = 'CARS_ORDERS_CANCEL'
export const CARS_PRODUCTS_ITEM_UPDATE = 'CARS_PRODUCTS_ITEM_UPDATE'

// Finances
export const FINANCES_CASHIERS_LIST = 'FINANCES_CASHIERS_LIST'
export const FINANCES_TRANSACTIONS_LIST = 'FINANCES_TRANSACTIONS_LIST'
export const FINANCES_ACCEPT_CASH_LIST = 'FINANCES_ACCEPT_CASH_LIST'
export const FINANCES_CLIENT_TRANSACTION_LIST = 'FINANCES_CLIENT_TRANSACTION_LIST'
export const MARKETS_BALANCE_NOTIFICATION_LIST = 'MARKETS_BALANCE_NOTIFICATION_LIST'
export const MARKETS_BALANCE_NOTIFICATION_CREATE = 'MARKETS_BALANCE_NOTIFICATION_CREATE'
export const FINANCES_ACCEPT_CLIENT_TRANSACTION = 'FINANCES_ACCEPT_CLIENT_TRANSACTION'
export const FINANCES_TRANSFER_CASH_CREATE = 'FINANCES_TRANSFER_CASH_CREATE'
export const FINANCES_CLIENT_TRANSACTION_CREATE = 'FINANCES_CLIENT_TRANSACTION_CREATE'
export const FINANCES_CLIENT_TRANSACTION_PREVIEW = 'FINANCES_CLIENT_TRANSACTION_PREVIEW'
export const PAYMENTS_CONVERT = 'PAYMENTS_CONVERT'
export const FINANCES_TRANSACTIONS_CREATE = 'FINANCES_TRANSACTIONS_CREATE'
export const FINANCES_DEBIT_CATEGORY = 'FINANCES_DEBIT_CATEGORY'
export const FINANCES_CREDIT_CATEGORY = 'FINANCES_CREDIT_CATEGORY'

// RequestFunds
export const REQUEST_FUNDS_LIST = 'REQUEST_FUNDS_LIST'
export const REQUEST_FUNDS_CREATE = 'REQUEST_FUNDS_CREATE'
export const REQUEST_FUNDS_ITEM = 'REQUEST_FUNDS_ITEM'

// Notification
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST'
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE'
export const NOTIFICATION_GET_NOT_VIEWED = 'NOTIFICATION_GET_NOT_VIEWED'

// Firebase
export const FIREBASE_TOKEN = 'FIREBASE_TOKEN'

// Stocks
export const STOCKS_INCOME_TASKS = 'STOCKS_INCOME_TASKS'
export const STOCKS_OUTCOME_TASKS = 'STOCKS_OUTCOME_TASKS'
export const STOCKS_ACCEPT_OUTCOME = 'STOCKS_ACCEPT_OUTCOME'
export const ORDERS_ACCEPT_OUTCOME = 'ORDERS_ACCEPT_OUTCOME'
export const STOCKS_DETAILS = 'STOCKS_DETAILS'
export const STOCKS_SELECTIONS = 'STOCKS_SELECTIONS'
export const STOCKS_SELECTIONS_CREATE = 'STOCKS_SELECTIONS_CREATE'
export const STOCKS_SELECTIONS_PRODUCTS_LIST = 'STOCKS_SELECTIONS_PRODUCTS_LIST'
export const STOCKS_SELECTIONS_CREATE_END = 'STOCKS_SELECTIONS_CREATE_END'
export const STOCKS_RESTS_LIST = 'STOCKS_RESTS_LIST'

// GPS
export const GPS_SENDER = 'GPS_SENDER'
export const GET_ADDRESS = 'GET_ADDRESS'

// POS
export const POS_CHECKS = 'POS_CHECKS'
export const POS_CHECKS_DETAIL = 'POS_CHECKS_DETAIL'
export const POS_DEBTS = 'POS_DEBTS'
export const POS_DEBTS_DETAIL = 'POS_DEBTS_DETAIL'
export const POS_TOTAL_STATISTICS = 'POS_TOTAL_STATISTICS'
export const POS_STATISTICS_POPULAR_PRODUCTS = 'POS_STATISTICS_POPULAR_PRODUCTS'
export const POS_STATISTICS_REVENUE = 'POS_STATISTICS_REVENUE'

// DeliveriesOld
export const DELIVERIES_PLAN = 'DELIVERIES_PLAN'

// Manufacture
export const MANUFACTURE_LIST = 'MANUFACTURE_LIST'
export const MANUFACTURE_BATCHES_LIST = 'MANUFACTURE_BATCHES_LIST'
export const MANUFACTURE_PRODUCTS_LIST = 'MANUFACTURE_PRODUCTS_LIST'
export const MANUFACTURE_PRODUCTS_DETAIL = 'MANUFACTURE_PRODUCTS_DETAIL'
export const EQUIPMENT_LIST = 'EQUIPMENT_LIST'
export const MARKETS_MATERIALS_LIST = 'MARKETS_MATERIALS_LIST'
export const MANUFACTURE_BATCHES_CREATE = 'MANUFACTURE_BATCHES_CREATE'
export const MANUFACTURE_BATCHES_DETAIL = 'MANUFACTURE_BATCHES_DETAIL'
export const MANUFACTURE_BATCHES_ADD_PRODUCTS = 'MANUFACTURE_BATCHES_ADD_PRODUCTS'
export const MANUFACTURE_BATCHES_ADD_MATERIALS = 'MANUFACTURE_BATCHES_ADD_MATERIALS'
export const MANUFACTURE_BATCHES_FINISH_CREATE = 'MANUFACTURE_BATCHES_FINISH_CREATE'
export const MANUFACTURE_BATCHES_PRODUCT_EDIT = 'MANUFACTURE_BATCHES_PRODUCT_EDIT'
export const MANUFACTURE_BATCHES_PRODUCT_DELETE = 'MANUFACTURE_BATCHES_PRODUCT_DELETE'
export const MANUFACTURE_BATCHES_MATERIAL_EDIT = 'MANUFACTURE_BATCHES_MATERIAL_EDIT'
export const MANUFACTURE_BATCHES_MATERIAL_DELETE = 'MANUFACTURE_BATCHES_MATERIAL_DELETE'
export const MANUFACTURE_SHIPMENT_END = 'MANUFACTURE_SHIPMENT_END'

// Products
export const PRODUCTS_LIST = 'PRODUCTS_LIST'
export const PRODUCTS_DETAIL = 'PRODUCTS_DETAIL'
export const PRODUCTS_DELETE = 'PRODUCTS_DELETE'
export const PRODUCTS_CREATE = 'PRODUCTS_CREATE'
export const PRODUCTS_EDIT = 'PRODUCTS_EDIT'

// Products Types
export const PRODUCTS_TYPES_LIST = 'PRODUCTS_TYPES_LIST'
export const PRODUCTS_TYPES_CREATE = 'PRODUCTS_TYPES_CREATE'
export const PRODUCTS_TYPES_EDIT = 'PRODUCTS_TYPES_EDIT'
export const PRODUCTS_TYPES_ITEM = 'PRODUCTS_TYPES_ITEM'
export const PRODUCTS_TYPES_DELETE = 'PRODUCTS_TYPES_DELETE'

// Types Select
export const PRODUCTS_TYPES = 'PRODUCTS_TYPES'

// Branches
export const BRANCHES_LIST = 'BRANCHES_LIST'
export const BRANCHES_DETAIL = 'BRANCHES_DETAIL'
export const BRANCHES_DELETE = 'BRANCHES_DELETE'
export const BRANCHES_CREATE = 'BRANCHES_CREATE'
export const BRANCHES_EDIT = 'BRANCHES_EDIT'

// Orders
export const ORDERS_CREATE = 'ORDERS_CREATE'

// SettingsBot
export const SETTINGS_BOT = 'SETTINGS_BOT'
export const SETTINGS_BOT_EDIT = 'SETTINGS_BOT_EDIT'
