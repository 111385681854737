import React, {useMemo} from 'react'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import styled from 'styled-components'
import {Field} from 'react-final-form'
import Switcher from 'components/Forms/Switcher'
import TextField from 'components/Forms/TextField'
import {filter, map, prop} from 'ramda'
import checkPermissions from 'helpers/checkPermissions'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import MapField from 'components/Forms/MapField'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import * as API from 'constants/api'
import numberFormat from 'helpers/numberFormat'
import {TabsBar} from 'components/Tabs/TabsBar'
import Tab from '@material-ui/core/Tab'
import Label from 'components/Forms/Label'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import {PAYMENT_TYPE_OPTIONS} from 'constants/backendConstant'
import normalizePhone from 'helpers/normalizers/normalizePhone'

const Wrap = styled('div')`
  position: relative;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const OrdersCreateForm = props => {
  const {
    mapPerms,
    isAdmin,
    stocks,
    values,
    loading,
    totalPrice,
    primaryCurrencyName,
    tabValue,
    setTabValue
  } = props

  // Values
  const deliveryType = prop('deliveryType', values)

  // Const
  const mainButtonText = 'Создать: ' + numberFormat(totalPrice, primaryCurrencyName)
  const isDelivery = deliveryType === 'delivery'
  const isSelf = deliveryType === 'self'

  // Perms
  const hasPermsCanSelf = checkPermissions(['can_self'], mapPerms)

  const deliveryTypeOptions = [
    {value: 'delivery', label: 'Доставка'},
    (hasPermsCanSelf || isAdmin) && {value: 'self', label: 'Самовывоз'}
  ]

  const filteredDeliveryTypeOptions = useMemo(() => {
    return deliveryTypeOptions && filter((i) => !!i === true)(deliveryTypeOptions)
  }, [deliveryTypeOptions])
  const deliveryTypesLength = +prop('length', filteredDeliveryTypeOptions)

  const stocksListOptions = stocks && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, stocks)

  // TabConfig
  const a11yProps = index => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    }
  }
  const handleChange = (event, value) => {
    setTabValue(value)
  }

  return (
    <Wrap>
      <div>
        <Label
          styles={{marginBottom: '0 !important'}}
          required
        >
          Клиент
        </Label>
        <TabsBar
          padding={'6px 0'}
          onChange={handleChange}
          value={tabValue}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'Выбрать'} {...a11yProps(0)} />
          <Tab label={'Создать'} {...a11yProps(1)} />
        </TabsBar>
        {tabValue === 0 &&
        <Field
          component={UniversalSearchField}
          api={API.MARKETS_LIST}
          params={{page_size: '100'}}
          name={'market'}
          label={'Клиент'}
          withoutLabel
          fullWidth
        />}
        {tabValue === 1 &&
        <Wrap>
          <Field
            component={TextField}
            name={'contactName'}
            label={'Имя'}
            required
            fullWidth
          />
          <Field
            component={TextField}
            type={'number'}
            name={'phoneNumber'}
            label={'Телефон'}
            required
            fullWidth
          />
        </Wrap>}
      </div>
      <Field
        name={'secondPhone'}
        component={TextField}
        placeholder={'Введите номер'}
        parse={normalizePhone}
        defaultValue={'+998'}
        type={'text'}
      />
      {isDelivery &&
      <>
        <Field
          label={'Локация'}
          component={MapField}
          name={'location'}
        />
        <Field
          component={TextField}
          name={'deliveryAddress'}
          label={'Адрес'}
          multiline
          required
          fullWidth
        />
      </>}
      <Field
        component={StaticSearchField}
        name={'stock'}
        label={'Филиал'}
        type={'select'}
        options={stocksListOptions}
        required
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'paymentType'}
        label={'Тип оплаты'}
        options={PAYMENT_TYPE_OPTIONS}
        required
        fullWidth
      />
      {!(deliveryTypesLength === 1) &&
      <Field
        component={StaticSearchField}
        name={'deliveryType'}
        label={'Тип доставки'}
        options={filteredDeliveryTypeOptions}
        required
        fullWidth
      />}
      {isDelivery &&
      <Field
        component={UsersSearchField}
        api={API.USERS_LIST_FOR_SELECT}
        params={{group: 'delivery', own_division: 'true', page_size: 1000}}
        name={'deliveryMan'}
        label={'Доставщик'}
        fullWidth
      />}
      <Field
        component={TextField}
        type={'number'}
        name={'deliveryPrice'}
        label={'Стоимость доставки'}
        fullWidth
      />
      <Field
        component={TextField}
        name={'dateDelivery'}
        type={'datetime-local'}
        label={isSelf ? 'Дата самовывоза' : 'Дата доставки'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        name={'comment'}
        label={'Комментарий'}
        multiline
        fullWidth
      />
      <Field
        component={Switcher}
        name={'isConfirmed'}
        type={'checkbox'}
        label={'Подтверждённый заказ'}
        fullWidth
      />
      <BottomBar>
        <Button
          text={mainButtonText}
          type={'submit'}
          disabled={loading}
          fullWidth
        />
      </BottomBar>
    </Wrap>
  )
}

export default OrdersCreateForm
