import React, {useState} from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import {MainBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import * as serializers from 'containers/Notification/actions/serializers/notificationSerializer'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {isEmpty, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import dateTimeFormat from 'helpers/dateTimeFormat'
import {Edit, X} from 'react-feather'
import ModalConfirm from '../../../components/Modal/ModalConfirm'
import NotificationItem from '../components/NotificationItem/NotificationItem'
import styled from 'styled-components'

const LastBtn = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const NotificationGrid = (props) => {
  const {
    notificationList,
    deleteNotificationDialog,
    handleDetailId,
    location,
    history
  } = props

  // UseStates
  const [moreOptions, setMoreOptions] = useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Data
  const notificationListData = prop('data', notificationList)

  // Main content
  const list = notificationListData && mapIndexed((item) => {
    const id = prop('id', item)
    const title = prop('title', item)
    const text = prop('text', item)
    const createdDate = prop('createdDate', item)

    return (
      <NotificationItem
        key={id}
        title={title}
        history={history}
        deleteNotificationDialog={deleteNotificationDialog}
        text={text}
        template={'notification'}
        backgroundColor={'#f0ad4e'}
        detailId={id}
        moreOptions={moreOptions}
        createdDate={dateTimeFormat(createdDate)}
        setQuery={handleDetailId}
        searchObj={searchObj}
      />
    )
  }, notificationListData)

  return (
    <>
      <MainBar
        title={'Уведомления'}
        firstBtn={
          <MainMenu />
        }
        lastBtn={!isEmpty(notificationListData) &&
        <LastBtn
          onClick={() => setMoreOptions(!moreOptions)}
        >
          {moreOptions
            ? <X size={22} color={'#000'} />
            : <Edit size={20} color={'#e66767'} />}
        </LastBtn>}
      />
      <Container
        background={'#fff'}
      >
        <InfiniteScroll
          api={API.NOTIFICATION_LIST}
          actionType={actionTypes.NOTIFICATION_LIST}
          emptyData={<li><NoResults /></li>}
          params={serializers.notificationListSerializer(searchObj)}
          list={list || []}
        />
      </Container>
      <ModalConfirm
        open={deleteNotificationDialog.modalDelete}
        isOpen={deleteNotificationDialog.onOpen}
        title={'Удалить'}
        loading={deleteNotificationDialog.loading}
        type={'delete'}
        onClick={deleteNotificationDialog.handleSubmit}
      />
    </>
  )
}

export default NotificationGrid
