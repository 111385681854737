import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {prop} from 'ramda'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
`
const Label = styled('label')`
  color: ${({theme, disabled}) => disabled ? theme.input.disabled.color : theme.text.secondary} !important;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  &:after{
    display: ${({required}) => required ? 'inline-block' : 'none'};
    content: '\u00A0*';
    color: ${({theme}) => theme.palette.red};
  }
`
const InputWrap = styled('div')`
  position: relative;
  width: ${({fullWidth}) => fullWidth && '100%'};
`
const Sub = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  svg{
    max-width: 15px;
    max-height: 15px;
  }
`
const Prefix = styled(Sub)`
  left: 0;
`
const Suffix = styled(Sub)`
  right: 0;
`

const StyledTextareaField = styled('textarea')`
  padding: 8px ${({suffix}) => suffix ? '35px' : '10px'} 8px ${({prefix}) => prefix ? '35px' : '10px'};
  height: ${({size}) => size === 'small' ? '36px' : '50px'};
  border-radius: ${({theme}) => theme.input.primary.borderRadius};
  border-color: transparent;
  background: ${({theme}) => theme.input.primary.background};
  border-width: 1px;
  font-size: ${({theme}) => theme.fontSize.primary};
  color: ${({theme}) => theme.palette.black};
  &:focus {
    background: #fff;
    border-color: ${({theme}) => theme.palette.blue};
  }
  svg {
  max-width: 20px;
  max-height: 20px;
  }
  ::placeholder{
    color: ${({theme}) => theme.text.secondary};
  }
  svg{
    max-width: 20px;
    max-height: 20px;
    color: ${({theme}) => theme.text.secondary};
  }
`

const StyledTextField = styled('input')`
  padding: 8px ${({suffix}) => suffix ? '35px' : '10px'} 8px ${({prefix}) => prefix ? '35px' : '10px'};
  height: ${({size}) => size === 'small' ? '36px' : '50px'};
  border-radius: ${({theme}) => theme.input.primary.borderRadius};
  border-color: transparent;
  background: ${({theme}) => theme.input.primary.background};
  border-width: 1px;
  font-size: ${({theme}) => theme.fontSize.primary};
  color: ${({theme}) => theme.palette.black};
  &:focus {
    background: #fff;
    border-color: ${({theme}) => theme.palette.blue};
  }
  &:disabled{
    ${({theme}) => theme.input.disabled};
  }
  svg {
  max-width: 20px;
  max-height: 20px;
  }
  &::placeholder{
    color: ${({theme, disabled}) => disabled ? theme.input.disabled.color : theme.text.secondary};
  }
  svg{
    max-width: 20px;
    max-height: 20px;
    color: ${({theme}) => theme.text.secondary};
  }
  ${({styles}) => styles};
`

const TextField = (props) => {
  const {
    required = false,
    type,
    label,
    placeholder,
    fullWidth,
    input,
    onChange,
    defaultValue,
    multiline,
    prefix,
    suffix,
    size,
    disabled,
    styles,
    ...defaultProps
  } = props

  const typeInput = (
    <StyledTextField
      {...defaultProps}
      {...input}
      prefix={prefix}
      suffix={suffix}
      style={{width: '100%'}}
      step={'any'}
      size={size}
      onChange={(onChange || prop('onChange', input))}
      type={type || prop('type', input) || 'text'}
      placeholder={placeholder || 'Введите текст'}
      defaultValue={defaultValue}
      disabled={disabled}
      styles={styles}
      required={required}
      autoComplete={'off'}
    />

  )
  const typeTextarea = (
    <StyledTextareaField
      {...defaultProps}
      {...input}
      prefix={prefix}
      suffix={suffix}
      style={{width: '100%', lineHeight: '20px', height: '70px'}}
      step={'any'}
      size={size}
      disabled={disabled}
      onChange={(onChange || prop('onChange', input))}
      type={type || prop('type', input) || 'text'}
      placeholder={placeholder || 'Введите текст'}
      defaultValue={defaultValue}
      required={required}
    />
  )
  return (
    <Wrap>
      {label &&
      <Label
        required={required}
        disabled={disabled}
      >
        {label}
      </Label>}
      <InputWrap
        fullWidth={fullWidth}
      >
        {prefix &&
        <Prefix>
          {prefix}
        </Prefix>}
        {multiline ? typeTextarea
          : typeInput}
        {suffix &&
        <Suffix>
          {suffix}
        </Suffix>}
      </InputWrap>
    </Wrap>
  )
}

TextField.propTypes = {
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.any,
  prefix: PropTypes.any,
  suffix: PropTypes.any
}

export default TextField
