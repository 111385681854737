import {prop, isEmpty, equals} from 'ramda'
import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import axios from 'helpers/axios'
import axiosLib from 'axios'
import toCamelCase from 'helpers/toCamelCase'
import InfiniteScroller from 'react-infinite-scroller'
import Loader from 'components/Loader'
import styled from 'styled-components'
import {parseParams} from '../../helpers/url'
import Button from 'components/Forms/Button'
import {WifiOff} from 'react-feather'

const enhance = compose(
  connect()
)

// isExtra дозагрузка
// reloadUI reload page
// initialLoading is first Loading

class InfiniteScroll extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      initialLoading: true,
      extraLoading: false,
      showWarning: false,
      nextPage: null,
      elementsCount: 0
    }
    this.initialList = []
    this.extraList = []
    this.cancelRequest = null
    this.warningTimeout = null

    this.axiosRequest = this.axiosRequest.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  axiosRequest ({api, params, isExtra, callBack, dispatch, getState}) {
    const that = this
    const CancelToken = axiosLib.CancelToken
    this.warningTimeout && clearTimeout(this.warningTimeout)
    this.warningTimeout = setTimeout(() => {
      this.setState({
        showWarning:true
      })
    }, 5000)
    isExtra && this.setState({extraLoading: true})
    return axios({dispatch, getState})
      .get(api, {
        params: {page: isExtra ? that.state.nextPage : null, ...params},
        cancelToken: new CancelToken(function executor (cancel) {
          that.cancelRequest = cancel
        })
      })
      .then((response) => {
        this.warningTimeout && clearTimeout(this.warningTimeout)
        this.setState({
          showWarning:false
        })

        const data = toCamelCase(prop('data', response))
        if (isExtra) {
          this.extraList = [...this.extraList, ...data.results]
        } else {
          this.initialList = data.results
        }
        callBack(data)
        return [...this.initialList, ...this.extraList]
      })
      .catch((e) => {
        this.setState({
          nextPage: null
        })
        this.initialList = []
        this.extraList = []
        // eslint-disable-next-line no-console
        return console.log(e, 'Проблема здесь! infiniteScroll')
      })
  }

  fetchData (api, isExtra) {
    const {params, actionType, getCount} = this.props
    return (dispatch, getState) => {
      const initialCallBack = data => {
        const next = prop('next', data) || null
        const apiParam = next && parseParams(next)
        const nextPage = prop('page', apiParam) || null
        const count = prop('count', data)

        this.setState({
          initialLoading: false,
          elementsCount: count,
          nextPage: nextPage
        })

        if (getCount) {
          getCount(count)
        }
      }
      const callBackExtra = data => {
        const next = prop('next', data) || null
        const apiParam = next && parseParams(next)
        const nextPage = prop('page', apiParam) || null

        this.setState({
          extraLoading: false,
          nextPage: nextPage
        })
      }
      const payload = isExtra
        ? this.axiosRequest({api, params, isExtra: true, callBack: callBackExtra, dispatch, getState})
        : this.axiosRequest({api, params, callBack: initialCallBack, dispatch, getState})
      return dispatch({
        payload,
        type: actionType
      })
    }
  }

  componentDidMount () {
    const {api, dispatch} = this.props
    if (this.cancelRequest) {
      return this.cancelRequest()
    }
    return dispatch(this.fetchData(api, false))
  }

  updateList () {
    const {dispatch, api} = this.props
    window.scrollTo(0, 0)
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      initialLoading: true,
      nextPage: null
    })
    this.initialList = []
    this.extraList = []
    if (this.cancelRequest) this.cancelRequest()
    dispatch(this.fetchData(api, false))
  }

  componentDidUpdate (prevProps, prevState) {
    const {params, api} = this.props
    const equal = !equals(prevProps.params, params) || !equals(prevProps.params.reloadUI, params.reloadUI)
    if (equal && api) {
      this.updateList()
    }
  }

  componentWillUnmount () {
    window.scrollTo(0, 0)
    this.initialList = []
    this.extraList = []
  }

  render () {
    const {list, emptyData, useWindow, element, childElement, style, loader} = this.props
    const {
      elementsCount,
      initialLoading,
      extraLoading,
      showWarning
    } = this.state

    const listCount = list && list.length
    const hasMore = listCount < elementsCount
    const emptyList = isEmpty(list) && !extraLoading

    const LoadWrap = styled(`${childElement}`)`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    `
    const ChildElement = styled(`${childElement}`)`
      display: flex;
      justify-content: center;
      align-items: center;
    `
    const ErrorText = styled('span')`
      position: relative;
      padding: 0 30px 0 50px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      svg{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    `

    const loadMore = () => {
      const {api} = this.props
      if (hasMore) {
        this.state.nextPage && this.props.dispatch(
          this.fetchData(api, true)
        )
      }
    }
    return (
      <InfiniteScroller
        style={{position: 'relative', width: '100%', maxWidth: '100%', ...style}}
        loadMore={loadMore}
        initialLoad={initialLoading}
        threshold={200}
        element={element}
        useWindow={useWindow}
        hasMore={hasMore && !extraLoading}
      >
        {showWarning &&
        <Button
          text={<ErrorText><WifiOff size={30} /> <span>Плохое соединение, проверьте интернет и нажмите сюда </span></ErrorText>}
          type={'button'}
          background={'#fbb432'}
          styles={{height: 'auto', borderRadius: '0 !important'}}
          onClick={() => { this.updateList() }}
        />}
        {initialLoading
          ? loader || <LoadWrap><Loader /></LoadWrap>
          : emptyList ? emptyData : list}

        {!initialLoading && hasMore && !emptyList && <ChildElement><Loader size={0.7} /></ChildElement>}
      </InfiniteScroller>
    )
  }
}

InfiniteScroll.propTypes = {
  api: PropTypes.string.isRequired,
  list: PropTypes.array,
  params: PropTypes.shape({
    reloadUI: PropTypes.any
  }),
  style: PropTypes.object,
  childElement: PropTypes.any,
  element: PropTypes.any,
  useWindow: PropTypes.bool,
  actionType: PropTypes.string.isRequired,
  emptyData: PropTypes.node.isRequired,
  loader: PropTypes.any
}

InfiniteScroll.defaultProps = {
  useWindow: true,
  childElement: 'li',
  element: 'ul',
  style: {
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0'
  }
}

export default enhance(InfiniteScroll)
