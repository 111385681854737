import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'

export const deleteNotificationAction = (id) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.NOTIFICATION_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.NOTIFICATION_DELETE,
      payload
    })
  }
}

export const notificationsGetNotViewedAction = () => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.NOTIFICATION_GET_NOT_VIEWED)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.NOTIFICATION_GET_NOT_VIEWED,
      payload
    })
  }
}
