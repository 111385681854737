import {compose, mapProps, withHandlers} from 'recompose'
import FinancesGrid from './Grid'
import {withCreate, withFilter, withRedirect} from 'hocs'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import toBoolean from '../../helpers/toBoolean'
import {TRANSFER_CASH_DIALOG} from './components/FinancesTransferCashCreateDialog'
import * as STATES from '../../constants/states'
import {transferCashCreateAction} from './actions/financesAction'
import {openSnackbarAction} from 'actions/snackbarAction'
import {openErrorAction} from 'actions/errorsAction'

export default compose(
  connect((state, props) => {
    const searchObj = parseParams(path(['location', 'search'], props))
    const financesCashierList = prop('financesCashierList', state)
    const config = path(['config', 'data'], state)
    return {
      searchObj,
      financesCashierList,
      config
    }
  }),
  withCreate({
    stateName: [STATES.FINANCES_TRANSFER_CASH_CREATE],
    action: transferCashCreateAction,
    key: [STATES.FINANCES_TRANSFER_CASH_CREATE],
    redirectUrl: ROUTES.MARKETS_URL,
    successMessage: 'Перевод успешно создан'
  }),
  withFilter({
    fields: ['search']
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.FINANCES_TRANSACTIONS_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goCreditsCreate',
    redirectUrl: ROUTES.FINANCES_CREDITS_CREATE_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goDebitsCreate',
    redirectUrl: ROUTES.FINANCES_DEBITS_CREATE_PATH,
    withCurrentParams: false
  }),
  withHandlers({
    changePageHandler: props => (key) => {
      props.history.push({
        pathname: props.location.pathname,
        search: appendParamsToUrl({page: key}, path(['history', 'location', 'search'], props))
      })
    },
    onHandleTransferCashCreateDialog: props => () => {
      props.history.push({
        pathname: ROUTES.FINANCES_URL
      })
    },
    financesTransferCashCreate: props => (values, isPercent, amountTo) => {
      const {dispatch} = props
      const reloadUI = Math.random()
      dispatch(transferCashCreateAction(values, isPercent, amountTo))
        .then(() => {
          props.history.push({
            search: appendParamsToUrl({[TRANSFER_CASH_DIALOG]: false, reloadUI}, path(['history', 'location', 'search'], props))
          })
          return dispatch(openSnackbarAction({message: 'Успешно переведено'}))
        })
        .catch(({...e}) => {
          return dispatch(openErrorAction({errMessage: e}))
        })
    }
  }),
  mapProps(({
    financesTransferCashCreate,
    onHandleTransferCashCreateDialog,
    ...props
  }) => {
    const transferCashDialogOpen = toBoolean(prop([TRANSFER_CASH_DIALOG], props.searchObj))
    const transferCashDialog = {
      open: transferCashDialogOpen,
      onHandleDialog: onHandleTransferCashCreateDialog,
      onSubmit: financesTransferCashCreate
    }
    return {
      transferCashDialog,
      ...props
    }
  })
)(FinancesGrid)
