import {compose, withHandlers, withState} from 'recompose'
import {withFilter, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import FinancesAcceptCashGrid from './Grid/FinancesAcceptCashGrid'

export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const financesAcceptCashList = prop('financesAcceptCashList', state)
    return {
      query,
      financesAcceptCashList
    }
  }),
  withState('filterIsOpen', 'setFilterIsOpen', false),
  withFilter({
    fields: ['search']
  }),
  withHandlers({
    goDetail: props => (user, realCurrency, division, sum) => {
      const {history} = props
      history.push({
        pathname: ROUTES.FINANCES_ACCEPT_CASH_DETAIL_URL,
        search: appendParamsToUrl({transaction: 0, type: 1, user, real_currency: realCurrency, division, sum}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.FINANCES_URL,
    withCurrentParams: false
  })
)(FinancesAcceptCashGrid)
