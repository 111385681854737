import React, {useState} from 'react'
import Container from 'components/Container'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import styled from 'styled-components'
import {isEmpty, length, prop} from 'ramda'
import moment from 'moment'
import {parseParams} from 'helpers/url'
import StatisticsFilters from 'containers/Statistics/containers/Main/Grid/StatisticsFilters'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import TileInner from 'components/Cards/Tile/TileInner'
import BagIcon from 'components/SvgIcons/CustomIcons/BagIcon'
import BagReturnIcon from 'components/SvgIcons/CustomIcons/BagReturnIcon'
import CardIcon from 'components/SvgIcons/CustomIcons/CardIcon'
import ChartIcon from 'components/SvgIcons/CustomIcons/ChartIcon'
import numberFormat from 'helpers/numberFormat'
import mapIndexed from 'helpers/mapIndexed'
import {TextStatus} from 'components/Status/TextStatus'
import NoResults from 'components/NoData/NoResults'
import ProgressBar from 'components/Charts/ProgressBar'
import ChartBar from 'components/Charts/ChartBar'
import {storageData} from 'helpers/storage'
import checkPermissions from 'helpers/checkPermissions'
import Loader from 'components/Loader'

const Div = styled('div')`
  position: fixed;
  height: 49px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e4e8;
  top: 61px;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${({theme}) => theme.nav.primary.background};
  & > *:not(:last-child) {
    border-right: ${({theme}) => theme.border.primary};
  }
`
const Wrap = styled('div')`
  display: flex;
  padding: 14px 16px;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 14px;
  }
`
const Title = styled('span')`
  display: block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme}) => theme.text.secondary};
`
const CustomButton = styled('div')`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({active}) => active ? 500 : 400};
  background: ${({active}) => active ? '#ffffff9e' : 'inherit'};
  cursor: pointer;
`
const SubText = styled('span')`
  display: flex;
  flex-flow: row;
  align-items: center;
  font-weight: 500;
  & > *:not(:first-child){
    margin-left: 5px;
    &:before{
      content: ' - ';
    }
  }
`

const StatisticsGrid = (props) => {
  const {
    location,
    filterActions,
    plansByAgentsList,
    config,
    customStore,
    handleSetPeriod,
    goOrders,
    goReturns,
    goBalances,
    goStatisticsByProducts
  } = props

  // useStates
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // Constants
  const today = moment().format('YYYY-MM-DD')

  // Data
  const configData = prop('data', config)
  const customStoreData = prop('data', customStore)
  const plansByAgentsListData = prop('data', plansByAgentsList)
  const plansByAgentsListLoading = prop('loading', plansByAgentsList)
  const realCurrency = prop('pRIMARYCURRENCY', configData)

  const userId = prop('id', customStoreData)
  const countMarkets = prop('countMarkets', plansByAgentsListData)
  const activeMarkets = prop('activeMarkets', plansByAgentsListData)
  const addMarket = prop('addMarket', plansByAgentsListData)
  const productCount = prop('productCount', plansByAgentsListData)
  const divisions = prop('divisions', plansByAgentsListData)
  const totalRealPrice = prop('realTotalPrice', plansByAgentsListData)
  const totalOrderPrice = prop('orderTotalPrice', plansByAgentsListData)
  const totalOrderReturnPrice = prop('orderReturnTotalPrice', plansByAgentsListData)
  const orderTotalCount = prop('orderTotalCount', plansByAgentsListData)
  const orderReturnTotalCount = prop('orderReturnTotalCount', plansByAgentsListData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const beginDate = prop('beginDate', searchObj) || today
  const endDate = prop('endDate', searchObj) || today
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const isToday = (beginDate === today) && (endDate === today)
  const isMonth = (beginDate === firstDayOfMonth) && (endDate === lastDayOfMonth)

  // Permissions
  const userPerms = prop('userPerms', props)
  const isAdmin = prop('isAdmin', props)
  const supervisor = prop('supervisor', searchObj) || storageData('onlyMyStats').getValue()
  const hasPerms = checkPermissions(['can_see_total_stat_mobile'], userPerms)
  const supervisorWithPerms = (hasPerms || isAdmin) ? (supervisor || '0') : (supervisor || '1')

  // Initial values
  filterActions.initialValues = {
    beginDate,
    endDate
  }

  // Period
  const period =
    <>
      <CustomButton
        active={isToday}
        onClick={() => handleSetPeriod(today, today)}
      >
        За день
      </CustomButton>
      <CustomButton
        active={isMonth}
        onClick={() => handleSetPeriod(firstDayOfMonth, lastDayOfMonth)}
      >
        За месяц
      </CustomButton>
      <CustomButton
        active={!isToday && !isMonth}
        onClick={() => setFilterIsOpen(true)}
      >
        Своя дата
      </CustomButton>
    </>

  // Total stats
  const ordersText =
    <SubText>
      <TextStatus status={totalOrderPrice < 0 ? 'inactive' : 'active'}>{numberFormat(totalOrderPrice, realCurrency)}</TextStatus>
      <span>{numberFormat(orderTotalCount, 'шт')}</span>
    </SubText>
  const returnsText =
    <SubText>
      <TextStatus status={totalOrderReturnPrice > 0 ? 'inactive' : 'active'}>{numberFormat(totalOrderReturnPrice, realCurrency)}</TextStatus>
      <span>{numberFormat(orderReturnTotalCount, 'шт')}</span>
    </SubText>
  const totalStats =
    <TilesList
      title={'Все организации'}
    >
      <TileItem>
        <TileInner
          title={'Заказы'}
          text={+totalOrderPrice !== 0 ? ordersText : 'Заказов не было'}
          icon={<BagIcon />}
          iconColor={'#fff'}
          iconBackground={'#353535'}
        />
      </TileItem>
      <TileItem>
        <TileInner
          title={'Возвраты'}
          text={+totalOrderReturnPrice !== 0 ? returnsText : 'Возвратов не было'}
          icon={<BagReturnIcon />}
          iconColor={'#fff'}
          iconBackground={'#e66767'}
        />
      </TileItem>
      <TileItem>
        <TileInner
          title={'Оплаты'}
          text={
            <span>
              {(totalRealPrice && !isEmpty(totalRealPrice))
                ? mapIndexed((item, index) => {
                  const totalAmount = prop('totalAmount', item)
                  const currencyName = prop('currencyName', item)
                  return (
                    <React.Fragment key={index}>
                      <TextStatus bold status={totalAmount < 0 ? 'inactive' : 'active'}>
                        {numberFormat(totalAmount, currencyName)}
                      </TextStatus>
                      <br />
                    </React.Fragment>
                  )
                }, totalRealPrice)
                : 'Оплат не было'}
            </span>
          }
          icon={<CardIcon />}
          iconColor={'#fff'}
          iconBackground={'#6770e6'}
        />
      </TileItem>
    </TilesList>

  // Tiles list
  const ordersTiles = divisions && mapIndexed((item) => {
    const id = prop('id', item)
    const name = prop('name', item)
    const orderTotalPrice = prop('orderTotalPrice', item)
    const orderReturnTotalPrice = prop('orderReturnTotalPrice', item)
    const realPrice = prop('realPrice', item)
    const orderTotalCount = `${prop('orderTotalCount', item)}`
    const orderReturnCount = `${prop('orderReturnCount', item)}`
    const ordersDivisionText =
      <SubText>
        <TextStatus status={orderTotalPrice < 0 ? 'inactive' : 'active'}>{numberFormat(orderTotalPrice, realCurrency)}</TextStatus>
        <span>{numberFormat(orderTotalCount, 'шт')}</span>
      </SubText>
    const returnsDivisionText =
      <SubText>
        <TextStatus status={orderReturnTotalPrice > 0 ? 'inactive' : 'active'}>{numberFormat(orderReturnTotalPrice, realCurrency)}</TextStatus>
        <span>{numberFormat(orderReturnCount, 'шт')}</span>
      </SubText>

    return (
      <TilesList
        title={name}
        key={id}
      >
        <TileItem
          onClick={() => goOrders(userId, id)}
        >
          <TileInner
            title={'Заказы'}
            text={+orderTotalPrice !== 0 ? ordersDivisionText : 'Заказов не было'}
            icon={<BagIcon />}
            iconColor={'#fff'}
            iconBackground={'#353535'}
          />
        </TileItem>
        <TileItem
          onClick={() => goReturns(userId, id)}
        >
          <TileInner
            title={'Возвраты'}
            text={+orderReturnTotalPrice !== 0 ? returnsDivisionText : 'Возвратов не было'}
            icon={<BagReturnIcon />}
            iconColor={'#fff'}
            iconBackground={'#e66767'}
          />
        </TileItem>
        <TileItem
          onClick={() => goBalances(userId, id)}
        >
          <TileInner
            title={'Оплаты'}
            text={
              <span>
                {(realPrice && !isEmpty(realPrice))
                  ? mapIndexed((item, index) => {
                    const totalAmount = prop('totalAmount', item)
                    const currencyName = prop('currencyName', item)
                    return (
                      <React.Fragment key={index}>
                        <TextStatus bold status={totalAmount < 0 ? 'inactive' : 'active'}>
                          {numberFormat(totalAmount, currencyName)}
                        </TextStatus>
                        <br />
                      </React.Fragment>
                    )
                  }, realPrice)
                  : 'Оплат не было'}
              </span>
            }
            icon={<CardIcon />}
            iconColor={'#fff'}
            iconBackground={'#6770e6'}
          />
        </TileItem>
      </TilesList>
    )
  }, divisions)

  const marketsCharts =
    <TilesList>
      <li>
        <ChartBar
          styles={{margin: '0 12px !important', padding: '12px 0'}}
        >
          <Title>Общая клиентская база</Title>
          <ProgressBar
            maxProgress={+countMarkets}
            progressText={+countMarkets}
            color={'#69768D'}
          />
        </ChartBar>
        <ChartBar
          styles={{margin: '0 12px !important', padding: '12px 0'}}
        >
          <Title>Активная клиентская база</Title>
          <ProgressBar
            maxProgress={+countMarkets}
            progressText={+activeMarkets}
            color={'#f83b7f'}
          />
        </ChartBar>
        <ChartBar
          styles={{margin: '0 12px !important', padding: '12px 0'}}
        >
          <Title>Новая клиентская база</Title>
          <ProgressBar
            maxProgress={+countMarkets}
            progressText={+addMarket}
            color={'#6770e6'}
          />
        </ChartBar>
      </li>
    </TilesList>

  const byProducts =
    <TilesList
      title={'Товары'}
    >
      <TileItem
        onClick={() => goStatisticsByProducts(userId)}
      >
        <TileInner
          title={'Статистика'}
          text={'Видов продуктов ' + numberFormat(productCount)}
          icon={<ChartIcon />}
        />
      </TileItem>
    </TilesList>

  // Main content
  const mainContent =
    <>
      {marketsCharts}
      {length(divisions) !== 1 && totalStats}
      {ordersTiles}
      {byProducts}
    </>

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
      initialValues={filterActions.initialValues}
    >
      <StatisticsFilters
        setFilterIsOpen={setFilterIsOpen}
        searchObj={searchObj}
        hasPerms={hasPerms}
        isAdmin={isAdmin}
        {...filterActions}
      />
    </FiltersBar>

  // Render
  return (
    <>
      <MainBar
        title={+supervisorWithPerms ? 'Моя статистика' : 'Общая статистика'}
        firstBtn={
          <MainMenu />
        }
        lastBtn={filters}
      />
      <Container
        secondHeight={48}
      >
        <Div>
          {period}
        </Div>
        <Wrap>
          {plansByAgentsListLoading ? <Loader styles={{marginTop: '20px'}} /> : plansByAgentsListData ? mainContent : <NoResults />}
        </Wrap>
      </Container>
    </>
  )
}

export default StatisticsGrid
