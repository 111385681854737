import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'
import {path, prop} from 'ramda'
import {requestFundsCreateSerializer, requestFundsUpdateSerializer} from './serializers/requestFundsSerializer'

export const requestFundsListAction = params => {
  const searchQuery = prop('search', params)
  const pageQuery = prop('page', params)
  const searchQueryDecoded = searchQuery && decodeURI(searchQuery)
  const defaultParams = {
    search: searchQueryDecoded,
    page: pageQuery
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.REQUEST_FUNDS_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.REQUEST_FUNDS_LIST,
      payload
    })
  }
}

export const requestFundsDetailAction = (props) => {
  const requestFundsId = path(['query', 'detailId'], props) || path(['query', 'detailId'], props)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.REQUEST_FUNDS_ITEM, +requestFundsId))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.REQUEST_FUNDS_ITEM,
      payload
    })
  }
}

export const requestFundsCreateAction = (values) => {
  const data = requestFundsCreateSerializer(values)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.REQUEST_FUNDS_CREATE, data)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.REQUEST_FUNDS_CREATE,
      payload
    })
  }
}

// eslint-disable-next-line camelcase
export const requestFundsUpdateAction = ({open, next_status, ...values}) => {
  const data = requestFundsUpdateSerializer(values, open, next_status)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.REQUEST_FUNDS_UPDATE, +open), data)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.REQUEST_FUNDS_CREATE,
      payload
    })
  }
}

// delete
export const cancelFundsRequestAction = (values, id) => {
  const data = {
    status: 'cancelled',
    comment: prop('comment', values)
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.REQUEST_FUNDS_UPDATE, id), data)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_TRANSACTIONS_LIST,
      payload
    })
  }
}
