const firebase = require('firebase/app')

export const initialFirebase = () => {
  const config = {
    apiKey: 'AIzaSyDRJHMU_yQM000pMY5W7wfDUVeajvS-xe4',
    authDomain: 'wdandroid-1f19c.firebaseapp.com',
    databaseURL: 'https://wdandroid-1f19c.firebaseio.com',
    projectId: 'wdandroid-1f19c',
    storageBucket: 'wdandroid-1f19c.appspot.com',
    messagingSenderId: '954274476694',
    appId: '1:954274476694:web:63ba3b1f75f4182af4c8b7'
  }
  firebase.initializeApp(config)
}
