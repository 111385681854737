import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 16 16'} {...props}>
      <path d="M9.32737 2.52764L12.3787 5.57895L4.65493 13.3027L1.60532 10.2514L9.32737 2.52764ZM14.6941 1.79173L13.3333 0.43095C12.8074 -0.094944 11.9535 -0.094944 11.4258 0.43095L10.1223 1.73444L13.1736 4.78578L14.6941 3.26533C15.102 2.85742 15.102 2.19962 14.6941 1.79173ZM0.00849115 14.5403C-0.0470393 14.7902 0.178599 15.0141 0.428543 14.9534L3.82875 14.1289L0.779139 11.0776L0.00849115 14.5403Z" />
    </SvgIcon>

  )
}
