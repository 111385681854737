import React, {useState} from 'react'
import {path, prop, isEmpty, find, propEq} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'
import Button from 'components/Forms/Button'
import CardDescription from 'components/Cards/CardDescription'
import Timer from 'components/Timer'
import moment from 'moment'
import {
  Edit2,
  PlusCircle as AddIcon,
  Trash2 as TrashIcon
} from 'react-feather'
import NoResults from 'components/NoData/NoResults'
import numberFormat from 'helpers/numberFormat'
import mapIndexed from 'helpers/mapIndexed'
import {Table} from 'components/Table'
import {TextStatus} from 'components/Status/TextStatus'
import sumBy from 'helpers/sumBy'
import Form from 'components/Forms/Form'
import Modal from 'components/Modal/Modal'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import {parseParams} from 'helpers/url'
import BottomBar from 'components/Navigation/BottomBar'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  min-height: calc(100vh - 60px);
  padding: 14px 16px 64px;
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  p{
    color: ${({theme}) => theme.text.secondary};
  }
`
const EmptyList = styled('div')`
  padding: 12px 0;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
  button{
    margin-top: 10px;
  }
`
const descriptionStyles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  }
}

const AddProductsFormFields = (props) => {
  const {
    productIdParam,
    materialIdParam,
    openProductsModal
  } = props

  const amountName = openProductsModal ? 'amount_' + productIdParam : 'amount_' + materialIdParam

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'number'}
        name={amountName}
        label={'Количество'}
        required
        fullWidth
      />
      <Button
        text={'Применить'}
        fullWidth
      />
    </StyledWrap>
  )
}

const ProductionsBatchesDetailGrid = props => {
  const {
    goBack,
    match,
    manufactureBatchesDetail,
    goAddProducts,
    goAddMaterials,
    goEdit,
    handleSetAcceptorIdQuery,
    manufactureBatchesFinishCreate,
    addProductIdHandler,
    addMaterialIdHandler,
    location,
    manufactureBatchesProductEdit,
    manufactureBatchesProductDelete,
    manufactureBatchesMaterialEdit,
    manufactureBatchesMaterialDelete
  } = props

  // useStates
  const [openProductsModal, setOpenProductsModal] = useState(false)
  const [openMaterialsModal, setOpenMaterialsModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState({})

  // Data
  const manufactureBatchesDetailData = prop('data', manufactureBatchesDetail)
  const manufactureBatchesDetailLoading = prop('loading', manufactureBatchesDetail)

  // Redirects
  const batchesId = path(['params', 'batchesId'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)
  const materialIdParam = prop('materialId', searchObj)

  // Const
  const productName = path(['product', 'name'], manufactureBatchesDetailData)
  const status = prop('status', manufactureBatchesDetailData)
  const isAccepted = status === 'done'
  const descriptionStatus = status === 'canceled' ? 'inactive' : status === 'closed' ? 'active' : 'pending'
  const amount = prop('amount', manufactureBatchesDetailData)
  const startTime = prop('openedTime', manufactureBatchesDetailData)
  const endTime = prop('closedTime', manufactureBatchesDetailData)
  const startTimeFormated = moment(startTime).format('DD MMM YYYY HH:mm:ss')
  const endTimeFormated = moment(endTime).format('DD MMM YYYY HH:mm:ss')
  const measurementName = path(['product', 'measurement', 'name'], manufactureBatchesDetailData)
  const productsListData = prop('returns', manufactureBatchesDetailData)
  const materialsDataList = prop('writeoffs', manufactureBatchesDetailData)
  const amountSum = productsListData && sumBy(productsListData, ['amount'])
  const amountName = openProductsModal ? 'amount_' + productIdParam : 'amount_' + materialIdParam

  // InitialValues
  const customAmountName = 'amount_' + productIdParam
  const customAmountNameMaterial = 'amount_' + materialIdParam
  const productInitialCurrently = productsListData && find(propEq('id', +productIdParam))(productsListData)
  const materialInitialCurrently = materialsDataList && find(propEq('id', +materialIdParam))(materialsDataList)
  const productAmountValue = prop('amount', productInitialCurrently)
  const materialAmountValue = prop('amount', materialInitialCurrently)
  const initialValuesProducts = {
    [customAmountName]: productAmountValue && `${+productAmountValue}`
  }
  const initialValuesMaterials = {
    [customAmountNameMaterial]: materialAmountValue && +materialAmountValue
  }

  // addButtons
  const handleGoAddProducts = () => {
    goAddProducts(+batchesId)
    handleSetAcceptorIdQuery(1 || 1)
  }

  const handleGoAddMaterials = () => {
    goAddMaterials(+batchesId)
    handleSetAcceptorIdQuery(1 || 1)
  }

  // CardDescription
  const cardDescription =
    <TilesList>
      <TileItem
        styles={descriptionStyles}
      >
        <PhotoWithStatus
          status={descriptionStatus}
          size={'62px'}
          statusSize={'13px'}
          type={'product'}
        />
        <RightBar>
          <h3>{productName}</h3>
          <p>{numberFormat(amount, measurementName)}</p>
        </RightBar>
      </TileItem>
    </TilesList>

  // Time
  const time =
    <TilesList
      title={'Время'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Начало работы'} is={startTimeFormated} />
      </TileItem>
      {endTime &&
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Конец работы'} is={endTimeFormated} />
      </TileItem>}
      {!endTime &&
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Время работы'} is={<Timer startTime={startTime} />} />
      </TileItem>}
    </TilesList>

  // Materials
  const materialsList =
    <Table>
      <tbody>
        <tr>
          <th colSpan={'2'}>Название</th>
          <th>Кол&nbsp;-&nbsp;во</th>
        </tr>
        {materialsDataList && mapIndexed((item) => {
          const id = prop('id', item)
          const productName = path(['product', 'name'], item)
          const measurement = path(['product', 'measurement', 'name'], item)
          const amount = prop('amount', item)
          const handleClick = () => {
            addMaterialIdHandler(id)
            setOpenProductsModal(false)
            setOpenMaterialsModal(true)
            setSelectedProduct({name: productName, amount})
          }

          return (
            <tr
              onClick={!isAccepted ? handleClick : undefined}
              key={id}
            >
              <td colSpan={'2'}>{productName}</td>
              <td>{numberFormat(amount, measurement)}</td>
            </tr>
          )
        }, materialsDataList)}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(amountSum, 'ед')}</TextStatus></td>
        </tr>
      </tfoot>
    </Table>

  const materialsAddBtn =
    <AddIcon
      color={'#6770e6'}
      onClick={() => handleGoAddMaterials()}
    />

  const materials =
    <TilesList
      title={'Список сырья'}
      button={!isAccepted && materialsAddBtn}
    >
      <TileItem
        height={'auto'}
        styles={{'& > div': {padding: '2px 0 !important'}}}
      >
        {materialsDataList && !isEmpty(materialsDataList)
          ? materialsList
          : <EmptyList>Нет данных</EmptyList>}
      </TileItem>
    </TilesList>

  // Products
  const productsList =
    <Table>
      <tbody>
        <tr>
          <th colSpan={'2'}>Название</th>
          <th>Кол&nbsp;-&nbsp;во</th>
        </tr>
        {productsListData && mapIndexed((item) => {
          const id = prop('id', item)
          const productName = path(['product', 'name'], item)
          const measurement = path(['product', 'measurement', 'name'], item)
          const amount = prop('amount', item)
          const handleClick = () => {
            addProductIdHandler(id)
            setOpenProductsModal(true)
            setOpenMaterialsModal(false)
            setSelectedProduct({name: productName, amount})
          }

          return (
            <tr
              onClick={!isAccepted ? handleClick : undefined}
              key={id}
            >
              <td colSpan={'2'}>{productName}</td>
              <td>{numberFormat(amount, measurement)}</td>
            </tr>
          )
        }, productsListData)}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(amountSum, 'ед')}</TextStatus></td>
        </tr>
      </tfoot>
    </Table>

  const productsAddBtn =
    <AddIcon
      color={'#6770e6'}
      onClick={() => handleGoAddProducts()}
    />

  const products =
    <TilesList
      title={'Список товаров'}
      button={!isAccepted && productsAddBtn}
    >
      <TileItem
        height={'auto'}
        styles={{'& > div': {padding: '2px 0 !important'}}}
      >
        {productsListData && !isEmpty(productsListData)
          ? productsList
          : <EmptyList>Нет данных</EmptyList>}
      </TileItem>
    </TilesList>

  // Submits
  const buttonFunc = () => {
    manufactureBatchesFinishCreate.onSubmit({
      id: batchesId
    })
  }
  const handleAddProductsSubmit = values => {
    manufactureBatchesProductEdit.onSubmit({
      amount: prop(amountName, values)
    })
    setTimeout(() => setOpenProductsModal(false), 0)
  }
  const handleAddMaterialsSubmit = values => {
    manufactureBatchesMaterialEdit.onSubmit({
      amount: prop(amountName, values)
    })
    setTimeout(() => setOpenMaterialsModal(false), 0)
  }
  const handleDeleteProduct = () => {
    manufactureBatchesProductDelete.onSubmit()
    setTimeout(() => setOpenProductsModal(false), 0)
  }
  const handleDeleteMaterial = () => {
    manufactureBatchesMaterialDelete.onSubmit()
    setTimeout(() => setOpenMaterialsModal(false), 0)
  }

  // Modals
  const addProductsModal =
    <Modal
      open={openProductsModal || openMaterialsModal}
      isOpen={openProductsModal ? setOpenProductsModal : setOpenMaterialsModal}
      title={prop('name', selectedProduct) || (openProductsModal ? 'Добавление товара' : 'Добавление сырья')}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={openProductsModal ? handleDeleteProduct : handleDeleteMaterial} />}
    >
      <Form
        onSubmit={openProductsModal ? handleAddProductsSubmit : handleAddMaterialsSubmit}
        initialValues={openProductsModal ? initialValuesProducts : initialValuesMaterials}
      >
        <AddProductsFormFields
          openProductsModal={openProductsModal}
          productIdParam={productIdParam}
          materialIdParam={materialIdParam}
        />
      </Form>
    </Modal>

  // Main
  const mainContent =
    <>
      {cardDescription}
      {time}
      {materials}
      {products}
      {!endTime &&
      <BottomBar>
        <Button
          text={'Завершить партию'}
          type={'button'}
          styles={{marginTop: 'auto !important'}}
          onClick={() => buttonFunc()}
          fullWidth
        />
      </BottomBar>}
    </>

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack()}
        title={'Партия №' + batchesId}
        buttons={[{
          icon: <Edit2 />,
          onClick: () => goEdit(batchesId)
        }]}
      />
      <Container>
        <Wrap>
          {manufactureBatchesDetailLoading ? <Loader styles={{marginTop: '20px'}} /> : manufactureBatchesDetailData ? mainContent : <NoResults />}
        </Wrap>
      </Container>
      {addProductsModal}
    </>
  )
}

export default ProductionsBatchesDetailGrid
