import React from 'react'
import {path, pathOr, prop} from 'ramda'
import {Modal} from 'components/Modal'
import Form from 'components/Forms/Form'
import {Trash2 as TrashIcon} from 'react-feather'
import checkPermissions from 'helpers/checkPermissions'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import styled from 'styled-components'
import {parseParams} from 'helpers/url'

const StyledWrap = styled('div')`
  ${({styles}) => styles};
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const AddProductsFormFields = props => {
  const {
    productIdParam,
    permissions,
    isAdmin
  } = props

  const amountName = 'amount_' + productIdParam
  const customPriceName = 'customPrice_' + productIdParam
  const hasPermsAnyPrice = checkPermissions(['can_set_any_price'], permissions)

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'number'}
        name={customPriceName}
        label={'Цена'}
        required
        disabled={!hasPermsAnyPrice && !isAdmin}
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        name={amountName}
        label={'Количество'}
        required
        fullWidth
      />
      <Button
        text={'Применить'}
        styles={{marginTop: '10px !important'}}
        fullWidth
      />
    </StyledWrap>
  )
}

const AddProductsModal = props => {
  const {
    modalOpen,
    setModalOpen,
    selectedProduct,
    initialValues,
    handleSubmit,
    handleDelete,
    location,
    customStore
  } = props

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)

  // Perms
  const permissions = pathOr([], ['data', 'permissions'], customStore)
  const isAdmin = path(['data', 'isSuperuser'], customStore)

  // AddProductsForm
  const addProductsForm =
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <AddProductsFormFields
        productIdParam={productIdParam}
        selectedProduct={selectedProduct}
        permissions={permissions}
        isAdmin={isAdmin}
      />
    </Form>

  // Render
  return (
    <Modal
      open={modalOpen}
      isOpen={setModalOpen}
      title={prop('name', selectedProduct) || 'Добавление продукта'}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={() => handleDelete()} />}
    >
      {addProductsForm}
    </Modal>
  )
}

export default AddProductsModal
