import React from 'react'
import PropTypes from 'prop-types'
import {map, omit, path, pathOr, pipe, prop} from 'ramda'
import {connect} from 'react-redux'
import checkPermissions from 'helpers/checkPermissions'
import {setOpenAlert} from '../../helpers/setOpenAlert'

const enhance = connect(state => ({
  userPerms: pipe(pathOr([], ['customStore', 'data', 'permissions']), map(prop('codename')))(state),
  isAdmin: path(['customStore', 'data', 'isSuperuser'], state)
}))

const Perms = props => {
  const {
    me,
    perms,
    children,
    userPerms,
    isAdmin,
    withAlert,
    onClick,
    log,
    ...defaultProps
  } = props

  const hasPerms = checkPermissions(perms, userPerms)
  const onClickActive = (!hasPerms && !isAdmin) ? () => setOpenAlert(withAlert) : onClick
  const filteredProps = omit(['dispatch'], defaultProps)
  const finalProps = {...filteredProps, onClick: onClickActive}
  if (!hasPerms && !isAdmin && withAlert) {
    return React.cloneElement(children, finalProps)
  } else if (!hasPerms && !isAdmin) {
    return null
  } else {
    return React.cloneElement(children, finalProps)
  }
}

Perms.propTypes = {
  perms: PropTypes.array
}
Perms.defaultProps = {
  perms: [],
  withAlert: false
}

export default enhance(Perms)
