import {prop} from 'ramda'

export const productsListSerializer = params => {
  const search = prop('search', params)

  return {
    search,
    page_size: 15
  }
}
