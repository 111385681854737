import React from 'react'
import styled from 'styled-components'
import {path} from 'ramda'
import PropTypes from 'prop-types'
import Button from '../Forms/Button'
import Loader from 'components/Loader'

const BGMusk = styled('div')`
  background: ${({theme}) => theme.background.mask};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  z-index: 1000;
  margin: 0 !important;
`
const ModalContent = styled('div')`
  background: #fff;
  border-radius: 12px;
  padding: 18px 16px;
  display: ${({isOpen}) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  left: 50%;
  right: 0;
  bottom: 2%;
  transition: all .2s ease-out;
  transform: translateX(-50%);
  max-width: 375px;
  width: 95%;
  z-index: 1000;
  max-height: 70vh;
`
const Title = styled('h4')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${({theme}) => theme.palette.black};
  h2{
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
`
const InContent = styled('div')`
  font-size: 14px;
`
const TitleWrap = styled('div')`
  min-height: 35px;
  margin-right: 50px;
`
const Message = styled('div')`
  display: flex;
  align-items: center;
  min-height: 30px;
  margin-bottom: 18px;
  line-height: 20px;
  font-size: 14px;
  color: ${({theme}) => theme.text.secondary};
`

const GroupButton = styled('div')`
  display:flex;
  justify-content: space-between;
  & button {
    width: 50%
  }
  & > :first-child {
    margin-right: 10px
  }
`

const ModalConfirm = (props) => {
  const {
    open,
    isOpen,
    type,
    message,
    loading,
    onClick,
    onCancel,
    submitText,
    cancelText
  } = props

  const typesList = {
    submit: {
      title: 'Подтверждение',
      submitText: 'Подтвердить'
    },
    delete: {
      title: 'Подтвердите удаление',
      submitText: 'Удалить',
      background: '#e66767'
    },
    cancel: {
      title: 'Подтвердите отмену',
      submitText: 'Подтвердить'
    },
    clean: {
      title: 'Подтвердите очистку',
      submitText: 'Очистить'
    }
  }

  const title = path([type, 'title'], typesList)
  const submitTextByType = path([type, 'submitText'], typesList)
  const cancelTextByType = path([type, 'cancelText'], typesList)
  const submitBackground = path([type, 'background'], typesList)
  const onModalClose = () => isOpen(false)
  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [open])

  return (
    <>
      <BGMusk
        isOpen={open}
        onClick={() => {
          onModalClose()
          onCancel && onCancel()
        }}
      />
      <ModalContent isOpen={open}>
        <TitleWrap>
          {title && <Title>{title}</Title>}
          {loading &&
          <Loader
            styles={{
              margin: '0 0 0 auto',
              position: 'absolute',
              right: '20px',
              top: '23px'
            }}
            size={'.5'}
          />}
        </TitleWrap>
        <InContent>
          {message && <Message>{message}</Message>}
          <GroupButton>
            <Button
              text={cancelText || cancelTextByType || 'Отмена'}
              type={'button'}
              secondary
              onClick={() => {
                isOpen(false)
                onCancel && onCancel()
              }}
              styles={{height: 'auto'}}
            />
            <Button
              text={submitText || submitTextByType}
              type={onClick ? 'button' : 'submit'}
              onClick={onClick}
              background={submitBackground}
              disabled={loading}
              styles={{height: 'auto'}}
            />
          </GroupButton>
        </InContent>
      </ModalContent>
    </>
  )
}

ModalConfirm.propTypes = {
  type: PropTypes.oneOf(['delete', 'cancel', 'submit', 'clean']).isRequired,
  open: PropTypes.bool.isRequired,
  isOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  message: PropTypes.any
}

export default ModalConfirm
