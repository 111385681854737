import React from 'react'
import {Field} from 'react-final-form'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField'
import {prop} from 'ramda'
import moment from 'moment'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = () => {
  const statusesOptions = [
    {value: '1', label: 'В ожидании'},
    {value: '2', label: 'В ходе выполнения'},
    {value: '3', label: 'Завершено'},
    {value: '4', label: 'Отменен'}
  ]

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'text'}
        name={'order'}
        label={'№ заказа'}
        fullWidth
      />
      <Field
        component={UniversalSearchField}
        name={'product'}
        label={'Товар'}
        api={API.MARKETS_PRODUCTS_LIST}
        params={{page_size: '1000'}}
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'statuses'}
        label={'Статус'}
        options={statusesOptions}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'beginDate'}
        label={'Создан с'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDate'}
        label={'Создан до'}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    onChangeFilter,
    setFilterIsOpen,
    initialValues
  } = props

  const handleSubmit = (values) => {
    const beginDate = prop('beginDate', values)
    const endDate = prop('endDate', values)
    const beginDeliveryDate = prop('beginDeliveryDate', values)
    const endDeliveryDate = prop('endDeliveryDate', values)
    const product = prop('product', values)
    const order = prop('order', values)
    const statuses = prop('statuses', values)

    onChangeFilter({
      beginDate: beginDate && moment(beginDate).format('YYYY-MM-DD'),
      endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
      beginDeliveryDate: beginDeliveryDate && moment(beginDeliveryDate).format('YYYY-MM-DD'),
      endDeliveryDate: endDeliveryDate && moment(endDeliveryDate).format('YYYY-MM-DD'),
      product: product && +product,
      order: order,
      statuses: statuses && +statuses
    })
    setFilterIsOpen(false)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormFields />
    </Form>
  )
}

export default Filter
