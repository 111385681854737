import React from 'react'
import {path, prop} from 'ramda'
import mapIndexed from 'helpers/mapIndexed'
import NoResults from 'components/NoData/NoResults'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {parseParams} from 'helpers/url'
import {marketsTelegramsSerializer} from 'containers/Clients/actions/serializers/marketsSerializer'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import TelegramsItem from 'containers/Clients/components/TelegramsItem/TelegramsItem'
import CardDescription from 'components/Cards/CardDescription'

const MarketsTelegramsGrid = (props) => {
  const {
    goBack,
    goCreate,
    location,
    marketsTelegrams,
    handleSetTelegramsId
  } = props

  // Data
  const marketsTelegramsData = prop('data', marketsTelegrams)

  // Redirects
  const searchHistory = prop('search', location)
  const searchObj = parseParams(searchHistory)
  const marketId = path(['match', 'params', 'id'], props)

  // Main list
  const mainList = marketsTelegramsData &&
    mapIndexed((item) => {
      const id = prop('id', item)
      const phone = prop('phone', item)
      const activatedDate = prop('activatedDate', item)
      const createdDate = prop('createdDate', item)
      const createdByFN = path(['createdBy', 'firstName'], item)
      const createdBySN = path(['createdBy', 'secondName'], item)
      const createdBy = createdByFN + ' ' + createdBySN
      const userFN = prop('firstName', item)
      const userSN = prop('lastName', item)
      const user = (userFN || userSN) ? userFN + ' ' + userSN : null

      return (
        <TelegramsItem
          key={id}
          id={id}
          name={phone}
          setQuery={handleSetTelegramsId}
          searchObj={searchObj}
          activatedDate={activatedDate}
          createdDate={createdDate}
        >
          <CardDescription it={'Пользователь'} is={user} />
          <CardDescription it={'Создал'} is={createdBy} />
        </TelegramsItem>
      )
    }, marketsTelegramsData)

  // Menus list
  const menusArr = [
    {
      title: 'Отправить приглашение',
      onClick: () => goCreate(marketId),
      withAlert: true,
      perms: ['add_market_worker']
    }
  ]

  // Content
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_TELEGRAMS}
        actionType={actionTypes.MARKETS_TELEGRAMS}
        emptyData={<li><NoResults /></li>}
        params={marketsTelegramsSerializer(searchObj, props)}
        list={mainList || []}
      />
      <SecondMenu
        title={'Дополнительные функции'}
        menusArr={menusArr}
        button={
          <Fab
            text={'Дополнительно'}
            withfixed={'true'}
          >
            <Plus size={20} color={'#fff'} />
          </Fab>
        }
      />
    </>

  return (
    <>
      <DetailBar
        title={'Подписчики'}
        goBack={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default MarketsTelegramsGrid
