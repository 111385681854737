import React from 'react'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import {path, prop} from 'ramda'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import * as serializers from 'containers/Cars/actions/serializers/carsSerializer'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import OrdersItem from 'containers/Cars/components/OrdersItem/OrdersItem'
import numberFormat from 'helpers/numberFormat'
import moment from 'moment'
import {TextStatus} from 'components/Status/TextStatus'

const CarsOrdersGrid = (props) => {
  const {
    match,
    location,
    goBack,
    goDetail,
    carsOrdersList
  } = props

  // Data
  const carsOrdersListData = prop('data', carsOrdersList)

  // Redirects
  const carId = path(['params', 'id'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // MainList
  const mainList = carsOrdersListData && mapIndexed((item) => {
    const id = prop('id', item)
    const km = prop('km', item)
    const currency = path(['currency', 'name'], item)
    const finishTime = prop('finishTime', item)
    const status = prop('status', item)
    const statusText = status === 'finish'
      ? 'Закончено'
      : status === 'cancel'
        ? 'Отменено'
        : 'В работе'
    const dateWithKm =
      finishTime
        ? numberFormat(km, 'км') + ' - ' + moment(finishTime).format('DD MMM YYYY')
        : <TextStatus bold status={'active'}>В работе</TextStatus>
    const totalPrice = prop('totalPrice', item) || 0

    return (
      <OrdersItem
        key={id}
        orderNumber={id}
        totalPrice={finishTime && (totalPrice || 0)}
        currency={currency}
        date={dateWithKm}
        onClick={() => goDetail(carId, id)}
        status={status}
        statusText={statusText}
      />
    )
  }, carsOrdersListData)

  // Main content
  const mainContent =
    <>
      <InfiniteScroll
        api={API.CARS_ORDERS_LIST}
        actionType={actionTypes.CARS_ORDERS_LIST}
        emptyData={<li><NoResults /></li>}
        params={serializers.carsOrdersListSerializer(searchObj, props)}
        list={mainList || []}
      />
    </>

  return (
    <>
      <DetailBar
        goBack={() => goBack(carId)}
        title={'Посещения'}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default CarsOrdersGrid
