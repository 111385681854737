import React, {useState} from 'react'
import MainBar from 'components/Navigation/TopBar/MainBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import ExtensionsItem from 'containers/Extensions/components/ExtensionsItem/ExtensionsItem'
import moment from 'moment'
import mapIndexed from 'helpers/mapIndexed'
import {prop} from 'ramda'
import Loader from 'components/Loader'
import CustomExtensionsItem from 'containers/Extensions/components/CustomExtensionsItem/CustomExtensionsItem'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  padding: 14px 16px;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const ExtensionsGrid = props => {
  const {
    config
  } = props

  // useState
  const [selectedExtension, setSelectedExtension] = useState(null)
  // eslint-disable-next-line no-console
  console.warn(selectedExtension, 'selectedExtension')

  // Data
  const configData = prop('data', config)
  const configLoading = prop('loading', config)
  const defaultCurrency = prop('pRIMARYCURRENCY', configData)

  // StaticData
  const staticData = [
    {
      title: 'Оплата PayMe',
      description: 'Созерцание непредсказуемо. Гедонизм осмысляет дедуктивный метод. Созерцание непредсказуемо. Импликация, следовательно.',
      date: null,
      switcherName: 'ext_1',
      price: 90000,
      switcherValue: false
    },
    {
      title: 'Оплата Click',
      description: 'Созерцание непредсказуемо. Гедонизм осмысляет дедуктивный метод. Созерцание непредсказуемо. Импликация, следовательно.',
      date: null,
      switcherName: 'ext_2',
      price: 70000,
      switcherValue: false
    },
    {
      title: 'Статистика клиентов',
      description: 'Созерцание непредсказуемо. Гедонизм осмысляет дедуктивный метод. Созерцание непредсказуемо. Импликация, следовательно.',
      date: moment().format('DD MMM YYYY'),
      switcherName: 'ext_3',
      price: 80000,
      switcherValue: true
    },
    {
      title: 'Неограниченная иерархия типов',
      description: 'Созерцание непредсказуемо. Гедонизм осмысляет дедуктивный метод. Созерцание непредсказуемо. Импликация, следовательно.',
      date: null,
      switcherName: 'ext_4',
      price: 80000,
      switcherValue: false
    },
    {
      title: 'Новый вид оформления заказов',
      description: 'Созерцание непредсказуемо. Гедонизм осмысляет дедуктивный метод. Созерцание непредсказуемо. Импликация, следовательно.',
      date: null,
      switcherName: 'ext_5',
      price: 80000,
      switcherValue: false
    }
  ]

  // MainContent
  const mainContent =
    <Wrap>
      {mapIndexed((item, index) => {
        const title = prop('title', item)
        const description = prop('description', item)
        const date = prop('date', item)
        const switcherName = prop('switcherName', item)
        const switcherValue = prop('switcherValue', item)
        const price = prop('price', item)
        const handleChange = event => {
          // eslint-disable-next-line no-console
          console.warn(event.target.checked, title)
          setSelectedExtension(switcherName)
        }

        return (
          <ExtensionsItem
            key={index}
            title={title}
            description={description}
            handleChange={handleChange}
            date={date}
            switcherName={switcherName}
            switcherValue={switcherValue}
            loading={`${index === 1}`}
            price={price}
            currency={defaultCurrency}
          />
        )
      }, staticData)}
      <CustomExtensionsItem
        title={'Заказать модуль'}
        handleSubmit={values => {
          // eslint-disable-next-line no-console
          console.warn(values, 'values')
        }}
      />
    </Wrap>

  // Render
  return (
    <>
      <MainBar
        title={'Расширения'}
        firstBtn={
          <MainMenu />
        }
      />
      <Container>
        {configLoading ? <Loader /> : mainContent}
      </Container>
    </>
  )
}

export default ExtensionsGrid
