import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
  body{
    line-height: 18px;
    color: ${({theme}) => theme.text.primary};
    background: ${({theme}) => theme.background.primary};
    font-size: ${({theme}) => theme.fontSize.primary};
  }
  h1, h2 {
    font-size: 34px;
    line-height: 41px;
    font-weight: 700;
    color: ${({theme}) => theme.palette.black};
    letter-spacing: 0.01em;
  }
  h3 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    color: ${({theme}) => theme.palette.black};
  }
  h4 {
    font-weight: 500;
    color: ${({theme}) => theme.palette.black};
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01px;
  }
  b{
    font-weight: 500;
  }
  button{
    border: none;
  }
  //input{
  //  user-select: none !important;
  //  -webkit-user-select: text !important;
  //}
`
