import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import {CornerDownRight as SubIcon, Trash2} from 'react-feather'

const Inner = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: ${({level}) => `${level * 10}px`};
  padding: 12px 0 12px ${({sub}) => sub ? '35px' : '0'};
  width: ${({withDelete}) => withDelete ? 'calc(100% - 60px)' : '100%'};
`
const Title = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 26px;
  h3{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  h5{
    text-align: center;
    background: ${({theme}) => theme.palette.blue};
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.016em;
    border-radius: 34px;
    padding: 4px 23px 4px 9px;
    font-weight: 400;
    position: relative;
    svg{
      color: #cbcef6;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      fill: #cbcef6;
    }
  }
`
const SubTitle = styled('p')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.text.secondary};
  line-height: 20px;
  ${({styles}) => styles};
`
const SubArrow = styled('span')`
  position: absolute;
  left: ${({level}) => `${level * 10 + 16}px`};
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    width: 20px;
    height: 20px;
    stroke-width: 2px;
    color: ${({theme}) => theme.palette.green};
  }
`
const Delete = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    color: ${({theme}) => theme.palette.red};
    width: 20px;
    height: 20px;
  }
`

const ProductsTypesItem = props => {
  const {
    title,
    level = 0,
    subTitle,
    onClick,
    sub = false,
    withDelete,
    onDelete
  } = props

  return (
    <TileItem
      styles={{'& > div': {padding: 0}}}
    >
      <Inner
        sub={sub}
        level={level}
        withDelete={withDelete}
        onClick={onClick}
      >
        {sub && <SubArrow level={level}><SubIcon /></SubArrow>}
        <Title><h3>{title}</h3></Title>
        <SubTitle>{subTitle}</SubTitle>
      </Inner>
      {withDelete &&
      <Delete
        onClick={onDelete}
      >
        <Trash2 />
      </Delete>}
    </TileItem>
  )
}

export default ProductsTypesItem
