import {prop} from 'ramda'

export const debtsSerializers = (params) => {
  const search = prop('search', params)

  return {
    search
  }
}

export const debtsDetailSerializers = () => {
  return {}
}
