import {compose, withHandlers} from 'recompose'
import MarketsReportsCreateGrid from 'containers/Clients/Grid/ReportsCreate/Grid'
import {withCreate, withFetchList, withFilter, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  marketsItemAction,
  marketsReportsCreateAction
} from 'containers/Clients/actions/marketsAction'
import {marketsReportCreateSerializer} from '../../actions/serializers/marketsSerializer'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'

export default compose(
  connect(state => {
    const config = prop('config', state)
    const marketsProductsList = prop('marketsProductsList', state)
    return {
      config,
      marketsProductsList
    }
  }),
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: STATES.MARKETS_ITEM,
    pickParams: []
  }),
  withCreate({
    stateName: STATES.MARKETS_REPORTS_CREATE,
    action: marketsReportsCreateAction,
    serializer: marketsReportCreateSerializer,
    key: STATES.MARKETS_REPORTS_CREATE,
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    propFromParams: 'id',
    successMessage: 'Фотоотчёт успешно отправлен'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withFilter({
    fields: ['search']
  }),
  withHandlers({
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(MarketsReportsCreateGrid)
