import {compose} from 'recompose'
import {withRedirect} from 'hocs'
import {prop} from 'ramda'
import {connect} from 'react-redux'
import MarketsReportsGrid from 'containers/Clients/Grid/Reports/Grid'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const marketsReports = prop('marketsReports', state)
    return {
      marketsReports
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_REPORTS_DETAIL_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goReportsCreate',
    redirectUrl: ROUTES.MARKETS_REPORTS_CREATE_URL,
    withCurrentParams: true
  })
)(MarketsReportsGrid)
