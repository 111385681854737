import React from 'react'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import NoResults from 'components/NoData/NoResults'
import mapIndexed from 'helpers/mapIndexed'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {MainBar} from 'components/Navigation/TopBar'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import CardDescription from 'components/Cards/CardDescription'
import * as serializers from '../actions/serializers/requestFundsSerializer'
import * as ROUTES from 'constants/routes'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Fab from 'components/Forms/Fab'
import {CheckCircle, Clock, CreditCard, DollarSign, Plus, Trash2 as TrashIcon, UserX} from 'react-feather'
import MainMenu from 'components/Navigation/components/MainMenu'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import RequestFundsFilters from './RequestFundsFilters'
import RequestFundsItem from '../components/RequestFundsItem/RequestFundsItem'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import {
  REQUEST_FUNDS_APPROVED,
  REQUEST_FUNDS_CANCELLED, REQUEST_FUNDS_GIVEN,
  REQUEST_FUNDS_PENDING, REQUEST_FUNDS_STATUS, selectValueFrom
} from 'constants/backendConstant'
import {Modal} from 'components/Modal'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import dateTimeFormat from '../../../helpers/dateTimeFormat'
import {trimString} from '../../../helpers/trimString'
import Perms from '../../../components/Perms/Perms'
import Loader from 'components/Loader'

const TabsBar = styled('div')`
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e4e4e6;
`

const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 99;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`

const GroupButtons = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  & > :not(:last-child) {
    margin-right: 15px;
  }
`

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const DescriptionHead = styled('p')`
  margin-bottom: 20px !important;
`

const LogStyled = styled('div')`
  :not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e4e4e6;
  }
`

const RequestFundsGrid = props => {
  const {
    filterActions,
    openFilter,
    setOpenFilter,
    location,
    history,
    query,
    requestFundsList,
    requestFundsItem,
    handleUpdateDialog,
    handleDetailId,
    cancelModal
  } = props
  // tabs

  const valueTab = prop('tab', query) || REQUEST_FUNDS_PENDING
  const tabChange = (event, newValue) => {
    history.push({
      search: appendParamsToUrl({tab: newValue}, path(['location', 'search'], history))
    })
  }
  const tabColor = (value) => {
    const valueTabSelected = (valueTab === value) ? value : ''
    switch (valueTabSelected) {
    case REQUEST_FUNDS_PENDING: return '#fbb432'
    case REQUEST_FUNDS_APPROVED: return '#6770e6'
    case REQUEST_FUNDS_GIVEN: return '#38d06c'
    case REQUEST_FUNDS_CANCELLED: return '#e66767'
    default: return '#000'
    }
  }

  // Option
  const menusArr = [
    {
      title: 'Создание запроса',
      onClick: () => history.push({pathname: ROUTES.REQUEST_FUNDS_CREATE_URL})
    }
  ]

  // Data
  const requestFundsListDataProp = prop('data', requestFundsList)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // DetailData
  const loadingDetail = prop('loading', requestFundsItem)
  const data = prop('data', requestFundsItem)
  const logsArray = prop('logs', data)
  const commentDetail = prop('comment', data)
  const logsDetail = logsArray && mapIndexed((item, index) => {
    const createdBy = path(['createdBy', 'firstName'], item) + ' ' + path(['createdBy', 'secondName'], item)
    const createdDate = prop('createdDate', item)
    const statusType = prop('status', item)
    const status = selectValueFrom(statusType, REQUEST_FUNDS_STATUS)
    const comment = prop('comment', item)
    const isStyle = {
      fontSize: '15px'
    }
    const itStyle = {
      fontSize: '15px'
    }
    return (
      <LogStyled key={index}>
        <CardDescription itStyle={{color: '#000', fontSize: '15px'}} isStyle={isStyle} it={createdBy} is={status} />
        {statusType === 'pending' ? false : <CardDescription it={'Коментарий'} is={comment} itStyle={itStyle} isStyle={isStyle} />}
        <CardDescription itStyle={itStyle} isStyle={isStyle} it={'Дата создания'} is={dateTimeFormat(createdDate)} />
      </LogStyled>
    )
  }, logsArray)

  const mainList = requestFundsListDataProp && mapIndexed((item, index) => {
    const createdDate = prop('createdDate', item)
    const comment = prop('comment', item)
    const status = prop('status', item)
    const id = prop('id', item)
    const amount = prop('amount', item)
    const currency = path(['currency', 'name'], item)
    const approveCheck = (status === 'pending') || (status === 'cancelled')
    const cancelCheck = (status === 'cancelled') || (status === 'given')
    const content = (
      <div>
        {logsDetail}
        <GroupButtons>
          {approveCheck &&
          <Perms
            perms={['can_approve_money_request']}
            onClick={() => handleUpdateDialog(id, 'approved')}
            withAlert
          >
            <Fab
              aria-label="add" size="medium" background={'#fff'}
            >
              <CreditCard size={20} color={'#6c7079'} />
            </Fab>
          </Perms>}
          {!cancelCheck &&
          <Perms
            perms={['can_delete_money_request']}
            onClick={() => cancelModal.onOpen(true)}
            withAlert
          >
            <Fab aria-label="add" size="medium" background={'#fff'}>
              <TrashIcon size={20} color={'#e66767'} />
            </Fab>
          </Perms>}
        </GroupButtons>
      </div>
    )
    return (
      <RequestFundsItem
        key={id}
        comment={trimString(comment)}
        detailId={id}
        date={dateTimeFormat(createdDate)}
        setQuery={handleDetailId}
        searchObj={searchObj}
        amount={amount}
        currency={currency}
      >
        {loadingDetail ? <Loader styles={{marginTop: '20px'}} /> : content}
      </RequestFundsItem>
    )
  }, requestFundsListDataProp)

  const searchForm = <DebounceSearch filterActions={filterActions} />

  const delForm =
    <Modal
      open={cancelModal.open}
      isOpen={cancelModal.onOpen}
      title={'Отменить запрос'}
    >
      <Form
        onSubmit={cancelModal.handleSubmit}
      >
        <StyledWrap>
          <DescriptionHead>{commentDetail}</DescriptionHead>
          <Field
            component={TextField}
            type={'text'}
            name={'comment'}
            label={'Коментарий'}
            fullWidth
          />
          <Button
            text={'Отменить'}
            fullWidth
          />
        </StyledWrap>
      </Form>
    </Modal>
  const mainContent =
    <>
      <InfiniteScroll
        api={API.REQUEST_FUNDS_LIST}
        actionType={actionTypes.REQUEST_FUNDS_LIST}
        emptyData={<li><NoResults /></li>}
        params={serializers.requestFundsListSerializer(searchObj)}
        list={mainList || []}
      />
    </>
  // Render
  return (
    <>
      <MainBar
        title={'Запросы средств'}
        secondHeight={100}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
          >
            <RequestFundsFilters
              setOpenFilter={setOpenFilter}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={100}
      >
        <TabsBar>
          <StyledTabs
            onChange={tabChange}
            value={valueTab}
            variant={'fullWidth'}
            component={'div'}
          >
            <Tab label={<Clock size={17} color={tabColor(REQUEST_FUNDS_PENDING)} />} value={REQUEST_FUNDS_PENDING} />
            <Tab label={<CheckCircle size={17} color={tabColor(REQUEST_FUNDS_APPROVED)} />} value={REQUEST_FUNDS_APPROVED} />
            <Tab label={<DollarSign size={17} color={tabColor(REQUEST_FUNDS_GIVEN)} />} value={REQUEST_FUNDS_GIVEN} />
            <Tab label={<UserX size={17} color={tabColor(REQUEST_FUNDS_CANCELLED)} />} value={REQUEST_FUNDS_CANCELLED} />
          </StyledTabs>
        </TabsBar>
        {mainContent}
        <Perms perms={['add_money_request']}>
          <SecondMenu
            title={'Дополнительно'}
            menusArr={menusArr}
            button={
              <Fab
                text={'Дополнительно'}
                withfixed={'true'}
              ><Plus size={20} color={'#fff'} />
              </Fab>
            }
          />
        </Perms>
        {delForm}
      </Container>
    </>
  )
}

export default RequestFundsGrid
