import React from 'react'
import styled from 'styled-components'

const Wrap = styled('label')`
  display: block;
  color: ${({theme}) => theme.text.secondary};
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  :after{
    display: ${({required}) => required ? 'inline-block' : 'none'};
    content: '\u00A0*';
    color: ${({theme}) => theme.palette.red};
  }
  ${({styles}) => styles};
`

const Label = (props) => {
  const {
    children,
    styles,
    required = false,
    ...defaultProps
  } = props

  return (
    <Wrap
      required={required}
      styles={styles}
      {...defaultProps}
    >
      {children}
    </Wrap>
  )
}

export default Label
