import {compose, mapProps, withHandlers} from 'recompose'
import StocksIncomeSupplyDetailGrid from 'containers/Stocks/containers/Income/Grid/SupplyDetail/Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import {connect} from 'react-redux'
import * as STATES from 'constants/states'
import {
  acceptSupplyProductIncomeAction,
  stockSupplyDetailAction
} from 'containers/Stocks/actions/stocksAction'
import moment from 'moment'

const today = moment().format('YYYY-MM-DD')

export default compose(
  connect((state, props) => ({
    customStore: prop('customStore', state),
    searchObj: parseParams(path(['location', 'search'], props))
  })),
  withFetchList({
    stateName: STATES.STOCKS_DETAILS,
    action: stockSupplyDetailAction,
    key: STATES.STOCKS_DETAILS
  }),
  withCreate({
    stateName: [STATES.STOCKS_ACCEPT_OUTCOME],
    action: acceptSupplyProductIncomeAction,
    key: [STATES.STOCKS_ACCEPT_OUTCOME],
    redirectUrl: ROUTES.STOCKS_INCOME_URL,
    successMessage: 'Успех'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.STOCKS_INCOME_URL,
    withCurrentParams: false
  }),
  withHandlers({
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  mapProps(({
    stocksAcceptOutcome,
    supplyIncome,
    modalOpenOrder,
    handleOpenConfirmSupply,
    handleCloseConfirmSupply,
    stocksDetails,
    ...props
  }) => {
    const orderTransferOpen = +prop('openConfirm', props.searchObj) > 0
    const supplyIncomeConfirmModal = {
      open: orderTransferOpen,
      onSubmit: stocksAcceptOutcome.onSubmit,
      initialValues: {
        date: today
      }
    }
    const list = {
      data: stocksDetails.data,
      loading: stocksDetails.loading
    }

    return {
      supplyIncomeConfirmModal,
      list,
      ...props
    }
  })
)(StocksIncomeSupplyDetailGrid)
