import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <path d="M14.2721 0.893738H3.60071C2.71804 0.893738 2 1.61178 2 2.49445V15.3713C2 16.066 2.44742 16.6527 3.06714 16.8737V6.13774C3.06714 5.42546 3.34488 4.75536 3.84875 4.2515L5.35776 2.74249C5.86163 2.23862 6.53172 1.96088 7.244 1.96088H15.7745C15.5535 1.34115 14.9668 0.893738 14.2721 0.893738Z" />
      <path d="M16.391 3C11.631 3 13.0163 3 8.3099 3V6.71137C8.3099 7.00495 8.06891 7.24966 7.77116 7.24966H4V17.4064C4 18.2853 4.72478 19 5.61621 19H16.391C17.282 19 18 18.2853 18 17.4064V4.58654C18 3.70792 17.282 3 16.391 3ZM15.3135 15.8128H6.69369C6.39595 15.8128 6.15495 15.5751 6.15495 15.2815C6.15495 14.988 6.39595 14.7503 6.69369 14.7503H15.3135C15.6112 14.7503 15.8522 14.988 15.8522 15.2815C15.8522 15.5751 15.6112 15.8128 15.3135 15.8128ZM15.3135 13.6879H6.69369C6.39595 13.6879 6.15495 13.4503 6.15495 13.1567C6.15495 12.8631 6.39595 12.6255 6.69369 12.6255H15.3135C15.6112 12.6255 15.8522 12.8631 15.8522 13.1567C15.8522 13.4503 15.6112 13.6879 15.3135 13.6879ZM15.3135 11.5631H6.69369C6.39595 11.5631 6.15495 11.3255 6.15495 11.0319C6.15495 10.7383 6.39595 10.5007 6.69369 10.5007H15.3135C15.6112 10.5007 15.8522 10.7383 15.8522 11.0319C15.8522 11.3255 15.6112 11.5631 15.3135 11.5631ZM15.3135 9.43827H6.69369C6.39595 9.43827 6.15495 9.20064 6.15495 8.90706C6.15495 8.61348 6.39595 8.37585 6.69369 8.37585H15.3135C15.6112 8.37585 15.8522 8.61348 15.8522 8.90706C15.8522 9.20064 15.6112 9.43827 15.3135 9.43827Z" />
      <path d="M7.244 3.02802C6.81672 3.02802 6.41448 3.19477 6.11226 3.49699L4.60325 5.006C4.2475 5.36171 4.13428 5.75947 4.13428 6.22944H7.33571V3.02802H7.244Z" />
    </SvgIcon>

  )
}
