import React, {useMemo} from 'react'
import {isEmpty, path, prop, propOr} from 'ramda'
import sumBy from 'helpers/sumBy'
import CardDescription from 'components/Cards/CardDescription'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'
import Form from 'components/Forms/Form'
import MarketsOrdersEditForm from 'containers/Clients/Grid/OrdersEdit/Grid/MarketsOrdersEditForm'
import TilesList from 'components/Cards/Tile/TilesList'
import mapIndexed from 'helpers/mapIndexed'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import styled from 'styled-components'
import {isAndroid} from 'constants/constants'
import moment from 'moment'
import toNumber from 'helpers/toNumber'
import normalizePhone from 'helpers/normalizers/normalizePhone'
import {onlyNumsAndSymbol} from 'helpers/onlyNumsAndSymbol'

const Wrap = styled('div')`
  padding: 14px 16px 0;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`
const Title = styled('h2')`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px;
  ${({styles}) => styles};
`
const DescriptionWrap = styled('ul')`
  & > * {
    padding: 12px 0;
    min-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
`

const MarketsOrdersEditTabOne = props => {
  const {
    marketsSalesOrdersDetail,
    products,
    setValue,
    setModalOpen,
    setSelectedProduct,
    addProductIdHandler,
    canIsLimited,
    marketsOrdersEdit,
    orderId,
    customStore,
    mapPerms,
    isAdmin
  } = props

  // Data
  const marketsSalesOrdersDetailData = prop('data', marketsSalesOrdersDetail)
  const marketsSalesOrdersDetailLoading = prop('loading', marketsSalesOrdersDetail)
  const marketsOrdersEditLoading = prop('loading', marketsOrdersEdit)
  const customStoreData = prop('data', customStore)

  // Const
  const me = path(['data', 'id'], customStore)
  const marketName = path(['market', 'name'], marketsSalesOrdersDetailData)
  const address = prop('deliveryAddress', marketsSalesOrdersDetailData)
  const secondPhone = prop('secondPhone', marketsSalesOrdersDetailData) || '+998'
  const marketId = path(['market', 'id'], marketsSalesOrdersDetailData)
  const stockId = path(['stock', 'id'], marketsSalesOrdersDetailData)
  const priceListId = path(['priceList', 'id'], marketsSalesOrdersDetailData)
  const deliveryManId = path(['deliveryMan', 'id'], marketsSalesOrdersDetailData)
  const currencyName = path(['currency', 'name'], marketsSalesOrdersDetailData)
  const currencyId = path(['currency', 'id'], marketsSalesOrdersDetailData)
  const totalPrice = sumBy(products, ['total_price'])
  const isPointOrder = prop('withBall', marketsSalesOrdersDetailData)
  const dateDelivery = prop('dateDelivery', marketsSalesOrdersDetailData)
  const dealType = prop('dealType', marketsSalesOrdersDetailData)
  const deliveryType = prop('deliveryType', marketsSalesOrdersDetailData)
  const paymentType = prop('paymentType', marketsSalesOrdersDetailData)
  const deliveryLat = prop('deliveryLat', marketsSalesOrdersDetailData)
  const deliveryLon = prop('deliveryLon', marketsSalesOrdersDetailData)
  const deliveryPrice = prop('deliveryPrice', marketsSalesOrdersDetailData)
  const comment = prop('comment', marketsSalesOrdersDetailData)
  const stocks = propOr([], 'stocks', customStoreData)

  // InitialValues
  const initialValues = {
    isConfirmed: true,
    dealType: dealType === '1',
    paymentType,
    dateDelivery: moment(dateDelivery).format('YYYY-MM-DD[T]HH:mm:ss'),
    deliveryType,
    deliveryAddress: address,
    secondPhone: normalizePhone(secondPhone || '+998'),
    stock: `${stockId}`,
    location: {lat: deliveryLat, lon: deliveryLon},
    comment,
    deliveryPrice: toNumber(deliveryPrice),
    deliveryMan: deliveryManId ? `${deliveryManId}` : null
  }

  // Description
  const description =
    <div>
      <Title>Описание</Title>
      <DescriptionWrap>
        <li><CardDescription it={'Сумма заказа'} is={<TextStatus bold status={'active'}>{numberFormat(totalPrice, currencyName)}</TextStatus>} /></li>
        <li
          onClick={() => isAndroid ? window.Android.call(marketName) : (window.location.href = `tel://${marketName}`)}
        >
          <CardDescription it={'Телефон'} is={<TextStatus bold status={'primary'}>{marketName}</TextStatus>} />
        </li>
      </DescriptionWrap>
    </div>

  // Submit
  const handleSubmit = values => {
    const dealType = prop('dealType', values)
    const dateDelivery = prop('dateDelivery', values)
    const deliveryMan = prop('deliveryMan', values)
    const secondPhoneValue = prop('secondPhone', values)

    const data = {
      id: orderId,
      market: marketId,
      deliveryAddress: prop('deliveryAddress', values),
      currency: currencyId,
      stock: prop('stock', values) || null,
      user: me,
      delivery_price: prop('deliveryPrice', values),
      second_phone: secondPhoneValue && secondPhoneValue.length > 7 ? onlyNumsAndSymbol(secondPhoneValue) : null,
      price_list: priceListId,
      payment_type: prop('paymentType', values),
      delivery_type: prop('deliveryType', values),
      deal_type: dealType ? '1' : '0',
      date_delivery: moment(dateDelivery).format('YYYY-MM-DD[T]HH:mm:ss'),
      comment: prop('comment', values),
      is_confirmed: prop('isConfirmed', values),
      delivery_lat: path(['location', 'lat'], values),
      delivery_lon: path(['location', 'lon'], values),
      deliveryMan: deliveryMan || null,
      products: products
    }

    marketsOrdersEdit.onSubmit(data)
  }

  // Form
  const form =
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      haveMutators
    >
      <MarketsOrdersEditForm
        isPointOrder={isPointOrder}
        mapPerms={mapPerms}
        isAdmin={isAdmin}
        stocks={stocks}
        marketsOrdersEditLoading={marketsOrdersEditLoading}
      />
    </Form>

  // ProductsMap
  const addButton = {
    text: 'Добавить',
    onClick: () => setValue(1)
  }
  const productsMap = useMemo(
    () => mapIndexed((item, index) => {
      const productId = prop('product', item)
      const productName = prop('product_name', item)
      const totalPrice = prop('total_price', item)
      const measurement = prop('measurement', item)
      const measurementName = path(['measurement', 'name'], item)
      const formattedMeasurement = measurementName && measurementName.slice(0, 3)
      const customPrice = prop('custom_price', item)
      const amount = prop('amount', item)
      const imageSrc = path(['image', 'file'], item)
      const image = prop('image', item)
      const realBalance = prop('balance', item)
      const limitBalance = prop('limitBalance', item)
      const balance = canIsLimited ? limitBalance : realBalance
      const boxes = prop('boxes', item)
      const transferPrice = prop('transfer_price', item)
      const cashPrice = prop('cash_price', item)

      const textAmount =
        <TextStatus
          bold
        >
          {numberFormat(amount, formattedMeasurement)}
        </TextStatus>
      const textPrice =
        <TextStatus
          bold
          status={'active'}
        >
          {numberFormat(totalPrice, currencyName)}
          {' по '}
          {numberFormat(customPrice, currencyName)}
        </TextStatus>

      const handleAddProductsOpen = () => {
        setModalOpen(true)
        setSelectedProduct({
          name: productName,
          amount: balance,
          measurement,
          image,
          boxes,
          transferPrice,
          cashPrice
        })
        addProductIdHandler(productId)
      }

      return (
        <ProductsItem
          name={productName}
          onClick={() => handleAddProductsOpen()}
          imageSrc={imageSrc}
          amount={textAmount}
          price={textPrice}
          key={index}
          isSelected
        />
      )
    }, products), [setSelectedProduct, products, addProductIdHandler, canIsLimited, currencyName, setModalOpen])

  // ProductsList
  const productsList =
    <>
      <TilesList
        title={'Товары'}
        textButton={addButton}
        style={{marginBottom: '50px', '& > h2': {padding: '0 16px'}}}
      >
        {!isEmpty(products) ? productsMap : <NoResults height={'200px'} text={'Добавьте товары'} />}
      </TilesList>
    </>

  // MainContent
  const mainContent =
    <>
      <Wrap>
        {description}
        {form}
      </Wrap>
      {productsList}
    </>

  return (
    <>
      {marketsSalesOrdersDetailLoading ? <Loader /> : marketsSalesOrdersDetailData ? mainContent : <NoResults />}
    </>
  )
}

export default MarketsOrdersEditTabOne
