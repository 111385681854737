import React from 'react'
import TilesList from 'components/Cards/Tile/TilesList'
import styled from 'styled-components'
import ProgressBar from 'components/Charts/ProgressBar'

const Wrap = styled('li')`
  padding: 12px 0;
  margin: 0 12px;
`
const Title = styled('span')`
  display: block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme}) => theme.text.secondary};
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: ${({theme}) => theme.border.primary};
`
const Graphs = styled('div')`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

const StatisticsDebtsItem = props => {
  const {
    name,
    maxProgress,
    startDebts,
    endDebts
  } = props

  const list =
    <>
      <ProgressBar
        maxProgress={+maxProgress}
        progressText={startDebts}
        color={'#e66767'}
      />
      <ProgressBar
        maxProgress={+maxProgress}
        progressText={endDebts}
        color={'#fbb432'}
      />
    </>

  return (
    <TilesList>
      <Wrap>
        <Title>{name}</Title>
        <Graphs>
          {list}
        </Graphs>
      </Wrap>
    </TilesList>
  )
}

export default StatisticsDebtsItem
