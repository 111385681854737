import {compose, mapPropsStream, withHandlers} from 'recompose'
import {withFilter, withRedirect} from 'hocs'
import {isEmpty, not, path, pipe, prop} from 'ramda'
import {connect} from 'react-redux'
import MarketsGrid from 'containers/Clients/Grid'
import * as ROUTES from 'constants/routes'
import {appendParamsToUrl} from 'helpers/url'
import {marketsAgentPlansAction} from 'containers/Clients/actions/marketsAction'
import {distinctUntilChanged, filter} from 'rxjs/operators'
import {getListParams} from 'helpers/get'
import {isEqualSearch} from 'helpers/isEquals'
import {storageData} from 'helpers/storage'

const getUserIdFromProps = path(['customStore', 'data', 'id'])

const loadWithGPS = props => {
  const userId = getUserIdFromProps(props)
  const currentLocation = storageData('location').getValue() || {}
  if (currentLocation.lat) {
    props.marketsAgentPlansAction({
      ...getListParams(props, ['planDate', 'search', 'planHide']),
      lat: currentLocation.lat,
      lon: currentLocation.lon,
      userId
    }, props)
  } else {
    props.marketsAgentPlansAction({
      ...getListParams(props, ['planDate', 'search', 'planHide']),
      userId
    }, props)
  }
}

export default compose(
  connect((state) => {
    const marketsAgentPlans = prop('marketsAgentPlans', state)
    const customStore = prop('customStore', state)
    const notificationGetNotViewed = prop('notificationGetNotViewed', state)
    const gpsSender = prop('gpsSender', state)
    return {
      customStore,
      marketsAgentPlans,
      gpsSender,
      notificationGetNotViewed
    }
  }, {
    marketsAgentPlansAction
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(null, getUserIdFromProps),
        filter(getUserIdFromProps)
      )
      .subscribe(loadWithGPS)

    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(['planDate', 'search', 'planHide', 'refresh']),
          path(['history', 'location', 'search'])
        ),
        filter(getUserIdFromProps),
        filter(path(['history', 'location', 'search'])),
        filter(
          pipe(
            prop('mapLocations'),
            isEmpty,
            not
          )
        )
      )
      .subscribe(loadWithGPS)
    return props$
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goReturnsCreate',
    redirectUrl: ROUTES.MARKETS_RETURNS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goVisitsCreate',
    redirectUrl: ROUTES.MARKETS_VISITS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrdersCreate',
    redirectUrl: ROUTES.MARKETS_ORDERS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goPaymentsCreate',
    redirectUrl: ROUTES.MARKETS_PAYMENTS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrdersDetail',
    redirectUrl: ROUTES.MARKETS_ORDERS_DETAIL_URL,
    withCurrentParams: true,
    newParams: {redirectTo: 'plans'}
  }),
  withFilter({
    fields: ['search', 'responsibleAgent', 'marketType', 'planDate', 'isActive', 'zone', 'marketTypeChild', 'debtors', 'planHide']
  }),
  withHandlers({
    handleSetRefresh: props => refresh => {
      props.history.push({
        search: appendParamsToUrl({refresh}, path(['history', 'location', 'search'], props))
      })
    },
    goYandexNav: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'navigator')
      } else {
        window.location.replace('yandexnavi://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
      }
    },
    goYandexMaps: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'yandex')
      } else {
        window.location.replace('yandexmaps://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
      }
    },
    goGoogleNav: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'google')
      } else {
        window.location.replace('google.navigation:q=' + lat + ',' + lon)
      }
    },
    goAppleMaps: () => (lat, lon) => {
      window.location.replace('maps://maps.google.com/maps?daddr=' + lat + ',' + lon + '&amp;ll=')
    }
  })
)(MarketsGrid)
