import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  branchesDetailAction
} from 'containers/Branches/actions/branchesActions'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const marketsSalesOrdersList = prop('marketsSalesOrdersList', state)
    return {
      marketsSalesOrdersList
    }
  }),
  withFetchList({
    stateName: STATES.BRANCHES_DETAIL,
    action: branchesDetailAction,
    key: STATES.BRANCHES_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_NEW_URL
  }),
  withHandlers({
    goYandexNav: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'navigator')
      } else {
        window.location.replace('yandexnavi://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
      }
    },
    goYandexMaps: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'yandex')
      } else {
        window.location.replace('yandexmaps://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
      }
    },
    goGoogleNav: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'google')
      } else {
        window.location.replace('google.navigation:q=' + lat + ',' + lon)
      }
    },
    goAppleMaps: () => (lat, lon) => {
      window.location.replace('maps://maps.google.com/maps?daddr=' + lat + ',' + lon + '&amp;ll=')
    }
  })
)(Grid)
