import * as ROUTES from './routes'

export default [
  {
    title: 'План доставки',
    url: ROUTES.MARKETS_URL,
    perms: ['can_see_delivery_plan'],
    counterName: 'yourPlan'
  },
  // {
  //   title: 'Доставка',
  //   url: ROUTES.ROOT_PATH,
  //   perms: ['can_see_delivery_plan'],
  //   label: true,
  //   subMenus: [
  //     // {
  //     //   title: 'Новые заказы',
  //     //   url: ROUTES.MARKETS_NEW_URL,
  //     //   perms: ['can_see_delivery_plan'],
  //     //   counterName: 'newOrders'
  //     // },
  //     {
  //       title: 'План',
  //       url: ROUTES.MARKETS_URL,
  //       perms: ['can_see_delivery_plan'],
  //       counterName: 'yourPlan'
  //     }
  //   ]
  // },
  // {
  //   title: 'Авто',
  //   url: ROUTES.CARS_URL,
  //   perms: ['show_cars']
  // },
  // {
  //   title: 'Финансы',
  //   url: ROUTES.FINANCES_URL,
  //   perms: ['financial_transactions']
  // },
  // {
  //   title: 'Планы',
  //   url: ROUTES.PLANS_URL,
  //   perms: ['can_get_list_supervisor']
  // },
  {
    title: 'Заказы',
    url: ROUTES.ORDERS_URL,
    params: {status: 5},
    counterName: 'unconfirmedOrdersCount',
    perms: ['can_see_orders']
  },
  {
    title: 'Продукты',
    url: ROUTES.PRODUCTS_URL,
    perms: ['frontend_products']
  },
  {
    title: 'Типы продуктов',
    url: ROUTES.PRODUCTS_TYPES_URL,
    perms: ['frontend_settings_product_type']
  },
  {
    title: 'Филиалы',
    url: ROUTES.BRANCHES_URL,
    perms: ['frontend_settings_stock']
  },
  {
    title: 'Сотрудники',
    url: ROUTES.USERS_URL,
    perms: ['frontend_users']
  },
  // {
  //   title: 'Запросы средств',
  //   url: ROUTES.REQUEST_FUNDS_URL,
  //   perms: ['frontend_money_request']
  // },
  {
    title: 'Отслеживание',
    url: ROUTES.TRACK_URL,
    android: 'openTrackingMap',
    perms: ['frontend_tracking']
  },
  // {
  //   title: 'Расширения',
  //   url: ROUTES.EXTENSIONS_URL,
  //   perms: ['frontend_extensions']
  // },
  {
    title: 'Настройки бота',
    url: ROUTES.SETTINGS_BOT_URL,
    perms: ['frontend_settings_bot']
  },
  {
    title: 'Статистика',
    url: ROUTES.STATISTICS_PRODUCTS_TYPES_URL,
    perms: ['frontend_statistics_products_types']
  }
  // {
  //   title: 'Склады',
  //   perms: ['frontend_remainder'],
  //   url: ROUTES.ROOT_PATH,
  //   label: true,
  //   subMenus: [
  //     {
  //       title: 'Приёмы',
  //       url: ROUTES.STOCKS_INCOME_URL,
  //       perms: ['frontend_remainder'],
  //       counterName: 'stocksIncome'
  //     },
  //     {
  //       title: 'Передачи',
  //       url: ROUTES.STOCKS_OUTCOME_URL,
  //       perms: ['frontend_remainder'],
  //       counterName: 'stocksOutcome'
  //     },
  //     {
  //       title: 'Остатки',
  //       url: ROUTES.STOCKS_RESTS_URL,
  //       perms: ['frontend_remainder']
  //     },
  //     {
  //       title: 'Подборы',
  //       url: ROUTES.STOCKS_SELECTIONS_URL,
  //       perms: ['frontend_remainder']
  //     }
  //   ]
  // },
  // {
  //   title: 'Производство',
  //   url: ROUTES.ROOT_PATH,
  //   perms: ['show_productions'],
  //   subMenus: [
  //     {
  //       title: 'Партии',
  //       url: ROUTES.PRODUCTIONS_BATCHES_URL,
  //       perms: ['show_productions']
  //     }
  //   ]
  // },
  // {
  //   title: 'POS - система',
  //   url: ROUTES.ROOT_PATH,
  //   checkPosConfig: true,
  //   perms: ['pos_system'],
  //   subMenus: [
  //     {
  //       title: 'Чеки',
  //       url: ROUTES.CHECKS_URL,
  //       perms: ['pos_system']
  //     },
  //     {
  //       title: 'Долги',
  //       url: ROUTES.DEBTS_URL,
  //       perms: ['pos_debt']
  //     },
  //     {
  //       title: 'Статистика',
  //       url: ROUTES.STATISTICS_POS_URL,
  //       perms: ['pos_statistics']
  //     }
  //   ]
  // }
]
