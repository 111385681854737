import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList} from 'hocs'
import {settingsBotAction, settingsBotEditAction} from 'containers/SettingsBot/actions/settingsBotActions'
import * as STATES from 'constants/states'

export default compose(
  withCreate({
    stateName: STATES.SETTINGS_BOT_EDIT,
    action: settingsBotEditAction,
    key: STATES.SETTINGS_BOT_EDIT,
    successMessage: 'Настройки бота успешно сохранены'
  }),
  withFetchList({
    stateName: STATES.SETTINGS_BOT,
    action: settingsBotAction,
    key: STATES.SETTINGS_BOT
  })
)(Grid)
