import {compose} from 'recompose'
import Grid from './Grid'
import {withFilter, withRedirect} from 'hocs'
import {connect} from 'react-redux'
import {prop} from 'ramda'
import * as ROUTES from 'constants/routes'

export default compose(
  connect(state => {
    const usersList = prop('usersList', state)
    return {
      usersList
    }
  }),
  withFilter({
    fields: ['search']
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.USERS_DETAIL_URL
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.USERS_CREATE_URL
  })
)(Grid)
