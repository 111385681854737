import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {
  productsTypesDeleteAction,
  productsTypesListAction
} from 'containers/Clients/actions/marketsAction'
import * as ROUTES from 'constants/routes'
import {path} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'

export default compose(
  withFetchList({
    stateName: STATES.PRODUCTS_TYPES_LIST,
    action: productsTypesListAction,
    key: STATES.PRODUCTS_TYPES_LIST,
    pickParams: ['search', 'reloadUI']
  }),
  withCreate({
    stateName: STATES.PRODUCTS_TYPES_DELETE,
    action: productsTypesDeleteAction,
    key: STATES.PRODUCTS_TYPES_DELETE,
    withCurrentParams: true,
    addParam: {reloadUI: Math.random()},
    redirectUrl: ROUTES.PRODUCTS_TYPES_URL,
    successMessage: 'Тип успешно удалён'
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.PRODUCTS_TYPES_CREATE_URL
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.PRODUCTS_TYPES_EDIT_URL
  }),
  withHandlers({
    handleSetSelectedType: props => selectedType => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({selectedType}, path(['history', 'location', 'search'], props))
      })
    },
    handleSetReloadUI: props => reloadUI => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({reloadUI}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
