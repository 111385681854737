const ID = ':id(\\d+)'
const selectionId = ':selectionId(\\d+)'
const batchesId = ':batchesId(\\d+)'

// Root path
export const ROOT_PATH = '/'

// Sign In
export const SIGN_IN_PATH = '/sign-in/'

// Clients
const MARKETS = 'clients'
export const MARKETS_PATH = `/${MARKETS}/`
export const MARKETS_URL = `/${MARKETS}/`
export const MARKETS_CREATE_PATH = `/${MARKETS}/clients-create/`
export const MARKETS_CREATE_URL = `/${MARKETS}/clients-create/`
export const MARKETS_DETAIL_PATH = `/${MARKETS}/${ID}/`
export const MARKETS_DETAIL_URL = `/${MARKETS}/%d/`
export const MARKETS_DETAIL_DESCRIPTION_PATH = `/${MARKETS}/:id/description/`
export const MARKETS_DETAIL_DESCRIPTION_URL = `/${MARKETS}/%d/description/`
export const MARKETS_ITEM_MAP_PATH = `/${MARKETS}/:id/map/`
export const MARKETS_ITEM_MAP_URL = `/${MARKETS}/%d/map/`
export const MARKETS_POINTS_PATH = `/${MARKETS}/:id/points/`
export const MARKETS_POINTS_URL = `/${MARKETS}/%d/points/`
export const MARKETS_ORDERS_PATH = `/${MARKETS}/:id/orders/`
export const MARKETS_ORDERS_URL = `/${MARKETS}/%d/orders/`
export const MARKETS_ORDERS_DETAIL_PATH = `/${MARKETS}/:id/orders/:orderId/`
export const MARKETS_ORDERS_DETAIL_URL = `/${MARKETS}/%d/orders/%d/`
export const MARKETS_ORDERS_CREATE_PATH = `/${MARKETS}/:id/orders-create/`
export const MARKETS_ORDERS_CREATE_URL = `/${MARKETS}/%d/orders-create/`
export const MARKETS_ORDERS_BONUS_CREATE_PATH = `/${MARKETS}/:id/orders/:orderId/orders-add-bonus/`
export const MARKETS_ORDERS_BONUS_CREATE_URL = `/${MARKETS}/%d/orders/%d/orders-add-bonus/`
export const MARKETS_ORDERS_EDIT_PATH = `/${MARKETS}/:id/orders/:orderId/orders-edit/`
export const MARKETS_ORDERS_EDIT_URL = `/${MARKETS}/%d/orders/%d/orders-edit/`
export const MARKETS_RETURNS_PATH = `/${MARKETS}/:id/returns/`
export const MARKETS_RETURNS_URL = `/${MARKETS}/%d/returns/`
export const MARKETS_RETURNS_DETAIL_PATH = `/${MARKETS}/:id/returns/:returnId/`
export const MARKETS_RETURNS_DETAIL_URL = `/${MARKETS}/%d/returns/%d/`
export const MARKETS_RETURNS_CREATE_PATH = `/${MARKETS}/:id/returns-create/`
export const MARKETS_RETURNS_CREATE_URL = `/${MARKETS}/%d/returns-create/`
export const MARKETS_PAYMENTS_CREATE_PATH = `/${MARKETS}/:id/payments-create/`
export const MARKETS_PAYMENTS_CREATE_URL = `/${MARKETS}/%d/payments-create/`
export const MARKETS_ITEM_BALANCES_PATH = `/${MARKETS}/:id/balances/`
export const MARKETS_ITEM_BALANCES_URL = `/${MARKETS}/%d/balances/`
export const MARKETS_ITEM_BALANCES_NOTIFICATION_PATH = `/${MARKETS}/:id/tasks-create/`
export const MARKETS_ITEM_BALANCES_NOTIFICATION_URL = `/${MARKETS}/%d/tasks-create/`
export const MARKETS_VISITS_PATH = `/${MARKETS}/:id/visits/`
export const MARKETS_VISITS_URL = `/${MARKETS}/%d/visits/`
export const MARKETS_VISITS_CREATE_PATH = `/${MARKETS}/:id/visits-create/`
export const MARKETS_VISITS_CREATE_URL = `/${MARKETS}/%d/visits-create/`
export const MARKETS_REPORTS_PATH = `/${MARKETS}/:id/reports/`
export const MARKETS_REPORTS_URL = `/${MARKETS}/%d/reports/`
export const MARKETS_REPORTS_CREATE_PATH = `/${MARKETS}/:id/reports-create/`
export const MARKETS_REPORTS_CREATE_URL = `/${MARKETS}/%d/reports-create/`
export const MARKETS_REPORTS_EDIT_PATH = `/${MARKETS}/:id/reports-edit/:reportId/`
export const MARKETS_REPORTS_EDIT_URL = `/${MARKETS}/%d/reports-edit/%d/`
export const MARKETS_REPORTS_DETAIL_PATH = `/${MARKETS}/:id/reports/:reportId/`
export const MARKETS_REPORTS_DETAIL_URL = `/${MARKETS}/%d/reports/%d/`
export const MARKETS_CONTRACTS_PATH = `/${MARKETS}/:id/contracts/`
export const MARKETS_CONTRACTS_URL = `/${MARKETS}/%d/contracts/`
export const MARKETS_CONTRACTS_CREATE_PATH = `/${MARKETS}/:id/contracts-create/`
export const MARKETS_CONTRACTS_CREATE_URL = `/${MARKETS}/%d/contracts-create/`
export const MARKETS_TELEGRAMS_PATH = `/${MARKETS}/:id/telegrams/`
export const MARKETS_TELEGRAMS_URL = `/${MARKETS}/%d/telegrams/`
export const MARKETS_TELEGRAM_CREATE_PATH = `/${MARKETS}/:id/telegrams-create/`
export const MARKETS_TELEGRAM_CREATE_URL = `/${MARKETS}/%d/telegrams-create/`
export const MARKETS_STATISTICS_MAIN_BY_PRODUCTS_PATH = `/${MARKETS}/:id/statistics-by-products/`
export const MARKETS_STATISTICS_MAIN_BY_PRODUCTS_URL = `/${MARKETS}/%d/statistics-by-products/`

// ClientsNew
const CLIENTS_NEW = 'clients-new'
export const MARKETS_NEW_PATH = `/${CLIENTS_NEW}/`
export const MARKETS_NEW_URL = `/${CLIENTS_NEW}/`
export const MARKETS_NEW_DETAIL_PATH = `/${CLIENTS_NEW}/${ID}/`
export const MARKETS_NEW_DETAIL_URL = `/${CLIENTS_NEW}/%d/`

// Statistics main
export const STATISTICS_MAIN = 'statistics-main'
export const STATISTICS_MAIN_PATH = `/${STATISTICS_MAIN}/`
export const STATISTICS_MAIN_URL = `/${STATISTICS_MAIN}/`
export const STATISTICS_MAIN_ORDERS_PATH = `/${STATISTICS_MAIN}/:userId/orders/:divisionId/`
export const STATISTICS_MAIN_ORDERS_URL = `/${STATISTICS_MAIN}/%d/orders/%d/`
export const STATISTICS_MAIN_RETURNS_PATH = `/${STATISTICS_MAIN}/:userId/returns/:divisionId/`
export const STATISTICS_MAIN_RETURNS_URL = `/${STATISTICS_MAIN}/%d/returns/%d/`
export const STATISTICS_MAIN_BALANCES_PATH = `/${STATISTICS_MAIN}/:userId/balances/:divisionId/`
export const STATISTICS_MAIN_BALANCES_URL = `/${STATISTICS_MAIN}/%d/balances/%d/`
export const STATISTICS_MAIN_BY_PRODUCTS_PATH = `/${STATISTICS_MAIN}/:userId/by-products/`
export const STATISTICS_MAIN_BY_PRODUCTS_URL = `/${STATISTICS_MAIN}/%d/by-products/`

// Statistics by agents
export const STATISTICS_BY_AGENTS = 'statistics-by-agents'
export const STATISTICS_BY_AGENTS_PATH = `/${STATISTICS_BY_AGENTS}/`
export const STATISTICS_BY_AGENTS_URL = `/${STATISTICS_BY_AGENTS}/`

// Statistics by debts
export const STATISTICS_DEBTS = 'statistics-debts'
export const STATISTICS_DEBTS_PATH = `/${STATISTICS_DEBTS}/`
export const STATISTICS_DEBTS_URL = `/${STATISTICS_DEBTS}/`

// Statistics ProductsTypes
export const STATISTICS_PRODUCTS_TYPES = 'statistics-products-types'
export const STATISTICS_PRODUCTS_TYPES_PATH = `/${STATISTICS_PRODUCTS_TYPES}/`
export const STATISTICS_PRODUCTS_TYPES_URL = `/${STATISTICS_PRODUCTS_TYPES}/`

// Finances
const FINANCES = 'finances'
export const FINANCES_PATH = `/${FINANCES}/`
export const FINANCES_URL = `/${FINANCES}/`
export const FINANCES_TRANSFER_CASH_CREATE_URL = `/${FINANCES}/transfer-cash-create`
export const FINANCES_TRANSACTIONS_PATH = `/${FINANCES}/${ID}/`
export const FINANCES_TRANSACTIONS_URL = `/${FINANCES}/%d/`
export const FINANCES_ACCEPT_CASH_URL = `/${FINANCES}/accept-cash/`
export const FINANCES_CLIENT_TRANSACTION_CREATE = `${FINANCES_ACCEPT_CASH_URL}client-transactions-create`
export const FINANCES_ACCEPT_PATH = `/${FINANCES}/accept-cash/`
export const FINANCES_ACCEPT_CASH_DETAIL_PATH = `${FINANCES_ACCEPT_PATH}client-transactions/`
export const FINANCES_ACCEPT_CASH_DETAIL_URL = `${FINANCES_ACCEPT_CASH_URL}client-transactions/`
export const FINANCES_DEBITS_CREATE_PATH = `/${FINANCES}/debits-create/`
export const FINANCES_DEBITS_CREATE_URL = `/${FINANCES}/debits-create/`
export const FINANCES_CREDITS_CREATE_PATH = `/${FINANCES}/credits-create/`
export const FINANCES_CREDITS_CREATE_URL = `/${FINANCES}/credits-create/`

// REQUEST_FUNDS
const REQUEST_FUNDS = 'request-funds'
export const REQUEST_FUNDS_PATH = `/${REQUEST_FUNDS}/`
export const REQUEST_FUNDS_URL = `/${REQUEST_FUNDS}/`
export const REQUEST_FUNDS_CREATE_PATH = `/${REQUEST_FUNDS}/request-funds-create/`
export const REQUEST_FUNDS_CREATE_URL = `/${REQUEST_FUNDS}/request-funds-create/`

// Notification
const NOTIFICATION = 'notifications'
export const NOTIFICATION_PATH = `/${NOTIFICATION}/`
export const NOTIFICATION_URL = `/${NOTIFICATION}/`

// UI Docs
const UI_DOCS = 'admin/ui-Docs'
export const UI_DOCS_URL = `/${UI_DOCS}/`
export const UI_DOCS_PATH = `/${UI_DOCS}/`

// Cars
const CARS = 'cars'
export const CARS_PATH = `/${CARS}/`
export const CARS_URL = `/${CARS}/`
export const CARS_DETAIL_PATH = `/${CARS}/${ID}/`
export const CARS_DETAIL_URL = `/${CARS}/%d/`
export const CARS_DESCRIPTION_PATH = `/${CARS}/${ID}/description/`
export const CARS_DESCRIPTION_URL = `/${CARS}/%d/description/`
export const CARS_ADD_PRODUCTS_PATH = `/${CARS}/${ID}/add-products/`
export const CARS_ADD_PRODUCTS_URL = `/${CARS}/%d/add-products/`
export const CARS_ADD_SERVICES_PATH = `/${CARS}/${ID}/add-services/`
export const CARS_ADD_SERVICES_URL = `/${CARS}/%d/add-services/`
export const CARS_CREATE_PATH = `/${CARS}/cars-create/`
export const CARS_CREATE_URL = `/${CARS}/cars-create/`
export const CARS_ORDERS_PATH = `/${CARS}/${ID}/orders/`
export const CARS_ORDERS_URL = `/${CARS}/%d/orders/`
export const CARS_ORDERS_DETAIL_PATH = `/${CARS}/${ID}/orders/:orderId/`
export const CARS_ORDERS_DETAIL_URL = `/${CARS}/%d/orders/%d/`
export const CARS_ORDERS_CANCEL_PATH = `/${CARS}/${ID}/orders/:orderId/orders-cancel/`
export const CARS_ORDERS_CANCEL_URL = `/${CARS}/%d/orders/%d/orders-cancel/`

// Stocks
// Stocks income
const STOCKS_INCOME = 'stocks-income'
export const STOCKS_INCOME_PATH = `/${STOCKS_INCOME}/`
export const STOCKS_INCOME_URL = `/${STOCKS_INCOME}/`
export const STOCKS_INCOME_DETAIL_PATH = `/${STOCKS_INCOME}/${ID}/`
export const STOCKS_INCOME_DETAIL_URL = `/${STOCKS_INCOME}/%d/`
export const STOCKS_INCOME_SUPPLY_DETAIL_PATH = `/${STOCKS_INCOME}/${ID}/supply/`
export const STOCKS_INCOME_SUPPLY_DETAIL_URL = `/${STOCKS_INCOME}/%d/supply/`

// Stocks outcome
const STOCKS_OUTCOME = 'stocks-outcome'
export const STOCKS_OUTCOME_PATH = `/${STOCKS_OUTCOME}/`
export const STOCKS_OUTCOME_URL = `/${STOCKS_OUTCOME}/`
export const STOCKS_OUTCOME_DETAIL_PATH = `/${STOCKS_OUTCOME}/${ID}/`
export const STOCKS_OUTCOME_DETAIL_URL = `/${STOCKS_OUTCOME}/%d/`

// Stocks rests
const STOCKS_RESTS = 'stocks-rests'
export const STOCKS_RESTS_PATH = `/${STOCKS_RESTS}/`
export const STOCKS_RESTS_URL = `/${STOCKS_RESTS}/`

// Stocks selections
const STOCKS_SELECTIONS = 'stocks-selections'
export const STOCKS_SELECTIONS_PATH = `/${STOCKS_SELECTIONS}/`
export const STOCKS_SELECTIONS_URL = `/${STOCKS_SELECTIONS}/`
export const STOCKS_SELECTIONS_CREATE_PATH = `/${STOCKS_SELECTIONS}/selections-create/`
export const STOCKS_SELECTIONS_CREATE_URL = `/${STOCKS_SELECTIONS}/selections-create/`
export const STOCKS_SELECTIONS_DETAIL_PATH = `/${STOCKS_SELECTIONS}/${selectionId}/`
export const STOCKS_SELECTIONS_DETAIL_URL = `/${STOCKS_SELECTIONS}/%d/`

// Checks
const CHECKS = 'checks'
export const CHECKS_PATH = `/${CHECKS}/`
export const CHECKS_URL = `/${CHECKS}/`
export const CHECKS_DETAIL_PATH = `/${CHECKS}/:checkId/`
export const CHECKS_DETAIL_URL = `/${CHECKS}/%d/`

// Debts
const DEBTS = 'debts'
export const DEBTS_PATH = `/${DEBTS}/`
export const DEBTS_URL = `/${DEBTS}/`
export const DEBTS_DETAIL_PATH = `/${DEBTS}/:debtId`
export const DEBTS_DETAIL_URL = `/${DEBTS}/%d`

// Statistics Pos
const STATISTICS_POS = 'statistics-pos'
export const STATISTICS_POS_PATH = `/${STATISTICS_POS}/`
export const STATISTICS_POS_URL = `/${STATISTICS_POS}/`

// DeliveriesOld
const DELIVERIES_OLD = 'deliveries-old'
export const DELIVERIES_OLD_PATH = `/${DELIVERIES_OLD}/`
export const DELIVERIES_OLD_URL = `/${DELIVERIES_OLD}/`

// Plans
const PLANS = 'plans'
export const PLANS_PATH = `/${PLANS}/`
export const PLANS_URL = `/${PLANS}/`
export const PLANS_DETAIL_PATH = `/${PLANS}/:id/`
export const PLANS_DETAIL_URL = `/${PLANS}/%d/`

// Productions
const PRODUCTIONS_BATCHES = 'productions-batches'
export const PRODUCTIONS_BATCHES_PATH = `/${PRODUCTIONS_BATCHES}/`
export const PRODUCTIONS_BATCHES_URL = `/${PRODUCTIONS_BATCHES}/`
export const PRODUCTIONS_BATCHES_DETAIL_PATH = `/${PRODUCTIONS_BATCHES}/${batchesId}/`
export const PRODUCTIONS_BATCHES_DETAIL_URL = `/${PRODUCTIONS_BATCHES}/%d/`
export const PRODUCTIONS_BATCHES_EDIT_PATH = `/${PRODUCTIONS_BATCHES}/${batchesId}/edit/`
export const PRODUCTIONS_BATCHES_EDIT_URL = `/${PRODUCTIONS_BATCHES}/%d/edit/`
export const PRODUCTIONS_BATCHES_CREATE_PATH = `/${PRODUCTIONS_BATCHES}/batches-create/`
export const PRODUCTIONS_BATCHES_CREATE_URL = `/${PRODUCTIONS_BATCHES}/batches-create/`
export const PRODUCTIONS_BATCHES_CREATE_STEP_TWO_PATH = `/${PRODUCTIONS_BATCHES}/batches-create/:id/`
export const PRODUCTIONS_BATCHES_CREATE_STEP_TWO_URL = `/${PRODUCTIONS_BATCHES}/batches-create/%d/`
export const PRODUCTIONS_BATCHES_ADD_PRODUCTS_PATH = `/${PRODUCTIONS_BATCHES}/${ID}/add-products/`
export const PRODUCTIONS_BATCHES_ADD_PRODUCTS_URL = `/${PRODUCTIONS_BATCHES}/%d/add-products/`
export const PRODUCTIONS_BATCHES_ADD_MATERIALS_PATH = `/${PRODUCTIONS_BATCHES}/${ID}/add-materials/`
export const PRODUCTIONS_BATCHES_ADD_MATERIALS_URL = `/${PRODUCTIONS_BATCHES}/%d/add-materials/`
export const PRODUCTIONS_BATCHES_CREATE_STEP_THREE_PATH = `/${PRODUCTIONS_BATCHES}/batches-create/:id/:secondId/`
export const PRODUCTIONS_BATCHES_CREATE_STEP_THREE_URL = `/${PRODUCTIONS_BATCHES}/batches-create/%d/%d/`

// Track
const TRACK = 'track'
export const TRACK_PATH = `/${TRACK}/`
export const TRACK_URL = `/${TRACK}/`

// Orders
const ORDERS = 'orders'
export const ORDERS_PATH = `/${ORDERS}/`
export const ORDERS_URL = `/${ORDERS}/`
export const ORDERS_CREATE_PATH = `/${ORDERS}/orders-create/`
export const ORDERS_CREATE_URL = `/${ORDERS}/orders-create/`

// Profile
const PROFILE = 'profile'
export const PROFILE_PATH = `/${PROFILE}/`
export const PROFILE_URL = `/${PROFILE}/`

// Products
const PRODUCTS = 'products'
export const PRODUCTS_PATH = `/${PRODUCTS}/`
export const PRODUCTS_URL = `/${PRODUCTS}/`
export const PRODUCTS_DETAIL_PATH = `/${PRODUCTS}/${ID}/`
export const PRODUCTS_DETAIL_URL = `/${PRODUCTS}/%d/`
export const PRODUCTS_CREATE_PATH = `/${PRODUCTS}/products-create/`
export const PRODUCTS_CREATE_URL = `/${PRODUCTS}/products-create/`
export const PRODUCTS_EDIT_PATH = `/${PRODUCTS}/${ID}/products-edit/`
export const PRODUCTS_EDIT_URL = `/${PRODUCTS}/%d/products-edit/`

// ProductsTypes
const PRODUCTS_TYPES = 'products-types'
export const PRODUCTS_TYPES_PATH = `/${PRODUCTS_TYPES}/`
export const PRODUCTS_TYPES_URL = `/${PRODUCTS_TYPES}/`
export const PRODUCTS_TYPES_EDIT_PATH = `/${PRODUCTS_TYPES}/${ID}/products-types-edit`
export const PRODUCTS_TYPES_EDIT_URL = `/${PRODUCTS_TYPES}/%d/products-types-edit`
export const PRODUCTS_TYPES_CREATE_PATH = `/${PRODUCTS_TYPES}/products-types-create/`
export const PRODUCTS_TYPES_CREATE_URL = `/${PRODUCTS_TYPES}/products-types-create/`

// Branches
const BRANCHES = 'branches'
export const BRANCHES_PATH = `/${BRANCHES}/`
export const BRANCHES_URL = `/${BRANCHES}/`
export const BRANCHES_CREATE_PATH = `/${BRANCHES}/branches-create/`
export const BRANCHES_CREATE_URL = `/${BRANCHES}/branches-create/`
export const BRANCHES_DETAIL_PATH = `/${BRANCHES}/${ID}/`
export const BRANCHES_DETAIL_URL = `/${BRANCHES}/%d/`
export const BRANCHES_EDIT_PATH = `/${BRANCHES}/${ID}/branches-edit/`
export const BRANCHES_EDIT_URL = `/${BRANCHES}/%d/branches-edit/`
export const BRANCHES_MAP_PATH = `/${BRANCHES}/${ID}/map/`
export const BRANCHES_MAP_URL = `/${BRANCHES}/%d/map/`

// Users
const USERS = 'users'
export const USERS_PATH = `/${USERS}/`
export const USERS_URL = `/${USERS}/`
export const USERS_DETAIL_PATH = `/${USERS}/${ID}/`
export const USERS_DETAIL_URL = `/${USERS}/%d/`
export const USERS_EDIT_PATH = `/${USERS}/${ID}/users-edit/`
export const USERS_EDIT_URL = `/${USERS}/%d/users-edit/`
export const USERS_CREATE_PATH = `/${USERS}/users-create/`
export const USERS_CREATE_URL = `/${USERS}/users-create/`

// SettingsBot
const SETTINGS_BOT = 'settings-bot'
export const SETTINGS_BOT_PATH = `/${SETTINGS_BOT}/`
export const SETTINGS_BOT_URL = `/${SETTINGS_BOT}/`

// Extensions
const EXTENSIONS = 'extensions'
export const EXTENSIONS_PATH = `/${EXTENSIONS}/`
export const EXTENSIONS_URL = `/${EXTENSIONS}/`
