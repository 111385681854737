import React from 'react'
import {CreditCard, Edit, Filter as FilterIcon, Trash2 as TrashIcon} from 'react-feather'
import {path, prop} from 'ramda'
import Container from 'components/Container/Container'
import CardItem from 'components/Cards/CardItem'
import mapIndexed from 'helpers/mapIndexed'
import CardDescription from 'components/Cards/CardDescription'
import NoResults from 'components/NoData/NoResults'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import * as serializers from '../../../actions/serializers/financesSerializer'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from 'containers/Clients/Grid/MarketsFilters'
import styled from 'styled-components'
import Button from 'components/Forms/Button'
import dateFormat from 'helpers/dateFormat'
import toNumber from 'helpers/toNumber'
import Fab from 'components/Forms/Fab/Fab'
import ModalConfirm from 'components/Modal/ModalConfirm'
import dateTimeFormat from 'helpers/dateTimeFormat'
import AcceptCashClientTransactionsItem from '../components/AcceptCashClientTransactionsItem'
import {DetailBar} from 'components/Navigation/TopBar'
import AcceptCashAllClientTransactionsDialog from '../components/AcceptCashAllClientTransactionsDialog'

const ButtonsStyled = styled('div')`
  display: flex;
  justify-content: center;
  padding: 10px;
`

const GroupButtons = styled('div')`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  & > :not(:last-child) {
    margin-right: 15px;
  }
`

const ButtonContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-left: 10px;
  }
`

const DetailStyled = styled('div')`
  :not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e4e4e6;
  }
`

const FinancesAcceptCashGrid = (props) => {
  const {
    goBack,
    handleDetailId,
    financesClientTransactionList,
    query,
    config,
    superUser,
    allTransactionDialog,
    transactionDialog,
    filterIsOpen,
    setFilterIsOpen,
    filterActions
  } = props

  // Nav buttons
  const navButtons = [
    {
      icon: <FilterIcon />,
      onClick: () => setFilterIsOpen(true)
    }
  ]

  const primaryCurrency = prop('pRIMARYCURRENCY', config) || null

  const financesClientTransactionListData = prop('data', financesClientTransactionList) || []

  const buttonContent = <ButtonContent><CreditCard size={17} /> <div>Принять все</div> </ButtonContent>
  const topButton = (
    <ButtonsStyled>
      <Button
        size="small"
        variant="contained"
        fullWidth
        onClick={() => { allTransactionDialog.onOpen(true) }}
        text={buttonContent}
      />
    </ButtonsStyled>
  )

  const contentList = mapIndexed((item, index) => {
    const agentName = path(['user', 'firstName'], item) + ' ' + path(['user', 'secondName'], item)
    const division = path(['division', 'name'], item)
    const divisionId = path(['division', 'id'], item)
    const currency = path(['currency', 'name'], item)
    const client = path(['client', 'name'], item)
    const realCurrency = path(['realCurrency', 'name'], item)
    const realCurrencyId = path(['realCurrency', 'id'], item)
    const realAmount = prop('realAmount', item)
    const id = prop('id', item)
    const createdDate = dateFormat(prop('createdDate', item))
    const isCurrencyDebt = path(['currencyDebt', 'id'], item)
    const sum = prop('sum', item)
    const internal = prop('internal', item)
    const amount = toNumber(prop('amount', item))
    const customRate = prop('customRate', item) ? toNumber(prop('customRate', item)) : toNumber(amount / internal)

    return (
      <AcceptCashClientTransactionsItem
        key={id}
        isCurrencyDebt={isCurrencyDebt}
        detailId={id}
        client={client}
        division={division}
        date={dateTimeFormat(createdDate)}
        setQuery={handleDetailId}
        searchObj={query}
        amount={amount}
        currency={currency}
        realAmount={realAmount}
        realCurrency={realCurrency}
        primaryCurrency={primaryCurrency}
        internal={internal}
        customRate={customRate}
        sum={sum}
        agentName={agentName}
      >
        <div>
          <DetailStyled>
            <CardDescription it={'№'} is={id} />
            <CardDescription it={'Дата'} is={createdDate} />
          </DetailStyled>
          <GroupButtons>
            <Fab aria-label="add" size="medium" background={'#fff'} colorname={'#6c7079'} onClick={() => transactionDialog.onOpen(id, realAmount, realCurrencyId, divisionId)}>
              <CreditCard size={20} />
            </Fab>
            <Fab aria-label="add" size="medium" background={'#fff'} colorname={'#6c7079'} onClick={() => transactionDialog.onOpen(id, realAmount, realCurrencyId, divisionId, true)}>
              <Edit size={20} />
            </Fab>
            <Fab aria-label="add" size="medium" background={'#fff'} colorname={'#e66767'} onClick={() => superUser.onOpen(id)}>
              <TrashIcon size={20} />
            </Fab>
          </GroupButtons>
        </div>
      </AcceptCashClientTransactionsItem>
    )
  }, financesClientTransactionListData)

  return (
    <>
      <DetailBar
        title={'Принять наличные'}
        goBack={() => goBack()}
        buttons={navButtons}
      />
      <Container
        background={'#fff'}
      >
        {topButton}
        <InfiniteScroll
          api={API.FINANCES_CLIENT_TRANSACTION_LIST}
          actionType={actionTypes.FINANCES_CLIENT_TRANSACTION_LIST}
          emptyData={<CardItem><NoResults /></CardItem>}
          params={serializers.AcceptCashClientTransactionsSerializer(query, props)}
          list={contentList || []}
        />
      </Container>
      <AcceptCashAllClientTransactionsDialog
        query={query}
        allTransactionDialog={allTransactionDialog}
        data={financesClientTransactionList}
      />
      {/* <AcceptCashClientTransactionsDialog */}
      {/*  query={query} */}
      {/*  transactionDialog={transactionDialog} */}
      {/*  data={financesClientTransactionList} */}
      {/* /> */}
      <ModalConfirm
        open={superUser.modalDelete}
        isOpen={superUser.onOpen}
        title={'Удалить'}
        message={`Транзакция № ${superUser.id}`}
        type={'delete'}
        onSubmit={superUser.handleSubmitDeleteTransaction}
      >
        <div />
      </ModalConfirm>
      <FiltersBar
        isOpen={filterIsOpen}
        setOpen={setFilterIsOpen}
        withOutButton
      >
        <Filter
          setFilterIsOpen={setFilterIsOpen}
          {...filterActions}
        />
      </FiltersBar>
    </>
  )
}

export default FinancesAcceptCashGrid
