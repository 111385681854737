import {compose, lifecycle} from 'recompose'
import StocksSelectionsDetailGrid from 'containers/Stocks/containers/Selections/Grid/Detail/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {
  stocksSelectionsCreateEndAction,
  stocksSelectionsCreateClearAction,
  stocksSelectionsProductsListAction
} from 'containers/Stocks/actions/stocksAction'

export default compose(
  withFetchList({
    stateName: STATES.STOCKS_SELECTIONS_PRODUCTS_LIST,
    action: stocksSelectionsProductsListAction,
    key: STATES.STOCKS_SELECTIONS_PRODUCTS_LIST
  }),
  withCreate({
    stateName: [STATES.STOCKS_SELECTIONS_CREATE_END],
    action: stocksSelectionsCreateEndAction,
    key: [STATES.STOCKS_SELECTIONS_CREATE_END],
    redirectUrl: ROUTES.STOCKS_SELECTIONS_URL,
    successMessage: 'Успех'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.STOCKS_SELECTIONS_URL,
    withCurrentParams: false
  }),
  lifecycle({
    componentWillMount () {
      this.props.dispatch(stocksSelectionsCreateClearAction())
    }
  })
)(StocksSelectionsDetailGrid)
