import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import moment from 'moment'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import {prop} from 'ramda'
import {TextStatus} from 'components/Status/TextStatus'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
`
const Date = styled('p')`
  display: flex;
  flex-flow: row wrap;
`
const RightBar = styled(Bar)`
  text-align: right;
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%) ${({eq}) => eq ? 'rotate(270deg)' : 'rotate(90deg)'};
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const Detail = styled('li')`
  padding: 12px 0;
  margin: 0 16px;
  display: ${({eq}) => eq ? 'block' : 'none'};
  transition: ${({theme}) => theme.transition.primary};
  border-bottom: ${({theme}) => theme.border.primary};
  & > *:not(:last-child){
    margin-bottom: 5px;
  }
`

const TelegramsItem = props => {
  const {
    onClick,
    name,
    query = 'telegramId',
    setQuery,
    id,
    searchObj,
    children,
    createdDate,
    activatedDate
  } = props

  const balanceIdQuery = prop(query, searchObj)

  const eq = (+balanceIdQuery === +id)

  const activatedDateStatus = activatedDate ? 'active' : 'inactive'

  const handleClick = () => {
    !eq ? setQuery(id) : setQuery('')
    onClick && onClick()
  }

  return (
    <>
      <TileItem
        onClick={handleClick}
        styles={styles}
      >
        <LeftBar>
          <h3>{name || 'Не определён'}</h3>
        </LeftBar>
        <RightBar>
          <TextStatus bold status={activatedDateStatus}>
            {activatedDate
              ? moment(activatedDate).format('DD MMM YYYY HH:mm')
              : 'Не активирован'}
          </TextStatus>
          <Date>
            {createdDate
              ? moment(createdDate).format('DD MMM YYYY HH:mm')
              : 'Нет даты'}
          </Date>
        </RightBar>
        <ArrowWrap
          eq={eq}
        >
          <Arrow />
        </ArrowWrap>
      </TileItem>
      <Detail
        eq={eq}
      >
        {children}
      </Detail>
    </>
  )
}

TelegramsItem.propTypes = {
  onClick: PropTypes.func,
  setQuery: PropTypes.func,
  query: PropTypes.string,
  name: PropTypes.any,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  createdDate: PropTypes.string,
  activatedDate: PropTypes.string,
  searchObj: PropTypes.object
}

export default TelegramsItem
