import React from 'react'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import * as API from 'constants/api'
import styled from 'styled-components'
import UploadImages from 'components/Forms/UploadField/UploadImages'
import MapField from 'components/Forms/MapField'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import Switcher from 'components/Forms/Switcher'
import {FieldArray} from 'react-final-form-arrays'
import TelephonesField from 'components/Forms/TelephonesField'

const Wrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 15px;
  }
`
const Telephones = styled('div')`
  margin-top: 4px;
`

const BranchesCreateForm = props => {
  const {
    loading,
    isEdit
  } = props

  return (
    <Wrap>
      <Field
        component={TextField}
        type={'text'}
        label={'Название'}
        name={'name'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        label={'Описание'}
        name={'comment'}
        multiline
        fullWidth
      />
      <Field
        component={TextField}
        label={'Адрес'}
        name={'address'}
        multiline
        required
        fullWidth
      />
      <Field
        component={TextField}
        label={'Ориентир'}
        type={'text'}
        name={'guide'}
        fullWidth
      />
      <Field
        component={MapField}
        name={'location'}
      />
      <Field
        component={UsersSearchField}
        label={'Заведующий'}
        name={'manager'}
        api={API.MANAGERS_LIST}
        params={{page_size: '1000'}}
        required
        fullWidth
      />
      <Field
        component={UploadImages}
        name={'images'}
        label={'Фото'}
        single
        required
        fullWidth
      />
      <Field
        component={Switcher}
        name={'showInMarket'}
        type={'checkbox'}
        label={'Подключить в боте'}
        fullWidth
      />
      <Telephones>
        <FieldArray
          name={'phones'}
          component={TelephonesField}
        />
      </Telephones>
      <BottomBar>
        <Button
          text={isEdit ? 'Редактировать' : 'Создать'}
          disabled={loading}
          fullWidth
        />
      </BottomBar>
    </Wrap>
  )
}

export default BranchesCreateForm
