import {compose, withHandlers} from 'recompose'
import MarketsReturnsGrid from 'containers/Clients/Grid/ReturnsCreate/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {path, prop} from 'ramda'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {connect} from 'react-redux'
import {withCreate, withFetchList, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import {
  marketsItemAction,
  marketsReturnsCreateAction,
  marketsReturnsCreatePreviewAction
} from 'containers/Clients/actions/marketsAction'

export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const customStore = prop('customStore', state)
    const config = prop('config', state)
    const marketsReturnsProductsList = prop('marketsReturnsProductsList', state)
    return {
      query,
      customStore,
      marketsReturnsProductsList,
      config
    }
  }),
  withCreate({
    stateName: [STATES.MARKETS_RETURNS_CREATE_PREVIEW],
    action: marketsReturnsCreatePreviewAction,
    key: [STATES.MARKETS_RETURNS_CREATE_PREVIEW],
    withoutMessage: true
  }),
  withCreate({
    withCurrentParams: true,
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    propFromParams: 'id',
    stateName: [STATES.MARKETS_RETURNS_CREATE],
    action: marketsReturnsCreateAction,
    key: [STATES.MARKETS_RETURNS_CREATE],
    successMessage: 'Возврат успешно оформлен'
  }),
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: [STATES.MARKETS_ITEM]
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withHandlers({
    addProductIdHandler: props => (key) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId: key}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  withFilter({
    fields: ['search']
  })
)(MarketsReturnsGrid)
