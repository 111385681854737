import {compose} from 'recompose'
import MarketsMapGrid from './Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {map, path, pathOr, pipe, prop} from 'ramda'
import {connect} from 'react-redux'
import {withFilter} from 'hocs'

export default compose(
  connect((state) => {
    const marketsReturnsList = prop('marketsReturnsList', state)
    const userPerms = pipe(pathOr([], ['customStore', 'data', 'permissions']), map(prop('codename')))(state)
    const isAdmin = path(['customStore', 'data', 'isSuperuser'], state)
    return {
      marketsReturnsList,
      userPerms,
      isAdmin
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goBackStatistics',
    redirectUrl: ROUTES.STATISTICS_MAIN_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_RETURNS_DETAIL_URL,
    withCurrentParams: true
  }),
  withFilter({
    fields: ['beginDate', 'endDate', 'order', 'product', 'statuses', 'returnsSearch']
  })
)(MarketsMapGrid)
