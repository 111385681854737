import React from 'react'
import TilesList from 'components/Cards/Tile/TilesList'
import styled from 'styled-components'
import ProgressBar from 'components/Charts/ProgressBar'
import mapIndexed from 'helpers/mapIndexed'
import {includes, isEmpty, prop, reject} from 'ramda'
import {TextStatus} from 'components/Status/TextStatus'

const Wrap = styled('li')`
  padding: 12px 0;
  margin: 0 12px;
`
const Title = styled('span')`
  display: block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme}) => theme.text.secondary};
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: ${({theme}) => theme.border.primary};
`
const Graphs = styled('div')`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

const StatisticsByAgentsItem = props => {
  const {
    name,
    divisions,
    maxProgress,
    currentDivisions
  } = props

  const list = mapIndexed((item) => {
    const id = prop('id', item)
    const color = prop('color', item) || '#69768D'
    const internalTotalPrice = +prop('internalTotalPrice', item)
    const eq = includes(`${id}`, currentDivisions)

    if (eq || isEmpty(currentDivisions)) {
      return (
        <ProgressBar
          key={id}
          maxProgress={+maxProgress}
          progressText={internalTotalPrice}
          color={color}
        />
      )
    }
    return false
  }, divisions)
  const cleanEmpties = reject(item => !item, list)

  return (
    <TilesList>
      <Wrap>
        <Title>{name}</Title>
        <Graphs>
          {!isEmpty(divisions) && !isEmpty(cleanEmpties) ? list : <TextStatus status={'inactive'}>Нет продаж за текущий период</TextStatus>}
        </Graphs>
      </Wrap>
    </TilesList>
  )
}

export default StatisticsByAgentsItem
