import {postFirebaseKeyAction} from '../actions/pushNotification'

const firebase = require('firebase/app')

export const askForPermissionToReceiveNotifications = async (dispatch) => {
  try {
    const messaging = firebase.messaging()
    await messaging.requestPermission()
    const token = await messaging.getToken()
    token && dispatch(postFirebaseKeyAction(token))
    return token
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Ошибка при подписке на уведомления, попробуйте позже')
  }
}
