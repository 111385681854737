import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import {isEmpty, map, path, prop, propOr} from 'ramda'
import BranchesCreateForm from 'containers/Branches/Grid/Create/Grid/Forms/BranchesCreateForm'
import Loader from 'components/Loader'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const BranchesEditGrid = props => {
  const {
    branchesDetail,
    branchesEdit,
    goBack,
    match
  } = props
  // Location
  const branchId = path(['params', 'id'], match)

  // Data
  const branchesDetailData = prop('data', branchesDetail)
  const branchesDetailLoading = prop('loading', branchesDetail)
  const branchesEditLoading = prop('loading', branchesEdit)

  // Const
  const name = prop('name', branchesDetailData)
  const address = prop('address', branchesDetailData)
  const lat = prop('lat', branchesDetailData)
  const lon = prop('lon', branchesDetailData)
  const stockManagerId = path(['stockManager', 'id'], branchesDetailData)
  const image = prop('image', branchesDetailData)
  const comment = prop('comment', branchesDetailData)
  const guide = prop('guide', branchesDetailData)
  const showInMarket = prop('showInMarket', branchesDetailData)
  const phones = prop('phones', branchesDetailData)

  // InitialValues
  const initialValues = {
    name,
    address,
    location: {lat, lon},
    manager: `${stockManagerId}`,
    images: [{image}],
    comment,
    guide,
    showInMarket,
    phones: phones && !isEmpty(phones) ? phones : [{}]
  }

  // HandleSubmit
  const handleSubmit = values => {
    const imagesArr = propOr([], 'images', values)
    const image = path(['0', 'image', 'fileId'], imagesArr) || path(['0', 'image', 'id'], imagesArr)

    const data = {
      name: prop('name', values),
      address: prop('address', values),
      stock_type: 'basic',
      stock_manager: prop('manager', values),
      lat: path(['location', 'lat'], values),
      lon: path(['location', 'lon'], values),
      image,
      id: branchId,
      comment: prop('comment', values),
      guide: prop('guide', values),
      show_in_market: prop('showInMarket', values),
      phones: map(item => {
        return {
          phone: +numberWithoutSpaces(prop('phone', item))
        }
      }, prop('phones', values))
    }

    branchesEdit.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        haveMutators
      >
        <BranchesCreateForm
          loading={branchesEditLoading}
          isEdit
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Изменение филиала'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {branchesDetailLoading ? <Loader /> : mainContent}
      </Container>
    </>
  )
}

export default BranchesEditGrid
