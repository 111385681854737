import React from 'react'
import Container from 'components/Container/Container'
import {DetailBar} from 'components/Navigation/TopBar'
import MapPinIcon from 'components/SvgIcons/CustomIcons/MapPinIcon'
import styled from 'styled-components'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import {
  filter,
  isEmpty,
  path,
  prop,
  propEq
} from 'ramda'
import PhotoWithStatus from 'components/PhotoWithStatus'
import numberFormat from 'helpers/numberFormat'
import ActionButton from 'containers/Clients/components/Buttons/ActionButton'
import {
  Bell as BellIcon,
  Phone as PhoneIcon
} from 'react-feather'
import BagIcon from 'components/SvgIcons/CustomIcons/BagIcon'
import TileInner from 'components/Cards/Tile/TileInner'
import BagReturnIcon from 'components/SvgIcons/CustomIcons/BagReturnIcon'
import DocsIcon from 'components/SvgIcons/CustomIcons/DocsIcon'
import ChartIcon from 'components/SvgIcons/CustomIcons/ChartIcon'
import mapIndexed from 'helpers/mapIndexed'
import moment from 'moment'
import checkPermissions from 'helpers/checkPermissions'
import Perms from 'components/Perms/Perms'
import Loader from 'components/Loader'
import Button from 'components/Forms/Button'
import {appendParamsToUrl} from 'helpers/url'
import CardDescription from 'components/Cards/CardDescription'
import {TextStatus} from 'components/Status/TextStatus'
import {isAndroid} from 'constants/constants'
import EditIcon from 'components/SvgIcons/CustomIcons/EditIcon'
import {MARKETS_CREATE_URL} from 'constants/routes'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  p{
    color: ${({theme}) => theme.text.secondary};
  }
`
const DescriptionStyles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  }
}
const ActionBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`
const SubTitle = styled('h3')`
  color: ${({theme}) => theme.text.secondary};
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 0 !important;
`
const ErrorText = styled('p')`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px;
  color: ${({theme}) => theme.palette.black};
`

const MarketsDetailGrid = props => {
  const {
    match,
    marketsItem,
    goMap,
    goBack,
    goOrders,
    goBalances,
    goReturns,
    goReturnsCreate,
    goOrdersCreate,
    goStatistics,
    goTaskCreate,
    handleSetDivisionQuery,
    userPerms,
    isAdmin,
    customStore,
    history
  } = props

  // Data
  const marketsItemData = prop('data', marketsItem)
  const marketsItemLoading = prop('loading', marketsItem)
  const customStoreLoading = prop('loading', customStore)
  const customStoreFailed = prop('failed', customStore)

  // Redirects
  const matchParams = prop('params', match)
  const marketId = prop('id', matchParams)

  const handleGoMap = () => {
    goMap(+marketId)
  }
  const handleGoBalances = (division) => {
    goBalances(+marketId)
    handleSetDivisionQuery(+division)
  }

  // Const
  const marketName = prop('name', marketsItemData)
  const marketAddress = prop('address', marketsItemData)
  const photos = prop('images', marketsItemData)
  const isPrimaryPhoto = propEq('isPrimary', true)
  const primaryPhotosArr = photos && filter(isPrimaryPhoto, photos)
  const primaryPhotoSrc = path(['0', 'file'], primaryPhotosArr)
  const clientTransactionCount = path(['extraMessage', 'clientTransactionCount'], marketsItemData)
  const productCount = path(['extraMessage', 'productCount'], marketsItemData)
  const totalPriceBalances = path(['extraMessage', 'totalPrice'], marketsItemData)
  const divisionPrice = path(['extraMessage', 'divisionPrice'], marketsItemData)
  const truePriceBalances = totalPriceBalances && filter((item) => +item.price !== 0, totalPriceBalances)

  const ordersMonthlyPrices = path(['extraMessage', 'monthlyPrices', 'order'], marketsItemData)
  const returnsMonthlyPrices = path(['extraMessage', 'monthlyPrices', 'orderReturn'], marketsItemData)
  const ordersMonthlyPricesTexts =
    <>
      {ordersMonthlyPrices && mapIndexed((item, index) => {
        const totalPrice = prop('totalPrice', item)
        const currencyName = path(['currency', 'name'], item)

        return (
          <span key={index}>
            Заказов на {numberFormat(totalPrice) + ' ' + currencyName}
          </span>
        )
      }, ordersMonthlyPrices)}
    </>
  const returnsMonthlyPricesTexts =
    <>
      {returnsMonthlyPrices && mapIndexed((item, index) => {
        const totalPrice = prop('totalPrice', item)
        const currencyName = path(['currency', 'name'], item)

        return (
          <span key={index}>
            Возвратов на {numberFormat(totalPrice) + ' ' + currencyName}
          </span>
        )
      }, returnsMonthlyPrices)}
    </>

  // NavButtons
  const navButtons = [
    {
      icon: <MapPinIcon fontSize={'20px'} />,
      onClick: () => handleGoMap()
    },
    {
      icon: <PhoneIcon fill={'#000'} />,
      onClick: () => isAndroid ? window.Android.call(marketName) : (window.location.href = `tel://${marketName}`)
    }
  ]

  // CardDescription
  const cardDescription =
    <TilesList>
      <TileItem
        styles={DescriptionStyles}
      >
        <PhotoWithStatus
          status={'none'}
          size={'62px'}
          statusSize={'13px'}
          imgSrc={primaryPhotoSrc}
          type={'user'}
        />
        <RightBar>
          <h3>{marketName}</h3>
          <p>{marketAddress || 'Адрес не указан'}</p>
        </RightBar>
      </TileItem>
    </TilesList>

  // Action Bar
  const menusArr = [
    {
      title: 'Заказ',
      icon: <BagIcon fontSize={'20'} />,
      onClick: () => goOrdersCreate(+marketId),
      perms: ['add_order']
    },
    {
      title: 'Возврат',
      icon: <BagReturnIcon secondColor={'#6770e6'} fontSize={'20'} />,
      onClick: () => goReturnsCreate(+marketId),
      perms: ['market_menu_return']
    },

    {
      title: 'Задача',
      icon: <BellIcon fill={'#fff'} fontSize={'20'} />,
      onClick: () => goTaskCreate(+marketId),
      perms: ['add_balancenotification'],
      withAlert: true
    },
    {
      title: 'Изменить',
      icon: <EditIcon fontSize={'20'} />,
      onClick:() => {
        return history.push({
          pathname: MARKETS_CREATE_URL,
          search: appendParamsToUrl({openUpdateDialog: marketId}, path(['location', 'search'], history))
        })
      },
      perms: ['add_market']
    }
  ]

  const actionButtons = menusArr && mapIndexed((item, index) => {
    const text = prop('title', item)
    const icon = prop('icon', item)
    const onClick = prop('onClick', item)
    const perms = prop('perms', item)
    const hasPerms = checkPermissions(perms, userPerms)

    if (hasPerms || isAdmin) {
      return (
        <ActionButton
          key={index}
          text={text}
          icon={icon}
          onClick={onClick}
        />
      )
    }
    return null
  }, menusArr)

  // Error bar
  const errorBar =
    <div>
      <ErrorText>Проверьте соединение с интернетом</ErrorText>
      <Button
        text={'Обновить страницу'}
        onClick={() => window.location.reload()}
        styles={{
          color: '#e66767 !important',
          borderRadius: '10px !important',
          background: '#fff !important',
          border: '1px solid #e66767 !important',
          height: '45px'
        }}
        type={'button'}
        fullWidth
      />
    </div>

  // Act
  const noBalancesAct =
    <TilesList
      title={'Баланс'}
    >
      <TileItem
        onClick={() => handleGoBalances()}
      >
        <TileInner
          title={'Акт сверки'}
          text={'Транзакций ' + numberFormat(clientTransactionCount)}
          icon={<DocsIcon />}
        />
      </TileItem>
    </TilesList>

  const act =
    <TilesList
      title={'Акт сверки'}
    >
      {truePriceBalances && !isEmpty(truePriceBalances) && mapIndexed((item, index) => {
        const currencyName = prop('currencyName', item)
        const price = prop('price', item)
        const lastTransactionDate = prop('lastTransactionDate', item)
        const statusUZS = (price < 0) ? 'inactive' : 'pending'

        return (
          <TileItem
            key={index}
            onClick={() => handleGoBalances()}
          >
            <TileInner
              title={numberFormat(price, currencyName)}
              text={lastTransactionDate ? 'Посл. ' + moment(lastTransactionDate).format('DD MMM YYYY HH:mm') : 'Нет оплат'}
              icon={currencyName}
              iconColor={'#fff'}
              iconBackground={'#fbb432'}
              status={statusUZS}
            />
          </TileItem>
        )
      }, truePriceBalances)}
    </TilesList>

  // Balances
  const cashBalances = divisionPrice &&
    filter(propEq('paymentType', 'cash'), divisionPrice)
  const truePriceDivisionsBalances = cashBalances && filter((item) => +prop('totalAmount', item) !== 0, cashBalances)

  const balances =
    <TilesList>
      {truePriceDivisionsBalances && mapIndexed((item, index) => {
        const name = path(['division', 'name'], item)
        const balance = prop('totalAmount', item)
        const currencyName = path(['currency', 'name'], item)
        const status = (balance < 0) ? 'inactive' : 'pending'

        return (
          <TileItem
            key={index}
          >
            <CardDescription
              it={name}
              itStyle={{color: '#000'}}
              is={<TextStatus bold status={status}>{numberFormat(balance, currencyName)}</TextStatus>}
            />
          </TileItem>
        )
      }, truePriceDivisionsBalances)}
    </TilesList>

  // Clients Info
  const orders =
    <TilesList
      title={'Информация'}
    >
      <Perms
        onClick={() => goOrders(+marketId)}
        perms={['frontend_orders']}
      >
        <TileItem>
          <TileInner
            title={'Заказы'}
            text={ordersMonthlyPrices && !isEmpty(ordersMonthlyPrices) ? ordersMonthlyPricesTexts : 'Нет заказов за тек. мес.'}
            icon={<BagIcon />}
            iconColor={'#fff'}
            iconBackground={'#353535'}
          />
        </TileItem>
      </Perms>
      <Perms
        onClick={() => goReturns(+marketId)}
        perms={['frontend_order_returns']}
      >
        <TileItem>
          <TileInner
            title={'Возвраты'}
            text={returnsMonthlyPrices && !isEmpty(returnsMonthlyPrices) ? returnsMonthlyPricesTexts : 'Нет возвратов за тек. мес.'}
            icon={<BagReturnIcon />}
            iconColor={'#fff'}
            iconBackground={'#e66767'}
          />
        </TileItem>
      </Perms>
    </TilesList>

  // More Info
  const moreInfo =
    <TilesList>
      <Perms perms={['frontend_stat_product']} onClick={() => goStatistics(marketId)}>
        <TileItem>
          <TileInner
            title={'Статистика по товарам'}
            text={'Видов продуктов ' + numberFormat(productCount)}
            icon={<ChartIcon />}
          />
        </TileItem>
      </Perms>
    </TilesList>

  // Clients Info
  const marketsInfo =
    <div>
      {orders}
      {moreInfo}
    </div>

  // MainContent
  const mainContent =
    <>
      {cardDescription}
      <ActionBar>
        {(customStoreLoading || marketsItemLoading) ? <Loader size={0.7} /> : customStoreFailed ? errorBar : actionButtons}
      </ActionBar>
      {!isEmpty(truePriceBalances) &&
      <SubTitle>БАЛАНС</SubTitle>}
      {cashBalances &&
      <div>
        {!isEmpty(truePriceBalances) ? act : noBalancesAct}
        {!isEmpty(truePriceDivisionsBalances) && balances}
      </div>}
      {marketsInfo}
    </>

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack()}
        buttons={navButtons}
        title={'Клиент'}
      />
      <Container>
        <Wrap>
          {(customStoreLoading || marketsItemLoading)
            ? <Loader styles={{marginTop: '20px'}} />
            : mainContent}
        </Wrap>
      </Container>
    </>
  )
}

export default MarketsDetailGrid
