import {compose, mapProps, withHandlers, withState} from 'recompose'
import {withFilter, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {head, path, prop} from 'ramda'
import AcceptCashClientTransactionsGrid from './Grid/AcceptCashClientTransactionsGrid'
import {
  acceptClientTransactionAction,
  deleteClientTransactionAction
} from '../../actions/financesAction'
import toNumber from '../../../../helpers/toNumber'
import {openSnackbarAction} from 'actions/snackbarAction'
import {openErrorAction} from 'actions/errorsAction'
import toBoolean from '../../../../helpers/toBoolean'

const DELETE_TRANSACTION = 'deleteTransaction'
const TRANSACTION_ACCEPT_ALL_DIALOG_OPEN = 'openAllAcceptTransactionDialog'
const TRANSACTION_ACCEPT_DIALOG_OPEN = 'openAcceptTransactionDialog'
export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props)) || {}
    const financesClientTransactionList = prop('financesClientTransactionList', state)
    const config = path(['config', 'data'], state)
    return {
      query,
      config,
      financesClientTransactionList
    }
  }),
  withState('filterIsOpen', 'setFilterIsOpen', false),
  withFilter({
    fields: ['search']
  }),
  withHandlers({
    // open Detail
    handleDetailId: props => (detailId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({detailId}, path(['history', 'location', 'search'], props))
      })
    },
    // Delete
    setModalDeleteOpenClientTransaction: props => (open) => {
      props.history.push({
        pathname: props.location.pathname,
        search: appendParamsToUrl({[DELETE_TRANSACTION]: open}, path(['history', 'location', 'search'], props))
      })
    },
    handleSubmitDeleteTransaction: props => () => {
      const {dispatch, query} = props
      const transactionId = toNumber(prop(DELETE_TRANSACTION, query))
      const reloadUI = Math.random()
      dispatch(deleteClientTransactionAction(transactionId))
        .then(() => {
          props.history.push({
            search: appendParamsToUrl({[DELETE_TRANSACTION]: false, reloadUI}, path(['history', 'location', 'search'], props))
          })
          return dispatch(openSnackbarAction({message: 'Успешно удалено'}))
        })
        .catch(({...e}) => {
          return dispatch(openErrorAction({errMessage: e}))
        })
    },
    // Accept all transactions and transaction
    setModalAcceptAllOpenClientTransaction: props => (open) => {
      props.history.push({
        search: appendParamsToUrl({[TRANSACTION_ACCEPT_ALL_DIALOG_OPEN]: open}, path(['history', 'location', 'search'], props))
      })
    },
    setModalAcceptOpenClientTransaction: props => (id, sum, realCurrency, divisionId, openUpdateDialog) => {
      const financesClientTransactionListData = prop('data', props.financesClientTransactionList) || []
      const firstElement = head(financesClientTransactionListData)
      props.history.push({
        pathname: ROUTES.FINANCES_CLIENT_TRANSACTION_CREATE,
        search: appendParamsToUrl({transactionSum: sum, open: id, transactionCurrency: realCurrency, transactionDivision: divisionId, openUpdateDialog}, path(['history', 'location', 'search'], props)),
        state: firstElement
      })
    },
    handleSubmitTransactionDialog: props => (values) => {
      const {dispatch, query, history} = props
      const clientTransaction = prop('clientTransaction', values) || null
      if (clientTransaction) {
        const reloadUI = Math.random()
        // Принять транзакиию выбранного агента!
        return dispatch(acceptClientTransactionAction(query, values))
          .then(() => {
            return dispatch(openSnackbarAction({message: 'Успешно сохранено'}))
          })
          .then(() => {
            return history.push({
              pathname: ROUTES.FINANCES_ACCEPT_CASH_DETAIL_URL,
              search: appendParamsToUrl({[TRANSACTION_ACCEPT_DIALOG_OPEN]: false, reloadUI}, path(['history', 'location', 'search'], props))
            })
          })
          .catch(({...e}) => {
            return dispatch(openErrorAction({errMessage: e}))
          })
      }
      // Принять все транзакии выбранного агента
      return dispatch(acceptClientTransactionAction(query, values))
        .then(() => {
          return dispatch(openSnackbarAction({message: 'Успешно сохранено'}))
        })
        .then(() => {
          // Redirect
          return history.push({pathname: ROUTES.FINANCES_ACCEPT_CASH_URL})
        })
        .catch(({...e}) => {
          return dispatch(openErrorAction({errMessage: e}))
        })
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.FINANCES_ACCEPT_CASH_URL,
    withCurrentParams: false
  }),
  mapProps(({
    setModalDeleteOpenClientTransaction,
    setModalAcceptAllOpenClientTransaction,
    financesClientTransactionCreate,
    handleSubmitDeleteTransaction,
    setModalAcceptOpenClientTransaction,
    handleSubmitTransactionDialog,
    ...props
  }) => {
    const modalDeleteClientTransactionOpen = toNumber(prop('deleteTransaction', props.query)) > 0 && true
    const modalAcceptAllTransactionOpen = toBoolean(prop('openAllAcceptTransactionDialog', props.query))
    const modalAcceptTransactionOpen = toNumber(prop('openAcceptTransactionDialog', props.query)) > 0 && true

    // Delete
    const superUser = {
      modalDelete: modalDeleteClientTransactionOpen,
      id: toNumber(prop('deleteTransaction', props.query)) || null,
      onOpen: setModalDeleteOpenClientTransaction,
      handleSubmitDeleteTransaction: handleSubmitDeleteTransaction
    }

    // Accept all transactions
    const allTransactionDialog = {
      open: modalAcceptAllTransactionOpen,
      onOpen: setModalAcceptAllOpenClientTransaction,
      onSubmit: handleSubmitTransactionDialog
    }

    // Accept transaction
    const transactionDialog = {
      open: modalAcceptTransactionOpen,
      id: toNumber(prop('openAcceptTransactionDialog', props.query)) || null,
      onOpen: setModalAcceptOpenClientTransaction,
      onSubmit: handleSubmitTransactionDialog
    }
    return {
      superUser,
      allTransactionDialog,
      transactionDialog,
      ...props
    }
  })
)(AcceptCashClientTransactionsGrid)
