import {compose, mapProps, withState} from 'recompose'
import MarketsOrdersDetailGrid from 'containers/Clients/Grid/OrdersDetail/Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {
  marketsSalesOrdersDetailAction,
  marketSalesAcceptDeliveredAction,
  marketsOrdersCancelAction,
  ordersAcceptOutcomeAction
} from 'containers/Clients/actions/marketsAction'
import * as ROUTES from 'constants/routes'

export default compose(
  withCreate({
    stateName: STATES.ORDERS_ACCEPT_OUTCOME,
    action: ordersAcceptOutcomeAction,
    key: STATES.ORDERS_ACCEPT_OUTCOME,
    successMessage: 'Заказ успешно передан',
    redirectUrl: ROUTES.ORDERS_URL,
    redirectDependence: 'branches',
    secondRedirectUrl: ROUTES.MARKETS_NEW_URL,
    withCurrentParams: true,
    addParam: {status: 1}
  }),
  withFetchList({
    stateName: STATES.MARKETS_SALES_ORDERS_DETAIL,
    action: marketsSalesOrdersDetailAction,
    key: STATES.MARKETS_SALES_ORDERS_DETAIL
  }),
  withCreate({
    stateName: STATES.MARKETS_ACCEPT_DELIVERED,
    action: marketSalesAcceptDeliveredAction,
    key: STATES.MARKETS_ACCEPT_DELIVERED,
    redirectUrl: ROUTES.ORDERS_URL,
    withCurrentParams: true,
    addParam: {status: 2},
    redirectDependence: 'plans',
    secondRedirectUrl: ROUTES.MARKETS_URL,
    successMessage: 'Доставка подтверждена'
  }),
  withCreate({
    stateName: STATES.MARKETS_ORDERS_CANCEL,
    action: marketsOrdersCancelAction,
    key: STATES.MARKETS_ORDERS_CANCEL,
    redirectUrl: ROUTES.ORDERS_URL,
    successMessage: 'Заказ успешно удалён'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.ORDERS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goPlan',
    redirectUrl: ROUTES.MARKETS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.MARKETS_ORDERS_EDIT_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goBonusAdd',
    redirectUrl: ROUTES.MARKETS_ORDERS_BONUS_CREATE_URL
  }),
  withRedirect({
    key: 'goBranch',
    redirectUrl: ROUTES.MARKETS_NEW_DETAIL_URL
  }),
  withState('openConfirmDelivered', 'setConfirmDelivered', false),
  mapProps(({
    openConfirmDelivered,
    setConfirmDelivered,
    marketsAcceptDelivered,
    ...props
  }) => {
    const confirmDeliveredModal = {
      open: openConfirmDelivered,
      handle: setConfirmDelivered,
      loading: marketsAcceptDelivered.loading,
      onSubmit:  (data) => {
        marketsAcceptDelivered.onSubmit(data)
        window.AndroidMaps && window.AndroidMaps.closeWebView(false)
      }
    }
    return {
      confirmDeliveredModal,
      ...props
    }
  })
)(MarketsOrdersDetailGrid)
