import {combineReducers} from 'redux'
import * as actionTypes from 'constants/actionTypes'
import * as STATES from 'constants/states'
import createThunkReducer from 'helpers/createThunkReducer'
import fromDBReducer from 'reducers/fromDBReducer'
import snackbarReducer from './snackbarReducer'
import errorReducer from './errorReducer'
import imageReducer from './imageReducer'

export default combineReducers({
  // Main
  [STATES.AUTH]: createThunkReducer(actionTypes.AUTH),
  [STATES.CUSTOM_STORE]: createThunkReducer(actionTypes.CUSTOM_STORE),
  [STATES.CONFIG]: createThunkReducer(actionTypes.CONFIG),
  [STATES.DIVISIONS_LIST]: createThunkReducer(actionTypes.DIVISIONS_LIST),
  [STATES.STOCKS_LIST]: createThunkReducer(actionTypes.STOCKS_LIST),
  [STATES.FILE_UPLOAD]: createThunkReducer(actionTypes.FILE_UPLOAD),
  [STATES.CASHIERS_LIST]: createThunkReducer(actionTypes.CASHIERS_LIST),

  // Users
  [STATES.USERS_LIST]: createThunkReducer(actionTypes.USERS_LIST),
  [STATES.USERS_LIST_FOR_SELECT]: createThunkReducer(actionTypes.USERS_LIST_FOR_SELECT),
  [STATES.USER_DETAIL]: createThunkReducer(actionTypes.USER_DETAIL),
  [STATES.USERS_DELIVERYMAN_LIST]: createThunkReducer(actionTypes.USERS_DELIVERYMAN_LIST),
  [STATES.USER_UPDATE_PASSWORD]: createThunkReducer(actionTypes.USER_UPDATE_PASSWORD),
  [STATES.USERS_DETAIL]: createThunkReducer(actionTypes.USERS_DETAIL),
  [STATES.USERS_DELETE]: createThunkReducer(actionTypes.USERS_DELETE),
  [STATES.USERS_CREATE]: createThunkReducer(actionTypes.USERS_CREATE),
  [STATES.USERS_EDIT]: createThunkReducer(actionTypes.USERS_EDIT),

  // Clients
  [STATES.MARKETS_LIST]: fromDBReducer(actionTypes.MARKETS_LIST),
  [STATES.MARKETS_CREATE]: createThunkReducer(actionTypes.MARKETS_CREATE),
  [STATES.MARKETS_ITEM]: fromDBReducer(actionTypes.MARKETS_ITEM),
  [STATES.MARKETS_ITEM_PHOTO]: createThunkReducer(actionTypes.MARKETS_ITEM_PHOTO),
  [STATES.MARKETS_SALES_CLIENT_BALANCES]: createThunkReducer(actionTypes.MARKETS_SALES_CLIENT_BALANCES),
  [STATES.MARKETS_SALES_ORDERS_LIST]: createThunkReducer(actionTypes.MARKETS_SALES_ORDERS_LIST),
  [STATES.MARKETS_SALES_ORDERS_DETAIL]: createThunkReducer(actionTypes.MARKETS_SALES_ORDERS_DETAIL),
  [STATES.MARKETS_POINTS_LIST]: createThunkReducer(actionTypes.MARKETS_POINTS_LIST),
  [STATES.MARKETS_PRODUCTS_LIST]: createThunkReducer(actionTypes.MARKETS_PRODUCTS_LIST),
  [STATES.MARKETS_PROMOTIONS_LIST]: createThunkReducer(actionTypes.MARKETS_PROMOTIONS_LIST),
  [STATES.MARKETS_CLIENT_BALANCES]: createThunkReducer(actionTypes.MARKETS_CLIENT_BALANCES),
  [STATES.MARKETS_RETURNS_LIST]: createThunkReducer(actionTypes.MARKETS_RETURNS_LIST),
  [STATES.MARKETS_RETURNS_ITEM]: createThunkReducer(actionTypes.MARKETS_RETURNS_ITEM),
  [STATES.MARKETS_ACCEPT_DELIVERED]: createThunkReducer(actionTypes.MARKETS_ACCEPT_DELIVERED),
  [STATES.MARKETS_RETURNS_PRODUCTS_LIST]: createThunkReducer(actionTypes.MARKETS_RETURNS_PRODUCTS_LIST),
  [STATES.MARKETS_RETURNS_CREATE]: createThunkReducer(actionTypes.MARKETS_RETURNS_CREATE),
  [STATES.MARKETS_RETURNS_CREATE_PREVIEW]: createThunkReducer(actionTypes.MARKETS_RETURNS_CREATE_PREVIEW),
  [STATES.MARKETS_ORDERS_CREATE]: createThunkReducer(actionTypes.MARKETS_ORDERS_CREATE),
  [STATES.MARKETS_VISITS]: createThunkReducer(actionTypes.MARKETS_VISITS),
  [STATES.MARKETS_VISIT_CREATE]: createThunkReducer(actionTypes.MARKETS_VISIT_CREATE),
  [STATES.MARKETS_PRICE_LIST]: createThunkReducer(actionTypes.MARKETS_PRICE_LIST),
  [STATES.MARKETS_CONTRACTS_LIST]: createThunkReducer(actionTypes.MARKETS_CONTRACTS_LIST),
  [STATES.MARKETS_TELEGRAMS]: createThunkReducer(actionTypes.MARKETS_TELEGRAMS),
  [STATES.MARKETS_TELEGRAMS_CREATE]: createThunkReducer(actionTypes.MARKETS_TELEGRAMS_CREATE),
  [STATES.MARKETS_CONTRACTS_CREATE]: createThunkReducer(actionTypes.MARKETS_CONTRACTS_CREATE),
  [STATES.MARKETS_STATISTICS_BY_PRODUCTS]: createThunkReducer(actionTypes.MARKETS_STATISTICS_BY_PRODUCTS),
  [STATES.MARKETS_TOTAL_STATISTICS]: createThunkReducer(actionTypes.MARKETS_TOTAL_STATISTICS),
  [STATES.MARKETS_STATISTICS_BY_AGENTS]: createThunkReducer(actionTypes.MARKETS_STATISTICS_BY_AGENTS),
  [STATES.MARKETS_STATISTICS_BY_AGENTS_PAYMENTS]: createThunkReducer(actionTypes.MARKETS_STATISTICS_BY_AGENTS_PAYMENTS),
  [STATES.MARKETS_REPORTS_CREATE]: createThunkReducer(actionTypes.MARKETS_REPORTS_CREATE),
  [STATES.MARKETS_REPORTS_EDIT]: createThunkReducer(actionTypes.MARKETS_REPORTS_EDIT),
  [STATES.MARKETS_REPORTS]: createThunkReducer(actionTypes.MARKETS_REPORTS),
  [STATES.MARKETS_REPORTS_DETAIL]: createThunkReducer(actionTypes.MARKETS_REPORTS_DETAIL),
  [STATES.MARKETS_REPORTS_DELETE]: createThunkReducer(actionTypes.MARKETS_REPORTS_DELETE),
  [STATES.MARKETS_AGENT_PLANS]: fromDBReducer(actionTypes.MARKETS_AGENT_PLANS),
  [STATES.MARKETS_PLAN]: createThunkReducer(actionTypes.MARKETS_PLAN),
  [STATES.MARKETS_PLAN_EDIT]: createThunkReducer(actionTypes.MARKETS_PLAN_EDIT),
  [STATES.MARKETS_PLAN_DELETE]: createThunkReducer(actionTypes.MARKETS_PLAN_DELETE),
  [STATES.MARKET_OPTIONS_LIST]: createThunkReducer(actionTypes.MARKET_OPTIONS_LIST),
  [STATES.MARKETS_PLANS_TYPES]: createThunkReducer(actionTypes.MARKETS_PLANS_TYPES),
  [STATES.MARKETS_AGENTS_LOCATION]: createThunkReducer(actionTypes.MARKETS_AGENTS_LOCATION),
  [STATES.MARKETS_PRODUCT_DETAIL]: createThunkReducer(actionTypes.MARKETS_PRODUCT_DETAIL),
  [STATES.MARKETS_AGENTS_PLANS]: createThunkReducer(actionTypes.MARKETS_AGENTS_PLANS),
  [STATES.MARKETS_SERVICE_TIME_START]: createThunkReducer(actionTypes.MARKETS_SERVICE_TIME_START),
  [STATES.MARKETS_SERVICE_TIME_END]: createThunkReducer(actionTypes.MARKETS_SERVICE_TIME_END),
  [STATES.MARKETS_ORDERS_EDIT]: createThunkReducer(actionTypes.MARKETS_ORDERS_EDIT),

  [STATES.MANUFACTURE_BATCHES_EDIT]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_EDIT),
  [STATES.MANUFACTURE_BATCHES_DELETE]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_DELETE),

  // add Bonus for Order
  [STATES.BONUS_ORDER_COUNT]: createThunkReducer(actionTypes.BONUS_ORDER_COUNT),
  [STATES.BONUS_ORDER_LIST]: createThunkReducer(actionTypes.BONUS_ORDER_LIST),
  [STATES.ORDER_ITEM_ADD_BONUS]: createThunkReducer(actionTypes.ORDER_ITEM_ADD_BONUS),

  // Statistics
  [STATES.STATISTICS_PAYMENTS_BY_AGENT]: createThunkReducer(actionTypes.STATISTICS_PAYMENTS_BY_AGENT),
  [STATES.STATISTICS_BY_AGENTS]: createThunkReducer(actionTypes.STATISTICS_BY_AGENTS),
  [STATES.STATISTICS_FOR_SUPERVISOR]: createThunkReducer(actionTypes.STATISTICS_FOR_SUPERVISOR),
  [STATES.STATISTICS_AGENTS_BY_DIVISIONS]: createThunkReducer(actionTypes.STATISTICS_AGENTS_BY_DIVISIONS),
  [STATES.STATISTICS_AGENTS_DEBTS]: createThunkReducer(actionTypes.STATISTICS_AGENTS_DEBTS),

  // Finances
  [STATES.FINANCES_CASHIERS_LIST]: createThunkReducer(actionTypes.FINANCES_CASHIERS_LIST),
  [STATES.FINANCES_TRANSACTIONS_LIST]: createThunkReducer(actionTypes.FINANCES_TRANSACTIONS_LIST),
  [STATES.FINANCES_ACCEPT_CASH_LIST]: createThunkReducer(actionTypes.FINANCES_ACCEPT_CASH_LIST),
  [STATES.FINANCES_CLIENT_TRANSACTION_LIST]: createThunkReducer(actionTypes.FINANCES_CLIENT_TRANSACTION_LIST),
  [STATES.MARKETS_BALANCE_NOTIFICATION_LIST]: createThunkReducer(actionTypes.MARKETS_BALANCE_NOTIFICATION_LIST),
  [STATES.MARKETS_BALANCE_NOTIFICATION_CREATE]: createThunkReducer(actionTypes.MARKETS_BALANCE_NOTIFICATION_CREATE),
  [STATES.FINANCES_ACCEPT_CLIENT_TRANSACTION]: createThunkReducer(actionTypes.FINANCES_ACCEPT_CLIENT_TRANSACTION),
  [STATES.FINANCES_TRANSFER_CASH_CREATE]: createThunkReducer(actionTypes.FINANCES_TRANSFER_CASH_CREATE),
  [STATES.PAYMENTS_CONVERT]: createThunkReducer(actionTypes.PAYMENTS_CONVERT),
  [STATES.FINANCES_TRANSACTIONS_CREATE]: createThunkReducer(actionTypes.FINANCES_TRANSACTIONS_CREATE),
  [STATES.FINANCES_DEBIT_CATEGORY]: createThunkReducer(actionTypes.FINANCES_DEBIT_CATEGORY),
  [STATES.FINANCES_CREDIT_CATEGORY]: createThunkReducer(actionTypes.FINANCES_CREDIT_CATEGORY),
  [STATES.FINANCES_CLIENT_TRANSACTION_CREATE]: createThunkReducer(actionTypes.FINANCES_CLIENT_TRANSACTION_CREATE),
  [STATES.FINANCES_CLIENT_TRANSACTION_PREVIEW]: createThunkReducer(actionTypes.FINANCES_CLIENT_TRANSACTION_PREVIEW),

  // RequestFunds
  [STATES.REQUEST_FUNDS_LIST]: createThunkReducer(actionTypes.REQUEST_FUNDS_LIST),
  [STATES.REQUEST_FUNDS_CREATE]: createThunkReducer(actionTypes.REQUEST_FUNDS_CREATE),
  [STATES.REQUEST_FUNDS_ITEM]: createThunkReducer(actionTypes.REQUEST_FUNDS_ITEM),

  // Notification
  [STATES.NOTIFICATION_LIST]: createThunkReducer(actionTypes.NOTIFICATION_LIST),
  [STATES.NOTIFICATION_DELETE]: createThunkReducer(actionTypes.NOTIFICATION_DELETE),
  [STATES.NOTIFICATION_GET_NOT_VIEWED]: createThunkReducer(actionTypes.NOTIFICATION_GET_NOT_VIEWED),

  // Cars
  [STATES.CARS_LIST]: createThunkReducer(actionTypes.CARS_LIST),
  [STATES.CARS_BRANDS_LIST]: createThunkReducer(actionTypes.CARS_BRANDS_LIST),
  [STATES.CARS_CREATE]: createThunkReducer(actionTypes.CARS_CREATE),
  [STATES.CARS_DETAIL]: createThunkReducer(actionTypes.CARS_DETAIL),
  [STATES.CARS_ORDERS_CREATE]: createThunkReducer(actionTypes.CARS_ORDERS_CREATE),
  [STATES.CARS_ORDERS_ADD_PRODUCTS]: createThunkReducer(actionTypes.CARS_ORDERS_ADD_PRODUCTS),
  [STATES.CARS_ORDERS_ADD_SERVICES]: createThunkReducer(actionTypes.CARS_ORDERS_ADD_SERVICES),
  [STATES.CARS_ORDERS_FINISH_CREATE]: createThunkReducer(actionTypes.CARS_ORDERS_FINISH_CREATE),
  [STATES.CARS_SERVICES_LIST]: createThunkReducer(actionTypes.CARS_SERVICES_LIST),
  [STATES.CARS_ORDERS_LIST]: createThunkReducer(actionTypes.CARS_ORDERS_LIST),
  [STATES.CARS_ORDERS_DETAIL]: createThunkReducer(actionTypes.CARS_ORDERS_DETAIL),
  [STATES.CARS_ORDERS_CANCEL]: createThunkReducer(actionTypes.CARS_ORDERS_CANCEL),
  [STATES.CARS_PRODUCTS_ITEM_UPDATE]: createThunkReducer(actionTypes.CARS_PRODUCTS_ITEM_UPDATE),

  // Stocks
  [STATES.STOCKS_INCOME_TASKS]: createThunkReducer(actionTypes.STOCKS_INCOME_TASKS),
  [STATES.STOCKS_OUTCOME_TASKS]: createThunkReducer(actionTypes.STOCKS_OUTCOME_TASKS),
  [STATES.STOCKS_ACCEPT_OUTCOME]: createThunkReducer(actionTypes.STOCKS_ACCEPT_OUTCOME),
  [STATES.ORDERS_ACCEPT_OUTCOME]: createThunkReducer(actionTypes.ORDERS_ACCEPT_OUTCOME),
  [STATES.STOCKS_DETAILS]: createThunkReducer(actionTypes.STOCKS_DETAILS),
  [STATES.STOCKS_SELECTIONS]: createThunkReducer(actionTypes.STOCKS_SELECTIONS),
  [STATES.STOCKS_SELECTIONS_CREATE]: createThunkReducer(actionTypes.STOCKS_SELECTIONS_CREATE),
  [STATES.STOCKS_SELECTIONS_PRODUCTS_LIST]: createThunkReducer(actionTypes.STOCKS_SELECTIONS_PRODUCTS_LIST),
  [STATES.STOCKS_SELECTIONS_CREATE_END]: createThunkReducer(actionTypes.STOCKS_SELECTIONS_CREATE_END),
  [STATES.STOCKS_RESTS_LIST]: createThunkReducer(actionTypes.STOCKS_RESTS_LIST),

  // GPS
  [STATES.GPS_SENDER]: createThunkReducer(actionTypes.GPS_SENDER),
  [STATES.GET_ADDRESS]: createThunkReducer(actionTypes.GET_ADDRESS),

  // Pos
  [STATES.POS_CHECKS]: createThunkReducer(actionTypes.POS_CHECKS),
  [STATES.POS_CHECKS_DETAIL]: createThunkReducer(actionTypes.POS_CHECKS_DETAIL),
  [STATES.POS_DEBTS]: createThunkReducer(actionTypes.POS_DEBTS),
  [STATES.POS_DEBTS_DETAIL]: createThunkReducer(actionTypes.POS_DEBTS_DETAIL),
  [STATES.POS_TOTAL_STATISTICS]: createThunkReducer(actionTypes.POS_TOTAL_STATISTICS),
  [STATES.POS_STATISTICS_POPULAR_PRODUCTS]: createThunkReducer(actionTypes.POS_STATISTICS_POPULAR_PRODUCTS),
  [STATES.POS_STATISTICS_REVENUE]: createThunkReducer(actionTypes.POS_STATISTICS_REVENUE),

  // DeliveriesOld
  [STATES.DELIVERIES_PLAN]: createThunkReducer(actionTypes.DELIVERIES_PLAN),

  // Plans
  [STATES.PLANS_BY_AGENTS_DETAIL]: createThunkReducer(actionTypes.PLANS_BY_AGENTS_DETAIL),
  [STATES.PLANS_BY_AGENTS_LIST]: createThunkReducer(actionTypes.PLANS_BY_AGENTS_LIST),

  // Manufacture
  [STATES.MANUFACTURE_LIST]: createThunkReducer(actionTypes.MANUFACTURE_LIST),
  [STATES.MANUFACTURE_BATCHES_LIST]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_LIST),
  [STATES.MANUFACTURE_PRODUCTS_LIST]: createThunkReducer(actionTypes.MANUFACTURE_PRODUCTS_LIST),
  [STATES.MANUFACTURE_PRODUCTS_DETAIL]: createThunkReducer(actionTypes.MANUFACTURE_PRODUCTS_DETAIL),
  [STATES.EQUIPMENT_LIST]: createThunkReducer(actionTypes.EQUIPMENT_LIST),
  [STATES.MARKETS_MATERIALS_LIST]: createThunkReducer(actionTypes.MARKETS_MATERIALS_LIST),
  [STATES.MANUFACTURE_BATCHES_CREATE]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_CREATE),
  [STATES.MANUFACTURE_BATCHES_DETAIL]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_DETAIL),
  [STATES.MANUFACTURE_BATCHES_ADD_PRODUCTS]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_ADD_PRODUCTS),
  [STATES.MANUFACTURE_BATCHES_ADD_MATERIALS]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_ADD_MATERIALS),
  [STATES.MANUFACTURE_BATCHES_FINISH_CREATE]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_FINISH_CREATE),
  [STATES.MANUFACTURE_BATCHES_PRODUCT_EDIT]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_PRODUCT_EDIT),
  [STATES.MANUFACTURE_BATCHES_PRODUCT_DELETE]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_PRODUCT_DELETE),
  [STATES.MANUFACTURE_BATCHES_MATERIAL_EDIT]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_MATERIAL_EDIT),
  [STATES.MANUFACTURE_BATCHES_MATERIAL_DELETE]: createThunkReducer(actionTypes.MANUFACTURE_BATCHES_MATERIAL_DELETE),
  [STATES.MANUFACTURE_SHIPMENT_END]: createThunkReducer(actionTypes.MANUFACTURE_SHIPMENT_END),

  // Products
  [STATES.PRODUCTS_LIST]: createThunkReducer(actionTypes.PRODUCTS_LIST),
  [STATES.PRODUCTS_DETAIL]: createThunkReducer(actionTypes.PRODUCTS_DETAIL),
  [STATES.PRODUCTS_DELETE]: createThunkReducer(actionTypes.PRODUCTS_DELETE),
  [STATES.PRODUCTS_CREATE]: createThunkReducer(actionTypes.PRODUCTS_CREATE),
  [STATES.PRODUCTS_EDIT]: createThunkReducer(actionTypes.PRODUCTS_EDIT),

  // Products Types
  [STATES.PRODUCTS_TYPES_LIST]: createThunkReducer(actionTypes.PRODUCTS_TYPES_LIST),
  [STATES.PRODUCTS_TYPES_CREATE]: createThunkReducer(actionTypes.PRODUCTS_TYPES_CREATE),
  [STATES.PRODUCTS_TYPES_EDIT]: createThunkReducer(actionTypes.PRODUCTS_TYPES_EDIT),
  [STATES.PRODUCTS_TYPES_ITEM]: createThunkReducer(actionTypes.PRODUCTS_TYPES_ITEM),
  [STATES.PRODUCTS_TYPES_DELETE]: createThunkReducer(actionTypes.PRODUCTS_TYPES_DELETE),
  [STATES.PRODUCTS_TYPES]: createThunkReducer(actionTypes.PRODUCTS_TYPES),

  // Branches
  [STATES.BRANCHES_LIST]: createThunkReducer(actionTypes.BRANCHES_LIST),
  [STATES.BRANCHES_DETAIL]: createThunkReducer(actionTypes.BRANCHES_DETAIL),
  [STATES.BRANCHES_DELETE]: createThunkReducer(actionTypes.BRANCHES_DELETE),
  [STATES.BRANCHES_CREATE]: createThunkReducer(actionTypes.BRANCHES_CREATE),
  [STATES.BRANCHES_EDIT]: createThunkReducer(actionTypes.BRANCHES_EDIT),

  // Orders
  [STATES.ORDERS_CREATE]: createThunkReducer(actionTypes.ORDERS_CREATE),

  // SettingsBot
  [STATES.SETTINGS_BOT]: createThunkReducer(actionTypes.SETTINGS_BOT),
  [STATES.SETTINGS_BOT_EDIT]: createThunkReducer(actionTypes.SETTINGS_BOT_EDIT),

  // SnackBar and Error
  snackbar: snackbarReducer(),
  image: imageReducer(),
  error: errorReducer()
})
