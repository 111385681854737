import axios, {getPayloadFromError, getPayloadFromSuccess} from '../../helpers/axios'
import * as API from '../../constants/api'
import * as actionTypes from '../../constants/actionTypes'

export const postFirebaseKeyAction = (token) => {
  const requestPayload = {
    key: token,
    is_browser: true
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FIREBASE_TOKEN, requestPayload)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FIREBASE_TOKEN,
      payload
    })
  }
}
