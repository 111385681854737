import {compose, mapPropsStream, withHandlers} from 'recompose'
import {withFilter, withRedirect} from 'hocs'
import StatisticsGrid from 'containers/Statistics/containers/Main/Grid'
import {totalStatisticsAction} from 'containers/Statistics/actions/statisticsAction'
import {connect} from 'react-redux'
import {map, path, pathOr, pipe, prop} from 'ramda'
import {distinctUntilChanged, filter} from 'rxjs/operators'
import {getListParams} from 'helpers/get'
import {isEqualSearch} from 'helpers/isEquals'
import * as ROUTES from 'constants/routes'
import {appendParamsToUrl} from 'helpers/url'

const getUserIdFromProps = path(['customStore', 'data', 'id'])

export default compose(
  connect((state) => {
    const customStore = prop('customStore', state)
    const config = prop('config', state)
    const plansByAgentsList = prop('statisticsByAgents', state)
    const userPerms = pipe(pathOr([], ['customStore', 'data', 'permissions']), map(prop('codename')))(state)
    const isAdmin = path(['customStore', 'data', 'isSuperuser'], state)
    return {
      customStore,
      plansByAgentsList,
      config,
      userPerms,
      isAdmin
    }
  }, {
    totalStatisticsAction
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(null, getUserIdFromProps),
        filter(
          getUserIdFromProps
        )
      )
      .subscribe(props => {
        props.totalStatisticsAction({
          ...getListParams(props, ['beginDate', 'endDate', 'supervisor']),
          props
        })
      })

    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(['beginDate', 'endDate', 'supervisor']),
          path(['history', 'location', 'search'])
        ),
        filter(
          getUserIdFromProps,
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        props.totalStatisticsAction({
          ...getListParams(props, ['beginDate', 'endDate', 'supervisor']),
          props
        })
      })

    return props$
  }),
  withFilter({
    fields: ['beginDate', 'endDate', 'supervisor']
  }),
  withRedirect({
    key: 'goOrders',
    redirectUrl: ROUTES.STATISTICS_MAIN_ORDERS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goReturns',
    redirectUrl: ROUTES.STATISTICS_MAIN_RETURNS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goBalances',
    redirectUrl: ROUTES.STATISTICS_MAIN_BALANCES_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goStatisticsByProducts',
    redirectUrl: ROUTES.STATISTICS_MAIN_BY_PRODUCTS_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetPeriod: props => (beginDate, endDate) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({beginDate, endDate}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(StatisticsGrid)
