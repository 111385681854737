import React from 'react'
import PropTypes from 'prop-types'
import * as CONST from 'constants/constants'
import {prop} from 'ramda'

const ClientBalanceFormat = (props) => {
  const {type, item, order, orderReturn, orderNumberId} = props

  let output = null
  switch (type) {
  case CONST.CLIENT_BALANCE_PAYMENT:
    if (prop('transaction', item) > CONST.ZERO) {
      output = <span>Оплата</span>
    } else {
      output = <span>Оплата <b style={{color: '#e57373'}}>(Кассир пока не принял)</b></span>
    }
    break
  case CONST.CLIENT_BALANCE_CUSTOM_DISCOUNT:
    output = <span>Индивидуальная скидка</span>
    break
  case CONST.CLIENT_BALANCE_FIRST_BALANCE:
    output = <span>Первоначальный баланс</span>
    break
  case CONST.CLIENT_BALANCE_ORDER_RETURN:
    output = <span>Возврат № {orderReturn}</span>
    break
  case CONST.CLIENT_BALANCE_ORDER:
    output = <span>Заказ № {orderNumberId}</span>
    break
  case CONST.CLIENT_BALANCE_EXPENSE:
    output = <span>Расход</span>
    break
  case CONST.CLIENT_BALANCE_CANCEL:
    output = <span>Отмена</span>
    break
  case CONST.CLIENT_BALANCE_CANCEL_ORDER:
    output = <span>Отмена заказа № {order}</span>
    break
  case CONST.CLIENT_BALANCE_CANCEL_ORDER_RETURN:
    output = <span>Отмена возврата № {orderReturn}</span>
    break
  case CONST.CLIENT_BALANCE_NONE_TYPE:
    output = <span>Произвольный</span>
    break
  case CONST.CLIENT_BALANCE_ORDER_EDIT:
    output = <span>Редактирование заказа № {order}</span>
    break
  case CONST.CLIENT_BALANCE_ORDER_DISCOUNT:
    output = <span>Скидка на заказ № {order}</span>
    break
  default:
    output = null
  }

  return output
}

ClientBalanceFormat.propTypes = {
  type: PropTypes.number.isRequired,
  order: PropTypes.number,
  orderReturn: PropTypes.number
}

export default ClientBalanceFormat
