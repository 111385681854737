import React from 'react'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {prop} from 'ramda'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import {CardFieldsFilter} from 'components/Cards/CardFieldsFilter'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = () => {
  return (
    <StyledWrap>
      <Field
        component={UniversalSearchField}
        api={API.FINANCES_CASHIERS_LIST}
        params={{currency: 5, type: 'cash', page_size: '1000'}}
        name={'fromCashbox'}
        label={'Кассы'}
        required
        fullWidth
      />
      <Field
        component={StaticSearchField}
        type={'responsibleAgent'}
        name={'responsibleAgent'}
        label={'Ответственный агент'}
        options={[
          {value: 1, label: 'Agent'},
          {value: 2, label: 'Agent'},
          {value: 3, label: 'Agent'},
          {value: 4, label: 'Agent'}
        ]}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
          fixed
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {setOpenFilter, onChangeFilter, initialValues} = props

  const handleSubmit = (values) => {
    setOpenFilter(false)
    onChangeFilter({
      search: prop('search', values)
    })
  }

  return (
    <CardFieldsFilter>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <FormFields />
      </Form>
    </CardFieldsFilter>
  )
}

export default Filter
