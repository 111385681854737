import {compose, withState} from 'recompose'
import MarketsVisitsCreateGrid from './Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {marketsItemAction, marketsVisitsCreateAction} from 'containers/Clients/actions/marketsAction'
import {marketsVisitCreateSerializer} from '../../actions/serializers/marketsSerializer'

export default compose(
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: STATES.MARKETS_ITEM
  }),
  withCreate({
    withCurrentParams: true,
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    propFromParams: 'id',
    stateName: STATES.MARKETS_VISIT_CREATE,
    action: marketsVisitsCreateAction,
    serializer: marketsVisitCreateSerializer,
    key: STATES.MARKETS_VISIT_CREATE,
    successMessage: 'Визит успешно принят'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withState('openModal', 'setOpenModal', false)
)(MarketsVisitsCreateGrid)
