import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {productsDetailAction, productsDetailDeleteAction} from 'containers/Products/actions/productsActions'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect(state => {
    const config = prop('config', state)
    return {
      config
    }
  }),
  withCreate({
    stateName: STATES.PRODUCTS_DELETE,
    action: productsDetailDeleteAction,
    key: STATES.PRODUCTS_DELETE,
    redirectToCreated: ROUTES.PRODUCTS_URL,
    successMessage: 'Продукт удалён'
  }),
  withFetchList({
    stateName: STATES.PRODUCTS_DETAIL,
    action: productsDetailAction,
    key: STATES.PRODUCTS_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTS_URL
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.PRODUCTS_EDIT_URL
  })
)(Grid)
