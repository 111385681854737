import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'
import {
  withCreate,
  withFilter,
  withRedirect
} from 'hocs'
import {
  ordersCreateAction
} from 'containers/Clients/actions/marketsAction'

export default compose(
  connect((state) => {
    const marketsProductsList = prop('marketsProductsList', state)
    const customStore = prop('customStore', state)
    const config = prop('config', state)
    return {
      marketsProductsList,
      customStore,
      config
    }
  }),
  withCreate({
    stateName: STATES.ORDERS_CREATE,
    action: ordersCreateAction,
    key: STATES.ORDERS_CREATE,
    redirectUrl: ROUTES.ORDERS_URL,
    successMessage: 'Заказ создан',
    withCurrentParams: true,
    addParam: {status: 5}
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.ORDERS_URL,
    withCurrentParams: true
  }),
  withFilter({
    fields: ['search']
  }),
  withHandlers({
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
