import React, {useState} from 'react'
import Container from 'components/Container'
import {isNil, path, prop} from 'ramda'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import CardDescription from 'components/Cards/CardDescription'
import moment from 'moment'
import * as CONST from 'constants/constants'
import numberFormat from 'helpers/numberFormat'
import mapIndexed from 'helpers/mapIndexed'
import NoResults from 'components/NoData/NoResults'
import {Table} from 'components/Table'
import {DetailBar} from 'components/Navigation/TopBar'
import TileItem from 'components/Grid/Tile/TileItem'
import TilesList from 'components/Cards/Tile/TilesList'
import {TextStatus} from 'components/Status/TextStatus'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import {MoreHorizontal} from 'react-feather'
import ConfirmDeliveredModal from '../components/ConfirmDeliveredModal'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import Perms from 'components/Perms/Perms'
import ModalConfirm from 'components/Modal/ModalConfirm'
import Loader from 'components/Loader'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import * as API from 'constants/api'
import {Modal} from 'components/Modal'
import {parseParams} from 'helpers/url'
import {PAYMENT_TYPE} from 'constants/backendConstant'
import timeWithoutYear from 'helpers/timeWithoutYear'
import {useSelector} from 'react-redux'

const Wrap = styled('div')`
  .react-swipeable-view-container{
    max-height: calc(100vh - 60px);
  }
`
const Inner = styled('div')`
  padding: 0 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const TabsBar = styled('div')`
  position: fixed;
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e4e4e6;
`
const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`
const Content = styled('div')`
  height: calc(100vh - 60px);
  padding-top: 55px;
  padding-bottom: 64px;
  overflow-x: hidden;
  overflow-y: scroll;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 15px;
  }
`
const ImgWrap = styled('li')`
  background: ${({theme, deliveryPhoto}) => deliveryPhoto ? theme.skeleton.primary : theme.palette.white};
  min-height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img{
    width: 100%;
  }
`

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const FormFields = ({isDelivery, ordersAcceptOutcomeLoading}) => {
  return (
    <StyledWrap>
      {isDelivery &&
      <Field
        component={UsersSearchField}
        api={API.USERS_LIST_FOR_SELECT}
        params={{group: 'delivery', own_division: 'true', page_size: 1000}}
        name={'deliveryMan'}
        label={'Доставщик'}
        required
        fullWidth
      />}
      <Button
        disabled={ordersAcceptOutcomeLoading}
        text={'Подтвердить'}
        fullWidth
      />
    </StyledWrap>
  )
}

const MarketsOrdersDetailGrid = props => {
  const {
    marketsSalesOrdersDetail,
    confirmDeliveredModal,
    marketsOrdersCancel,
    match,
    goBack,
    goPlan,
    goBranch,
    goEdit,
    ordersAcceptOutcome,
    location
  } = props

  // useState
  const [isOpenSecondMenu, setIsOpenSecondMenu] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [acceptOutcome, setAcceptOutcome] = useState(false)

  // Location
  const marketId = path(['params', 'id'], match)
  const orderId = path(['params', 'orderId'], match)
  const query = parseParams(path(['search'], location))
  const redirectToQuery = prop('redirectTo', query)
  const fromBranchQuery = prop('fromBranch', query)

  // Data
  const config = useSelector(state => prop('config', state))
  const configData = prop('data', config)
  const addPhotoOnDelivery = prop('aDDPHOTOONDELIVERY', configData)
  const marketsSalesOrdersDetailData = prop('data', marketsSalesOrdersDetail)
  const marketsSalesOrdersDetailLoading = prop('loading', marketsSalesOrdersDetail)
  const marketsOrdersCancelLoading = prop('loading', marketsOrdersCancel)
  const ordersAcceptOutcomeLoading = prop('loading', ordersAcceptOutcome)

  // Tabs configs
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }
  const navButtons = [
    {
      icon: <MoreHorizontal />,
      onClick: () => setIsOpenSecondMenu(true)
    }
  ]
  const status = +prop('status', marketsSalesOrdersDetailData)
  const menusArr = [
    {
      title: 'Редактировать',
      onClick: () => goEdit(+marketId, +orderId),
      perms: ['change_order']
    },
    {
      title: 'Удалить',
      onClick: () => setConfirmModalOpen(true),
      perms: ['change_order']
    }
  ]

  // Tabs content
  const marketName = path(['market', 'name'], marketsSalesOrdersDetailData)
  const secondPhone = prop('secondPhone', marketsSalesOrdersDetailData)
  const deliveryAddress = prop('deliveryAddress', marketsSalesOrdersDetailData)
  const deliveryPrice = prop('deliveryPrice', marketsSalesOrdersDetailData) || 0
  const orderNumber = prop('orderNumber', marketsSalesOrdersDetailData)
  const createDateTime = prop('createdDate', marketsSalesOrdersDetailData)
  const createdDate = createDateTime && moment(createDateTime).format('DD MMM YYYY HH:mm')
  const dateDeliveryTime = prop('dateDelivery', marketsSalesOrdersDetailData)
  const dateDelivery = dateDeliveryTime && moment(dateDeliveryTime).format('DD MMM YYYY HH:mm')
  const factDeliveryTimeTime = prop('factDeliveryTime', marketsSalesOrdersDetailData)
  const factDeliveryTime = factDeliveryTimeTime && moment(factDeliveryTimeTime).format('DD MMM YYYY HH:mm')

  const statusStatus = (status === CONST.ORDER_DELIVERED)
    ? 'active'
    : (status === CONST.ORDER_CANCELED)
      ? 'inactive'
      : 'primary'
  const statusText = (status === 5)
    ? 'Новый'
    : (status === 1)
      ? 'В работе'
      : (status === 2)
        ? 'В пути'
        : (status === CONST.ORDER_DELIVERED)
          ? 'Доставлен'
          : (status === CONST.ORDER_CANCELED)
            ? 'Отменён'
            : (status === CONST.ORDER_NOT_CONFIRMED)
              ? 'Не подтвержден'
              : null
  const deliveryManFirstName = path(['deliveryMan', 'firstName'], marketsSalesOrdersDetailData)
  const deliveryManSecondName = path(['deliveryMan', 'secondName'], marketsSalesOrdersDetailData)
  const deliveryManId = path(['deliveryMan', 'id'], marketsSalesOrdersDetailData)
  const deliveryMan = deliveryManFirstName ? (deliveryManFirstName + ' ' + deliveryManSecondName) : null
  const deliveryType = prop('deliveryType', marketsSalesOrdersDetailData)
  const isDelivery = deliveryType === 'delivery'
  const deliveryTypeText = isDelivery ? 'Доставка' : 'Самовывоз'
  const dateDeliveryText = isDelivery ? 'Дата доставки' : 'Дата самовывоза'

  const currency = path(['currency', 'name'], marketsSalesOrdersDetailData)
  const totalPrice = prop('totalPrice', marketsSalesOrdersDetailData)

  const comment = prop('comment', marketsSalesOrdersDetailData)
  const paymentCreatedDate = timeWithoutYear(prop('paymentCreatedDate', marketsSalesOrdersDetailData), 'Не принял')
  const paymentAmount = prop('paymentAmount', marketsSalesOrdersDetailData)
  const paymentTransactionId = prop('paymentTransactionId', marketsSalesOrdersDetailData)
  const paymentStatus = isNil(paymentTransactionId) ? <span style={{color: '#f0ad4e'}}>Ожидается</span> : <span style={{color: '#81c784'}}>Оплачено</span>
  const productsList = prop('products', marketsSalesOrdersDetailData)
  const stockName = path(['stock', 'name'], marketsSalesOrdersDetailData)
  const paymentType = prop('paymentType', marketsSalesOrdersDetailData)
  const oneCStockName = prop('oneCStockName', marketsSalesOrdersDetailData)
  const oneCPaymentForm = prop('oneCPaymentForm', marketsSalesOrdersDetailData)
  const paymentText = PAYMENT_TYPE[paymentType] || 'Не указано'
  const deliveryPhoto = path(['deliveryPhoto', 'file'], marketsSalesOrdersDetailData)

  // TabOne
  const tabContentOne =
    <Inner>
      <TilesList
        title={'Описание заказа'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription
            it={'Телефон'}
            is={<TextStatus styles={{textDecoration: 'underline'}} bold status={'primary'}>{marketName}</TextStatus>}
            onClick={() => CONST.isAndroid ? window.Android.call(marketName) : (window.location.href = `tel://${marketName}`)}
          />
        </TileItem>
        {addPhotoOnDelivery &&
        <TileItem
          height={'auto'}
        >
          <CardDescription
            it={'Склад'}
            is={oneCStockName}
          />
        </TileItem>}
        {secondPhone &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дополнительный номер тел.'} is={secondPhone} />
        </TileItem>}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Тип оплаты'} is={addPhotoOnDelivery ? oneCPaymentForm : paymentText} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Адрес'} is={deliveryAddress} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Комментарий'} is={comment} />
        </TileItem>
      </TilesList>
      {!addPhotoOnDelivery && (paymentType === 'payme' || paymentType === 'click') &&
        <TilesList
          title={`Оплачено с ${paymentText}`}
        >
          <TileItem
            height={'auto'}
          >
            <CardDescription
              it={'Статус оплаты'}
              is={paymentStatus}
            />
          </TileItem>
          <TileItem
            height={'auto'}
          >
            <CardDescription
              it={'Время оплаты'}
              is={paymentCreatedDate}
            />
          </TileItem>
          <TileItem
            height={'auto'}
          >
            <CardDescription
              it={'Сумма оплаты'}
              is={numberFormat(paymentAmount, currency)}
            />
          </TileItem>
        </TilesList>}
      <TilesList title={'Список товаров'}>
        <TileItem
          height={'auto'}
          styles={{'& > div': {padding: '2px 0'}}}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
                <th>Сумма</th>
              </tr>
              {productsList && mapIndexed((item, index) => {
                const productName = path(['product', 'name'], item)
                const amount = prop('amount', item)
                const measurement = (path(['product', 'measurement', 'name'], item)).slice(0, 3)
                const totalPriceProduct = prop('totalPrice', item)

                return (
                  <tr key={index}>
                    <td colSpan={'2'}>{productName}</td>
                    <td>{numberFormat(amount, measurement)}</td>
                    <td>{numberFormat(totalPriceProduct, currency)}</td>
                  </tr>
                )
              }, productsList)}
              <tr>
                <td colSpan={'2'}>Доставка</td>
                <td> </td>
                <td>{numberFormat(deliveryPrice, currency)}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td> </td>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(totalPrice, currency)}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
    </Inner>

  // TabTwo
  const tabContentTwo =
    <Inner>
      <TilesList
        title={'Исполнение'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Текущий статус'} is={<TextStatus bold status={statusStatus}>{statusText}</TextStatus>} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Филиал'} is={stockName} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Тип передачи'} is={deliveryTypeText} />
        </TileItem>
        {isDelivery &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Доставщик'} is={deliveryMan} />
        </TileItem>}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата создания'} is={createdDate} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={dateDeliveryText} is={dateDelivery} />
        </TileItem>
        {isDelivery &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Фактическое время доставки'} is={factDeliveryTime} />
        </TileItem>}
      </TilesList>
      {addPhotoOnDelivery &&
      <TilesList
        title={'Фото клиента с товаром'}
      >
        <ImgWrap
          deliveryPhoto={deliveryPhoto}
        >
          {deliveryPhoto
            ? <img src={deliveryPhoto} alt={'Ритм.Доставка'} />
            : <NoResults height={'40vw'} />}
        </ImgWrap>
      </TilesList>}
    </Inner>

  // Loader
  const loader = <Loader styles={{marginTop: '20px'}} />

  // MainContent
  const mainContent =
    <>
      <TabsBar>
        <StyledTabs
          onChange={handleChange}
          value={value}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'Товары'} {...a11yProps(0)} />
          <Tab label={'Исполнение'} {...a11yProps(1)} />
        </StyledTabs>
      </TabsBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Content value={value} index={0}>
          {marketsSalesOrdersDetailLoading ? loader : marketsSalesOrdersDetailData ? tabContentOne : <NoResults />}
        </Content>
        <Content value={value} index={1}>
          {marketsSalesOrdersDetailLoading ? loader : marketsSalesOrdersDetailData ? tabContentTwo : <NoResults />}
        </Content>
      </SwipeableViews>
    </>

  // HandleSubmit
  const handleDelete = () => {
    marketsOrdersCancel.onSubmit({
      id: orderId
    })
    setTimeout(() => {
      setConfirmModalOpen(false)
      setIsOpenSecondMenu(false)
    }, 0)
  }

  // HandleSubmit
  const handleSubmit = values => {
    const deliveryMan = prop('deliveryMan', values)
    const data = {
      deliveryMan,
      orders: [orderId]
    }
    ordersAcceptOutcome.onSubmit(data)
  }

  // InitialValues
  const initialValues = {
    deliveryMan: isDelivery ? `${deliveryManId}` : undefined
  }

  // Confirm modal
  const confirmModals =
    <>
      <ModalConfirm
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        onClick={handleDelete}
        loading={marketsOrdersCancelLoading}
        type={'delete'}
      />
      <ConfirmDeliveredModal
        dataModal={confirmDeliveredModal}
        orderId={orderId}
      />
      <Modal
        open={acceptOutcome}
        isOpen={setAcceptOutcome}
        title={isDelivery ? 'Подтвердите передачу заказа доставщику' : 'Подтвердите передачу заказа клиенту на пункте самовывоза'}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          haveMutators
        >
          <FormFields
            isDelivery={isDelivery}
            ordersAcceptOutcomeLoading={ordersAcceptOutcomeLoading}
          />
        </Form>
      </Modal>
    </>

  // Buttons
  const buttons =
    <>
      {!marketsSalesOrdersDetailLoading && (status === CONST.ORDER_GIVEN) &&
      <Perms
        perms={['can_mark_delivery']}
      >
        <BottomBar>
          <Button
            text={'Доставил'}
            onClick={() => confirmDeliveredModal.handle(true)}
            fullWidth
          />
        </BottomBar>
      </Perms>}
      {!marketsSalesOrdersDetailLoading && (status === CONST.ORDER_NOT_CONFIRMED) &&
      <Perms
        perms={['change_order']}
      >
        <BottomBar>
          <Button
            text={'Подтвердить'}
            onClick={() => goEdit(+marketId, +orderId)}
            fullWidth
          />
        </BottomBar>
      </Perms>}
      {!marketsSalesOrdersDetailLoading && (status === CONST.ORDER_READY) &&
      <Perms
        perms={['FFFFFFF']}
      >
        <BottomBar>
          <Button
            text={isDelivery ? 'Передать доставщику' : 'Передать клиенту'}
            onClick={() => setAcceptOutcome(true)}
            fullWidth
          />
        </BottomBar>
      </Perms>}
    </>

  // SecondMenu
  const secondMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с заказом'}
      menusArr={menusArr}
      isOpen={isOpenSecondMenu}
      setIsOpen={setIsOpenSecondMenu}
    />

  // Render
  return (
    <>
      <DetailBar
        title={orderNumber ? ('Заказ № ' + orderNumber) : 'Заказ'}
        goBack={() => redirectToQuery === 'plans'
          ? goPlan()
          : (redirectToQuery === 'branches')
            ? goBranch(+fromBranchQuery)
            : goBack()}
        buttons={navButtons}
      />
      <Container>
        <Wrap>
          {mainContent}
        </Wrap>
      </Container>
      {buttons}
      {secondMenu}
      {confirmModals}
    </>
  )
}

export default MarketsOrdersDetailGrid
