import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import moment from 'moment'
import {TextStatus} from 'components/Status/TextStatus'
import {Check as CheckIcon, ChevronRight as ArrowIcon, X as XIcon} from 'react-feather'
import PropTypes from 'prop-types'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)``
const Delivery = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const RightBar = styled(Bar)``
const Price = styled('h4')``
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const deliveryStyles = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '5px',
  '& > svg': {
    marginRight: '2px',
    strokeWidth: '3'
  }
}
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`

const ReturnsItem = props => {
  const {
    onClick,
    orderNumber,
    totalPrice,
    currency,
    date,
    status
  } = props

  return (
    <TileItem
      onClick={onClick}
      styles={styles}
    >
      <LeftBar>
        <h3>№ {orderNumber || 'не определён'}</h3>
        <Delivery>
          <TextStatus
            status={status}
            styles={deliveryStyles}
          >
            {(status === 'active')
              ? <CheckIcon size={15} color={'#38d06c'} />
              : (status === 'inactive')
                ? <XIcon size={15} color={'#e66767'} />
                : ''}
            {(status === 'inactive') ? 'отменён' : 'возврат:'}
          </TextStatus>
          {(status !== 'inactive') &&
          <span>
            {date
              ? moment(date).format('DD MMM YYYY')
              : 'не определена'}
          </span>}
        </Delivery>
      </LeftBar>
      <RightBar>
        <Price>
          {numberFormat(totalPrice, currency)}
        </Price>
      </RightBar>
      <Arrow />
    </TileItem>
  )
}

ReturnsItem.propTypes = {
  onClick: PropTypes.func,
  orderNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  totalPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string
}

export default ReturnsItem
