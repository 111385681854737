import React, {useState} from 'react'
import styled from 'styled-components'
import {prop, path, find, propEq} from 'ramda'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import Form from 'components/Forms/Form'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Label from 'components/Forms/Label'
import mapIndexed from 'helpers/mapIndexed'
import Radio from 'components/Forms/Radio'
import * as API from 'constants/api'
import Loader from 'components/Loader'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const FormFields = (props) => {
  const {
    marketsPlansTypesData,
    disabled,
    values
  } = props

  const radioCommentValue = prop('radioComment', values)
  const userValue = prop('user', values)
  const paymentDateValue = prop('paymentDate', values)
  const isCustomComment = radioCommentValue === 'custom'
  const disabledSubmit = !(radioCommentValue && userValue && paymentDateValue)

  return (
    <StyledWrap>
      <div>
        <Label
          styles={{marginBottom: '0'}}
        >
          Задачи
        </Label>
        {marketsPlansTypesData && mapIndexed((item, index) => {
          const type = prop('type', item)
          const name = prop('name', item)
          return (
            <Field
              key={index}
              component={Radio}
              type={'radio'}
              name={'radioComment'}
              label={name}
              value={type}
              required
            />
          )
        }, marketsPlansTypesData)}
      </div>
      {isCustomComment &&
      <Field
        component={TextField}
        type={'text'}
        multiline
        name={'comment'}
        fullWidth
      />}
      <Field
        component={UsersSearchField}
        api={API.USERS_LIST_FOR_SELECT}
        params={{page_size: '1000'}}
        name={'user'}
        label={'Ответственный'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'paymentDate'}
        label={'Дата'}
        required
      />
      <BottomBar>
        <Button
          disabled={disabledSubmit || disabled}
          type={'submit'}
          text={'Создать'}
          fullWidth
        />
      </BottomBar>
    </StyledWrap>
  )
}

const TasksCreateGrid = props => {
  const {
    goBack,
    marketsBalanceNotificationCreate,
    marketsPlansTypes
  } = props

  // useStates
  const [formValues, setFormValues] = useState({})

  // Redirects
  const marketId = path(['match', 'params', 'id'], props)

  // Data
  const marketsPlansTypesData = prop('data', marketsPlansTypes)
  const marketsPlansTypesLoading = prop('loading', marketsPlansTypes)
  const disabled = prop('loading', marketsBalanceNotificationCreate)

  const selectedTypeValue = prop('radioComment', formValues)
  const selectedTypeObj = marketsPlansTypesData && find(propEq('type', selectedTypeValue))(marketsPlansTypesData)
  const selectedResponsiveUser = path(['user', 'id'], selectedTypeObj)

  // Initial values
  const initialValuesObj = {
    radioComment: path(['0', 'type'], marketsPlansTypesData),
    user: selectedResponsiveUser || path(['0', 'user', 'id'], marketsPlansTypesData)
  }

  // Submit
  const handleMainSubmit = (values) => {
    const isCustomComment = prop('radioComment', values) === 'custom'
    const userId = prop('user', values)

    marketsBalanceNotificationCreate.onSubmit({
      market_id: +marketId,
      user_id: userId,
      date: prop('paymentDate', values),
      type: isCustomComment ? 'custom' : prop('radioComment', values),
      comment: isCustomComment ? prop('comment', values) : null
    })
  }

  // MainContent
  const mainContent =
    <Form
      onSubmit={handleMainSubmit}
      initialValues={initialValuesObj}
      getValues={setFormValues}
      keepDirtyOnReinitialize
    >
      <FormFields
        marketsPlansTypesData={marketsPlansTypesData}
        disabled={disabled}
      />
    </Form>

  // Render
  return (
    <>
      <CreateBar
        title={'Создание задачи'}
        goExit={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {marketsPlansTypesLoading ? <Loader /> : mainContent}
        </Wrap>
      </Container>
    </>
  )
}

export default TasksCreateGrid
