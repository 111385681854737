import React, {useState} from 'react'
import {isEmpty, path, prop} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import NoResults from 'components/NoData/NoResults'
import VisitsItem from 'containers/Clients/components/VisitsItem/VisitsItem'
import mapIndexed from 'helpers/mapIndexed'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import * as serializers from 'containers/Clients/actions/serializers/marketsSerializer'
import InfiniteScroll from 'components/InfiniteScroll'
import {parseParams} from 'helpers/url'
import CardDescription from 'components/Cards/CardDescription'
import Button from 'components/Forms/Button'
import styled from 'styled-components'
import ImageViewer from 'components/ImageViewer'

const ButtonWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 7px 0 0;
`
const GalleryWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
`
const ImgWrap = styled('div')`
  background: ${({theme}) => theme.skeleton.primary};
  min-height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img{
    width: 100%;
  }
  &:not(:last-child){
    margin-bottom: 16px;
  }
`
const MarketsVisitsGrid = (props) => {
  const {
    goBack,
    goMap,
    match,
    location,
    marketsVisits,
    handleSetVisitId,
    handleSetLocation,
    handleSetSelectedImage
  } = props

  // useState
  const [openViewer, setOpenViewer] = useState(false)

  // Data
  const marketsVisitsData = prop('data', marketsVisits)

  // Redirects
  const marketId = path(['params', 'id'], match)
  const searchObj = parseParams(prop('search', location))

  // MainList
  const mainList = marketsVisitsData && mapIndexed((item) => {
    const id = prop('id', item)
    const user = prop('user', item)
    const userFN = prop('firstName', user)
    const userSN = prop('secondName', user)
    const userName = userFN + ' ' + userSN
    const createdDate = prop('createdDate', item)
    const comment = prop('comment', item)
    const lon = path(['location', 'lon'], item)
    const lat = path(['location', 'lat'], item)
    const imagesArr = prop('images', item)
    const images = mapIndexed((item, index) => {
      const fileId = prop('id', item)
      const imgSrc = prop('file', item)

      return (
        <ImgWrap
          key={index}
          onClick={() => {
            handleSetSelectedImage(fileId)
            setTimeout(() => {
              setOpenViewer(true)
            }, 0)
          }}
        >
          <img src={imgSrc} alt={'Ритм.Доставка'} />
        </ImgWrap>
      )
    }, imagesArr)

    return (
      <VisitsItem
        key={id}
        id={id}
        name={comment}
        date={createdDate}
        searchObj={searchObj}
        setQuery={handleSetVisitId}
        onClick={() => handleSetLocation(lon, lat)}
      >
        <CardDescription it={'Ответственный'} is={userName} />
        <GalleryWrap>
          {!isEmpty(imagesArr) && images}
        </GalleryWrap>
        <ButtonWrap>
          <Button
            text={'Показать на карте'}
            styles={{
              height: '40px',
              width: '100%'
            }}
            onClick={() => {
              goMap(marketId)
            }}
          />
        </ButtonWrap>
      </VisitsItem>
    )
  }, marketsVisitsData)

  // Content
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_VISITS}
        actionType={actionTypes.MARKETS_VISITS}
        emptyData={<li><NoResults /></li>}
        params={serializers.marketsVisitsSerializer(searchObj, props)}
        list={mainList || []}
      />
    </>

  return (
    <>
      <DetailBar
        title={'Отказы'}
        goBack={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      <ImageViewer
        open={openViewer}
        setOpen={setOpenViewer}
        searchObj={searchObj}
      />
    </>
  )
}

export default MarketsVisitsGrid
