import {compose} from 'recompose'
import Grid from './Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {
  carsDetailAction
} from 'containers/Cars/actions/carsAction'
import * as ROUTES from 'constants/routes'

export default compose(
  withFetchList({
    stateName: STATES.CARS_DETAIL,
    action: carsDetailAction,
    key: STATES.CARS_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    withCurrentParams: false
  })
)(Grid)
