import {path, prop} from 'ramda'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'

export const requestFundsListSerializer = (params) => {
  const search = prop('search', params)
  const reloadUI = prop('reloadUI', params)
  const status = prop('tab', params) || 'pending'
  return {
    search: search,
    status,
    reloadUI
  }
}

export const requestFundsCreateSerializer = (values) => {
  const amount = numberWithoutSpaces(prop('amount', values))
  const currency = path(['currency', 'value'], values) || prop('currency', values)
  const comment = prop('comment', values)
  return {
    currency,
    amount,
    comment
  }
}

// eslint-disable-next-line camelcase
export const requestFundsUpdateSerializer = (values, open, next_status) => {
  const amount = numberWithoutSpaces(prop('amount', values))
  const currency = path(['currency', 'value'], values) || prop('currency', values)
  const cashbox = path(['cashbox', 'value'], values) || prop('cashbox', values)
  const comment = prop('comment', values)
  return {
    currency,
    amount,
    comment,
    cashbox: cashbox && `${cashbox}`,
    status: next_status
  }
}
