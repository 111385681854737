import {compose} from 'recompose'
import StocksOutcomeGrid from './Grid'
import {prop} from 'ramda'
import {withFilter} from 'hocs'
import {connect} from 'react-redux'

export default compose(
  connect((state) => {
    const customStore = prop('customStore', state)
    const stocksRestsList = prop('stocksRestsList', state)
    return {
      customStore,
      stocksRestsList
    }
  }),
  withFilter({
    fields: ['search', 'stock', 'type', 'subtype', 'measurement']
  })
)(StocksOutcomeGrid)
