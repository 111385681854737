import {compose, mapProps, withState} from 'recompose'
import MarketsPaymentsCreateGrid from 'containers/Clients/Grid/PaymentsCreate/Grid'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {
  financesClientTransactionCreate,
  financesClientTransactionPreviewAction
} from 'containers/Finances/actions/financesAction'
import {marketsClientBalancesAction} from 'containers/Clients/actions/marketsAction'
import {parseParams} from 'helpers/url'

export default compose(
  connect((state, props) => {
    const customStore = prop('customStore', state)
    const query = parseParams(path(['location', 'search'], props))
    return {
      customStore,
      query
    }
  }),
  withFetchList({
    stateName: STATES.MARKETS_CLIENT_BALANCES,
    action: marketsClientBalancesAction,
    key: [STATES.MARKETS_CLIENT_BALANCES]
  }),
  withCreate({
    stateName: STATES.FINANCES_CLIENT_TRANSACTION_PREVIEW,
    action: financesClientTransactionPreviewAction,
    key: STATES.FINANCES_CLIENT_TRANSACTION_PREVIEW,
    withoutMessage: true
  }),
  withCreate({
    stateName: STATES.FINANCES_CLIENT_TRANSACTION_CREATE,
    action: financesClientTransactionCreate,
    key: STATES.FINANCES_CLIENT_TRANSACTION_CREATE,
    withCurrentParams: true,
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    propFromParams: 'id',
    successMessage: 'Оплата успешно проведена'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: false
  }),
  withState('openConfirm', 'setOpenConfirm', false),
  mapProps(({
    ...props
  }) => {
    return {
      ...props
    }
  })
)(MarketsPaymentsCreateGrid)
