import React from 'react'
import {compose, mapPropsStream} from 'recompose'
import {distinctUntilChanged, filter} from 'rxjs/operators'
import {connect} from 'react-redux'
import {getDataFromState} from 'helpers/get'
import * as STATES from 'constants/states'
import {customStoreAction, configAction} from 'containers/SignIn/actions/signInAction'
import {path, pipe, prop} from 'ramda'

import {storageData} from 'helpers/storage'
import GPSSender from 'components/GPSSender'
import {withCreate} from 'hocs'
import {gprSenderCreateAction} from 'actions/gpsAction'
import Location from 'components/Location'
import moment from 'moment'

const enhance = compose(
  connect((state) => {
    const customStore = getDataFromState(STATES.CUSTOM_STORE, state)
    const token = path(['auth', 'data', 'token'], state) || storageData('token').getValue()
    return {
      customStore,
      token
    }
  }, {
    customStoreAction,
    configAction
  }),
  withCreate({
    stateName: STATES.GPS_SENDER,
    action: gprSenderCreateAction,
    key: STATES.GPS_SENDER,
    withOutCatch: true,
    withoutMessage: true
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(null, props => {
          return pipe(path(['customStore', 'data']), JSON.stringify)(props)
        }),
        filter(props => {
          const customStateData = path(['customStore', 'data'], props)
          const customStateLoading = path(['customStore', 'loading'], props)
          const customStateFailed = path(['customStore', 'failed'], props)
          const token = prop('token', props)
          return !customStateLoading && !customStateFailed && !customStateData && token
        })
      )
      .subscribe(props => {
        props.customStoreAction()
        props.configAction()
      })

    return props$
  })
)

const LayoutProvider = props => {
  const {
    gpsSender
  } = props

  // Const
  const token = storageData('token').getValue()

  // Render
  return (
    <>
      <Location
        seconds={moment().format('ss')}
      />
      {token &&
      <GPSSender
        gpsSender={gpsSender}
      />}
    </>
  )
}

export default enhance(LayoutProvider)
