import React from 'react'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import styled from 'styled-components'
import ModalFullScreen from 'components/Modal/ModalFullScreen'
import {path, prop} from 'ramda'
import UniversalSearchField
  from 'components/Forms/SearchField/UniversalSearchField/UniversalSearchField'
import numberFormat from 'helpers/numberFormat'
import toNumber from 'helpers/toNumber'
import * as API from 'constants/api'
import normalizeNumber from '../../../helpers/normalizers/normalizeNumber'
import numberWithoutSpaces from '../../../helpers/numberWithoutSpaces'
import toBoolean from '../../../helpers/toBoolean'
import Grid from '@material-ui/core/Grid'
import {round} from '../../../helpers/round'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const GrigWithRight = styled(Grid)`
  margin-right: 10px !important;
`

const Text = styled('div')`
    color: ${({theme}) => theme.palette.darkColor} !important;
`

export const TRANSFER_CASH_DIALOG = 'transferCashDialog'
const FinancesTransferCashCreateDialog = (props) => {
  const {transferCashDialog, config} = props
  // values select data
  const [parentCashbox, setParentCashbox] = React.useState(0)
  const [childCashbox, setChildCashbox] = React.useState(0)
  // values form
  const [values, getValues] = React.useState({})
  const amountFrom = prop('amountFrom', values) || 0
  const amountToPersent = prop('amountTo', values) || 0
  const rate = prop('rate', values) || 0
  const amountFromPersent = prop('amountFromPersent', values) || 0

  const cashboxId = prop('id', parentCashbox)
  const cashboxChildrenId = prop('id', childCashbox)
  const currencyCashbox = path(['currency', 'name'], parentCashbox)
  const currencyCildrenCashbox = path(['currency', 'name'], childCashbox)
  const sameCurrencyDiffType = path(['currency', 'name'], parentCashbox) === path(['currency', 'name'], childCashbox) && (prop('type', parentCashbox) !== prop('type', childCashbox))
  const diffCurrency = path(['currency', 'name'], parentCashbox) !== path(['currency', 'name'], childCashbox)
  const primaryCurrency = prop('PRIMARY_CURRENCY', config)
  const rateOutput = currencyCashbox === currencyCildrenCashbox ? 1 : rate
  const convertedAmount = primaryCurrency === currencyCashbox
    ? toNumber(numberWithoutSpaces(amountFrom)) * toNumber(numberWithoutSpaces(rateOutput))
    : toNumber(numberWithoutSpaces(amountFrom)) / toNumber(numberWithoutSpaces(diffCurrency ? rateOutput : 1))
  const customRatePersent = toNumber(numberWithoutSpaces(amountFromPersent)) * toNumber(numberWithoutSpaces(amountToPersent)) / 100
  const amountTo = round(convertedAmount, 4)
  const onSubmit = (values) => transferCashDialog.onSubmit(values, sameCurrencyDiffType && amountToPersent, sameCurrencyDiffType && amountToPersent ? customRatePersent : amountTo)

  return (
    <ModalFullScreen
      open={transferCashDialog.open}
      handleDialog={transferCashDialog.onHandleDialog}
      getValues={getValues}
      handleSubmit={onSubmit}
      topTitle={'Принять наличные'}
    >
      <StyledWrap>
        <Field
          component={UniversalSearchField}
          api={API.FINANCES_CASHIERS_LIST}
          params={{currency: 5, type: 'cash', page_size: '1000'}}
          onChange={(value, data) => setParentCashbox(data)}
          name={'fromCashbox'}
          placeholder={'Кассы'}
          required
          fullWidth
        />
        <Field
          component={UniversalSearchField}
          api={API.DIVISION_LIST}
          params={{page_size: '1000'}}
          name={'fromDivision'}
          placeholder={'С организации'}
          required
          fullWidth
        />
        {cashboxId &&
        <Field
          component={UniversalSearchField}
          api={API.FINANCES_CASHIERS_LIST}
          params={{exclude_id: cashboxId, page_size: '1000'}}
          onChange={(value, data) => setChildCashbox(data)}
          name={'toCashbox'}
          placeholder={'Касса получатель'}
          required
          fullWidth
        />}
        {cashboxId &&
        <Field
          component={UniversalSearchField}
          api={API.DIVISION_LIST}
          params={{page_size: '1000'}}
          name={'toDivision'}
          placeholder={'На организацию'}
          required
          fullWidth
        />}
        {!sameCurrencyDiffType &&
        <div>
          <div>
            <Grid container direction="row" alignItems="center" justify="space-between">
              {cashboxId &&
                <GrigWithRight item xs={8}>
                  <Field
                    component={TextField}
                    type={'text'}
                    name={'amountFrom'}
                    parse={normalizeNumber}
                    placeholder={'Сумма с кассы'}
                    required
                  />
                </GrigWithRight>}
              <Grid item xs={3}>
                <Text>{currencyCashbox}</Text>
              </Grid>
            </Grid>
            {cashboxChildrenId && diffCurrency &&
              <div>
                <Field
                  name="rate"
                  component={TextField}
                  label={'Курс'}
                  parse={normalizeNumber}
                  fullWidth
                />
              </div>}
          </div>
          {(amountFrom !== 0) && (rate || !diffCurrency) &&
          <div style={{padding: '10px 0'}}>
            Сумма перевода: <strong>{numberFormat(amountTo, currencyCildrenCashbox, 5)}</strong>
          </div>}
        </div>}
        {sameCurrencyDiffType &&
          <div>
            <div>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                {cashboxId &&
                  <GrigWithRight item xs={8}>
                    <Field
                      name="amountFromPersent"
                      component={TextField}
                      label={'Сумма с кассы'}
                      parse={normalizeNumber}
                      fullWidth
                    />
                  </GrigWithRight>}
                <Grid item xs={3}>
                  <Text style={{marginLeft: '10px'}}>{currencyCashbox}</Text>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                <GrigWithRight item xs={8}>
                  <Field
                    name="amountTo"
                    component={TextField}
                    label={'Процент'}
                    parse={normalizeNumber}
                    fullWidth
                  />
                </GrigWithRight>
                <Grid item xs={3}>
                  <Text>%</Text>
                </Grid>
              </Grid>
            </div>
            {(toBoolean(amountFromPersent) && toBoolean(amountToPersent)) &&
            <div style={{padding: '10px 0'}}>
              Касса <i>{currencyCildrenCashbox}</i> получает: <strong>{numberFormat(customRatePersent, currencyCildrenCashbox)} </strong>
            </div>}
          </div>}
        <Field
          component={TextField}
          name={'date'}
          type={'date'}
          placeholder={'Дата создания'}
          required
          fullWidth
        />
        <Field
          component={TextField}
          name={'time'}
          type={'time'}
          placeholder={'Время'}
          required
          fullWidth
        />
        <Field
          component={TextField}
          type={'text'}
          name={'comment'}
          placeholder={'Коментарий'}
          fullWidth
        />
      </StyledWrap>
    </ModalFullScreen>
  )
}

export default FinancesTransferCashCreateDialog
