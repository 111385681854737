import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const Wrap = styled('div')`
  position: relative;
  padding: 14px 18px 14px 30px;
  color: ${({theme}) => theme.palette.black};
  margin-right: 10px;
  margin-bottom: 5px;
  background: ${({theme, active}) => active && theme.palette.white};
  border-radius: 0 5px 5px 0;
  &:hover{
    background: ${({theme}) => theme.palette.white};
    color: unset;
  }
`
const Progress = styled('span')`
  position: absolute;
  left: 1px;
  top: 3px;
  bottom: 3px;
  width: 7px;
  background: #ececec;
  border: 1px solid #dadada;
  overflow: hidden;
  &:after{
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: ${({progress}) => progress}%;
    position: absolute;
    background: ${({theme}) => theme.palette.green};
  }
`
const Name = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  & > span:first-child{
    max-width: 75%;
  }
  & > *:not(:last-child){
    margin-right: 3px;
  }
  & > b{
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: ${({theme}) => theme.text.secondary}
  }
`
const Circle = styled('span')`
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({color}) => color || '#ccc'};
`

const AgentItem = props => {
  const {
    name,
    active,
    todoCount = 0,
    doneCount = 0,
    onClick,
    color,
    withCount = true,
    withProgress = true,
    locationDate
  } = props

  const progress = 100 - ((100 / todoCount) * doneCount)

  return (
    <Wrap
      active={active}
      onClick={onClick}
    >
      {withProgress &&
      <Progress
        progress={progress}
      />}
      <Circle
        color={color}
      />
      <Name>
        <span>{name}</span>
        {locationDate && <b>{moment(locationDate).fromNow()}</b>}
        {withCount && <span>{doneCount + ' из ' + todoCount}</span>}
      </Name>
    </Wrap>
  )
}

export default AgentItem
