import React from 'react'
import styled from 'styled-components'
import notFoundImg from 'img/not-found.png'
import {Link} from 'react-router-dom'
import * as ROUTE from 'constants/routes'

const Wrap = styled('div')`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img{
    width: 220px;
    max-width: 100%;
  }
`
const Text = styled('p')`
  display: flex;
  flex-flow: column nowrap;
  width: 60%;
  text-align: center;
  color: #555;
  font-size: 16px;
  margin-top: 10px;
`
const StyledLink = styled(Link)`
  text-align: center;
  color: #12aaeb;
  font-size: 16px;
  margin-top: 5px;
`

export default () => {
  return (
    <Wrap>
      <img src={notFoundImg} alt={'No results'} />
      <Text>
        <span>Запрашиваемая страница не найдена</span>
        <StyledLink to={{
          pathname: ROUTE.ROOT_PATH
        }}
        >На главную
        </StyledLink>
      </Text>
    </Wrap>
  )
}
