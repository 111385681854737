import React from 'react'
import styled from 'styled-components'
import {Field} from 'react-final-form'
import TextField from '../TextField'
import Fab from '../Fab'
import {PlusCircle, X} from 'react-feather'
import normalizePhone from '../../../helpers/normalizers/normalizePhone'

const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
`
const Content = styled('div')`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  & > :first-child {
    width: calc(100% - 43px)
  }
  & > :last-child {
    width: 33px;
    margin-left: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg{
    width: 30px;
    height: 30px;
  }
`

const TelephonesField = (props) => {
  const {
    fields,
    innerName = 'phone'
  } = props

  return (
    <Wrap>
      {fields.map((name, index) => {
        return (
          <Content key={index}>
            <Field
              name={`${name}.${innerName}`}
              component={TextField}
              placeholder={'Введите номер'}
              parse={normalizePhone}
              defaultValue={'+998'}
              type={'text'}
            />
            {fields.length !== (fields.length - 1) && ((fields.length - 1) !== index) &&
            <Fab
              background={'transparent'}
              size={'small'}
              type={'button'}
              onClick={() => fields.remove(index)}
            >
              <X color={'#e66767'} />
            </Fab>}
            {((fields.length - 1) === index) &&
            <Fab
              background={'transparent'}
              size={'small'}
              type={'button'}
              onClick={() => fields.push({})}
            >
              <PlusCircle color={'#6770e6'} />
            </Fab>}
          </Content>
        )
      })}
    </Wrap>
  )
}

TelephonesField.propTypes = {
}

export default TelephonesField
