import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Map from '../../Map'
import Button from '@material-ui/core/Button'
import {MapPin, Save} from 'react-feather'
import {DetailBar} from '../../Navigation/TopBar'
import Container from '../../Container/Container'
import {prop, path} from 'ramda'
import Loader from 'components/Loader'
import Label from 'components/Forms/Label'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
`
const MapContainer = styled('div')`
  display: flex;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f3f3;
`
const ContainerStyled = styled(Container)`
  width: 100%;
`
const ButtonStyled = styled(Button)`
  display: flex;
  background: #6770e6 !important;
  border-radius: 10px !important;
  height: 50px;
`
const ActiveMap = styled('div')`
  width: 100%;
  text-transform: none;
  font-size: 14px;
  text-align: center;
  color: #fff;
`

const MapField = (props) => {
  const {
    input,
    location,
    getAddress,
    label
  } = props

  const lonTashkent = '69.27582200'
  const latTashkent = '41.29658769'
  const latFromValue = path(['value', 'lat'], input) ? `${path(['value', 'lat'], input)}` : null
  const lonFromValue = path(['value', 'lon'], input) ? `${path(['value', 'lon'], input)}` : null
  const lat = latFromValue || prop('lat', location)
  const lon = lonFromValue || prop('lon', location)
  const checkLocation = lat && true
  const [coords, setCoords] = useState([lat, lon])
  const [open, setOpen] = useState(false)
  const [loadingMap, setLoadingMap] = useState(true)

  // For Field
  const locationValue = coords[0] && {
    lat: `${coords[0]}`,
    lon: `${coords[1]}`
  }
  const saveAndExit = () => {
    input && locationValue && input.onChange(locationValue)
    setOpen(false)
  }

  const navButtons = [
    {
      icon: <Save />,
      onClick: saveAndExit
    }
  ]
  return (
    <Wrap>
      {label && <Label>{label}</Label>}
      <ButtonStyled fullWidth onClick={() => { setOpen(true) }}>
        <MapPin color={'#fff'} />
        <ActiveMap active={checkLocation}>{checkLocation ? 'Изменить местоположение' : 'Указать местоположение'}</ActiveMap>
      </ButtonStyled>
      {open &&
      <MapContainer>
        <DetailBar
          goBack={() => setOpen(false)}
          title={'Карта'}
          buttons={input && navButtons}
        />
        <ContainerStyled>
          <Map onLoad={e => setLoadingMap(!e)} lat={lat || latTashkent} lon={lon || lonTashkent} zoom={12} input={input} coords={coords} setCoords={setCoords} getAddress={getAddress} />
          {loadingMap && <Loader />}
        </ContainerStyled>
      </MapContainer>}
    </Wrap>
  )
}

MapField.propTypes = {
  location: PropTypes.object
}

MapField.defaultProps = {
  location: {}
}

export default MapField
