import React from 'react'
import Container from 'components/Container'
import {path, prop} from 'ramda'
import NoResults from 'components/NoData/NoResults'
import {DetailBar} from 'components/Navigation/TopBar'
import DebtsItem from 'containers/Debts/components/DebtsItem/DebtsItem'
import mapIndexed from 'helpers/mapIndexed'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'
import {debtsDetailSerializers} from 'containers/Debts/actions/serializers/debtsSerializers'
import moment from 'moment'

const DebtsDetailGrid = (props) => {
  const {
    posDebtsDetail,
    goCheckDetail,
    match,
    goBack,
    config
  } = props

  // Data
  const posDebtsDetailData = prop('data', posDebtsDetail)
  const configData = prop('data', config)
  const defaultCurrency = prop('dEFAULTCURRENCYNAMEFOROPERATIONS', configData)

  // Redirects
  const debtId = path(['params', 'debtId'], match)

  // MainList
  const list = posDebtsDetailData && mapIndexed((item) => {
    const id = prop('id', item)
    const name = prop('invoiceNumber', item)
    const createdDate = prop('createdDate', item)
    const amount = prop('amount', item)
    const orderId = prop('orderId', item)

    return (
      <DebtsItem
        key={id}
        onClick={() => goCheckDetail(orderId)}
        name={`Чек № ${name}`}
        price={amount}
        priceSuffix={defaultCurrency}
        number={createdDate && moment(createdDate).format('DD MMM YYYY HH:mm:ss')}
      />
    )
  }, posDebtsDetailData)

  // MainContent
  const mainContent =
    <InfiniteScroll
      api={sprintf(API.POS_DEBTS_DETAIL, debtId)}
      actionType={actionTypes.POS_DEBTS_DETAIL}
      emptyData={<li><NoResults /></li>}
      params={debtsDetailSerializers()}
      list={list || []}
    />

  // Render
  return (
    <>
      <DetailBar
        title={debtId ? ('Долг № ' + debtId) : 'Долг'}
        goBack={() => goBack(debtId)}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default DebtsDetailGrid
