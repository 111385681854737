import {compose, mapProps, mapPropsStream, withState} from 'recompose'
import moment from 'moment'
import StocksDetailGrid from 'containers/Stocks/subContainers/Detail/Grid'
import {withCreate, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {parseParams} from 'helpers/url'
import {equals, path, prop} from 'ramda'
import {connect} from 'react-redux'
import {distinctUntilChanged} from 'rxjs/operators'
import * as STATES from 'constants/states'
import {
  acceptOrderStockTransferAction,
  stocksAcceptAction,
  stockOrdersDetailAction,
  stockSupplyOrderReturnDetail,
  stockTransferDetailAction,
  shipmentReturnsDetail
} from 'containers/Stocks/actions/stocksAction'

const today = moment().format('YYYY-MM-DD')

export default compose(
  connect((state, props) => {
    const pathNameValue = path(['location', 'pathname'], props)
    const isOutcome = pathNameValue && !!pathNameValue.match('stocks-outcome')
    return {
      customStore: prop('customStore', state),
      details: prop('stocksDetails', state),
      searchObj: parseParams(path(['location', 'search'], props)),
      isOutcome: isOutcome
    }
  }),
  withCreate({
    stateName: STATES.STOCKS_ACCEPT_OUTCOME,
    action: stocksAcceptAction,
    key: STATES.STOCKS_ACCEPT_ACTION,
    onSuccess: (value, props) => {
      const pathNameValue = path(['location', 'pathname'], props)
      const isOutcome = pathNameValue && !!pathNameValue.match('stocks-outcome')
      props.history.push({
        pathname: isOutcome ? ROUTES.STOCKS_OUTCOME_URL : ROUTES.STOCKS_INCOME_URL
      })
    },
    successMessage: 'Успех'
  }),
  withCreate({
    stateName: STATES.STOCKS_ACCEPT_OUTCOME,
    action: acceptOrderStockTransferAction,
    key: STATES.STOCKS_ACCEPT_OUTCOME,
    redirectUrl: ROUTES.STOCKS_OUTCOME_URL,
    successMessage: 'Успех'
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => equals(
            path(['match', 'params', 'id'], prev),
            path(['match', 'params', 'id'], curr)
          )
        )
      )
      .subscribe(props => {
        const {searchObj} = props
        const id = path(['match', 'params', 'id'], props)
        const type = prop('type', searchObj)
        type === 'order' && props.dispatch(stockOrdersDetailAction(+id))
        type === 'transfer' && props.dispatch(stockTransferDetailAction(+id))
        type === 'order_return' && props.dispatch(stockSupplyOrderReturnDetail(+id))
        type === 'shipment' && props.dispatch(shipmentReturnsDetail(+id))
      })
    return props$
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.STOCKS_INCOME_URL,
    withCurrentParams: false
  }),
  withState('openConfirm', 'setOpenConfirm', false),
  mapProps(({
    stocksAcceptOutcome,
    modalOpenOrder,
    stocksAcceptAction,
    details,
    openConfirm,
    setOpenConfirm,
    isOutcome,
    ...props
  }) => {
    const type = prop('type', props.searchObj)
    const confirmModal = {
      open: openConfirm,
      onOpen: () => setOpenConfirm(true),
      onClose: () => setOpenConfirm(false),
      loading: ((type === 'transfer' || type === 'order') && isOutcome)
        ? stocksAcceptOutcome.loading
        : stocksAcceptAction.loading,
      onSubmit: ((type === 'transfer' || type === 'order') && isOutcome)
        ? stocksAcceptOutcome.onSubmit
        : stocksAcceptAction.onSubmit,
      initialValues: {
        date: today,
        deliveryMan: +path(['data', 'deliveryMan', 'id'], details)
      }
    }
    const list = {
      data: details.data,
      loading: details.loading
    }

    return {
      confirmModal,
      list,
      ...props
    }
  })
)(StocksDetailGrid)
