import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  branchesDeleteAction,
  branchesDetailAction
} from 'containers/Branches/actions/branchesActions'

export default compose(
  withFetchList({
    stateName: STATES.BRANCHES_DETAIL,
    action: branchesDetailAction,
    key: STATES.BRANCHES_DETAIL
  }),
  withCreate({
    stateName: STATES.BRANCHES_DELETE,
    action: branchesDeleteAction,
    key: STATES.BRANCHES_DELETE,
    redirectToCreated: ROUTES.BRANCHES_URL,
    successMessage: 'Филиал удалён'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.BRANCHES_URL
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.BRANCHES_EDIT_URL
  }),
  withRedirect({
    key: 'goMap',
    redirectUrl: ROUTES.BRANCHES_MAP_URL,
    withCurrentParams: true
  })
)(Grid)
