import React, {useState} from 'react'
import styled from 'styled-components'
import {path, prop} from 'ramda'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import Form from 'components/Forms/Form'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import ModalConfirm from 'components/Modal/ModalConfirm'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const FormFields = (props) => {
  const {
    setConfirmModalOpen,
    modals
  } = props

  return (
    <>
      <StyledWrap>
        <Field
          component={TextField}
          type={'text'}
          name={'comment'}
          label={'Причина отмены'}
          multiline
          required
          fullWidth
        />
        <BottomBar>
          <Button
            type={'button'}
            text={'Удалить'}
            fullWidth
            onClick={() => setConfirmModalOpen(true)}
            // disabled={disabled}
          />
        </BottomBar>
      </StyledWrap>
      {modals}
    </>
  )
}

const CarsOrdersDeleteGrid = props => {
  const {
    goBack,
    match,
    carsOrdersDelete
  } = props

  // UseStates
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Data
  const deleteLoading = prop('loading', carsOrdersDelete)

  // Redirects
  const carId = path(['params', 'id'], match)
  const orderId = path(['params', 'orderId'], match)

  const modals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Отмена посещения'}
      loading={deleteLoading}
      type={'delete'}
    />

  // Main submit
  const handleMainSubmit = (values) => {
    carsOrdersDelete.onSubmit({
      comment: prop('comment', values),
      id: orderId
    })
  }

  // Render
  return (
    <>
      <CreateBar
        title={'Удаление посещения'}
        goExit={() => goBack(carId, orderId)}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          <Form
            onSubmit={handleMainSubmit}
          >
            <FormFields
              setConfirmModalOpen={setConfirmModalOpen}
              modals={modals}
            />
          </Form>
        </Wrap>
      </Container>
    </>
  )
}

export default CarsOrdersDeleteGrid
