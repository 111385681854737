import {askForPermissionToReceiveNotifications} from './pushNotification/pushMessages'

export function sendNotification (dispatch) {
// Проверим, поддерживает ли браузер HTML5 Notifications
  if (!('Notification' in window)) {
    // eslint-disable-next-line no-console
    console.log('Ваш браузер не поддерживает HTML Notifications, его необходимо обновить.')
  } else if (Notification.permission === 'granted') {
    // Проверим, есть ли права на отправку уведомлений
    // Если права есть, отправим уведомление

  } else if (Notification.permission !== 'denied') {
    // Если прав нет, пытаемся их получить
    return askForPermissionToReceiveNotifications(dispatch)
  } else {
    // Пользователь ранее отклонил наш запрос на показ уведомлений
    // В этом месте мы можем, но не будем его беспокоить. Уважайте решения своих пользователей.
  }
}
