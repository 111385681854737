import React from 'react'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button from 'components/Forms/Button'
import {prop} from 'ramda'

const Wrap = styled('div')`
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

const PasswordFormFields = props => {
  const {
    values,
    userUpdatePasswordLoading
  } = props

  // Values
  const passwordValue = prop('password', values)
  const passwordRepeatValue = prop('passwordRepeat', values)
  const isEq = (passwordValue && (passwordValue === passwordRepeatValue)) && !userUpdatePasswordLoading

  return (
    <TilesList>
      <TileItem>
        <Wrap>
          <Field
            component={TextField}
            name={'currentPassword'}
            label={'Текущий пароль'}
            placeholder={'Введите пароль'}
            type={'password'}
            required
          />
          <Field
            component={TextField}
            name={'password'}
            label={'Новый пароль'}
            placeholder={'Введите пароль'}
            type={'password'}
            required
          />
          <Field
            component={TextField}
            name={'passwordRepeat'}
            type={'password'}
            placeholder={'Повторите пароль'}
            required
          />
          <Button
            text={'Изменить'}
            disabled={!isEq}
            fullWidth
          />
        </Wrap>
      </TileItem>
    </TilesList>
  )
}

export default PasswordFormFields
