import React from 'react'
import {Filter as FilterIcon} from 'react-feather'
import {path, prop} from 'ramda'
import Container from 'components/Container/Container'
import CardItem from 'components/Cards/CardItem'
import mapIndexed from 'helpers/mapIndexed'
import NoResults from '../../../../../components/NoData/NoResults'
import InfiniteScroll from '../../../../../components/InfiniteScroll/InfiniteScroll'
import * as API from '../../../../../constants/api'
import * as actionTypes from '../../../../../constants/actionTypes'
import * as serializers from '../../../actions/serializers/financesSerializer'
import FiltersBar from '../../../../../components/Navigation/components/FiltersBar'
import Filter from 'containers/Clients/Grid/MarketsFilters'
import styled from 'styled-components'
import {DetailBar} from '../../../../../components/Navigation/TopBar'
import DebounceSearch from '../../../../../components/Forms/DebounceSearch/DebounceSearch'
import AcceptCashItem from '../components/AcceptCashItem'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const FixedSearch = styled('div')`
    position: fixed;
    top: 60px;
    left: 14px;
    padding: 10px 0;
    right: 14px;
    background: #fff;
    z-index: 100;
    opacity: 0.96
`
const FinancesAcceptCashGrid = (props) => {
  const {goBack, goDetail, financesAcceptCashList, query, filterIsOpen, setFilterIsOpen, filterActions} = props
  const financesAcceptCashListData = prop('data', financesAcceptCashList) || []

  // Nav buttons
  const navButtons = [
    {
      icon: <FilterIcon />,
      onClick: () => setFilterIsOpen(true)
    }
  ]

  const contentList = mapIndexed((item, index) => {
    const agent = path(['user', 'name'], item)
    const agentId = path(['user', 'id'], item)
    const division = path(['division', 'name'], item)
    const divisionId = path(['division', 'id'], item)
    const currency = path(['currency', 'name'], item)
    const currencyId = path(['currency', 'id'], item)
    const sum = prop('sum', item)

    return (
      <AcceptCashItem
        key={index}
        agent={agent}
        totalPrice={sum}
        currency={currency}
        division={division}
        onClick={() => { goDetail(agentId, currencyId, divisionId, sum) }}
      />
    )
  }, financesAcceptCashListData)

  return (
    <>
      <DetailBar
        title={'Принять наличные'}
        goBack={() => goBack()}
        buttons={navButtons}
      />
      <Container
        secondHeight={36}
        background={'#fff'}
      >
        <FixedSearch>
          <DebounceSearch filterActions={filterActions} placeholder={'Название'} />
        </FixedSearch>
        <Wrap>
          <InfiniteScroll
            api={API.FINANCES_ACCEPT_CASH_LIST}
            actionType={actionTypes.FINANCES_ACCEPT_CASH_LIST}
            emptyData={<CardItem><NoResults /></CardItem>}
            params={serializers.financesFinancesAcceptCashSerializer(query, props)}
            list={contentList || []}
          />
        </Wrap>
      </Container>
      <FiltersBar
        isOpen={filterIsOpen}
        setOpen={setFilterIsOpen}
        withOutButton
      >
        <Filter
          setFilterIsOpen={setFilterIsOpen}
          {...filterActions}
        />
      </FiltersBar>
    </>
  )
}

export default FinancesAcceptCashGrid
