import {compose} from 'recompose'
import {withFetchList, withFilter, withRedirect} from 'hocs'
import Grid from './Grid'
import {connect} from 'react-redux'
import {prop} from 'ramda'
import * as STATES from 'constants/states'
import {totalStatisticsPosAction} from 'containers/StatisticsPos/actions/statisticsPosAction'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const config = prop('config', state)
    const posStatisticsPopularProducts = prop('posStatisticsPopularProducts', state)
    const posStatisticsRevenue = prop('posStatisticsRevenue', state)
    return {
      config,
      posStatisticsPopularProducts,
      posStatisticsRevenue
    }
  }),
  withFetchList({
    stateName: STATES.POS_TOTAL_STATISTICS,
    action: totalStatisticsPosAction,
    key: STATES.POS_TOTAL_STATISTICS,
    pickParams: ['beginDate', 'endDate']
  }),
  withFilter({
    fields: ['beginDate', 'endDate']
  }),
  withRedirect({
    key: 'goChecks',
    redirectUrl: ROUTES.CHECKS_URL,
    withCurrentParams: true
  })
)(Grid)
