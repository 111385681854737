import {compose} from 'recompose'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {prop} from 'ramda'
import Grid from './Grid'
import {withCreate, withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {
  financesCashierAction,
  financesTransactionsCreateAction,
  financesCreditCategoryAction,
  financesDebitCategoryAction
} from 'containers/Finances/actions/financesAction'

export default compose(
  connect((state) => {
    const customStore = prop('customStore', state)
    return {
      customStore
    }
  }),
  withCreate({
    withCurrentParams: true,
    redirectUrl: ROUTES.FINANCES_URL,
    propFromParams: 'id',
    stateName: STATES.FINANCES_TRANSACTIONS_CREATE,
    action: financesTransactionsCreateAction,
    key: STATES.FINANCES_TRANSACTIONS_CREATE,
    successMessage: 'Транзакция успешно создана'
  }),
  withFetchList({
    key: STATES.CASHIERS_LIST,
    action: financesCashierAction,
    stateName: STATES.CASHIERS_LIST
  }),
  withFetchList({
    key: STATES.FINANCES_DEBIT_CATEGORY,
    action: financesDebitCategoryAction,
    stateName: STATES.FINANCES_DEBIT_CATEGORY
  }),
  withFetchList({
    key: STATES.FINANCES_CREDIT_CATEGORY,
    action: financesCreditCategoryAction,
    stateName: STATES.FINANCES_CREDIT_CATEGORY
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.FINANCES_URL,
    withCurrentParams: true
  })
)(Grid)
