import React from 'react'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {prop} from 'ramda'
import {parseParams} from 'helpers/url'
import {debtsSerializers} from '../actions/serializers/debtsSerializers'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import mapIndexed from 'helpers/mapIndexed'
import DebtsItem from 'containers/Debts/components/DebtsItem/DebtsItem'

const DebtsGrid = (props) => {
  const {
    posDebts,
    location,
    filterActions,
    goDetail,
    config
  } = props

  // Data
  const posDebtsData = prop('data', posDebts)
  const configData = prop('data', config)
  const defaultCurrency = prop('dEFAULTCURRENCYNAMEFOROPERATIONS', configData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // MainList
  const list = posDebtsData && mapIndexed((item) => {
    const id = prop('id', item)
    const name = prop('clientName', item)
    const number = prop('phone', item)
    const totalDebt = prop('totalDebt', item)

    return (
      <DebtsItem
        key={id}
        name={name}
        price={totalDebt}
        priceSuffix={defaultCurrency}
        number={number}
        onClick={() => goDetail(id)}
      />
    )
  }, posDebtsData)

  // MainContent
  const mainContent =
    <InfiniteScroll
      api={API.POS_DEBTS}
      actionType={actionTypes.POS_DEBTS}
      emptyData={<li><NoResults /></li>}
      params={debtsSerializers(searchObj)}
      list={list || []}
    />

  // SecondHeight
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Render
  return (
    <>
      <MainBar
        title={'Долги'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default DebtsGrid
