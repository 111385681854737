import styled from 'styled-components'

export const Table = styled('table')`
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  tr{
    :not(:last-child){
      td{
        border-bottom: ${({theme}) => theme.border.primary};
      }
    }
  }
  td{
    padding: 11px 0;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.024em;
    color: ${({theme}) => theme.palette.black};
    &:not(:last-child){
      padding-right: 5px;
    }
  }
  th{
    padding: 11px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: ${({theme}) => theme.palette.grey};
    border-bottom: ${({theme}) => theme.border.primary};
  }
  tr, th{
    text-align: ${({textAlign}) => textAlign};
  }
  tbody{
    tr th{
      text-transform: uppercase;
    }
    tr > *:last-child {
      text-align: right;
    }
    tr > *:first-child{
      text-align: left;
    }
  }
  tfoot{
    tr td{
      text-transform: uppercase;
    }
    tr > *:last-child {
      text-align: right;
    }
    tr{
      border-top: ${({theme}) => theme.border.primary};
    }
  }
`
