import {compose, withState} from 'recompose'
import MarketsTelegramsCreateGrid from 'containers/Clients/Grid/TelegramsCreate/Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  marketsTelegramsCreateAction,
  marketsItemAction
} from 'containers/Clients/actions/marketsAction'

export default compose(
  withCreate({
    stateName: [STATES.MARKETS_TELEGRAMS_CREATE],
    action: marketsTelegramsCreateAction,
    key: [STATES.MARKETS_TELEGRAMS_CREATE],
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    propFromParams: 'id'
  }),
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: [STATES.MARKETS_ITEM]
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withState('confirmModalOpen', 'setConfirmModalOpen', false)
)(MarketsTelegramsCreateGrid)
