import React, {useEffect} from 'react'
import styled from 'styled-components'
import {path, prop} from 'ramda'
import Form from 'components/Forms/Form'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import CardDescription from 'components/Cards/CardDescription'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import numberFormat from 'helpers/numberFormat'
import ModalConfirm from 'components/Modal/ModalConfirm'
import {getDistance} from 'helpers/getDistance'
import Label from 'components/Forms/Label'
import mapIndexed from 'helpers/mapIndexed'
import Radio from 'components/Forms/Radio'
import UploadImages from 'components/Forms/UploadField/UploadImages'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const Description = styled('ul')`
  margin-bottom: 15px;
  & > * {
    padding: 12px 0;
    min-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const Invalid = styled('div')`
  margin-top: 10px;
  line-height: 20px;
  color: ${({theme}) => theme.palette.red};
  font-weight: 500;
`

const FormFields = (props) => {
  const {
    setOpenModal,
    modal,
    values,
    // eslint-disable-next-line no-unused-vars
    realDistance,
    commentsOptions
  } = props

  const radioCommentValue = prop('radioComment', values)
  const isCustomComment = radioCommentValue === 'custom'

  return (
    <>
      <StyledWrap>
        <Field
          component={UploadImages}
          name={'photos'}
          label={'Фотография'}
          single
          required
          fullWidth
        />
        <div>
          <Label
            styles={{marginBottom: '0'}}
          >
            Причина отказа
          </Label>
          {mapIndexed((item, index) => {
            const value = prop('value', item)
            const name = prop('name', item)
            return (
              <Field
                key={index}
                component={Radio}
                type={'radio'}
                name={'radioComment'}
                label={name}
                value={value}
                required
              />
            )
          }, commentsOptions)}
        </div>
        {isCustomComment &&
        <Field
          component={TextField}
          name={'comment'}
          fullWidth
          required
        />}
      </StyledWrap>
      <BottomBar>
        <Button
          type={'button'}
          text={'Подтвердить'}
          onClick={() => setOpenModal(true)}
          fullWidth
        />
      </BottomBar>
      {modal}
    </>
  )
}

const MarketsVisitsCreateGrid = props => {
  const {
    goBack,
    match,
    marketsItem,
    marketsVisitCreate,
    openModal,
    setOpenModal
  } = props

  // UseStates
  const [mapLocations, setMapLocations] = React.useState({})
  const [invalid, setInvalid] = React.useState(null)

  // Data
  const marketsItemData = prop('data', marketsItem)
  const marketsVisitCreateLoading = prop('loading', marketsVisitCreate)

  // UseEffects
  useEffect(() => {
    let startPos
    const geoOptions = {
      enableHighAccuracy: true
    }
    const geoSuccess = (position) => {
      startPos = position
      const accuracy = startPos.coords.accuracy
      const latitude = startPos.coords.latitude
      const longitude = startPos.coords.longitude
      setMapLocations({latitude, longitude, accuracy})
    }
    const geoError = (position) => {
      setInvalid(position)
      // alert('Проверьте точку доступа GPS: ' + position.message)
    }
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
  }, [])

  // Main data
  const marketsName = prop('name', marketsItemData)
  const marketsAddress = prop('address', marketsItemData)
  const marketsLatitude = path(['location', 'lat'], marketsItemData)
  const marketsLongitude = path(['location', 'lon'], marketsItemData)
  const userLatitude = prop('latitude', mapLocations)
  const userLongitude = prop('longitude', mapLocations)
  const accuracy = prop('accuracy', mapLocations)
  const realDistance = getDistance(marketsLatitude, marketsLongitude, userLatitude, userLongitude)
  const formatedDistance = numberFormat(Math.round(realDistance * 1000), 'м.')

  // Redirects
  const marketId = path(['params', 'id'], match)

  // Options
  const commentsOptions = [
    {name: 'Товары есть в наличии', value: 'Товары есть в наличии'},
    {name: 'Товаров нет в наличии', value: 'Товаров нет в наличии'},
    {name: 'Не интересно', value: 'Не интересно'},
    {name: 'Нет денег', value: 'Нет денег'},
    {name: 'Своя причина', value: 'custom'}
  ]

  // InitialValues
  const initialValues = {
    radioComment: commentsOptions[0].value
  }

  const modal =
    <ModalConfirm
      type={'submit'}
      open={openModal}
      isOpen={setOpenModal}
      loading={marketsVisitCreateLoading}
      message={'Подтвердите посещение'}
    />

  const mainContent =
    <>
      <Description>
        <li><CardDescription it={'Клиент'} is={marketsName} /></li>
        <li><CardDescription it={'Адрес'} is={marketsAddress} /></li>
        <li><CardDescription it={'Расстояние'} is={formatedDistance} /></li>
        <li><CardDescription it={'Точность'} is={Math.round(accuracy)} /></li>
      </Description>
    </>

  // Submit
  const handleMainSubmit = (values) => {
    marketsVisitCreate.onSubmit({
      ...values, marketId, userLatitude, userLongitude, realDistance, accuracy
    })
  }

  return (
    <>
      <CreateBar
        title={'Отказ'}
        goExit={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {mainContent}
          <Form
            onSubmit={handleMainSubmit}
            initialValues={initialValues}
          >
            <FormFields
              modal={modal}
              realDistance={realDistance}
              setOpenModal={setOpenModal}
              commentsOptions={commentsOptions}
            />
          </Form>
          {invalid &&
          <Invalid>Проверьте точку доступа GPS: {invalid.message}</Invalid>}
        </Wrap>
      </Container>
    </>
  )
}

export default MarketsVisitsCreateGrid
