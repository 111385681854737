import React, {useState} from 'react'
import Container from 'components/Container/Container'
import MainMenu from 'components/Navigation/components/MainMenu'
import {MainBar} from 'components/Navigation/TopBar'
import {filter, find, isEmpty, length, path, pathEq, pathOr, prop, propEq, propOr} from 'ramda'
import YMap from 'containers/Track/components/Map/YMap'
import Loader from 'components/Loader'
import RightBar from 'components/Navigation/components/RightBar'
import {Users} from 'react-feather'
import styled from 'styled-components'
import mapIndexed from 'helpers/mapIndexed'
import AgentItem from 'containers/Track/components/AgentItem/AgentItem'
import {paramsToSearch, parseParams} from 'helpers/url'
import Modal from 'components/Modal/Modal'
import CheckedTask from 'components/CheckedTask'
import {sprintf} from 'sprintf-js'
import * as ROUTES from 'constants/routes'

const BarWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  padding: 11px 0;
`
const TitleWrap = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  & > *:not(:last-child){
    margin-right: 5px;
  }
`
const TasksWrap = styled('div')`
  background: #f9f9f9;
  border: 1px solid #e6e6e8;
  border-radius: ${({theme}) => theme.borderRadius.primary};
  padding: 0 12px;
`

const TrackGrid = props => {
  const {
    marketsAgentsLocation,
    handleAgentId,
    handleMarketId,
    location,
    history,
    goReturnsCreate,
    goVisitsCreate,
    goOrdersCreate,
    goPaymentsCreate
  } = props

  // useState
  const [loadingMap, setLoadingMap] = useState(true)
  const [rightBarOpen, setRightBarOpen] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)

  // Data
  const marketsAgentsLocationData = propOr([], 'data', marketsAgentsLocation)
  const marketsAgentsLocationLoading = prop('loading', marketsAgentsLocation)

  // Loading
  const loading = isEmpty(marketsAgentsLocationData) && marketsAgentsLocationLoading

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const agentId = prop('agentId', searchObj)
  const marketId = prop('marketId', searchObj)

  // Const
  const filteredList = agentId
    ? filter(propEq('id', +agentId), marketsAgentsLocationData)
    : marketsAgentsLocationData
  const filteredListLength = length(filteredList)
  const isOneAgent = filteredListLength === 1
  const selectedAgentPlans = (isOneAgent && marketId && agentId) ? pathOr([], [0, 'plan'], filteredList) : []
  const selectedMarket = find(pathEq(['market', 'id'], +marketId), selectedAgentPlans)
  const marketName = path(['market', 'name'], selectedMarket)
  const marketAddress = path(['market', 'address'], selectedMarket)
  const selectedPlans = propOr([], 'plans', selectedMarket)

  // Func
  const handleChoice = id => {
    handleAgentId(id)
    setTimeout(() => {
      setRightBarOpen(false)
    }, 0)
  }

  // AgentsList
  const agentsList = mapIndexed((item, index) => {
    const id = prop('id', item)
    const name = prop('userFullname', item)
    const todoCount = prop('todoCount', item)
    const doneCount = prop('doneCount', item)
    const color = prop('color', item)

    return (
      <AgentItem
        name={name}
        todoCount={todoCount}
        doneCount={doneCount}
        onClick={() => handleChoice(id)}
        active={+agentId === +id}
        color={color}
        key={index}
      />
    )
  }, marketsAgentsLocationData)

  // SelectedPlansList
  const selectedPlansList = mapIndexed((item, index) => {
    const text = prop('text', item)
    const id = prop('id', item)
    const type = prop('type', item)
    const status = prop('status', item)
    const orderId = prop('orderId', item)
    const currStatus = status === 'done' ? 'active' : status === 'undone' ? 'inactive' : null
    const currentParams = parseParams(search)
    const allParams = {...currentParams, ...{taskId: id}}
    const onClickObj = {
      order_return: () => goReturnsCreate(marketId),
      visit: () => goVisitsCreate(marketId),
      order: () => goOrdersCreate(marketId),
      payment: () => goPaymentsCreate(marketId),
      delivery: () => {
        history.push({
          pathname: sprintf(ROUTES.MARKETS_ORDERS_DETAIL_URL, marketId, orderId),
          search: paramsToSearch(allParams)
        })
      },
      custom: () => {
        history.push({
          pathname: sprintf(ROUTES.MARKETS_REPORTS_CREATE_URL, marketId),
          search: paramsToSearch(allParams)
        })
      }
    }

    return (
      <CheckedTask
        type={type}
        onClickObj={onClickObj}
        key={index}
        text={text}
        status={currStatus}
      />
    )
  }, selectedPlans)

  // RightBarContent
  const rightBarContent =
    <BarWrap>
      <AgentItem
        name={'Все'}
        withCount={false}
        withProgress={false}
        onClick={() => handleChoice('')}
        active={!agentId}
      />
      {agentsList}
    </BarWrap>

  // Title
  const title = marketsAgentsLocationLoading ? <TitleWrap><span>Отслеживание</span><Loader size={0.7} /></TitleWrap> : 'Отслеживание'

  // Render
  return (
    <>
      <MainBar
        title={title}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <RightBar
            icon={<Users />}
            title={'Доставщики'}
            open={rightBarOpen}
            setOpen={setRightBarOpen}
            content={loading ? <Loader /> : rightBarContent}
          />
        }
      />
      <Container>
        {loadingMap && <Loader />}
        <YMap
          location={filteredList}
          onLoad={e => setLoadingMap(!e)}
          handleMarketId={handleMarketId}
          openDetail={openDetail}
          setOpenDetail={setOpenDetail}
          withWay
        />
      </Container>
      <Modal
        title={marketName || 'Клиент'}
        subTitle={marketAddress || 'Адрес не указан'}
        open={openDetail}
        isOpen={setOpenDetail}
        position={'bottom'}
      >
        <TasksWrap>
          {loading ? <Loader size={0.75} /> : selectedPlansList}
        </TasksWrap>
      </Modal>
    </>
  )
}

export default TrackGrid
