import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import * as ROUTES from 'constants/routes'
import {
  manufactureBatchesDetailAction,
  manufactureBatchesFinishCreateAction,
  manufactureBatchesMaterialEditAction,
  manufactureBatchesMaterialIdDeleteAction,
  manufactureBatchesProductDeleteAction,
  manufactureBatchesProductEditAction
} from 'containers/Productions/actions/ProductionsBatchesAction'
import {appendParamsToUrl} from 'helpers/url'
import {path} from 'ramda'

export default compose(
  withFetchList({
    stateName: STATES.MANUFACTURE_BATCHES_DETAIL,
    action: manufactureBatchesDetailAction,
    key: STATES.MANUFACTURE_BATCHES_DETAIL
  }),
  withHandlers({
    handleSetAcceptorIdQuery: props => (acceptorId) => {
      props.history.push({
        search: appendParamsToUrl({acceptorId}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_FINISH_CREATE,
    action: manufactureBatchesFinishCreateAction,
    key: STATES.MANUFACTURE_BATCHES_FINISH_CREATE,
    successMessage: 'Успех',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_URL
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_PRODUCT_EDIT,
    action: manufactureBatchesProductEditAction,
    key: STATES.MANUFACTURE_BATCHES_PRODUCT_EDIT,
    successAction: manufactureBatchesDetailAction,
    successMessage: 'Изменения применены'
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_PRODUCT_DELETE,
    action: manufactureBatchesProductDeleteAction,
    key: STATES.MANUFACTURE_BATCHES_PRODUCT_DELETE,
    successAction: manufactureBatchesDetailAction,
    successMessage: 'Товар удалён'
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_MATERIAL_EDIT,
    action: manufactureBatchesMaterialEditAction,
    key: STATES.MANUFACTURE_BATCHES_MATERIAL_EDIT,
    successAction: manufactureBatchesDetailAction,
    successMessage: 'Изменения применены'
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_MATERIAL_DELETE,
    action: manufactureBatchesMaterialIdDeleteAction,
    key: STATES.MANUFACTURE_BATCHES_MATERIAL_DELETE,
    successAction: manufactureBatchesDetailAction,
    successMessage: 'Сырьё удалено'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_URL
  }),
  withRedirect({
    key: 'goAddProducts',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_ADD_PRODUCTS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goAddMaterials',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_ADD_MATERIALS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_EDIT_URL,
    withCurrentParams: true
  }),
  withHandlers({
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    },
    addMaterialIdHandler: props => (materialId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({materialId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
