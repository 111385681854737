import React from 'react'
import styled from 'styled-components'
import {filter, isEmpty, path, prop, propEq} from 'ramda'
import * as API from 'constants/api'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import InfiniteScroll from '../../../../../components/InfiniteScroll/InfiniteScroll'
import * as actionTypes from '../../../../../constants/actionTypes'
import NoResults from '../../../../../components/NoData/NoResults'
import * as serializers from '../../../actions/serializers/marketsSerializer'
import mapIndexed from '../../../../../helpers/mapIndexed'
import MarketsItem from '../../../components/MarketsItem/MarketsItem'
import DebounceSearch from '../../../../../components/Forms/DebounceSearch/DebounceSearch'
import BottomBar from '../../../../../components/Navigation/BottomBar'
import Button from '../../../../../components/Forms/Button'

const Wrap = styled('div')`
  padding: 60px 0;
`

const Title = styled('h3')`
    line-height: 20px;
    margin-left: 15px;
`

const FixedSearch = styled('div')`
    position: fixed;
    top: 60px;
    left: 14px;
    padding: 10px 0;
    right: 14px;
    background: #fff;
    z-index: 100;
    opacity: 0.96
`

const SearchIsShop = props => {
  const {
    setCreate,
    searchIsShop,
    filterActions,
    query,
    mapLocations,
    invalid
  } = props

  const handleGoDetail = (marketId) => {
    searchIsShop.goDetail(marketId)
  }
  const marketsListData = prop('data', searchIsShop.dataList)
  // MainList
  const list = marketsListData && mapIndexed((item) => {
    const marketName = prop('name', item)
    const marketId = prop('id', item)
    const address = prop('address', item)
    const distance = prop('distance', item)
    const isConfirmed = prop('isConfirmed', item)
    const isActive = prop('isActive', item)
    const photos = prop('images', item)
    const lat = path(['location', 'lat'], item)
    const lon = path(['location', 'lon'], item)
    const isPrimaryPhoto = propEq('isPrimary', true)
    const primaryPhotosArr = filter(isPrimaryPhoto, photos)
    const primaryPhotoSrc = path(['0', 'file'], primaryPhotosArr)
    const status =
        !isConfirmed
          ? 'pending'
          : (isActive
            ? 'active'
            : 'inactive')

    return (
      <MarketsItem
        key={marketId}
        title={marketName}
        address={address}
        location={{lat, lon}}
        imgSrc={primaryPhotoSrc}
        status={status}
        distance={distance}
        onClick={() => handleGoDetail(marketId)}
      />
    )
  }, marketsListData)

  // MainContent
  const mainContent =
    <>
      <Title>Список совпадений</Title>
      <InfiniteScroll
        api={API.MARKETS_LIST}
        actionType={actionTypes.MARKETS_LIST}
        emptyData={<li><NoResults text={'Клиент не найден, нажмите далее'} /></li>}
        params={serializers.marketsListSerializer(query, mapLocations)}
        list={list || []}
      />
    </>

  return (
    <>
      <CreateBar
        title={'Создание клиента'}
        goExit={() => searchIsShop.goBack()}
      />
      <Container
        background={'#fff'}
      >
        <FixedSearch>
          <DebounceSearch filterActions={filterActions} placeholder={'Введите название'} />
        </FixedSearch>
        <Wrap>
          {invalid || isEmpty(mapLocations)
            ? <NoResults text={'Включите GPS для отображения ближайших клиентов. Это поможет предотвратить их дублирование'} />
            : mainContent}
          <BottomBar
            fixed
          >
            <Button
              text={'Продолжить'}
              fullWidth
              onClick={() => setCreate(false)}
            />
          </BottomBar>
        </Wrap>
      </Container>
    </>
  )
}

export default SearchIsShop
