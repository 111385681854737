import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {path} from 'ramda'
import {sprintf} from 'sprintf-js'

export const usersListForSelectAction = () => {
  const defaultParams = {
    page_size: 1000
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.USERS_LIST_FOR_SELECT, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.USERS_LIST_FOR_SELECT,
      payload
    })
  }
}

export const usersDeliveryManListAction = () => {
  const defaultParams = {
    page_size: 1000,
    group: 'delivery'
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.USERS_LIST_FOR_SELECT, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.USERS_DELIVERYMAN_LIST,
      payload
    })
  }
}

export const userDetailAction = (params, props) => {
  const userId = path(['match', 'params', 'id'], props)

  const defaultParams = {}
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.USER_DETAIL, userId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.USERS_DETAIL,
      payload
    })
  }
}
