import React, {useState} from 'react'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import * as ROUTES from 'constants/routes'
import {sprintf} from 'sprintf-js'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import styled from 'styled-components'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as serializers from '../../../actions/serializers/stocksSerializers'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from 'containers/Stocks/containers/Outcome/Grid/StocksOutcomeFilter'
import mapIndexed from 'helpers/mapIndexed'
import StocksItem from 'containers/Stocks/components/StocksItem/StocksItem'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'

const Content = styled('div')`
  min-height: ${({secondHeight}) => 'calc(100vh - ' + (60 + secondHeight) + 'px)'} ;
`

const StocksOutcomeGrid = (props) => {
  const {
    filterActions,
    location,
    history,
    customStore,
    stocksOutcomeTasks,
    goSelectionCreate
  } = props

  // Menus
  const menusArr = [
    {
      title: 'Подбор',
      onClick: () => goSelectionCreate()
    }
  ]

  // Data
  const customStoreData = prop('data', customStore)
  const stocks = prop('stocks', customStoreData)
  const stocksOutcomeTasksData = prop('data', stocksOutcomeTasks)

  // UseStates
  const [openFilter, setOpenFilter] = useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // SecondHeight
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Outcome List
  const outcomeList = stocksOutcomeTasksData && mapIndexed((item, index) => {
    const stock = path(['stock', 'name'], item)
    const date = prop('dateRequest', item)
    const receiver = prop('receiver', item)
    const type = prop('type', item)
    const id = prop('id', item)
    const acceptedTime = prop('acceptedTime', item)
    const withHistory = prop('withHistory', searchObj)

    return (
      <StocksItem
        onClick={() => history.push({
          pathname: type !== 'supply' && sprintf(ROUTES.STOCKS_OUTCOME_DETAIL_URL, id),
          search: appendParamsToUrl({type: type}, path(['location', 'search'], history))
        })}
        orderId={id}
        key={index}
        stock={stock}
        date={date}
        fromWhom={receiver}
        type={type}
        acceptedTime={acceptedTime}
        withHistory={withHistory}
      />
    )
  }, stocksOutcomeTasksData)

  // MainContent
  const outcomeTasks =
    <InfiniteScroll
      api={API.STOCKS_OUTCOME_TASKS}
      actionType={actionTypes.STOCKS_OUTCOME_TASKS}
      emptyData={<li><NoResults /></li>}
      params={serializers.stocksOutcomeTasksSerializer(searchObj)}
      list={outcomeList || []}
    />

  // Render
  return (
    <>
      <MainBar
        title={'Передачи'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
          >
            <Filter
              setOpenFilter={setOpenFilter}
              stocks={stocks}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        <Content secondHeight={secondHeight}>
          {outcomeTasks}
        </Content>
        <SecondMenu
          title={'Дополнительные функции по работе с подборами'}
          menusArr={menusArr}
          button={
            <Fab
              text={'Дополнительно'}
              withfixed={'true'}
            >
              <Plus size={20} color={'#fff'} />
            </Fab>
          }
        />
      </Container>
    </>
  )
}

export default StocksOutcomeGrid
