import {compose} from 'recompose'
import Grid from 'containers/Productions/containers/Batches/Grid/Create/StepTwo/Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {manufactureListAction} from 'containers/Productions/actions/ProductionsBatchesAction'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const manufactureProductsList = prop('manufactureProductsList', state)
    return {
      manufactureProductsList
    }
  }),
  withFetchList({
    stateName: STATES.MANUFACTURE_LIST,
    action: manufactureListAction,
    key: STATES.MANUFACTURE_LIST
  }),
  withRedirect({
    key: 'goExit',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_URL
  }),
  withRedirect({
    key: 'goNext',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_CREATE_STEP_THREE_URL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_CREATE_URL
  })
)(Grid)
