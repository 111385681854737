import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import {storageData} from 'helpers/storage'
import * as actionTypes from 'constants/actionTypes'
import * as API from 'constants/api'

export const setTokenAction = () => {
  return {
    type: `${actionTypes.AUTH}_FULFILLED`,
    payload: {
      token: storageData('token').getValue(),
      server: storageData('server').getValue()
    }
  }
}

export const logoutAction = () => {
  localStorage.clear()
  return {
    type: actionTypes.AUTH,
    payload: Promise.resolve(null)
  }
}

export const customStoreClearAction = () => {
  return {
    type: `${actionTypes.CUSTOM_STORE}_CLEAR`
  }
}

export const signInAction = values => {
  const defaultValues = {
    username: values.username,
    password: values.password
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.SIGN_IN, {...defaultValues})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.AUTH,
      payload
    })
  }
}

export const customStoreAction = () => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.CUSTOM_STORE)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CUSTOM_STORE,
      payload
    })
  }
}

export const configAction = () => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.CONFIG)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CONFIG,
      payload
    })
  }
}
