import React from 'react'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import styled from 'styled-components'
import Switcher from 'components/Forms/Switcher'
import {prop} from 'ramda'

const Wrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 15px;
  }
`

const ProductsTypesCreateForm = props => {
  const {
    productsTypesEditLoading,
    isEdit,
    values
  } = props

  const withParentValue = prop('withParent', values)

  return (
    <Wrap>
      <Field
        component={TextField}
        type={'text'}
        label={'Название типа'}
        name={'name'}
        required
        fullWidth
      />
      <Field
        component={Switcher}
        name={'withParent'}
        type={'checkbox'}
        label={'Привязать к родительскому типу'}
        fullWidth
      />
      {withParentValue &&
      <Field
        component={UniversalSearchField}
        label={'Родительский тип'}
        name={'type'}
        api={API.PRODUCTS_TYPES}
        params={{page_size: '1000'}}
        required
        fullWidth
      />}
      <Field
        component={TextField}
        type={'number'}
        label={'Порядковый номер'}
        name={'order'}
        fullWidth
      />
      <Field
        component={Switcher}
        name={'showInMarket'}
        type={'checkbox'}
        label={'Доступ через бот'}
        fullWidth
      />
      {/* <Field */}
      {/*  component={Switcher} */}
      {/*  name={'showManyProducts'} */}
      {/*  type={'checkbox'} */}
      {/*  label={'Отображать все продукты выбранного типа'} */}
      {/*  fullWidth */}
      {/* /> */}
      <BottomBar>
        <Button
          text={isEdit ? 'Редактировать' : 'Создать'}
          disabled={productsTypesEditLoading}
          fullWidth
        />
      </BottomBar>
    </Wrap>
  )
}

export default ProductsTypesCreateForm
