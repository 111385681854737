import React from 'react'
import {prop} from 'ramda'
import mapIndexed from 'helpers/mapIndexed'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {MainBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import MainMenu from 'components/Navigation/components/MainMenu'
import NoResults from 'components/NoData/NoResults'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import DebounceSearch from '../../../components/Forms/DebounceSearch/DebounceSearch'
import {parseParams} from 'helpers/url'
import {plansListSerializers} from 'containers/Plans/actions/serializers/plansSerializer'
import PlansAgentItem from 'containers/Plans/components/PlansAgentItem/PlansAgentItem'

const PlansGrid = props => {
  const {
    filterActions,
    plansList,
    location,
    config,
    goDetail
  } = props

  // Data
  const configData = prop('data', config)
  const plansListData = prop('data', plansList)

  // Const
  const maxDowntime = prop('mAXDOWNTIME', configData)
  const realCurrency = prop('pRIMARYCURRENCY', configData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // MainList
  const list = plansListData && mapIndexed((item) => {
    const name = prop('fullName', item)
    const id = prop('id', item)
    const downtime = prop('differenceMin', item)
    const planCount = prop('planCount', item)
    const planDoneCount = prop('planDoneCount', item)
    const orderTotalPrice = prop('orderTotalPrice', item)
    const clientTransactionTotalPrice = prop('clientTransactionTotalPrice', item)
    const lastVisitMarket = prop('lastVisitMarket', item)
    const lastVisitMarketDate = prop('lastVisitMarketDate', item)
    const lastVisitMarketTask = prop('lastVisitMarketTask', item)
    const nextVisitMarket = prop('nextVisitMarket', item)
    const nextMarketTask = prop('nextMarketTask', item)
    const distanceNextMarket = prop('distanceNextMarket', item)
    const startedWork = prop('startedWork', item)

    return (
      <PlansAgentItem
        key={id}
        title={name}
        downtime={downtime}
        maxDowntime={maxDowntime}
        currency={realCurrency}
        orderTotalPrice={orderTotalPrice}
        planCount={planCount}
        planDoneCount={planDoneCount}
        onClick={() => goDetail(id)}
        clientTransactionTotalPrice={clientTransactionTotalPrice}
        lastVisitMarket={lastVisitMarket}
        lastVisitMarketDate={lastVisitMarketDate}
        lastVisitMarketTask={lastVisitMarketTask}
        nextVisitMarket={nextVisitMarket}
        nextMarketTask={nextMarketTask}
        startedWork={startedWork}
        distanceNextMarket={distanceNextMarket}
      />
    )
  }, plansListData)

  // SecondHeight
  const secondHeight = 100

  // SecondContent
  const secondContent =
    <>
      {searchForm}
    </>

  // Content
  const mainContent =
    <InfiniteScroll
      api={API.STATISTICS_FOR_SUPERVISOR}
      actionType={actionTypes.STATISTICS_FOR_SUPERVISOR}
      emptyData={<li><NoResults /></li>}
      params={plansListSerializers(searchObj)}
      list={list || []}
    />

  // Render
  return (
    <>
      <MainBar
        title={'План агентов'}
        secondHeight={secondHeight}
        secondContent={secondContent}
        firstBtn={
          <MainMenu />
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default PlansGrid
