import {map, pipe, sum, path} from 'ramda'
import numberFormat from 'helpers/numberFormat'

export default (array, keyPath, formated) => {
  const sumBy = pipe(
    map(path(keyPath)),
    sum
  )(array)
  return formated ? numberFormat(sumBy) : sumBy || 0
}
