import {compose, withHandlers} from 'recompose'
import MarketsVisitsGrid from 'containers/Clients/Grid/Visits/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'

export default compose(
  connect((state) => {
    const marketsVisits = prop('marketsVisits', state)
    return {
      marketsVisits
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goMap',
    redirectUrl: ROUTES.MARKETS_ITEM_MAP_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetVisitId: props => (visitId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({visitId}, path(['history', 'location', 'search'], props))
      })
    },
    handleSetLocation: props => (lon, lat) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({secondLon: lon, secondLat: lat}, path(['history', 'location', 'search'], props))
      })
    },
    handleSetSelectedImage: props => (imageViewer) => {
      props.history.push({
        search: appendParamsToUrl({imageViewer}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(MarketsVisitsGrid)
