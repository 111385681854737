import React from 'react'
import styled from 'styled-components'
import FabUi from '@material-ui/core/Fab'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {loader} from 'components/Skeleton/SkeletonKeyframe'

const StyledFab = styled(FabUi)`
  color: ${({theme, colorname}) => colorname || theme.palette.white} !important;
  background: ${({theme, background, loading}) => !loading ? (background || theme.palette.blue) : theme.skeleton.primary} !important;
  box-shadow: none !important;
  z-index: 100 !important;
  position: ${({withfixed}) => (withfixed === 'true') && 'fixed'}!important;
  bottom: ${({withfixed}) => (withfixed === 'true') && '20px'}!important;
  right: ${({withfixed}) => (withfixed === 'true') && '20px'}!important;
  opacity: 0.8;
  animation: ${loader} ${({loading}) => loading ? '1s' : '0s'} infinite ease-in-out;
  ${({styles}) => styles};
  svg{
    color: ${({loading}) => !loading ? '#fff' : '#ccc'};
  }
`

const GlobalCss = withStyles({
  '@global': {
    '.MuiFab-sizeSmall': {
      width: '30px',
      height: '30px'
    },
    '.MuiFab-sizeMedium': {
      width: '40px',
      height: '40px'
    },
    '.MuiFab-root': {
      minHeight: '26px'
    }
  }
})(() => null)

const Fab = (props) => {
  const {
    type = 'submit',
    input,
    withfixed,
    colorname,
    children,
    background,
    onClick,
    loading,
    styles
  } = props

  // Render
  return (
    <>
      <GlobalCss />
      <StyledFab
        type={type}
        withfixed={withfixed}
        colorname={colorname}
        background={background}
        onClick={onClick}
        loading={loading}
        styles={styles}
        {...input}
        {...props}
      >
        {children}
      </StyledFab>
    </>

  )
}

Fab.propTypes = {
  fixed: PropTypes.bool
}

export default Fab
