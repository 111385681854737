import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {loader} from 'components/Skeleton/SkeletonKeyframe'
import {
  Home as MarketIcon,
  User as UserIcon,
  Database as ProductIcon,
  Bell as BellIcon,
  Box as BoxIcon,
  FileText as ReportIcon
} from 'react-feather'
import CarIcon from 'components/SvgIcons/CustomIcons/CarIcon'
import CardIcon from 'components/SvgIcons/CustomIcons/CardIcon'
import WalletIcon from 'components/SvgIcons/CustomIcons/WalletIcon'

const Wrap = styled('div')`
  position: relative;
  min-width: ${({size}) => size};
  width: ${({size}) => size};
  height: ${({size}) => size};
  &:after{
    width: 100%;
    height: 100%;
    content: '';
    display: ${({imgSrc}) => imgSrc ? 'none' : 'block'};
    background: ${({theme, background}) => background || theme.skeleton.primary};
    border-radius: 100%;
    animation: ${loader} ${({skeleton}) => skeleton ? '1s' : '0'} infinite ease-in-out;
  }
  ${({styles}) => styles}
`
const Img = styled('div')`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: absolute;
  overflow: hidden;
  background: #eee url(${({imgSrc}) => imgSrc}) no-repeat center;
  background-size: cover;
`
const NoPhoto = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  svg{
    color: ${({theme, svgColor}) => svgColor || theme.text.secondary};
    max-width: 22px;
    max-height: 22px;
  }
`
const Dot = styled('span')`
  display: ${({status, skeleton}) => (status === 'none' || skeleton) && 'none'};
  position: absolute;
  border: 1px solid #fff;
  height: ${({statusSize}) => statusSize || '10px'};
  width: ${({statusSize}) => statusSize || '10px'};
  z-index: 10;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  background: ${({theme, status}) =>
    (status === 'active')
      ? theme.palette.green
      : (status === 'inactive')
        ? theme.palette.red
        : theme.palette.grey};
`

const PhotoWithStatus = props => {
  const {
    size = '45px',
    imgSrc,
    background,
    status = 'pending',
    statusSize,
    svgColor,
    styles,
    skeleton,
    type,
    onClick
  } = props

  const icon = () => {
    switch (type) {
    case 'new_market':
    case 'market': return <MarketIcon />
    case 'user': return <UserIcon />
    case 'product': return <ProductIcon />
    case 'supply_accepted': return <ProductIcon />
    case 'car': return <CarIcon />
    case 'notification': return <BellIcon fill={'#fff'} />
    case 'called_agent': return <BellIcon />
    case 'order_delivered':
    case 'order_ready':
    case 'order_request':
    case 'finance_transaction':
    case 'bank': return <CardIcon />
    case 'cash': return <WalletIcon />
    case 'custom_client_transaction': return <BellIcon />
    case 'added_return_order': return <BoxIcon />
    case 'report': return <ReportIcon />
    case 'new_contract': return <BellIcon />
    default: return null
    }
  }

  return (
    <Wrap
      size={size}
      imgSrc={imgSrc}
      styles={styles}
      skeleton={skeleton}
      background={background}
      onClick={onClick}
    >
      {
        imgSrc
          ? <Img imgSrc={imgSrc} />
          : <NoPhoto svgColor={svgColor}>{icon()}</NoPhoto>
      }
      <Dot statusSize={statusSize} status={status} skeleton={skeleton} />
    </Wrap>
  )
}

PhotoWithStatus.propTypes = {
  size: PropTypes.string,
  imgSrc: PropTypes.string,
  status: PropTypes.string,
  statusSize: PropTypes.string,
  skeleton: PropTypes.bool,
  styles: PropTypes.object,
  type: PropTypes.string
}

export default PhotoWithStatus
