import React, {useState} from 'react'
import {PhotoBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import Loader from 'components/Loader'
import styled from 'styled-components'
import {path, prop} from 'ramda'
import NoResults from 'components/NoData/NoResults'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import ModalConfirm from 'components/Modal/ModalConfirm'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'
import Button from 'components/Forms/Button'
import Perms from 'components/Perms/Perms'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  min-height: ${({secondHeight}) => secondHeight && `calc(100vh - ${secondHeight + 60}px)`};
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const ButtonWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  & > *:not(:last-child){
    margin-right: 10px;
  }
`

const ProductsDetailGrid = props => {
  const {
    productsDetail,
    productsDelete,
    config,
    goBack,
    goEdit,
    match
  } = props

  // useState
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Location
  const productId = path(['params', 'id'], match)

  // Data
  const productsDetailData = prop('data', productsDetail)
  const configData = prop('data', config)
  const productsDetailLoading = prop('loading', productsDetail)
  const productsDeleteLoading = prop('loading', productsDelete)

  // Const
  const primaryCurrency = prop('pRIMARYCURRENCY', configData)
  const productName = prop('name', productsDetailData)
  const productType = path(['type', 'name'], productsDetailData)
  const productParentType = path(['type', 'parent', 'name'], productsDetailData)
  const measurementName = path(['measurement', 'name'], productsDetailData)
  const showInMarket = prop('showInMarket', productsDetailData)
  const showInMarketStatus = showInMarket ? 'active' : 'inactive'
  const statusText = showInMarket ? 'Активный' : 'Снят с продажи'
  const description = prop('description', productsDetailData)
  const coverPhoto = path(['image', 'file'], productsDetailData) || undefined
  const price = prop('price', productsDetailData)

  // SecondHeight
  const secondHeight = (!productsDetailLoading && coverPhoto) ? 200 : 50

  const descriptionContent =
    <TilesList
      title={'Описание'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Статус'} is={<TextStatus bold status={showInMarketStatus}>{statusText}</TextStatus>} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Тип'} is={productParentType || productType} />
      </TileItem>
      {productParentType &&
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Подтип'} is={productType} />
      </TileItem>}
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Цена'} is={numberFormat(price, primaryCurrency)} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Ед. измерения'} is={measurementName} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Описание'} is={description} />
      </TileItem>
    </TilesList>

  // Buttons
  const buttons =
    <ButtonWrap>
      <Perms
        perms={['change_product']}
        onClick={() => goEdit(productId)}
      >
        <Button
          text={'Редактировать'}
          onClick={() => goEdit(productId)}
          fullWidth
        />
      </Perms>
      <Perms
        perms={['delete_product']}
        onClick={() => setConfirmModalOpen(true)}
      >
        <Button
          text={'Удалить'}
          background={'#e66767'}
          fullWidth
        />
      </Perms>
    </ButtonWrap>

  // MainContent
  const mainContent =
    <>
      {descriptionContent}
      {buttons}
    </>

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        onClick={() => productsDelete.onSubmit({id: productId})}
        loading={productsDeleteLoading}
        type={'delete'}
      />
    </>

  // Render
  return (
    <>
      <PhotoBar
        title={(!productsDetailLoading && productName) || 'Продукт'}
        cover={!productsDetailLoading ? coverPhoto : undefined}
        secondHeight={secondHeight}
        goBack={() => goBack()}
      />
      <Container
        secondHeight={secondHeight}
      >
        <Wrap
          secondHeight={secondHeight}
        >
          {productsDetailLoading
            ? <Loader />
            : mainContent || <NoResults text={'Нет данных'} />}
        </Wrap>
      </Container>
      {modals}
    </>
  )
}

export default ProductsDetailGrid
