import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'

export const TabsBar = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed, .MuiTabs-scroller.MuiTabs-scrollable{
    padding: ${({padding}) => padding || '6px 16px'};
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: 1px solid transparent !important;
    }
    &.Mui-selected + * {
      border-left: 1px solid transparent !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`
