import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import {Check as CheckIcon} from 'react-feather'
import PropTypes from 'prop-types'
import {find} from 'ramda'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: calc(100% - 20px);
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)``
const Delivery = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const Price = styled('h4')`
  color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : theme.palette.black
};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '25px'
  }
}
const Checked = styled('b')`
  background: transparent;
  box-shadow: inset 0 0 3px 0 #ccc;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    color: ${({theme}) => theme.palette.blue};
    stroke-width: 3px;
  }
`

const StocksOrdersItem = props => {
  const {
    market,
    productId,
    selectedProducts,
    onClick,
    orderNumber,
    totalPrice,
    currency,
    paymentStatus,
    address
  } = props
  const isProduct = find(i => i === productId)(selectedProducts)
  return (
    <TileItem
      height={'auto'}
      onClick={() => onClick(productId)}
      styles={styles}
    >
      <LeftBar>
        <h3>№ {orderNumber || 'не определён'}</h3>
        <Delivery>
          <span>
            {market}
          </span>
        </Delivery>
        <Delivery>
          <span>
            {address}
          </span>
        </Delivery>
        {totalPrice &&
        <Price
          status={paymentStatus}
        >
          {numberFormat(totalPrice, currency)}
        </Price>}
      </LeftBar>
      <Checked>
        {isProduct &&
        <CheckIcon size={16} />}
      </Checked>
    </TileItem>
  )
}

StocksOrdersItem.propTypes = {
  orderNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  totalPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string,
  paymentStatus: PropTypes.string
}

export default StocksOrdersItem
