import React from 'react'
import {Form as FinalForm} from 'react-final-form'
import arrayMutators from 'final-form-arrays'

const Form = (props) => {
  const {onSubmit, initialValues, children, getValues, haveMutators, ...rest} = props
  return (
    <FinalForm
      {...rest}
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={haveMutators && {
        // potentially other mutators could be merged here
        ...arrayMutators
      }}
      render={({handleSubmit, ...formProps}) => (
        <form onSubmit={handleSubmit}>
          {getValues ? getValues(formProps.values) : null}
          {React.cloneElement(children, formProps)}
        </form>
      )}
    />
  )
}

export default Form
