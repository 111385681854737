import {withRouter} from 'react-router-dom'
import {compose, withHandlers} from 'recompose'
import {sprintf} from 'sprintf-js'
import {paramsToSearch, parseParams} from 'helpers/url'

export default ({key, redirectUrl, withCurrentParams, newParams}) => {
  return compose(
    withRouter,
    withHandlers({
      [key]: ({history, location}) => (id, secondId, params) => {
        const currentParams = parseParams(location.search)
        const allParams = (withCurrentParams && newParams) ? {...currentParams, ...params, ...newParams} : newParams ? {...params, ...newParams} : withCurrentParams ? {...currentParams, ...params} : params
        if (id) {
          if (secondId) {
            history.push({
              pathname: sprintf(redirectUrl, id, secondId),
              search: paramsToSearch(allParams)
            })
          } else {
            history.push({
              pathname: sprintf(redirectUrl, id),
              search: paramsToSearch(allParams)
            })
          }
        } else {
          history.push({
            pathname: redirectUrl,
            search: paramsToSearch(allParams)
          })
        }
      }
    })
  )
}
