import React from 'react'
import PropTypes from 'prop-types'
import toCamelCase from 'helpers/toCamelCase'
import SearchField from '../SearchField'
import axios from 'helpers/axios'
import {prop} from 'ramda'

const getText = (obj) => {
  return prop('firstName', obj) + ' ' + prop('secondName', obj)
}

const UsersSearchField = (props) => {
  const {input, api, params, parent, ...defaultProps} = props
  const getOptions = () => {
    return (dispatch, getState) => {
      return axios({dispatch, getState})
        .get(`${api}`, {params: {...parent, ...params}})
        .then(({data}) => {
          const array = data.results || data
          return Promise.resolve(toCamelCase(array))
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          return console.log('Ошибка здесь', e)
        })
    }
  }
  return (
    <SearchField
      getOptions={getOptions}
      parent={parent}
      placeholder={props.placeholder}
      getValue={SearchField.defaultGetValue('id')}
      getText={getText}
      input={input}
      {...defaultProps}
    />
  )
}

UsersSearchField.propTypes = {
  api: PropTypes.string.isRequired,
  params: PropTypes.object,
  parent: PropTypes.object,
  optionName: PropTypes.string
}

export default UsersSearchField
