import React from 'react'
import PropTypes from 'prop-types'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import {ChevronRight as ArrowIcon, Trash2 as TrashIcon} from 'react-feather'
import {prop} from 'ramda'
import PhotoWithStatus from 'components/PhotoWithStatus'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 76%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  width: 45px;
  margin-right: 10px;
  h3{
    font-size: 14px;
  }
`
const RightBar = styled(Bar)`
  text-align: left;
`
const Text = styled('p')`
  color: ${({theme}) => theme.palette.black};
  width: 100%;
`

const Comment = styled('p')`
  color: ${({theme}) => theme.text.secondary};
  width: 100%;
`

const ArrowWrap = styled('div')`
  position: absolute;
  right: 15px;
  top: 50%;
  transition: ${({theme}) => theme.transition.primary};
  transform: translateY(-50%);
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const Content = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
`

const NotificationItem = props => {
  const {
    deleteNotificationDialog,
    moreOptions,
    template = 'notification',
    backgroundColor,
    text,
    title,
    history,
    getLink,
    detailId,
    searchObj,
    createdDate
  } = props

  const detailIdQuery = prop('detailId', searchObj)

  const eq = (+detailIdQuery === +detailId)

  const handleClick = () => {
    moreOptions === false && history.push(getLink(template))
  }

  const background = eq ? '#f7f7f8' : ''

  const styles = {
    '& > div': {
      display: 'flex',
      height: 'auto',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '30px'
    },
    background: background
  }
  return (
    <>
      <TileItem
        onClick={getLink && handleClick}
        styles={styles}
      >
        <Content>
          <LeftBar>
            <PhotoWithStatus
              background={backgroundColor}
              status={'none'}
              svgColor={'#fff'}
              type={template}
            />
          </LeftBar>
          <RightBar>
            <h3>{title}</h3>
            <Comment>{createdDate}</Comment>
          </RightBar>
        </Content>
        <ArrowWrap
          eq={eq}
        >
          {moreOptions ? (
            <TrashIcon
              onClick={() => deleteNotificationDialog.onOpen(detailId)}
              size={20}
              color={'#e66767'}
            />
          ) : (getLink && <Arrow />)}
        </ArrowWrap>
        <Text>{text}</Text>
      </TileItem>
    </>
  )
}

NotificationItem.propTypes = {
  detailId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  searchObj: PropTypes.object
}

export default NotificationItem
