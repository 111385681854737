import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'
import {path} from 'ramda'

export const checksDetailAction = (params, props) => {
  const checkId = path(['match', 'params', 'checkId'], props)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.POS_CHECKS_DETAIL, checkId), {...params})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.POS_CHECKS_DETAIL,
      payload
    })
  }
}
