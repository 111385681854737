import axios from 'axios'
import {storageData} from './storage'
import responseToCamelCase from './responseToCamelCase'
import {prop, path, compose, equals, curry} from 'ramda'
import {AUTH, CUSTOM_STORE} from 'constants/actionTypes'

export const getPayloadFromSuccess = prop('data')

export const getPayloadFromError = compose(
  data => Promise.reject(data),
  path(['response', 'data'])
)

const UNAUTHORIZED = 401

const axiosConfig = (api) => ({
  baseURL: api,
  transformResponse: [responseToCamelCase]
})

const errorInterceptors = curry((dispatch, error) => {
  const status = path(['response', 'status'], error)

  if (equals(UNAUTHORIZED, status)) {
    dispatch({type: `${AUTH}_CLEAR`})
    dispatch({type: `${CUSTOM_STORE}_CLEAR`})
    storageData('token').setValue('')
    storageData('server').setValue('')
  }

  return Promise.reject(error)
})

export default ({dispatch, getState}) => {
  const state = getState()
  const serverValue = storageData('server').getValue() || path(['auth', 'data', 'server'], state)
  const apiHost = serverValue && serverValue.replace(/\s/g, '')
  const finalApiHost =
    apiHost &&
    apiHost.slice(0, 4) === 'http'
      ? apiHost
      : `${'https://' + apiHost + '.ritm.uz'}`
  const api = `${finalApiHost}/ru/api/v1`
  const formatedApi = api.toLowerCase()

  const token = storageData('token').getValue() || path(['auth', 'data', 'token'], state)

  const headers = {
    common: {Authorization: token ? `Token ${token}` : ''}
  }

  const instance = axios.create({...axiosConfig(formatedApi), headers})

  instance.interceptors.response.use(
    response => response,
    errorInterceptors(dispatch)
  )

  return instance
}
