import React from 'react'
import Container from 'components/Container'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import {path, prop} from 'ramda'
import SwipeableViews from 'react-swipeable-views'
import CardDescription from 'components/Cards/CardDescription'
import moment from 'moment'
import {parseParams} from 'helpers/url'
import Filters from './StatisticsPosFilters'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import NoResults from 'components/NoData/NoResults'
import TilesList from 'components/Cards/Tile/TilesList'
import mapIndexed from 'helpers/mapIndexed'
import TileItem from 'components/Grid/Tile/TileItem'
import numberFormat from 'helpers/numberFormat'
import ChartBar from 'components/Charts/ChartBar'
import ProgressBar from 'components/Charts/ProgressBar'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {
  posStatisticsPopularProductsSerializer,
  posStatisticsRevenueSerializer
} from 'containers/StatisticsPos/actions/serializers/statisticsPosSerializer'

const TabsBar = styled('div')`
  position: fixed;
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 99;
  border-bottom: 1px solid #e4e4e6;
`
const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`
const Content = styled('div')`
  height: calc(100vh - 60px);
  padding-top: 90px;
  overflow-x: hidden;
  overflow-y: scroll;
`
const Div = styled('div')`
  position: fixed;
  background: ${({theme}) => theme.nav.primary.background};
  top: 104px;
  left: 0;
  right: 0;
  border-bottom: 1px solid #e0e4e8;
  & > * {
    padding: 15px 16px;
  }
`
const Inner = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const Wrap = styled('div')`
  padding: 14px 0;
  margin: 0 16px;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const ChartWrap = styled('div')`
  margin: 0 16px;
`
const ChartOptions = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.35px;
  color: #000;
  padding: 15px 0;
  &:not(:first-child){
    border-top: 1px solid #e0e4e8;
  }
`
const Option = styled('span')`
  position: relative;
  padding: 0 12px 0 14px;
  text-transform: lowercase;
  &:before{
    content: '';
    position: absolute;
    transform: translateY(50%);
    height: 10px;
    width: 10px;
    left: 0;
    background: ${props => props.color || '#ccc'};
    border-radius: 50%;
  }
`
const Title = styled('span')`
  display: block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme, color}) => color || theme.text.secondary};
`
const InfiniteWrap = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 220px);
  background: #f8f8f8;
  padding: 0 10px;
  border-radius: 10px;
`

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const StatisticsPosGrid = (props) => {
  const {
    location,
    filterActions,
    config,
    posTotalStatistics,
    posStatisticsPopularProducts,
    posStatisticsRevenue,
    goChecks
  } = props

  // Constants
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)

  // Data
  const configData = prop('data', config)
  const posTotalStatisticsData = prop('data', posTotalStatistics)
  const posStatisticsPopularProductsData = prop('data', posStatisticsPopularProducts)
  const posStatisticsRevenueData = prop('data', posStatisticsRevenue)
  const primaryCurrency = prop('pRIMARYCURRENCY', configData)

  const totalAmount = prop('totalAmount', posTotalStatisticsData)
  const totalNds = prop('totalNds', posTotalStatisticsData)
  const totalCount = prop('totalCount', posTotalStatisticsData)
  const averageCheck = prop('averageCheck', posTotalStatisticsData)
  const averageTime = prop('averageTime', posTotalStatisticsData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Initial values
  const beginDate = prop('beginDate', searchObj) || firstDayOfMonth
  const endDate = prop('endDate', searchObj) || lastDayOfMonth
  filterActions.initialValues = {
    beginDate,
    endDate
  }

  // UseState
  const [value, setValue] = React.useState(0)
  const [filterIsOpen, setFilterIsOpen] = React.useState(false)

  // Tabs configs
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }

  // Period
  const period =
    <CardDescription
      onClick={() => setFilterIsOpen(true)}
      styles={{
        '& > span': {
          fontSize: '14px',
          lineHeight: '17px'
        }
      }}
      it={'Период'}
      is={
        (beginDate && endDate)
          ? moment(beginDate).format('DD MMM YYYY') + ' - ' + moment(endDate).format('DD MMM YYYY')
          : 'За весь период'
      }
    />

  // Content
  // Tab one
  const tabContentOne =
    <>
      <Wrap>
        <Inner>
          <TilesList
            background={'#f8f8f8'}
          >
            <TileItem
              height={'auto'}
            >
              <CardDescription it={'Выручка'} is={numberFormat(totalAmount, primaryCurrency)} />
            </TileItem>
            <TileItem
              height={'auto'}
            >
              <CardDescription it={'НДС'} is={numberFormat(totalNds, primaryCurrency)} />
            </TileItem>
            <TileItem
              height={'auto'}
            >
              <CardDescription it={'Чеки'} is={numberFormat(totalCount, 'шт.')} />
            </TileItem>
            <TileItem
              height={'auto'}
            >
              <CardDescription it={'Ср. чек'} is={numberFormat(averageCheck, primaryCurrency)} />
            </TileItem>
            <TileItem
              height={'auto'}
            >
              <CardDescription it={'Ср. время на чек'} is={numberFormat(averageTime, 'сек')} />
            </TileItem>
          </TilesList>
        </Inner>
      </Wrap>
    </>

  // Tab two
  // Chart
  const infiniteContentTwoList = posStatisticsRevenueData && mapIndexed((item, index) => {
    const period = prop('period', item)
    const maxProgress = path(['0', 'totalAmount'], posStatisticsRevenueData)
    const totalAmount = prop('totalAmount', item)
    const checkCount = prop('checkCount', item)
    const averageCheck = prop('averageCheck', item)
    const thisDay = moment(period).format('YYYY-MM-DD')
    const handleClick = () => {
      goChecks(null, null, {beginDate: thisDay, endDate: thisDay})
    }

    return (
      <ChartBar
        key={index}
        onClick={handleClick}
      >
        <Title>{moment(period).format('DD MMM YYYY')}</Title>
        <ProgressBar
          maxProgress={+maxProgress}
          progressText={+totalAmount}
          progressSuffix={primaryCurrency}
          color={'#69768D'}
        />
        <CardDescription it={'Ср. чек'} is={numberFormat(averageCheck, primaryCurrency)} styles={{'& > span': {fontSize: '14px', lineHeight: '15px'}}} />
        <CardDescription it={'Кол-во чеков'} is={numberFormat(checkCount, 'шт.')} styles={{'& > span': {fontSize: '14px', lineHeight: '15px'}}} />
      </ChartBar>
    )
  }, posStatisticsRevenueData)

  const infiniteContentTwo =
    <InfiniteScroll
      api={API.POS_STATISTICS_REVENUE}
      actionType={actionTypes.POS_STATISTICS_REVENUE}
      emptyData={<NoResults />}
      childElement={'div'}
      useWindow={false}
      element={'div'}
      params={posStatisticsRevenueSerializer(searchObj, props)}
      list={infiniteContentTwoList || []}
    />

  const tabContentTwo =
    <ChartWrap>
      <ChartOptions>
        <Option color={'#69768d'}>выручка</Option>
      </ChartOptions>
      <InfiniteWrap>
        {infiniteContentTwo}
      </InfiniteWrap>
    </ChartWrap>

  // Tab three
  // Chart
  const infiniteContentThreeList = posStatisticsPopularProductsData && mapIndexed((item, index) => {
    const name = prop('name', item)
    const maxProgress = +path(['0', 'count'], posStatisticsPopularProductsData)
    const count = prop('count', item)

    return (
      <ChartBar
        key={index}
      >
        <Title>{name}</Title>
        <ProgressBar
          maxProgress={+maxProgress}
          progressText={+count}
          progressSuffix={'ед'}
          color={'#69768D'}
        />
      </ChartBar>
    )
  }, posStatisticsPopularProductsData)

  const infiniteContentThree =
    <InfiniteScroll
      api={API.POS_STATISTICS_POPULAR_PRODUCTS}
      actionType={actionTypes.POS_STATISTICS_POPULAR_PRODUCTS}
      emptyData={<NoResults />}
      childElement={'div'}
      useWindow={false}
      element={'div'}
      params={posStatisticsPopularProductsSerializer(searchObj, props)}
      list={infiniteContentThreeList || []}
    />

  const tabContentThree =
    <ChartWrap>
      <ChartOptions>
        <Option color={'#69768d'}>продано</Option>
      </ChartOptions>
      <InfiniteWrap>
        {infiniteContentThree}
      </InfiniteWrap>
    </ChartWrap>

  // Main content
  const mainContent =
    <>
      <TabsBar>
        <StyledTabs
          onChange={handleChange}
          value={value}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'Итоги'} {...a11yProps(0)} />
          <Tab label={'Выручка'} {...a11yProps(1)} />
          <Tab label={'Популярное'} {...a11yProps(2)} />
        </StyledTabs>
      </TabsBar>
      <Div>
        {period}
      </Div>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <Content value={value} index={0}>
          {tabContentOne}
        </Content>
        <Content value={value} index={1}>
          {tabContentTwo}
        </Content>
        <Content value={value} index={2}>
          {tabContentThree}
        </Content>
      </SwipeableViews>
    </>

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
    >
      <Filters
        setFilterIsOpen={setFilterIsOpen}
        {...filterActions}
      />
    </FiltersBar>

  // Render
  return (
    <>
      <MainBar
        title={'Статистика POS'}
        firstBtn={
          <MainMenu />
        }
        lastBtn={filters}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default StatisticsPosGrid
