import React, {useState} from 'react'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import {posChecksSerializer} from 'containers/Checks/actions/serializers/checksSerializers'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from './ChecksFilter'
import Container from 'components/Container/Container'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import mapIndexed from 'helpers/mapIndexed'
import ChecksItem from 'containers/Checks/components/ChecksItem/ChecksItem'

const ChecksGrid = (props) => {
  const {
    posChecks,
    location,
    filterActions,
    config,
    goDetail
  } = props

  // useStates
  const [openFilter, setOpenFilter] = useState(false)

  // Data
  const posChecksData = prop('data', posChecks)
  const configData = prop('data', config)
  const defaultCurrency = prop('dEFAULTCURRENCYNAMEFOROPERATIONS', configData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // MainList
  const list = posChecksData && mapIndexed((item) => {
    const id = prop('id', item)
    const invoiceNumber = prop('invoiceNumber', item)
    const totalPrice = prop('totalPrice', item)
    const endDate = prop('endDate', item)
    const createdByFN = path(['createdBy', 'firstName'], item)
    const createdBySN = path(['createdBy', 'secondName'], item)
    const createdBy = createdByFN + ' ' + createdBySN

    return (
      <ChecksItem
        key={id}
        price={totalPrice}
        priceSuffix={defaultCurrency}
        who={createdBy}
        date={endDate}
        number={invoiceNumber}
        onClick={() => goDetail(id)}
      />
    )
  }, posChecksData)

  // MainContent
  const mainContent =
    <InfiniteScroll
      api={API.POS_CHECKS}
      actionType={actionTypes.POS_CHECKS}
      emptyData={<li><NoResults /></li>}
      params={posChecksSerializer(searchObj)}
      list={list || []}
    />

  // SecondHeight
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Render
  return (
    <>
      <MainBar
        title={'Чеки'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
          >
            <Filter
              setOpenFilter={setOpenFilter}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default ChecksGrid
