import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import moment from 'moment'
import {Check as CheckIcon, ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
    position: relative;
    padding-left: ${({status}) => status ? '25px' : '0'};
  }
  p{
    font-size: 15px;
    line-height: 18px;
    color: ${({theme}) => theme.text.secondary};
  }
`
const Date = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
  text-align: right;
`
const RightBar = styled(Bar)`
  align-items: flex-end;
`
const Type = styled('p')`
  color: ${({theme}) => theme.palette.black};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const Checked = styled('span')`
  position: absolute;
  left: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translateY(-50%);
  background: ${({status, theme}) => status === 'active' ? theme.palette.orange : status === 'inactive' ? theme.palette.red : '#fff'};
  border: ${({status}) => (status !== 'active' && status !== 'inactive') && '2px solid #c4c4c4'};
  svg{
    color: #fff;
    stroke-width: 3px;
  }
`

const StocksItem = props => {
  const {
    onClick,
    stock,
    fromWhom,
    type,
    date,
    text,
    orderId,
    acceptedTime,
    withHistory
  } = props

  const checkType = () => {
    switch (type) {
    case 'supply': return 'Поставка'
    case 'transfer': return 'Передача'
    case 'delivery_return': return 'Возврат от поставщика'
    case 'order_return': return 'Возврат'
    case 'order': return 'Заказ'
    case 'supply_return': return 'Возврат с П.'
    case 'shipment': return 'Производство'
    default: return null
    }
  }

  const historyStatus = acceptedTime && 'active'

  return (
    <>
      <TileItem
        onClick={onClick}
        styles={styles}
      >
        <LeftBar
          status={withHistory && withHistory !== 'false'}
        >
          <h3>
            {(withHistory && withHistory !== 'false') &&
            <Checked
              status={historyStatus}
            >
              {historyStatus === 'active' ? <CheckIcon size={'12px'} /> : null}
            </Checked>}
            {fromWhom || 'Не известно'}
          </h3>
          <p>{stock || 'Не известно'}</p>
        </LeftBar>
        <RightBar>
          <Type>{type ? (orderId ? checkType() + ' № ' + orderId : checkType()) : text || 'Не определён'}</Type>
          <Date>
            {date
              ? moment(date).format('DD MMM YYYY HH:mm')
              : 'Нет даты'}
          </Date>
        </RightBar>
        <ArrowWrap>
          <Arrow />
        </ArrowWrap>
      </TileItem>
    </>
  )
}

StocksItem.propTypes = {
  onClick: PropTypes.func,
  stock: PropTypes.string,
  fromWhom: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  orderId: PropTypes.any,
  date: PropTypes.string
}

export default StocksItem
