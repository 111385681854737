import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import * as API from 'constants/api'
import React from 'react'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import normalizeNumber from 'helpers/normalizers/normalizeNumber'
import {map, path, prop} from 'ramda'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'
import {CURRENCY, selectValueFrom, PAYMENT_TYPE} from 'constants/backendConstant'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'
import BottomBar from '../../../../../../components/Navigation/BottomBar'
import Button from '../../../../../../components/Forms/Button'
import styled from 'styled-components'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const UpdateFormFields = (props) => {
  const {
    customStore,
    titleContent,
    updateDialog,
    config,
    paymentsConvert
  } = props
  // paymentsConvert
  const primaryCurrency = path(['data', 'pRIMARYCURRENCYID'], config)
  const paymentsConvertData = path(['data', 'amount'], paymentsConvert)
  const realValues = prop('values', props)
  const realValuesCurrency = path(['currency', 'value'], realValues) || prop('currency', realValues)
  // const realSaleCurrency = path(['currencyDebt', 'value'], realValues) || prop('currencyDebt', realValues)
  const realCourse = prop('customRate', realValues)
  const realValuesAmount = numberWithoutSpaces(prop('amount', realValues)) || null
  const restConvert = () => {
    if (primaryCurrency === 5 && realValuesCurrency === '4') {
      return (+realValuesAmount / +realCourse)
    } else if (primaryCurrency === 4 && realValuesCurrency === '5') {
      return (+realValuesAmount * +realCourse)
    } else {
      return +realValuesAmount
    }
  }
  const customStoreData = prop('data', customStore)
  const customStoreLoading = prop('loading', customStore)

  // For Divisions
  const divisions = prop('divisions', customStoreData)
  const divisionsOptions = divisions && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: value, label}
  }, divisions)

  // For currency
  const currencies = prop('currencies', customStoreData)
  const currenciesOptions = currencies && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, currencies)

  return (
    <StyledWrap>
      {titleContent}
      <Field
        component={UsersSearchField}
        api={API.USERS_LIST_FOR_SELECT}
        name={'user'}
        params={{page_size: '100'}}
        label={'Пользователь'}
        required
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'paymentType'}
        label={'Тип оплаты'}
        options={PAYMENT_TYPE}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'text'}
        name={'amount'}
        parse={normalizeNumber}
        label={'Сумма'}
        required
      />
      {divisionsOptions &&
      <Field
        component={StaticSearchField}
        name={'division'}
        label={'Организация'}
        loading={customStoreLoading}
        options={divisionsOptions}
        fullWidth
        required
      />}
      <Field
        component={StaticSearchField}
        name={'currency'}
        label={'Валюта'}
        loading={customStoreLoading}
        options={currenciesOptions}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        name={'customRate'}
        defaultValue={paymentsConvertData}
        label={'Курс'}
        required
        fullWidth
      />
      {realValuesCurrency && primaryCurrency && realCourse && realValuesAmount &&
      <p>После конвертации: <TextStatus bold>{numberFormat(restConvert())} {selectValueFrom(prop('currency', realValues), CURRENCY)}</TextStatus></p>}
      <Field
        component={StaticSearchField}
        name={'currencyDebt'}
        label={'Индивидуальная покрытия долга'}
        loading={customStoreLoading}
        options={currenciesOptions}
        fullWidth
      />
      <Field
        component={TextField}
        type={'text'}
        multiline
        name={'comment'}
        label={'Описание'}
        fullWidth
      />
      <BottomBar>
        <Button
          disabled={updateDialog.loading}
          type={'submit'}
          text={'Изменить'}
          fullWidth
        />
      </BottomBar>
    </StyledWrap>
  )
}

export default UpdateFormFields
