// User
export const USERS = 'main/user'
export const CONFIG = 'config'
export const SIGN_IN = `/${USERS}/auth/`
export const CUSTOM_STORE = `/${USERS}/auth-confirm/`
export const USERS_LIST_FOR_SELECT = `/${USERS}/crud/for_select/`
export const USER_DETAIL = '/main/crud/%d/'
export const USERS_LIST = '/main/crud/'
export const POSITIONS_LIST = '/main/position/'
export const USER_UPDATE_PASSWORD = '/main/crud/update_password/'
export const MANAGERS_LIST = '/main/crud/for_select/'
export const CLIENTS_LIST = '/sales/client/for_select/'
export const PROVIDERS_LIST = '/stock/provider/'
export const PRODUCTS_TYPES = '/main/product_type/'
export const PRODUCTS_TYPES_ITEM = '/main/product_type/%d/'
export const MEASUREMENT_LIST = '/main/measurement/'
export const SUPPLY_EXPENSE_LIST = '/stock/supply_expense/'

// Division
export const DIVISION = 'main/division'
export const DIVISION_LIST = `${DIVISION}/`

// Divisions
const DIVISIONS = 'main/division'
export const DIVISIONS_LIST = `/${DIVISIONS}/`

// Stocks
const STOCKS = 'stock'
export const STOCKS_LIST = `/${STOCKS}/stock/`
export const STOCKS_DETAIL = `/${STOCKS}/stock/%d/`
export const STOCKS_INCOME_TASKS = `/${STOCKS}/income_tasks/`
export const STOCKS_OUTCOME_TASKS = `/${STOCKS}/outcome_tasks/`
export const STOCKS_ACCEPT_OUTCOME = `/${STOCKS}/accept_outcome/`
export const STOCKS_INCOME_TRANSFER = `/${STOCKS}/stock_transfer/%d/change_status/`
export const STOCKS_INCOME_ACCEPT_PRODUCTS = `/${STOCKS}/supply/%d/accept_products/`
export const STOCKS_INCOME_ACCEPT_ORDER_RETURN = `/${STOCKS}/accept_order_return/`
export const STOCKS_OUTCOME_TRANSFER_DETAIL = `/${STOCKS}/stock_transfer/%d/`
export const STOCKS_INCOME_ACCEPT_TRANSFER = `/${STOCKS}/give_stock_transfer/`
export const STOCKS_RESTS_LIST = `/${STOCKS}/all_balances/`

// Supply
export const SUPPLY = 'stock/supply'
export const SUPPLY_LIST = `/${SUPPLY}/`
export const SUPPLY_DETAIL = `/${SUPPLY}/%d/`

// Clients
const MARKETS = 'market'
export const MARKETS_LIST = `/${MARKETS}/market/`
export const MARKETS_MINI_LIST = `/${MARKETS}/market/for_select/`
export const MARKETS_CREATE = `/${MARKETS}/market/`
export const MARKETS_UPDATE = `/${MARKETS}/market/%d/`
export const MARKETS_ITEM = `/${MARKETS}/market/%d/`
export const MARKETS_ITEM_PHOTO = '/main/file/%d/'
export const MARKETS_TYPE = `/${MARKETS}/market_type/`
export const MARKETS_VISITS = `/${MARKETS}/rejection/`
export const MARKETS_REJECTION_CREATE = `/${MARKETS}/rejection/`
export const MARKETS_TELEGRAMS = '/telegram/market_worker/'
export const MARKETS_TELEGRAMS_CREATE = `/${MARKETS}/set_market/`
export const MARKETS_REPORTS_CREATE = `/${MARKETS}/merch_report/`
export const MARKETS_REPORTS = `/${MARKETS}/merch_report/`
export const MARKETS_REPORTS_DETAIL = `/${MARKETS}/merch_report/%d/`
export const MARKETS_AGENT_PLANS = `/${MARKETS}/agent_plans/%d/`
export const MARKETS_PLAN = `/${MARKETS}/plan/`
export const MARKETS_PLAN_EDIT = `/${MARKETS}/plan/%d/`
export const MARKETS_PLAN_DELETE = `/${MARKETS}/plan/%d/`
export const MARKETS_BALANCE_NOTIFICATION_CREATE = `/${MARKETS}/plan_create/`
export const STATISTICS_FOR_SUPERVISOR = `/${MARKETS}/all_today_tasks/`
export const PLANS_BY_AGENTS_LIST = `/${MARKETS}/supervisor_stats/`
export const MARKETS_PLANS_TYPES = `/${MARKETS}/get_delivery_man/`
export const MARKETS_AGENTS_LOCATION = `/${MARKETS}/way/`
export const MARKETS_AGENTS_PLANS = `/${MARKETS}/agents_plans/`
export const MARKETS_SERVICE_TIME_START = `/${MARKETS}/market/%d/start_service_time/`
export const MARKETS_SERVICE_TIME_END = `/${MARKETS}/market/%d/end_service_time/`
export const STATISTICS_AGENTS_DEBTS = `/${MARKETS}/agent_debts/`

export const MARKET_OPTIONS = 'market/options'
export const MARKET_OPTIONS_CREATE = `/${MARKET_OPTIONS}/`
export const MARKET_OPTIONS_LIST = `/${MARKET_OPTIONS}/`
export const MARKET_OPTIONS_DELETE = `/${MARKET_OPTIONS}/%d/`
export const MARKET_OPTIONS_ITEM = `/${MARKET_OPTIONS}/%d/`
export const MARKET_OPTIONS_UPDATE = `/${MARKET_OPTIONS}/%d/`

// Balance notification
export const MARKETS_BALANCE_NOTIFICATION_LIST = '/notification/balance_notification/'

// Files
export const FILE_UPLOAD = '/main/file/'

// Clients Sales
const SALES = 'sales'
export const MARKETS_SALES_ORDERS_LIST = `/${SALES}/order/`
export const MARKETS_SALES_ORDERS_DETAIL = `/${SALES}/order/%d/`
export const MARKETS_POINTS_LIST = `/${SALES}/client_promotion/%d/market_detail/`
export const MARKETS_CLIENT_BALANCES = `/${MARKETS}/prices/%d/`
export const MARKETS_RETURNS_PRODUCTS_LIST = `/${SALES}/product_sales/`
export const MARKETS_RETURNS_CREATE_PREVIEW = `/${SALES}/order_return/client_return_preview/`
export const MARKETS_RETURNS_CREATE = `/${SALES}/order_return/from_client/`
export const MARKETS_RETURNS_LIST = `/${SALES}/order_return/`
export const MARKETS_RETURNS_ITEM = `/${SALES}/order_return/%d/`
export const MARKETS_ACCEPT_DELIVERED = `/${SALES}/order/delivered/`
export const MARKETS_PRICE_LIST = `/${SALES}/price_list/`
export const MARKETS_CONTRACTS_LIST = `/${SALES}/contract/`
export const DELIVERIES_PLAN = `/${SALES}/delivery_plan/`
export const BONUS_ORDER_COUNT = `/${SALES}/order/%d/bonus_count/`
export const ORDER_ITEM_ADD_BONUS = `/${SALES}/order/%d/add_bonus/`
export const STOCKS_SELECTIONS = `/${SALES}/products_selection/`
export const STOCKS_SELECTIONS_PRODUCTS_LIST = `/${SALES}/products_selection/%d/products/`
export const STOCKS_SELECTIONS_CREATE_END = `/${SALES}/products_selection/%d/end_selection/`
export const MARKETS_ORDERS_CANCEL = `/${SALES}/order/%d/cancel/`

// Clients Main
const MAIN = 'main'
export const MARKETS_PRODUCTS_LIST = `/${MAIN}/product/`
export const MAIN_BOT = `/${MAIN}/bot/`

// Products
export const PRODUCTS_LIST = `/${MAIN}/product/`
export const PRODUCTS_DETAIL = `/${MAIN}/product/%d/`
export const PRODUCTS_TYPES_LIST = `/${MAIN}/product_type/as_menu/`

// Clients stats
const STATS = 'stats'
export const MARKETS_STATISTICS_BY_PRODUCTS = `/${STATS}/products/`
export const MARKETS_STATISTICS_BY_AGENTS = `/${STATS}/markets/`
export const MARKETS_TOTAL_STATISTICS = `/${STATS}/client_balances_expanded/total/`
export const STATISTICS_PAYMENTS_BY_AGENT = `/${STATS}/client_transactions/agent_statistics/`
export const STATISTICS_AGENTS_BY_DIVISIONS = `/${STATS}/agents/division/`

// Finances
const FINANCES = 'finance'
export const FINANCES_CASHIERS_LIST = `/${FINANCES}/cashbox/`
export const FINANCES_TRANSACTIONS_LIST = `/${FINANCES}/transaction/`
export const FINANCES_ACCEPT_CASH_LIST = `/${FINANCES}/accept_cash_list/`
export const FINANCES_CLIENT_TRANSACTION_LIST = '/sales/client_transaction/'
export const FINANCES_CLIENT_TRANSACTION_DETAIL = '/sales/client_transaction/%d/'
export const FINANCES_CLIENT_TRANSACTION_DELETE = '/sales/client_transaction/%d/cancel/'
export const FINANCES_ACCEPT_CLIENT_TRANSACTION = '/finance/accept_client_transaction/'
export const FINANCES_TRANSFER_CASH_CREATE = `/${FINANCES}/transfer/`
export const FINANCES_CLIENT_TRANSACTION_CREATE = '/sales/client_transaction/'
export const FINANCES_CLIENT_TRANSACTION_PREVIEW = '/sales/check_auto_transaction/'
export const PAYMENTS_CONVERT = `/${FINANCES}/convert/`
export const FINANCES_DEBIT_CATEGORY = `/${FINANCES}/income_category/`
export const FINANCES_CREDIT_CATEGORY = `/${FINANCES}/expense_category/`

// RequestFunds
export const REQUEST_FUNDS_LIST = `/${FINANCES}/money_request/`
export const REQUEST_FUNDS_ITEM = `/${FINANCES}/money_request/%d/`
export const REQUEST_FUNDS_CREATE = `/${FINANCES}/money_request/`
export const REQUEST_FUNDS_UPDATE = `/${FINANCES}/money_request/%d/`

// Notification
export const NOTIFICATION = 'notification'
export const NOTIFICATION_LIST = `/${NOTIFICATION}/notifications/`
export const NOTIFICATION_DELETE = `/${NOTIFICATION}/notifications/%d/`
export const FIREBASE_TOKEN = `/${NOTIFICATION}/firebase_key/`
export const NOTIFICATION_GET_NOT_VIEWED = `/${NOTIFICATION}/notifications/get_not_viewed/`

// Cars
export const CARS = 'cars'
export const CARS_LIST = `/${CARS}/car/`
export const CARS_DETAIL = `/${CARS}/car/%d/`
export const CARS_CREATE = `/${CARS}/car/`
export const CARS_BRANDS_LIST = `/${CARS}/car_brand/`
export const CARS_ORDERS_CREATE = `/${CARS}/acceptor_car/`
export const CARS_ORDERS_ADD_PRODUCTS = `/${CARS}/acceptor_car/add_products/`
export const CARS_ORDERS_FINISH_CREATE = `/${CARS}/acceptor_car/finish/`
export const CARS_SERVICES_LIST = `/${MAIN}/service_mobile/`
export const CARS_ORDERS_LIST = `/${CARS}/acceptor_car/`
export const CARS_ORDERS_DETAIL = `/${CARS}/acceptor_car/%d/`
export const CARS_ORDERS_CANCEL = `/${CARS}/acceptor_car/cancel/`
export const CARS_PRODUCTS_ITEM_UPDATE = `/${CARS}/acceptor_car/%d/update_product/`

// GPS Sender and Yandex
export const GPS_SENDER = `/${MARKETS}/location/new/`
export const GET_ADDRESS = `/${MARKETS}/location_name/`

// POS
const POS = 'pos'
export const POS_CHECKS = `/${POS}/order_mobile/`
export const POS_CHECKS_DETAIL = `/${POS}/order_mobile/%d/`
export const POS_DEBTS = `/${POS}/debtor_mobile/`
export const POS_DEBTS_DETAIL = `/${POS}/debtor_mobile/%d/debts_detail/`
export const POS_TOTAL_STATISTICS = `/${POS}/static_total/`
export const POS_STATISTICS_POPULAR_PRODUCTS = `/${POS}/popular_products/`
export const POS_STATISTICS_REVENUE = `/${POS}/revenue/`

// Zones
export const ZONE = 'market/border'
export const ZONE_CREATE = `/${ZONE}/`
export const ZONE_UPDATE = `/${ZONE}/%d/`
export const ZONE_LIST = `/${ZONE}/`
export const ZONE_ITEM = `/${ZONE}/%d/`
export const ZONE_DELETE = `/${ZONE}/%d/`
export const ZONE_STAT = `/${ZONE}/statistics/`
export const ZONE_BIND_AGENT = `${ZONE}/%d/bind_agent/`
export const ZONE_UNBIND_AGENT = `${ZONE}/%d/unbind_agent/`

// Manufacture
const MANUFACTURE = 'manufacture'
export const MANUFACTURE_LIST = `/${MANUFACTURE}/manufacturing/`
export const EQUIPMENT_LIST = `/${MANUFACTURE}/equipment/`
export const MANUFACTURE_BATCHES_LIST = `/${MANUFACTURE}/shipment/`
export const MANUFACTURE_BATCHES_DETAIL = `/${MANUFACTURE}/shipment/%d/`
export const MANUFACTURE_PRODUCTS_LIST = `/${MAIN}/product/`
export const MANUFACTURE_PRODUCTS_DETAIL = `/${MAIN}/product/%d/`
export const MANUFACTURE_BATCHES_ADD_PRODUCTS = `/${MANUFACTURE}/manufacture_return/wb_create/`
export const MANUFACTURE_BATCHES_ADD_MATERIALS = `/${MANUFACTURE}/manufacture_writeoff/wb_create/`
export const MANUFACTURE_BATCHES_FINISH_CREATE = `/${MANUFACTURE}/shipment/%d/close/`
export const MANUFACTURE_MATERIALS_LIST = `/${MANUFACTURE}/manufacture_writeoff/review/`
export const MANUFACTURE_BATCHES_PRODUCTS_LIST = `/${MANUFACTURE}/products/`
export const MANUFACTURE_BATCHES_PRODUCT_EDIT = `/${MANUFACTURE}/manufacture_return/%d/update_amount/`
export const MANUFACTURE_BATCHES_PRODUCT_DELETE = `/${MANUFACTURE}/manufacture_return/%d/`
export const MANUFACTURE_BATCHES_MATERIAL_EDIT = `/${MANUFACTURE}/manufacture_writeoff/%d/update_amount/`
export const MANUFACTURE_BATCHES_MATERIAL_DELETE = `/${MANUFACTURE}/manufacture_writeoff/%d/`
export const MANUFACTURE_SHIPMENT_END = `/${MANUFACTURE}/shipment/%d/done/`
export const MANUFACTURE_BATCHES_EDIT = `/${MANUFACTURE}/shipment/%d/update_amount/`
export const MANUFACTURE_BATCHES_DELETE = `/${MANUFACTURE}/shipment/%d/cancel/`
