import React from 'react'
import styled from 'styled-components'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {
  marketsReportsProductsListSerializer
} from 'containers/Clients/actions/serializers/marketsSerializer'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import mapIndexed from 'helpers/mapIndexed'
import {find, path, prop, propEq, propOr} from 'ramda'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'

const ProductsSearch = styled('div')`
  position: fixed;
  right: 0;
  left: 0;
  top: 60px;
  background: #f8f8f8;
  z-index: 100;
  height: ${({value}) => (value === 1) ? '56px' : '0'};
  opacity: ${({value}) => (value === 1) ? 1 : 0};
  padding: ${({value}) => (value === 1) ? '10px 12px' : '0'};
  transition: all 0s;
  border-bottom: 1px solid #e4e4e6;
`

const MarketsReportsEditStepTwo = props => {
  const {
    value,
    setValue,
    filterActions,
    searchObj,
    marketsProductsList,
    products,
    canIsLimited,
    addProductIdHandler,
    setSelectedProduct,
    setModalOpen,
    totalAmount
  } = props

  // Data
  const marketsProductsListData = propOr([], 'data', marketsProductsList)

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch
      value={value}
    >
      <DebounceSearch filterActions={filterActions} placeholder={'Название'} />
    </ProductsSearch>

  // ProductsList
  const productsList = mapIndexed((item) => {
    const productId = prop('id', item)
    const productName = prop('name', item)
    const image = prop('image', item)
    const imageSrc = prop('file', image)
    const isSelected = find(propEq('product', `${productId}`))(products)
    const selectedAmount = prop('amount', isSelected)

    const measurement = prop('measurement', item)
    const measurementName = path(['measurement', 'name'], item) && path(['measurement', 'name'], item).slice(0, 3)

    const realBalance = prop('balance', item)
    const limitBalance = prop('limitBalance', item)
    const balance = canIsLimited ? limitBalance : realBalance

    const textAmount =
      <TextStatus
        status={'active'}
        bold
      >
        {numberFormat(selectedAmount, measurementName)}
      </TextStatus>

    const handleAddProductsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({
        name: productName,
        amount: balance,
        measurement,
        image
      })
      addProductIdHandler(productId)
    }

    return (
      <ProductsItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        onClick={() => handleAddProductsOpen()}
        isSelected={isSelected}
        amount={selectedAmount && textAmount}
      />
    )
  }, marketsProductsListData)

  // MainContent
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_PRODUCTS_LIST}
        actionType={actionTypes.MARKETS_PRODUCTS_LIST}
        emptyData={<><NoResults /></>}
        params={marketsReportsProductsListSerializer(searchObj, props)}
        style={{padding: '56px 0 50px'}}
        list={productsList || []}
        useWindow
      />
      <BottomBar>
        <Button
          type={'button'}
          text={`Добавить: ${numberFormat(totalAmount, 'ед')}`}
          onClick={() => setValue(0)}
          fullWidth
        />
      </BottomBar>
    </>

  // Render
  return (
    <>
      {debounceSearch}
      {mainContent}
    </>
  )
}

export default MarketsReportsEditStepTwo
