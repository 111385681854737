import {compose, withState} from 'recompose'
import MarketsReportsCreateGrid from 'containers/Clients/Grid/ContractsCreate/Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  marketsItemAction,
  marketsContractsCreateAction
} from 'containers/Clients/actions/marketsAction'

export default compose(
  withFetchList({
    stateName: [STATES.MARKETS_ITEM],
    action: marketsItemAction,
    key: [STATES.MARKETS_ITEM]
  }),
  withCreate({
    stateName: [STATES.MARKETS_CONTRACTS_CREATE],
    action: marketsContractsCreateAction,
    key: [STATES.MARKETS_CONTRACTS_CREATE],
    redirectUrl: ROUTES.MARKETS_CONTRACTS_URL,
    propFromParams: 'id',
    withCurrentParams: true,
    successMessage: 'Договор успешно создан'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_CONTRACTS_URL,
    withCurrentParams: true
  }),
  withState('confirmModalOpen', 'setConfirmModalOpen', false)
)(MarketsReportsCreateGrid)
