import React from 'react'
import styled from 'styled-components'
import TileItem from 'components/Grid/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'
import numberFormat from 'helpers/numberFormat'
import PropTypes from 'prop-types'
import {filter, isEmpty, prop} from 'ramda'
import {storageData} from 'helpers/storage'
import mapIndexed from 'helpers/mapIndexed'
import {TextStatus} from 'components/Status/TextStatus'
import {Navigation} from 'react-feather'

const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: calc(100% - 60px);
  flex-grow: 1;
`
const Title = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 26px;
  h3{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  h5{
    max-width: 85px;
    max-height: 26px;
    text-align: center;
    background: ${({theme}) => theme.palette.blue};
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.016em;
    border-radius: 34px;
    padding: 4px 23px 4px 9px;
    font-weight: 400;
    position: relative;
    svg{
      color: #cbcef6;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      fill: #cbcef6;
    }
  }
`
const Address = styled('p')`
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.text.secondary};
  line-height: 20px;
  ${({styles}) => styles};
`
const LastVisit = styled(Address)``
const BalancesWrap = styled('span')`
  margin-top: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.0241176em;
  color: #000;
`

const MarketsItem = props => {
  const {
    title,
    address,
    status,
    imgSrc,
    onClick,
    height,
    balances,
    lastVisit,
    mainOnClick,
    navClick,
    distance
  } = props

  const isVisibleBalances = storageData('isVisibleBalances').getValue() || false
  const isVisibleLastVisit = storageData('isVisibleLastVisit').getValue() || false
  const stylesWithBalances = {
    marginTop: '-3px'
  }

  // Distance
  const formatedDist = Math.round(distance * 10) / 10000
  const distanceText = formatedDist < 100 ? numberFormat(formatedDist, 'км') : <i style={{fontSize: '13px'}}>Далеко</i>

  const withBalances = balances && isVisibleBalances
  const withLastVisit = isVisibleLastVisit
  const withBalancesAndLV = withBalances && withLastVisit

  const filteredBalances = balances && filter((item) => +prop('amountTotal', item) !== 0, balances)
  return (
    <TileItem
      onClick={onClick}
      height={height || withBalancesAndLV ? 'auto' : withBalances ? 'auto' : withLastVisit ? '90px' : undefined}
    >
      <PhotoWithStatus
        onClick={mainOnClick}
        status={status}
        imgSrc={imgSrc}
        type={'market'}
        styles={{marginTop: '3px'}}
      />
      <RightBar>
        <Title>
          <h3 onClick={mainOnClick}>{title}</h3>
          {(distance || distance === 0) ? <h5 onClick={navClick}>{distanceText}<Navigation size={'12px'} /></h5> : <h5 onClick={navClick}> GPS <Navigation size={'12px'} /></h5>}
        </Title>
        <Address styles={(isVisibleBalances && !isEmpty(filteredBalances)) && stylesWithBalances} onClick={mainOnClick}>{address || 'Адрес не указан'}</Address>
        {withLastVisit &&
        <LastVisit onClick={mainOnClick}>{lastVisit ? numberFormat(lastVisit, 'дней назад') : 'Нет посещений'}</LastVisit>}
        {(withBalances && !isEmpty(filteredBalances)) &&
        <BalancesWrap onClick={mainOnClick}>
          {balances && mapIndexed((item) => {
            const id = prop('id', item)
            const name = prop('name', item)
            const amountTotal = prop('amountTotal', item)

            if (+amountTotal !== 0) {
              return (
                <TextStatus status={amountTotal < 0 ? 'inactive' : null} key={id}>
                  {numberFormat(amountTotal, name)}
                </TextStatus>
              )
            } else {
              return false
            }
          }, balances)}
        </BalancesWrap>}
      </RightBar>
    </TileItem>
  )
}

MarketsItem.propTypes = {
  title: PropTypes.any,
  address: PropTypes.any,
  lastVisit: PropTypes.any,
  status: PropTypes.string,
  imgSrc: PropTypes.string,
  balances: PropTypes.array,
  onClick: PropTypes.func,
  distance: PropTypes.any,
  height: PropTypes.string
}

export default MarketsItem
