import React from 'react'
import styled from 'styled-components'
import {prop, path} from 'ramda'
import Form from 'components/Forms/Form'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import * as API from 'constants/api'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import normalizeNumber from '../../../../../helpers/normalizers/normalizeNumber'
import UniversalSearchField from '../../../../../components/Forms/SearchField/UniversalSearchField'
import toNumber from '../../../../../helpers/toNumber'
import numberWithoutSpaces from '../../../../../helpers/numberWithoutSpaces'
import {round} from '../../../../../helpers/round'
import Grid from '@material-ui/core/Grid'
import numberFormat from '../../../../../helpers/numberFormat'
import toBoolean from '../../../../../helpers/toBoolean'
import BottomBar from '../../../../../components/Navigation/BottomBar'
import Button from '../../../../../components/Forms/Button'
import {transferCashCreateSerializer} from '../../../actions/serializers/financesSerializer'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const GrigWithRight = styled(Grid)`
  margin-right: 10px !important;
  margin-top: 10px !important;
`

const Text = styled('div')`
    color: ${({theme}) => theme.palette.black} !important;
`

const FinancesTransferCashCreateGrid = props => {
  const {
    goBack,
    config,
    financesTransferCashCreate
  } = props
  // data

  // values select data
  const [parentCashbox, setParentCashbox] = React.useState(0)
  const [childCashbox, setChildCashbox] = React.useState(0)
  // values form
  const [values, getValues] = React.useState({})
  const amountFrom = prop('amountFrom', values) || 0
  const amountToPersent = prop('amountTo', values) || 0
  const rate = prop('rate', values) || 0
  const amountFromPersent = prop('amountFromPersent', values) || 0

  const cashboxId = prop('id', parentCashbox)
  const cashboxChildrenId = prop('id', childCashbox)
  const currencyCashbox = path(['currency', 'name'], parentCashbox)
  const currencyCildrenCashbox = path(['currency', 'name'], childCashbox)
  const sameCurrencyDiffType = path(['currency', 'name'], parentCashbox) === path(['currency', 'name'], childCashbox) && (prop('type', parentCashbox) !== prop('type', childCashbox))
  const diffCurrency = path(['currency', 'name'], parentCashbox) !== path(['currency', 'name'], childCashbox)
  const primaryCurrency = prop('PRIMARY_CURRENCY', config)
  const rateOutput = currencyCashbox === currencyCildrenCashbox ? 1 : rate
  const convertedAmount = primaryCurrency === currencyCashbox
    ? toNumber(numberWithoutSpaces(amountFrom)) * toNumber(numberWithoutSpaces(rateOutput))
    : toNumber(numberWithoutSpaces(amountFrom)) / toNumber(numberWithoutSpaces(diffCurrency ? rateOutput : 1))
  const customRatePersent = toNumber(numberWithoutSpaces(amountFromPersent)) * toNumber(numberWithoutSpaces(amountToPersent)) / 100
  const amountTo = round(convertedAmount, 4)
  const onSubmit = (values) => financesTransferCashCreate.onSubmit(
    transferCashCreateSerializer(values, sameCurrencyDiffType && amountToPersent, sameCurrencyDiffType && amountToPersent ? customRatePersent : amountTo)
  )

  return (
    <>
      <CreateBar
        title={'Перевод'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          <Form
            getValues={getValues}
            onSubmit={onSubmit}
          >
            <StyledWrap>
              <Field
                component={UniversalSearchField}
                api={API.FINANCES_CASHIERS_LIST}
                params={{currency: 5, type: 'cash', page_size: '1000'}}
                onChange={(value, data) => setParentCashbox(data)}
                name={'fromCashbox'}
                label={'Кассы'}
                required
                fullWidth
              />
              <Field
                component={UniversalSearchField}
                api={API.DIVISION_LIST}
                params={{page_size: '1000'}}
                name={'fromDivision'}
                label={'С организации'}
                required
                fullWidth
              />
              {cashboxId &&
              <Field
                component={UniversalSearchField}
                api={API.FINANCES_CASHIERS_LIST}
                params={{page_size: '1000'}}
                parent={{exclude_id: cashboxId}}
                onChange={(value, data) => setChildCashbox(data)}
                name={'toCashbox'}
                label={'Касса получатель'}
                required
                fullWidth
              />}
              {cashboxId &&
              <Field
                component={UniversalSearchField}
                api={API.DIVISION_LIST}
                params={{page_size: '1000'}}
                name={'toDivision'}
                label={'На организацию'}
                required
                fullWidth
              />}
              {!sameCurrencyDiffType && cashboxId &&
              <div>
                <div>
                  <Grid container direction="row" alignItems="center" justify="space-between">
                    {cashboxId &&
                    <GrigWithRight item xs={8}>
                      <Field
                        component={TextField}
                        type={'text'}
                        name={'amountFrom'}
                        parse={normalizeNumber}
                        label={'Сумма с кассы'}
                        required
                      />
                    </GrigWithRight>}
                    <Grid item xs={3}>
                      <Text style={{marginTop: '25px'}}>{currencyCashbox}</Text>
                    </Grid>
                  </Grid>
                  {cashboxChildrenId && diffCurrency &&
                  <div>
                    <Field
                      name="rate"
                      component={TextField}
                      label={'Курс'}
                      parse={normalizeNumber}
                      fullWidth
                    />
                  </div>}
                </div>
                {(amountFrom !== 0) && (rate || !diffCurrency) &&
                <div style={{padding: '10px 0'}}>
                  Сумма перевода: <strong>{numberFormat(amountTo, currencyCildrenCashbox, 5)}</strong>
                </div>}
              </div>}
              {sameCurrencyDiffType &&
              <div>
                <div>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    {cashboxId &&
                    <GrigWithRight item xs={8}>
                      <Field
                        name="amountFromPersent"
                        component={TextField}
                        label={'Сумма с кассы'}
                        parse={normalizeNumber}
                        fullWidth
                      />
                    </GrigWithRight>}
                    <Grid item xs={3}>
                      <Text style={{marginLeft: '10px'}}>{currencyCashbox}</Text>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <GrigWithRight item xs={8}>
                      <Field
                        name="amountTo"
                        component={TextField}
                        label={'Процент'}
                        parse={normalizeNumber}
                        fullWidth
                      />
                    </GrigWithRight>
                    <Grid item xs={3}>
                      <Text>%</Text>
                    </Grid>
                  </Grid>
                </div>
                {(toBoolean(amountFromPersent) && toBoolean(amountToPersent)) &&
                <div style={{padding: '10px 0'}}>
                  Касса <i>{currencyCildrenCashbox}</i> получает: <strong>{numberFormat(customRatePersent, currencyCildrenCashbox)} </strong>
                </div>}
              </div>}
              <Field
                component={TextField}
                name={'date'}
                type={'date'}
                label={'Дата создания'}
                required
                fullWidth
              />
              <Field
                component={TextField}
                name={'time'}
                type={'time'}
                label={'Время'}
                required
                fullWidth
              />
              <Field
                component={TextField}
                type={'text'}
                name={'comment'}
                label={'Коментарий'}
                multiline
                fullWidth
              />
              <BottomBar>
                <Button
                  type={'submit'}
                  text={'Отправить'}
                  fullWidth
                />
              </BottomBar>
            </StyledWrap>
          </Form>
        </Wrap>
      </Container>
    </>
  )
}

export default FinancesTransferCashCreateGrid
