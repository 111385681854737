import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import moment from 'moment'
import {TextStatus} from 'components/Status/TextStatus'
import {Check as CheckIcon, ChevronRight as ArrowIcon, X as XIcon} from 'react-feather'
import PropTypes from 'prop-types'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)``
const Delivery = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const RightBar = styled(Bar)`
  text-align: right;
`
const Price = styled('h4')`
  color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : theme.palette.black
};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const deliveryStyles = {
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: '2px',
    strokeWidth: '3'
  }
}
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`
const DeliveryStatus = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  svg{
    margin-right: 3px;
  }
  i{
    margin-bottom: 3px;
  }
`

const OrdersItem = props => {
  const {
    onClick,
    orderNumber,
    totalPrice,
    currency,
    paymentStatus,
    date,
    deliveryStatus,
    statusText,
    deliveryMan
  } = props

  return (
    <TileItem
      onClick={onClick}
      styles={styles}
    >
      <LeftBar>
        <h3>№ {orderNumber || 'не определён'}</h3>
        <Delivery>
          <TextStatus
            status={deliveryStatus}
            styles={deliveryStyles}
          >
            {(deliveryStatus === 'active')
              ? <DeliveryStatus><CheckIcon size={15} color={'#38d06c'} /><i>доставлен</i></DeliveryStatus>
              : (deliveryStatus === 'inactive')
                ? <DeliveryStatus><XIcon size={15} color={'#e66767'} /><i>отменён</i></DeliveryStatus>
                : ''}
          </TextStatus>
          {(deliveryStatus !== 'inactive' && deliveryStatus !== 'active') &&
          <span>
            {date
              ? moment(date).format('DD MMM YYYY HH:mm')
              : 'не определена'}
          </span>}
        </Delivery>
      </LeftBar>
      <RightBar>
        <Price
          status={paymentStatus}
        >{numberFormat(totalPrice, currency)}
        </Price>
        {statusText &&
        <span>{statusText}</span>}
        {deliveryMan &&
        <span>{deliveryMan}</span>}
      </RightBar>
      <Arrow />
    </TileItem>
  )
}

OrdersItem.propTypes = {
  onClick: PropTypes.func,
  orderNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  totalPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  currency: PropTypes.string,
  date: PropTypes.string,
  deliveryStatus: PropTypes.string,
  paymentStatus: PropTypes.string
}

export default OrdersItem
