import React from 'react'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import {Modal} from 'components/Modal'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import moment from 'moment'
import {path, prop, propOr} from 'ramda'
import Perms from '../../../../../components/Perms/Perms'
import UploadImages from 'components/Forms/UploadField/UploadImages'
import {getLocation} from 'helpers/location'
import {useSelector} from 'react-redux'

const StyledWrap = styled('div')`
  ${({styles}) => styles};
  & > *:not(:last-child){
    margin-bottom: 15px;
  }
`
const today = moment().format('YYYY-MM-DD')
const ConfirmDeliveredModal = (props) => {
  const {
    dataModal,
    orderId
  } = props

  const coords = getLocation()

  const config = useSelector(state => prop('config', state))
  const configData = prop('data', config)
  const addPhotoOnDelivery = prop('aDDPHOTOONDELIVERY', configData)

  // for modal const
  const handleSubmit = values => {
    const imagesArr = propOr([], 'deliveryPhoto', values)
    const deliveryPhoto = path(['0', 'image', 'fileId'], imagesArr)

    const deliveryLocation = {
      lat: path(['location', 'latitude'], coords),
      lon: path(['location', 'longitude'], coords)
    }

    const dataWithLocation = {
      orders: [+orderId],
      date: moment(prop('date', values)).toDate(),
      deliveryPhoto,
      deliveryLocation
    }

    const data = {
      orders: [+orderId],
      date: moment(prop('date', values)).toDate()
    }

    dataModal.onSubmit(addPhotoOnDelivery ? dataWithLocation : data)
  }

  return (
    <Modal
      open={dataModal.open}
      isOpen={dataModal.handle}
      title={`Заказ № ${orderId}`}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={dataModal.initialValues}
      >
        <StyledWrap>
          {addPhotoOnDelivery &&
          <Field
            component={UploadImages}
            name={'deliveryPhoto'}
            label={'Фото клиента с товаром'}
            single
            required
            fullWidth
          />}
          <Perms perms={['can_set_order_delivery_date']}>
            <Field
              component={TextField}
              type={'date'}
              name={'date'}
              label={'Дата'}
              defaultValue={today}
              fullWidth
            />
          </Perms>
          <Button
            disabled={dataModal.loading}
            text={'Применить'}
            fullWidth
          />
        </StyledWrap>
      </Form>
    </Modal>
  )
}

export default ConfirmDeliveredModal
