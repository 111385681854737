import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {prop} from 'ramda'
import Form from 'components/Forms/Form'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Loader from '../../../../../components/Loader'
import SearchIsShop from '../Components/SearchIsShop'
import FormFields from '../Components/FormFields'
import {getAddressYandexAction} from '../../../../../actions/gpsAction'

const LoadWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

const MarketsCreateGrid = props => {
  const {
    goBack,
    gpsAddress,
    query,
    dispatch,
    searchIsShop,
    filterActions,
    marketsCreate,
    optionsData,
    updateDialog
  } = props

  // Use States
  const [mapLocations, setMapLocations] = React.useState(null)
  const [invalid, setInvalid] = React.useState(null)

  // UseEffects
  useEffect(() => {
    let startPos
    const geoOptions = {
      enableHighAccuracy: true
    }
    const geoSuccess = (position) => {
      startPos = position
      const accuracy = startPos.coords.accuracy
      const latitude = startPos.coords.latitude
      const longitude = startPos.coords.longitude
      setMapLocations({latitude, longitude, accuracy})
    }
    const geoError = (position) => {
      setInvalid(position)
    }
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
  }, [])

  useEffect(() => {
    const lat = prop('latitude', mapLocations)
    const lon = prop('longitude', mapLocations)
    mapLocations && dispatch(getAddressYandexAction(lat, lon))
  }, [mapLocations, dispatch])

  const latFromApi = prop('latitude', mapLocations) || null
  const lonFromApi = prop('longitude', mapLocations) || null

  // Submit
  const handleMainSubmit = props => {
    updateDialog.open ? updateDialog.onSubmit({open: updateDialog.open, ...props}) : marketsCreate.onSubmit(props)
  }
  const disabled = prop('loading', marketsCreate)

  const [create, setCreate] = useState(true)
  if (!updateDialog.open && create) {
    return (
      <SearchIsShop
        query={query}
        filterActions={filterActions}
        searchIsShop={searchIsShop}
        setCreate={setCreate}
        mapLocations={mapLocations}
        invalid={invalid}
      />
    )
  }
  return (
    <>
      <CreateBar
        title={updateDialog.open ? `Изменение ${updateDialog.name || ''}` : 'Создание клиента'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {
          updateDialog.loading
            ? (
              <LoadWrap>
                <Loader size={2} />
              </LoadWrap>
            )
            : (
              <Form
                haveMutators
                keepDirtyOnReinitialize
                onSubmit={handleMainSubmit}
                initialValues={updateDialog.open ? updateDialog.initialValues
                  : {
                    telephones: [{}],
                    name: filterActions.initialValues.search,
                    location: {lat: latFromApi, lon: lonFromApi}
                  }}
              >
                <FormFields
                  mapLocations={mapLocations}
                  optionsData={optionsData}
                  gpsAddress={gpsAddress}
                  disabled={disabled}
                  dispatch={dispatch}
                  updateDialog={updateDialog}
                />
              </Form>
            )
        }
      </Container>
    </>
  )
}

export default MarketsCreateGrid
