import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 512 512'} {...props}>
      <g>
        <g>
          <circle cx="416" cy="318.853" r="10.667" />
        </g>
      </g>
      <g>
        <g>
          <path d="M416,265.541c-29.419,0-53.333,23.915-53.333,53.333c0,29.397,23.915,53.333,53.333,53.333h96V265.541H416z M416,350.874c-17.643,0-32-14.357-32-32c0-17.643,14.357-32,32-32c17.643,0,32,14.357,32,32C448,336.517,433.643,350.874,416,350.874z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M416,244.207h96v-32c0-41.173-33.493-74.667-74.667-74.667H74.667C33.493,137.541,0,171.034,0,212.207v213.333c0,41.173,33.493,74.667,74.667,74.667h362.667c41.173,0,74.667-33.493,74.667-74.667v-32h-96c-41.173,0-74.667-33.493-74.667-74.667S374.827,244.207,416,244.207z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M427.52,78.298l19.435,38.869c20.288,2.048,38.592,10.283,53.184,22.997C485.803,110.533,459.605,87.663,427.52,78.298z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M117.333,73.541c-46.613,0-86.869,27.413-105.771,66.901c14.549-12.821,32.875-21.12,53.184-23.232l81.109-43.669H117.333z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M346.816,15.301c-19.413-6.123-39.979-4.181-57.941,5.44l-177.28,95.467h148.693l125.611-71.296C376.533,30.959,363.115,20.463,346.816,15.301z" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="396.288,63.557 303.531,116.207 422.592,116.207" />
        </g>
      </g>
    </SvgIcon>

  )
}
