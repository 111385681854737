import {compose} from 'recompose'
import {withFilter, withRedirect} from 'hocs'
import {prop} from 'ramda'
import {connect} from 'react-redux'
import * as ROUTES from 'constants/routes'
import PlansGrid from 'containers/Plans/Grid'

export default compose(
  connect((state) => {
    const plansList = prop('statisticsForSupervisor', state)
    const customStore = prop('customStore', state)
    const config = prop('config', state)
    return {
      plansList,
      customStore,
      config
    }
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.PLANS_DETAIL_URL,
    withCurrentParams: false
  }),
  withFilter({
    fields: ['search', 'date', 'isVisiblePlanDetail']
  })
)(PlansGrid)
