import React from 'react'
import styled from 'styled-components'
import TileItem from 'components/Grid/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'

const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`
const Address = styled('p')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.text.secondary};
`

const CarsItem = props => {
  const {
    carBrand,
    number,
    status = 'none',
    imgSrc,
    onClick
  } = props

  return (
    <TileItem onClick={onClick}>
      <PhotoWithStatus
        status={status}
        imgSrc={imgSrc}
        type={'car'}
      />
      <RightBar>
        <h3>{number}</h3>
        <Address>{carBrand}</Address>
      </RightBar>
    </TileItem>
  )
}

export default CarsItem
