import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Container from '../Container/Container'
import Form from '../Forms/Form'
import Button from '../Forms/Button'
import Loader from '../Loader'
import CreateBar from '../Navigation/TopBar/CreateBar'
import BottomBar from '../Navigation/BottomBar'

const LoadWrap = styled('li')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

const ContainerStyled = styled(Container)`
  padding: calc(60px + 0px) 0 0;
  min-height: 100vh;
  overflow-y: auto;
  background: #fff !important;
`

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const DialogStyled = styled(Dialog)`
  background: ${({theme}) => theme.background.primary};
  & .MuiPaper-root, .MuiBackdrop-root {
    background: transparent !important;
  }
`

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalFullScreen = (props) => {
  const {
    handleDialog,
    open,
    topTitle,
    title,
    children,
    loading,
    handleSubmit,
    haveMutators,
    initialValues,
    getValues
  } = props
  return (
    <DialogStyled fullScreen open={open} onClose={handleDialog} TransitionComponent={Transition}>
      <CreateBar
        title={topTitle}
        goExit={() => handleDialog(false)}
      />
      <ContainerStyled>
        <Form
          onSubmit={handleSubmit}
          getValues={getValues}
          initialValues={initialValues}
          haveMutators={haveMutators}
        >
          <>
            <Wrap>
              {
                loading
                  ? <LoadWrap><Loader size={2} /></LoadWrap>
                  : <div title={title}>{children}</div>
              }
            </Wrap>
            <BottomBar>
              <Button
                text={'Применить'}
                type={'submit'}
                fullWidth
              />
            </BottomBar>
          </>
        </Form>
      </ContainerStyled>
    </DialogStyled>
  )
}

ModalFullScreen.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired
}

export default ModalFullScreen
