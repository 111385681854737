import React, {useMemo, useState} from 'react'
import Container from 'components/Container'
import {groupBy, isEmpty, path, pipe, prop, propOr, toPairs} from 'ramda'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import CardDescription from 'components/Cards/CardDescription'
import moment from 'moment'
import NoResults from 'components/NoData/NoResults'
import {DetailBar} from 'components/Navigation/TopBar'
import TileItem from 'components/Grid/Tile/TileItem'
import TilesList from 'components/Cards/Tile/TilesList'
import mapIndexed from 'helpers/mapIndexed'
import ImageViewer from 'components/ImageViewer'
import {parseParams} from 'helpers/url'
import Loader from 'components/Loader'
import {Table} from 'components/Table'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import sumBy from 'helpers/sumBy'
import {MoreHorizontal} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import ModalConfirm from 'components/Modal/ModalConfirm'

const Wrap = styled('div')`
  .react-swipeable-view-container{
    max-height: calc(100vh - 60px);
  }
`
const Inner = styled('div')`
  padding: 0 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const TabsBar = styled('div')`
  position: fixed;
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e4e4e6;
`

const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`

const Content = styled('div')`
  height: calc(100vh - 60px);
  padding-top: 55px;
  padding-bottom: 14px;
  overflow-x: hidden;
  overflow-y: scroll;
`

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}
const GalleryWrap = styled('div')`
  padding: 0 16px;
  display: flex;
  flex-flow: column nowrap;
`
const ImgWrap = styled('div')`
  background: ${({theme}) => theme.skeleton.primary};
  min-height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img{
    width: 100%;
  }
  &:not(:last-child){
    margin-bottom: 16px;
  }
`

const MarketsOrdersDetailGrid = (props) => {
  const {
    marketsReportsDetail,
    marketsReportsDelete,
    handleSetSelectedImage,
    match,
    goBack,
    goEdit,
    location
  } = props

  // useState
  const [openViewer, setOpenViewer] = useState(false)
  const [value, setValue] = useState(0)
  const [isOpenSecondMenu, setIsOpenSecondMenu] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  // Data
  const marketsReportsDetailData = useMemo(() => prop('data', marketsReportsDetail), [marketsReportsDetail])

  // Redirects
  const reportId = path(['params', 'reportId'], match)
  const marketId = path(['params', 'id'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Loading
  const marketsReportsDetailLoading = prop('loading', marketsReportsDetail)

  // Tabs configs
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }

  // Content const
  const marketName = path(['market', 'name'], marketsReportsDetailData)
  const userFN = path(['user', 'firstName'], marketsReportsDetailData)
  const userSN = path(['user', 'secondName'], marketsReportsDetailData)
  const user = userFN + ' ' + userSN
  const createDate = prop('createDate', marketsReportsDetailData)
  const comment = prop('comment', marketsReportsDetailData)
  const products = useMemo(() => propOr([], 'products', marketsReportsDetailData), [marketsReportsDetailData])
  const sortedBy = pipe(
    groupBy(path(['product', 'id'])),
    toPairs
  )(products)
  const productsListAmount = prop('length', sortedBy)
  const sortedProducts = pipe(
    groupBy(path(['product', 'measurement', 'name'])),
    toPairs
  )(products)
  const totalAllAmount = sumBy(products, ['amount'])

  const afterImgArr = prop('afterImg', marketsReportsDetailData)
  const afterImg = afterImgArr && mapIndexed((item, index) => {
    const imgSrc = prop('file', item)
    const fileId = prop('fileId', item)

    return (
      <ImgWrap
        key={index}
        onClick={() => {
          handleSetSelectedImage(fileId)
          setTimeout(() => {
            setOpenViewer(true)
          }, 0)
        }}
      >
        <img src={imgSrc} alt={'Ритм.Доставка'} />
      </ImgWrap>
    )
  }, afterImgArr)
  const beforeImgArr = prop('beforeImg', marketsReportsDetailData)
  const beforeImg = beforeImgArr && mapIndexed((item, index) => {
    const imgSrc = prop('file', item)
    const fileId = prop('fileId', item)

    return (
      <ImgWrap
        key={index}
        onClick={() => {
          handleSetSelectedImage(fileId)
          setTimeout(() => {
            setOpenViewer(true)
          }, 0)
        }}
      >
        <img src={imgSrc} alt={'Ритм.Доставка'} />
      </ImgWrap>
    )
  }, beforeImgArr)

  // Tab Content
  // Content One
  const tabContentOne =
    <GalleryWrap>
      {!isEmpty(afterImgArr) ? afterImg : <NoResults text={'Нет фото'} />}
    </GalleryWrap>

  // Content Two
  const tabContentTwo =
    <GalleryWrap>
      {!isEmpty(beforeImgArr) ? beforeImg : <NoResults text={'Нет фото'} />}
    </GalleryWrap>

  // Content Three
  const tabContentThree =
    <Inner>
      <TilesList title={'Информация по фотоотчёту'}>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Клиент'} is={marketName} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Агент'} is={user} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата'} is={moment(createDate).format('DD MMM YYYY HH:mm')} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Комментарий'} is={comment} />
        </TileItem>
      </TilesList>
      <TilesList
        title={'Итого'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во видов товаров'} is={numberFormat(productsListAmount, 'ед')} />
        </TileItem>
        {sortedProducts && mapIndexed((item, index) => {
          const sortName = prop('0', item)
          const arr = prop('1', item)
          const amountSum = sumBy(arr, ['amount'])

          return (
            <TileItem
              height={'auto'}
              key={index}
            >
              <CardDescription it={'Кол-во товаров'} is={numberFormat(amountSum, sortName.slice(0, 3))} />
            </TileItem>
          )
        }, sortedProducts)}
      </TilesList>
      <TilesList title={'Список товаров'}>
        <TileItem
          height={'auto'}
          styles={{'& > div': {padding: '2px 0'}}}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
              </tr>
              {mapIndexed((item, index) => {
                const productName = path(['product', 'name'], item)
                const amount = prop('amount', item)
                const measurement = (path(['product', 'measurement', 'name'], item)).slice(0, 3)

                return (
                  <tr key={index}>
                    <td colSpan={'2'}>{productName}</td>
                    <td>{numberFormat(amount, measurement)}</td>
                  </tr>
                )
              }, products)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(totalAllAmount, 'ед')}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
    </Inner>

  // Loader
  const loader = <Loader styles={{marginTop: '20px'}} />

  const mainContent =
    <>
      <TabsBar>
        <StyledTabs
          onChange={handleChange}
          value={value}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'До'} {...a11yProps(0)} />
          <Tab label={'После'} {...a11yProps(1)} />
          <Tab label={'Описание'} {...a11yProps(2)} />
        </StyledTabs>
      </TabsBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Content value={value} index={0}>
          {marketsReportsDetailLoading ? loader : marketsReportsDetailData ? tabContentTwo : <NoResults />}
        </Content>
        <Content value={value} index={1}>
          {marketsReportsDetailLoading ? loader : marketsReportsDetailData ? tabContentOne : <NoResults />}
        </Content>
        <Content value={value} index={2}>
          {marketsReportsDetailLoading ? loader : marketsReportsDetailData ? tabContentThree : <NoResults />}
        </Content>
      </SwipeableViews>
    </>

  // ImageViewer
  const imageViewer =
    <ImageViewer
      open={openViewer}
      setOpen={setOpenViewer}
      searchObj={searchObj}
    />

  // NavButtons
  const navButtons = [
    {
      icon: <MoreHorizontal />,
      onClick: () => setIsOpenSecondMenu(true)
    }
  ]

  // MenusArr
  const menusArr = [
    {
      title: 'Редактировать',
      onClick: () => goEdit(marketId, reportId),
      perms: ['change_merchreport']
    },
    {
      title: 'Удалить',
      onClick: () => setOpenDelete(true),
      perms: ['delete_merchreport']
    }
  ]

  // SecondMenu
  const secondMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с заказом'}
      menusArr={menusArr}
      isOpen={isOpenSecondMenu}
      setIsOpen={setIsOpenSecondMenu}
    />

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={openDelete}
        isOpen={setOpenDelete}
        type={'delete'}
        onClick={() => marketsReportsDelete.onSubmit({id: reportId})}
      />
    </>

  // Render
  return (
    <>
      <DetailBar
        title={'Фотоотчёт № ' + reportId}
        goBack={() => goBack(marketId)}
        buttons={navButtons}
      />
      <Container>
        <Wrap>
          {mainContent}
        </Wrap>
      </Container>
      {imageViewer}
      {secondMenu}
      {modals}
    </>
  )
}

export default MarketsOrdersDetailGrid
