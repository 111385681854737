import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledCardItem = styled('li')`
  color: #84909f;
  font-size: 14px;
  &:not(:last-child){
    margin-bottom: 18px;
  }
  i{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    color: ${({theme}) => theme.text.primary};
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
`
const Card = styled('div')`
  border: 1px solid rgba(162, 173, 186, 0.33);
  background: #fff;
  box-shadow: ${({theme}) => theme.palette.boxShadow};
  border-radius: 5px;
  padding: 15px 12px;
  overflow: hidden;
  h2 {
    margin: 0;
  }
  & > :not(:last-child){
    margin-bottom: 15px;
  }
  & > a > *:not(:last-child){
    margin-bottom: 15px;
  }
`

const CardItem = props => {
  const {title, children, onClick, bottom} = props

  return (
    <StyledCardItem onClick={onClick}>
      {title && <i>{title}</i>}
      <Card bottom={bottom}>
        {children}
      </Card>
    </StyledCardItem>
  )
}

CardItem.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any
}

export default CardItem
