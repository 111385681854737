import React from 'react'
import {path, prop} from 'ramda'
import NoResults from 'components/NoData/NoResults'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {parseParams} from 'helpers/url'
import {marketsContractsSerializer} from 'containers/Clients/actions/serializers/marketsSerializer'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import mapIndexed from 'helpers/mapIndexed'
import CardDescription from 'components/Cards/CardDescription'
import ContractsItem from 'containers/Clients/components/ContractsItem/ContractsItem'
import numberFormat from 'helpers/numberFormat'
import styled from 'styled-components'
import Button from 'components/Forms/Button'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import Perms from 'components/Perms/Perms'

const ButtonWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 7px 0 0;
`

const MarketsContractsGrid = (props) => {
  const {
    goBack,
    goPaymentsCreate,
    goContractsCreate,
    location,
    marketsContractsList,
    handleSetContractsId
  } = props

  // Data
  const marketsContractsListData = prop('data', marketsContractsList)

  // Redirects
  const searchHistory = prop('search', location)
  const searchObj = parseParams(searchHistory)
  const marketId = path(['match', 'params', 'id'], props)

  // Main list
  const mainList = marketsContractsListData && mapIndexed((item) => {
    const id = prop('id', item)
    const contractNumber = prop('contractNumber', item)
    const currency = path(['currency', 'name'], item)
    const sum = prop('sum', item)
    const createdDate = prop('createdDate', item)
    const name = contractNumber ? '№ ' + contractNumber : 'Договор'
    const status = prop('status', item)
    const agentFirstName = path(['agent', 'firstName'], item)
    const agentSecondName = path(['agent', 'secondName'], item)
    const agentName = agentFirstName + ' ' + agentSecondName
    const limit = prop('limit', item)
    const comment = prop('comment', item)

    return (
      <ContractsItem
        key={id}
        setQuery={handleSetContractsId}
        id={id}
        searchObj={searchObj}
        date={createdDate}
        amount={sum}
        currency={currency}
        name={name}
        status={status}
      >
        <CardDescription it={'Агент'} is={agentName} />
        <CardDescription it={'Лимит'} is={numberFormat(limit, currency)} />
        <CardDescription it={'Юр. лицо'} is={comment} />
        <ButtonWrap>
          <Perms perms={['market_orders_payment']} onClick={() => goPaymentsCreate(marketId)}>
            <Button
              text={'Оплатить'}
              styles={{
                height: '40px'
              }}
              fullWidth
            />
          </Perms>
        </ButtonWrap>
      </ContractsItem>
    )
  }, marketsContractsListData)

  // Content
  const mainContent =
    <InfiniteScroll
      api={API.MARKETS_CONTRACTS_LIST}
      actionType={actionTypes.MARKETS_CONTRACTS_LIST}
      emptyData={<li><NoResults /></li>}
      params={marketsContractsSerializer(searchObj, props)}
      list={mainList || []}
    />

  // Second menu
  const menusArr = [
    {
      title: 'Создать договор',
      onClick: () => goContractsCreate(marketId),
      withAlert: true,
      perms: ['add_contract']
    }
  ]

  return (
    <>
      <DetailBar
        title={'Договоры'}
        goBack={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
        <SecondMenu
          title={'Дополнительные функции по работе с договорами'}
          menusArr={menusArr}
          button={
            <Fab
              text={'Дополнительно'}
              withfixed={'true'}
            >
              <Plus size={20} color={'#fff'} />
            </Fab>
          }
        />
      </Container>
    </>
  )
}

export default MarketsContractsGrid
