import React from 'react'
import {Field} from 'react-final-form'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {map, prop} from 'ramda'
import styled from 'styled-components'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import TextField from 'components/Forms/TextField'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = (props) => {
  const {
    divisionsList,
    userId,
    divisionId
  } = props

  // Data
  const divisionsListData = prop('results', divisionsList)

  const divisionsOptions = divisionsListData && map((item) => {
    const value = `${prop('id', item)}`
    const label = prop('name', item)
    return {value, label}
  }, divisionsListData)

  const typesOptions = [
    {value: '1', label: 'Оплата'},
    {value: '3', label: 'Возврат'},
    {value: '4', label: 'Заказ'},
    {value: '9', label: 'Произвольный'}
  ]

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'date'}
        name={'beginDate'}
        label={'Период до'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDate'}
        label={'Период с'}
        fullWidth
      />
      {!divisionId &&
      <Field
        component={StaticSearchField}
        name={'division'}
        label={'Организация'}
        options={divisionsOptions}
        fullWidth
      />}
      {!userId &&
      <Field
        component={StaticSearchField}
        name={'type'}
        label={'Тип'}
        options={typesOptions}
        fullWidth
      />}
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    setFilterIsOpen,
    onChangeFilter,
    initialValues,
    divisionsList,
    userId,
    searchObj,
    divisionId
  } = props

  const handleSubmit = (values) => {
    onChangeFilter({
      division: prop('division', values),
      beginDate: prop('beginDate', values),
      endDate: prop('endDate', values),
      type: prop('type', values)
    })
    setFilterIsOpen(false)
  }
  const typeQuery = prop('type', searchObj)
  const newInitialValues = (userId && !typeQuery)
    ? {...initialValues, type: '1'}
    : initialValues

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={newInitialValues}
    >
      <FormFields
        divisionsList={divisionsList}
        userId={userId}
        divisionId={divisionId}
      />
    </Form>
  )
}

export default Filter
