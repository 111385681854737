import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ButtonUI from '@material-ui/core/Button'

const StyledButtonUI = styled(ButtonUI)`
  height: 50px;
  font-size: 16px !important;
  text-transform: none !important;
  line-height: 100% !important;
  padding: 12px 10px !important;
  box-shadow: none !important;
  background: ${({theme, secondary, background}) => background || ((secondary === 'true') ? '#dedede' : theme.palette.blue)} !important;
  color: ${({theme, secondary}) => (secondary === 'true') ? '#000' : theme.palette.white} !important;
  border-radius: ${props => props.theme.borderRadius.primary} !important;
  &.MuiButton-root.Mui-disabled{
    background: ${({theme}) => theme.button.disabled.background} !important;
    color: ${({theme}) => theme.palette.lightGrey} !important;
  }
  ${({styles}) => styles};
`
export const Fixed = styled('div')`
  position: fixed;
  bottom: 12px;
  z-index: 10;
  right: 12px;
  left: 12px;
  max-width: calc(100% - 24px);
`

const Button = props => {
  const {
    type = 'submit',
    variant = 'contained',
    input,
    text,
    color,
    background,
    fullWidth,
    onClick,
    disabled,
    secondary,
    styles,
    ...rest
  } = props

  const secondaryString = secondary ? 'true' : 'false'

  return (
    <StyledButtonUI
      variant={variant}
      type={type}
      color={color}
      background={background}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      secondary={secondaryString}
      styles={styles}
      {...input}
      {...rest}
    >
      {text}
    </StyledButtonUI>
  )
}

Button.propTypes = {
  text: PropTypes.any.isRequired,
  styles: PropTypes.object,
  type: PropTypes.string,
  fullWidth: PropTypes.bool
}

Button.defaultProps = {
  disabled: false
}

export default Button
