import React, {useEffect, useState} from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import MainBar from 'components/Navigation/TopBar/MainBar'
import {isEmpty, map, prop, propOr} from 'ramda'
import Container from 'components/Container/Container'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Fab from 'components/Forms/Fab'
import {Edit, Plus, X} from 'react-feather'
import ProductsTypesItem from 'containers/ProductsTypes/components/ProductsTypesItem/ProductsTypesItem'
import moment from 'moment'
import Loader from 'components/Loader'
import ModalConfirm from 'components/Modal/ModalConfirm'
import {parseParams} from 'helpers/url'
import styled from 'styled-components'
import NoResults from 'components/NoData/NoResults'

const LastBtn = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const ProductsTypesGrid = props => {
  const {
    productsTypesList,
    productsTypesDelete,
    handleSetSelectedType,
    handleSetReloadUI,
    goCreate,
    goEdit,
    location
  } = props

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const selectedType = prop('selectedType', searchObj)
  const reloadUI = prop('reloadUI', searchObj)

  // useStates
  const [moreOptions, setMoreOptions] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  // useEffect
  useEffect(() => {
    if (reloadUI) {
      handleSetReloadUI('')
    }
    // eslint-disable-next-line
  }, [reloadUI])

  // Data
  const productsTypesListData = propOr([], 'data', productsTypesList)
  const productsTypesListLoading = prop('loading', productsTypesList)
  const productsTypesDeleteLoading = prop('loading', productsTypesDelete)

  // Const
  const handleDelete = () => {
    productsTypesDelete.onSubmit({id: selectedType})
    setConfirmDelete(false)
  }
  const childrenContent = (children, level) => map(subItem => {
    const subId = prop('id', subItem)
    const subName = prop('name', subItem)
    const subCreatedDate = prop('createdDate', subItem)
    const hasChildren = !isEmpty(prop(subItem, 'children'))
    return (
      <React.Fragment
        key={subId}
      >
        <ProductsTypesItem
          withDelete={moreOptions}
          level={level}
          title={subName}
          subTitle={subCreatedDate && moment(subCreatedDate).format('DD MMM YYYY HH:mm')}
          sub
          onClick={() => goEdit(subId)}
          onDelete={() => {
            handleSetSelectedType(subId)
            setConfirmDelete(true)
          }}
        />
        {hasChildren && childrenContent(prop('children', subItem), level + 1)}
      </React.Fragment>
    )
  }, children)
  // ProductsList
  const productsTypesListContent = map(item => {
    const id = prop('id', item)
    const name = prop('name', item)
    const createdDate = prop('createdDate', item)
    const children = propOr([], 'children', item)

    return (
      <React.Fragment key={id}>
        <ProductsTypesItem
          withDelete={moreOptions}
          title={name}
          subTitle={createdDate && moment(createdDate).format('DD MMM YYYY HH:mm')}
          key={id}
          onClick={() => goEdit(id)}
          onDelete={() => {
            handleSetSelectedType(id)
            setConfirmDelete(true)
          }}
        />
        {childrenContent(children, 1)}
      </React.Fragment>
    )
  }, productsTypesListData)

  // MainContent
  const mainContent =
    <ul>
      {productsTypesListContent}
    </ul>

  // SecondMenuButton
  const secondMenuButton =
    <Fab
      text={'Дополнительно'}
      withfixed={'true'}
    >
      <Plus size={20} />
    </Fab>

  // SecondMenuList
  const menusArr = [
    {
      title: 'Создать тип',
      onClick: goCreate,
      withAlert: true,
      perms: ['add_market']
    }
  ]

  // SecondMenu
  const secondMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с продуктами'}
      menusArr={menusArr}
      button={secondMenuButton}
    />

  // ConfirmModal
  const confirmModals =
    <>
      <ModalConfirm
        open={confirmDelete}
        isOpen={setConfirmDelete}
        onClick={handleDelete}
        loading={productsTypesDeleteLoading}
        type={'delete'}
      />
    </>

  // Render
  return (
    <>
      <MainBar
        title={'Типы продуктов'}
        firstBtn={
          <MainMenu />
        }
        lastBtn={!isEmpty(productsTypesListData) &&
        <LastBtn
          onClick={() => setMoreOptions(!moreOptions)}
        >
          {moreOptions
            ? <X size={22} color={'#000'} />
            : <Edit size={20} color={'#e66767'} />}
        </LastBtn>}
      />
      <Container
        background={'#fff'}
      >
        {productsTypesListLoading ? <Loader styles={{marginTop: '20px'}} /> : isEmpty(productsTypesListData) ? <NoResults /> : mainContent}
      </Container>
      {confirmModals}
      {secondMenu}
    </>
  )
}

export default ProductsTypesGrid
