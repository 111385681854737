import {compose} from 'recompose'
import {withRedirect} from 'hocs'
import CarsOrdersGrid from './Grid'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const carsOrdersList = prop('carsOrdersList', state)
    return {
      carsOrdersList
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.CARS_ORDERS_DETAIL_URL,
    withCurrentParams: true
  })
)(CarsOrdersGrid)
