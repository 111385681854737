import {compose, withHandlers} from 'recompose'
import Grid from 'containers/Cars/Grid/Detail/Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {
  carsDetailAction,
  carsOrdersCreateAction,
  carsOrdersFinishCreateAction,
  carsProductsItemUpdateAction
} from 'containers/Cars/actions/carsAction'
import * as ROUTES from 'constants/routes'
import {appendParamsToUrl} from 'helpers/url'
import {path} from 'ramda'

export default compose(
  withFetchList({
    stateName: STATES.CARS_DETAIL,
    action: carsDetailAction,
    key: STATES.CARS_DETAIL
  }),
  withCreate({
    stateName: STATES.CARS_ORDERS_CREATE,
    action: carsOrdersCreateAction,
    key: STATES.CARS_ORDERS_CREATE,
    withoutMessage: true
  }),
  withCreate({
    stateName: [STATES.CARS_ORDERS_FINISH_CREATE],
    action: carsOrdersFinishCreateAction,
    key: [STATES.CARS_ORDERS_FINISH_CREATE],
    successMessage: 'Работа успешно закончена',
    redirectUrl: ROUTES.CARS_URL
  }),
  withCreate({
    stateName: [STATES.CARS_PRODUCTS_ITEM_UPDATE],
    action: carsProductsItemUpdateAction,
    key: [STATES.CARS_PRODUCTS_ITEM_UPDATE],
    successMessage: 'Товар успешно изменён',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    propFromParams: 'id'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goDescription',
    redirectUrl: ROUTES.CARS_DESCRIPTION_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goAddProducts',
    redirectUrl: ROUTES.CARS_ADD_PRODUCTS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goAddServices',
    redirectUrl: ROUTES.CARS_ADD_SERVICES_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrders',
    redirectUrl: ROUTES.CARS_ORDERS_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetAcceptorIdQuery: props => (acceptorId) => {
      props.history.push({
        search: appendParamsToUrl({acceptorId}, path(['history', 'location', 'search'], props))
      })
    },
    addProductIdHandler: props => (productId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productId}, path(['history', 'location', 'search'], props))
      })
    },
    addServiceIdHandler: props => (serviceId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({serviceId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
