export const colorBlue = '#12aaeb'

export const isOpenToTop = (focus) => {
  return {
    position: 'fixed',
    top: focus ? '14px' : '24vh',
    left: '14px',
    zIndex: '2002',
    right: '14px',
    height: '98px',
    background: '#fff',
    transition: 'top .35s'
  }
}

export const isOpenToTopForMenu = (focus) => {
  return {
    width: 'auto',
    position: 'fixed',
    top: focus ? '108px' : 'calc(24vh + 97px)',
    left: '14px',
    zIndex: '2002',
    right: '14px',
    transition: 'top .35s'
  }
}
