import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {productsTypesCreateAction} from 'containers/Products/actions/productsActions'

export default compose(
  withCreate({
    stateName: STATES.PRODUCTS_TYPES_CREATE,
    action: productsTypesCreateAction,
    key: STATES.PRODUCTS_TYPES_CREATE,
    redirectToCreated: ROUTES.PRODUCTS_TYPES_URL,
    successMessage: 'Тип продукта создан'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTS_TYPES_URL
  })
)(Grid)
