import {compose} from 'recompose'
import Grid from './Grid'
import {withFilter, withRedirect} from 'hocs'
import {connect} from 'react-redux'
import {prop} from 'ramda'
import * as ROUTES from 'constants/routes'

export default compose(
  connect(state => {
    const branchesList = prop('branchesList', state)
    return {
      branchesList
    }
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_NEW_DETAIL_URL
  }),
  withFilter({
    fields: ['search']
  })
)(Grid)
