import {compose, withHandlers} from 'recompose'
import Grid from './Grid'
import {withFetchList, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import {marketsStatisticsByProductsAction} from 'containers/Clients/actions/marketsAction'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'
import {connect} from 'react-redux'

export default compose(
  connect((state) => {
    const config = prop('config', state)
    return {
      config
    }
  }),
  withFetchList({
    stateName: STATES.MARKETS_STATISTICS_BY_PRODUCTS,
    action: marketsStatisticsByProductsAction,
    key: [STATES.MARKETS_STATISTICS_BY_PRODUCTS],
    pickParams: ['beginDate', 'endDate', 'search', 'user']
  }),
  withFilter({
    fields: ['beginDate', 'endDate', 'search', 'user']
  }),
  withHandlers({
    handleSetPeriod: props => (beginDate, endDate) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({beginDate, endDate}, path(['history', 'location', 'search'], props))
      })
    },
    handleSetProductTypeId: props => productTypeId => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({productTypeId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(Grid)
