import {path, prop} from 'ramda'
import moment from 'moment'
import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import {sprintf} from 'sprintf-js'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'

export const plansByAgentsDetailAction = (params, props) => {
  const userId = path(['match', 'params', 'id'], props)
  const today = moment().format('YYYY-MM-DD')
  const date = prop('date', params)
  const search = prop('search', params)

  const defaultParams = {
    page_size: 100,
    date: date || today,
    search
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_AGENT_PLANS, userId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PLANS_BY_AGENTS_DETAIL,
      payload
    })
  }
}
