import React from 'react'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'
import styled from 'styled-components'
import logoIcon from 'img/logoIcon.png'
import PropTypes from 'prop-types'
import TextField from 'components/Forms/TextField'
import Form from 'components/Forms/Form'
import Button from 'components/Forms/Button'
import {Field} from 'react-final-form'

const TopBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 12px;
`
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  p{
    color: ${({theme}) => theme.text.secondary};
  }
`
const tileItemStyles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flexStart'
  }
}
const FieldsWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = props => {
  const {
    loading
  } = props

  return (
    <FieldsWrap>
      <Field
        component={TextField}
        placeholder={'Введите описание'}
        name={'description'}
        multiline
        fullWidth
      />
      <Button
        text={'Отправить'}
        disabled={loading}
        fullWidth
      />
    </FieldsWrap>
  )
}

const CustomExtensionsItem = props => {
  const {
    title,
    description,
    imgSrc = logoIcon,
    handleSubmit,
    loading
  } = props

  // Render
  return (
    <TilesList
      title={title}
    >
      <TileItem
        styles={tileItemStyles}
      >
        <TopBar>
          <PhotoWithStatus
            imgSrc={imgSrc}
            status={'none'}
            size={'50px'}
          />
          <RightBar>
            {description
              ? <p>{description}</p>
              : <p>Вы так же можете заказать отдельные модули под ваш бизнес. Мы обязательно его рассмотрим.</p>}
          </RightBar>
        </TopBar>
        <Form
          initialValues={{}}
          onSubmit={handleSubmit}
        >
          <FormFields
            loading={loading}
          />
        </Form>
      </TileItem>
    </TilesList>
  )
}

CustomExtensionsItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.string
}

export default CustomExtensionsItem
