import React from 'react'
import styled from 'styled-components'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {CardFieldsFilter} from 'components/Cards/CardFieldsFilter'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = () => {
  return (
    <StyledWrap>
      <h1>Coming soon</h1>
      <Fixed>
        <Button
          text={'Coming soon'}
          fullWidth
          fixed
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {setOpenFilter, onChangeFilter, initialValues} = props

  const handleSubmit = () => {
    setOpenFilter(false)
    onChangeFilter({
      // ({values})
    })
  }

  return (
    <CardFieldsFilter>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <FormFields />
      </Form>
    </CardFieldsFilter>
  )
}

export default Filter
