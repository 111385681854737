import {compose, withHandlers} from 'recompose'
import MarketsTelegramsGrid from 'containers/Clients/Grid/Telegrams/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {connect} from 'react-redux'
import {path, prop} from 'ramda'
import {divisionsListAction} from 'actions/divisionsAction'
import {appendParamsToUrl} from 'helpers/url'

export default compose(
  connect((state) => {
    const marketsTelegrams = prop('marketsTelegrams', state)
    return {
      marketsTelegrams
    }
  }),
  withFetchList({
    stateName: STATES.DIVISIONS_LIST,
    action: divisionsListAction,
    key: STATES.DIVISIONS_LIST
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goCreate',
    redirectUrl: ROUTES.MARKETS_TELEGRAM_CREATE_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetTelegramsId: props => (telegramId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({telegramId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(MarketsTelegramsGrid)
