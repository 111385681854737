import React from 'react'
import PropTypes from 'prop-types'
import {sprintf} from 'sprintf-js'
import toCamelCase from 'helpers/toCamelCase'
import * as API from 'constants/api'
import SearchField from '../SearchField'
import axios from 'helpers/axios'
import EndlessSearchField from '../EndlessSearchField'
import {pathOr} from 'ramda'

const PARENT = 0
const ProductTypeEndlessSearchField = (props) => {
  const {input, api, params, pageSize, optionName = 'name', ...defaultProps} = props
  const isInitial = pathOr(false, ['meta', 'initial', 'value'], props)
  const getOptions = (search, params) => {
    return (dispatch, getState) => {
      return axios({dispatch, getState})
        .get(API.PRODUCTS_TYPES, {params: {...params}})
        .then(({data}) => {
          const array = data.results || data
          return Promise.resolve(toCamelCase(array))
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          return console.log('Ошибка здесь', e)
        })
    }
  }
  const getItem = (id) => {
    return (dispatch, getState) => {
      return axios({dispatch, getState})
        .get(sprintf(API.PRODUCTS_TYPES_ITEM, +id))
        .then(({data}) => {
          const array = data.results || data
          return Promise.resolve(toCamelCase(array))
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          return console.log('Ошибка здесь', e)
        })
    }
  }
  return (
    <EndlessSearchField
      isInitial={isInitial}
      placeholder={props.placeholder}
      getValue={SearchField.defaultGetValue('id')}
      getItem={getItem}
      getOptions={(search, parent = PARENT) => getOptions(search, {...params, parent}, pageSize)}
      getText={SearchField.defaultGetText(optionName)}
      input={input}
      {...defaultProps}
    />
  )
}

ProductTypeEndlessSearchField.propTypes = {
  params: PropTypes.object,
  optionName: PropTypes.string
}

export default ProductTypeEndlessSearchField
