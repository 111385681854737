import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'
import {path} from 'ramda'

export const branchesDetailAction = (params, props) => {
  const branchId = path(['match', 'params', 'id'], props)
  const data = {
    thumbnail_type: 'original'
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.STOCKS_DETAIL, branchId), {params: data})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.BRANCHES_DETAIL,
      payload
    })
  }
}

export const branchesDeleteAction = ({id}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.STOCKS_DETAIL, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.BRANCHES_DELETE,
      payload
    })
  }
}

export const branchesCreateAction = values => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.STOCKS_LIST, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.BRANCHES_CREATE,
      payload
    })
  }
}

export const branchesEditAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.STOCKS_DETAIL, id), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.BRANCHES_CREATE,
      payload
    })
  }
}
