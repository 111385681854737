import React, {useState} from 'react'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import {
  filter,
  isEmpty,
  length,
  path,
  pipe,
  prop,
  propEq,
  propOr
} from 'ramda'
import NoResults from 'components/NoData/NoResults'
import numberFormat from 'helpers/numberFormat'
import CardDescription from 'components/Cards/CardDescription'
import mapIndexed from 'helpers/mapIndexed'
import MarketsPlanItem from 'containers/Clients/components/MarketsPlanItem/MarketsPlanItem'
import sumBy from 'helpers/sumBy'
import styled from 'styled-components'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import CallIcon from 'components/SvgIcons/CustomIcons/CallIcon'
import Loader from 'components/Loader'

const TotalPlans = styled('li')`
  padding: 12px 0;
  margin: 0 16px;
  h3{
    margin-bottom: 7px;
  }
  & > div {
    padding: 10px 12px;
    background: #f9f9f9;
    border: 1px solid #e6e6e8;
    border-radius: ${({theme}) => theme.borderRadius.primary};
    & > * {
      padding: 5px 0;
    }
  }
`
const Href = styled('a')`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  svg{
    font-size: 17px;
    margin-left: 2px;
  }
`

const PlansDetailGrid = (props) => {
  const {
    goBack,
    plansByAgentsDetail,
    history,
    location,
    goDetail,
    goAppleMaps,
    goGoogleNav,
    goYandexNav,
    goYandexMaps,
    goReturnsCreate,
    goVisitsCreate,
    goOrdersCreate,
    goPaymentsCreate,
    userDetail
  } = props

  // useStates
  const [selectedMarket, setSelectedMarket] = useState({})
  const [isOpenMapsMenu, setIsOpenMapsMenu] = useState(false)

  // Redirects
  const search = prop('search', location)

  // Data
  const plansByAgentsDetailData = propOr([], 'data', plansByAgentsDetail)
  const plansByAgentsDetailLoading = prop('loading', plansByAgentsDetail)
  const userDetailData = prop('data', userDetail)

  // Const
  const userPhone = prop('phoneNumber', userDetailData)
  const userFirstName = prop('firstName', userDetailData)
  const userSecondName = prop('secondName', userDetailData)

  // PlanMarkets
  const planList = mapIndexed((item, index) => {
    const marketName = path(['market', 'name'], item)
    const marketId = path(['market', 'id'], item)
    const address = path(['market', 'address'], item)
    const planTasks = prop('plans', item)
    const distance = prop('distance', item)

    return (
      <MarketsPlanItem
        key={index}
        marketId={marketId}
        title={marketName}
        address={address}
        planTasks={planTasks}
        history={history}
        search={search}
        mainOnClick={() => goDetail(marketId)}
        navClick={() => {
          setSelectedMarket(marketId)
          setIsOpenMapsMenu(true)
        }}
        goReturnsCreate={goReturnsCreate}
        goVisitsCreate={goVisitsCreate}
        goOrdersCreate={goOrdersCreate}
        goPaymentsCreate={goPaymentsCreate}
        distance={distance}
      />
    )
  }, plansByAgentsDetailData)

  // Totals
  const currentMarketsAgentPlansLength = pipe(
    filter(item => {
      const plans = prop('plans', item)
      const donePlans = pipe(filter(propEq('status', 'done')), length)(plans)
      return donePlans >= 1
    }),
    length
  )(plansByAgentsDetailData)
  const tasksPlansLength = plansByAgentsDetailData && sumBy(plansByAgentsDetailData, ['plans', 'length'])
  const currentPlans = plansByAgentsDetailData && mapIndexed((item) => {
    return prop('plans', item)
  }, plansByAgentsDetailData)
  const currentPlansArr = [].concat.apply([], currentPlans)
  const finishedPlans = currentPlansArr && filter(propEq('status', 'done'), currentPlansArr)
  const finishedPlansLength = prop('length', finishedPlans)
  const plansByAgentsDetailLength = prop('length', plansByAgentsDetailData)

  // Totals
  const marketsTotalPlans = numberFormat(currentMarketsAgentPlansLength) + ' из ' + numberFormat(plansByAgentsDetailLength)
  const tasksTotalPlans = numberFormat(finishedPlansLength) + ' из ' + numberFormat(tasksPlansLength)
  const totals =
    <TotalPlans>
      <h3>Итоги по плану</h3>
      <div>
        <CardDescription it={'Посещено клиентов'} is={marketsTotalPlans} />
        <CardDescription it={'Выполнено заданий'} is={tasksTotalPlans} />
      </div>
    </TotalPlans>

  // MainContent
  const mainContent =
    <ul>
      {totals}
      {planList}
    </ul>

  // Maps
  const nativeMaps =
    ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1))
      ? {title: 'Apple Maps', onClick: () => goAppleMaps(prop('lat', selectedMarket), prop('lon', selectedMarket))}
      : {title: 'Google Карты', onClick: () => goGoogleNav(prop('lat', selectedMarket), prop('lon', selectedMarket))}

  const mapsMenuArr = [
    {
      title: 'Яндекс.Навигатор',
      onClick: () => goYandexNav(prop('lat', selectedMarket), prop('lon', selectedMarket))
    },
    {
      title: 'Яндекс.Карта',
      onClick: () => goYandexMaps(prop('lat', selectedMarket), prop('lon', selectedMarket))
    },
    nativeMaps
  ]

  const mapsMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с картами'}
      menusArr={mapsMenuArr}
      isOpen={isOpenMapsMenu}
      setIsOpen={setIsOpenMapsMenu}
    />

  // NavButtons
  const navButtons = [
    {
      icon: <Href href={'tel:' + userPhone}><CallIcon fontSize={'17px'} /></Href>
    }
  ]

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack()}
        title={userDetailData ? userFirstName + ' ' + userSecondName : 'План агента'}
        buttons={userDetailData && false && navButtons}
      />
      <Container
        background={'#fff'}
      >
        {plansByAgentsDetailLoading
          ? <Loader styles={{marginTop: '20px'}} />
          : isEmpty(plansByAgentsDetailData)
            ? <NoResults />
            : mainContent}
      </Container>
      {mapsMenu}
    </>
  )
}

export default PlansDetailGrid
