import React, {useState} from 'react'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import * as ROUTES from 'constants/routes'
import {sprintf} from 'sprintf-js'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import styled from 'styled-components'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as serializers from '../../../actions/serializers/stocksSerializers'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from './StocksIncomeFilter'
import mapIndexed from 'helpers/mapIndexed'
import StocksItem from 'containers/Stocks/components/StocksItem/StocksItem'

const Content = styled('div')`
  min-height: ${({secondHeight}) => 'calc(100vh - ' + (60 + secondHeight) + 'px)'} ;
`

const StocksIncomeGrid = (props) => {
  const {
    filterActions,
    location,
    history,
    customStore,
    stocksIncomeTasks
  } = props

  // Data
  const customStoreData = prop('data', customStore)
  const stocks = prop('stocks', customStoreData)
  const stocksIncomeTasksData = prop('data', stocksIncomeTasks)

  // UseStates
  const [openFilter, setOpenFilter] = useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // SecondHeight
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Income List
  const incomeList = stocksIncomeTasksData && mapIndexed((item, index) => {
    const stock = path(['stock', 'name'], item)
    const date = prop('date', item)
    const by = prop('by', item)
    const type = prop('type', item)
    const id = prop('id', item)
    const orderNumberId = prop('orderNumberId', item)
    const acceptedTime = prop('acceptedTime', item)
    const withHistory = prop('withHistory', searchObj)

    return (
      <StocksItem
        orderId={id}
        key={index}
        onClick={() => history.push({
          pathname: type === 'supply' ? sprintf(ROUTES.STOCKS_INCOME_SUPPLY_DETAIL_URL, id) : sprintf(ROUTES.STOCKS_INCOME_DETAIL_URL, orderNumberId || id),
          search: appendParamsToUrl({type: type}, path(['location', 'search'], history))
        })}
        stock={stock}
        date={date}
        fromWhom={by}
        type={type}
        acceptedTime={acceptedTime}
        withHistory={withHistory}
      />
    )
  }, stocksIncomeTasksData)

  // MainContent
  const incomeTasks =
    <InfiniteScroll
      api={API.STOCKS_INCOME_TASKS}
      actionType={actionTypes.STOCKS_INCOME_TASKS}
      emptyData={<li><NoResults /></li>}
      params={serializers.stocksIncomeTasksSerializer(searchObj)}
      list={incomeList || []}
    />

  // Render
  return (
    <>
      <MainBar
        title={'Приёмы'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
          >
            <Filter
              setOpenFilter={setOpenFilter}
              stocks={stocks}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        <Content
          secondHeight={secondHeight}
        >
          {incomeTasks}
        </Content>
      </Container>
    </>
  )
}

export default StocksIncomeGrid
