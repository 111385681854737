import {compose, mapPropsStream, pure} from 'recompose'
import {withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {
  marketsItemAction,
  marketsItemPhotoAction
} from 'containers/Clients/actions/marketsAction'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import MarketsDescriptionGrid from './Grid'
import {path, prop} from 'ramda'
import {distinctUntilChanged} from 'rxjs/operators'
import {DEFAULT_PICK_PARAMS, isEqualSearch} from 'helpers/isEquals'
import {getDataFromState, getListParams} from 'helpers/get'
import {connect} from 'react-redux'
import {parseParams} from 'helpers/url'

export default compose(
  connect((state) => {
    const marketsItemPhoto = getDataFromState(STATES.MARKETS_ITEM_PHOTO, state)
    return {
      marketsItemPhoto
    }
  }, {
    marketsItemPhotoAction
  }),
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: STATES.MARKETS_ITEM,
    pickParams: [DEFAULT_PICK_PARAMS]
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch([...DEFAULT_PICK_PARAMS, 'photoId']),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        const search = path(['location', 'search'], props)
        const searchObj = parseParams(search)
        const photoId = prop('photoId', searchObj)
        photoId &&
        props.marketsItemPhotoAction(getListParams(props, [...DEFAULT_PICK_PARAMS, 'photoId']), props)
      })

    return props$
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goMap',
    redirectUrl: ROUTES.MARKETS_ITEM_MAP_URL,
    withCurrentParams: false
  }),
  pure
)(MarketsDescriptionGrid)
