import React, {useState} from 'react'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import {groupBy, isEmpty, path, pipe, prop, toPairs} from 'ramda'
import styled from 'styled-components'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import NoResults from 'components/NoData/NoResults'
import SwipeableViews from 'react-swipeable-views'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import moment from 'moment'
import {Table} from 'components/Table'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import sumBy from 'helpers/sumBy'
import {MoreVertical as MoreIcon} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  .react-swipeable-view-container{
    max-height: calc(100vh - 60px);
  }
`
const TabsBar = styled('div')`
  position: fixed;
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e4e4e6;
`
const Inner = styled('div')`
  padding: 0 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`

const Content = styled('div')`
  height: calc(100vh - 60px);
  padding-top: 55px;
  padding-bottom: 14px;
  overflow-x: hidden;
  overflow-y: scroll;
`

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const CarsOrdersDetailGrid = (props) => {
  const {
    match,
    goBack,
    goDelete,
    carsOrdersDetail
  } = props

  // useStates
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  // Data
  const carsOrdersDetailData = prop('data', carsOrdersDetail)
  const carsOrdersDetailLoading = prop('loading', carsOrdersDetail)
  const id = prop('id', carsOrdersDetailData)
  const number = path(['car', 'number'], carsOrdersDetailData)
  const km = prop('km', carsOrdersDetailData)
  const clientName = path(['car', 'clientName'], carsOrdersDetailData)
  const carModel = path(['car', 'carBrand', 'name'], carsOrdersDetailData)
  const carBrand = path(['car', 'carBrand', 'parent', 'name'], carsOrdersDetailData)
  const comment = prop('comment', carsOrdersDetailData)
  const createdDate = prop('createdDate', carsOrdersDetailData)
  const finishTime = prop('finishTime', carsOrdersDetailData)
  const currency = path(['currency', 'name'], carsOrdersDetailData)
  const productsList = prop('products', carsOrdersDetailData)
  const servicesList = prop('services', carsOrdersDetailData)
  const totalPrice = prop('totalPrice', carsOrdersDetailData) || 0
  const status = prop('status', carsOrdersDetailData)
  const statusText = status === 'pending' ? 'В работе' : status === 'finish' ? 'Закончено' : 'Отменено'
  const statusStatus = status === 'pending' ? 'pending' : status === 'finish' ? 'active' : 'inactive'
  const diffH = moment(finishTime).diff(createdDate, 'hours')
  const diffM = moment(finishTime).diff(createdDate, 'minutes')
  const diffS = moment(finishTime).diff(createdDate, 'seconds')
  const timer = `${(+diffH !== 0) ? diffH + 'ч' : ''} ${diffM % 60}м ${diffS % 60}с`

  // Total prices
  const productsTotalPrice = productsList && sumBy(productsList, ['totalPrice'])
  const servicesTotalPrice = servicesList && sumBy(servicesList, ['totalPrice'])
  const sortedBy = productsList && pipe(
    groupBy(path(['product', 'id'])),
    toPairs
  )(productsList)
  const productsListAmount = prop('length', sortedBy)
  const sortedProducts = productsList && pipe(
    groupBy(path(['product', 'measurement', 'name'])),
    toPairs
  )(productsList)

  // Redirects
  const carId = path(['params', 'id'], match)

  // Tabs configs
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }

  // Tab Content One
  const productsTable =
    <TileItem
      height={'auto'}
      styles={{'& > div': {padding: '2px 0'}}}
    >
      <Table>
        <tbody>
          <tr>
            <th colSpan={'2'}>Название</th>
            <th>Кол&nbsp;-&nbsp;во</th>
            <th>Сумма</th>
          </tr>
          {productsList && mapIndexed((item, index) => {
            const productName = path(['product', 'name'], item)
            const amount = prop('amount', item)
            const measurement = (path(['product', 'measurement', 'name'], item)).slice(0, 3)
            const totalPriceProduct = prop('totalPrice', item)

            return (
              <tr key={index}>
                <td colSpan={'2'}>{productName}</td>
                <td>{numberFormat(amount, measurement)}</td>
                <td>{numberFormat(totalPriceProduct, currency)}</td>
              </tr>
            )
          }, productsList)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
            <td> </td>
            <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(productsTotalPrice, currency)}</TextStatus></td>
          </tr>
        </tfoot>
      </Table>
    </TileItem>

  const servicesTable =
    <TileItem
      height={'auto'}
      styles={{'& > div': {padding: '2px 0'}}}
    >
      <Table>
        <tbody>
          <tr>
            <th colSpan={'2'}>Название</th>
            <th>Кол&nbsp;-&nbsp;во</th>
            <th>Сумма</th>
          </tr>
          {servicesList && mapIndexed((item, index) => {
            const productName = path(['service', 'name'], item)
            const amount = prop('amount', item)
            const totalPriceProduct = prop('totalPrice', item)

            return (
              <tr key={index}>
                <td colSpan={'2'}>{productName}</td>
                <td>{numberFormat(amount, 'шт')}</td>
                <td>{numberFormat(totalPriceProduct, currency)}</td>
              </tr>
            )
          }, servicesList)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
            <td> </td>
            <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(servicesTotalPrice, currency)}</TextStatus></td>
          </tr>
        </tfoot>
      </Table>
    </TileItem>

  const tabContentOne =
    <Inner>
      <TilesList
        title={'Итого'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во видов товаров'} is={numberFormat(productsListAmount, 'ед')} />
        </TileItem>
        {sortedProducts && mapIndexed((item, index) => {
          const sortName = prop('0', item)
          const arr = prop('1', item)
          const amountSum = sumBy(arr, ['amount'])

          return (
            <TileItem
              height={'auto'}
              key={index}
            >
              <CardDescription it={'Кол-во товаров'} is={numberFormat(amountSum, sortName.slice(0, 3))} />
            </TileItem>
          )
        }, sortedProducts)}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во услуг'} is={numberFormat((prop('length', servicesList) || 0), 'шт')} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Сумма'} is={<TextStatus bold status={'primary'}>{numberFormat(totalPrice, currency)}</TextStatus>} />
        </TileItem>
      </TilesList>
      <TilesList
        title={'Список товаров'}
      >
        {!isEmpty(productsList) ? productsTable : <TileItem height={'auto'}>Нет товаров</TileItem>}
      </TilesList>
      <TilesList
        title={'Список услуг'}
      >
        {!isEmpty(servicesList) ? servicesTable : <TileItem height={'auto'}>Нет услуг</TileItem>}
      </TilesList>
    </Inner>

  // Tab Content Two
  const tabContentTwo =
    <Inner>
      <TilesList
        title={'Описание заказа'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Имя клиента'} is={clientName} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Марка авто'} is={carBrand + ' ' + carModel} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Номер авто'} is={number} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Пробег'} is={numberFormat(km, 'км')} />
        </TileItem>
        {comment &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Комментарий'} is={comment} />
        </TileItem>}
      </TilesList>
      <TilesList
        title={'Исполнение'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Текущий статус'} is={<TextStatus bold status={statusStatus}>{statusText}</TextStatus>} />
        </TileItem>
        {finishTime &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Время работы'} is={timer} />
        </TileItem>}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата создания'} is={moment(createdDate).format('DD MMM YYYY HH:mm')} />
        </TileItem>
        {finishTime &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата завершения'} is={moment(finishTime).format('DD MMM YYYY HH:mm')} />
        </TileItem>}
      </TilesList>
    </Inner>

  // Main content
  const mainContent =
    <>
      <TabsBar>
        <StyledTabs
          onChange={handleChange}
          value={value}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'Товары'} {...a11yProps(0)} />
          <Tab label={'Описание'} {...a11yProps(1)} />
        </StyledTabs>
      </TabsBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Content value={value} index={0}>
          {carsOrdersDetailLoading ? <Loader styles={{marginTop: '20px'}} /> : carsOrdersDetailData ? tabContentOne : <NoResults />}
        </Content>
        <Content value={value} index={1}>
          {carsOrdersDetailLoading ? <Loader styles={{marginTop: '20px'}} /> : carsOrdersDetailData ? tabContentTwo : <NoResults />}
        </Content>
      </SwipeableViews>
    </>

  // Nav Buttons
  const navButtons = [
    {
      icon: <MoreIcon />,
      onClick: () => setMenuIsOpen(true)
    }
  ]

  // Second menu list
  const menusArr = [
    {
      title: 'Удалить',
      onClick: () => goDelete(carId, id)
    }
  ]

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack(carId)}
        title={(!carsOrdersDetailLoading && carsOrdersDetailData) ? ('Посещение № ' + id) : 'Посещение'}
        buttons={navButtons}
      />
      <Container>
        <Wrap>
          {mainContent}
        </Wrap>
      </Container>
      <SecondMenu
        isOpen={menuIsOpen}
        setIsOpen={setMenuIsOpen}
        title={'Дополнительные функции по работе с посещением'}
        menusArr={menusArr}
      />
    </>
  )
}

export default CarsOrdersDetailGrid
