import React from 'react'
import moment from 'moment'
import Container from 'components/Container/Container'
import {DetailBar} from 'components/Navigation/TopBar'
import styled from 'styled-components'
import {groupBy, path, pipe, prop, toPairs} from 'ramda'
import {Modal} from 'components/Modal'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import * as API from 'constants/api'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import numberFormat from 'helpers/numberFormat'
import mapIndexed from 'helpers/mapIndexed'
import {TextStatus} from 'components/Status/TextStatus'
import {Table} from 'components/Table'
import BottomBar from 'components/Navigation/BottomBar'
import sumBy from 'helpers/sumBy'
import NoResults from 'components/NoData/NoResults'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 16px 50px;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const StyledWrap = styled('div')`
  ${({styles}) => styles};
  & > *:not(:last-child){
    margin-bottom: 15px !important;
  }
`

const StocksDetailGrid = props => {
  const {
    list,
    goBack,
    searchObj,
    confirmModal
  } = props

  // Data
  const listLoading = prop('loading', list)
  const data = prop('data', list)
  const id = prop('id', data)
  const stockId = path(['stock', 'id'], data) || ''
  const orderNumber = prop('orderNumber', data)
  const productsList = prop('products', data) || prop('returnedProducts', data) || prop('returns', data)
  const sortedBy = productsList && pipe(
    groupBy(path(['product', 'id'])),
    toPairs
  )(productsList)
  const productsListAmount = prop('length', sortedBy)

  const sortedProducts = productsList && pipe(
    groupBy(path(['product', 'measurement', 'name'])),
    toPairs
  )(productsList)
  const stockName = path(['stock', 'name'], data)
  const stockIncomeName = path(['fromStock', 'name'], data)
  const stockOutcomeName = path(['toStock', 'name'], data)
  const marketName = path(['market', 'name'], data)
  const marketAddress = path(['market', 'address'], data)
  const type = prop('type', searchObj)
  const totalAmount = productsList && sumBy(productsList, ['amount'])
  const createdByFN = path(['createdBy', 'firstName'], data)
  const createdBySN = path(['createdBy', 'secondName'], data)
  const createdByName = (createdByFN && createdBySN) && createdByFN + ' ' + createdBySN
  const userFN = path(['user', 'firstName'], data)
  const userSN = path(['user', 'secondName'], data)
  const userName = (userFN && userSN) && userFN + ' ' + userSN
  const deliveryManFN = path(['deliveryMan', 'firstName'], data)
  const deliveryManSN = path(['deliveryMan', 'secondName'], data)
  const closedTime = prop('closedTime', data)
  const closedBy = prop('closedBy', data)
  const closedByFN = prop('firstName', closedBy)
  const closedBySN = prop('secondName', closedBy)
  const closedByName = closedByFN + ' ' + closedBySN
  const deliveryManName = (deliveryManFN && deliveryManSN) && deliveryManFN + ' ' + deliveryManSN
  const acceptedTime = prop('acceptedTime', data)
  const dateDelivery = prop('dateDelivery', data)

  // Title types
  const checkType = () => {
    switch (type) {
    case 'supply': return 'Поставка'
    case 'transfer': if (id) { return `Передача № ${id}` } else { return 'Передача' }
    case 'delivery_return': return 'Возврат от поставщика'
    case 'order_return': if (id) { return `Возврат № ${id}` } else { return 'Возврат' }
    case 'order': if (orderNumber) { return `Заказ № ${orderNumber}` } else { return 'Заказ' }
    case 'supply_return': return 'Возврат с П.'
    case 'shipment': if (id) { return `Партия № ${id}` } else { return 'Производство' }
    default: return null
    }
  }

  // Main submit
  const handleSubmit = (values) => {
    confirmModal.onSubmit({
      id: orderNumber || id,
      date: moment(prop('date', values)).toDate(),
      delivery_man: prop('deliveryMan', values),
      stock: stockId,
      type: type,
      order: orderNumber,
      transferId: id
    })
  }
  // Modal
  const modalOrderTransfer =
    <Modal
      open={confirmModal.open}
      isOpen={confirmModal.onClose}
      title={`Подтвердить запрос № ${orderNumber || id || ''}`}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={confirmModal.initialValues}
      >
        <StyledWrap>
          {type === 'order' &&
          <Field
            component={UsersSearchField}
            api={API.USERS_LIST_FOR_SELECT}
            params={{group: 'delivery', own_division: 'true', page_size: 1000}}
            name={'deliveryMan'}
            label={'Доставщик'}
            required
            fullWidth
          />}
          <Field
            component={TextField}
            type={'date'}
            label={'Дата'}
            name={'date'}
            required
            fullWidth
          />
          <Button
            disabled={confirmModal.loading}
            text={'Применить'}
            fullWidth
          />
        </StyledWrap>
      </Form>
    </Modal>

  const detailContent = (
    <Wrap>
      <TilesList
        title={'Подробности'}
      >
        {(type === 'shipment') &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кто передал'} is={closedByName} />
        </TileItem>}
        {(type === 'shipment') &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата передачи'} is={moment(closedTime).format('DD MMM YYYY HH:mm')} />
        </TileItem>}
        {(type === 'order' || type === 'order_return') &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Клиент'} is={marketName} />
        </TileItem>}
        {(type === 'order' || type === 'order_return') &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Адрес'} is={marketAddress} />
        </TileItem>}
        {stockName &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Склад'} is={stockName} />
        </TileItem>}
        {(stockIncomeName && type === 'transfer') &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Склад передачи'} is={stockIncomeName} />
        </TileItem>}
        {(stockOutcomeName && type === 'transfer') &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Склад приёма'} is={stockOutcomeName} />
        </TileItem>}
        {((type === 'order_return' || type === 'order') && (userName || createdByName)) &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Агент'} is={userName || createdByName} />
        </TileItem>}
        {((type === 'order') && (deliveryManName)) &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Доставщик'} is={deliveryManName} />
        </TileItem>}
        {((type === 'order') && (dateDelivery)) &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата доставки'} is={moment(dateDelivery).format('DD MMM YYYY')} />
        </TileItem>}
        {acceptedTime &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Время закрытия'} is={moment(acceptedTime).format('DD MMM YYYY HH:mm')} />
        </TileItem>}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во видов товаров'} is={numberFormat(productsListAmount, 'ед')} />
        </TileItem>
        {sortedProducts && mapIndexed((item, index) => {
          const sortName = prop('0', item)
          const arr = prop('1', item)
          const amountSum = sumBy(arr, ['amount'])

          return (
            <TileItem
              height={'auto'}
              key={index}
            >
              <CardDescription it={'Кол-во товаров'} is={numberFormat(amountSum, sortName.slice(0, 3))} />
            </TileItem>
          )
        }, sortedProducts)}
      </TilesList>
      <TilesList title={'Список товаров'}>
        <TileItem
          height={'auto'}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
              </tr>
              {productsList && mapIndexed((item, index) => {
                const productName = path(['product', 'name'], item)
                const amount = prop('amount', item)
                const measurement = (path(['product', 'measurement', 'name'], item)).slice(0, 3)

                return (
                  <tr key={index}>
                    <td colSpan={'2'}>{productName}</td>
                    <td>{numberFormat(amount, measurement)}</td>
                  </tr>
                )
              }, productsList)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(totalAmount, 'ед')}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
      <BottomBar>
        <Button
          type={'button'}
          text={'Подтвердить'}
          fullWidth
          onClick={() => confirmModal.onOpen(id)}
        />
      </BottomBar>
    </Wrap>
  )
  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack()}
        title={checkType()}
      />
      <Container>
        {listLoading ? <Loader styles={{marginTop: '20px'}} /> : data ? detailContent : <NoResults />}
      </Container>
      {modalOrderTransfer}
    </>
  )
}

export default StocksDetailGrid
