import React from 'react'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import styled from 'styled-components'
import UploadImages from 'components/Forms/UploadField/UploadImages'
import Switcher from 'components/Forms/Switcher'
import {prop} from 'ramda'
import ProductTypeEndlessSearchField
  from 'components/Forms/SearchField/Product/ProductTypeEndlessSearchField'

const Wrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 15px;
  }
`

const ProductsCreateForm = props => {
  const {
    productsCreateLoading,
    isEdit,
    values
  } = props
  // Values
  const nameValue = prop('name', values)
  const typeValue = prop('type', values)
  const measurementValue = prop('measurement', values)
  const priceValue = prop('price', values)

  // Const

  // Options

  // Button validation
  const isEq = !(
    nameValue &&
    typeValue &&
    measurementValue &&
    priceValue
  )

  return (
    <Wrap>
      <Field
        component={TextField}
        type={'text'}
        label={'Название'}
        name={'name'}
        required
        fullWidth
      />
      <Field
        component={UniversalSearchField}
        label={'Ед. измерения'}
        name={'measurement'}
        api={API.MEASUREMENT_LIST}
        params={{page_size: '1000', parent: '0'}}
        required
        fullWidth
      />
      <Field
        component={ProductTypeEndlessSearchField}
        params={{page_size: '1000', parent: '0'}}
        label={'Тип продукта'}
        name={'type'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        label={'Цена'}
        name={'price'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'text'}
        label={'Описание'}
        name={'description'}
        multiline
        fullWidth
      />
      <Field
        component={UploadImages}
        name={'images'}
        label={'Фото'}
        required
        single
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        label={'Порядковый номер'}
        name={'stockNumber'}
        fullWidth
      />
      <Field
        component={Switcher}
        name={'showInMarket'}
        type={'checkbox'}
        label={'Подключить в боте'}
        fullWidth
      />
      <BottomBar>
        <Button
          text={isEdit ? 'Редактировать' : 'Создать'}
          disabled={productsCreateLoading || isEq}
          fullWidth
        />
      </BottomBar>
    </Wrap>
  )
}

export default ProductsCreateForm
