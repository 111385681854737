import {compose, mapPropsStream} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {equals, path, prop} from 'ramda'
import {appendParamsToUrl} from '../../../helpers/url'
import {Field} from 'react-final-form'
import TextField from '../TextField'
import {Search} from 'react-feather'
import React from 'react'
import Form from '../Form'

const enhance = compose(
  connect(
  ),
  withRouter,
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => equals(
            prop('values', prev),
            prop('values', curr)
          )
        ),
        debounceTime(500)
      )
      .subscribe((props) => {
        const search = path(['values', props.query], props) || ''
        return props.history.push({
          search: appendParamsToUrl({[props.query]: search}, props.location.search)
        })
      }
      )
    return props$
  })
)

const FormFields = enhance((props) => {
  const {
    placeholder,
    query
  } = props

  return (
    <>
      <Field
        component={TextField}
        type={'search'}
        prefix={<Search size={18} />}
        name={query}
        placeholder={placeholder || 'Поиск'}
        size={'small'}
        fullWidth
      />
    </>
  )
})

const DebounceSearch = (props) => {
  const {
    filterActions,
    placeholder,
    query = 'search'
  } = props
  // Filters
  const handleSubmit = (values) => {
    filterActions.onChangeFilter({
      search: prop(query, values)
    })
  }
  const searchValue = path(['initialValues', query], filterActions)
  const initialValue = searchValue ? {[query]: decodeURI(searchValue)} : {}

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValue}
      >
        <FormFields
          placeholder={placeholder}
          query={query}
        />
      </Form>
    </>
  )
}

export default DebounceSearch
