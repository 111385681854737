import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import moment from 'moment'

export const posStatisticsPopularProductsSerializer = (params, props) => {
  const searchQuery = path(['location', 'search'], props)
  const searchObj = parseParams(searchQuery)
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const beginDate = prop('beginDate', searchObj) || firstDayOfMonth
  const endDate = prop('endDate', searchObj) || lastDayOfMonth

  const defaultParams = {
    begin_date: beginDate,
    end_date: endDate
  }

  return {...defaultParams}
}

export const posStatisticsRevenueSerializer = (params, props) => {
  const searchQuery = path(['location', 'search'], props)
  const searchObj = parseParams(searchQuery)
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const beginDate = prop('beginDate', searchObj) || firstDayOfMonth
  const endDate = prop('endDate', searchObj) || lastDayOfMonth

  const defaultParams = {
    by: 'day',
    begin_date: beginDate,
    end_date: endDate
  }

  return {...defaultParams}
}
