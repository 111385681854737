import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <path d="M17.9193 16.4927L17.0262 5.2384C16.9708 4.51328 16.3573 3.94551 15.6303 3.94551H13.866V6.55946C13.866 6.88317 13.604 7.1452 13.2803 7.1452C12.957 7.1452 12.6946 6.88317 12.6946 6.55946V3.94551H7.30585V6.55946C7.30585 6.88317 7.04342 7.1452 6.7201 7.1452C6.39639 7.1452 6.13436 6.88317 6.13436 6.55946V3.94551H4.37014C3.64307 3.94551 3.02959 4.51328 2.97417 5.23684L2.08073 16.4942C2.01163 17.3959 2.3244 18.2932 2.93862 18.9563C3.55288 19.6193 4.42366 20 5.32764 20H14.6728C15.5768 20 16.4476 19.6193 17.0618 18.9562C17.676 18.2932 17.9888 17.3958 17.9193 16.4927ZM12.6391 10.7614L9.60971 13.7909C9.4953 13.9053 9.34534 13.9627 9.19542 13.9627C9.04545 13.9627 8.89553 13.9053 8.78112 13.7909L7.36131 12.3711C7.13249 12.1422 7.13249 11.7716 7.36131 11.5428C7.59014 11.314 7.96112 11.314 8.18956 11.5428L9.19545 12.5483L11.8109 9.93285C12.0394 9.70442 12.4103 9.70442 12.6392 9.93285C12.868 10.1617 12.868 10.5326 12.6391 10.7614Z" />
      <path d="M10.0001 0C7.86842 0 6.13428 1.73453 6.13428 3.86621V3.94547H7.30576V3.86621C7.30576 2.38039 8.51432 1.17145 10.0001 1.17145C11.486 1.17145 12.6945 2.38039 12.6945 3.86621V3.94547H13.866V3.86621C13.8659 1.73453 12.1318 0 10.0001 0Z" />
    </SvgIcon>

  )
}
