import React, {useState} from 'react'
import Map from 'components/Map'
import {path, prop} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import NoResults from 'components/NoData/NoResults'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import {parseParams} from 'helpers/url'
import {Navigation as NavIcon} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Loader from 'components/Loader'

const MarketsMapGrid = (props) => {
  const {
    marketsItem,
    location,
    goBack,
    goYandexNav,
    goYandexMaps,
    goGoogleNav,
    goAppleMaps
  } = props

  // UseStates
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [loadingMap, setLoadingMap] = useState(true)

  // Data
  const marketsItemData = prop('data', marketsItem)
  const marketsItemLoading = prop('loading', marketsItem)

  const lat = `${path(['location', 'lat'], marketsItemData)}`
  const lon = `${path(['location', 'lon'], marketsItemData)}`

  // Redirects
  const marketId = path(['match', 'params', 'id'], props)
  const searchObj = parseParams(prop('search', location))

  const secondLat = prop('secondLat', searchObj)
  const secondLon = prop('secondLon', searchObj)

  const map =
    <Map
      lat={lat}
      lon={lon}
      secondLat={secondLat}
      secondLon={secondLon}
      onLoad={setLoadingMap}
    />

  const noResults =
    <TilesList style={{margin: '15px 10px'}}>
      <TileItem>
        <NoResults text={'Нет данных'} />
      </TileItem>
    </TilesList>

  const nativeMaps =
    ((navigator.platform.indexOf('iPhone') !== -1) ||
    (navigator.platform.indexOf('iPad') !== -1) ||
    (navigator.platform.indexOf('iPod') !== -1))
      ? {title: 'Apple Maps', onClick: () => goAppleMaps(lat, lon)}
      : {title: 'Google Карты', onClick: () => goGoogleNav(lat, lon)}

  const menuButtons = [
    {
      title: 'Яндекс.Навигатор',
      onClick: () => goYandexNav(lat, lon)
    },
    {
      title: 'Яндекс.Карта',
      onClick: () => goYandexMaps(lat, lon)
    },
    nativeMaps
  ]

  const navButton = [
    {
      icon: <NavIcon size={18} fill={'#000'} />,
      onClick: () => setMenuIsOpen(true)
    }
  ]

  return (
    <>
      <DetailBar
        goBack={() => goBack(marketId)}
        title={'Карта'}
        buttons={navButton}
      />
      <Container>
        {!marketsItemLoading && marketsItemData
          ? map
          : noResults}
        {loadingMap && <Loader />}
      </Container>
      <SecondMenu
        title={'Выберите навигатор из списка'}
        menusArr={menuButtons}
        isOpen={menuIsOpen}
        setIsOpen={setMenuIsOpen}
      />
    </>
  )
}

export default MarketsMapGrid
