import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import moment from 'moment'
import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {storageData} from 'helpers/storage'
import checkPermissions from 'helpers/checkPermissions'

export const totalStatisticsAction = ({params, props}) => {
  const searchQuery = path(['location', 'search'], props)
  const searchObj = parseParams(searchQuery)
  const today = moment().format('YYYY-MM-DD')
  const beginDate = prop('beginDate', searchObj) || today
  const endDate = prop('endDate', searchObj) || today
  const userPerms = prop('userPerms', props)
  const isAdmin = prop('isAdmin', props)
  const supervisor = prop('supervisor', searchObj) || storageData('onlyMyStats').getValue()
  const hasPerms = checkPermissions(['can_see_total_stat_mobile'], userPerms)
  const supervisorWithPerms = (hasPerms || isAdmin) ? (supervisor || '0') : (supervisor || '1')

  const defaultParams = {
    begin_date: beginDate,
    end_date: endDate,
    supervisor: !(+supervisorWithPerms)
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.PLANS_BY_AGENTS_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STATISTICS_BY_AGENTS,
      payload
    })
  }
}

export const statisticsAgentsByDivisionsAction = (params) => {
  const today = moment().format('YYYY-MM-DD')
  const beginDate = prop('begin_date', params) || today
  const endDate = prop('end_date', params) || today

  const defaultParams = {
    begin_date: beginDate,
    end_date: endDate
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.STATISTICS_AGENTS_BY_DIVISIONS, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STATISTICS_AGENTS_BY_DIVISIONS,
      payload
    })
  }
}

export const statisticsAgentsDebtsAction = (params) => {
  const monday = moment().clone().weekday(0).format('YYYY-MM-DD')
  const sunday = moment().clone().weekday(6).format('YYYY-MM-DD')
  const beginDate = prop('begin_date', params) || monday
  const endDate = prop('end_date', params) || sunday

  const defaultParams = {
    begin_date: beginDate,
    end_date: endDate
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.STATISTICS_AGENTS_DEBTS, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STATISTICS_AGENTS_DEBTS,
      payload
    })
  }
}
