import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Upload as UploadIcon, X} from 'react-feather'
import {path, filter, isEmpty} from 'ramda'
import {fromEvent} from 'rxjs'
import styled from 'styled-components'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {ImageUploadAction} from 'components/Forms/UploadField/UploadImages/ImageUploadAction'
import mapIndexed from 'helpers/mapIndexed'
import {openErrorAction} from 'actions/errorsAction'
import Loader from 'components/Loader'
import Button from 'components/Forms/Button'

const Wrap = styled('div')`
  button{
    margin-bottom: 10px;
    svg{
      color: ${({theme}) => theme.palette.white};
    }
  }
`
const LoaderWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vw;
`
const Label = styled('label')`
  display: block;
  color: ${({theme, disabled}) => disabled ? theme.input.disabled.color : theme.text.secondary} !important;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  &:after{
    display: ${({required}) => required ? 'inline-block' : 'none'};
    content: '\u00A0*';
    color: ${({theme}) => theme.palette.red};
  }
`
const ImageItem = styled('div')`
  position: relative;
`
const Delete = styled('div')`
  position: absolute;
  cursor: pointer;
  right: 3px;
  top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.palette.red};
  svg{
    color: ${({theme}) => theme.palette.white};
  }
`
const ImageWrap = styled('div')`
  position: relative;
  margin-top: 10px;
  img{
    width: 100%;
    border-radius: ${({theme}) => theme.borderRadius.primary};
  }
`

const enhance = compose(
  connect((state) => {
    const fileUpload = path(['fileUpload'], state)
    const androidImage = path(['image'], state)
    return {
      fileUpload,
      androidImage
    }
  })
)
const UploadImages = enhance((props) => {
  const {
    dispatch,
    androidImage,
    disabled,
    input,
    label,
    single,
    required
  } = props

  const inputName = path(['name'], input)
  const randomNumber = Math.round(Math.random() * 1000)
  const initialValues = input.value || []
  const [load, setLoad] = React.useState(false)
  const [images, setImages] = React.useState([...initialValues])
  const [newObj, setNewObj] = React.useState(null)
  const inputRef = useRef(null)

  useEffect(() => {
    const onChange$ = fromEvent(inputRef.current, 'change')
    onChange$.subscribe((e) => {
      const files = e.target.files
      const formData = new FormData()
      formData.append('file', files[0])
      setLoad(true)
      dispatch(ImageUploadAction(formData))
        .then((response) => {
          // setWithError(null)
          const data = response.value
          const {id, ...allParams} = data
          const newObJ = [{image: {fileId: id, ...allParams}}]
          setNewObj(newObJ)
          setLoad(false)
        })
        .catch((newError) => {
          return dispatch(openErrorAction({errMessage: newError}))
        })
    })
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    if (newObj !== null) {
      const newArray = [...images, ...newObj]
      input.onChange(newArray)
      setImages(newArray)
    }
    // eslint-disable-next-line
  }, [newObj])

  const onDelete = (fileId) => {
    const filtered = filter((i) => {
      const fileIdItem = path(['image', 'fileId'], i)
      return fileIdItem !== fileId && i
    }, images)
    setImages(filtered)
    input.onChange(filtered)
  }
  const stateDataImages = path(['data', inputName], androidImage)

  // For android ----
  useEffect(() => {
    if (stateDataImages) {
      input.onChange(stateDataImages)
    }
  // eslint-disable-next-line
  }, [stateDataImages])

  // MainContent
  const mainFields =
    <>
      {label &&
      <Label
        required={required}
        disabled={disabled}
      >
        {label}
      </Label>}
      <label htmlFor={randomNumber}>
        {((isEmpty(images) && single) || !single) &&
        <Button
          disabled={disabled}
          text={<UploadIcon size={25} />}
          component={'span'}
          type={'button'}
          fullWidth
        />}
      </label>
      <ImageWrap>
        {mapIndexed((i, key) => {
          const src = path(['image', 'file'], i)
          const fileId = path(['image', 'fileId'], i)
          return (
            <ImageItem key={key}>
              <Delete onClick={() => { onDelete(fileId) }}>
                <X color={'#000'} size={15} />
              </Delete>
              <img src={src} alt={'Ритм.Доставка'} />
            </ImageItem>
          )
        }, images)}
        {load &&
        <LoaderWrap>
          <Loader size={0.7} />
        </LoaderWrap>}
      </ImageWrap>
    </>

  return (
    <Wrap>
      {mainFields}

      <input
        disabled={disabled}
        multiple={!single}
        accept="image/*"
        type="file"
        ref={inputRef}
        name={'img'}
        id={randomNumber}
        style={{display: 'none'}}
      />
    </Wrap>

  )
})

UploadImages.propTypes = {
  fixed: PropTypes.bool
}

Button.defaultProps = {
  disabled: false
}

export default UploadImages
