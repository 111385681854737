import * as actionTypes from '../constants/actionTypes'
import createReducer from '../helpers/createReducer'
import {path, mergeRight, pathOr} from 'ramda'

export const defaultState = {
  data: {}
}

const imageReducer = () => {
  return createReducer(defaultState, {
    [`${actionTypes.IMAGE_ADDED}`] (state, action) {
      const key = path(['key'], action)
      const oldData = pathOr([], ['data', key], state)
      // console.warn(oldData, action)
      oldData.push(path(['data'], action))
      const newData = mergeRight(
        path(['data'], state),
        {[key]: oldData}
      )
      return {
        data: newData
      }
    },
    [`${actionTypes.IMAGE_ADDED}_CHANGE`] (state, {payload}) {
      return {
        data: payload
      }
    }

  })
}

export default imageReducer
