import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
  p{
    font-size: 15px;
    line-height: 18px;
    color: ${({theme}) => theme.text.secondary};
  }
`
const RightBar = styled(Bar)`
  align-items: flex-end;
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const stylesWithoutRP = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}
const ArrowWrap = styled('div')`
  display: ${({onClick}) => onClick ? 'block' : 'none'};
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`

const DebtsItem = props => {
  const {
    onClick,
    number,
    name,
    price,
    priceSuffix
  } = props

  return (
    <>
      <TileItem
        onClick={onClick && onClick}
        styles={onClick ? styles : stylesWithoutRP}
      >
        <LeftBar>
          <h3>{name}</h3>
          <p>{number || 'Нет данных'}</p>
        </LeftBar>
        <RightBar>
          <TextStatus bold status={price > 0 ? 'inactive' : 'active'}>{numberFormat(price, priceSuffix) || 'Нет долгов'}</TextStatus>
        </RightBar>
        <ArrowWrap
          onClick={onClick}
        >
          <Arrow />
        </ArrowWrap>
      </TileItem>
    </>
  )
}

DebtsItem.propTypes = {
  onClick: PropTypes.func,
  number: PropTypes.any,
  name: PropTypes.any,
  price: PropTypes.any,
  priceSuffix: PropTypes.string
}

export default DebtsItem
