import React, {useState} from 'react'
import Container from 'components/Container'
import {prop, path} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import {parseParams} from 'helpers/url'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import * as serializers from 'containers/Clients/actions/serializers/marketsSerializer'
import NoResults from 'components/NoData/NoResults'
import mapIndexed from 'helpers/mapIndexed'
import OrdersItem from 'containers/Clients/components/OrdersItem/OrdersItem'
import * as CONST from 'constants/constants'
import moment from 'moment'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from './MarketsOrdersFilters'
import {Filter as FilterIcon} from 'react-feather'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'
import * as ROUTES from 'constants/routes'
import MainBar from 'components/Navigation/TopBar/MainBar'
import MainMenu from 'components/Navigation/components/MainMenu'

const ProductsSearch = styled('div')`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.nav.primary.background};
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  transition: height .3s, opacity .3s, padding .3s;
`

const MarketsOrdersGrid = (props) => {
  const {
    location,
    match,
    goBack,
    goBackStatistics,
    goDetail,
    marketsSalesOrdersList,
    filterActions
  } = props

  // useStates
  const [openFilter, setOpenFilter] = useState(false)

  // Global
  const now = moment().format('YYYY-MM-DD')

  // Data
  const marketsSalesOrdersListData = prop('data', marketsSalesOrdersList)
  const checkData = Array.isArray(marketsSalesOrdersListData) ? marketsSalesOrdersListData : []

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const pathname = prop('pathname', location)
  const isUnconfirmed = pathname === ROUTES.ORDERS_PATH

  const marketId = path(['params', 'id'], match)
  const handleGoDetail = (market, id) => {
    goDetail(market, id)
  }

  // Main List
  const mainList = marketsSalesOrdersListData && mapIndexed((item) => {
    const id = prop('id', item)
    const market = path(['market', 'id'], item)
    const orderNumber = prop('orderNumber', item)
    const totalPrice = prop('totalPrice', item)
    const totalBalance = prop('totalBalance', item)
    const currency = path(['currency', 'name'], item)
    const paymentDate = prop('paymentDate', item)
    const dateDelivery = prop('dateDelivery', item)
    const paymentDifference = moment(paymentDate).diff(now, 'days')
    const paymentStatus = () => {
      if (totalBalance > CONST.ZERO) {
        if (paymentDifference >= CONST.ZERO) {
          return 'pending'
        } return 'inactive'
      } else if (totalBalance === CONST.ZERO) {
        return 'active'
      }
      return 'pending'
    }
    const status = +prop('status', item)
    const deliveryStatus = (status === CONST.ORDER_DELIVERED)
      ? 'active'
      : (status === CONST.ORDER_CANCELED)
        ? 'inactive'
        : 'pending'

    return (
      <OrdersItem
        key={id}
        orderNumber={orderNumber}
        totalPrice={totalPrice}
        currency={currency}
        paymentStatus={paymentStatus()}
        date={dateDelivery}
        deliveryStatus={deliveryStatus}
        onClick={() => handleGoDetail(market, id)}
      />
    )
  }, checkData)

  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_SALES_ORDERS_LIST}
        actionType={actionTypes.MARKETS_SALES_ORDERS_LIST}
        emptyData={<li><NoResults /></li>}
        params={serializers.marketsSalesOrdersListSerializer(searchObj, props)}
        list={mainList || []}
        style={{paddingTop: '56px'}}
      />
    </>

  // Nav
  const navButtons = [
    {
      icon: <FilterIcon />,
      onClick: () => setOpenFilter(true)
    }
  ]

  // Filters
  const filters =
    <FiltersBar
      isOpen={openFilter}
      setOpen={setOpenFilter}
      withOutButton
    >
      <Filter
        setFilterIsOpen={setOpenFilter}
        {...filterActions}
      />
    </FiltersBar>

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        query={'ordersSearch'}
        placeholder={'Название'}
      />
    </ProductsSearch>

  // DetailBar
  const detailBar =
    <DetailBar
      goBack={() => marketId ? goBack(marketId) : goBackStatistics()}
      title={'Заказы'}
      buttons={navButtons}
    />

  // MainBar
  const mainBar =
    <MainBar
      title={'Неподтверждённые'}
      firstBtn={
        <MainMenu />
      }
    />

  return (
    <>
      {isUnconfirmed ? mainBar : detailBar}
      <Container
        background={'#fff'}
      >
        {debounceSearch}
        {mainContent}
      </Container>
      {filters}
    </>
  )
}

export default MarketsOrdersGrid
