import {compose} from 'recompose'
import {prop} from 'ramda'
import {withFilter, withRedirect} from 'hocs'
import {connect} from 'react-redux'
import DebtsGrid from './Grid'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const posDebts = prop('posDebts', state)
    const config = prop('config', state)
    return {
      posDebts,
      config
    }
  }),
  withFilter({
    fields: ['search']
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.DEBTS_DETAIL_URL,
    withCurrentParams: false
  })
)(DebtsGrid)
