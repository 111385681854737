import {compose, mapProps, mapPropsStream} from 'recompose'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import MarketsCreateGrid from './Grid/MarketsCreateGrid'
import {
  marketsCreateAction,
  marketsDetailAction,
  marketsUpdateAction
} from '../../actions/marketsAction'
import normalizePhone from 'helpers/normalizers/normalizePhone'
import {isEqualSearch} from 'helpers/isEquals'
import {parseParams} from 'helpers/url'
import {path, prop, map, pipe, fromPairs} from 'ramda'
import {distinctUntilChanged} from 'rxjs/operators'

export const DEFAULT_PICK_PARAMS = [
  'openUpdateDialog'
]
export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const marketsList = prop('marketsList', state)
    const marketsItem = prop('marketsItem', state)
    const marketOptionsList = prop('marketOptionsList', state)
    const gpsAddress = path(['getAddress', 'data', 'result'], state)
    return {
      query,
      marketsList,
      marketsItem,
      marketOptionsList,
      gpsAddress
    }
  }),
  withCreate({
    stateName: [STATES.MARKETS_CREATE],
    action: marketsCreateAction,
    key: [STATES.MARKETS_CREATE],
    redirectToCreated: ROUTES.MARKETS_DETAIL_URL,
    successMessage: 'Успех'
  }),
  // Update
  withCreate({
    stateName: [STATES.MARKETS_CREATE],
    action: marketsUpdateAction,
    key: 'update',
    withCurrentParams: true,
    redirectUrl: ROUTES.MARKETS_DETAIL_DESCRIPTION_URL,
    propFromQuery: 'openUpdateDialog',
    successMessage: 'Успех'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_URL
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withFilter({
    fields: ['search']
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(DEFAULT_PICK_PARAMS),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        props.query.openUpdateDialog && props.dispatch(marketsDetailAction(props))
      })
    return props$
  }),
  mapProps(({
    marketsItem,
    marketsList,
    goDetail,
    update,
    marketOptionsList,
    handleCheckChangesOptionList,
    ...props
  }) => {
    const data = prop('data', marketsItem)
    // update dialog
    const images = prop('images', data) || []
    const phones = prop('phones', data) || []

    const searchIsShop = {
      dataList: marketsList,
      goDetail: goDetail,
      goBack: props.goBack
    }

    const optionsData = {
      onChange: handleCheckChangesOptionList,
      data: marketOptionsList.data,
      loading: marketOptionsList.loading
    }
    const marketOptions = data && pipe(
      prop('marketOptions'),
      map((ec) => {
        const type = path(['optionValue', 'option', 'fieldType'], ec)
        const key = path(['optionValue', 'option', 'id'], ec)
        const value = (type === 'select')
          ? path(['optionValue', 'id'], ec)
          : path(['optionValue', 'value'], ec)
        return ([`${key}`, value])
      }),
      fromPairs
    )(data)
    const updateDialog = {
      open: prop('openUpdateDialog', props.query),
      loading: prop('loading', marketsItem),
      name: prop('name', data),
      initialValues: {
        name: prop('name', data),
        marketType: path(['marketType', 'parent'], data) || path(['marketType', 'id'], data),
        marketTypeChild: path(['marketType', 'parent'], data) && path(['marketType', 'id'], data),
        ...marketOptions || null,
        images: map((i) => {
          return {
            image: i
          }
        }, images),
        telephones: map((i) => {
          return {
            phone: normalizePhone(prop('phone', i))
          }
        }, phones),
        // Address is located in FormFields > defaultValue!!
        guide: prop('guide', data),
        location: {
          lat: path(['location', 'lat'], data),
          lon: path(['location', 'lon'], data)
        },
        contactName: prop('contactName', data)
      },
      address: prop('address', data),
      onSubmit: update.onSubmit
    }

    return {
      updateDialog,
      searchIsShop,
      optionsData,
      ...props
    }
  })
)(MarketsCreateGrid)
