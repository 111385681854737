import React, {useState} from 'react'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Form from 'components/Forms/Form'
import {find, isEmpty, path, prop, propEq, reject} from 'ramda'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'
import {Modal} from 'components/Modal'
import {Trash2 as TrashIcon} from 'react-feather'
import {parseParams} from 'helpers/url'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {batchesAddMaterialsSerializer} from 'containers/Productions/actions/serializers/productsBatchesSerializers'
import InfiniteScroll from 'components/InfiniteScroll'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import BottomBar from 'components/Navigation/BottomBar'
import ModalConfirm from 'components/Modal/ModalConfirm'

const Wrap = styled('div')`
  padding: 14px 16px 30px;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
  button{
    margin-top: 10px;
  }
`
const MaterialsSearch = styled('div')`
  right: 14px;
  background: #fff;
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
`

const AddMaterialsFormFields = (props) => {
  const {
    productIdParam
  } = props

  const amountName = 'amount_' + productIdParam

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'number'}
        name={amountName}
        label={'Количество'}
        required
        fullWidth
      />
      <Button
        text={'Применить'}
        fullWidth
      />
    </StyledWrap>
  )
}
const InfiniteWrap = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 165px);
`

const BatchesAddMaterialsGrid = (props) => {
  const {
    goBack,
    match,
    location,
    filterActions,
    manufactureMaterialsList,
    addProductIdHandler,
    manufactureBatchesAddMaterials
  } = props

  // UseStates
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState({})
  const [products, setMaterials] = React.useState([])
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)
  const batchesId = path(['params', 'id'], match)

  // Data
  const manufactureMaterialsListData = prop('data', manufactureMaterialsList)
  const manufactureBatchesAddMaterialsLoading = prop('loading', manufactureBatchesAddMaterials)

  // Initial values

  const initialValuesObj = {}
  const initialValuesMaterials = {
  }

  // DebounceSearch
  const debounceSearch =
    <MaterialsSearch>
      <DebounceSearch
        filterActions={filterActions}
        placeholder={'Название'}
        query={'productsSearch'}
      />
    </MaterialsSearch>

  // Delete product
  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productIdParam), products)
    setMaterials(withoutDeletedProduct)
    setModalOpen(false)
  }

  // Main submit
  const handleMainSubmit = () => {
    manufactureBatchesAddMaterials.onSubmit({
      shipment: batchesId,
      products: products
    })
  }

  // Add products submit
  const handleAddMaterialsSubmit = (values) => {
    const amountPlus = 'amount_' + productIdParam
    const newObject = {
      product: productIdParam,
      amount: prop(amountPlus, values)
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedMaterials = reject(leftObject, products)
    const newMaterials = [...rejectedMaterials, newObject]

    const sortedMaterials = reject(propEq('amount', '0'), newMaterials)
    setMaterials(sortedMaterials)

    filterActions.onChangeFilter({})
    setModalOpen(false)
  }

  // Modals
  const addMaterialsModal =
    <Modal
      open={modalOpen}
      isOpen={setModalOpen}
      title={prop('name', selectedProduct) || 'Добавление сырья'}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={() => handleDeleteProduct()} />}
    >
      <Form
        onSubmit={handleAddMaterialsSubmit}
        initialValues={initialValuesMaterials}
      >
        <AddMaterialsFormFields
          productIdParam={productIdParam}
        />
      </Form>
    </Modal>

  // Confirm modal
  const confirmModals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Для продолжения подтвердите возврат'}
      loading={manufactureBatchesAddMaterialsLoading}
      type={'submit'}
    />
  // Materials list
  const productsList = manufactureMaterialsListData && mapIndexed((item) => {
    const productName = prop('name', item)
    const productId = prop('id', item)
    const isSelected = find(propEq('product', `${productId}`))(products)
    const selectedAmount = prop('amount', isSelected)
    const imageSrc = path(['image', 'file'], item)
    const measurementName = path(['measurement', 'name'], item).slice(0, 3)
    const balance = prop('balance', item)
    const selectedAmountStatus = (selectedAmount <= balance) ? 'active' : 'inactive'
    const handleAddMaterialsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({name: productName})
      addProductIdHandler(productId)
    }
    const amountFrom =
      <span>
        <TextStatus
          status={selectedAmountStatus}
          bold
        >
          {numberFormat(selectedAmount, measurementName)}
        </TextStatus>
        {' из '}
        <TextStatus
          bold
        >
          {numberFormat(balance, measurementName)}
        </TextStatus>
      </span>
    const textAmount = isSelected ? amountFrom : <TextStatus>{numberFormat(balance, measurementName)}</TextStatus>

    return (
      <ProductsItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        onClick={() => handleAddMaterialsOpen()}
        isSelected={isSelected}
        amount={textAmount}
        validAmount={+balance}
      />
    )
  }, manufactureMaterialsListData)

  // MainContent
  const mainContent =
    <InfiniteWrap>
      <InfiniteScroll
        api={API.MANUFACTURE_BATCHES_PRODUCTS_LIST}
        actionType={actionTypes.MARKETS_MATERIALS_LIST}
        emptyData={<Wrap><NoResults /></Wrap>}
        params={batchesAddMaterialsSerializer(search, props)}
        list={productsList || []}
        useWindow={false}
      />
      {confirmModals}
      <BottomBar>
        <Button
          type={'button'}
          onClick={() => setConfirmModalOpen(true)}
          text={'Применить'}
          fullWidth
          disabled={isEmpty(products)}
        />
      </BottomBar>
    </InfiniteWrap>

  // Return
  return (
    <>
      <CreateBar
        title={'Добавление сырья'}
        goExit={() => goBack(batchesId)}
      />
      <Container
        background={'#fff'}
        overflow={'hidden'}
        styles={{
          maxHeight: '100vh'
        }}
      >
        {debounceSearch}
        <Form
          onSubmit={handleMainSubmit}
          initialValues={initialValuesObj}
        >
          {mainContent}
        </Form>
        {addMaterialsModal}
      </Container>
    </>
  )
}

export default BatchesAddMaterialsGrid
