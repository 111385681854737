import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {X as CloseIcon} from 'react-feather'

const Item = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
`
const MenuTrigger = styled(Item)`
  width: 40px;
  position: relative;
  svg{
    color: #000;
    width: 22px;
    height: 22px;
  }
`
const BGMusk = styled('div')`
  background: ${({theme}) => theme.background.mask};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  transition: all .1s ease-out;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  z-index: 900;
`
const MenuContent = styled('div')`
  background: ${({theme}) => theme.background.primary};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all .2s ease-out;
  transform: translateX(${({isOpen}) => isOpen ? '0' : '300px'});
  overflow-y: auto;
  max-width: 300px;
  width: 100%;
  z-index: 1000;
`
const Title = styled('div')`
  height: 61px;
  border-bottom: 1px solid #e4e4e6;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 19px 16px;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #000;
  letter-spacing: -0.0241176em;
  background: ${({theme}) => theme.nav.primary.background};
`
const CloseMenu = styled('span')`
  cursor: pointer;
  margin-left: auto;
  width: 30px;
  height: 30px;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MainContent = styled('div')``

const RightBar = props => {
  const {
    icon,
    title,
    content,
    open,
    setOpen
  } = props

  // useStates
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  // switcherFunc
  const onMenuOpen = () => {
    setMenuIsOpen(true)
    setOpen(true)
  }
  const onMenuClose = () => {
    setMenuIsOpen(false)
    setOpen(false)
  }

  // useEffect
  useEffect(() => {
    if (menuIsOpen || open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
    return setMenuIsOpen(open)
  }, [menuIsOpen, open])

  return (
    <>
      <MenuTrigger onClick={onMenuOpen}>
        {icon}
      </MenuTrigger>
      <BGMusk isOpen={menuIsOpen || open} onClick={onMenuClose} />
      <MenuContent isOpen={menuIsOpen || open}>
        <Title>
          <span>{title || 'Ритм.Доставка'}</span>
          <CloseMenu onClick={onMenuClose}>
            <CloseIcon size={16} color={'#000'} />
          </CloseMenu>
        </Title>
        <MainContent>
          {content}
        </MainContent>
      </MenuContent>
    </>
  )
}

export default RightBar
