import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import FinancesTransferCashCreateGrid from './Grid/FinancesTransferCashCreateGrid'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path} from 'ramda'
import {transferCashCreateAction} from '../../actions/financesAction'

export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const config = path(['config', 'data'], state)
    return {
      query,
      config
    }
  }),
  withCreate({
    stateName: [STATES.FINANCES_TRANSFER_CASH_CREATE],
    action: transferCashCreateAction,
    key: [STATES.FINANCES_TRANSFER_CASH_CREATE],
    redirectUrl: ROUTES.FINANCES_URL,
    withCurrentParams: true,
    successMessage: 'Успех'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.FINANCES_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetClientQuery: props => (clientId) => {
      props.history.push({
        search: appendParamsToUrl({clientId}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  withFilter({
    fields: ['search']
  })
)(FinancesTransferCashCreateGrid)
