import React from 'react'
import {Field} from 'react-final-form'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField'
import {prop} from 'ramda'
import moment from 'moment'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import * as CONST from 'constants/constants'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = () => {
  const statusOptions = [
    {
      value: `${CONST.ORDER_REQUESTED}`,
      label: 'Запрос отправлен'
    },
    {
      value: `${CONST.ORDER_READY}`,
      label: 'Есть на складе'
    },
    {
      value: `${CONST.ORDER_GIVEN}`,
      label: 'Передан доставщику'
    },
    {
      value: `${CONST.ORDER_DELIVERED}`,
      label: 'Доставлен'
    },
    {
      value: `${CONST.ORDER_CANCELED}`,
      label: 'Отменён'
    },
    {
      value: `${CONST.ORDER_NOT_CONFIRMED}`,
      label: 'Не подтвержден'
    }
  ]

  return (
    <StyledWrap>
      <Field
        component={UniversalSearchField}
        name={'product'}
        label={'Товар'}
        api={API.MARKETS_PRODUCTS_LIST}
        params={{page_size: '1000'}}
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'status'}
        label={'Статус'}
        options={statusOptions}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'beginDate'}
        label={'Создан с'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDate'}
        label={'Создан до'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'beginDeliveryDate'}
        label={'Доставка с'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDeliveryDate'}
        label={'Доставка до'}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    onChangeFilter,
    setFilterIsOpen,
    initialValues
  } = props

  const handleSubmit = (values) => {
    const beginDate = prop('beginDate', values)
    const endDate = prop('endDate', values)
    const beginDeliveryDate = prop('beginDeliveryDate', values)
    const endDeliveryDate = prop('endDeliveryDate', values)
    const product = prop('product', values)
    const status = prop('status', values)

    onChangeFilter({
      beginDate: beginDate && moment(beginDate).format('YYYY-MM-DD'),
      endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
      beginDeliveryDate: beginDeliveryDate && moment(beginDeliveryDate).format('YYYY-MM-DD'),
      endDeliveryDate: endDeliveryDate && moment(endDeliveryDate).format('YYYY-MM-DD'),
      product: product && +product,
      status: status && +status
    })
    setFilterIsOpen(false)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormFields />
    </Form>
  )
}

export default Filter
