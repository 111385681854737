import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Plus as PlusIcon, Check as CheckIcon} from 'react-feather'
import PhotoWithStatus from 'components/PhotoWithStatus'
import {TextStatus} from 'components/Status/TextStatus'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
`
const RightBar = styled(Bar)`
  width: calc(100% - 57px);
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const StatusWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Plus = styled(PlusIcon)`
  color: #c7c7cc;
`
const Check = styled(CheckIcon)`
  color: ${({theme}) => theme.palette.green};
  stroke-width: 3;
`
const ValidAmount = styled('span')`
   color: ${({status, theme}) => status && theme.palette.red};
   font-weight: ${({status}) => status && '500'};
   * {
     color: ${({status, theme}) => status && theme.palette.red};
     font-weight: ${({status}) => status && '500'};
   }
`

const ProductsItem = props => {
  const {
    onClick,
    name,
    amount,
    price,
    isSelected,
    imageSrc,
    validAmount,
    withOutArrow
  } = props

  const statusIcon = isSelected ? <Check /> : <Plus />

  return (
    <>
      <TileItem
        onClick={onClick}
        styles={styles}
        height={'auto'}
      >
        <LeftBar>
          <PhotoWithStatus
            imgSrc={imageSrc}
            status={'none'}
            type={'product'}
          />
        </LeftBar>
        <RightBar>
          <TextStatus bold>{name}</TextStatus>
          {price &&
          <span>{price}</span>}
          {amount &&
          <ValidAmount status={validAmount <= 3}>{amount}</ValidAmount>}
        </RightBar>
        <StatusWrap>
          {(onClick && !withOutArrow) && statusIcon}
        </StatusWrap>
      </TileItem>
    </>
  )
}

ProductsItem.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.any,
  amount: PropTypes.any,
  imageSrc: PropTypes.string,
  validAmount: PropTypes.number
}

export default ProductsItem
