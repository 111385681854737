import {compose} from 'recompose'
import Grid from './Grid'
import {connect} from 'react-redux'
import {prop} from 'ramda'
import {withFilter, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'

export default compose(
  connect((state) => {
    const stocksSelections = prop('stocksSelections', state)
    const customStore = prop('customStore', state)
    return {
      stocksSelections,
      customStore
    }
  }),
  withFilter({
    fields: ['search', 'beginDate', 'endDate', 'stock']
  }),
  withRedirect({
    redirectUrl: ROUTES.STOCKS_SELECTIONS_DETAIL_URL,
    withCurrentParams: false,
    key: 'goDetail'
  }),
  withRedirect({
    key: 'goSelectionCreate',
    redirectUrl: ROUTES.STOCKS_SELECTIONS_CREATE_URL
  })
)(Grid)
