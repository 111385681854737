import React from 'react'
import {storageData} from 'helpers/storage'

class Location extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    let startPos
    const geoOptions = {
      enableHighAccuracy: true
    }
    const geoSuccess = position => {
      startPos = position
      const accuracy = startPos.coords.accuracy
      const latitude = startPos.coords.latitude
      const longitude = startPos.coords.longitude
      storageData('location').setValue({latitude, longitude, accuracy})
    }
    const geoError = (position) => {
      // eslint-disable-next-line no-console
      console.log('Проверьте точку доступа GPS: ' + position.message)
    }
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)

    this.timerID = setInterval(
      () => this.tick(),
      30000
    )
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  tick () {
    let startPos
    const geoOptions = {
      enableHighAccuracy: true
    }
    const geoSuccess = position => {
      startPos = position
      const accuracy = startPos.coords.accuracy
      const latitude = startPos.coords.latitude
      const longitude = startPos.coords.longitude
      storageData('location').setValue({latitude, longitude, accuracy})
    }
    const geoError = (position) => {
      // eslint-disable-next-line no-console
      console.log('Проверьте точку доступа GPS: ' + position.message)
    }
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
  }

  render () {
    return false
  }
}

export default Location
