import React from 'react'
import styled from 'styled-components'
import TileItem from 'components/Grid/Tile/TileItem'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import CardDescription from 'components/Cards/CardDescription'
import CheckedTask from 'components/CheckedTask'
import moment from 'moment'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`
const Title = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  h3{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-bottom: 5px;
  }
`
const VisitsWrap = styled('div')`
  margin-top: 7px;
  background: #f9f9f9;
  border: 1px solid #e6e6e8;
  border-radius: ${({theme}) => theme.borderRadius.primary};
  padding: 0 12px;
`
const StatsWrap = styled('div')`
  & > div > span {
    font-size: unset;
    line-height: unset;
    padding: 3px 0;
  }
  & > div {
  padding: 10px 0;
    &:not(:last-child){
      border-bottom: ${({theme}) => theme.border.primary};
    }
  }
`

const PlansAgentItem = props => {
  const {
    title,
    onClick,
    downtime,
    maxDowntime,
    lastVisitMarket,
    lastVisitMarketDate,
    lastVisitMarketTask,
    nextVisitMarket,
    nextMarketTask,
    startedWork,
    planCount = 0,
    planDoneCount = 0,
    orderTotalPrice,
    currency,
    clientTransactionTotalPrice,
    distanceNextMarket
  } = props

  const formatedDist = Math.round(distanceNextMarket * 10) / 10000
  const distanceText = formatedDist < 100 ? numberFormat(formatedDist, 'км') : 'Далеко'

  const downtimeStatus = !downtime ? undefined : downtime > maxDowntime ? 'inactive' : 'active'

  return (
    <TileItem
      onClick={onClick}
      height={'auto'}
    >
      <Wrap>
        <Title>
          <h3>{title}</h3>
        </Title>
        <StatsWrap>
          <CardDescription
            it={'Перый визит'}
            is={startedWork ? moment(startedWork).format('DD MMM YYYY HH:mm') : null}
          />
          <CardDescription
            it={'Посл. активность'}
            is={downtime ? <TextStatus bold status={downtimeStatus}>{numberFormat(downtime, 'мин назад')}</TextStatus> : null}
          />
          <CardDescription
            it={'Клиенты'}
            is={<TextStatus bold status={((planDoneCount > planCount) && (+planDoneCount !== 0)) ? null : 'active'}>{planCount + ' из ' + planDoneCount}</TextStatus>}
          />
          {(orderTotalPrice && +orderTotalPrice !== 0) &&
          <CardDescription it={'Заказы'} is={numberFormat(orderTotalPrice, currency)} />}
          {(clientTransactionTotalPrice && +clientTransactionTotalPrice !== 0) &&
          <CardDescription it={'Оплаты'} is={numberFormat(clientTransactionTotalPrice, currency)} />}
          <CardDescription it={'Дистанция до след клиента'} is={numberFormat(distanceText, 'км')} />
        </StatsWrap>
        <VisitsWrap>
          <CheckedTask
            text={lastVisitMarket || 'Нет посещённых клиентов'}
            subText={lastVisitMarketDate && moment(lastVisitMarketDate).format('HH:mm') + ' - ' + lastVisitMarketTask}
            status={lastVisitMarket ? 'active' : 'inactive'}
          />
          <CheckedTask
            text={nextVisitMarket || 'Нет клиентов по плану'}
            subText={nextMarketTask || false}
            status={'pending'}
          />
        </VisitsWrap>
      </Wrap>
    </TileItem>
  )
}

export default PlansAgentItem
