import React, {useMemo, useState} from 'react'
import {isEmpty, path, prop, propOr} from 'ramda'
import sumBy from 'helpers/sumBy'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'
import Form from 'components/Forms/Form'
import TilesList from 'components/Cards/Tile/TilesList'
import mapIndexed from 'helpers/mapIndexed'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import styled from 'styled-components'
import moment from 'moment'
import NoResults from 'components/NoData/NoResults'
import OrdersCreateForm from 'containers/Orders/Grid/Create/Grid/OrdersCreateForm'
import {onlyNumsAndSymbol} from 'helpers/onlyNumsAndSymbol'

const Wrap = styled('div')`
  padding: 14px 16px 0;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const OrdersCreateTabOne = props => {
  const {
    ordersCreate,
    products,
    setValue,
    setModalOpen,
    setSelectedProduct,
    addProductIdHandler,
    canIsLimited,
    customStore,
    mapPerms,
    isAdmin,
    configData,
    getFormValues
  } = props

  // useState
  const [tabValue, setTabValue] = useState(0)

  // Data
  const ordersCreateLoading = prop('loading', ordersCreate)
  const customStoreData = prop('data', customStore)

  // Const
  const me = path(['data', 'id'], customStore)
  const totalPrice = sumBy(products, ['total_price'])
  const stocks = propOr([], 'stocks', customStoreData)
  const primaryCurrencyName = prop('pRIMARYCURRENCY', configData)

  // InitialValues
  const initialValues = {
    deliveryType: 'delivery',
    paymentType: 'cash'
  }

  // Submit
  const handleSubmit = values => {
    const newMarket = tabValue === 1
    const dateDelivery = prop('dateDelivery', values)
    const deliveryMan = prop('deliveryMan', values)
    const deliveryType = prop('deliveryType', values)
    const secondPhone = prop('secondPhone', values)
    const isDelivery = deliveryType === 'delivery'

    const data = {
      new_market: newMarket,
      phone_number: newMarket ? prop('phoneNumber', values) : null,
      contact_name: newMarket ? prop('contactName', values) : null,
      second_phone: secondPhone && secondPhone.length > 7 ? onlyNumsAndSymbol(prop('secondPhone', values)) : null,
      market: !newMarket ? prop('market', values) : null,
      delivery_lat: isDelivery ? path(['location', 'lat'], values) : null,
      delivery_lon: isDelivery ? path(['location', 'lon'], values) : null,
      delivery_address: isDelivery ? prop('deliveryAddress', values) : null,
      stock: prop('stock', values),
      date_delivery: moment(dateDelivery).format('YYYY-MM-DD[T]HH:mm:ss'),
      payment_type: prop('paymentType', values),
      delivery_type: deliveryType,
      delivery_man: isDelivery ? deliveryMan : null,
      is_confirmed: prop('isConfirmed', values),
      comment: prop('comment', values),
      delivery_price: prop('deliveryPrice', values),
      dealType: 2,
      user: me,
      products: products
    }

    ordersCreate.onSubmit(data)
  }

  // Form
  const form =
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      getValues={getFormValues}
      keepDirtyOnReinitialize
      haveMutators
    >
      <OrdersCreateForm
        mapPerms={mapPerms}
        isAdmin={isAdmin}
        stocks={stocks}
        loading={ordersCreateLoading}
        totalPrice={totalPrice}
        primaryCurrencyName={primaryCurrencyName}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
    </Form>

  // ProductsMap
  const addButton = {
    text: 'Добавить',
    onClick: () => setValue(1)
  }
  const productsMap = useMemo(
    () => mapIndexed((item, index) => {
      const productId = prop('product', item)
      const productName = prop('product_name', item)
      const totalPrice = prop('total_price', item)
      const measurement = prop('measurement', item)
      const measurementName = path(['measurement', 'name'], item)
      const formattedMeasurement = measurementName && measurementName.slice(0, 3)
      const customPrice = prop('custom_price', item)
      const amount = prop('amount', item)
      const imageSrc = path(['image', 'file'], item)
      const image = prop('image', item)
      const realBalance = prop('balance', item)
      const limitBalance = prop('limitBalance', item)
      const balance = canIsLimited ? limitBalance : realBalance
      const boxes = prop('boxes', item)
      const transferPrice = prop('transfer_price', item)
      const cashPrice = prop('cash_price', item)

      const textAmount =
        <TextStatus
          bold
        >
          {numberFormat(amount, formattedMeasurement)}
        </TextStatus>
      const textPrice =
        <TextStatus
          bold
          status={'active'}
        >
          {numberFormat(totalPrice, primaryCurrencyName)}
          {' по '}
          {numberFormat(customPrice, primaryCurrencyName)}
        </TextStatus>

      const handleAddProductsOpen = () => {
        setModalOpen(true)
        setSelectedProduct({
          name: productName,
          amount: balance,
          measurement,
          image,
          boxes,
          transferPrice,
          cashPrice
        })
        addProductIdHandler(productId)
      }

      return (
        <ProductsItem
          name={productName}
          onClick={() => handleAddProductsOpen()}
          imageSrc={imageSrc}
          amount={textAmount}
          price={textPrice}
          key={index}
          isSelected
        />
      )
    // eslint-disable-next-line
    }, products), [products])

  // ProductsList
  const productsList =
    <>
      <TilesList
        title={'Товары'}
        textButton={addButton}
        style={{marginBottom: '55px', '& > h2': {padding: '0 16px'}}}
      >
        {!isEmpty(products) ? productsMap : <NoResults height={'200px'} text={'Добавьте товары'} />}
      </TilesList>
    </>

  // MainContent
  const mainContent =
    <>
      <Wrap>
        {form}
      </Wrap>
      {productsList}
    </>

  return (
    <>
      {mainContent}
    </>
  )
}

export default OrdersCreateTabOne
