import React, {useEffect, useState} from 'react'
import Container from 'components/Container'
import {
  filter,
  find,
  groupBy,
  isEmpty,
  length,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  propOr,
  reject,
  toPairs
} from 'ramda'
import {
  Trash2 as TrashIcon
} from 'react-feather'
import SwipeableViews from 'react-swipeable-views'
import Button from 'components/Forms/Button'
import {Modal} from 'components/Modal'
import Form from 'components/Forms/Form'
import styled from 'styled-components'
import BottomBar from 'components/Navigation/BottomBar'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {
  marketsReturnsProductsListSerializer
} from 'containers/Clients/actions/serializers/marketsSerializer'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import CardDescription from 'components/Cards/CardDescription'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import moment from 'moment'
import Switcher from 'components/Forms/Switcher'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField/UniversalSearchField'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField'
import sumBy from 'helpers/sumBy'
import {CreateBar} from 'components/Navigation/TopBar'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import {TextStatus} from 'components/Status/TextStatus'
import ModalConfirm from 'components/Modal/ModalConfirm'
import Loader from 'components/Loader'
import checkPermissions from 'helpers/checkPermissions'
import Perms from 'components/Perms/Perms'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'

const TabsWrap = styled('div')`
  height: calc(100vh - 100px);
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
`
const Content = styled('div')`
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  &.react-swipeable-view-container{
    height: auto !important;
  }
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const NewSales = styled('span')`
  display: flex;
  flex-flow: row nowrap;
`
const Wrap = styled('div')`
  padding: 14px 16px;
`
const WrapThree = styled('div')`
  padding: 14px 16px 0;
`
const ProductsThree = styled('div')`
  margin-top: 20px;
`
const SelectedTitle = styled('h2')`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px;
`
const SelectedProducts = styled('ul')`
  margin-bottom: 15px;
  & > * {
    padding: 12px 0;
    min-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
`

// Forms
const StepOneFormFields = (props) => {
  const {
    formValues,
    selectedFields,
    setSelectedFields,
    marketId,
    customStore,
    withOldBase,
    mapPerms,
    stocks
  } = props

  // Data
  const customStoreData = prop('data', customStore)
  const customStoreLoading = prop('loading', customStore)

  // Values
  const isOrderValue = prop('isOrder', formValues)
  const isDeliveryManValue = prop('isDeliveryMan', formValues)
  const returnFromOldBaseValue = prop('returnFromOldBase', formValues)

  // Const
  const currencies = propOr([], 'currencies', customStoreData)
  const priceList = propOr([], 'priceLists', customStoreData)
  const isAdmin = prop('isSuperuser', customStoreData)

  // Perms
  const hasPermsCanCash = checkPermissions(['can_cash'], mapPerms)
  const hasPermsCanBank = checkPermissions(['can_bank'], mapPerms)

  // Options
  const currenciesOptions = map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, currencies)
  const stocksOptions = map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, stocks)
  const priceListListOptions = map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, priceList)
  const paymentTypeOptions = [
    (hasPermsCanCash || isAdmin) && {value: 'cash', label: 'Наличные'},
    (hasPermsCanBank || isAdmin) && {value: 'bank', label: 'Перечисление'}
  ]
  const filteredPaymentTypeOptions = paymentTypeOptions && filter((i) => !!i === true)(paymentTypeOptions)
  const paymentTypesLength = length(filteredPaymentTypeOptions)

  return (
    <StyledWrap>
      {!(length(stocks) === 1 || length(stocks) === 0) &&
      <Field
        component={StaticSearchField}
        name={'stock'}
        onChange={(value, data) => {
          setSelectedFields({
            ...selectedFields,
            stock: {
              name: prop('name', data),
              id: prop('id', data)
            }
          })
        }}
        options={stocksOptions}
        label={'Склад для возврата'}
        required
        fullWidth
      />}
      {!(paymentTypesLength === 1 || paymentTypesLength === 0) &&
      <Field
        component={StaticSearchField}
        name={'paymentType'}
        label={'Тип оплаты'}
        options={filteredPaymentTypeOptions}
        onChange={(data) => {
          setSelectedFields({
            ...selectedFields,
            paymentType: {
              name: prop('label', data),
              id: prop('value', data)
            }
          })
        }}
        required
        fullWidth
      />}
      <Field
        component={Switcher}
        name={'isOrder'}
        type={'checkbox'}
        label={'Возврат по конкретному заказу'}
        disabled={returnFromOldBaseValue}
        fullWidth
      />
      {isOrderValue &&
      <>
        <Field
          component={UniversalSearchField}
          api={API.MARKETS_SALES_ORDERS_LIST}
          params={{page_size: '1000', market: marketId, exclude_cancelled: true}}
          name={'order'}
          label={'Номер заказа'}
          optionName={'orderNumber'}
          required
          fullWidth
        />
        <Field
          component={Switcher}
          name={'selectAllProducts'}
          type={'checkbox'}
          label={'Выбрать все товары по заказу'}
          fullWidth
        />
      </>}
      <Field
        component={Switcher}
        name={'isDeliveryMan'}
        type={'checkbox'}
        label={'Указать доставщика'}
        fullWidth
      />
      {isDeliveryManValue &&
      <Field
        component={UniversalSearchField}
        api={API.USERS_LIST_FOR_SELECT}
        params={{page_size: '1000', group: 'delivery'}}
        optionName={'firstName'}
        name={'deliveryMan'}
        label={'Доставщик'}
        required
        fullWidth
      />}
      {withOldBase &&
      <Perms
        perms={['can_see_add_return_from_old_base']}
      >
        <Field
          component={Switcher}
          name={'returnFromOldBase'}
          type={'checkbox'}
          label={'Возврат по старой базе'}
          disabled={isOrderValue}
          fullWidth
        />
      </Perms>}
      {returnFromOldBaseValue &&
      <>
        {length(currenciesOptions) !== 1 &&
        <Field
          component={StaticSearchField}
          name={'currency'}
          label={'Валюта'}
          loading={customStoreLoading}
          options={currenciesOptions}
          required
          fullWidth
        />}
        {length(priceListListOptions) !== 1 &&
        <Field
          component={StaticSearchField}
          name={'priceList'}
          label={'Прайс-лист'}
          loading={customStoreLoading}
          options={priceListListOptions}
          required
          fullWidth
        />}
      </>}
    </StyledWrap>
  )
}

// Form Step Two
const AddProductsFormFields = (props) => {
  const {
    productIdParam,
    permissions,
    isAdmin
  } = props

  const amountName = 'amount_' + productIdParam
  const costName = 'cost_' + productIdParam
  const customPriceName = 'customPrice_' + productIdParam
  const realValues = prop('values', props)
  const realCustomPriceName = prop(customPriceName, realValues)
  const hasPerms = checkPermissions(['can_set_any_price'], permissions)

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'number'}
        name={amountName}
        placeholder={'Количество'}
        required
        fullWidth
      />
      {(hasPerms || isAdmin) &&
      <Field
        component={Switcher}
        name={customPriceName}
        type={'checkbox'}
        label={'Указать стоимость в ручную'}
        fullWidth
      />}
      {
        realCustomPriceName &&
        <Field
          component={TextField}
          type={'number'}
          name={costName}
          placeholder={'Цена'}
          required
          fullWidth
        />
      }
      <Button
        styles={{
          marginTop: '5px !important',
          height: '50px'
        }}
        text={'Применить'}
        fullWidth
      />
    </StyledWrap>
  )
}
const ProductsSearch = styled('div')`
  right: 14px;
  z-index: 100;
  height: ${({value}) => (value === 1) ? '56px' : '0'};
  opacity: ${({value}) => (value === 1) ? 1 : 0};
  padding: ${({value}) => (value === 1) ? '10px 12px' : '0'};
  transition: all 0s;
  background: ${({theme}) => theme.nav.primary.background};
  border-bottom: 1px solid #e4e4e6;
`

const StepThreeFormFields = () => {
  return (
    <StyledWrap>
      <Field
        component={TextField}
        name={'comment'}
        label={'Комментарий'}
        fullWidth
      />
    </StyledWrap>
  )
}

const MarketsGrid = props => {
  const {
    goBack,
    query,
    match,
    marketsReturnsProductsList,
    addProductIdHandler,
    marketsReturnsCreatePreview,
    marketsReturnsCreate,
    marketsItem,
    customStore,
    config,
    filterActions
  } = props

  // UseStates
  const [value, setValue] = useState(0)
  const [products, setProducts] = useState([])
  const [selectedFields, setSelectedFields] = useState({})
  const [formValues, setFormValues] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmBackModalOpen, setConfirmBackModalOpen] = useState(false)

  // Data
  const marketsReturnsProductsListData = propOr([], 'data', marketsReturnsProductsList)
  const marketsReturnsProductsListLoading = prop('loading', marketsReturnsProductsList)
  const marketsReturnsCreatePreviewData = propOr([], 'data', marketsReturnsCreatePreview)
  const marketsReturnsCreatePreviewLoading = prop('loading', marketsReturnsCreatePreview)
  const marketsItemData = prop('data', marketsItem)
  const configData = prop('data', config)
  const createLoading = prop('loading', marketsReturnsCreate)

  // Constants
  const permissions = pathOr([], ['data', 'permissions'], customStore)
  const userPerms = map(prop('codename'))(permissions)
  const isAdmin = path(['data', 'isSuperuser'], customStore)
  const withOldBase = prop('cANRETURNFROMOLDBASE', configData)

  // Form validation
  const returnFromOldBaseValue = prop('returnFromOldBase', formValues)
  const formValuesPaymentType = prop('paymentType', formValues)
  const formValuesStock = prop('stock', formValues)
  const isOrder = prop('isOrder', formValues)
  const order = prop('order', formValues)
  const priceList = pathOr([], ['data', 'priceLists'], customStore)
  const currencies = pathOr([], ['data', 'currencies'], customStore)
  const stocks = pathOr([], ['data', 'stocks'], customStore)
  const currencyIdValue = prop('currency', formValues)
  const currencyValue = find(propEq('id', +currencyIdValue), currencies)
  const currencyNameValue = prop('name', currencyValue)
  const eqSwipe = formValuesPaymentType && formValuesStock && (!isOrder || (isOrder && order))
  const isOldBase = prop('returnFromOldBase', formValues)
  const isOrderValue = prop('isOrder', formValues)
  const selectAllProducts = prop('selectAllProducts', formValues)

  // useEffect
  useEffect(() => {
    if (selectAllProducts && isOrderValue && (value === 0)) {
      return setProducts(
        map(item => {
          return {
            product: `${prop('id', item)}`,
            amount: `${prop('sales', item)}`
          }
        }, marketsReturnsProductsListData)
      )
    } else if ((!selectAllProducts || !isOrderValue) && (value === 0)) {
      return setProducts([])
    }
  // eslint-disable-next-line
  }, [selectAllProducts, isOrderValue, marketsReturnsProductsListLoading])

  // Global constants
  const marketId = path(['params', 'id'], match)
  const productIdParam = prop('productId', query)
  const todayWithTime = moment().format('YYYY-MM-DD HH:mm')
  const marketName = prop('name', marketsItemData)
  const clientName = path(['client', 'name'], marketsItemData)
  const previewGroupedByCurrencies = pipe(
    groupBy(path(['currency', 'name'])),
    toPairs
  )(marketsReturnsCreatePreviewData)

  // totalCosts
  const totalCostContent = !isEmpty(previewGroupedByCurrencies) && mapIndexed((itemArr, index) => {
    const totalPrice = sumBy(prop('1', itemArr), ['totalPrice'])
    return (
      <li key={index}>
        <CardDescription
          it={'Сумма возврата'}
          is={
            <TextStatus bold status={'active'}>
              {numberFormat(totalPrice, prop('0', itemArr))}
            </TextStatus>
          }
        />
      </li>
    )
  }, previewGroupedByCurrencies)

  const selectedStock = path(['stock', 'name'], selectedFields)
  const selectedPaymentType = path(['paymentType', 'name'], selectedFields)

  // Perms
  const mapPerms = map((item) => {
    return prop('codename', item)
  }, permissions)
  const hasPermsCanCash = checkPermissions(['can_cash'], mapPerms)
  const hasPermsCanBank = checkPermissions(['can_bank'], mapPerms)

  // InitialValues
  const paymentTypeInitialValue = (isAdmin || hasPermsCanCash) ? 'cash' : (isAdmin || hasPermsCanBank) ? 'bank' : 'cash'
  const initialValuesObj = {
    paymentType: paymentTypeInitialValue,
    priceList: `${path(['0', 'id'], priceList)}`,
    currency: `${path(['0', 'id'], currencies)}`,
    stock: `${path(['0', 'id'], stocks)}`
  }

  // Tabs configs
  const handleChangeIndex = index => {
    setValue(index)
  }

  const onTransitionEnd = value => {
    if (value === 2) {
      const order = prop('isOrder', formValues) ? prop('order', formValues) : null

      marketsReturnsCreatePreview.onSubmit({
        market: marketId,
        products: products,
        comment: prop('comment', formValues),
        stock: prop('stock', formValues),
        paymentType: path(['paymentType'], formValues),
        order: order,
        priceList: 1,
        createdDate: todayWithTime,
        zeroBalances: false,
        currency: isOldBase ? prop('currency', formValues) : null,
        priceLists: isOldBase ? prop('priceLists', formValues) : null,
        returnFromOldBase: isOldBase
      })
    } else if (value === 0 && !isEmpty(products)) {
      setConfirmBackModalOpen(true)
    }
  }

  // Products forms
  const selectedProductsName = prop('name', selectedProduct)

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch
      value={value}
    >
      <DebounceSearch filterActions={filterActions} placeholder={'Название'} />
    </ProductsSearch>

  // Content
  const productsList = marketsReturnsProductsListData && mapIndexed((item) => {
    const productName = prop('name', item)
    const productId = prop('id', item)
    const isSelected = find(propEq('product', `${productId}`))(products)
    const selectedAmount = prop('amount', isSelected)
    const selectedCost = prop('cost', isSelected)
    const imageSrc = path(['image', 'file'], item)
    const sales = prop('sales', item)
    const measurementName = path(['measurement', 'name'], item).slice(0, 3)
    const selectedAmountStatus = (selectedAmount > sales) ? 'inactive' : 'active'
    const newSales =
      <NewSales>
        <TextStatus bold status={selectedAmountStatus}>{numberFormat(selectedAmount)}</TextStatus>&nbsp;из&nbsp;<TextStatus bold>{numberFormat(sales, measurementName)}</TextStatus>
      </NewSales>
    const selectedSales = selectedAmount ? newSales : !returnFromOldBaseValue ? numberFormat((sales), measurementName) : null
    const handleSetProductsOpen = () => {
      setModalOpen(true)
      addProductIdHandler(productId)
      setSelectedProduct({name: productName})
    }
    const price = selectedCost && <>Цена:<TextStatus bold status={'active'}>&nbsp;{numberFormat(selectedCost, currencyNameValue)}</TextStatus></>

    return (
      <ProductsItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        isSelected={isSelected}
        onClick={() => handleSetProductsOpen()}
        amount={selectedSales}
        price={price}
      />
    )
  }, marketsReturnsProductsListData)

  const contentOne =
    <Wrap>
      <StepOneFormFields
        formValues={formValues}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        marketId={marketId}
        customStore={customStore}
        withOldBase={withOldBase}
        mapPerms={mapPerms}
        stocks={stocks}
      />
    </Wrap>

  const contentTwo =
    <>
      <InfiniteScroll
        api={returnFromOldBaseValue ? API.MARKETS_PRODUCTS_LIST : API.MARKETS_RETURNS_PRODUCTS_LIST}
        actionType={actionTypes.MARKETS_RETURNS_PRODUCTS_LIST}
        emptyData={<Wrap><NoResults text={'Ни один заказ не доставлен, обратитесь к доставщику'} /></Wrap>}
        params={marketsReturnsProductsListSerializer(query, props, formValues)}
        style={{padding: '0 0 55px'}}
        list={productsList || []}
        useWindow={false}
      />
    </>

  const selectedProductsList = marketsReturnsCreatePreviewData && mapIndexed((item, index) => {
    const selectedProductName = path(['product', 'name'], item)
    const selectedProductAmount = prop('amount', item)
    const selectedProductMeasurement = path(['product', 'measurement', 'name'], item).slice(0, 3)
    const selectedProductTotalPrice = prop('totalPrice', item)
    const selectedProductCost = prop('cost', item)
    const selectedProductCurrency = path(['currency', 'name'], item)
    const priceText =
      <TextStatus bold status={'active'}>
        {numberFormat(selectedProductTotalPrice, selectedProductCurrency)}&nbsp;по&nbsp;
        {numberFormat(selectedProductCost, selectedProductCurrency)}
      </TextStatus>
    const selectedOrder = prop('order', item)
    const amountWithoutOrder =
      <TextStatus bold>{numberFormat(selectedProductAmount, selectedProductMeasurement)}</TextStatus>
    const amountWithOrder =
      <span>
        {amountWithoutOrder}&nbsp;по заказу&nbsp;
        <TextStatus bold>№{selectedOrder}</TextStatus>
      </span>

    return (
      <ProductsItem
        key={index}
        name={selectedProductName}
        amount={returnFromOldBaseValue ? amountWithoutOrder : amountWithOrder}
        price={priceText}
        isSelected
      />
    )
  }, marketsReturnsCreatePreviewData)

  const contentThree =
    <>
      <WrapThree>
        <SelectedTitle>Предпросмотр</SelectedTitle>
        <SelectedProducts>
          <li><CardDescription it={'Клиент'} is={marketName} /></li>
          <li><CardDescription it={'Клиент'} is={clientName} /></li>
          {selectedStock &&
          <li><CardDescription it={'Склад'} is={selectedStock} /></li>}
          {selectedPaymentType &&
          <li><CardDescription it={'Тип оплаты'} is={selectedPaymentType} /></li>}
          {totalCostContent}
        </SelectedProducts>
        <div>
          <StepThreeFormFields />
        </div>
      </WrapThree>
      <ProductsThree>
        {isEmpty(products)
          ? <NoResults height={'auto'} text={'Выберите продукты'} />
          : <ul>{marketsReturnsCreatePreviewLoading ? <Loader styles={{marginTop: '30px'}} /> : selectedProductsList}</ul>}
      </ProductsThree>
    </>

  // Buttons Configs
  const cancelButtonFunc = () => {
    (value >= 1) && setValue(value - 1)
  }
  const buttonFunc = () => {
    (value < 2) ? setValue(value + 1) : setConfirmModalOpen(true)
  }
  const backButton = (value !== 0) && cancelButtonFunc
  const buttonText = (value < 2) ? 'Далее' : 'Оформить'
  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productIdParam), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  // Submit
  const handleAddProductsSubmit = (values) => {
    const amountPlus = 'amount_' + productIdParam
    const costPlus = 'cost_' + productIdParam
    const customPriceName = 'customPrice_' + productIdParam
    const customPriceValue = prop(customPriceName, values)
    const costObj = customPriceValue && {cost: prop(costPlus, values)}
    const newObject = {
      product: productIdParam,
      amount: prop(amountPlus, values),
      ...costObj
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('amount', '0'), newProducts)
    setProducts(sortedProducts)

    setModalOpen(false)
  }

  const fromAndroid = prop('fromAndroid', query) && window.AndroidMaps

  const handleMainSubmit = values => {
    const isOldBase = prop('returnFromOldBase', formValues)

    marketsReturnsCreate.onSubmit({
      market: marketId,
      products: products,
      comment: prop('comment', values),
      stock: prop('stock', values),
      paymentType: path(['paymentType'], values),
      order: prop('order', values),
      priceList: 1,
      createdDate: todayWithTime,
      zeroBalances: false,
      deliveryMan: prop('deliveryMan', values),
      currency: isOldBase ? prop('currency', values) : null,
      priceLists: isOldBase ? prop('priceLists', values) : null,
      returnFromOldBase: isOldBase
    })
    fromAndroid && window.AndroidMaps.closeWebView(true)
  }

  // Render
  return (
    <>
      <CreateBar
        title={'Оформление возврата'}
        goBack={backButton}
        goExit={() => {
          goBack(marketId)
          fromAndroid && window.AndroidMaps.closeWebView(false)
        }}
      />
      <Container
        background={'#fff'}
        overflow={'hidden'}
        styles={{
          maxHeight: '100vh'
        }}
      >
        {debounceSearch}
        <Form
          onSubmit={handleMainSubmit}
          initialValues={initialValuesObj}
          getValues={setFormValues}
        >
          <TabsWrap>
            <SwipeableViews
              index={value}
              onChangeIndex={handleChangeIndex}
              onTransitionEnd={() => onTransitionEnd(value)}
              animateHeight
              disabled={!eqSwipe}
            >
              <Content value={value} index={0}>
                {contentOne}
              </Content>
              <Content value={value} index={1}>
                {contentTwo}
              </Content>
              <Content value={value} index={2}>
                {contentThree}
              </Content>
            </SwipeableViews>
            <BottomBar>
              <Button
                type={'button'}
                text={buttonText}
                onClick={() => buttonFunc()}
                fullWidth
                disabled={!eqSwipe}
              />
            </BottomBar>
            <ModalConfirm
              open={confirmModalOpen}
              isOpen={setConfirmModalOpen}
              message={'Для продолжения подтвердите возврат'}
              loading={createLoading}
              type={'submit'}
            />
            <ModalConfirm
              open={confirmBackModalOpen}
              isOpen={setConfirmBackModalOpen}
              message={'Это действие очистит список выбранных товаров'}
              onCancel={() => {
                setValue(1)
              }}
              onClick={() => {
                setConfirmBackModalOpen(false)
                setValue(0)
                if (selectAllProducts && isOrderValue) {
                  setProducts(
                    map(item => {
                      return {
                        product: `${prop('id', item)}`,
                        amount: `${prop('sales', item)}`
                      }
                    }, marketsReturnsProductsListData))
                } else {
                  setProducts([])
                }
              }}
              type={'clean'}
            />
          </TabsWrap>
        </Form>
      </Container>
      <Modal
        open={modalOpen}
        isOpen={setModalOpen}
        title={selectedProductsName || 'Добавление продукта'}
        secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={() => handleDeleteProduct()} />}
      >
        <Form
          onSubmit={handleAddProductsSubmit}
        >
          <AddProductsFormFields
            productIdParam={productIdParam}
            permissions={userPerms}
            isAdmin={isAdmin}
          />
        </Form>
      </Modal>
    </>
  )
}

export default MarketsGrid
