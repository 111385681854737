import styled from 'styled-components'

export const TextStatus = styled('span')`
  display: inline-block;
  color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : status === 'pending'
          ? theme.text.secondary
          : status === 'primary'
            ? theme.palette.blue
            : theme.palette.black
};
  font-weight: ${({bold}) => bold ? '500' : ''};
  ${({styles}) => styles}
`
