import React from 'react'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {CardFieldsFilter} from 'components/Cards/CardFieldsFilter'
import TextField from 'components/Forms/TextField'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField'
import {map, prop} from 'ramda'
import Switcher from 'components/Forms/Switcher'
import mapIndexed from 'helpers/mapIndexed'
import Radio from 'components/Forms/Radio'
import Label from 'components/Forms/Label'

const StyledWrap = styled('div')`
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`
const SwitcherWrap = styled('div')`
  border-top: ${({theme}) => theme.border.primary};
  padding-top: 10px;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = (props) => {
  const {
    stocks
  } = props

  const typeIncomeOptions = [
    {label: 'Поставка', value: 'supplies'},
    {label: 'Передача', value: 'transfers'},
    {label: 'Возврат', value: 'returns'}
  ]
  const stocksListOptions = stocks && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, stocks)
  const orderingOptions = [
    {value: 'date', label: 'Дата приёма'},
    {value: 'id', label: 'Номер приёма'},
    {value: 'accepted_by', label: 'Принявший'}
  ]

  return (
    <StyledWrap>
      <Field
        component={StaticSearchField}
        name={'stock'}
        label={'Склад'}
        type={'select'}
        options={stocksListOptions}
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'type'}
        label={'Тип'}
        type={'select'}
        options={typeIncomeOptions}
        fullWidth
      />
      <Field
        component={TextField}
        name={'beginDate'}
        type={'date'}
        label={'Период с'}
        fullWidth
      />
      <Field
        component={TextField}
        name={'endDate'}
        type={'date'}
        label={'Период до'}
        fullWidth
      />
      <SwitcherWrap>
        <Field
          component={Switcher}
          name={'withHistory'}
          type={'checkbox'}
          label={'Показывать историю'}
          fullWidth
        />
      </SwitcherWrap>
      <SwitcherWrap>
        <div>
          <Label styles={{marginBottom: '0'}}>Сортировка</Label>
          {mapIndexed((item, index) => {
            const value = prop('value', item)
            const name = prop('label', item)
            return (
              <Field
                key={index}
                component={Radio}
                styles={{padding: '16px 5px', '& > b': {right: '5px'}}}
                type={'radio'}
                name={'ordering'}
                label={name}
                value={value}
                required
              />
            )
          }, orderingOptions)}
        </div>
      </SwitcherWrap>
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
          fixed
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {setOpenFilter, onChangeFilter, initialValues, stocks} = props

  const newInitialValues = {
    ordering: 'date',
    ...initialValues
  }

  const handleSubmit = (values) => {
    setOpenFilter(false)
    const withHistory = prop('withHistory', values)
    onChangeFilter({
      stock: prop('stock', values),
      type: prop('type', values),
      beginDate: prop('beginDate', values),
      endDate: prop('endDate', values),
      ordering: prop('ordering', values),
      withHistory: withHistory || undefined
    })
  }

  return (
    <CardFieldsFilter>
      <Form
        initialValues={newInitialValues}
        onSubmit={handleSubmit}
      >
        <FormFields
          stocks={stocks}
        />
      </Form>
    </CardFieldsFilter>
  )
}

export default Filter
