import toNumber from './toNumber'
import {isEmpty, replace, toString} from 'ramda'

const ZERO = 0
const AFTER_ZERO = 3
const numberWithoutSpaces = (amount) => {
  if (amount) {
    const amountStringFormat = (typeof amount === 'number') ? toString(amount) : amount
    const first = replace('&nbsp;', '', replace(/\s/g, '', replace('.', ',', amountStringFormat)))
    if (isEmpty(first)) {
      return ZERO
    }
    return toNumber(first).toFixed(AFTER_ZERO)
  }
  return null
}

export default numberWithoutSpaces
