import React, {useMemo, useState} from 'react'
import moment from 'moment'
import {groupBy, isEmpty, map, path, pipe, prop, propOr, reverse, sortBy, toPairs} from 'ramda'
import {parseParams} from 'helpers/url'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from './StatisticsProductsTypesFilters'
import Loader from 'components/Loader'
import {MainBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import NoResults from 'components/NoData/NoResults'
import styled from 'styled-components'
import MainMenu from 'components/Navigation/components/MainMenu'
import sumBy from 'helpers/sumBy'
import TilesList from 'components/Cards/Tile/TilesList'
import mapIndexed from 'helpers/mapIndexed'
import ProgressBar from 'components/Charts/ProgressBar'
import {
  CornerDownRight as SubIcon,
  ChevronDown as ArrowDownIcon
} from 'react-feather'

const Wrap = styled('li')`
  display: flex;
  padding: 14px 16px;
  flex-flow: column nowrap;
  opacity: ${({hidden}) => hidden ? 0.35 : 1};
  transition: ${({theme}) => theme.transition.primary};
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const Div = styled('div')`
  position: fixed;
  height: 49px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e4e8;
  top: 61px;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${({theme}) => theme.nav.primary.background};
  & > *:not(:last-child) {
    border-right: ${({theme}) => theme.border.primary};
  }
`
const CustomButton = styled('div')`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({active}) => active ? 500 : 400};
  background: ${({active}) => active ? '#ffffff9e' : 'inherit'};
  cursor: pointer;
`
const GraphOptions = styled('li')`
  display: flex;
  flex-flow: column wrap;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.35px;
  color: #000;
  padding: 12px;
  &:not(:first-child){
    border-top: 1px solid #e0e4e8;
  }
`
const Option = styled('span')`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 18px;
  &:before{
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${props => props.color || '#ccc'};
    border-radius: 50%;
  }
`
const Title = styled('span')`
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme}) => theme.text.secondary};
  font-weight: ${({sub}) => !sub && 500};
  padding-bottom: 12px;
  padding-left: ${({sub}) => sub && '28px'};
`
const Graphs = styled('div')`
  margin-bottom: ${({active}) => active ? '12px' : '0'};
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`
const SubArrow = styled('span')`
  position: absolute;
  left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    width: 20px;
    height: 20px;
    stroke-width: 2px;
    color: ${({theme}) => theme.palette.green};
  }
`
const SubGraph = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;
  overflow: hidden;
  transition: ${({theme}) => theme.transition.primary};
  & > *:not(:last-child){
    margin-bottom: 5px;
  }
`
const ArrowWrap = styled('div')`
  position: relative;
  width: 100%;
  height: 10px;
  margin-bottom: 0 !important;
  margin-top: 5px;
  svg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: ${({open}) => open ? 'translate(-50%, -50%) scaleY(-1)' : 'translate(-50%, -50%)'};
    color: ${({theme}) => theme.text.secondary};
    transition: ${({theme}) => theme.transition.primary};
  }
`

const StatisticsProductsTypesGrid = props => {
  const {
    marketsStatisticsByProducts,
    config,
    filterActions,
    location,
    handleSetPeriod,
    handleSetProductTypeId
  } = props

  // useStates
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Data
  const marketsStatisticsByProductsData = propOr([], 'results', marketsStatisticsByProducts)
  const marketsStatisticsByProductsLoading = prop('loading', marketsStatisticsByProducts)
  const configData = prop('data', config)

  // Const
  const defaultCurrency = prop('pRIMARYCURRENCY', configData)
  const today = moment().format('YYYY-MM-DD')
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const beginDate = prop('beginDate', searchObj) || firstDayOfMonth
  const endDate = prop('endDate', searchObj) || lastDayOfMonth
  const isToday = (beginDate === today) && (endDate === today)
  const isMonth = (beginDate === firstDayOfMonth) && (endDate === lastDayOfMonth)
  const productTypeIdQuery = prop('productTypeId', searchObj)
  const groupedList = useMemo(
    () => pipe(
      groupBy(path(['type', 'id'])),
      toPairs(),
      map(item => {
        return {
          id: path(['1', '0', 'type', 'id'], item),
          typeName: path(['1', '0', 'type', 'name'], item),
          salesIncome: sumBy(prop('1', item), ['salesIncome']),
          products: [...prop('1', item)]
        }
      })
    )(marketsStatisticsByProductsData), [marketsStatisticsByProductsData])
  const sortedList = pipe(
    sortBy(prop('salesIncome')),
    reverse()
  )(groupedList)
  const maxProgress = path(['0', 'salesIncome'], sortedList)

  // InitialValues
  filterActions.initialValues = {
    beginDate: prop('beginDate', searchObj) || firstDayOfMonth,
    endDate: prop('endDate', searchObj) || lastDayOfMonth,
    search: prop('search', searchObj)
  }

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
      withOutButton
    >
      <Filter
        setFilterIsOpen={setFilterIsOpen}
        {...filterActions}
      />
    </FiltersBar>

  // Chart
  const chartList = mapIndexed((item) => {
    const id = prop('id', item)
    const typeName = prop('typeName', item)
    const salesIncome = prop('salesIncome', item)
    const products = prop('products', item)
    const isActive = +productTypeIdQuery === +id
    const handleClick = () => {
      if (isActive) {
        return handleSetProductTypeId('')
      } else {
        return handleSetProductTypeId(id)
      }
    }

    return (
      <TilesList
        key={id}
        onClick={handleClick}
      >
        <Wrap
          hidden={!isActive && productTypeIdQuery}
        >
          <div>
            <Title
              active={isActive}
            >
              {typeName}
            </Title>
            <Graphs>
              <ProgressBar
                maxProgress={isActive ? +salesIncome : +maxProgress}
                progressText={+salesIncome}
                progressSuffix={defaultCurrency}
                color={'#6770e6'}
              />
            </Graphs>
          </div>
          <ArrowWrap
            open={isActive}
          >
            <ArrowDownIcon />
          </ArrowWrap>
          {isActive &&
          <SubGraph>
            {map(subItem => {
              const id = prop('id', subItem)
              const productsName = prop('name', subItem)
              const productsSalesIncome = prop('salesIncome', subItem)

              return (
                <div
                  key={id}
                >
                  <Title
                    sub
                  >
                    <SubArrow><SubIcon /></SubArrow>
                    {productsName}
                  </Title>
                  <Graphs
                    active={isActive}
                  >
                    <ProgressBar
                      maxProgress={+salesIncome}
                      progressText={+productsSalesIncome}
                      progressSuffix={defaultCurrency}
                      color={'#fbb432'}
                    />
                  </Graphs>
                </div>
              )
            }, products)}
          </SubGraph>}
        </Wrap>
      </TilesList>
    )
  }, groupedList)

  // Period
  const period =
    <>
      <CustomButton
        active={isToday}
        onClick={() => handleSetPeriod(today, today)}
      >
        За день
      </CustomButton>
      <CustomButton
        active={isMonth}
        onClick={() => handleSetPeriod(firstDayOfMonth, lastDayOfMonth)}
      >
        За месяц
      </CustomButton>
      <CustomButton
        active={!isToday && !isMonth}
        onClick={() => setFilterIsOpen(true)}
      >
        Своя дата
      </CustomButton>
    </>

  // GraphInfo
  const graphInfo =
    <TilesList>
      <GraphOptions>
        <Option color={'#6770e6'}>Фактические продажи по типам</Option>
      </GraphOptions>
    </TilesList>

  // Content
  const mainContent =
    <>
      {graphInfo}
      {chartList}
    </>

  // Render
  return (
    <>
      <MainBar
        title={'Статистика типов продаж'}
        firstBtn={
          <MainMenu />
        }
      />
      <Container
        secondHeight={49}
      >
        <Div>
          {period}
        </Div>
        <Wrap>
          {marketsStatisticsByProductsLoading
            ? <Loader styles={{marginTop: '20px'}} />
            : isEmpty(marketsStatisticsByProductsData)
              ? <NoResults text={'Нет данных за этот период'} />
              : mainContent}
        </Wrap>
      </Container>
      {filters}
    </>
  )
}

export default StatisticsProductsTypesGrid
