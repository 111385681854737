import React, {useState} from 'react'
import Container from 'components/Container'
import {
  find,
  isEmpty,
  path,
  prop,
  propEq,
  reject,
  pipe,
  groupBy,
  toPairs,
  pathEq,
  map
} from 'ramda'
import {
  Check as CheckIcon, Minus as MinusIcon,
  Trash2 as TrashIcon
} from 'react-feather'
import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import NoResults from 'components/NoData/NoResults'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import mapIndexed from 'helpers/mapIndexed'
import {Modal} from 'components/Modal'
import numberFormat from 'helpers/numberFormat'
import {Field} from 'react-final-form'
import Form from 'components/Forms/Form'
import {TextStatus} from 'components/Status/TextStatus'
import {CreateBar} from 'components/Navigation/TopBar'
import BottomBar from 'components/Navigation/BottomBar'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import sumBy from 'helpers/sumBy'
import {Table} from 'components/Table'
import ProductSupplyItem from 'containers/Stocks/components/ProductSupplyItem/ProductSupplyItem'
import normalizeNumber from 'helpers/normalizers/normalizeNumber'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'
import moment from 'moment'
import Loader from 'components/Loader'

const TabsWrap = styled('div')`
  height: calc(100vh - 100px);
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
`
const Content = styled('div')`
  min-height: ${({value}) => (value === 1) ? 'calc(100vh - 165px)' : 'calc(100vh - 110px)'};
  max-height: ${({value}) => (value === 1) ? 'calc(100vh - 165px)' : 'calc(100vh - 110px)'};
  overflow-y: auto;
  &.react-swipeable-view-container{
    height: auto !important;
  }
`
const StyledWrap = styled('div')`
  ${({styles}) => styles};
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const SelectAllLabel = styled('p')`
  padding-right: 30px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  flex-flow: row;
`
const Wrap = styled('div')`
  padding: 14px 16px 64px;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const CheckAll = styled('div')`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30px;
  height: 30px;
  & > div{
    background: ${({disabled}) => disabled ? '#ebebec' : 'transparent'};
    box-shadow: inset 0 0 3px 0 #ccc;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    content: "";
    display: flex;
    justify-content: center;
    align-content: center;
  }
  svg{
    color: ${({theme, disabled}) => disabled ? theme.palette.lightGrey : theme.palette.blue};
    margin-top: 4px;
    stroke-width: 4px;
  }
`

const StocksIncomeSupplyDetailGrid = (props) => {
  const {
    list,
    match,
    searchObj,
    goBack,
    addProductIdHandler,
    supplyIncomeConfirmModal
  } = props

  // UseStates
  const [value, setValue] = useState(0)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Redirects
  const stockDetailId = path(['params', 'id'], match)
  const productIdParam = prop('productId', searchObj)

  // Data
  const data = prop('data', list)
  const dataLoading = prop('loading', list)

  // Const
  const id = prop('id', data) || ''
  const orderNumber = prop('orderNumber', data) || ''
  const productsArr = prop('products', data)
  const sortedBy = productsArr && pipe(
    groupBy(path(['product', 'id'])),
    toPairs
  )(productsArr)
  const productsListAmount = prop('length', sortedBy)
  const amountName = 'amount_' + productIdParam
  const defectAmountName = 'defectAmount_' + productIdParam
  const onAcceptAll = () => {
    const newArray = map((item) => {
      return {
        product: `${path(['product', 'id'], item)}`,
        product_name: path(['product', 'name'], item),
        amount: +(prop('amount', item)),
        supply_product: prop('id', item),
        defect_amount: '0'
      }
    })(productsArr)
    const newProducts = [...newArray]
    setProducts(newProducts)
    if (products.length === productsArr.length) {
      setProducts([])
    }
  }

  const sortedProducts = productsArr && pipe(
    groupBy(path(['product', 'measurement', 'name'])),
    toPairs
  )(productsArr)
  const stockName = path(['stock', 'name'], data)
  const acceptedTime = prop('acceptedTime', data)
  const type = prop('type', searchObj)

  // Title types
  const checkType = () => {
    switch (type) {
    case 'supply': return 'Поставка'
    case 'transfer': return `Передача ${id}`
    case 'delivery_return': return 'Возврат от поставщика'
    case 'order_return': return 'Возврат'
    case 'order': return `Заказ ${orderNumber}`
    case 'supply_return': return 'Возврат с П.'
    default: return null
    }
  }

  // InitialValues
  const customAmountName = 'amount_' + productIdParam
  const customDefectAmountName = 'defectAmount_' + productIdParam
  const productInitial = productsArr && find(pathEq(['product', 'id'], +productIdParam))(productsArr)
  const initialValuesProducts = {
    [customAmountName]: numberFormat(prop('amount', productInitial)),
    [customDefectAmountName]: numberFormat(prop('defectAmount', productInitial)) || '0'
  }
  const totalAmount = productsArr && sumBy(productsArr, ['amount'])

  // Slide One
  const contentOne =
    <Wrap>
      <TilesList
        title={'Подробности'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Склад'} is={stockName} />
        </TileItem>
        {acceptedTime &&
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Время закрытия'} is={moment(acceptedTime).format('DD MMM YYYY HH:mm')} />
        </TileItem>}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во видов товаров'} is={numberFormat(productsListAmount, 'ед')} />
        </TileItem>
        {sortedProducts && mapIndexed((item, index) => {
          const sortName = prop('0', item)
          const arr = prop('1', item)
          const amountSum = sumBy(arr, ['amount'])

          return (
            <TileItem
              height={'auto'}
              key={index}
            >
              <CardDescription it={'Кол-во товаров'} is={numberFormat(amountSum, sortName.slice(0, 3))} />
            </TileItem>
          )
        }, sortedProducts)}
      </TilesList>
      <TilesList
        title={'Список товаров'}
      >
        <TileItem
          height={'auto'}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
              </tr>
              {productsArr && mapIndexed((item, index) => {
                const productName = path(['product', 'name'], item)
                const amount = prop('amount', item)
                const measurement = (path(['product', 'measurement', 'name'], item)).slice(0, 3)

                return (
                  <tr key={index}>
                    <td colSpan={'2'}>{productName}</td>
                    <td>{numberFormat(amount, measurement)}</td>
                  </tr>
                )
              }, productsArr)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(totalAmount)}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
    </Wrap>

  // Slide Two
  const productsList = productsArr && mapIndexed((item) => {
    const productName = path(['product', 'name'], item)
    const productId = path(['product', 'id'], item)
    const isSelected = find(propEq('product', `${productId}`))(products)
    const selectedCustomDefectAmount = numberWithoutSpaces(prop('defect_amount', isSelected))
    const selectedAmount = numberWithoutSpaces(prop('amount', isSelected))
    const imageSrc = path(['image', 'file'], item)
    const measurementName = path(['product', 'measurement', 'name'], item).slice(0, 3)
    const amount = prop('amount', item)
    const defectAmount = prop('defectAmount', item)
    const selectedAmountStatus = (+selectedAmount >= +amount) ? 'active' : 'inactive'

    // Prices more info
    const handleAddProductsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({name: productName, defect_amount: 0, priceNumber: 0})
      addProductIdHandler(productId)
    }
    const amountFrom =
      <span>
        <TextStatus
          status={selectedAmountStatus}
          bold
        >
          {numberFormat(selectedAmount, measurementName)}
        </TextStatus>
        {' из '}
        <TextStatus
          bold
        >
          {numberFormat(amount, measurementName)}
        </TextStatus>
      </span>
    const textAmount = isSelected ? amountFrom : <TextStatus>{numberFormat(amount, measurementName)}</TextStatus>
    const textDefectAmount = isSelected ? numberFormat(selectedCustomDefectAmount, measurementName) : numberFormat(defectAmount, measurementName)

    return (
      <ProductSupplyItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        onClick={() => handleAddProductsOpen()}
        isSelected={isSelected}
        amount={textAmount}
        defectAmount={textDefectAmount}
        validAmount={+amount}
      />
    )
  }, productsArr)

  const contentTwo =
    <Wrap>
      <TilesList
        title={'Выберите товары'}
      >
        <TileItem
          height={'auto'}
        >
          <SelectAllLabel
            onClick={onAcceptAll}
          >
            Выбрать всё
          </SelectAllLabel>
          <CheckAll
            onClick={onAcceptAll}
          >
            <div>
              {isEmpty(products)
                ? null
                : (productsArr.length === products.length)
                  ? <CheckIcon size={16} />
                  : <MinusIcon size={16} />}
            </div>
          </CheckAll>
        </TileItem>
        {productsList}
      </TilesList>
    </Wrap>
  // products
  // productsArr

  // Main button
  const buttonFunc = () => {
    (value < 1) ? setValue(value + 1) : setConfirmModalOpen(true)
  }
  const cancelButtonFunc = () => {
    (value !== 0) &&
    setValue(value - 1)
  }
  const buttonText = (value === 1) ? 'Отправить' : 'Далее'
  const backButton = (value !== 0) && cancelButtonFunc

  // Tabs configs
  const handleChangeIndex = index => {
    setValue(index)
  }

  // Submits
  const handleAddProductsSubmit = (values) => {
    const amountPlus = 'amount_' + productIdParam
    const defectAmountPlus = 'defectAmount_' + productIdParam
    const newObject = {
      product: productIdParam,
      product_name: prop('name', selectedProduct),
      supply_product: prop('id', selectedProduct),
      amount: prop(amountPlus, values),
      defect_amount: prop(defectAmountPlus, values)
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('amount', '0'), newProducts)
    setProducts(sortedProducts)
    setModalOpen(false)
  }

  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productIdParam), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  const handleMainSubmit = (values) => {
    const dataSerializer = {
      products: products.map((i) => ({
        amount: numberWithoutSpaces(prop('amount', i)),
        defect_amount: numberWithoutSpaces(prop('defect_amount', i)),
        supply_product: prop('supply_product', i)
      })),
      id: stockDetailId,
      provider: path(['provider', 'id'], data),
      contact: path(['contact', 'id'], data),
      currency: path(['currency', 'id'], data),
      stock: path(['stock', 'id'], data),
      date: moment(prop('date', values)).format('YYYY-MM-DD HH:MM')
    }
    supplyIncomeConfirmModal.onSubmit(dataSerializer)
  }

  // Modals
  const modals =
    <>
      <Modal
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        title={'Выберите дату'}
      >
        <StyledWrap>
          <Field
            component={TextField}
            type={'date'}
            label={'Дата'}
            name={'date'}
            required
            fullWidth
          />
          <Button
            disabled={dataLoading}
            type={'submit'}
            text={'Применить'}
            fullWidth
          />
        </StyledWrap>
      </Modal>
    </>

  const addProductsModal =
    <Modal
      open={modalOpen}
      isOpen={setModalOpen}
      title={prop('name', selectedProduct) || 'Добавление продукта'}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={() => handleDeleteProduct()} />}
    >
      <Form
        onSubmit={handleAddProductsSubmit}
        keepDirtyOnReinitialize
        initialValues={initialValuesProducts}
      >
        <StyledWrap>
          <Field
            component={TextField}
            type={'text'}
            name={amountName}
            parse={normalizeNumber}
            label={'Принято'}
            required
            fullWidth
          />
          <Field
            component={TextField}
            type={'text'}
            parse={normalizeNumber}
            name={defectAmountName}
            label={'Брак'}
            required
            fullWidth
          />
          <Button
            text={'Применить'}
            fullWidth
          />
        </StyledWrap>
      </Form>
    </Modal>

  const formContent =
    <>
      <TabsWrap>
        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Content value={value} index={0}>
            {dataLoading ? <Loader styles={{marginTop: '20px'}} /> : data ? contentOne : <NoResults />}
          </Content>
          <Content value={value} index={1}>
            {dataLoading ? <Loader styles={{marginTop: '20px'}} /> : data ? contentTwo : <NoResults />}
          </Content>
        </SwipeableViews>
        <BottomBar>
          <Button
            type={'button'}
            text={buttonText}
            onClick={() => buttonFunc()}
            fullWidth
          />
        </BottomBar>
        {modals}
      </TabsWrap>
    </>

  // Render
  return (
    <>
      <CreateBar
        title={checkType()}
        goBack={backButton}
        goExit={() => goBack()}
      />
      <Container>
        <Form
          onSubmit={handleMainSubmit}
        >
          {formContent}
        </Form>
        {addProductsModal}
      </Container>
    </>
  )
}

export default StocksIncomeSupplyDetailGrid
