import React from 'react'
import {Field} from 'react-final-form'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {filter, map, pipe, prop, toPairs} from 'ramda'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const FormFields = () => {
  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'date'}
        name={'beginDate'}
        label={'Период до'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDate'}
        label={'Период с'}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    setFilterIsOpen,
    onChangeFilter,
    initialValues,
    loading
  } = props

  // Submit
  const handleSubmit = values => {
    const trueDivisions = pipe(
      filter((item) => !!item),
      toPairs,
      map((item) => {
        const key = prop('0', item)
        if (key.startsWith('division_')) {
          return key
        }
        return false
      }),
      filter((item) => !!item),
      map((item) => {
        return item.split('division_')[1]
      })
    )(values)

    setTimeout(() => {
      onChangeFilter({
        beginDate: prop('beginDate', values),
        endDate: prop('endDate', values),
        trueDivisions
      })
      setFilterIsOpen(false)
    }, 0)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormFields
        loading={loading}
      />
    </Form>
  )
}

export default Filter
