import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import moment from 'moment'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
  p{
    font-size: 15px;
    line-height: 18px;
    color: ${({theme}) => theme.text.secondary};
  }
`
const Date = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const RightBar = styled(Bar)`
  align-items: flex-end;
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`

const ChecksItem = props => {
  const {
    onClick,
    number,
    who,
    price,
    priceSuffix,
    date
  } = props

  return (
    <>
      <TileItem
        onClick={onClick && onClick}
        styles={styles}
      >
        <LeftBar>
          <h3>Чек №{number}</h3>
          <p>{who || 'Не известно'}</p>
        </LeftBar>
        <RightBar>
          <TextStatus bold status={price < 0 ? 'inactive' : 'active'}>{numberFormat(price, priceSuffix) || 'Не определён'}</TextStatus>
          <Date>
            {date
              ? moment(date).format('DD MMM YYYY, HH:mm')
              : 'Открыт'}
          </Date>
        </RightBar>
        <ArrowWrap>
          <Arrow />
        </ArrowWrap>
      </TileItem>
    </>
  )
}

ChecksItem.propTypes = {
  onClick: PropTypes.func,
  number: PropTypes.any,
  who: PropTypes.string,
  price: PropTypes.any,
  priceSuffix: PropTypes.string,
  date: PropTypes.string
}

export default ChecksItem
