import * as ROUTES from 'constants/routes'
import {storageData} from 'helpers/storage'
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import Layout from 'Layout'
import NotFound from 'containers/NotFound'
import SignInContainer from 'containers/SignIn'
import MarketsContainer from 'containers/Clients'
import MarketsDetailContainer from 'containers/Clients/Grid/Detail'
import MarketsDescriptionContainer from 'containers/Clients/Grid/Description/MarketsDescriptionContainer'
import MarketsOrdersContainer from 'containers/Clients/Grid/Orders'
import MarketsOrdersDetailContainer from 'containers/Clients/Grid/OrdersDetail'
import MarketsReturnsContainer from 'containers/Clients/Grid/Returns'
import MarketsReturnsDetailContainer from 'containers/Clients/Grid/ReturnsDetail/MarketsReturnsDetailContainer'
import MarketsReturnsCreateContainer from 'containers/Clients/Grid/ReturnsCreate/MarketsReturnsCreateContainer'
import MarketsMapContainer from 'containers/Clients/Grid/Map/MarketsMapContainer'
import MarketsPointsContainer from 'containers/Clients/Grid/Points/MarketsPointsContainer'
import MarketsPaymentsCreateContainer from 'containers/Clients/Grid/PaymentsCreate/MarketsPaymentsCreateContainer'
import MarketsBalancesContainer from 'containers/Clients/Grid/Balances/MarketsBalancesContainer'
import MarketsOrdersCreateContainer from 'containers/Orders/Grid/Create/OrdersCreateContainer'
import AcceptCashClientTransactionsContainer from 'containers/Finances/Grid/AcceptCashClientTransactions/AcceptCashClientTransactionsContainer'
import FinancesContainer from 'containers/Finances/FinancesContainer'
import FinancesTransactionsContainer from 'containers/Finances/Grid/Transactions/FinancesTransactionsContainer'
import FinancesAcceptCashContainer from 'containers/Finances/Grid/AcceptCash/FinancesAcceptCashContainer'
import MarketsCreateContainer from '../containers/Clients/Grid/MarketsCreate/MarketsCreateContainer'
import MarketsVisitsCreateContainer from 'containers/Clients/Grid/VisitsCreate/MarketsVisitsCreateContainer'
import MarketsReportsCreateContainer from 'containers/Clients/Grid/ReportsCreate/MarketsReportsCreateContainer'
import MarketsContractsContainer from 'containers/Clients/Grid/Contracts/MarketsContractsContainer'
import MarketsTelegramTelegramsCreateContainer
  from 'containers/Clients/Grid/TelegramsCreate/MarketsTelegramCreateContainer'
import MarketsTelegramsContainer from 'containers/Clients/Grid/Telegrams/MarketsTelegramsContainer'
import MarketsContractsCreateContainer from 'containers/Clients/Grid/ContractsCreate/MarketsContractsCreateContainer'
import MarketsStatisticsByProductsContainer from 'containers/Clients/Grid/StatisticsByProducts/MarketsStatisticsByProductsContainer'
import RequestFundsContainer from 'containers/RequestFunds/RequestFundsContainer'
import RequestFundsCreateContainer
  from 'containers/RequestFunds/Grid/RequestFundsCreate/RequestFundsCreateContainer'
import MarketsVisitsContainer from 'containers/Clients/Grid/Visits/MarketsVisitsContainer'
import StatisticsContainer from 'containers/Statistics/containers/Main/StatisticsContainer'
import BalanceNotificationCreateContainer
  from 'containers/Clients/Grid/TasksCreate/TasksCreateContainer'
import CarsContainer from 'containers/Cars/CarsContainer'
import CarsCreateContainer from 'containers/Cars/Grid/Create/CarsCreateContainer'
import CarsDetailContainer from 'containers/Cars/Grid/Detail/CarsDetailContainer'
import CarsDescriptionContainer from 'containers/Cars/Grid/Description/CarsDescriptionContainer'
import CarsAddProductsContainer from 'containers/Cars/Grid/AddProducts/CarsAddProductsContainer'
import CarsAddServicesContainer from 'containers/Cars/Grid/AddServices/CarsAddServicesContainer'
import CarsOrdersContainer from 'containers/Cars/Grid/Orders/CarsOrdersContainer'
import FinancesTransferCashCreateContainer
  from '../containers/Finances/Grid/TransferCashCreate/FinancesTransferCashCreateContainer'
import CarsOrdersDetailContainer from 'containers/Cars/Grid/OrdersDetail/CarsOrdersDetailContainer'
import CarsOrdersDeleteContainer from 'containers/Cars/Grid/OrdersDelete/CarsOrdersDeleteContainer'
import NotificationContainer from '../containers/Notification/NotificationContainer'
import AcceptCashClientTransactionCreateContainer
  from '../containers/Finances/Grid/AcceptCashClientTransactionCreate/AcceptCashClientTransactionCreateContainer'
import MarketsMerchReportsContainer from 'containers/Clients/Grid/Reports/MarketsReportsContainer'
import MarketsMerchReportsDetailContainer
  from 'containers/Clients/Grid/Reports/Grid/Detail/MarketsReportsDetailContainer'
import ChecksContainer from 'containers/Checks/ChecksContainer'
import ChecksDetailContainer from 'containers/Checks/Grid/ChecksDetail/ChecksDetailContainer'
import DebtsContainer from 'containers/Debts/DebtsContainer'
import DebtsDetailContainer from 'containers/Debts/Grid/DebtsDetail/DebtsDetailContainer'
import StatisticsPosContainer from 'containers/StatisticsPos/StatisticsPosContainer'
import OrderDetailAddBonusContainer from 'containers/Clients/Grid/OrderAddBonus/OrderDetailAddBonusContainer'
import PlansContainer from 'containers/Plans/PlansContainer'
import PlansDetailContainer from 'containers/Plans/Grid/Detail/PlansDetailContainer'
import StocksSelectionsContainer from 'containers/Stocks/containers/Selections/StocksSelectionsContainer'
import StocksSelectionsCreateContainer
  from 'containers/Stocks/containers/Selections/Grid/Create/StocksSelectionsCreateContainer'
import StocksSelectionsDetailContainer
  from 'containers/Stocks/containers/Selections/Grid/Detail/StocksSelectionsDetailContainer'
import StocksIncomeContainer from 'containers/Stocks/containers/Income/StocksIncomeContainer'
import StocksOutcomeContainer from 'containers/Stocks/containers/Outcome/StocksOutcomeContainer'
import StocksDetailContainer from 'containers/Stocks/subContainers/Detail'
import StocksIncomeSupplyDetailContainer
  from 'containers/Stocks/containers/Income/Grid/SupplyDetail/StocksIncomeSupplyDetailContainer'
import FinancesCreditsDebitsCreateContainer
  from 'containers/Finances/Grid/CreditsDebitsCreate/FinancesCreditsDebitsCreateContainer'
import StocksRestsContainer from 'containers/Stocks/containers/Rests/StocksRestsContainer'
import ProductionsBatchesContainer from 'containers/Productions/containers/Batches/ProductionsBatchesContainer'
import ProductionsBatchesCreateStepOneContainer from 'containers/Productions/containers/Batches/Grid/Create/StepOne/ProductionsBatchesCreateStepOneContainer'
import ProductionsBatchesCreateStepTwoContainer from 'containers/Productions/containers/Batches/Grid/Create/StepTwo/ProductionsBatchesCreateStepTwoContainer'
import ProductionsBatchesCreateStepThreeContainer from 'containers/Productions/containers/Batches/Grid/Create/StepThree/ProductionsBatchesCreateStepThreeContainer'
import ProductionsBatchesDetailContainer
  from 'containers/Productions/containers/Batches/Grid/Detail/ProductionsBatchesDetailContainer'
import BatchesAddProductsContainer
  from 'containers/Productions/containers/Batches/Grid/AddProducts/BatchesAddProductsContainer'
import BatchesAddMaterialsContainer
  from 'containers/Productions/containers/Batches/Grid/AddMaterials/BatchesAddMaterialsContainer'
import StatisticsByAgentsContainer from 'containers/Statistics/containers/ByAgents/StatisticsByAgentsContainer'
import TrackContainer from 'containers/Track/TrackContainer'
import ProductionsBatchesEditContainer
  from 'containers/Productions/containers/Batches/Grid/Edit/ProductionsBatchesEditContainer'
import MarketsOrdersEditContainer from 'containers/Clients/Grid/OrdersEdit/MarketsOrdersEditContainer'
import StatisticsByDebtsContainer from 'containers/Statistics/containers/ByDebts/StatisticsByDebtsContainer'
import ProfileContainer from 'containers/Profile/ProfileContainer'
import MarketsReportsEditContainer from 'containers/Clients/Grid/ReportsEdit/MarketsReportsEditContainer'
import OrdersContainer from 'containers/Orders/OrdersContainer'
import ProductsContainer from 'containers/Products/ProductsContainer'
import ProductsDetailContainer from 'containers/Products/Grid/Detail/ProductsDetailContainer'
import ProductsCreateContainer from 'containers/Products/Grid/Create/ProductsCreateContainer'
import ProductsEditContainer from 'containers/Products/Grid/Edit/ProductsEditContainer'
import ProductsTypesContainer from 'containers/ProductsTypes/ProductsTypesContainer'
import ProductsTypesCreateContainer from 'containers/ProductsTypes/Grid/Create/ProductsTypesCreateContainer'
import ProductsTypesEditContainer from 'containers/ProductsTypes/Grid/Edit/ProductsTypesEditContainer'
import BranchesContainer from 'containers/Branches/BranchesContainer'
import BranchesDetailContainer from 'containers/Branches/Grid/Detail/BranchesDetailContainer'
import BranchesMapContainer from 'containers/Branches/Grid/Map/BranchesMapContainer'
import BranchesCreateContainer from 'containers/Branches/Grid/Create/BranchesCreateContainer'
import BranchesEditContainer from 'containers/Branches/Grid/Edit/BranchesEditContainer'
import UsersContainer from 'containers/Users/UsersContainer'
import UsersDetailContainer from 'containers/Users/Grid/Detail/UsersDetailContainer'
import UsersCreateContainer from 'containers/Users/Grid/Create/UsersCreateContainer'
import UsersEditContainer from 'containers/Users/Grid/Edit/UsersEditContainer'
import DeliveriesBranchesContainer from 'containers/Clients/Grid/Branches/DeliveriesBranchesContainer'
import DeliveriesBranchesDetailContainer from 'containers/Clients/Grid/BranchesDetail/DeliveriesBranchesDetailContainer'
import StatisticsProductsTypesContainer from 'containers/Statistics/containers/ProductsTypes/StatisticsProductsTypesContainer'
import SettingsBotContainer from 'containers/SettingsBot/SettingsBotContainer'
import ExtensionsContainer from 'containers/Extensions/ExtensionsContainer'

// Is auth user
export const userIsAuth = connectedRouterRedirect({
  authenticatedSelector: () => {
    const token = storageData('token').getValue()
    return token && true
  },
  redirectPath: ROUTES.SIGN_IN_PATH,
  wrapperDisplayName: 'UserIsAuthenticated'
})

export default [
  // Sign in
  {
    path: ROUTES.SIGN_IN_PATH,
    layout: Layout,
    component: SignInContainer
  },
  // Clients
  {
    path: ROUTES.MARKETS_PATH,
    layout: Layout,
    component: userIsAuth(MarketsContainer),
    routes: [
      {
        path: ROUTES.MARKETS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(MarketsDetailContainer)
      },
      {
        path: ROUTES.MARKETS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_DETAIL_DESCRIPTION_PATH,
        layout: Layout,
        component: userIsAuth(MarketsDescriptionContainer)
      },
      {
        path: ROUTES.MARKETS_ITEM_MAP_PATH,
        layout: Layout,
        component: userIsAuth(MarketsMapContainer)
      },
      {
        path: ROUTES.MARKETS_ORDERS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsOrdersContainer)
      },
      {
        path: ROUTES.MARKETS_ORDERS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(MarketsOrdersDetailContainer)
      },
      {
        path: ROUTES.MARKETS_ORDERS_BONUS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(OrderDetailAddBonusContainer)
      },
      {
        path: ROUTES.MARKETS_ORDERS_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(MarketsOrdersEditContainer)
      },
      {
        path: ROUTES.MARKETS_RETURNS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsReturnsContainer)
      },
      {
        path: ROUTES.MARKETS_RETURNS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(MarketsReturnsDetailContainer)
      },
      {
        path: ROUTES.MARKETS_RETURNS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsReturnsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_POINTS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsPointsContainer)
      },
      {
        path: ROUTES.MARKETS_PAYMENTS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsPaymentsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_ITEM_BALANCES_PATH,
        layout: Layout,
        component: userIsAuth(MarketsBalancesContainer)
      },
      {
        path: ROUTES.MARKETS_ITEM_BALANCES_NOTIFICATION_PATH,
        layout: Layout,
        component: userIsAuth(BalanceNotificationCreateContainer)
      },
      {
        path: ROUTES.MARKETS_VISITS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsVisitsContainer)
      },
      {
        path: ROUTES.MARKETS_VISITS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsVisitsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_REPORTS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsReportsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_REPORTS_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(MarketsReportsEditContainer)
      },
      {
        path: ROUTES.MARKETS_CONTRACTS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsContractsContainer)
      },
      {
        path: ROUTES.MARKETS_CONTRACTS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsContractsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_TELEGRAMS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsTelegramsContainer)
      },
      {
        path: ROUTES.MARKETS_TELEGRAM_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsTelegramTelegramsCreateContainer)
      },
      {
        path: ROUTES.MARKETS_STATISTICS_MAIN_BY_PRODUCTS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsStatisticsByProductsContainer)
      },
      {
        path: ROUTES.MARKETS_REPORTS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsMerchReportsContainer)
      },
      {
        path: ROUTES.MARKETS_REPORTS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(MarketsMerchReportsDetailContainer)
      }
    ]
  },
  // Statistics main
  {
    path: ROUTES.STATISTICS_MAIN_PATH,
    layout: Layout,
    component: userIsAuth(StatisticsContainer),
    routes: [
      {
        path: ROUTES.STATISTICS_MAIN_ORDERS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsOrdersContainer)
      },
      {
        path: ROUTES.STATISTICS_MAIN_RETURNS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsReturnsContainer)
      },
      {
        path: ROUTES.STATISTICS_MAIN_BALANCES_PATH,
        layout: Layout,
        component: userIsAuth(MarketsBalancesContainer)
      },
      {
        path: ROUTES.STATISTICS_MAIN_BY_PRODUCTS_PATH,
        layout: Layout,
        component: userIsAuth(MarketsStatisticsByProductsContainer)
      }
    ]
  },
  // Statistics main
  {
    path: ROUTES.STATISTICS_BY_AGENTS_PATH,
    layout: Layout,
    component: userIsAuth(StatisticsByAgentsContainer)
  },
  // Statistics by debts
  {
    path: ROUTES.STATISTICS_DEBTS_PATH,
    layout: Layout,
    component: userIsAuth(StatisticsByDebtsContainer)
  },
  // Statistics by debts
  {
    path: ROUTES.STATISTICS_PRODUCTS_TYPES_PATH,
    layout: Layout,
    component: userIsAuth(StatisticsProductsTypesContainer)
  },
  // Cars
  {
    path: ROUTES.CARS_PATH,
    layout: Layout,
    component: userIsAuth(CarsContainer),
    routes: [
      {
        path: ROUTES.CARS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(CarsCreateContainer)
      },
      {
        path: ROUTES.CARS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(CarsDetailContainer)
      },
      {
        path: ROUTES.CARS_DESCRIPTION_PATH,
        layout: Layout,
        component: userIsAuth(CarsDescriptionContainer)
      },
      {
        path: ROUTES.CARS_ADD_PRODUCTS_PATH,
        layout: Layout,
        component: userIsAuth(CarsAddProductsContainer)
      },
      {
        path: ROUTES.CARS_ADD_SERVICES_PATH,
        layout: Layout,
        component: userIsAuth(CarsAddServicesContainer)
      },
      {
        path: ROUTES.CARS_ORDERS_PATH,
        layout: Layout,
        component: userIsAuth(CarsOrdersContainer)
      },
      {
        path: ROUTES.CARS_ORDERS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(CarsOrdersDetailContainer)
      },
      {
        path: ROUTES.CARS_ORDERS_CANCEL_PATH,
        layout: Layout,
        component: userIsAuth(CarsOrdersDeleteContainer)
      }
    ]
  },
  // Finances
  {
    path: ROUTES.FINANCES_PATH,
    layout: Layout,
    component: userIsAuth(FinancesContainer),
    routes: [
      {
        path: ROUTES.FINANCES_DEBITS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(FinancesCreditsDebitsCreateContainer)
      },
      {
        path: ROUTES.FINANCES_CREDITS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(FinancesCreditsDebitsCreateContainer)
      },
      {
        path: ROUTES.FINANCES_ACCEPT_PATH,
        layout: Layout,
        component: userIsAuth(FinancesAcceptCashContainer)
      },
      {
        path: ROUTES.FINANCES_TRANSFER_CASH_CREATE_URL,
        layout: Layout,
        component: userIsAuth(FinancesTransferCashCreateContainer)
      },
      {
        path: ROUTES.FINANCES_TRANSACTIONS_PATH,
        layout: Layout,
        component: userIsAuth(FinancesTransactionsContainer)
      },
      {
        path: ROUTES.FINANCES_CLIENT_TRANSACTION_CREATE,
        layout: Layout,
        component: userIsAuth(AcceptCashClientTransactionCreateContainer)
      },
      {
        path: ROUTES.FINANCES_ACCEPT_CASH_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(AcceptCashClientTransactionsContainer)
      }
    ]
  },
  // RequestFunds
  {
    path: ROUTES.REQUEST_FUNDS_PATH,
    layout: Layout,
    component: userIsAuth(RequestFundsContainer),
    routes: [
      {
        path: ROUTES.REQUEST_FUNDS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(RequestFundsCreateContainer)
      }
    ]
  },
  // Notifications
  {
    path: ROUTES.NOTIFICATION_PATH,
    layout: Layout,
    component: userIsAuth(NotificationContainer)
  },
  // ClientsNew
  {
    path: ROUTES.MARKETS_NEW_PATH,
    layout: Layout,
    component: userIsAuth(DeliveriesBranchesContainer),
    routes: [
      {
        path: ROUTES.MARKETS_NEW_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(DeliveriesBranchesDetailContainer)
      }
    ]
  },
  // Stocks income
  {
    path: ROUTES.STOCKS_INCOME_PATH,
    layout: Layout,
    component: userIsAuth(StocksIncomeContainer),
    routes: [
      {
        path: ROUTES.STOCKS_INCOME_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(StocksDetailContainer)
      },
      {
        path: ROUTES.STOCKS_INCOME_SUPPLY_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(StocksIncomeSupplyDetailContainer)
      }
    ]
  },
  // Stocks outcome
  {
    path: ROUTES.STOCKS_OUTCOME_PATH,
    layout: Layout,
    component: userIsAuth(StocksOutcomeContainer),
    routes: [
      {
        path: ROUTES.STOCKS_OUTCOME_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(StocksDetailContainer)
      }
    ]
  },
  // Stocks rests
  {
    path: ROUTES.STOCKS_RESTS_PATH,
    layout: Layout,
    component: userIsAuth(StocksRestsContainer)
  },
  // Stocks Selections
  {
    path: ROUTES.STOCKS_SELECTIONS_PATH,
    layout: Layout,
    component: userIsAuth(StocksSelectionsContainer),
    routes: [
      {
        path: ROUTES.STOCKS_SELECTIONS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(StocksSelectionsCreateContainer)
      },
      {
        path: ROUTES.STOCKS_SELECTIONS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(StocksSelectionsDetailContainer)
      }
    ]
  },
  // Checks
  {
    path: ROUTES.CHECKS_PATH,
    layout: Layout,
    component: userIsAuth(ChecksContainer),
    routes: [
      {
        path: ROUTES.CHECKS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(ChecksDetailContainer)
      }
    ]
  },
  // Debts
  {
    path: ROUTES.DEBTS_PATH,
    layout: Layout,
    component: userIsAuth(DebtsContainer),
    routes: [
      {
        path: ROUTES.DEBTS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(DebtsDetailContainer)
      }
    ]
  },
  // Plans
  {
    path: ROUTES.PLANS_PATH,
    layout: Layout,
    component: userIsAuth(PlansContainer),
    routes: [
      {
        path: ROUTES.PLANS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(PlansDetailContainer)
      }
    ]
  },
  // Statistics Pos
  {
    path: ROUTES.STATISTICS_POS_PATH,
    layout: Layout,
    component: userIsAuth(StatisticsPosContainer)
  },
  // Products batches
  {
    path: ROUTES.PRODUCTIONS_BATCHES_PATH,
    layout: Layout,
    component: userIsAuth(ProductionsBatchesContainer),
    routes: [
      {
        path: ROUTES.PRODUCTIONS_BATCHES_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(ProductionsBatchesCreateStepOneContainer)
      },
      {
        path: ROUTES.PRODUCTIONS_BATCHES_CREATE_STEP_TWO_PATH,
        layout: Layout,
        component: userIsAuth(ProductionsBatchesCreateStepTwoContainer)
      },
      {
        path: ROUTES.PRODUCTIONS_BATCHES_CREATE_STEP_THREE_PATH,
        layout: Layout,
        component: userIsAuth(ProductionsBatchesCreateStepThreeContainer)
      },
      {
        path: ROUTES.PRODUCTIONS_BATCHES_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(ProductionsBatchesDetailContainer)
      },
      {
        path: ROUTES.PRODUCTIONS_BATCHES_ADD_PRODUCTS_PATH,
        layout: Layout,
        component: userIsAuth(BatchesAddProductsContainer)
      },
      {
        path: ROUTES.PRODUCTIONS_BATCHES_ADD_MATERIALS_PATH,
        layout: Layout,
        component: userIsAuth(BatchesAddMaterialsContainer)
      },
      {
        path: ROUTES.PRODUCTIONS_BATCHES_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(ProductionsBatchesEditContainer)
      }
    ]
  },
  // Track
  {
    path: ROUTES.TRACK_PATH,
    layout: Layout,
    component: userIsAuth(TrackContainer)
  },
  // Orders
  {
    path: ROUTES.ORDERS_PATH,
    layout: Layout,
    component: userIsAuth(OrdersContainer),
    routes: [
      {
        path: ROUTES.ORDERS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(MarketsOrdersCreateContainer)
      }
    ]
  },
  // Profile
  {
    path: ROUTES.PROFILE_PATH,
    layout: Layout,
    component: userIsAuth(ProfileContainer)
  },
  // Products
  {
    path: ROUTES.PRODUCTS_PATH,
    layout: Layout,
    component: userIsAuth(ProductsContainer),
    routes: [
      {
        path: ROUTES.PRODUCTS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(ProductsDetailContainer)
      },
      {
        path: ROUTES.PRODUCTS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(ProductsCreateContainer)
      },
      {
        path: ROUTES.PRODUCTS_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(ProductsEditContainer)
      }
    ]
  },
  // Products
  {
    path: ROUTES.PRODUCTS_TYPES_PATH,
    layout: Layout,
    component: userIsAuth(ProductsTypesContainer),
    routes: [
      {
        path: ROUTES.PRODUCTS_TYPES_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(ProductsTypesCreateContainer)
      },
      {
        path: ROUTES.PRODUCTS_TYPES_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(ProductsTypesEditContainer)
      }
    ]
  },
  // Branches
  {
    path: ROUTES.BRANCHES_PATH,
    layout: Layout,
    component: userIsAuth(BranchesContainer),
    routes: [
      {
        path: ROUTES.BRANCHES_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(BranchesDetailContainer)
      },
      {
        path: ROUTES.BRANCHES_MAP_PATH,
        layout: Layout,
        component: userIsAuth(BranchesMapContainer)
      },
      {
        path: ROUTES.BRANCHES_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(BranchesCreateContainer)
      },
      {
        path: ROUTES.BRANCHES_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(BranchesEditContainer)
      }
    ]
  },
  // Users
  {
    path: ROUTES.USERS_PATH,
    layout: Layout,
    component: userIsAuth(UsersContainer),
    routes: [
      {
        path: ROUTES.USERS_DETAIL_PATH,
        layout: Layout,
        component: userIsAuth(UsersDetailContainer)
      },
      {
        path: ROUTES.USERS_CREATE_PATH,
        layout: Layout,
        component: userIsAuth(UsersCreateContainer)
      },
      {
        path: ROUTES.USERS_EDIT_PATH,
        layout: Layout,
        component: userIsAuth(UsersEditContainer)
      }
    ]
  },
  // SettingsBot
  {
    path: ROUTES.SETTINGS_BOT_PATH,
    layout: Layout,
    component: userIsAuth(SettingsBotContainer)
  },
  // Extensions
  {
    path: ROUTES.EXTENSIONS_PATH,
    layout: Layout,
    component: userIsAuth(ExtensionsContainer)
  },
  // Not found
  {
    path: '*',
    layout: Layout,
    component: NotFound
  }
]
