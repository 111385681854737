import React, {useState} from 'react'
import {find, isEmpty, path, prop, propEq} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import CallIcon from 'components/SvgIcons/CustomIcons/CallIcon'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'
import TileInner from 'components/Cards/Tile/TileInner'
import FlagIcon from 'components/SvgIcons/CustomIcons/FlagIcon'
import Button from 'components/Forms/Button'
import CardDescription from 'components/Cards/CardDescription'
import Timer from 'components/Timer'
import Loader from 'components/Loader'
import moment from 'moment'
import Form from 'components/Forms/Form'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import ModalConfirm from 'components/Modal/ModalConfirm'
import {Table} from 'components/Table'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import {PlusCircle as AddIcon, Trash2 as TrashIcon} from 'react-feather'
import Modal from 'components/Modal/Modal'
import {parseParams} from 'helpers/url'
import sumBy from 'helpers/sumBy'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const Href = styled('a')`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  p{
    color: ${({theme}) => theme.text.secondary};
  }
`
const NumberWrap = styled('div')`
  color: ${({theme}) => theme.text.secondary};
  h4{
    display: inline;
  }
`
const EmptyList = styled('div')`
  padding: 12px 0;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
  button{
    margin-top: 10px;
  }
`
const descriptionStyles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  }
}

const AddProductsFormFields = (props) => {
  const {
    productIdParam,
    serviceIdParam,
    openProductsModal
  } = props

  const amountName = openProductsModal ? 'amount_' + productIdParam : 'amount_' + serviceIdParam
  const customPriceName = openProductsModal ? 'customPrice_' + productIdParam : 'customPrice_' + serviceIdParam

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'number'}
        name={customPriceName}
        label={'Цена'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        name={amountName}
        label={'Количество'}
        required
        fullWidth
      />
      <Button
        text={'Применить'}
        fullWidth
      />
    </StyledWrap>
  )
}
const FormFields = () => {
  return (
    <>
      <Field
        component={TextField}
        name={'km'}
        label={'Текущий километраж'}
        fullWidth
        required
      />
      <Field
        component={TextField}
        name={'comment'}
        label={'Комментарий к заказу'}
        fullWidth
      />
    </>
  )
}

const CarsDetailGrid = (props) => {
  const {
    match,
    location,
    carsDetail,
    goBack,
    goDescription,
    goAddProducts,
    goAddServices,
    goOrders,
    handleSetAcceptorIdQuery,
    addProductIdHandler,
    addServiceIdHandler,
    carsOrdersCreate,
    carsOrdersFinishCreate
    // carsProductsItemUpdate
  } = props

  // UseStates
  const [isAcceptor, setIsAcceptor] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [openProductsModal, setOpenProductsModal] = useState(false)
  const [openServicesModal, setOpenServicesModal] = useState(false)
  const [formValues, setFormValues] = React.useState({})
  const [selectedProduct, setSelectedProduct] = React.useState({})

  // Data
  const carsDetailData = prop('data', carsDetail)
  const carsDetailLoading = prop('loading', carsDetail)
  const carsOrdersCreateData = prop('data', carsOrdersCreate)
  const carsOrdersCreateLoading = prop('loading', carsOrdersCreate)
  const carsOrdersFinishCreateLoading = prop('loading', carsOrdersFinishCreate)
  const clientName = prop('clientName', carsDetailData)
  const currency = path(['acceptor', 'currency', 'name'], carsDetailData)
  const number = prop('number', carsDetailData)
  const phone = path(['phones', '0', 'number'], carsDetailData)
  const carBrand = path(['carBrand', 'name'], carsDetailData)
  const carBrandParent = path(['carBrand', 'parent', 'name'], carsDetailData)
  const name = carBrandParent ? (carBrandParent + ' ' + carBrand) : carBrand
  const acceptor = prop('acceptor', carsDetailData)
  const startTimePreview = prop('startTime', carsOrdersCreateData)
  const startTime = path(['acceptor', 'startTime'], carsDetailData)
  const startTimePreviewFormated = moment(startTimePreview).format('DD MMM YYYY HH:mm:ss')
  const startTimeFormated = moment(startTime).format('DD MMM YYYY HH:mm:ss')
  const acceptorId = prop('id', acceptor)
  const startedAcceptorId = prop('id', carsOrdersCreateData)
  const acceptorIdPreview = prop('id', carsOrdersCreateData)
  const lastAcceptor = prop('lastAcceptor', carsDetailData)
  const lastKm = prop('km', lastAcceptor)
  const lastVisit = prop('finishTime', lastAcceptor)
  const visitsSubTitle = lastKm
    ? numberFormat(lastKm, 'км') + ' - ' + moment(lastVisit).format('DD MMM YYYY HH:mm')
    : 'Новый клиент'
  const productsDataList = path(['acceptor', 'products'], carsDetailData)
  const productsTotalPrice = productsDataList && sumBy(productsDataList, ['totalPrice'])
  const servicesDataList = path(['acceptor', 'services'], carsDetailData)
  const servicesTotalPrice = servicesDataList && sumBy(servicesDataList, ['totalPrice'])

  // Redirects
  const carId = path(['params', 'id'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)
  const serviceIdParam = prop('serviceId', searchObj)

  // Initial values
  const customPriceName = 'customPrice_' + productIdParam
  const customAmountName = 'amount_' + productIdParam
  const customPriceNameServices = 'customPrice_' + serviceIdParam
  const customAmountNameServices = 'amount_' + serviceIdParam
  const productInitialCurrently = productsDataList && find(propEq('id', +productIdParam))(productsDataList)
  const serviceInitialCurrently = servicesDataList && find(propEq('id', +serviceIdParam))(servicesDataList)
  const productPriceValue = prop('price', productInitialCurrently)
  const productAmountValue = prop('amount', productInitialCurrently)
  const servicePriceValue = prop('price', serviceInitialCurrently)
  const serviceAmountValue = prop('amount', serviceInitialCurrently)

  const initialValuesObj = {}
  const initialValuesProducts = {
    [customPriceName]: productPriceValue && `${+productPriceValue}`,
    [customAmountName]: productAmountValue && `${+productAmountValue}`
  }
  const initialValuesServices = {
    [customPriceNameServices]: servicePriceValue && +servicePriceValue,
    [customAmountNameServices]: serviceAmountValue && +serviceAmountValue
  }

  // CardDescription
  const cardDescription =
    <TilesList>
      <TileItem
        styles={descriptionStyles}
        onClick={() => goDescription(+carId)}
      >
        <PhotoWithStatus
          status={'none'}
          size={'62px'}
          type={'car'}
        />
        <RightBar>
          <h3>{clientName}</h3>
          <p>{name}</p>
        </RightBar>
      </TileItem>
    </TilesList>

  // Score
  const carNumber =
    <TilesList>
      <TileItem>
        <NumberWrap>
          Номер авто: <h4>{number}</h4>
        </NumberWrap>
      </TileItem>
    </TilesList>

  // Visits
  const cardVisits =
    <TilesList>
      <TileItem
        onClick={() => goOrders(carId)}
      >
        <TileInner
          title={'Посещения'}
          text={visitsSubTitle}
          iconBackground={'#6770e6'}
          icon={<FlagIcon color={'#fff'} />}
        />
      </TileItem>
    </TilesList>

  // Time
  const time =
    <TilesList
      title={'Время'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Начало работы'} is={startTimeFormated || startTimePreviewFormated} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription
          it={'Время работы'}
          is={<Timer startTime={startTime || startTimePreview} />}
        />
      </TileItem>
    </TilesList>

  // Mileage
  const mileage = <FormFields />

  // Products
  const productsList =
    <Table>
      <tbody>
        <tr>
          <th colSpan={'2'}>Название</th>
          <th>Кол&nbsp;-&nbsp;во</th>
          <th>Сумма</th>
        </tr>
        {productsDataList && mapIndexed((item) => {
          const id = prop('id', item)
          const productName = path(['product', 'name'], item)
          const measurement = path(['product', 'measurement', 'name'], item)
          const amount = prop('amount', item)
          const price = prop('price', item)
          const handleClick = () => {
            addProductIdHandler(id)
            setOpenProductsModal(true)
            setOpenServicesModal(false)
            setSelectedProduct({name: productName, price: price})
          }

          return (
            <tr
              onClick={handleClick}
              key={id}
            >
              <td colSpan={'2'}>{productName}</td>
              <td>{numberFormat(amount, measurement)}</td>
              <td>{numberFormat((price * amount), currency)}</td>
            </tr>
          )
        }, productsDataList)}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
          <td> </td>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(productsTotalPrice, currency)}</TextStatus></td>
        </tr>
      </tfoot>
    </Table>

  const handleGoAddProducts = () => {
    goAddProducts(+carId)
    handleSetAcceptorIdQuery(acceptorId || startedAcceptorId)
  }

  const handleGoAddServices = () => {
    goAddServices(+carId)
    handleSetAcceptorIdQuery(acceptorId || startedAcceptorId)
  }

  const productsAddBtn =
    <AddIcon
      color={'#6770e6'}
      onClick={() => handleGoAddProducts()}
    />

  const products =
    <TilesList
      title={'Список товаров'}
      button={productsAddBtn}
    >
      <TileItem
        height={'auto'}
        styles={{'& > div': {padding: '2px 0 !important'}}}
      >
        {productsDataList && !isEmpty(productsDataList)
          ? productsList
          : <EmptyList>Товары не выбраны</EmptyList>}
      </TileItem>
    </TilesList>

  // Services
  const servicesList =
    <Table>
      <tbody>
        <tr>
          <th colSpan={'2'}>Название</th>
          <th>Кол&nbsp;-&nbsp;во</th>
          <th>Сумма</th>
        </tr>
        {servicesDataList && mapIndexed((item) => {
          const id = prop('id', item)
          const serviceName = path(['service', 'name'], item)
          const amount = prop('amount', item)
          const price = prop('price', item)
          const handleClick = () => {
            addServiceIdHandler(id)
            setOpenProductsModal(false)
            setOpenServicesModal(true)
            setSelectedProduct({name: serviceName, price: price})
          }

          return (
            <tr
              onClick={() => handleClick()}
              key={id}
            >
              <td colSpan={'2'}>{serviceName}</td>
              <td>{numberFormat(amount, 'шт')}</td>
              <td>{numberFormat((price * amount), currency)}</td>
            </tr>
          )
        }, servicesDataList)}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
          <td> </td>
          <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(servicesTotalPrice, currency)}</TextStatus></td>
        </tr>
      </tfoot>
    </Table>

  const servicesAddBtn =
    <AddIcon
      color={'#6770e6'}
      onClick={() => handleGoAddServices()}
    />

  const services =
    <TilesList
      title={'Список услуг'}
      button={servicesAddBtn}
    >
      <TileItem
        height={'auto'}
        styles={{'& > div': {padding: '2px 0 !important'}}}
      >
        {servicesDataList && !isEmpty(servicesDataList)
          ? servicesList
          : <EmptyList>Услуги не выбраны</EmptyList>}
      </TileItem>
    </TilesList>

  // Form add products submit
  const handleAddProductsSubmit = (values) => {
    // const amountName = 'amount_' + productIdParam
    // 'amount_' + serviceIdParam
    // const customPriceName = 'customPrice_' + productIdParam
    // 'customPrice_' + serviceIdParam

    // !equals(initialValuesProducts, values) && carsProductsItemUpdate.onSubmit({
    //   product_id: productIdParam,
    //   amount: 100,
    //   price: 200
    // })
    // console.warn(equals(initialValuesProducts, values), 'eq')
    // console.warn(initialValuesProducts, 'initialValuesProducts')
    // console.warn(values, 'values')
  }
  const handleDeleteProduct = () => {
    alert('deleted product')
  }

  // Form add services submit
  const handleAddServicesSubmit = () => {
    alert('added service')
  }
  const handleDeleteService = () => {
    alert('deleted service')
  }

  // Modals
  const addProductsModal =
    <Modal
      open={openProductsModal || openServicesModal}
      isOpen={openProductsModal ? setOpenProductsModal : setOpenServicesModal}
      title={prop('name', selectedProduct) || (openProductsModal ? 'Добавление продукта' : 'Добавление услуги')}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={openProductsModal ? handleDeleteProduct : handleDeleteService} />}
    >
      <Form
        onSubmit={openProductsModal ? handleAddProductsSubmit : handleAddServicesSubmit}
        initialValues={openProductsModal ? initialValuesProducts : initialValuesServices}
      >
        <AddProductsFormFields
          openProductsModal={openProductsModal}
          productIdParam={productIdParam}
          serviceIdParam={serviceIdParam}
        />
      </Form>
    </Modal>
  const modals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Для продолжения подтвердите возврат'}
      loading={carsOrdersFinishCreateLoading}
      type={'submit'}
    />

  // StartContent
  const startContent =
    <>
      {time}
      {products}
      {services}
      {mileage}
      {modals}
    </>

  // MainButton
  const buttonText = acceptor || isAcceptor ? 'Закончить работу' : 'Начать работу'
  const buttonFunc = () => {
    if (!acceptor && !isAcceptor) {
      carsOrdersCreate.onSubmit({
        car: carId
      })
      setIsAcceptor(true)
    } else {
      setConfirmModalOpen(true)
    }
  }
  const buttonEq = (acceptor || isAcceptor) && !(formValues.km)

  const startButton =
    <Button
      type={'button'}
      onClick={() => buttonFunc()}
      text={buttonText}
      fullWidth
      disabled={buttonEq}
    />

  // Main
  const mainContent =
    <>
      <div>
        {cardDescription}
        {carNumber}
      </div>
      <div>
        {cardVisits}
      </div>
      {(!carsOrdersCreateLoading && (acceptor || isAcceptor)) && startContent}
      {carsOrdersCreateLoading ? <Loader /> : startButton}
    </>

  // Nav buttons
  const navButtons = [
    {
      icon: <Href href={'tel:' + phone}><CallIcon fontSize={'17px'} /></Href>
    }
  ]

  // Form submit
  const handleMainSubmit = (values) => {
    carsOrdersFinishCreate.onSubmit({
      id: acceptorId || acceptorIdPreview,
      comment: prop('comment', values),
      km: +prop('km', values)
    })
  }

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack()}
        buttons={phone && navButtons}
        title={'Авто'}
      />
      <Container>
        <Form
          onSubmit={handleMainSubmit}
          initialValues={initialValuesObj}
          getValues={setFormValues}
        >
          <Wrap>
            {carsDetailLoading ? <Loader styles={{marginTop: '20px'}} /> : mainContent}
          </Wrap>
        </Form>
        {addProductsModal}
      </Container>
    </>
  )
}

export default CarsDetailGrid
