import React, {useState} from 'react'
import {find, map, pathOr, prop, propEq, propOr, reject} from 'ramda'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import OrdersCreateTabOne from './Tabs/OrdersCreateTabOne'
import OrdersCreateTabTwo from './Tabs/OrdersCreateTabTwo'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import AddProductsModal from 'containers/Orders/Grid/Create/Grid/Modals/AddProductsModal'
import {parseParams} from 'helpers/url'

const TabWrap = styled('div')`
  overflow: ${({value, show}) => value !== show && 'hidden'};
  height: ${({value, show}) => value !== show && '0'};
  margin: ${({value, show}) => value !== show && '0'};
  padding: ${({value, show}) => value !== show && '0'};
  & button {
    display: ${({value, show}) => value !== show && 'none'};
  }
`

const OrdersCreateGrid = props => {
  const {
    marketsProductsList,
    customStore,
    config,
    goBack,
    location,
    addProductIdHandler
  } = props

  // useState
  const [products, setProducts] = useState([])
  const [value, setValue] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState({})
  const [formValues, getFormValues] = useState({})

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)

  // Data
  const marketsProductsListData = propOr([], 'data', marketsProductsList)
  const configData = prop('data', config)
  const customStoreData = prop('data', customStore)

  // Const
  const canIsLimited = prop('cANISLIMITED', configData)
  const customPriceName = 'customPrice_' + productIdParam
  const paymentType = prop('paymentType', formValues)
  const productInitialCurrently = find(propEq('id', +productIdParam))(marketsProductsListData)
  const permissions = pathOr([], ['data', 'permissions'], customStore)

  const productsInitialPrice =
    paymentType === 'cash'
      ? prop('cashPrice', productInitialCurrently)
      : paymentType === 'bank'
        ? prop('transferPrice', productInitialCurrently)
        : prop('ball', productInitialCurrently)

  // Perms
  const isAdmin = prop('isSuperuser', customStoreData)
  const mapPerms = map((item) => {
    return prop('codename', item)
  }, permissions)

  // Initial values
  const initialValuesProducts = {
    [customPriceName]: productsInitialPrice && +productsInitialPrice
  }

  // HandleSubmits
  const handleAddProductsSubmit = values => {
    const amountPlus = 'amount_' + productIdParam
    const customPricePlus = 'customPrice_' + productIdParam
    const customPrice = prop(customPricePlus, values)
    const amount = prop(amountPlus, values)
    const newObject = {
      product: productIdParam,
      product_name: prop('name', selectedProduct),
      measurement: prop('measurement', selectedProduct),
      amount,
      custom_price: customPrice,
      total_price: customPrice * amount,
      image: prop('image', selectedProduct),
      boxes: prop('boxes', selectedProduct),
      transfer_price: prop('transferPrice', selectedProduct),
      cash_price: prop('cashPrice', selectedProduct)
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('amount', '0'), newProducts)
    setProducts(sortedProducts)

    setModalOpen(false)
  }
  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productIdParam), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  // Modals
  const modals =
    <>
      <AddProductsModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedProduct={selectedProduct}
        initialValues={initialValuesProducts}
        handleSubmit={handleAddProductsSubmit}
        handleDelete={handleDeleteProduct}
        location={location}
        customStore={customStore}
      />
    </>

  // MainContent
  const mainContent =
    <>
      <TabWrap
        value={value}
        show={0}
      >
        <OrdersCreateTabOne
          products={products}
          setValue={setValue}
          setModalOpen={setModalOpen}
          setSelectedProduct={setSelectedProduct}
          addProductIdHandler={addProductIdHandler}
          canIsLimited={canIsLimited}
          mapPerms={mapPerms}
          configData={configData}
          isAdmin={isAdmin}
          getFormValues={getFormValues}
          {...props}
        />
      </TabWrap>
      <TabWrap
        value={value}
        show={1}
      >
        <OrdersCreateTabTwo
          setValue={setValue}
          products={products}
          value={value}
          setModalOpen={setModalOpen}
          setSelectedProduct={setSelectedProduct}
          formValues={formValues}
          {...props}
        />
      </TabWrap>
    </>

  // Render
  return (
    <>
      <CreateBar
        title={'Создание заказа'}
        goBack={value === 1 && (() => setValue(0))}
        goExit={goBack}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      {modals}
    </>
  )
}

export default OrdersCreateGrid
