import React from 'react'
import moment from 'moment'
import {path} from 'ramda'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {notificationsGetNotViewedAction} from 'containers/Notification/actions/notificationAction'

const enhance = compose(
  connect()
)

class GPSSender extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    let startPos
    const geoOptions = {
      enableHighAccuracy: true
    }
    const geoSuccess = (position) => {
      startPos = position
      const latitude = startPos.coords.latitude
      const longitude = startPos.coords.longitude
      this.setState({
        point: {
          lat: latitude,
          lon: longitude
        },
        gps: 1,
        registered_date: moment().format('YYYY-MM-DD HH:mm:ss')
      })
    }
    const geoError = (position) => {
      // eslint-disable-next-line no-console
      console.log(position)
    }
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)

    this.props.dispatch(notificationsGetNotViewedAction())

    this.timerID = setInterval(
      () => this.tick(),
      1000
    )
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  tick () {
    const {
      gpsSender
    } = this.props

    const seconds = moment().format('ss')
    const remainderSeconds = seconds % 60

    const isGps = !!(path(['point', 'lat'], this.state) && path(['point', 'lon'], this.state))

    if (remainderSeconds === 0 && isGps) {
      gpsSender.onSubmit({
        point: {
          lat: this.state.point.lat,
          lon: this.state.point.lon
        },
        gps: this.state.gps,
        registered_date: moment().format('YYYY-MM-DD HH:mm:ss')
      })
    } else if (remainderSeconds === 0 && !isGps) {
      this.props.dispatch(notificationsGetNotViewedAction())
    }
  }

  render () {
    return false
  }
}

export default enhance(GPSSender)
