import {compose, mapProps, mapPropsStream, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import RequestFundsCreateGrid from './Grid/RequestFundsCreateGrid'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import {distinctUntilChanged} from 'rxjs/operators'
import {isEqualSearch} from 'helpers/isEquals'
import {
  requestFundsCreateAction,
  requestFundsDetailAction,
  requestFundsUpdateAction
} from '../../actions/requestFundsAction'
import numberFormat from 'helpers/numberFormat'

export const DEFAULT_PICK_PARAMS = [
  'openUpdateDialog'
]

export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const requestFundsItem = prop('requestFundsItem', state)
    const customStore = prop('customStore', state)
    return {
      query,
      requestFundsItem,
      customStore
    }
  }),
  withCreate({
    stateName: [STATES.REQUEST_FUNDS_CREATE],
    action: requestFundsCreateAction,
    key: [STATES.REQUEST_FUNDS_CREATE],
    redirectUrl: ROUTES.REQUEST_FUNDS_URL,
    addParam: {tab: 'pending'},
    withCurrentParams: true,
    successMessage: 'Успех'
  }),
  // Update
  withCreate({
    stateName: [STATES.REQUEST_FUNDS_CREATE],
    action: requestFundsUpdateAction,
    key: 'update',
    addParam: {tab: 'approved'},
    withCurrentParams: true,
    redirectUrl: ROUTES.REQUEST_FUNDS_URL,
    propFromQuery: 'openUpdateDialog',
    successMessage: 'Успех'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.REQUEST_FUNDS_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetClientQuery: props => (clientId) => {
      props.history.push({
        search: appendParamsToUrl({clientId}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  withFilter({
    fields: ['search']
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(DEFAULT_PICK_PARAMS),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        props.query.openUpdateDialog && props.dispatch(requestFundsDetailAction(props))
      })
    return props$
  }),
  mapProps(({
    requestFundsItem,
    handleSetClientQuery,
    goDetail,
    update,
    ...props
  }) => {
    const data = prop('data', requestFundsItem)
    const currency = path(['currency', 'id'], data)
    // update dialog
    const updateDialog = {
      open: prop('openUpdateDialog', props.query),
      nextStatus: prop('nextStatus', props.query),
      loading: prop('loading', requestFundsItem),
      comment: prop('comment', data),
      initialValues: {
        amount: numberFormat(prop('amount', data)),
        currency: currency && `${currency}`
      },
      onSubmit: update.onSubmit
    }

    return {
      updateDialog,
      ...props
    }
  })
)(RequestFundsCreateGrid)
