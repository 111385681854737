import React from 'react'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {CardFieldsFilter} from 'components/Cards/CardFieldsFilter'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField'
import {map, prop} from 'ramda'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'

const StyledWrap = styled('div')`
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = (props) => {
  const {
    stocks,
    values
  } = props

  // Values
  const typeValue = prop('type', values)

  // Options
  const stocksListOptions = stocks && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, stocks)

  return (
    <StyledWrap>
      <Field
        component={StaticSearchField}
        name={'stock'}
        label={'Склад'}
        type={'select'}
        options={stocksListOptions}
        fullWidth
      />
      <Field
        component={UniversalSearchField}
        api={API.PRODUCTS_TYPES}
        params={{page_size: '1000', parent: '0'}}
        name={'type'}
        label={'Тип товаров'}
        fullWidth
      />
      {typeValue &&
      <Field
        component={UniversalSearchField}
        api={API.PRODUCTS_TYPES}
        params={typeValue && {page_size: '1000', parent: typeValue}}
        name={'subtype'}
        label={'Подтип товаров'}
        fullWidth
      />}
      <Field
        component={UniversalSearchField}
        api={API.MEASUREMENT_LIST}
        params={{page_size: '1000'}}
        name={'measurement'}
        label={'Ед. измерения'}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
          fixed
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {setOpenFilter, onChangeFilter, initialValues, stocks} = props

  const handleSubmit = (values) => {
    setOpenFilter(false)
    onChangeFilter({
      stock: prop('stock', values),
      type: prop('type', values),
      subtype: prop('subtype', values),
      measurement: prop('measurement', values)
    })
  }

  return (
    <CardFieldsFilter>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <FormFields
          stocks={stocks}
        />
      </Form>
    </CardFieldsFilter>
  )
}

export default Filter
