import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ClipboardIcon from 'components/SvgIcons/CustomIcons/Clipboard'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: ${({height}) => height || 'calc(100vh - 220px)'};
  padding: 25px 10px;
  margin: 0 auto;
  ${({styles}) => styles};
`
const Text = styled('p')`
  max-width: 170px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0241176em;
  color: ${({theme}) => theme.palette.grey};
`
const Img = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: ${({background}) => background || 'rgba(140, 140, 140, 0.1)'};
  border-radius: 8px;
  min-width: 44px;
  min-height: 44px;
  margin-bottom: 7px;
  padding: 14px;
`

const NoResults = (props) => {
  const {
    text,
    height,
    styles,
    background
  } = props

  return (
    <Wrap
      height={height}
      styles={styles}
    >
      <Img
        background={background}
      >
        <ClipboardIcon
          color={'#8c8c8c'}
        />
      </Img>
      <Text>{text}</Text>
    </Wrap>
  )
}

NoResults.propTypes = {
  text: PropTypes.any,
  height: PropTypes.string
}

NoResults.defaultProps = {
  text: 'Нет данных'
}

export default NoResults
