import {compose} from 'recompose'
import {withCreate, withFilter, withRedirect} from 'hocs'
import {prop} from 'ramda'
import {connect} from 'react-redux'
import CarsCreateGrid from 'containers/Cars/Grid/Create/Grid'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  carsCreateAction
} from 'containers/Cars/actions/carsAction'

export default compose(
  connect((state) => {
    const carsList = prop('carsList', state)
    return {
      carsList
    }
  }),
  withCreate({
    stateName: [STATES.CARS_CREATE],
    action: carsCreateAction,
    key: [STATES.CARS_CREATE],
    redirectUrl: ROUTES.CARS_URL
  }),
  withFilter({
    fields: ['carsSearch']
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.CARS_DETAIL_URL,
    withCurrentParams: true
  })
)(CarsCreateGrid)
