import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {usersCreateAction} from 'containers/Users/actions/usersActions'
import {stocksListAction} from 'actions/stocksAction'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const config = prop('config', state)
    return {
      config
    }
  }),
  withCreate({
    stateName: STATES.USERS_CREATE,
    action: usersCreateAction,
    key: STATES.USERS_CREATE,
    redirectToCreated: ROUTES.USERS_URL,
    successMessage: 'Сотрудник зарегистрирован'
  }),
  withFetchList({
    stateName: STATES.STOCKS_LIST,
    action: stocksListAction,
    key: STATES.STOCKS_LIST,
    workOnlyFirstTime: true
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.USERS_URL
  })
)(Grid)
