import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import PhotoWithStatus from 'components/PhotoWithStatus'

const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: calc(100% - 60px);
  flex-grow: 1;
  & > p {
    margin-top: 4px;
    & > span:not(:last-child){
      margin-right: 7px;
    }
  }
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const NoWrap = styled('span')`
  display: inline-flex;
  flex-flow: row nowrap;
`

const StocksRestsItem = props => {
  const {
    onClick,
    name,
    balance,
    reserved,
    defects,
    imgSrc
  } = props

  return (
    <>
      <TileItem
        onClick={onClick}
        styles={styles}
        height={'auto'}
      >
        <PhotoWithStatus
          status={'none'}
          imgSrc={imgSrc}
          type={'product'}
          styles={{marginTop: '3px', marginBottom: 'auto'}}
        />
        <RightBar>
          <h3>{name || 'Не известно'}</h3>
          <p>
            <NoWrap>{balance || 'Нет данных'}</NoWrap>
            {reserved && <NoWrap>{reserved}</NoWrap>}
            {defects && <NoWrap>{defects}</NoWrap>}
          </p>
        </RightBar>
        {onClick &&
        <ArrowWrap>
          <Arrow />
        </ArrowWrap>}
      </TileItem>
    </>
  )
}

StocksRestsItem.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string,
  imgSrc: PropTypes.string,
  balance: PropTypes.any,
  defects: PropTypes.any,
  reserved: PropTypes.any
}

export default StocksRestsItem
