import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'
import {filter, isNil, path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import {marketsCreateSerializer, marketsItemPhotoSerializer} from './serializers/marketsSerializer'
import moment from 'moment'
import {storageData} from 'helpers/storage'
import {getFromDb, setToDb} from '../../../hocs/Idb'
import {connect} from 'react-redux'
import toNumber from '../../../helpers/toNumber'
import axiosLib from 'axios'

export const marketsCreateAction = (values) => {
  const data = marketsCreateSerializer(values)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_CREATE, data)
      .then(({data}) => {
        dispatch({type: `${actionTypes.IMAGE_ADDED}_CHANGE`, payload: []})
        return data
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_CREATE,
      payload
    })
  }
}

export const marketsUpdateAction = ({open, ...values}) => {
  const data = marketsCreateSerializer(values, open)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.MARKETS_UPDATE, +open), data)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_CREATE,
      payload
    })
  }
}

export const marketOptionsListFetchAction = (categories) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKET_OPTIONS_LIST, {params: {categories}})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKET_OPTIONS_LIST,
      payload
    })
  }
}

export const ordersCreateAction = values => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_SALES_ORDERS_LIST, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.ORDERS_CREATE,
      payload
    })
  }
}

export const marketsContractsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_CONTRACTS_LIST, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_CONTRACTS_CREATE,
      payload
    })
  }
}

export const marketsDetailAction = (props) => {
  const marketId = path(['query', 'openUpdateDialog'], props)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_ITEM, +marketId))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_ITEM,
      payload
    })
  }
}

export const marketsItemAction = (params, props, marketId) => {
  const defaultParams = {
    with_extra_message: 1
  }
  const id = toNumber(path(['match', 'params', 'id'], props) || marketId)

  return (dispatch, getState) => {
    if (window.cancelRequestMarketDetail) {
      window.cancelRequestMarketDetail()
    }
    const CancelToken = axiosLib.CancelToken
    return getFromDb('markets', id).then((data) => {
      if (typeof data === 'undefined') {
        getFromDb('lists', '/market/market/').then((data) => {
          const response = path(['response'], data)
          if (response) {
            const currentMarket = filter(item => id === prop('id', item), response)
            dispatch({
              type: actionTypes.MARKETS_ITEM + '_DB',
              dbData: currentMarket[0]
            })
          }
        })
      } else {
        dispatch({
          type: actionTypes.MARKETS_ITEM + '_DB',
          dbData: data
        })
      }
      axios({dispatch, getState, connect})
        .get(sprintf(API.MARKETS_ITEM, id), {
          params: defaultParams,
          cancelToken: new CancelToken(function executor (cancel) {
            window.cancelRequestMarketDetail = cancel
          })
        })
        .then(({data, ...props}) => {
          setToDb('markets', data, true)
          const currentPath = window.location.pathname.split('/')
          if (toNumber(currentPath[2]) === id) {
            dispatch({
              type: actionTypes.MARKETS_ITEM + '_FULFILLED',
              payload: data
            })
          }
        })
        .catch(getPayloadFromError)
    })
  }
}

export const marketsItemPhotoAction = (params) => {
  const photoId = prop('photoId', params) || prop('photo_id', params)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_ITEM_PHOTO, +photoId), {params: marketsItemPhotoSerializer(params)})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_ITEM_PHOTO,
      payload
    })
  }
}

export const imageViewerAction = (params) => {
  const photoId = prop('imageViewer', params)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_ITEM_PHOTO, +photoId), {})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_ITEM_PHOTO,
      payload
    })
  }
}

export const marketsReturnsCreatePreviewAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_RETURNS_CREATE_PREVIEW, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_RETURNS_CREATE_PREVIEW,
      payload
    })
  }
}

export const marketsReturnsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_RETURNS_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_RETURNS_CREATE,
      payload
    })
  }
}

export const marketsSalesOrdersDetailAction = (params, props) => {
  const orderId = path(['match', 'params', 'orderId'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_SALES_ORDERS_DETAIL, +orderId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_SALES_ORDERS_DETAIL,
      payload
    })
  }
}

export const productsTypesListAction = params => {
  const search = prop('search', params)

  const data = {
    search,
    page_size: '1000'
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.PRODUCTS_TYPES_LIST, {params: data})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_TYPES_LIST,
      payload
    })
  }
}

export const marketsReturnsItemAction = (params, props) => {
  const returnId = path(['match', 'params', 'returnId'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_RETURNS_ITEM, +returnId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_RETURNS_ITEM,
      payload
    })
  }
}

export const marketSalesAcceptDeliveredAction = ({id, ...values}) => {
  const requestData = {
    ...values
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_ACCEPT_DELIVERED, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_ACCEPT_DELIVERED,
      payload
    })
  }
}

export const marketsBalanceNotificationCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_BALANCE_NOTIFICATION_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_BALANCE_NOTIFICATION_CREATE,
      payload
    })
  }
}

export const marketsClientBalancesAction = (params, props) => {
  const search = path(['location', 'search'], props)
  const searchObj = parseParams(search)
  const marketId = path(['match', 'params', 'id'], props)
  const division = prop('division', searchObj)
  const currency = prop('currency', searchObj)
  const paymentType = prop('paymentType', searchObj)

  const defaultParams = {
    division,
    currency,
    payment_type: paymentType
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_CLIENT_BALANCES, marketId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_CLIENT_BALANCES,
      payload
    })
  }
}

export const marketsStatisticsByProductsAction = (params, props) => {
  const marketId = path(['match', 'params', 'id'], props)
  const userId = path(['match', 'params', 'userId'], props)
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const searchQuery = path(['location', 'search'], props)
  const searchObj = parseParams(searchQuery)
  const search = prop('search', searchObj)
  const beginDate = prop('beginDate', searchObj) || firstDayOfMonth
  const endDate = prop('endDate', searchObj) || lastDayOfMonth

  const defaultParams = {
    market_id: marketId || null,
    page_size: 1000,
    ordering: '-sales_count',
    begin_date: beginDate,
    end_date: endDate,
    user: userId || null,
    search
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKETS_STATISTICS_BY_PRODUCTS, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_STATISTICS_BY_PRODUCTS,
      payload
    })
  }
}

export const marketsTotalStatisticsAction = (params, props) => {
  const searchQuery = path(['location', 'search'], props)
  const searchObj = parseParams(searchQuery)
  const beginDate = prop('beginDate', searchObj)
  const endDate = prop('endDate', searchObj)
  const division = prop('division', searchObj)
  const type = prop('type', searchObj)
  const marketId = path(['match', 'params', 'id'], props)
  const userId = path(['match', 'params', 'userId'], props)
  const divisionId = path(['match', 'params', 'divisionId'], props)

  const defaultParams = {
    begin_date: beginDate,
    end_date: endDate,
    market: marketId,
    user: userId || null,
    type: type || (userId ? 1 : null),
    division: division || divisionId
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKETS_TOTAL_STATISTICS, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_TOTAL_STATISTICS,
      payload
    })
  }
}

export const marketsVisitsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_REJECTION_CREATE, values)
      .then(({data}) => {
        dispatch({type: `${actionTypes.IMAGE_ADDED}_CHANGE`, payload: []})
        return data
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_VISIT_CREATE,
      payload
    })
  }
}

export const marketsTelegramsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_TELEGRAMS_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_TELEGRAMS_CREATE,
      payload
    })
  }
}

export const marketsReportsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_REPORTS_CREATE, values)
      .then(({data}) => {
        dispatch({type: `${actionTypes.IMAGE_ADDED}_CHANGE`, payload: []})
        return data
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_REPORTS_CREATE,
      payload
    })
  }
}

export const marketsReportsEditAction = ({reportId, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.MARKETS_REPORTS_DETAIL, reportId), {...values})
      .then(({data}) => {
        dispatch({type: `${actionTypes.IMAGE_ADDED}_CHANGE`, payload: []})
        return data
      })
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_REPORTS_EDIT,
      payload
    })
  }
}

export const marketsReportsDetailAction = (params, props) => {
  const reportId = path(['match', 'params', 'reportId'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_REPORTS_DETAIL, +reportId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_REPORTS_DETAIL,
      payload
    })
  }
}

export const marketsAgentPlansAction = params => {
  const planHideParam = prop('planHide', params)
  const userId = prop('userId', params)
  const today = moment().format('YYYY-MM-DD')
  const date = prop('plan_date', params) || storageData('marketsPlanDate').getValue() || null
  const planHide = (planHideParam && (planHideParam !== 'false')) || storageData('marketsPlanHide').getValue()
  const search = prop('search', params)
  const locationObj = storageData('location').getValue()
  const location = !isNil(prop('latitude', locationObj)) ? ` ${prop('latitude', locationObj) + '|' + prop('longitude', locationObj)}` : null

  const defaultParams = {
    page_size: 100,
    date: date || today,
    hide: planHide === null ? true : planHide,
    search,
    location
  }

  return (dispatch, getState) => {
    if (window.agentListCancelRequest) {
      window.agentListCancelRequest()
    }
    const CancelToken = axiosLib.CancelToken
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MARKETS_AGENT_PLANS, userId),
        {
          params: defaultParams,
          cancelToken: new CancelToken(function executor (cancel) {
            window.agentListCancelRequest = cancel
          })
        })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_AGENT_PLANS,
      payload
    })
  }
}

export const marketsPlanAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.MARKETS_PLAN, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_PLAN,
      payload
    })
  }
}

export const marketsPlanClearAction = () => {
  return {
    type: `${actionTypes.MARKETS_PLAN}_CLEAR`
  }
}

export const marketsServiceTimeEndClearAction = () => {
  return {
    type: `${actionTypes.MARKETS_SERVICE_TIME_END}_CLEAR`
  }
}

export const marketsServiceTimeStartClearAction = () => {
  return {
    type: `${actionTypes.MARKETS_SERVICE_TIME_START}_CLEAR`
  }
}

export const marketsPlanEditClearAction = () => {
  return {
    type: `${actionTypes.MARKETS_PLAN_EDIT}_CLEAR`
  }
}

export const marketsPlanDeleteAction = (id) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.MARKETS_PLAN_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_PLAN_DELETE,
      payload
    })
  }
}

export const productsTypesDeleteAction = ({id}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.PRODUCTS_TYPES_ITEM, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_TYPES_DELETE,
      payload
    })
  }
}

export const marketsReportsDeleteAction = ({id}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.MARKETS_REPORTS_DETAIL, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_REPORTS_DELETE,
      payload
    })
  }
}

export const marketsPlanDeleteClearAction = () => {
  return {
    type: `${actionTypes.MARKETS_PLAN_DELETE}_CLEAR`
  }
}

// orderAddBonus
export const orderBonusListAction = (params, props) => {
  const id = path(['match', 'params', 'orderId'], props)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKETS_PRODUCTS_LIST, {params: {bonus_for_order: id}})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.BONUS_ORDER_LIST,
      payload
    })
  }
}

export const marketsSalesOrderCountAction = (params, props) => {
  const id = path(['match', 'params', 'orderId'], props)
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.BONUS_ORDER_COUNT, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.BONUS_ORDER_COUNT,
      payload
    })
  }
}

// eslint-disable-next-line camelcase
export const orderBonusCreateAction = ({order_id, ...values}) => {
  const requestData = {
    ...values
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
    // eslint-disable-next-line camelcase
      .post(sprintf(API.ORDER_ITEM_ADD_BONUS, +order_id), requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.ORDER_ITEM_ADD_BONUS,
      payload
    })
  }
}

export const marketsServiceTimeStartAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.MARKETS_SERVICE_TIME_START, id), {...values})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_SERVICE_TIME_START,
      payload
    })
  }
}

export const marketsServiceTimeEndAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.MARKETS_SERVICE_TIME_END, id), {...values})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_SERVICE_TIME_END,
      payload
    })
  }
}

export const marketsPlanEditAction = (values) => {
  const planId = prop('plan_id', values)
  const agent = prop('agent', values)
  const market = prop('market', values)
  const recurrences = prop('recurrences', values)
  const priority = prop('priority', values)
  const evenWeeks = prop('every_weeks', values)
  const data = {
    agent,
    market,
    recurrences,
    priority,
    every_weeks: evenWeeks
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.MARKETS_PLAN_EDIT, +planId), data)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_PLAN_EDIT,
      payload
    })
  }
}

export const marketsOrdersEditAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.MARKETS_SALES_ORDERS_DETAIL, id), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_ORDERS_EDIT,
      payload
    })
  }
}

export const marketsOrdersCancelAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.MARKETS_ORDERS_CANCEL, id), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_ORDERS_CANCEL,
      payload
    })
  }
}

export const ordersAcceptOutcomeAction = values => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.STOCKS_ACCEPT_OUTCOME, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.ORDERS_ACCEPT_OUTCOME,
      payload
    })
  }
}

export const marketsPlansTypesAction = (params, props) => {
  const marketId = path(['match', 'params', 'id'], props)

  const defaultParams = {
    market_id: marketId
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKETS_PLANS_TYPES, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_PLANS_TYPES,
      payload
    })
  }
}

export const marketsAgentsLocationAction = () => {
  const today = moment().format('YYYY-MM-DD')

  const defaultParams = {
    date: today,
    page_size: '1000'
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.MARKETS_AGENTS_LOCATION, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_AGENTS_LOCATION,
      payload
    })
  }
}

export const marketsProductDetailAction = (params) => {
  const productId = prop('productId', params)
  const defaultParams = {
    thumbnail_type: 'large'
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.MANUFACTURE_PRODUCTS_DETAIL, productId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.MARKETS_PRODUCT_DETAIL,
      payload
    })
  }
}
