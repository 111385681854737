import {prop} from 'ramda'
import moment from 'moment'
import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'

export const totalStatisticsPosAction = (params) => {
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const beginDate = prop('begin_date', params) || firstDayOfMonth
  const endDate = prop('end_date', params) || lastDayOfMonth

  const defaultParams = {
    begin_date: beginDate,
    end_date: endDate
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.POS_TOTAL_STATISTICS, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.POS_TOTAL_STATISTICS,
      payload
    })
  }
}
