import {prop} from 'ramda'

export const notificationListSerializer = (params) => {
  const search = prop('search', params)
  const reloadUI = prop('reloadUI', params)
  return {
    search: search,
    reloadUI
  }
}
