import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {path} from 'ramda'
import {sprintf} from 'sprintf-js'

export const productsDetailAction = (params, props) => {
  const {
    match
  } = props

  const id = path(['params', 'id'], match)

  const data = {
    thumbnail_type: 'original'
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.PRODUCTS_DETAIL, id), {data})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_DETAIL,
      payload
    })
  }
}

export const productsTypesItemAction = (params, props) => {
  const {
    match
  } = props

  const id = path(['params', 'id'], match)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.PRODUCTS_TYPES_ITEM, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_TYPES_ITEM,
      payload
    })
  }
}

export const productsDetailDeleteAction = ({id}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .delete(sprintf(API.PRODUCTS_DETAIL, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_DELETE,
      payload
    })
  }
}

export const productsCreateAction = values => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.PRODUCTS_LIST, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_CREATE,
      payload
    })
  }
}

export const productsTypesAction = () => {
  const data = {
    page_size: '1000',
    parent: '0'
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.PRODUCTS_TYPES, {params: data})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_TYPES,
      payload
    })
  }
}

export const productsTypesCreateAction = values => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.PRODUCTS_TYPES, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_TYPES_CREATE,
      payload
    })
  }
}

export const productsTypesEditAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.PRODUCTS_TYPES_ITEM, id), {...values})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_TYPES_EDIT,
      payload
    })
  }
}

export const productsEditAction = ({id, ...values}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.PRODUCTS_DETAIL, id), {...values})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PRODUCTS_EDIT,
      payload
    })
  }
}
