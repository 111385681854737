import React from 'react'
import styled from 'styled-components'

const Wrap = styled('span')`
  position: relative;
  background: ${({blur}) => blur ? 'transparent' : '#fefefe'};
  color: ${({theme, blur}) => blur ? theme.palette.white : theme.palette.black};
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({borderRadius}) => borderRadius ? `${borderRadius}` : '100%'};
  padding: 9px;
  overflow: hidden;
  transition: ${({theme}) => theme.transition.primary}, color 0s;
  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    background: #ffffff50;
    filter: blur(10px);
  }
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`

const Button = props => {
  const {
    title,
    onClick,
    blur,
    style
  } = props

  return (
    <Wrap
      onClick={onClick}
      blur={blur}
      style={style}
    >
      {title}
    </Wrap>
  )
}

export default Button
