import React from 'react'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Label from 'components/Forms/Label'
import mapIndexed from 'helpers/mapIndexed'
import Radio from 'components/Forms/Radio'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import TextField from 'components/Forms/TextField'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const LoadWrap = styled('li')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

const FormFields = props => {
  const {
    // measurementName,
    equipmentListData
  } = props
  const measurementName = 'шт'

  return (
    <>
      <StyledWrap>
        <div>
          <Label
            styles={{marginBottom: '0'}}
          >
            Выберите оборудование
          </Label>
          {equipmentListData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const id = prop('id', item)

            return (
              <Field
                key={index}
                component={Radio}
                type={'radio'}
                name={'equipment'}
                label={name}
                value={`${id}`}
              />
            )
          }, equipmentListData)}
        </div>
        <Field
          component={TextField}
          type={'number'}
          name={'amount'}
          label={measurementName ? ('Количество - ' + measurementName) : 'Количество'}
        />
      </StyledWrap>
      <BottomBar>
        <Button
          type={'submit'}
          text={'Начать производство'}
          fullWidth
        />
      </BottomBar>
    </>
  )
}

const ProductionsBatchesCreateGrid = props => {
  const {
    goExit,
    goBack,
    match,
    manufactureProductsDetail,
    equipmentList,
    manufactureBatchesCreate
  } = props

  // Data
  const manufactureProductsDetailData = prop('data', manufactureProductsDetail)
  const manufactureProductsDetailLoading = prop('loading', manufactureProductsDetail)
  const equipmentListData = prop('results', equipmentList)
  const equipmentListLoading = prop('loading', equipmentList)

  // Const
  const productName = prop('name', manufactureProductsDetailData)
  const measurementName = path(['measurement', 'name'], manufactureProductsDetailData)

  // Redirects
  const matchId = path(['params', 'id'], match)
  const secondId = path(['params', 'secondId'], match)

  // Initial values
  const initialValuesObj = {
    equipment: `${path(['0', 'id'], equipmentListData)}`
  }

  // Main submit
  const handleMainSubmit = values => {
    manufactureBatchesCreate.onSubmit({
      equipment: prop('equipment', values),
      amount: prop('amount', values),
      product: secondId
    })
  }

  // Main content
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleMainSubmit}
        initialValues={initialValuesObj}
      >
        <FormFields
          measurementName={measurementName}
          equipmentListData={equipmentListData}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={productName || 'Дополнительно'}
        goBack={() => goBack(matchId)}
        goExit={() => goExit()}
      />
      <Container
        background={'#fff'}
      >
        {(manufactureProductsDetailLoading || equipmentListLoading) ? <LoadWrap><Loader size={1.5} /></LoadWrap> : mainContent || <NoResults />}
      </Container>
    </>
  )
}

export default ProductionsBatchesCreateGrid
