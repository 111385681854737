import React from 'react'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import InfiniteScroll from 'components/InfiniteScroll'
import styled from 'styled-components'
import {find, path, prop, propEq, propOr} from 'ramda'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {marketsEditProductsListSerializer} from 'containers/Clients/actions/serializers/marketsSerializer'
import {parseParams} from 'helpers/url'

const Wrap = styled('div')`
  padding: 14px 16px 30px;
`
const LineThrough = styled('span')`
  text-decoration: line-through;
`
const ProductsSearch = styled('div')`
  position: fixed;
  right: 0;
  left: 0;
  top: 60px;
  background: #f8f8f8;
  z-index: 100;
  height: ${({value}) => (value === 1) ? '56px' : '0'};
  opacity: ${({value}) => (value === 1) ? 1 : 0};
  padding: ${({value}) => (value === 1) ? '10px 12px' : '0'};
  transition: all 0s;
  border-bottom: 1px solid #e4e4e6;
`

const MarketsOrdersEditTabTwo = props => {
  const {
    marketsProductsList,
    marketsSalesOrdersDetail,
    products,
    config,
    value,
    filterActions,
    setValue,
    location,
    setModalOpen,
    addProductIdHandler,
    setSelectedProduct
  } = props

  // Data
  const marketsProductsListData = propOr([], 'data', marketsProductsList)
  const marketsSalesOrdersDetailData = prop('data', marketsSalesOrdersDetail)
  const configData = prop('data', config)

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Const
  const canIsLimited = prop('cANISLIMITED', configData)
  const paymentType = prop('paymentType', marketsSalesOrdersDetailData)
  const currencyName = path(['currency', 'name'], marketsSalesOrdersDetailData)

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch
      value={value}
    >
      <DebounceSearch filterActions={filterActions} placeholder={'Название'} />
    </ProductsSearch>

  // ProductsList
  const productsList = mapIndexed((item) => {
    const productName = prop('name', item)
    const productId = prop('id', item)
    const isSelected = find(propEq('product', `${productId}`))(products)
    const selectedCustomPrice = prop('custom_price', isSelected)
    const selectedAmount = prop('amount', isSelected)
    const image = prop('image', item)
    const imageSrc = prop('file', image)
    const measurement = prop('measurement', item)
    const measurementName = path(['measurement', 'name'], item) && path(['measurement', 'name'], item).slice(0, 3)
    const realBalance = prop('balance', item)
    const limitBalance = prop('limitBalance', item)
    const balance = canIsLimited ? limitBalance : realBalance
    const selectedAmountStatus = (selectedAmount <= balance) ? 'active' : 'inactive'
    const formatedCustomPrice = numberFormat(selectedCustomPrice, currencyName)
    const boxes = prop('boxes', item)

    // Cash price
    const cashPrice = prop('cashPrice', item)
    const formatedCashPrice = cashPrice && numberFormat(cashPrice, currencyName)
    const newCashPrice =
      <>
        <TextStatus
          bold
          status={'active'}
          style={{marginRight: '5px'}}
        >
          {formatedCustomPrice}
        </TextStatus>
        <LineThrough>
          {formatedCashPrice}
        </LineThrough>
      </>
    const cashPriceExtra = ((selectedCustomPrice > cashPrice) ||
      (selectedCustomPrice < cashPrice))
      ? newCashPrice
      : formatedCashPrice

    // Points price
    const pointsPrice = prop('ball', item)
    const formatedPointsPrice = pointsPrice && numberFormat(pointsPrice, 'Балл')
    const newPointsPrice =
      <>
        <TextStatus
          bold
          status={'active'}
          style={{marginRight: '5px'}}
        >
          {formatedCustomPrice}
        </TextStatus>
        <LineThrough>
          {formatedPointsPrice}
        </LineThrough>
      </>
    const pointsPriceExtra = ((selectedCustomPrice > pointsPrice) ||
      (selectedCustomPrice < pointsPrice))
      ? newPointsPrice
      : formatedPointsPrice

    // Transfer price
    const transferPrice = prop('transferPrice', item)
    const formatedTransferPrice = transferPrice && numberFormat(transferPrice, currencyName)
    const newTransferPrice =
      <>
        <TextStatus
          bold
          status={'active'}
          style={{marginRight: '5px'}}
        >
          {formatedCustomPrice}
        </TextStatus>
        <LineThrough>
          {formatedTransferPrice}
        </LineThrough>
      </>
    const transferPriceExtra = ((selectedCustomPrice > transferPrice) || (selectedCustomPrice < transferPrice)) ? newTransferPrice : formatedTransferPrice

    // Prices more info
    const price = (paymentType === 'cash') ? cashPriceExtra : (paymentType === 'bank') ? transferPriceExtra : pointsPriceExtra
    const priceNumber = (paymentType === 'cash') ? cashPrice : (paymentType === 'bank') ? transferPrice : pointsPrice
    const handleAddProductsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({
        name: productName,
        price,
        priceNumber,
        amount: balance,
        measurement,
        image,
        boxes,
        transferPrice,
        cashPrice
      })
      addProductIdHandler(productId)
    }
    const amountFrom =
      <span>
        <TextStatus
          status={selectedAmountStatus}
          bold
        >
          {numberFormat(selectedAmount, measurementName)}
        </TextStatus>
      </span>
    const textAmount = isSelected ? amountFrom : ''

    const itemPrice = paymentType === 'cash' ? cashPriceExtra : paymentType === 'bank' ? transferPriceExtra : pointsPriceExtra

    return (
      <ProductsItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        onClick={() => handleAddProductsOpen()}
        isSelected={isSelected}
        amount={textAmount}
        price={itemPrice}
        validAmount={+balance}
      />
    )
  }, marketsProductsListData)

  // MainContent
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_PRODUCTS_LIST}
        actionType={actionTypes.MARKETS_PRODUCTS_LIST}
        emptyData={<Wrap><NoResults /></Wrap>}
        params={marketsEditProductsListSerializer(searchObj, props)}
        style={{padding: '56px 0 50px'}}
        list={productsList || []}
        useWindow
      />
      <BottomBar>
        <Button
          type={'button'}
          text={'Добавить'}
          onClick={() => setValue(0)}
          fullWidth
        />
      </BottomBar>
    </>

  return (
    <>
      {debounceSearch}
      {mainContent}
    </>
  )
}

export default MarketsOrdersEditTabTwo
