import React from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import MainBar from 'components/Navigation/TopBar/MainBar'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'
import {path, prop, propOr} from 'ramda'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import ProductsItem from 'containers/Products/components/ProductsItem/ProductsItem'
import Container from 'components/Container/Container'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {branchesListSerializer} from 'containers/Branches/actions/serializers'

const ProductsSearch = styled('div')`
  position: fixed;
  top: 61px;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.nav.primary.background};
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  transition: height .3s, opacity .3s, padding .3s;
`

const BranchesGrid = props => {
  const {
    filterActions,
    location,
    branchesList,
    goDetail,
    goCreate
  } = props

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Data
  const branchesListData = propOr([], 'data', branchesList)

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        query={'search'}
        placeholder={'Название'}
      />
    </ProductsSearch>

  // ProductsList
  const branchesArr = mapIndexed(item => {
    const id = prop('id', item)
    const name = prop('name', item)
    const address = prop('address', item)
    const imgSrc = path(['image', 'file'], item)

    return (
      <ProductsItem
        status={'active'}
        onClick={() => goDetail(id)}
        key={id}
        title={name}
        subTitle={address || 'Адрес не указан'}
        imgSrc={imgSrc}
      />
    )
  }, branchesListData)

  // MainContent
  const mainContent =
    <Container
      secondHeight={56}
      background={'#fff'}
    >
      <InfiniteScroll
        api={API.STOCKS_LIST}
        actionType={actionTypes.BRANCHES_LIST}
        emptyData={<NoResults />}
        params={branchesListSerializer(searchObj)}
        list={branchesArr || []}
      />
    </Container>

  // SecondMenuList
  const menusArr = [
    {
      title: 'Создать филиал',
      onClick: () => goCreate(),
      withAlert: true,
      perms: ['add_stock']
    }
  ]

  // SecondMenuButton
  const secondMenuButton =
    <Fab
      text={'Дополнительно'}
      withfixed={'true'}
    >
      <Plus size={20} />
    </Fab>

  // SecondMenu
  const secondMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с филиалами'}
      menusArr={menusArr}
      button={secondMenuButton}
    />

  // Render
  return (
    <>
      <MainBar
        title={'Филиалы'}
        firstBtn={
          <MainMenu />
        }
      />
      {debounceSearch}
      {mainContent}
      {secondMenu}
    </>
  )
}

export default BranchesGrid
