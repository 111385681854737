import React, {useEffect, useState} from 'react'
import {Field} from 'react-final-form'
import {prop, map, path} from 'ramda'
import TextField from 'components/Forms/TextField'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import MapField from 'components/Forms/MapField'
import UploadImages from 'components/Forms/UploadField/UploadImages'
import {FieldArray} from 'react-final-form-arrays'
import TelephonesField from 'components/Forms/TelephonesField'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import styled from 'styled-components'
import Loader from 'components/Loader'
import {marketOptionsListFetchAction} from '../../../actions/marketsAction'

const Telephones = styled('div')`
  margin-top: 4px;
`
const StyledWrap = styled('div')`
  padding: 14px 16px 64px;
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const Accuracy = styled('div')`
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  color: #8c8c8c;
`

const FormFields = props => {
  const {
    disabled,
    gpsAddress,
    dispatch,
    updateDialog,
    values,
    optionsData,
    mapLocations
  } = props
  // address  from Field
  const [address, getAddress] = useState(null)
  const marketType = prop('marketType', values)
  // UseEffects
  useEffect(() => {
    marketType && dispatch(marketOptionsListFetchAction(marketType))
  }, [marketType, dispatch])
  const accuracy = prop('accuracy', mapLocations) || 999999
  const optionResults = path(['data', 'results'], optionsData) || []
  const optionSelect = optionResults && marketType && (optionsData.loading ? <Loader size={0.7} />
    : map(option => {
      const fieldType = prop('fieldType', option)
      const values = prop('values', option)
      const optionsField = map((item) => {
        const value = prop('id', item)
        const label = prop('value', item)
        return {value: value, label}
      }, values)
      return fieldType === 'text'
        ? (
          <Field
            name={`marketOptions[${option.id}]`}
            component={TextField}
            key={option.id}
            label={option.name}
            // required={option.isRequired}
            fullWidth
          />
        ) : (
          <Field
            key={option.id}
            name={`marketOptions[${option.id}]`}
            component={StaticSearchField}
            options={optionsField}
            label={option.name}
            itemText={'value'}
            // required={option.isRequired}
            fullWidth
          />
        )
    })(optionResults)
  )
  return (
    <>
      <StyledWrap>
        <Field
          component={TextField}
          required
          type={'text'}
          label={'Наименование'}
          name={'name'}
          placeholder={'Укажите название'}
          fullWidth
        />
        <Field
          component={UniversalSearchField}
          api={API.MARKETS_TYPE}
          params={{parent: 0, page_size: '100'}}
          name={'marketType'}
          label={'Тип заведения'}
          required
          fullWidth
        />
        {values.marketType &&
        <Field
          component={UniversalSearchField}
          api={API.MARKETS_TYPE}
          params={{page_size: '100'}}
          parent={{parent: values.marketType}}
          name={'marketTypeChild'}
          label={'Подкатегория'}
          fullWidth
        />}
        {optionSelect}
        {!address && <Accuracy>Точность: {Math.round(accuracy)} м</Accuracy>}
        <Field
          getAddress={getAddress}
          component={MapField}
          name={'location'}
        />
        <Field
          component={TextField}
          type={'text'}
          label={'Адрес'}
          name={'address'}
          defaultValue={address || updateDialog.address || gpsAddress}
          fullWidth
        />
        <Field
          component={TextField}
          type={'text'}
          label={'Ориентир'}
          name={'guide'}
          fullWidth
        />
        <Field
          component={UploadImages}
          name={'images'}
          label={'Фото'}
          required
          fullWidth
        />
        <Field
          component={TextField}
          type={'text'}
          label={'Контактное лицо'}
          name={'contactName'}
          placeholder={'Укажите имя'}
          fullWidth
        />
        <Telephones>
          <FieldArray
            name={'telephones'}
            component={TelephonesField}
          />
        </Telephones>
      </StyledWrap>
      <BottomBar
        styles={{marginTop: '10px'}}
      >
        <Button
          disabled={disabled}
          type={'submit'}
          text={updateDialog.open ? 'Изменить' : 'Создать'}
          fullWidth
        />
      </BottomBar>
    </>
  )
}

export default FormFields
