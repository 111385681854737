import React from 'react'
import Container from 'components/Container'
import {groupBy, path, pipe, prop, toPairs} from 'ramda'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import {CardsList} from 'components/Cards/CardsList'
import CardItem from 'components/Cards/CardItem'
import CardDescription from 'components/Cards/CardDescription'
import moment from 'moment'
import * as CONST from 'constants/constants'
import numberFormat from 'helpers/numberFormat'
import mapIndexed from 'helpers/mapIndexed'
import sumBy from 'helpers/sumBy'
import NoResults from 'components/NoData/NoResults'
import {Table} from 'components/Table'
import {DetailBar} from 'components/Navigation/TopBar'
import TileItem from 'components/Grid/Tile/TileItem'
import TilesList from 'components/Cards/Tile/TilesList'
import {TextStatus} from 'components/Status/TextStatus'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 0;
`
const Inner = styled('div')`
  padding: 0 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const TabsBar = styled('div')`
  position: fixed;
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e4e4e6;
`
const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`
const Content = styled('div')`
  min-height: calc(100vh - 108px);
  padding-top: 44px;
`

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const MarketsReturnsDetailGrid = (props) => {
  const {
    marketsReturnsItem,
    match,
    goBack
  } = props

  // Data
  const marketsReturnsItemData = prop('data', marketsReturnsItem)

  // Loading
  const marketsReturnsItemLoading = prop('loading', marketsReturnsItem)

  // Tabs configs
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }

  // Tabs content
  const marketId = path(['params', 'id'], match)
  const clientName = path(['client', 'name'], marketsReturnsItemData)
  const marketName = path(['market', 'name'], marketsReturnsItemData)
  const id = prop('id', marketsReturnsItemData)
  const comment = prop('comment', marketsReturnsItemData)
  const createdByFirstName = path(['createdBy', 'firstName'], marketsReturnsItemData)
  const createdBySecondName = path(['createdBy', 'secondName'], marketsReturnsItemData)
  const createdBy = createdByFirstName + ' ' + createdBySecondName
  const createdDate = moment(prop('createdDate', marketsReturnsItemData)).format('DD MMM YYYY')

  const acceptedDateData = prop('acceptedDate', marketsReturnsItemData)
  const acceptedDate = acceptedDateData
    ? moment(acceptedDateData).format('DD MMM YYYY HH:mm')
    : <TextStatus status={'inactive'}>Не установлено</TextStatus>
  const finishedTimeData = prop('finishedTime', marketsReturnsItemData)
  const finishedTime = acceptedDateData && finishedTimeData
    ? moment(finishedTimeData).format('DD MMM YYYY HH:mm')
    : <TextStatus status={'inactive'}>Не установлено</TextStatus>
  const acceptedBy = prop('acceptedBy', marketsReturnsItemData)
  const acceptedByFN = path(['acceptedBy', 'firstName'], marketsReturnsItemData)
  const acceptedBySN = path(['acceptedBy', 'secondName'], marketsReturnsItemData)
  const acceptedByName = acceptedBy
    ? (acceptedByFN + ' ' + acceptedBySN)
    : <TextStatus status={'inactive'}>Не принят</TextStatus>
  const status = +prop('status', marketsReturnsItemData)
  const statusStatus = (status === CONST.ORDER_RETURN_PENDING || status === CONST.ORDER_RETURN_IN_PROGRESS)
    ? 'primary'
    : (status === CONST.ORDER_RETURN_COMPLETED)
      ? 'active'
      : (status === CONST.ORDER_RETURN_CANCELED)
        ? 'inactive'
        : null
  const statusText = (status === CONST.ORDER_RETURN_PENDING || status === CONST.ORDER_RETURN_IN_PROGRESS)
    ? 'Ожидает'
    : (status === CONST.ORDER_RETURN_COMPLETED)
      ? 'Завершен'
      : (status === CONST.ORDER_RETURN_CANCELED)
        ? 'Отменен'
        : null

  const currency = path(['currency', 'name'], marketsReturnsItemData)
  const totalPrice = prop('totalPrice', marketsReturnsItemData)

  const productsList = prop('returnedProducts', marketsReturnsItemData)
  const sortedBy = productsList && pipe(
    groupBy(path(['product', 'id'])),
    toPairs
  )(productsList)
  const productsListAmount = prop('length', sortedBy)
  const stockName = path(['stock', 'name'], marketsReturnsItemData)

  const sortedProducts = productsList && pipe(
    groupBy(path(['product', 'measurement', 'name'])),
    toPairs
  )(productsList)

  const tabContentOne =
    <Inner>
      <TilesList
        title={'Описание возврата'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Клиент'} is={clientName} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Клиент'} is={marketName} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Добавил'} is={createdBy} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата возврата'} is={createdDate} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Комментарий'} is={comment} />
        </TileItem>
      </TilesList>

      <TilesList
        title={'Исполнение'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Текущий статус'} is={<TextStatus bold status={statusStatus}>{statusText}</TextStatus>} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Начало приёмки'} is={acceptedDate} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Конец приёмки'} is={finishedTime} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Принял'} is={acceptedByName} />
        </TileItem>
      </TilesList>
    </Inner>

  const tabContentTwo =
    <Inner>
      <TilesList
        title={'Итого'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Склад'} is={stockName} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кол-во видов товаров'} is={numberFormat(productsListAmount, 'ед')} />
        </TileItem>
        {sortedProducts && mapIndexed((item, index) => {
          const sortName = prop('0', item)
          const arr = prop('1', item)
          const amountSum = sumBy(arr, ['amount'])

          return (
            <TileItem
              height={'auto'}
              key={index}
            >
              <CardDescription it={'Кол-во товаров'} is={numberFormat(amountSum, sortName.slice(0, 3))} />
            </TileItem>
          )
        }, sortedProducts)}
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Сумма'} is={<TextStatus bold status={'primary'}>{numberFormat(totalPrice, currency)}</TextStatus>} />
        </TileItem>
      </TilesList>
      <TilesList title={'Список товаров'}>
        <TileItem
          height={'auto'}
          styles={{'& > div': {padding: '2px 0'}}}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
                <th>Сумма</th>
              </tr>
              {productsList && mapIndexed((item, index) => {
                const productName = path(['product', 'name'], item)
                const amount = prop('amount', item)
                const measurement = (path(['product', 'measurement', 'name'], item)).slice(0, 3)
                const price = prop('price', item)
                const totalPrice = +price * +amount

                return (
                  <tr key={index}>
                    <td colSpan={'2'}>{productName}</td>
                    <td>{numberFormat(amount, measurement)}</td>
                    <td>{numberFormat(totalPrice, currency)}</td>
                  </tr>
                )
              }, productsList)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td> </td>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(totalPrice, currency)}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
    </Inner>

  // Loader
  const loader = <Loader styles={{marginTop: '20px'}} />

  const mainContent =
    <>
      <TabsBar>
        <StyledTabs
          onChange={handleChange}
          value={value}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'Товары'} {...a11yProps(0)} />
          <Tab label={'Описание'} {...a11yProps(1)} />
        </StyledTabs>
      </TabsBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Content value={value} index={0}>
          {marketsReturnsItemLoading ? loader : marketsReturnsItemData ? tabContentTwo : <CardsList><CardItem><NoResults /></CardItem></CardsList>}
        </Content>
        <Content value={value} index={1}>
          {marketsReturnsItemLoading ? loader : marketsReturnsItemData ? tabContentOne : <CardsList><CardItem><NoResults /></CardItem></CardsList>}
        </Content>
      </SwipeableViews>
    </>

  // Render
  return (
    <>
      <DetailBar
        title={id ? ('Возврат № ' + id) : 'Возврат'}
        goBack={() => goBack(marketId)}
      />
      <Container>
        <Wrap>
          {mainContent}
        </Wrap>
      </Container>
    </>
  )
}

export default MarketsReturnsDetailGrid
