let mapLocations
let invalid
let startPos

export const getLocation = () => {
  const geoOptions = {
    enableHighAccuracy: true,
    maximumAge: 0
  }
  const geoSuccess = (position) => {
    startPos = position
    const accuracy = startPos.coords.accuracy
    const latitude = startPos.coords.latitude
    const longitude = startPos.coords.longitude

    mapLocations = {latitude, longitude, accuracy}
  }
  const geoError = (position) => {
    invalid = position
  }

  navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
  navigator.geolocation.watchPosition(geoSuccess, geoError, geoOptions)

  return {
    location: mapLocations,
    error: invalid
  }
}
