import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {ChevronRight as ArrowIcon} from 'react-feather'
import Loader from 'components/Loader'

const Card = styled('li')`
  position: relative;
  transition: ${({theme}) => theme.transition.primary};
  background: ${({loading, theme}) => loading && theme.button.disabled.background} !important;
  ${({styles}) => styles}
  & * {
    color: ${({loading, theme}) => loading && theme.palette.lightGrey} !important;
  }
  & > div{
    padding: ${({onClick}) => onClick ? '12px 20px 12px 0' : '12px 0'};
    margin: 0 12px;
  }
  &:not(:last-child){
    & > div{
      border-bottom: ${({theme}) => theme.border.primary};
    }
  }
  &:hover{
    background: ${({hover}) => hover && '#f7f7f8'};
    transition: ${({theme}) => theme.transition.primary};
  }
  & > svg{
    color: #c7c7cc;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
`
const LoaderWrap = styled('span')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`

const TileItem = props => {
  const {
    children,
    arrowIcon,
    withArrow = true,
    onClick,
    loading,
    styles
  } = props

  return (
    <Card
      styles={styles}
      onClick={!loading ? onClick : undefined}
      hover={!loading && onClick}
    >
      <div>
        {children}
      </div>
      {withArrow && onClick && !arrowIcon && !loading &&
      <ArrowIcon size={'20'} />}
      {(onClick && arrowIcon && !loading) &&
      arrowIcon}
      {loading && <LoaderWrap><Loader size={0.7} /></LoaderWrap>}
    </Card>
  )
}

TileItem.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  withArrow: PropTypes.bool,
  styles: PropTypes.object
}

export default TileItem
