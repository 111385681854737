import React from 'react'
import Container from 'components/Container'
import {prop, path} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import {parseParams} from 'helpers/url'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import * as serializers from 'containers/Clients/actions/serializers/marketsSerializer'
import mapIndexed from 'helpers/mapIndexed'
import ReportsItem from 'containers/Clients/components/ReportsItem/ReportsItem'
import moment from 'moment'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'

const MarketsReportsGrid = (props) => {
  const {
    match,
    goBack,
    goDetail,
    goReportsCreate,
    location,
    marketsReports
  } = props

  // Redirect
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const marketId = path(['params', 'id'], match)

  // Data
  const marketsReportsData = prop('data', marketsReports)

  // Main List
  const mainList = marketsReportsData && mapIndexed((item) => {
    const id = prop('id', item)
    const createdDate = prop('createdDate', item)
    const lastAfterImg = path(['afterImg', '0', 'file'], item) || path(['beforeImg', '0', 'file'], item)

    return (
      <ReportsItem
        title={'Фотоотчёт № ' + id}
        date={moment(createdDate).format('DD MMM YYYY HH:mm')}
        imgSrc={lastAfterImg}
        onClick={() => goDetail(marketId, id)}
        key={id}
      />
    )
  }, marketsReportsData)

  // MainContent
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_REPORTS}
        actionType={actionTypes.MARKETS_REPORTS}
        emptyData={<li><NoResults /></li>}
        params={serializers.marketsReportsSerializer(searchObj, props)}
        list={mainList || []}
      />
    </>

  // Menus arr
  const menusArr = [
    {
      title: 'Создать фотоотчёт',
      onClick: () => goReportsCreate(marketId),
      withAlert: true
    }
  ]

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack(marketId)}
        title={'Фотоотчёты'}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      <SecondMenu
        title={'Дополнительные функции по работе с Фотоотчётами'}
        menusArr={menusArr}
        button={<Fab text={'Дополнительно'} withfixed={'true'}><Plus size={20} color={'#fff'} /></Fab>}
      />
    </>
  )
}

export default MarketsReportsGrid
