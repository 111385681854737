import React from 'react'
import styled from 'styled-components'

const Wrap = styled('div')`
  margin: 0 !important;
  padding: 15px 0;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
  :not(:first-child){
    border-top: 1px solid #e0e4e8;
  }
  ${({styles}) => styles}
`

const ChartBar = ({children, onClick, styles}) => {
  return (
    <Wrap onClick={onClick} styles={styles}>
      {children}
    </Wrap>
  )
}

export default ChartBar
