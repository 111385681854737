import React from 'react'
import styled from 'styled-components'
import {Check as CheckIcon, X as XIcon} from 'react-feather'

const Wrap = styled('div')`
  position: relative;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.024em;
  color: #000;
  padding: 14px 0 14px 25px;
  &:not(:last-child){
    border-bottom: 1px solid #e4e4e6;
  }
  span{
    text-decoration: ${({status}) => status === 'inactive' && 'line-through'};
    color: ${({status}) => status === 'inactive' && '#8c8c8c'};
  }
`
const Checked = styled('span')`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 16px;
  background: ${({status, theme}) => status === 'active' ? theme.palette.orange : status === 'inactive' ? theme.palette.red : '#fff'};
  border: ${({status}) => (status !== 'active' && status !== 'inactive') && '2px solid #c4c4c4'};
  svg{
    color: #fff;
    stroke-width: 3px;
  }
`
const Text = styled('p')``
const SubText = styled('p')`
  color: ${({theme}) => theme.text.secondary};
`

const CheckedTask = (props) => {
  const {
    text,
    status,
    onClick,
    taskClick,
    // onClickObj,
    // type,
    subText
  } = props

  // const handleOnClick = () => {
  //   onClick && onClick()
  //   if (status !== 'active') {
  //     return type && prop(type, onClickObj)()
  //   }
  // }

  return (
    <Wrap
      status={status}
      onClick={onClick}
      // onClick={handleOnClick}
    >
      <Checked
        status={status}
        onClick={taskClick}
      >
        {status === 'active' ? <CheckIcon size={'12px'} /> : status === 'inactive' ? <XIcon size={'12px'} /> : null}
      </Checked>
      <Text>{text}</Text>
      {subText && <SubText>{subText}</SubText>}
    </Wrap>
  )
}

export default CheckedTask
