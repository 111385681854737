import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 15 21'} {...props}>
      <path d="M7.50008 0.916656C3.79133 0.916656 0.791748 3.91624 0.791748 7.62499C0.791748 12.6562 7.50008 20.0833 7.50008 20.0833C7.50008 20.0833 14.2084 12.6562 14.2084 7.62499C14.2084 3.91624 11.2088 0.916656 7.50008 0.916656ZM7.50008 10.0208C6.17758 10.0208 5.10425 8.94749 5.10425 7.62499C5.10425 6.30249 6.17758 5.22916 7.50008 5.22916C8.82258 5.22916 9.89592 6.30249 9.89592 7.62499C9.89592 8.94749 8.82258 10.0208 7.50008 10.0208Z" />
    </SvgIcon>

  )
}
