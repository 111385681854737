import {compose, mapProps, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {sprintf} from 'sprintf-js'
import {getDataFromState, getListParams, getIdFromProps} from 'helpers/get'
import toSnakeCase from 'helpers/toSnakeCase'
import {openSnackbarAction} from 'actions/snackbarAction'
import {paramsToSearch, parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import {openErrorAction} from 'actions/errorsAction'
import {DEFAULT_PICK_PARAMS} from 'helpers/isEquals'

const withCreate = params => {
  const {
    key = 'create',
    action,
    stateName,
    propFromParams,
    secondPropFromParams,
    propFromQuery,
    redirectToCreated,
    redirectUrl,
    onSuccess,
    withCurrentParams,
    successMessage = 'Успешно!',
    withoutMessage,
    addParam,
    successAction,
    serializer = toSnakeCase,
    withOutCatch = false,
    mapper = getListParams,
    pickParams = DEFAULT_PICK_PARAMS,
    redirectDependence,
    secondRedirectUrl
  } = params

  const mapStateToProps = state => ({
    createState: getDataFromState(stateName, state)
  })

  const mapDispatchToProps = {action}

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withHandlers({
      onSubmit: props => values => {
        return props
          .action(serializer(values, props))
          .then(data => {
            const createdId = path(['value', 'id'], data)
            const query = parseParams(path(['location', 'search'], props))
            const redirectToQuery = prop('redirectTo', query)
            const trueRedirectUrl = redirectDependence && secondRedirectUrl && redirectToQuery === redirectDependence
              ? secondRedirectUrl
              : redirectUrl
            const allParams = withCurrentParams ? {...query, ...addParam} : null
            !withoutMessage && props.dispatch(openSnackbarAction({message: successMessage}))
            successAction && props.dispatch(successAction(mapper(props, pickParams), props))
            if (onSuccess) {
              onSuccess(data, {...props, values})
            } else if ((!propFromParams && !propFromQuery) && trueRedirectUrl) {
              props.history.push({
                pathname: trueRedirectUrl,
                search: withCurrentParams && paramsToSearch(allParams)
              })
            } else if (propFromParams && !secondPropFromParams) {
              // Берем из params id с именем (Нужно указать = propFromParams: idMarket)
              const idFromParam = getIdFromProps(propFromParams, props)
              props.history.push({
                pathname: sprintf(trueRedirectUrl, idFromParam),
                search: paramsToSearch(allParams)
              })
            } else if (propFromParams && secondPropFromParams) {
              // Берем из params id с именем (Нужно указать = propFromParams: idMarket)
              const idFromParam = getIdFromProps(propFromParams, props)
              const idFromParamSecond = getIdFromProps(secondPropFromParams, props)
              props.history.push({
                pathname: sprintf(trueRedirectUrl, idFromParam, idFromParamSecond),
                search: paramsToSearch(allParams)
              })
            } else if (propFromQuery) {
              // Берем из query с именем (Нужно указать = propFromQuery: 'openDialog')
              const query = parseParams(path(['location', 'search'], props))
              const idFromQuery = prop([propFromQuery], query)
              props.history.push({
                pathname: sprintf(trueRedirectUrl, idFromQuery),
                search: paramsToSearch(allParams)
              })
            } else if (redirectToCreated) {
              props.history.push({
                pathname: sprintf(redirectToCreated, createdId),
                search: paramsToSearch(allParams)
              })
            }
          })
          .catch((e) => {
            if (withOutCatch) {
              // eslint-disable-next-line no-console
              // console.log('Запрос отправил, но сработал catch... WTF?!  - ' + e)
            } else {
              return props.dispatch(openErrorAction({errMessage: e}))
            }
          })
      }
    }),
    mapProps(({onSubmit, createState, ...props}) => ({
      [key]: {
        onSubmit,
        ...createState
      },
      ...props
    }))
  )
}

export default withCreate
