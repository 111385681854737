export const AUTH = 'auth'
export const CUSTOM_STORE = 'customStore'
export const CONFIG = 'config'
export const DIVISIONS_LIST = 'divisionsList'
export const STOCKS_LIST = 'stocksList'

// Users
export const USERS_LIST_FOR_SELECT = 'usersListForSelect'
export const USERS_DELIVERYMAN_LIST = 'usersDeliverymanList'
export const USER_DETAIL = 'userDetail'
export const USER_UPDATE_PASSWORD = 'userUpdatePassword'
export const USERS_LIST = 'usersList'
export const USERS_DETAIL = 'usersDetail'
export const USERS_DELETE = 'usersDelete'
export const USERS_CREATE = 'usersCreate'
export const USERS_EDIT = 'usersEdit'

// Cashiers
export const CASHIERS_LIST = 'cashiersList'

// Clients
export const MARKETS_LIST = 'marketsList'
export const MARKETS_CREATE = 'marketsCreate'
export const MARKETS_ITEM = 'marketsItem'
export const MARKETS_ITEM_PHOTO = 'marketsItemPhoto'
export const MARKETS_SALES_CLIENT_BALANCES = 'marketsSalesClientBalances'
export const MARKETS_SALES_ORDERS_LIST = 'marketsSalesOrdersList'
export const MARKETS_SALES_ORDERS_DETAIL = 'marketsSalesOrdersDetail'
export const MARKETS_POINTS_LIST = 'marketsPointsList'
export const MARKETS_PRODUCTS_LIST = 'marketsProductsList'
export const MARKETS_ORDERS_CREATE = 'marketsOrdersCreate'
export const MARKETS_PROMOTIONS_LIST = 'marketsPromotionsList'
export const MARKETS_CLIENT_BALANCES = 'marketsClientBalances'
export const MARKETS_RETURNS_PRODUCTS_LIST = 'marketsReturnsProductsList'
export const MARKETS_RETURNS_CREATE_PREVIEW = 'marketsReturnsCreatePreview'
export const MARKETS_RETURNS_CREATE = 'marketsReturnsCreate'
export const MARKETS_RETURNS_LIST = 'marketsReturnsList'
export const MARKETS_RETURNS_ITEM = 'marketsReturnsItem'
export const MARKETS_ACCEPT_DELIVERED = 'marketsAcceptDelivered'
export const MARKETS_VISITS = 'marketsVisits'
export const MARKETS_VISIT_CREATE = 'marketsVisitCreate'
export const MARKETS_PRICE_LIST = 'marketsPriceList'
export const MARKETS_CONTRACTS_LIST = 'marketsContractsList'
export const MARKETS_CONTRACTS_CREATE = 'marketsContractsCreate'
export const MARKETS_TELEGRAMS = 'marketsTelegrams'
export const MARKETS_TELEGRAMS_CREATE = 'marketsTelegramsCreateCreate'
export const MARKETS_STATISTICS_BY_PRODUCTS = 'marketsStatisticsByProducts'
export const MARKETS_TOTAL_STATISTICS = 'marketsTotalStatistics'
export const MARKETS_STATISTICS_BY_AGENTS = 'marketsStatisticsByAgents'
export const MARKETS_STATISTICS_BY_AGENTS_PAYMENTS = 'marketsStatisticsByAgentsPayments'
export const MARKETS_BALANCE_NOTIFICATION_LIST = 'marketsBalanceNotificationList'
export const MARKETS_BALANCE_NOTIFICATION_CREATE = 'marketsBalanceNotificationCreate'
export const MARKETS_REPORTS_CREATE = 'marketsReportsCreate'
export const MARKETS_REPORTS_EDIT = 'marketsReportsEdit'
export const MARKETS_REPORTS = 'marketsReports'
export const MARKETS_REPORTS_DETAIL = 'marketsReportsDetail'
export const MARKETS_REPORTS_DELETE = 'marketsReportsDelete'
export const MARKETS_AGENT_PLANS = 'marketsAgentPlans'
export const MARKETS_PLAN_DELETE = 'marketsPlanDelete'
export const MARKETS_PLAN = 'marketsPlan'
export const MARKETS_PLAN_EDIT = 'marketsPlanEdit'
export const MARKET_OPTIONS_LIST = 'marketOptionsList'
export const PLANS_BY_AGENTS_LIST = 'plansByAgentsList'
export const MARKETS_PLAN_CLEAR = 'marketsPlanClear'
export const MARKETS_PLAN_EDIT_CLEAR = 'marketsPlanEditClear'
export const MARKETS_PLANS_TYPES = 'marketsPlansTypes'
export const MARKETS_AGENTS_LOCATION = 'marketsAgentsLocation'
export const MARKETS_PRODUCT_DETAIL = 'marketsProductDetail'
export const MARKETS_AGENTS_PLANS = 'marketsAgentsPlans'
export const MARKETS_SERVICE_TIME_START = 'marketsServiceTimeStart'
export const MARKETS_SERVICE_TIME_END = 'marketsServiceTimeEnd'
export const MARKETS_SERVICE_TIME_END_CLEAR = 'marketsServiceTimeEndClear'
export const MARKETS_SERVICE_TIME_START_CLEAR = 'marketsServiceTimeStartClear'
export const MARKETS_ORDERS_EDIT = 'marketsOrdersEdit'
export const MARKETS_ORDERS_CANCEL = 'marketsOrdersCancel'

export const MANUFACTURE_BATCHES_EDIT = 'manufactureBatchesEdit'
export const MANUFACTURE_BATCHES_DELETE = 'manufactureBatchesDelete'

// add Bonus for Order
export const BONUS_ORDER_COUNT = 'bonusOrderCount'
export const BONUS_ORDER_LIST = 'bonusOrderList'
export const ORDER_ITEM_ADD_BONUS = 'orderItemAddBonus'

// Statistics
export const STATISTICS_PAYMENTS_BY_AGENT = 'statisticsPaymentsByAgent'
export const STATISTICS_BY_AGENTS = 'statisticsByAgents'
export const STATISTICS_FOR_SUPERVISOR = 'statisticsForSupervisor'
export const STATISTICS_AGENTS_BY_DIVISIONS = 'statisticsAgentsByDivisions'
export const STATISTICS_AGENTS_DEBTS = 'statisticsAgentsDebts'

// Cars
export const CARS_LIST = 'carsList'
export const CARS_BRANDS_LIST = 'carsBrandsList'
export const CARS_CREATE = 'carsCreate'
export const CARS_DETAIL = 'carsDetail'
export const CARS_ORDERS_CREATE = 'carsOrdersCreate'
export const CARS_ORDERS_FINISH_CREATE = 'carsOrdersFinishCreate'
export const CARS_ORDERS_ADD_PRODUCTS = 'carsOrdersAddProducts'
export const CARS_ORDERS_ADD_SERVICES = 'carsOrdersAddServices'
export const CARS_SERVICES_LIST = 'carsServicesList'
export const CARS_ORDERS_LIST = 'carsOrdersList'
export const CARS_ORDERS_DETAIL = 'carsOrdersDetail'
export const CARS_ORDERS_CANCEL = 'carsOrdersDelete'
export const CARS_PRODUCTS_ITEM_UPDATE = 'carsProductsItemUpdate'

// Finances
export const FINANCES_CASHIERS_LIST = 'financesCashierList'
export const FINANCES_TRANSACTIONS_LIST = 'financesTransactionsList'
export const FINANCES_TRANSACTIONS_CREATE = 'financesTransactionsCreate'
export const FINANCES_ACCEPT_CASH_LIST = 'financesAcceptCashList'
export const FINANCES_CLIENT_TRANSACTION_LIST = 'financesClientTransactionList'
export const FINANCES_ACCEPT_CLIENT_TRANSACTION = 'financesAcceptClientTransaction'
export const FINANCES_CLIENT_TRANSACTION_CREATE = 'financesClientTransactionCreate'
export const FINANCES_CLIENT_TRANSACTION_PREVIEW = 'financesClientTransactionPreview'
export const FINANCES_CLIENT_TRANSACTION_ITEM = 'financesClientTransactionItem'
export const FINANCES_TRANSFER_CASH_CREATE = 'financesTransferCashCreate'
export const PAYMENTS_CONVERT = 'paymentsConvert'
export const FINANCES_DEBIT_CATEGORY = 'financesDebitCategory'
export const FINANCES_CREDIT_CATEGORY = 'financesCreditCategory'

// RequestFunds
export const REQUEST_FUNDS_LIST = 'requestFundsList'
export const REQUEST_FUNDS_CREATE = 'requestFundsCreate'
export const REQUEST_FUNDS_ITEM = 'requestFundsItem'

// Notification
export const NOTIFICATION_LIST = 'notificationList'
export const NOTIFICATION_DELETE = 'notificationDelete'
export const NOTIFICATION_GET_NOT_VIEWED = 'notificationGetNotViewed'

// Files
export const FILE_UPLOAD = 'fileUpload'

// Stocks
export const STOCKS_INCOME_TASKS = 'stocksIncomeTasks'
export const STOCKS_OUTCOME_TASKS = 'stocksOutcomeTasks'
export const STOCKS_ACCEPT_OUTCOME = 'stocksAcceptOutcome'
export const ORDERS_ACCEPT_OUTCOME = 'ordersAcceptOutcome'
export const STOCKS_DETAILS = 'stocksDetails'
export const STOCKS_SELECTIONS = 'stocksSelections'
export const STOCKS_SELECTIONS_CREATE = 'stocksSelectionsCreate'
export const STOCKS_SELECTIONS_PRODUCTS_LIST = 'stocksSelectionsProductsList'
export const STOCKS_SELECTIONS_CREATE_END = 'stocksSelectionsCreateEnd'
export const STOCKS_RESTS_LIST = 'stocksRestsList'
export const STOCKS_ACCEPT_ACTION = 'stocksAcceptAction'

// GPS
export const GPS_SENDER = 'gpsSender'
export const GET_ADDRESS = 'getAddress'

// POS
export const POS_CHECKS = 'posChecks'
export const POS_CHECKS_DETAIL = 'posChecksDetail'
export const POS_DEBTS = 'posDebts'
export const POS_DEBTS_DETAIL = 'posDebtsDetail'
export const POS_TOTAL_STATISTICS = 'posTotalStatistics'
export const POS_STATISTICS_POPULAR_PRODUCTS = 'posStatisticsPopularProducts'
export const POS_STATISTICS_REVENUE = 'posStatisticsRevenue'

// DeliveriesOld
export const DELIVERIES_PLAN = 'deliveriesPlan'

// Plans
export const PLANS_BY_AGENTS_DETAIL = 'plansByAgentsDetail'

// Manufacture
export const MANUFACTURE_LIST = 'manufactureList'
export const MANUFACTURE_BATCHES_LIST = 'manufactureBatchesList'
export const MANUFACTURE_PRODUCTS_LIST = 'manufactureProductsList'
export const MANUFACTURE_PRODUCTS_DETAIL = 'manufactureProductsDetail'
export const EQUIPMENT_LIST = 'equipmentList'
export const MARKETS_MATERIALS_LIST = 'manufactureMaterialsList'
export const MANUFACTURE_BATCHES_CREATE = 'manufactureBatchesCreate'
export const MANUFACTURE_BATCHES_DETAIL = 'manufactureBatchesDetail'
export const MANUFACTURE_BATCHES_ADD_PRODUCTS = 'manufactureBatchesAddProducts'
export const MANUFACTURE_BATCHES_ADD_MATERIALS = 'manufactureBatchesAddMaterials'
export const MANUFACTURE_BATCHES_FINISH_CREATE = 'manufactureBatchesFinishCreate'
export const MANUFACTURE_BATCHES_PRODUCT_EDIT = 'manufactureBatchesProductEdit'
export const MANUFACTURE_BATCHES_PRODUCT_DELETE = 'manufactureBatchesProductDelete'
export const MANUFACTURE_BATCHES_MATERIAL_EDIT = 'manufactureBatchesMaterialEdit'
export const MANUFACTURE_BATCHES_MATERIAL_DELETE = 'manufactureBatchesMaterialDelete'
export const MANUFACTURE_SHIPMENT_END = 'manufactureShipmentEnd'

// Products
export const PRODUCTS_LIST = 'productsList'
export const PRODUCTS_DETAIL = 'productsDetail'
export const PRODUCTS_DELETE = 'productsDelete'
export const PRODUCTS_CREATE = 'productsCreate'
export const PRODUCTS_EDIT = 'productsEdit'

// Products Types
export const PRODUCTS_TYPES_LIST = 'productsTypesList'
export const PRODUCTS_TYPES_CREATE = 'productsTypesCreate'
export const PRODUCTS_TYPES_EDIT = 'productsTypesEdit'
export const PRODUCTS_TYPES_ITEM = 'productsTypesItem'
export const PRODUCTS_TYPES_DELETE = 'productsTypesDelete'
export const PRODUCTS_TYPES = 'productsTypes'

// Branches
export const BRANCHES_LIST = 'branchesList'
export const BRANCHES_DETAIL = 'branchesDetail'
export const BRANCHES_DELETE = 'branchesDelete'
export const BRANCHES_CREATE = 'branchesCreate'
export const BRANCHES_EDIT = 'branchesEdit'

// Orders
export const ORDERS_CREATE = 'ordersCreate'

// SettingsBot
export const SETTINGS_BOT = 'settingsBot'
export const SETTINGS_BOT_EDIT = 'settingsBotEdit'
