import {compose} from 'recompose'
import {connect} from 'react-redux'
import * as ROUTES from 'constants/routes'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import TasksCreateGrid from 'containers/Clients/Grid/TasksCreate/Grid/TasksCreateGrid'
import {parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import {
  marketsBalanceNotificationCreateAction,
  marketsPlansTypesAction
} from '../../actions/marketsAction'

export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const customStore = prop('customStore', state)
    return {
      query,
      customStore
    }
  }),
  withFetchList({
    action: marketsPlansTypesAction,
    key: STATES.MARKETS_PLANS_TYPES,
    stateName: STATES.MARKETS_PLANS_TYPES
  }),
  withCreate({
    stateName: [STATES.MARKETS_BALANCE_NOTIFICATION_CREATE],
    action: marketsBalanceNotificationCreateAction,
    key: [STATES.MARKETS_BALANCE_NOTIFICATION_CREATE],
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true,
    propFromParams: 'id',
    successMessage: 'Успех'
  }),
  withRedirect({
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    key: 'goBack',
    withCurrentParams: false
  })
)(TasksCreateGrid)
