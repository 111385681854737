import {compose} from 'recompose'
import Grid from './Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {usersDeleteAction, usersDetailAction} from 'containers/Users/actions/usersActions'
import withCreate from 'hocs/withCreate'

export default compose(
  withFetchList({
    stateName: STATES.USERS_DETAIL,
    action: usersDetailAction,
    key: STATES.USERS_DETAIL
  }),
  withCreate({
    stateName: STATES.USERS_DELETE,
    action: usersDeleteAction,
    key: STATES.USERS_DELETE,
    redirectToCreated: ROUTES.USERS_URL,
    successMessage: 'Сотрудник удалён'
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.USERS_URL
  }),
  withRedirect({
    key: 'goEdit',
    redirectUrl: ROUTES.USERS_EDIT_URL
  })
)(Grid)
