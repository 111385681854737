import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 55px);
  h3{
    color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : theme.palette.black
};
  }
`
const Text = styled('p')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: column nowrap;
`
const Icon = styled('span')`
  background: ${({theme, iconBackground}) => iconBackground || theme.palette.greyOpacity};
  color: ${({theme, iconColor}) => iconColor || theme.text.secondary};
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  svg{
    max-width: 22px;
    max-height: 22px;
  }
`

const TileInner = props => {
  const {
    icon,
    iconColor,
    iconBackground,
    title,
    status = 'pending',
    text
  } = props

  return (
    <Wrap>
      {icon &&
      <Icon
        iconColor={iconColor}
        iconBackground={iconBackground}
      >
        {icon}
      </Icon>}
      <RightBar
        status={status}
      >
        <h3>{title}</h3>
        <Text>{text}</Text>
      </RightBar>
    </Wrap>
  )
}

TileInner.propTypes = {
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  iconBackground: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  status: PropTypes.string,
  text: PropTypes.any
}

export default TileInner
