import {isOpenToTopForMenu} from 'constants/styles'

export default (theme, params) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: theme.input.primary.background,
    boxShadow: null,
    borderRadius: theme.input.primary.borderRadius,
    transition: theme.transition.primary,
    height: params.menuIsOpen ? '40px' : '52px',
    minHeight: '32px',
    border: 'none',
    zIndex: params.menuIsOpen ? '102' : '2',
    borderBottom: theme.input.primary.border,
    position: 'relative'
  }),
  indicatorSeparator: () => ({}),
  loadingIndicator: (provided, state) => ({
    ...provided,
    '& span': {
      background: state.isFocused
        ? theme.text.primary
        : theme.text.secondary
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display: params.menuIsOpen ? 'flex' : 'none',
    marginRight:  params.menuIsOpen && '10px',
    color: theme.text.secondary,
    padding: '0'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: params.menuIsOpen ? 'none' : 'flex',
    alignItems: 'center',
    color: state.isFocused
      ? theme.palette.blue
      : theme.input.primary.backgroundIndicator,
    marginRight: '12px',
    padding: '0',
    transition: 'color 300ms, transform 150ms',
    transform: params.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)'
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 1500
  }),
  menu: provided => ({
    ...provided,
    boxShadow: 'none',
    borderRadius: '0px 0px 12px 12px',
    margin: '0',
    ...isOpenToTopForMenu(params.focus)
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 'calc(100vh - 400px)',
    minHeight: '50px',
    padding: '7px'
  }),
  option: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: 'inherit',
    cursor: 'pointer',
    fontSize: theme.fontSize.primary,
    padding: '15px 12px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #E4E4E6'
    },
    position: 'relative',
    '&:before': {
      content: state.isSelected
        ? '" "' : state.isFocused ? '" "' : null,
      zIndex: '-1',
      // position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '-7px',
      right: '-7px',
      background: params.menuIsOpen ? 'rgba(241, 241, 242, 0.51)' : 'rgba(241, 241, 242, 0.51)'
    },
    transition: theme.transition.primary
  }),
  valueContainer: (provided, state) => {
    return {
      ...provided,
      padding: params.menuIsOpen ? '8px' : '12px',
      fontSize: theme.fontSize.primary
    }
  },
  singleValue: (provided) => ({
    ...provided,
    fontSize: theme.fontSize.primary,
    color: '#000'
  }),
  placeholder: provided => ({
    ...provided,
    paddingLeft: '5px',
    color: theme.text.secondary,
    margin: '0'
  }),
  noOptionsMessage: provided => ({
    ...provided,
    color: theme.text.secondary
  }),
  loadingMessage: provided => ({
    ...provided,
    color: theme.text.secondary
  }),

  multiValue: (provided, state) => {
    const hasValueFocused = state.hasValue && params.menuIsOpen
    return {
      ...provided,
      backgroundColor: hasValueFocused
        ? theme.input.backgroundColor
        : '#FAFBFB',
      border: '1px solid transparent',
      borderRadius: theme.input.borderRadius.primary,
      transition: theme.transition.primary,
      margin: '4px',
      '&:hover': {
        borderColor: theme.input.borderColor
      },
      '&:focus': {
        color: 'red !important'
      }
    }
  },
  multiValueLabel: provided => ({
    ...provided,
    fontSize: 'inherit',
    padding: '7px 0',
    paddingLeft: '12px',
    paddingRight: '6px'
  }),
  multiValueRemove: provided => ({
    ...provided,
    alignSelf: 'center',
    borderRadius: '50%',
    color: theme.text.secondary,
    cursor: 'pointer',
    justifyContent: 'center',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '12px',
    transition: theme.transition.primary,
    height: '20px',
    width: '20px',
    '&:hover': {
      backgroundColor: '#8591A3',
      color: 'white'
    },
    '& svg': {
      height: '16px',
      width: '16px'
    }
  })
})
