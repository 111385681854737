import React from 'react'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {CardFieldsFilter} from 'components/Cards/CardFieldsFilter'
import TextField from 'components/Forms/TextField'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField'
import {map, prop} from 'ramda'

const StyledWrap = styled('div')`
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = (props) => {
  const {
    stocks
  } = props

  const stocksListOptions = stocks && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, stocks)

  return (
    <StyledWrap>
      <Field
        component={StaticSearchField}
        name={'stock'}
        label={'Склад'}
        type={'select'}
        options={stocksListOptions}
        fullWidth
      />
      <Field
        component={TextField}
        name={'beginDate'}
        type={'date'}
        label={'Период с'}
        fullWidth
      />
      <Field
        component={TextField}
        name={'endDate'}
        type={'date'}
        label={'Период до'}
        fullWidth
      />
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
          fixed
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {setOpenFilter, onChangeFilter, initialValues, stocks} = props

  const handleSubmit = (values) => {
    setOpenFilter(false)
    onChangeFilter({
      beginDate: prop('beginDate', values),
      endDate: prop('endDate', values),
      stock: prop('stock', values),
      date: prop('date', values)
    })
  }

  return (
    <CardFieldsFilter>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <FormFields
          stocks={stocks}
        />
      </Form>
    </CardFieldsFilter>
  )
}

export default Filter
