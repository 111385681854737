import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import {filter, map, mergeAll, path, pipe, prop, propOr, toPairs} from 'ramda'
import UsersCreateForm from 'containers/Users/Grid/Create/Grid/Forms/UsersCreateForm'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const UsersEditGrid = props => {
  const {
    usersDetail,
    usersEdit,
    stocksList,
    goBack,
    config,
    match
  } = props

  // Location
  const userId = path(['params', 'id'], match)

  // Data
  const usersEditLoading = prop('loading', usersEdit)
  const configData = prop('data', config)
  const usersDetailData = prop('data', usersDetail)
  const usersDetailLoading = prop('loading', usersDetail)

  // Const
  const priceListDefault = prop('pRICELISTIDFORTELEGRAM', configData)
  const currencyDefault = prop('pRIMARYCURRENCYID', configData)
  const username = prop('username', usersDetailData)
  const firstName = prop('firstName', usersDetailData)
  const secondName = prop('secondName', usersDetailData)
  const phoneNumber = prop('phoneNumber', usersDetailData)
  const trackThePlan = prop('trackThePlan', usersDetailData)
  const positionId = path(['position', 'id'], usersDetailData)
  const isActive = prop('isActive', usersDetailData)
  const photo = prop('photo', usersDetailData)
  const stocksArr = propOr([], 'stocks', usersDetailData)
  const stocks = pipe(
    map(item => {
      return {
        [`stock_${prop('id', item)}`]: true
      }
    }),
    mergeAll
  )(stocksArr)

  // InitialValues
  const initialValues = {
    username,
    firstName,
    secondName,
    phoneNumber: phoneNumber ? +phoneNumber : null,
    trackThePlan,
    position: positionId,
    isActive,
    images: photo ? [{image: photo}] : null,
    ...stocks
  }

  // HandleSubmit
  const handleSubmit = values => {
    const imagesArr = propOr([], 'images', values)
    const photo = path(['0', 'image', 'fileId'], imagesArr)
    const trueStocks = pipe(
      filter((item) => !!item),
      toPairs,
      map((item) => {
        const key = prop('0', item)
        if (key.startsWith('stock_')) {
          return key
        }
        return false
      }),
      filter((item) => !!item),
      map((item) => {
        return item.split('stock_')[1]
      })
    )(values)

    const data = {
      id: userId,
      username: prop('username', values),
      first_name: prop('firstName', values),
      second_name: prop('secondName', values),
      phone_number: prop('phoneNumber', values),
      track_the_plan: prop('trackThePlan', values),
      password: prop('password', values),
      position: prop('position', values),
      is_active: prop('isActive', values),
      stocks: trueStocks,
      photo,
      price_lists: [priceListDefault],
      currencies: [currencyDefault]
    }

    usersEdit.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        haveMutators
      >
        <UsersCreateForm
          loading={usersEditLoading}
          stocksList={stocksList}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Регистрация сотрудника'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {usersDetailLoading ? <Loader /> : mainContent}
      </Container>
    </>
  )
}

export default UsersEditGrid
