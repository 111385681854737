import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import ProductsCreateForm from 'containers/Products/Grid/Create/Grid/Forms/ProductsCreateForm'
import {path, prop, propOr} from 'ramda'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const ProductsCreateGrid = props => {
  const {
    productsCreate,
    productsTypes,
    goBack
  } = props

  // Data
  const productsCreateLoading = prop('loading', productsCreate)

  // InitialValues
  const initialValues = {
    showInMarket: true
  }

  // HandleSubmit
  const handleSubmit = values => {
    const imagesArr = propOr([], 'images', values)
    const image = path(['0', 'image', 'fileId'], imagesArr) || path(['0', 'image', 'id'], imagesArr)
    const data = {
      name: prop('name', values),
      type: path(['type', 'value'], values),
      measurement: prop('measurement', values),
      price: prop('price', values),
      description: prop('description', values),
      image,
      stockNumber: prop('stockNumber', values),
      showInMarket: prop('showInMarket', values)
    }

    productsCreate.onSubmit(data)
  }

  // MainContent
  // don`t set GetValues. You will break ProductTypeEndlessSearchField
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        haveMutators
      >
        <ProductsCreateForm
          productsCreateLoading={productsCreateLoading}
          productsTypes={productsTypes}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Создание продукта'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default ProductsCreateGrid
