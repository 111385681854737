import React from 'react'
import styled from 'styled-components'
import {map, prop, path} from 'ramda'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import Form from 'components/Forms/Form'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import * as API from 'constants/api'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Loader from '../../../../../components/Loader'
import normalizeNumber from '../../../../../helpers/normalizers/normalizeNumber'
import UniversalSearchField from '../../../../../components/Forms/SearchField/UniversalSearchField'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const LoadWrap = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

const TwoFields = styled('div')`
  display:flex;
  & > :first-child {
    width: 50%;
  }
    & > :last-child {
    width: 50%;
    margin-left: 10px;
  }
`

const HeadComment = styled('div')`
  margin-bottom: 15px;
  span {
    color: #000;
  }
`

const RequestFundsCreateGrid = props => {
  const {
    goBack,
    requestFundsCreate,
    updateDialog,
    customStore
  } = props
  // data
  const customStoreLoading = prop('loading', customStore)
  const customStoreData = prop('data', customStore)
  const disabled = prop('loading', requestFundsCreate)
  // Submit
  const handleMainSubmit = props => {
    (updateDialog.open !== 'false' && updateDialog.open) ? updateDialog.onSubmit({open: updateDialog.open, nextStatus: updateDialog.nextStatus, ...props}) : requestFundsCreate.onSubmit(props)
  }

  const FormFields = ({values}) => {
    const currency = path(['currency', 'value'], values) || prop('currency', values) || null
    return (
      <StyledWrap>
        <TwoFields>
          <Field
            component={TextField}
            type={'text'}
            name={'amount'}
            label={'Сумма'}
            parse={normalizeNumber}
            required
            fullWidth
          />
          <Field
            component={StaticSearchField}
            name={'currency'}
            placeholder={'Выберите'}
            label={'Валюта'}
            loading={customStoreLoading}
            options={currenciesOptions}
            required
            fullWidth
          />
        </TwoFields>
        {updateDialog.open && currency &&
        <Field
          component={UniversalSearchField}
          api={API.FINANCES_CASHIERS_LIST}
          params={{type: 'cash', page_size: '1000'}}
          parent={{currency: currency}}
          name={'cashbox'}
          label={'Кассы'}
          required
          fullWidth
        />}
        <Field
          component={TextField}
          type={'text'}
          multiline
          name={'comment'}
          label={'Описание'}
          fullWidth
        />
        <BottomBar>
          <Button
            disabled={disabled}
            type={'submit'}
            text={updateDialog.open ? 'Подтвердить' : 'Создать'}
            fullWidth
          />
        </BottomBar>
      </StyledWrap>
    )
  }

  // currency from config
  const currencies = prop('currencies', customStoreData)
  const currenciesOptions = currencies && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, currencies)
  return (
    <>
      <CreateBar
        title={updateDialog.open ? 'Подтверждение' : 'Создание запроса'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {
            updateDialog.loading
              ? (<LoadWrap><Loader size={2} /></LoadWrap>)
              : (
                <>
                  {(updateDialog.open !== 'false' && updateDialog.open) &&
                  <HeadComment>
                    <span>Описание:</span> {updateDialog.comment}
                  </HeadComment>}
                  <Form
                    keepDirtyOnReinitialize
                    onSubmit={handleMainSubmit}
                    initialValues={updateDialog.open ? updateDialog.initialValues : {}}
                  >
                    <FormFields />
                  </Form>
                </>
              )
          }
        </Wrap>
      </Container>
    </>
  )
}

export default RequestFundsCreateGrid
