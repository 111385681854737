import {compose, withHandlers, withState} from 'recompose'
import MarketsBalancesGrid from 'containers/Clients/Grid/Balances/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withFetchList, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import {connect} from 'react-redux'
import {map, path, pathOr, pipe, prop} from 'ramda'
import {divisionsListAction} from 'actions/divisionsAction'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {marketsTotalStatisticsAction} from 'containers/Clients/actions/marketsAction'
import {deleteClientTransactionAction} from 'containers/Finances/actions/financesAction'
import {openSnackbarAction} from 'actions/snackbarAction'
import {openErrorAction} from 'actions/errorsAction'

export default compose(
  connect((state, props) => {
    const financesClientTransactionList = prop('financesClientTransactionList', state)
    const marketsBalanceNotificationList = prop('marketsBalanceNotificationList', state)
    const config = prop('config', state)
    const userPerms = pipe(pathOr([], ['customStore', 'data', 'permissions']), map(prop('codename')))(state)
    const isAdmin = path(['customStore', 'data', 'isSuperuser'], state)
    const search = path(['location', 'search'], props)
    const searchObj = parseParams(search)
    return {
      financesClientTransactionList,
      marketsBalanceNotificationList,
      config,
      userPerms,
      isAdmin,
      searchObj
    }
  }),
  withFetchList({
    stateName: STATES.DIVISIONS_LIST,
    action: divisionsListAction,
    key: STATES.DIVISIONS_LIST
  }),
  withFetchList({
    stateName: STATES.MARKETS_TOTAL_STATISTICS,
    action: marketsTotalStatisticsAction,
    key: [STATES.MARKETS_TOTAL_STATISTICS],
    pickParams: ['division', 'beginDate', 'endDate', 'type', 'reload']
  }),
  withFilter({
    fields: ['division', 'beginDate', 'endDate', 'type']
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goBackStatistics',
    redirectUrl: ROUTES.STATISTICS_MAIN_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goOrdersDetail',
    redirectUrl: ROUTES.MARKETS_ORDERS_DETAIL_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goReturnsDetail',
    redirectUrl: ROUTES.MARKETS_RETURNS_DETAIL_URL,
    withCurrentParams: true
  }),
  withState('openDelete', 'setOpenDelete', false),
  withHandlers({
    handleSetBalancesId: props => balanceId => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({balanceId}, path(['history', 'location', 'search'], props))
      })
    },
    handleDeleteBalance: props => () => {
      const {
        dispatch,
        history,
        searchObj,
        setOpenDelete
      } = props
      const balanceId = prop('balanceId', searchObj)
      dispatch(deleteClientTransactionAction(balanceId))
        .then(() => {
          history.push({
            search: `reload=${balanceId + 1}`
          })
          setOpenDelete(false)
          return dispatch(openSnackbarAction({message: 'Успешно удалено'}))
        })
        .catch(({...e}) => dispatch(openErrorAction({errMessage: e})))
    }
  })
)(MarketsBalancesGrid)
