import React, {useEffect, useState} from 'react'
import Container from 'components/Container'
import {prop, path, reject, find, isEmpty, map} from 'ramda'
import {CreateBar} from 'components/Navigation/TopBar'
import mapIndexed from 'helpers/mapIndexed'
import * as CONST from 'constants/constants'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'
import StocksOrdersItem from 'containers/Stocks/components/StocksOrdersItem/StocksOrdersItem'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {Check as CheckIcon, Minus as MinusIcon} from 'react-feather'
import {parseParams} from 'helpers/url'
import Loader from 'components/Loader'

const ProductsSearch = styled('div')`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.nav.primary.background};
  z-index: 100;
  padding: 10px 55px 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  height: 56px;
  overflow: hidden;
`
const CheckAll = styled('div')`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30px;
  height: 30px;
  & > div{
    background: ${({disabled}) => disabled ? '#ebebec' : 'transparent'};
    box-shadow: inset 0 0 3px 0 #ccc;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    content: "";
    display: flex;
    justify-content: center;
    align-content: center;
  }
  svg{
    color: ${({theme, disabled}) => disabled ? theme.palette.lightGrey : theme.palette.blue};
    margin-top: 4px;
    stroke-width: 4px;
  }
`
const Content = styled('div')`
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  &.react-swipeable-view-container{
    height: auto !important;
  }
`
const TabsWrap = styled('div')`
  height: calc(100vh - 110px);
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
`
const ContentOne = styled('ul')`
  padding-top: 54px;
`

const StocksSelectionsCreateGrid = (props) => {
  const {
    marketsSalesOrdersList,
    stocksSelectionsCreate,
    filterActions,
    goBack,
    goNext,
    location
  } = props

  // useStates
  const [selectedProducts, setSelectedProducts] = useState([])

  // Data
  const marketsSalesOrdersListData = prop('results', marketsSalesOrdersList)
  const marketsSalesOrdersListLoading = prop('loading', marketsSalesOrdersList)
  const stocksSelectionsCreateData = prop('data', stocksSelectionsCreate)
  const stocksSelectionsCreateLoading = prop('loading', stocksSelectionsCreate)

  const checkData = Array.isArray(marketsSalesOrdersListData) ? marketsSalesOrdersListData : []
  const allOrders = marketsSalesOrdersListData && map((item) => {
    return prop('id', item)
  }, marketsSalesOrdersListData)
  const allOrdersLength = prop('length', allOrders)
  const selectedProductsLength = prop('length', selectedProducts)
  const stocksSelectionsCreateId = prop('id', stocksSelectionsCreateData)

  // useEffect
  useEffect(() => {
    if (stocksSelectionsCreateId) {
      return goNext(stocksSelectionsCreateId)
    }
  }, [stocksSelectionsCreateId, goNext])

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const searchQuery = prop('search', searchObj)

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        placeholder={'Название'}
      />
      <CheckAll
        onClick={() => {
          if ((allOrdersLength === selectedProductsLength) && !searchQuery) {
            setSelectedProducts([])
          } else if (!searchQuery) {
            setSelectedProducts(allOrders)
          }
        }}
        disabled={!!searchQuery}
      >
        <div>
          {isEmpty(selectedProducts)
            ? null
            : ((allOrdersLength === selectedProductsLength) && !searchQuery)
              ? <CheckIcon size={16} />
              : <MinusIcon size={16} />}
        </div>
      </CheckAll>
    </ProductsSearch>

  // Select
  const handleProductSelect = (id) => {
    if (find(i => i === id)(selectedProducts)) {
      const newArray = reject((i) => i === id, selectedProducts)
      setSelectedProducts(newArray)
    } else {
      const newArray = [...selectedProducts, id]
      setSelectedProducts(newArray)
    }
  }

  // Main List
  const mainList = marketsSalesOrdersListData && mapIndexed((item) => {
    const id = prop('id', item)
    const orderNumber = prop('orderNumber', item)
    const marketName = path(['market', 'name'], item)
    const address = path(['market', 'address'], item)
    const dateDelivery = prop('dateDelivery', item)

    const status = +prop('status', item)
    const deliveryStatus = (status === CONST.ORDER_DELIVERED)
      ? 'active'
      : (status === CONST.ORDER_CANCELED)
        ? 'inactive'
        : 'pending'

    return (
      <StocksOrdersItem
        key={id}
        productId={id}
        selectedProducts={selectedProducts}
        onClick={handleProductSelect}
        orderNumber={orderNumber}
        market={marketName}
        date={dateDelivery}
        deliveryStatus={deliveryStatus}
        address={address}
      />
    )
  }, checkData)

  // Button configs
  const buttonText = 'Продолжить подбор'
  const buttonFunc = () => {
    stocksSelectionsCreate.onSubmit({
      order: selectedProducts
    })
  }
  const buttonDisabled =
    isEmpty(selectedProducts) || !!stocksSelectionsCreateId || !!stocksSelectionsCreateLoading

  // Content
  const content =
    <ContentOne>
      {mainList}
    </ContentOne>

  // Main content
  const mainContent =
    <>
      <TabsWrap>
        <Content>
          {marketsSalesOrdersListLoading
            ? <Loader styles={{marginTop: '20px'}} />
            : content}
        </Content>
        <BottomBar>
          <Button
            type={'button'}
            text={buttonText}
            onClick={() => buttonFunc()}
            disabled={buttonDisabled}
            fullWidth
          />
        </BottomBar>
      </TabsWrap>
    </>

  // Render
  return (
    <>
      <CreateBar
        goExit={goBack}
        title={'Создание подбора'}
      />
      <Container
        background={'#fff'}
      >
        {debounceSearch}
        {mainContent}
      </Container>
    </>
  )
}

export default StocksSelectionsCreateGrid
