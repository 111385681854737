import React from 'react'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'
import styled from 'styled-components'
import logoIcon from 'img/logoIcon.png'
import Switcher from 'components/Forms/Switcher'
import PropTypes from 'prop-types'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'

const TopBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 12px;
`
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  p{
    color: ${({theme}) => theme.text.secondary};
  }
`
const tileItemStyles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flexStart'
  }
}
const tileListStyles = {
  '& > h2 > b': {
    fontSize: '17px'
  }
}

const ExtensionsItem = props => {
  const {
    title,
    description,
    imgSrc = logoIcon,
    handleChange,
    date,
    switcherName,
    price,
    switcherValue = false,
    currency,
    loading
  } = props

  // Render
  return (
    <TilesList
      title={title}
      textButton={{
        text: numberFormat(price, currency)
      }}
      style={tileListStyles}
    >
      <TileItem
        styles={tileItemStyles}
      >
        <TopBar>
          <PhotoWithStatus
            imgSrc={imgSrc}
            status={'none'}
            size={'50px'}
          />
          <RightBar>
            <p>{description}</p>
          </RightBar>
        </TopBar>
        <Switcher
          loading={loading}
          label={loading === 'true'
            ? <TextStatus bold status={'primary'}>Подключение . . .</TextStatus>
            : date
              ? <TextStatus bold status={'primary'}>{`Активно до ${date}`}</TextStatus>
              : 'Активировать'}
          name={switcherName}
          defaultChecked={switcherValue ? 'checked' : undefined}
          onChange={handleChange}
        />
      </TileItem>
    </TilesList>
  )
}

ExtensionsItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  date: PropTypes.string,
  loading: PropTypes.oneOf(['true', 'false']),
  switcherName: PropTypes.string.isRequired
}

export default ExtensionsItem
