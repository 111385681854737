import React from 'react'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import NoResults from 'components/NoData/NoResults'
import mapIndexed from 'helpers/mapIndexed'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import * as serializers from 'containers/Clients/actions/serializers/marketsSerializer'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {sprintf} from 'sprintf-js'
import {DetailBar} from 'components/Navigation/TopBar'
import {parseParams} from 'helpers/url'
import PointsItem from 'containers/Clients/components/PointsItem/PointsItem'
import CardDescription from 'components/Cards/CardDescription'
import numberFormat from 'helpers/numberFormat'

const MarketsPointsGrid = props => {
  const {
    goBack,
    location,
    marketsPointsList,
    handleSetPointsId
  } = props

  // Data
  const marketsPointsListDataProp = prop('data', marketsPointsList)
  const marketsPointsListData = Array.isArray(marketsPointsListDataProp) ? marketsPointsListDataProp : []

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const marketId = path(['match', 'params', 'id'], props)

  const mainList = marketsPointsListData && mapIndexed((item, index) => {
    const productName = path(['orderProduct', 'product', 'name'], item)
    const createdDate = prop('createdDate', item)
    const ball = prop('ball', item)
    const id = prop('id', item)
    const amount = prop('amount', item)
    const orderId = path(['orderProduct', 'orderId'], item)
    const type = prop('type', item)
    const typeText = (type === 'delivered')
      ? 'Доставлен'
      : (type === 'order_return_accept')
        ? 'Возврат'
        : (type === 'order_prize')
          ? 'Призовой'
          : 'Индивидуальный'
    const promotion = path(['promotion', 'name'], item)

    return (
      <PointsItem
        key={id}
        name={productName}
        points={ball}
        pointId={id}
        date={createdDate}
        setQuery={handleSetPointsId}
        searchObj={searchObj}
        amount={amount}
        typeText={typeText}
        promotion={promotion}
      >
        <CardDescription it={'Заказ'} is={orderId} />
        <CardDescription it={'Кол-во'} is={numberFormat(amount)} />
        <CardDescription it={'Тип'} is={typeText} />
        <CardDescription it={'Акция'} is={promotion} />
      </PointsItem>
    )
  }, marketsPointsListData)

  const mainContent =
    <>
      <InfiniteScroll
        api={sprintf(API.MARKETS_POINTS_LIST, marketId)}
        actionType={actionTypes.MARKETS_POINTS_LIST}
        emptyData={<li><NoResults /></li>}
        params={serializers.marketsPointsListSerializer(searchObj)}
        list={mainList || []}
      />
    </>

  // Render
  return (
    <>
      <DetailBar
        goBack={() => goBack(marketId)}
        title={'Баллы'}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default MarketsPointsGrid
