import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {
  equipmentListAction,
  manufactureProductsDetailAction,
  manufactureBatchesCreateAction
} from 'containers/Productions/actions/ProductionsBatchesAction'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const manufactureProductsList = prop('manufactureProductsList', state)
    return {
      manufactureProductsList
    }
  }),
  withCreate({
    stateName: STATES.MANUFACTURE_BATCHES_CREATE,
    action: manufactureBatchesCreateAction,
    key: STATES.MANUFACTURE_BATCHES_CREATE,
    successMessage: 'Партия успешно создана',
    redirectToCreated: ROUTES.PRODUCTIONS_BATCHES_DETAIL_URL
  }),
  withFetchList({
    stateName: STATES.MANUFACTURE_PRODUCTS_DETAIL,
    action: manufactureProductsDetailAction,
    key: STATES.MANUFACTURE_PRODUCTS_DETAIL
  }),
  withFetchList({
    stateName: STATES.EQUIPMENT_LIST,
    action: equipmentListAction,
    key: STATES.EQUIPMENT_LIST
  }),
  withRedirect({
    key: 'goExit',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_URL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTIONS_BATCHES_CREATE_STEP_TWO_URL
  })
)(Grid)
