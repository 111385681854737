import Grid from './Grid'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect(state => {
    return {
      config: prop('config', state)
    }
  })
)(Grid)
