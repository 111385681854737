import React, {useState} from 'react'
import Container from 'components/Container'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import styled from 'styled-components'
import {includes, isEmpty, map, mergeAll, pathOr, pipe, prop, propEq, propOr, reject} from 'ramda'
import moment from 'moment'
import {parseParams} from 'helpers/url'
import StatisticsFilters from './StatisticsByAgentsFilters'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import mapIndexed from 'helpers/mapIndexed'
import StatisticsByAgentsItem from 'containers/Statistics/components/StatisticsByAgentsItem/StatisticsByAgentsItem'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import TilesList from 'components/Cards/Tile/TilesList'

const Div = styled('div')`
  position: fixed;
  height: 49px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e4e8;
  top: 61px;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${({theme}) => theme.nav.primary.background};
  & > *:not(:last-child) {
    border-right: ${({theme}) => theme.border.primary};
  }
`
const Wrap = styled('div')`
  display: flex;
  padding: 14px 16px;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`
const CustomButton = styled('div')`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({active}) => active ? 500 : 400};
  background: ${({active}) => active ? '#ffffff9e' : 'inherit'};
  cursor: pointer;
`
const GraphOptions = styled('li')`
  display: flex;
  flex-flow: column wrap;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.35px;
  color: #000;
  padding: 12px;
  &:not(:first-child){
    border-top: 1px solid #e0e4e8;
  }
`
const Option = styled('span')`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 18px;
  &:before{
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${props => props.color || '#ccc'};
    border-radius: 50%;
  }
`

const StatisticsByAgentsGrid = (props) => {
  const {
    location,
    filterActions,
    handleSetPeriod,
    statisticsAgentsByDivisions
  } = props

  // useStates
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // Data
  const statisticsAgentsByDivisionsData = propOr([], 'results', statisticsAgentsByDivisions)
  const statisticsAgentsByDivisionsLoading = prop('loading', statisticsAgentsByDivisions)

  // Constants
  const today = moment().format('YYYY-MM-DD')
  const divisions = pathOr([], ['0', 'divisions'], statisticsAgentsByDivisionsData)
  const maxProgress = pathOr([], ['0', 'totalSales'], statisticsAgentsByDivisionsData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const beginDate = prop('beginDate', searchObj) || today
  const endDate = prop('endDate', searchObj) || today
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)
  const isToday = (beginDate === today) && (endDate === today)
  const isMonth = (beginDate === firstDayOfMonth) && (endDate === lastDayOfMonth)
  const initialDivisions = propOr(null, 'trueDivisions', searchObj)
  const initialDivisionsArr = (initialDivisions && initialDivisions.split(',')) || []
  const trueDivisions = map((item) => `division_${item}`, initialDivisionsArr)
  const allDivisions = map((item) => `division_${prop('id', item)}`, divisions)
  const finallyDivisionsArr = initialDivisions ? trueDivisions : allDivisions
  const finallyDivisions = pipe(
    map((item) => {
      return ({
        [item]: true
      })
    }),
    mergeAll
  )(finallyDivisionsArr)

  // Initial values
  filterActions.initialValues = {
    beginDate,
    endDate,
    ...finallyDivisions
  }

  // Period
  const period =
    <>
      <CustomButton
        active={isToday}
        onClick={() => handleSetPeriod(today, today)}
      >
        За день
      </CustomButton>
      <CustomButton
        active={isMonth}
        onClick={() => handleSetPeriod(firstDayOfMonth, lastDayOfMonth)}
      >
        За месяц
      </CustomButton>
      <CustomButton
        active={!isToday && !isMonth}
        onClick={() => setFilterIsOpen(true)}
      >
        Своя дата
      </CustomButton>
    </>

  // GraphInfo
  const graphInfo =
    <TilesList>
      <GraphOptions>
        {mapIndexed((item) => {
          const id = prop('id', item)
          const name = prop('name', item)
          const color = prop('color', item) || '#69768d'

          if (includes(`${id}`, initialDivisionsArr) || isEmpty(initialDivisionsArr)) {
            return (
              <Option key={id} color={color}>{name}</Option>
            )
          }
          return false
        }, divisions)}
      </GraphOptions>
    </TilesList>

  // GraphList
  const graphList = mapIndexed((item) => {
    const id = prop('id', item)
    const agent = prop('agent', item)
    const divisions = propOr([], 'divisions', item)
    const filteredDivisions = reject(propEq('internalTotalPrice', 0), divisions)

    return (
      <StatisticsByAgentsItem
        key={id}
        name={agent}
        maxProgress={maxProgress}
        divisions={filteredDivisions}
        currentDivisions={initialDivisionsArr}
      />
    )
  }, statisticsAgentsByDivisionsData)

  // Main content
  const mainContent =
    <>
      {graphInfo}
      {graphList}
    </>

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
      initialValues={filterActions.initialValues}
      divisions={divisions}
    >
      <StatisticsFilters
        setFilterIsOpen={setFilterIsOpen}
        searchObj={searchObj}
        divisions={divisions}
        loading={statisticsAgentsByDivisionsLoading}
        {...filterActions}
      />
    </FiltersBar>

  // Render
  return (
    <>
      <MainBar
        title={'Статистика продаж'}
        firstBtn={
          <MainMenu />
        }
        lastBtn={filters}
      />
      <Container
        secondHeight={48}
      >
        <Div>
          {period}
        </Div>
        <Wrap>
          {statisticsAgentsByDivisionsLoading
            ? <Loader />
            : !isEmpty(statisticsAgentsByDivisionsData)
              ? mainContent
              : <NoResults text={'Нет данных за этот период'} />}
        </Wrap>
      </Container>
    </>
  )
}

export default StatisticsByAgentsGrid
