import React from 'react'
import Container from 'components/Container'
import {
  find,
  path,
  prop,
  propEq,
  reject
} from 'ramda'
import {
  Trash2 as TrashIcon
} from 'react-feather'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import mapIndexed from 'helpers/mapIndexed'
import {Modal} from 'components/Modal'
import {parseParams} from 'helpers/url'
import numberFormat from 'helpers/numberFormat'
import {Field} from 'react-final-form'
import Form from 'components/Forms/Form'
import {TextStatus} from 'components/Status/TextStatus'
import {CreateBar} from 'components/Navigation/TopBar'
import BottomBar from 'components/Navigation/BottomBar'
import ModalConfirm from 'components/Modal/ModalConfirm'
import normalizeNumber from 'helpers/normalizers/normalizeNumber'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'
import OrderAddBonusItem from '../../../components/OrderAddBonusItem/OrderAddBonusItem'
import NoResults from 'components/NoData/NoResults'
import Loader from 'components/Loader'

const StyledWrap = styled('div')`
  ${({styles}) => styles};
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

const StyledUl = styled('ul')`
  overflow: auto;
  padding-bottom: 50px;
`

const HeadText = styled('h3')`
    margin-top: 10px;
    text-align: center;
    font-size: 17px;
    line-height: 22px;
    color: #8f8e94;
    span {
      color: #000
    }
`

const OrderDetailAddBonusGrid = props => {
  const {
    goBack,
    modalOpen,
    setModalOpen,
    confirmModalOpen,
    setConfirmModalOpen,
    //
    addProductIdHandler,
    addBonusConfirmModal,
    location,
    list
  } = props
  const marketId = path(['params', 'id'], props.match)
  const orderId = path(['params', 'orderId'], props.match)

  // data
  const data = prop('data', list)
  const dataLoading = prop('loading', list)
  const productsArr = prop('results', data)

  // Count Data
  const dataCount = prop('countData', list)
  const countLoading = prop('countLoading', list)
  const count = prop('bonusCount', dataCount)

  // UseStates
  const [products, setProducts] = React.useState([])
  const [selectedProduct, setSelectedProduct] = React.useState({})
  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)
  // Content
  const productsList = productsArr && mapIndexed((item) => {
    const productName = prop('name', item)
    const productId = prop('id', item)
    const isSelected = find(propEq('product', `${productId}`))(products)
    const selectedBalance = numberWithoutSpaces(prop('balance', isSelected))
    const imageSrc = path(['image', 'file'], item)
    const measurementName = path(['measurement', 'name'], item).slice(0, 3)
    const balance = prop('balance', item)
    const selectedBalanceStatus = (+selectedBalance <= +balance) ? 'active' : 'inactive'

    // Prices more info
    const handleAddProductsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({name: productName, id: productId})
      addProductIdHandler(productId)
    }
    const balanceFrom =
      <span>
        <TextStatus
          status={selectedBalanceStatus}
          bold
        >
          {numberFormat(selectedBalance, measurementName)}
        </TextStatus>
        {' из '}
        <TextStatus
          bold
        >
          {numberFormat(balance, measurementName)}
        </TextStatus>
      </span>
    const textBalance = isSelected ? balanceFrom : <TextStatus>{numberFormat(balance, measurementName)}</TextStatus>

    return (
      <OrderAddBonusItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        onClick={() => handleAddProductsOpen()}
        isSelected={isSelected}
        balance={textBalance}
        validBalance={+balance}
      />
    )
  }, productsArr)

  const content =
    dataLoading
      ? <Loader styles={{marginTop: '20px'}} />
      : productsArr
        ? (
          <StyledUl>
            {productsList}
          </StyledUl>
        )
        : <NoResults />

  // Submits
  const handleAddProductsSubmit = (values) => {
    const balancePlus = 'balance_' + productIdParam
    const newObject = {
      product: productIdParam,
      product_name: prop('name', selectedProduct),
      product_id: prop('id', selectedProduct),
      balance: prop(balancePlus, values)
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('balance', '0'), newProducts)
    setProducts(sortedProducts)
    setModalOpen(false)
  }

  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productIdParam), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  const handleMainSubmit = (values) => {
    const dataSerializer = {
      products: products.map((i) => ({
        amount: numberWithoutSpaces(prop('balance', i)),
        product: prop('product_id', i)
      })),
      orderId: orderId
    }
    addBonusConfirmModal.onSubmit(dataSerializer)
  }

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        message={'Подтвердите добавление бонуса'}
        loading={addBonusConfirmModal.loading}
        type={'submit'}
      />
    </>

  const balanceName = 'balance_' + productIdParam

  const addProductsModal =
    <Modal
      open={modalOpen}
      isOpen={setModalOpen}
      title={prop('name', selectedProduct) || 'Добавление продукта'}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={() => handleDeleteProduct()} />}
    >
      <Form
        onSubmit={handleAddProductsSubmit}
        keepDirtyOnReinitialize
      >
        <StyledWrap>
          <Field
            component={TextField}
            type={'text'}
            name={balanceName}
            parse={normalizeNumber}
            label={'Кол - во'}
            required
            fullWidth
          />
          <Button
            text={'Применить'}
            fullWidth
          />
        </StyledWrap>
      </Form>
    </Modal>

  const formContent =
    <>
      <HeadText>
        Доступно бонусов: <span>{countLoading ? 'загружаем...' : count}</span>
      </HeadText>
      {content}
      <BottomBar>
        <Button
          type={'button'}
          text={'Сохранить'}
          onClick={() => setConfirmModalOpen(true)}
          fullWidth
        />
      </BottomBar>
      {modals}
    </>

  // Render
  return (
    <>
      <CreateBar
        title={'Добавить бонус'}
        goExit={() => goBack(+marketId, +orderId, {})}
      />
      <Container
        background={'#fff'}
      >
        <Form
          onSubmit={handleMainSubmit}
        >
          {formContent}
        </Form>
        {addProductsModal}
      </Container>
    </>
  )
}

export default OrderDetailAddBonusGrid
