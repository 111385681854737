import {compose, withHandlers} from 'recompose'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import MarketsMapGrid from 'containers/Clients/Grid/Map/Grid'
import {withFetchList} from 'hocs'
import * as STATES from 'constants/states'
import {marketsItemAction} from 'containers/Clients/actions/marketsAction'

export default compose(
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: [STATES.MARKETS_ITEM]
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withHandlers({
    goYandexNav: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'navigator')
      } else {
        window.location.replace('yandexnavi://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
      }
    },
    goYandexMaps: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'yandex')
      } else {
        window.location.replace('yandexmaps://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
      }
    },
    goGoogleNav: () => (lat, lon) => {
      if (typeof window.Android !== 'undefined') {
        window.Android.openMaps(lat.toString(), lon.toString(), 'google')
      } else {
        window.location.replace('google.navigation:q=' + lat + ',' + lon)
      }
    },
    goAppleMaps: () => (lat, lon) => {
      window.location.replace('maps://maps.google.com/maps?daddr=' + lat + ',' + lon + '&amp;ll=')
    }
  })
)(MarketsMapGrid)
