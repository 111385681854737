import React from 'react'
import {isEmpty, path, prop, propOr} from 'ramda'
import mapIndexed from 'helpers/mapIndexed'
import MarketsPlanItem from 'containers/Clients/components/MarketsPlanItem/MarketsPlanItem'
import NoResults from 'components/NoData/NoResults'
import Loader from 'components/Loader'
import {isAndroid} from 'constants/constants'

const MarketsPlansList = props => {
  const {
    marketsAgentPlans,
    history,
    search,
    setSelectedMarket,
    setIsOpenMapsMenu,
    customStoreLoading,
    goOrdersDetail
  } = props

  // Data
  const marketsAgentPlansData = propOr([], 'data', marketsAgentPlans)
  const marketsAgentPlansFailed = prop('failed', marketsAgentPlans)
  const marketsAgentPlansLoading = prop('loading', marketsAgentPlans)

  // PlanMarkets
  const planList = mapIndexed((item, index) => {
    const marketName = path(['market', 'name'], item)
    const marketId = path(['market', 'id'], item)
    const address = path(['market', 'address'], item)
    const planTasks = prop('plans', item)
    const distance = prop('distance', item)
    const lat = path(['market', 'location', 'lat'], item)
    const lon = path(['market', 'location', 'lon'], item)
    const handleCall = () => isAndroid ? window.Android.call(marketName) : (window.location.href = `tel://${marketName}`)

    return (
      <MarketsPlanItem
        key={index}
        marketId={marketId}
        title={marketName}
        address={address}
        planTasks={planTasks}
        history={history}
        search={search}
        mainOnClick={handleCall}
        navClick={() => {
          setSelectedMarket({
            id: marketId,
            lat,
            lon
          })
          setIsOpenMapsMenu(true)
        }}
        taskClick={goOrdersDetail}
        distance={distance}
      />
    )
  }, marketsAgentPlansData)

  // MarketsPlansList
  const marketsPlansList =
    <ul>
      {planList}
    </ul>

  // MarketsPlansContent
  const marketsPlansContent = (isEmpty(marketsAgentPlansData) && (marketsAgentPlansLoading || customStoreLoading))
    ? <Loader styles={{marginTop: '20px'}} />
    : isEmpty(marketsAgentPlansData) || marketsAgentPlansFailed
      ? <NoResults text={'Нет плана'} />
      : marketsPlansList

  return (
    <>
      {marketsPlansContent}
    </>
  )
}

export default MarketsPlansList
