import React from 'react'
import styled from 'styled-components'
import TileItem from 'components/Grid/Tile/TileItem'
import NoResults from 'components/NoData/NoResults'

const Caption = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 7px;
`
const Title = styled('span')`
  color: ${({theme}) => theme.palette.black};
  font-weight: 500;
`
const Date = styled('span')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.text.secondary};
`
const ImgWrap = styled('div')`
  background: ${({theme}) => theme.skeleton.primary};
  min-height: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img{
    width: 100%;
  }
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'column nowrap'
  }
}

const ReportsItem = props => {
  const {
    title,
    date,
    imgSrc,
    onClick
  } = props

  return (
    <TileItem
      height={'auto'}
      onClick={onClick}
      styles={styles}
    >
      <Caption style={{display: 'flex', width: '100%'}}>
        <Title>{title}</Title>
        <Date>{date}</Date>
      </Caption>
      <ImgWrap>
        {imgSrc
          ? <img src={imgSrc} alt={'Ритм.Доставка'} />
          : <NoResults height={'40vw'} />}
      </ImgWrap>
    </TileItem>
  )
}

export default ReportsItem
