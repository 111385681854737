import React, {useEffect, useState} from 'react'
import Container from 'components/Container'
import {map, path, prop, propEq, propOr, reject} from 'ramda'
import styled from 'styled-components'
import {CreateBar} from 'components/Navigation/TopBar'
import MarketsReportsEditStepOne from 'containers/Clients/Grid/ReportsEdit/Grid/Tabs/MarketsReportsEditStepOne'
import MarketsReportsEditStepTwo from 'containers/Clients/Grid/ReportsEdit/Grid/Tabs/MarketsReportsEditStepTwo'
import {parseParams} from 'helpers/url'
import AddProductsModal from 'containers/Clients/Grid/ReportsEdit/Grid/Modals/AddProductsModal'
import sumBy from 'helpers/sumBy'

const TabWrap = styled('div')`
  overflow-x: ${({value, show}) => value !== show && 'hidden'};
  overflow-y: ${({value, show}) => value !== show ? 'hidden' : 'auto'};
  height: ${({value, show, height}) => value !== show ? '0' : (height || '100%')};
  margin: ${({value, show}) => value !== show && '0'};
  padding: ${({value, show}) => value !== show && '0'};
  & button {
    display: ${({value, show}) => value !== show && 'none'};
  }
`

const MarketsReportsEditGrid = props => {
  const {
    marketsReportsDetail,
    marketsReportsEdit,
    marketsProductsList,
    config,
    filterActions,
    location,
    match,
    goBack,
    addProductIdHandler
  } = props

  // useState
  const [value, setValue] = useState(0)
  const [products, setProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  // Data
  const configData = prop('data', config)
  const marketsReportsDetailData = prop('data', marketsReportsDetail)

  // Const
  const canIsLimited = prop('cANISLIMITED', configData)
  const totalAmount = sumBy(products, ['amount'])
  const productsInitial = propOr([], 'products', marketsReportsDetailData)

  // useEffect
  useEffect(() => {
    const mappedInitialProducts = map(item => {
      const productId = path(['product', 'id'], item)
      const productName = path(['product', 'name'], item)
      const productMeasurement = path(['product', 'measurement'], item)
      const productAmount = +prop('amount', item)
      const productImage = path(['product', 'image'], item)
      return {
        product: `${productId}`,
        product_name: productName,
        measurement: productMeasurement,
        amount: productAmount,
        image: productImage
      }
    }, productsInitial)
    setProducts(mappedInitialProducts)
  }, [productsInitial])

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const marketId = path(['params', 'id'], match)
  const reportId = path(['params', 'reportId'], match)
  const productId = prop('productId', searchObj)

  // InitialValuesProducts
  const initialValuesProducts = {}

  // MainContent
  const mainContent =
    <>
      <TabWrap
        value={value}
        show={0}
      >
        <MarketsReportsEditStepOne
          marketsReportsDetail={marketsReportsDetail}
          match={match}
          location={location}
          marketsReportsEdit={marketsReportsEdit}
          confirmModalOpen={confirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          setValue={setValue}
          products={products}
          setModalOpen={setModalOpen}
          setSelectedProduct={setSelectedProduct}
          addProductIdHandler={addProductIdHandler}
          totalAmount={totalAmount}
        />
      </TabWrap>
      <TabWrap
        value={value}
        show={1}
      >
        <MarketsReportsEditStepTwo
          value={value}
          setValue={setValue}
          filterActions={filterActions}
          searchObj={searchObj}
          configData={configData}
          marketsProductsList={marketsProductsList}
          products={products}
          canIsLimited={canIsLimited}
          addProductIdHandler={addProductIdHandler}
          setSelectedProduct={setSelectedProduct}
          setModalOpen={setModalOpen}
          totalAmount={totalAmount}
        />
      </TabWrap>
    </>

  // Submits
  const handleAddProductsSubmit = values => {
    const amountPlus = 'amount_' + productId
    const amount = prop(amountPlus, values)
    const newObject = {
      product: productId,
      product_name: prop('name', selectedProduct),
      measurement: prop('measurement', selectedProduct),
      amount,
      image: prop('image', selectedProduct)
    }

    const leftObject = propEq('product', productId)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('amount', '0'), newProducts)

    setProducts(sortedProducts)

    setModalOpen(false)
  }
  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productId), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  // Modals
  const modals =
    <>
      <AddProductsModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedProduct={selectedProduct}
        location={location}
        initialValues={initialValuesProducts}
        handleSubmit={handleAddProductsSubmit}
        handleDelete={handleDeleteProduct}
      />
    </>

  // Render
  return (
    <>
      <CreateBar
        title={'Фотоотчёт №' + reportId}
        goExit={() => goBack(marketId)}
        goBack={value !== 0 ? () => setValue(value - 1) : undefined}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      {modals}
    </>
  )
}

export default MarketsReportsEditGrid
