import React from 'react'
import styled from 'styled-components'
import CardDescription from 'components/Cards/CardDescription'
import {isEmpty, map, path, prop, propOr} from 'ramda'
import TilesList from 'components/Cards/Tile/TilesList'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import UploadImages from 'components/Forms/UploadField/UploadImages'
import TextField from 'components/Forms/TextField'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {parseParams} from 'helpers/url'
import ModalConfirm from 'components/Modal/ModalConfirm'
import mapIndexed from 'helpers/mapIndexed'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import {TextStatus} from 'components/Status/TextStatus'
import numberFormat from 'helpers/numberFormat'
import NoResults from 'components/NoData/NoResults'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 0 0;
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
    margin-bottom: 15px;
  }
`
const Description = styled('ul')`
  & > * {
    padding: 12px 0;
    min-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
`
const StyledWrap = styled('div')`
  padding: 15px 0;
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const PhotosWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  & > div{
    width: calc(50% - 5px);
    &:not(:last-child){
      padding-right: 10px;
    } 
  }
`
const Title = styled('h2')`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px;
`
const InnerWrap = styled('div')`
  margin: 0 16px;
`

// Forms
const CommentsForm = (props) => {
  const {
    setConfirmModalOpen,
    modals
  } = props

  return (
    <>
      <StyledWrap>
        <PhotosWrap>
          <Field
            component={UploadImages}
            name={'beforeImage'}
            label={'До'}
            single
            required
            fullWidth
          />
          <Field
            component={UploadImages}
            name={'afterImage'}
            label={'После'}
            single
            required
            fullWidth
          />
        </PhotosWrap>
        <Field
          component={TextField}
          name={'comment'}
          label={'Комментарий'}
          fullWidth
          multiline
        />
      </StyledWrap>
      <BottomBar>
        <Button
          type={'button'}
          text={'Отправить'}
          onClick={() => setConfirmModalOpen(true)}
          fullWidth
        />
      </BottomBar>
      {modals}
    </>
  )
}

const MarketsReportsEditStepOne = props => {
  const {
    marketsReportsDetail,
    match,
    location,
    marketsReportsEdit,
    confirmModalOpen,
    setConfirmModalOpen,
    setValue,
    products,
    setModalOpen,
    setSelectedProduct,
    addProductIdHandler,
    totalAmount
  } = props

  // Data
  const marketsReportsDetailData = prop('data', marketsReportsDetail)
  const marketsReportsDetailLoading = prop('loading', marketsReportsDetail)
  const marketsReportsEditLoading = prop('loading', marketsReportsEdit)

  // Const
  const marketsName = path(['market', 'name'], marketsReportsDetailData)
  const marketType = path(['market', 'marketType', 'name'], marketsReportsDetailData)

  const comment = prop('comment', marketsReportsDetailData)
  const beforeImage = propOr([], 'beforeImg', marketsReportsDetailData)
  const afterImage = propOr([], 'afterImg', marketsReportsDetailData)

  // Location
  const marketId = path(['params', 'id'], match)
  const reportId = path(['params', 'reportId'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const taskIdQuery = prop('taskId', searchObj)

  // InitialValues
  const initialValuesObj = {
    comment: comment,
    beforeImage: map((i) => {
      return {
        image: i
      }
    }, beforeImage),
    afterImage: map((i) => {
      return {
        image: i
      }
    }, afterImage)
  }

  // Description
  const description =
    <InnerWrap>
      <Title>Описание</Title>
      <Description>
        <li><CardDescription it={'Клиент'} is={marketsName} /></li>
        <li><CardDescription it={'Категория'} is={marketType} /></li>
        <li><CardDescription it={'Кол-во товаров'} is={<TextStatus bold status={'primary'}>{numberFormat(totalAmount, 'ед')}</TextStatus>} /></li>
      </Description>
    </InnerWrap>

  // ProductsList
  const productsList = mapIndexed((item) => {
    const productId = prop('product', item)
    const productName = prop('product_name', item)
    const measurement = prop('measurement', item)
    const image = prop('image', item)
    const measurementName = path(['measurement', 'name'], item)
    const imageSrc = path(['image', 'file'], item)
    const amount = prop('amount', item)
    const textAmount =
      <TextStatus
        status={'active'}
        bold
      >
        {numberFormat(amount, measurementName)}
      </TextStatus>

    const handleAddProductsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({
        name: productName,
        amount: amount,
        measurement,
        image
      })
      addProductIdHandler(productId)
    }

    return (
      <ProductsItem
        key={productId}
        name={productName}
        imageSrc={imageSrc}
        amount={textAmount}
        isSelected
        onClick={() => handleAddProductsOpen()}
      />
    )
  }, products)

  // Products
  const productsContent =
    <TilesList
      title={'Товары'}
      textButton={{
        text: 'Добавить',
        onClick: () => setValue(1)
      }}
      style={{marginBottom: '50px', '& > h2': {padding: '0 16px'}}}
    >
      {!isEmpty(products) ? productsList : <li><NoResults styles={{height: '200px'}} text={'Выберите товары'} /></li>}
    </TilesList>

  // Modals
  const modals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Для продолжения подтвердите возврат'}
      loading={marketsReportsEditLoading}
      type={'submit'}
    />

  // Submits
  const handleMainSubmit = values => {
    const data = {
      ...values,
      marketId,
      taskIdQuery,
      products,
      reportId
    }
    marketsReportsEdit.onSubmit(data)
  }

  // Form
  const form =
    <InnerWrap>
      <Form
        onSubmit={handleMainSubmit}
        initialValues={initialValuesObj}
      >
        <CommentsForm
          setConfirmModalOpen={setConfirmModalOpen}
          modals={modals}
        />
      </Form>
    </InnerWrap>

  const mainContent =
    <>
      <Wrap>
        {description}
        {form}
        {productsContent}
      </Wrap>
    </>

  // Render
  return (
    <>
      {marketsReportsDetailLoading ? <Loader /> : mainContent}
    </>
  )
}

export default MarketsReportsEditStepOne
