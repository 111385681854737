import React, {useState} from 'react'
import {PhotoBar} from 'components/Navigation/TopBar'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import {path, prop, propOr} from 'ramda'
import ModalConfirm from 'components/Modal/ModalConfirm'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {
  deliveriesBranchesOrdersSerializer
} from 'containers/Clients/actions/serializers/marketsSerializer'
import {paramsToSearch, parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import * as CONST from 'constants/constants'
import OrdersItem from 'containers/Clients/components/OrdersItem/OrdersItem'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import {Navigation as NavIcon} from 'react-feather'
import {sprintf} from 'sprintf-js'
import * as ROUTES from 'constants/routes'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  min-height: ${({secondHeight}) => secondHeight && `calc(100vh - ${secondHeight + 60}px)`};
`
const Href = styled('span')`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  svg{
    font-size: 24px;
    margin-right: 2px;
    margin-top: 2px;
  }
`

const DeliveriesBranchesDetailGrid = props => {
  const {
    marketsSalesOrdersList,
    branchesDetail,
    branchesDelete,
    match,
    goBack,
    history,
    location,
    goAppleMaps,
    goGoogleNav,
    goYandexNav,
    goYandexMaps
  } = props

  // useState
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [isOpenMapsMenu, setIsOpenMapsMenu] = useState(false)

  // Location
  const branchId = path(['params', 'id'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Data
  const branchesDetailData = prop('data', branchesDetail)
  const branchesDetailLoading = prop('loading', branchesDetail)
  const branchesDeleteLoading = prop('loading', branchesDelete)
  const marketsSalesOrdersListData = propOr([], 'data', marketsSalesOrdersList)

  // Const
  const branchName = prop('name', branchesDetailData)
  const branchAddress = prop('address', branchesDetailData)
  const coverPhoto = path(['image', 'file'], branchesDetailData)

  // SecondHeight
  const secondHeight = (!branchesDetailLoading && coverPhoto) ? 200 : 50

  const callIcon =
    <Href>
      <NavIcon />
    </Href>

  const navButtons = [
    {
      icon: callIcon,
      withAlert: true,
      perms: ['add_market'],
      onClick: () => setIsOpenMapsMenu(true)
    }
  ]

  // Main List
  const mainList = mapIndexed(item => {
    const id = prop('id', item)
    const market = path(['market', 'id'], item)
    const orderNumber = prop('orderNumber', item)
    const totalPrice = prop('totalPrice', item)
    const currency = path(['currency', 'name'], item)
    const createdDate = prop('createdDate', item)
    const status = +prop('status', item)
    const paymentStatus = status === 3 ? 'active' : status === 4 ? 'inactive' : 'pending'
    const deliveryStatus = (status === CONST.ORDER_DELIVERED)
      ? 'active'
      : (status === CONST.ORDER_CANCELED)
        ? 'inactive'
        : 'pending'
    const handleGoDetail = (market, id) => {
      history.push({
        pathname: sprintf(ROUTES.MARKETS_ORDERS_DETAIL_URL, market, id),
        search: paramsToSearch({...searchObj, redirectTo: 'branches', fromBranch: branchId})
      })
    }

    return (
      <OrdersItem
        key={id}
        orderNumber={orderNumber}
        totalPrice={totalPrice}
        currency={currency}
        paymentStatus={paymentStatus}
        date={createdDate}
        deliveryStatus={deliveryStatus}
        onClick={() => handleGoDetail(market, id)}
      />
    )
  }, marketsSalesOrdersListData)

  // MainContent
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_SALES_ORDERS_LIST}
        actionType={actionTypes.MARKETS_SALES_ORDERS_LIST}
        emptyData={<li><NoResults /></li>}
        params={deliveriesBranchesOrdersSerializer(searchObj, props)}
        list={mainList || []}
      />
    </>

  // MapsMenuList
  const nativeMaps =
    ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1))
      ? {title: 'Apple Maps', onClick: () => goAppleMaps(prop('lat', branchesDetailData), prop('lon', branchesDetailData))}
      : {title: 'Google Карты', onClick: () => goGoogleNav(prop('lat', branchesDetailData), prop('lon', branchesDetailData))}

  const mapsMenuArr = [
    {
      title: 'Яндекс.Навигатор',
      onClick: () => goYandexNav(prop('lat', branchesDetailData), prop('lon', branchesDetailData))
    },
    {
      title: 'Яндекс.Карта',
      onClick: () => goYandexMaps(prop('lat', branchesDetailData), prop('lon', branchesDetailData))
    },
    nativeMaps
  ]

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={confirmModalOpen}
        isOpen={setConfirmModalOpen}
        onClick={() => branchesDelete.onSubmit({id: branchId})}
        loading={branchesDeleteLoading}
        type={'delete'}
      />
      <SecondMenu
        title={'Дополнительные функции по работе с картами'}
        menusArr={mapsMenuArr}
        isOpen={isOpenMapsMenu}
        setIsOpen={setIsOpenMapsMenu}
      />
    </>

  // Render
  return (
    <>
      <PhotoBar
        title={(!branchesDetailLoading && branchName) || 'Филиал'}
        subTitle={(!branchesDetailLoading && branchAddress) || null}
        cover={!branchesDetailLoading ? coverPhoto : undefined} e
        secondHeight={secondHeight}
        goBack={() => goBack()}
        buttons={navButtons}
      />
      <Container
        secondHeight={secondHeight}
        background={'#fff'}
      >
        <Wrap
          secondHeight={secondHeight}
        >
          {branchesDetailLoading
            ? <Loader />
            : mainContent || <NoResults text={'Нет данных'} />}
        </Wrap>
      </Container>
      {modals}
    </>
  )
}

export default DeliveriesBranchesDetailGrid
