import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import moment from 'moment'
import {prop} from 'ramda'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 100%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const MainBar = styled(Bar)``
const Date = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%) ${({eq}) => eq ? 'rotate(270deg)' : 'rotate(90deg)'};
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const Detail = styled('li')`
  padding: 12px 0;
  margin: 0 16px;
  display: ${({eq}) => eq ? 'block' : 'none'};
  transition: ${({theme}) => theme.transition.primary};
  border-bottom: ${({theme}) => theme.border.primary};
  & > *:not(:last-child){
    margin-bottom: 5px;
  }
`

const VisitsItem = props => {
  const {
    onClick,
    name,
    date,
    children,
    searchObj,
    query = 'visitId',
    id,
    setQuery
  } = props

  const balanceIdQuery = prop(query, searchObj)

  const eq = (+balanceIdQuery === +id)

  const handleClick = () => {
    !eq ? setQuery(id) : setQuery('')
    onClick && onClick()
  }

  return (
    <>
      <TileItem
        onClick={handleClick}
        styles={styles}
      >
        <MainBar>
          <h3>{name}</h3>
          <Date>{moment(date).format('DD MMM YYYY HH:mm')}</Date>
        </MainBar>
        <ArrowWrap
          eq={eq}
        >
          <Arrow />
        </ArrowWrap>
      </TileItem>
      <Detail
        eq={eq}
      >
        {children}
      </Detail>
    </>
  )
}

VisitsItem.propTypes = {
  onClick: PropTypes.func,
  date: PropTypes.string,
  name: PropTypes.string
}

export default VisitsItem
