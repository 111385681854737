import React from 'react'
import Container from 'components/Container'
import {path, prop} from 'ramda'
import Button from 'components/Forms/Button'
import Form from 'components/Forms/Form'
import styled from 'styled-components'
import BottomBar from 'components/Navigation/BottomBar'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import {CreateBar} from 'components/Navigation/TopBar'
import ModalConfirm from 'components/Modal/ModalConfirm'
import CardDescription from 'components/Cards/CardDescription'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const Description = styled('ul')`
  margin-bottom: 15px;
  & > * {
    padding: 12px 0;
    min-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

// Forms
const CommentsForm = (props) => {
  const {
    setConfirmModalOpen,
    modals
  } = props

  return (
    <>
      <StyledWrap>
        <Field
          component={TextField}
          name={'comment'}
          label={'Юр. лицо'}
          placeholder={'Название полностью'}
          required
          fullWidth
        />
      </StyledWrap>
      <BottomBar>
        <Button
          type={'button'}
          text={'Создать'}
          onClick={() => setConfirmModalOpen(true)}
          fullWidth
          // disabled={!eqSwipe}
        />
      </BottomBar>
      {modals}
    </>
  )
}

const MarketsGrid = props => {
  const {
    goBack,
    confirmModalOpen,
    setConfirmModalOpen,
    match,
    marketsItem,
    marketsContractsCreate
  } = props

  // Global constants
  const marketId = path(['params', 'id'], match)

  // Data
  const marketsItemData = prop('data', marketsItem)
  const marketsContractsCreateLoading = prop('loading', marketsContractsCreate)

  // Main data
  const marketsName = prop('name', marketsItemData)
  const marketType = path(['marketType', 'name'], marketsItemData)
  const marketsAddress = prop('address', marketsItemData)

  // InitialValues
  const initialValuesObj = {}

  // Content
  const mainContent =
    <>
      <Description>
        <li><CardDescription it={'Клиент'} is={marketsName} /></li>
        <li><CardDescription it={'Категория'} is={marketType} /></li>
        <li><CardDescription it={'Адрес'} is={marketsAddress} /></li>
      </Description>
    </>

  const modals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Для продолжения подтвердите возврат'}
      loading={marketsContractsCreateLoading}
      type={'submit'}
    />

  // Submits
  const handleMainSubmit = (props) => {
    const data = {
      market: marketId,
      comment: prop('comment', props)
    }
    marketsContractsCreate.onSubmit(data)
  }

  // Render
  return (
    <>
      <CreateBar
        title={'Создание договора'}
        goExit={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {mainContent}
          <Form
            onSubmit={handleMainSubmit}
            initialValues={initialValuesObj}
          >
            <CommentsForm
              setConfirmModalOpen={setConfirmModalOpen}
              modals={modals}
            />
          </Form>
        </Wrap>
      </Container>
    </>
  )
}

export default MarketsGrid
