import React from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'
import ReactSearchField from 'react-select'
import {find, prop, pipe, propEq, map, isEmpty, path, equals, not, propOr} from 'ramda'
import selectStyles from './selectStyles'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import Loader from '../../Loader'
import {isOpenToTop} from 'constants/styles'
import Portal from '@material-ui/core/Portal/Portal'
import Fab from '../Fab'
import {X as CloseIcon} from 'react-feather'

const SelectWrapper = styled('div')`
  width: ${(props) => props.fullWidth ? '100%' : '220px'};
  min-width: ${(props) => props.fullWidth ? '100%' : '220px'};
  max-width: 100%;
  color: ${({theme}) => theme.text.primary};
  font-size: 16px;
`

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  border-radius: ${({open}) => open && '12px 12px 0px 0px'};
  padding: ${({open}) => open && '16px 16px 4px 16px'};
`

const Close = styled('div')`
    position: fixed;
    top: ${({focus}) => focus ? '21px' : 'calc(24vh + 9px)'};
    right: 21px;
    transition: top .35s;
`

const LoadWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  & > div {
    margin: 0
  }
`

const BgMask = styled('div')`
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.09);
  z-index: 2001;
  box-sizing: border-box;
`

const Label = styled('label')`
  text-transform: ${({open}) => open ? 'none' : 'uppercase'};
  font-weight: ${({open}) => open && '500'};
  margin-bottom: ${({open}) => open ? '20px' : '8px'};
  font-size: ${({open}) => open ? '17px' : '12px'};
  color: ${({theme, open}) => open ? '#000' : theme.text.secondary}
  :after{
    display: ${({required}) => required ? 'inline-block' : 'none'};
    content: '\u00A0*';
    color: ${({theme}) => theme.palette.red}
  }
`

const noOptionsMessage = ({inputValue}) => {
  if (inputValue) return `Не найдено "${inputValue}"`
  return 'Не найдено'
}
const loadingMessage = ({inputValue}) => {
  return <LoadWrapper><Loader size={0.5} /></LoadWrapper>
}

const enhance = compose(
  connect()
)

class EndlessSearchField extends React.Component {
    state = {
      label: '',
      focus: false,
      loading: false,
      menuIsOpen: false,
      ownValue: '',
      dataSource: []
    }

    mount = true;

    fetchList = ({getOptions, getText, getValue, dispatch, input, valueString, ...rest}) => {
      const parent = path(['parent', 'id'], rest) || path(['input', 'value', 'parent', 'id'], rest)
      this.setState({loading: true})
      dispatch(getOptions(this.state.label, parent || 0))
        .then((data) => {
          if (this.mount && data) {
            data = map((item) => {
              return {
                label: getText(item),
                value: valueString ? `${getValue(item)}` : getValue(item),
                ...item
              }
            }, data)
            this.setState({dataSource: data, loading: false})
          }
        })
    }

    handleMenuOpen = () => {
      this.setState({
        menuIsOpen: true
      })
      document.body.style.overflow = 'hidden'
      if (isEmpty(this.state.dataSource)) {
        this.fetchList(this.props)
      }
    }

    handleMenuClose = () => {
      this.setState({
        menuIsOpen: false,
        focus: false
      })
      document.body.style = null
    }

  onValueChange = (value) => {
    const {input} = this.props
    this.setState({ownValue: value})
    input.onChange(value)
  }

  getSelectedOption = (options, option) => {
    // set value from object
    const optionValue = prop('value', option)
    return find(propEq('value', optionValue || option))(options)
  }

  componentDidMount () {
    // isInitial - it is with initialValues
    const {isInitial} = this.props
    if (isEmpty(this.state.dataSource) && !isInitial) {
      this.fetchList(this.props)
    }
    // it work when become initialValues
    if (!isEmpty(prop('value', this.props.input)) && isInitial) {
      const id = path(['value', 'value'], this.props.input)
      const parentId = prop('value', this.props.parent)
      if (parentId !== id) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          ownValue: this.props.input.value
        })
      }
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const {parent, input, getItem, dispatch} = this.props
    const value = path(['value', 'value'], input)
    const prevValue = path(['input', 'value', 'value'], prevProps)
    const nextOwnValue = path(['ownValue', 'value'], this.state)
    const ownValue = path(['ownValue', 'value'], prevState)
    const eqParent = equals(prevProps.parent, parent)
    const eqValue = equals(value, prevValue)
    // standardSetting for Select
    if ((!eqValue && isEmpty(this.state.dataSource)) || !eqParent) {
      this.fetchList(this.props)
      return input.onChange('')
    }
    // it work when become initialValues
    if ((nextOwnValue !== ownValue) && nextOwnValue) {
      const value = path(['ownValue', 'value'], this.state)
      const finder = find(propEq('value', value))(this.state.dataSource) || {}
      if (isEmpty(finder) && value) {
        dispatch(getItem(value)).then((data) => {
          if (!isEmpty(data)) {
            const isParent = prop('parent', data) || null
            return this.fetchList({...this.props, parent: isParent})
          }
          return null
        })
      }
    }
  }

  componentWillUnmount () {
    this.mount = false
  }

    selectRef = React.createRef()
    container = React.createRef()
    render () {
      const {
        theme,
        label,
        onChange,
        type,
        onMenuOpen,
        onMenuClose,
        input,
        disabled,
        meta,
        required,
        placeholder,
        fullWidth,
        valueString,
        level = 0,
        withoutLabel = false,
        ...rest
      } = this.props
      const {menuIsOpen, loading, dataSource, focus, ownValue} = this.state
      const value = prop('value', ownValue)
      const selectedOption = this.getSelectedOption(dataSource, value) || ''
      const foundValue = pipe(prop('dataSource'), find(propEq('value', value)))(this.state) || {}
      const hasChild = pipe(propOr([], 'children'), isEmpty, not)(foundValue)
      return (
        <>
          <div ref={this.container}>
            {menuIsOpen &&
            // read only !! not work
            <Wrap open={false}>
              {!withoutLabel &&
              <Label required={required} open={false}>{label}</Label>}
              <SelectWrapper
                fullWidth={fullWidth}
              >
                <ReactSearchField
                  value={selectedOption}
                  options={dataSource}
                  classNamePrefix={'select'}
                  styles={selectStyles(theme, {
                    menuIsOpen: false, focus: false
                  })}
                  placeholder={placeholder}
                  name={'fakeNameSelect'}
                />
              </SelectWrapper>
            </Wrap>}
            {/* work variant!!!! */}
            <Portal container={menuIsOpen ? document.body : this.container.current}>
              <Wrap style={menuIsOpen ? isOpenToTop(focus) : null} open={menuIsOpen}>
                {(!withoutLabel || menuIsOpen) &&
                <Label required={required} open={menuIsOpen}>{label}</Label>}
                {menuIsOpen &&
                <Close focus={focus}>
                  <Fab size="small" background={'rgba(118, 118, 128, 0.12)'} onClick={this.handleMenuClose}>
                    <CloseIcon size={18} color={'rgba(60, 60, 67, 0.6)'} />
                  </Fab>
                </Close>}
                <SelectWrapper
                  fullWidth={fullWidth}
                >
                  <ReactSearchField
                    ref={this.selectRef}
                    isDisabled={disabled}
                    value={selectedOption}
                    options={dataSource}
                    classNamePrefix={'select'}
                    styles={selectStyles(theme, {
                      menuIsOpen, focus
                    })}
                    onInputChange={label => this.setState({label: label})}
                    closeMenuOnEndlessSearchField={!rest.isMulti}
                    menuIsOpen={menuIsOpen}
                    onFocus={() => menuIsOpen && this.setState({focus: true})}
                    onBlur={() => this.setState({focus: false})}
                    openMenuOnClick={type === 'select'}
                    onMenuOpen={this.handleMenuOpen}
                    onMenuClose={this.handleMenuClose}
                    noOptionsMessage={noOptionsMessage}
                    loadingMessage={loadingMessage}
                    isLoading={loading}
                    // eslint-disable-next-line react/jsx-handler-names
                    onChange={this.onValueChange}
                    placeholder={placeholder}
                    {...rest}
                  />
                </SelectWrapper>
              </Wrap>
            </Portal>
            {menuIsOpen &&
            <Portal>
              <BgMask onClick={this.handleMenuClose} />
            </Portal>}
          </div>
          {hasChild && <EndlessSearchField {...this.props} level={level + 1} parent={foundValue} isInitial={false} />}
        </>
      )
    }
}

EndlessSearchField.defaultGetText = (label) => {
  return (obj) => {
    return prop(label, obj)
  }
}

EndlessSearchField.defaultGetValue = (value) => {
  return (obj) => {
    return prop(value, obj)
  }
}

EndlessSearchField.propTypes = {
  theme: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    'select',
    'autocomplete'
  ]),
  isMulti: PropTypes.bool,
  valueString: PropTypes.bool,
  options: PropTypes.array,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  getText: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired
}

EndlessSearchField.defaultProps = {
  type: 'select',
  placeholder: 'Выберите из списка',
  isClearable: true,
  valueString: true,
  disabled: false
}

export default enhance(withTheme(EndlessSearchField))
