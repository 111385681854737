import React from 'react'
import {path} from 'ramda'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import styled from 'styled-components'
import MUISnackbar from '@material-ui/core/Snackbar'
import {closeSnackbarAction} from 'actions/snackbarAction'
import {CheckCircle} from 'react-feather'
import SnackbarContent from '@material-ui/core/SnackbarContent'

const enhance = compose(
  connect((state) => {
    const open = path(['snackbar', 'open'], state)
    const message = path(['snackbar', 'message'], state)
    const autoHideDuration = path(['snackbar', 'autoHideDuration'], state)

    return {
      open,
      message,
      autoHideDuration
    }
  })
)

const CustomSnackbar = styled(MUISnackbar)`
  background-color:  ${props => props.background};
  border-radius: ${props => props.theme.borderRadius.primary};
  div {
     background-color: transparent;
  }
`

const CustomSnackbarContent = styled('div')`
  display: flex;
  color: ${({theme}) => theme.palette.black}
  flex-direction: row;
  align-items: center;
  line-height: 100%;
  & svg {
    color: ${({theme}) => theme.palette.green}
  }
  & > :first-child {
    margin-right: 10px;
  }
`

const Snackbar = ({dispatch, open, message, autoHideDuration = 4000, ...defaultProps}) => {
  const vertical = 'top'
  const horizontal = 'center'

  const background = '#fff'
  const MySnackbarContentWrapper = (props) => {
    const {...other} = props
    return (
      <SnackbarContent
        message={
          <CustomSnackbarContent id="client-snackbar">
            <CheckCircle />
            {message}
          </CustomSnackbarContent>
        }
        {...other}
      />
    )
  }

  return (
    <CustomSnackbar
      background={background}
      anchorOrigin={{vertical, horizontal}}
      key={`${vertical},${horizontal}`}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => dispatch(closeSnackbarAction())}
      {...defaultProps}
    >
      <MySnackbarContentWrapper />
    </CustomSnackbar>
  )
}

export default enhance(Snackbar)
