import {compose, withHandlers} from 'recompose'
import PlansDetailGrid from './Grid'
import {withFetchList, withFilter, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {plansByAgentsDetailAction} from 'containers/Plans/actions/plansAction'
import {userDetailAction} from 'actions/usersAction'

export default compose(
  withFetchList({
    stateName: STATES.PLANS_BY_AGENTS_DETAIL,
    action: plansByAgentsDetailAction,
    key: STATES.PLANS_BY_AGENTS_DETAIL
  }),
  withFetchList({
    stateName: STATES.USER_DETAIL,
    action: userDetailAction,
    key: STATES.USER_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PLANS_PATH,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goDetail',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goReturnsCreate',
    redirectUrl: ROUTES.MARKETS_RETURNS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goVisitsCreate',
    redirectUrl: ROUTES.MARKETS_VISITS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrdersCreate',
    redirectUrl: ROUTES.MARKETS_ORDERS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goPaymentsCreate',
    redirectUrl: ROUTES.MARKETS_PAYMENTS_CREATE_URL,
    withCurrentParams: true
  }),
  withFilter({
    fields: ['date', 'search']
  }),
  withHandlers({
    goYandexNav: () => (lat, lon) => {
      window.location.replace('yandexnavi://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
    },
    goYandexMaps: () => (lat, lon) => {
      window.location.replace('yandexmaps://build_route_on_map?lat_to=' + lat + '&lon_to=' + lon)
    },
    goGoogleNav: () => (lat, lon) => {
      window.location.replace('google.navigation:q=' + lat + ',' + lon)
    },
    goAppleMaps: () => (lat, lon) => {
      window.location.replace('maps://maps.google.com/maps?daddr=' + lat + ',' + lon + '&amp;ll=')
    }
  })
)(PlansDetailGrid)
