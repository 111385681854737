import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 16 16'} {...props}>
      <path d={'M4.59154 10.8977C4.59154 10.8977 11.0394 16.7831 14.6733 14.9277C14.6733 14.9277 15.6776 14.105 15.7748 13.2968C15.8721 12.4885 15.0377 11.9464 14.7153 11.7622C13.9434 11.3191 13.1494 10.9063 12.3617 10.4859C12.1128 10.3527 11.8428 10.2025 11.5488 10.2762C11.3786 10.3197 11.2032 10.3669 11.0478 10.4406C10.4932 10.7013 10.0385 10.9469 9.43773 11.1916C9.43773 11.1916 8.82455 11.0123 6.53708 8.92933C4.10677 6.71599 4.43521 5.89302 4.43521 5.89302C4.75134 5.48965 5.03911 5.06739 5.27857 4.62245C5.3815 4.43069 5.52328 4.24553 5.58315 4.03676C5.64406 3.82705 5.51908 3.67684 5.41405 3.49264C5.1809 3.08076 4.93198 2.67645 4.67362 2.27685C4.22831 1.58631 3.77354 0.866472 3.20535 0.249607C3.14549 0.18537 3.0058 -0.00734116 2.89132 0.000216147C1.62786 0.0767338 0.383295 1.21316 0.383295 1.21316C-1.57229 4.4288 4.59154 10.8977 4.59154 10.8977Z'} />
    </SvgIcon>

  )
}
