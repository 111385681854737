import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import ProductsTypesCreateForm from 'containers/ProductsTypes/Grid/Create/Grid/Forms/ProductsTypesCreateForm'
import {prop} from 'ramda'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const ProductsTypesCreateGrid = props => {
  const {
    productsTypesCreate,
    goBack
  } = props

  // Data
  const productsTypesCreateLoading = prop('loading', productsTypesCreate)

  // InitialValues
  const initialValues = {
    withParent: false
  }

  // HandleSubmit
  const handleSubmit = values => {
    const data = {
      name: prop('name', values),
      parent: prop('withParent', values) ? prop('type', values) : null,
      order: prop('order', values),
      showInMarket: prop('showInMarket', values),
      showManyProducts: prop('showManyProducts', values)
    }

    productsTypesCreate.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        haveMutators
      >
        <ProductsTypesCreateForm
          productsTypesCreateLoading={productsTypesCreateLoading}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Создание типа продукта'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default ProductsTypesCreateGrid
