import React from 'react'
import {isEmpty, path, prop} from 'ramda'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import mapIndexed from 'helpers/mapIndexed'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import styled from 'styled-components'

const LoadWrap = styled('li')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

const ProductionsBatchesCreateGrid = props => {
  const {
    manufactureList,
    goExit,
    goNext
  } = props

  // Data
  const manufactureListData = prop('results', manufactureList)
  const manufactureListLoading = prop('loading', manufactureList)

  // MainContent
  const mainContent =
    <ul>
      {mapIndexed((item) => {
        const name = prop('name', item)
        const id = prop('id', item)
        const stockName = path(['warehouse', 'name'], item)

        return (
          <ProductsItem
            name={name}
            amount={stockName}
            key={id}
            withOutArrow
            onClick={() => goNext(id)}
          />
        )
      }, manufactureListData)}
    </ul>

  // Render
  return (
    <>
      <CreateBar
        title={'Этапы производства'}
        goExit={() => goExit()}
      />
      <Container
        background={'#fff'}
      >
        {manufactureListLoading ? <LoadWrap><Loader size={1.5} /></LoadWrap> : isEmpty(manufactureListLoading) ? <NoResults /> : mainContent}
      </Container>
    </>
  )
}

export default ProductionsBatchesCreateGrid
