import React from 'react'
import styled from 'styled-components'
import {
  X as ExitIcon
} from 'react-feather'
import PropTypes from 'prop-types'

const BGMusk = styled('div')`
  background: ${({theme}) => theme.background.mask};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  z-index: 1000;
  margin: 0 !important;
`
const ModalContent = styled('div')`
  overflow: hidden;
  margin: 0 !important;
  background: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: ${({isOpen}) => isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.05)' : 'none'};
  display: ${({isOpen}) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  max-width: 375px;
  width: 90%;
  z-index: 1000;
  max-height: 90vh;
  transition: all .2s ease-out;
  ${({positionStyles}) => positionStyles};
`
const Title = styled('h2')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: ${({onClick, theme}) => onClick && theme.palette.blue};
  font-style: ${({onClick}) => onClick && 'italic'};
  letter-spacing: -0.41px;
`
const SubTitle = styled('h3')`
  color: ${({theme}) => theme.text.secondary};
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
`
const TitleWrap = styled('div')`
  min-height: 35px;
  height: auto;
  margin-right: 70px;
  margin-bottom: 10px;
`
const ExitIconWrap = styled('span')`
  position: absolute;
  right: 16px;
  top: 17px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #e6e6e7;
  svg{
    color: rgba(130, 130, 130, 0.85);
  }
`
const SecondaryButton = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 55px;
  top: 17px;
  height: 24px;
`
const MainContent = styled('div')`
  overflow-y: auto;
`

const Modal = (props) => {
  const {
    open,
    isOpen,
    children,
    title,
    subTitle,
    secondBtn,
    onTitleClick,
    position = 'center'
  } = props

  // const onModalOpen = () => isOpen(true)
  const onModalClose = () => isOpen(false)

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [open])

  const positionStyles = () => {
    switch (position) {
    case 'center': return {top: '50%', left: '50%', right: 0, transform: 'translateX(-50%) translateY(-50%)'}
    case 'bottom': return {bottom: '5vw', left: '50%', transform: 'translateX(-50%)'}
    default: return null
    }
  }

  return (
    <>
      <BGMusk isOpen={open} onClick={onModalClose} />
      <ModalContent
        isOpen={open}
        positionStyles={positionStyles()}
      >
        <TitleWrap>
          {title && <Title onClick={onTitleClick}>{title}</Title>}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <div>
            <SecondaryButton>
              {secondBtn}
            </SecondaryButton>
            <ExitIconWrap onClick={onModalClose}>
              <ExitIcon size={16} />
            </ExitIconWrap>
          </div>
        </TitleWrap>
        <MainContent>
          {children}
        </MainContent>
      </ModalContent>
    </>
  )
}

Modal.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  secondBtn: PropTypes.any,
  isOpen: PropTypes.func,
  open: PropTypes.bool,
  position: PropTypes.string
}

export default Modal
