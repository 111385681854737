import React from 'react'
import {path, prop} from 'ramda'
import {MainBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import MainMenu from 'components/Navigation/components/MainMenu'
import mapIndexed from 'helpers/mapIndexed'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import BatchesItem from 'containers/Productions/components/BatchesItem/BatchesItem'

const ProductionsBatchesGrid = props => {
  const {
    filterActions,
    goCreate,
    manufactureBatchesList,
    goDetail
  } = props

  // Data
  const manufactureBatchesListData = prop('data', manufactureBatchesList)

  // Second Height
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Menus
  const menusArr = [
    {
      title: 'Создать партию',
      onClick: () => goCreate()
    }
  ]

  // MainList
  const mainList = manufactureBatchesListData && mapIndexed((item) => {
    const id = prop('id', item)
    const closedTime = prop('closedTime', item)
    const status = prop('status', item)
    const equipmentName = path(['equipment', 'name'], item)
    const productName = path(['product', 'name'], item)

    return (
      <BatchesItem
        key={id}
        id={id}
        endDate={closedTime}
        equipment={equipmentName}
        product={productName}
        status={status}
        onClick={() => goDetail(id)}
      />
    )
  }, manufactureBatchesListData)

  // MainContent
  const mainContent =
    <ul>
      <InfiniteScroll
        api={API.MANUFACTURE_BATCHES_LIST}
        actionType={actionTypes.MANUFACTURE_BATCHES_LIST}
        emptyData={<li><NoResults /></li>}
        params={{}}
        useWindow
        list={mainList}
      />
    </ul>

  // Render
  return (
    <>
      <MainBar
        title={'Партии'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
      />
      <Container
        secondHeight={secondHeight}
        background={'#fff'}
      >
        {mainContent}
      </Container>
      <SecondMenu
        title={'Дополнительные функции по работе со складом'}
        menusArr={menusArr}
        button={
          <Fab
            text={'Дополнительно'}
            withfixed={'true'}
          >
            <Plus size={20} color={'#fff'} />
          </Fab>
        }
      />
    </>
  )
}

export default ProductionsBatchesGrid
