import {compose, mapProps, mapPropsStream, withHandlers, withState} from 'recompose'
import {path, prop} from 'ramda'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {connect} from 'react-redux'
import {withFilter} from 'hocs'
import RequestFundsGrid from './Grid/RequestFundsGrid'
import {cancelFundsRequestAction, requestFundsDetailAction} from './actions/requestFundsAction'
import {distinctUntilChanged} from 'rxjs/operators'
import {isEqualSearch} from '../../helpers/isEquals'
import {REQUEST_FUNDS_CREATE_URL} from 'constants/routes'
import toNumber from '../../helpers/toNumber'
import {openSnackbarAction} from '../../actions/snackbarAction'
import {openErrorAction} from '../../actions/errorsAction'

export const DETAIL_PICK_PARAMS = [
  'detailId'
]

export default compose(
  connect((state, props) => {
    const requestFundsList = prop('requestFundsList', state)
    const requestFundsItem = prop('requestFundsItem', state)
    const query = parseParams(path(['location', 'search'], props))
    return {
      requestFundsList,
      requestFundsItem,
      query
    }
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        distinctUntilChanged(
          isEqualSearch(DETAIL_PICK_PARAMS),
          path(['history', 'location', 'search'])
        )
      )
      .subscribe(props => {
        const detailId = path(['query', 'detailId'], props)
        return detailId && props.dispatch(requestFundsDetailAction(props))
      })
    return props$
  }),
  withFilter({
    fields: ['search']
  }),
  withState('modalOpen', 'setModalOpen', false),
  withHandlers({
    handleDetailId: props => (detailId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({detailId}, path(['history', 'location', 'search'], props))
      })
    },
    handleUpdateDialog: props => (detailId, nextStatus) => {
      return props.history.push({
        pathname: REQUEST_FUNDS_CREATE_URL,
        search: appendParamsToUrl({openUpdateDialog: detailId, nextStatus}, path(['location', 'search'], props.history))
      })
    },
    handleSubmitCancelFundsRequest: props => (values) => {
      const {dispatch, query, setModalOpen} = props
      const detailId = toNumber(prop('detailId', query))
      const reloadUI = Math.random()
      dispatch(cancelFundsRequestAction(values, detailId))
        .then(() => {
          setModalOpen(false)
          props.history.push({
            search: appendParamsToUrl({reloadUI}, path(['history', 'location', 'search'], props))
          })
          return dispatch(openSnackbarAction({message: 'Успешно удалено'}))
        })
        .catch((e) => {
          return dispatch(openErrorAction({errMessage: e}))
        })
    }
  }),
  withState('openFilter', 'setOpenFilter', false),
  mapProps(({modalOpen, setModalOpen, handleSubmitCancelFundsRequest, ...props}) => {
    const cancelModal = {
      open: modalOpen,
      onOpen: setModalOpen,
      handleSubmit: handleSubmitCancelFundsRequest
    }
    return {
      cancelModal,
      ...props
    }
  })

)(RequestFundsGrid)
