import {propEq, find, prop, pipe} from 'ramda'

export const EVERY_DAY = '1'
export const ONCE_IN_A_WEEK = '2'
export const TWICE_IN_A_WEEK = '3'
export const IN_A_DAY = '4'

export const FREQUENCY = [
  {label: 'Каждый день', value: EVERY_DAY},
  {label: 'Раз в неделю', value: ONCE_IN_A_WEEK},
  {label: 'Два раза в неделю', value: TWICE_IN_A_WEEK},
  {label: 'Через день', value: IN_A_DAY}
]

export const CURRENCY = [
  {value: '5', label: 'UZS'},
  {value: '4', label: 'USD'}
]

export const REQUEST_FUNDS_PENDING = 'pending'
export const REQUEST_FUNDS_APPROVED = 'approved'
export const REQUEST_FUNDS_CANCELLED = 'cancelled'
export const REQUEST_FUNDS_GIVEN = 'given'

export const REQUEST_FUNDS_STATUS = [
  {value: REQUEST_FUNDS_PENDING, label: 'В ожидании'},
  {value: REQUEST_FUNDS_APPROVED, label: 'Подтвержден'},
  {value: REQUEST_FUNDS_CANCELLED, label: 'Отменен'},
  {value: REQUEST_FUNDS_GIVEN, label: 'Оплачен'}
]

export const selectValueFrom = (id, arr) => {
  return pipe(
    find(propEq('value', id)),
    prop('label')
  )(arr)
}

export const PAYMENT_TYPE = {
  cash: 'Наличные',
  bank: 'Перечисление',
  payme: 'Payme',
  click: 'Click'
}

export const PAYMENT_TYPE_OPTIONS = [
  {value: 'cash', label: 'Наличные'},
  {value: 'bank', label: 'Терминал'},
  {value: 'payme', label: 'Payme'},
  {value: 'click', label: 'Click'}
]
