import React, {useState} from 'react'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import CarsPreview from 'containers/Cars/components/CarsPreview/CarsPreview'
import {map, prop, reject} from 'ramda'
import {parseParams} from 'helpers/url'
import Form from 'components/Forms/Form'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import styled from 'styled-components'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import {FieldArray} from 'react-final-form-arrays'
import TelephonesField from 'components/Forms/TelephonesField'
import Label from 'components/Forms/Label'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import {onlyNumsAndSymbol} from 'helpers/onlyNumsAndSymbol'
import ModalConfirm from 'components/Modal/ModalConfirm'

const FieldsWrap = styled('div')`
  padding: 14px 16px 64px;
  & > :not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = (props) => {
  const {
    values,
    modals,
    setConfirmModalOpen
  } = props

  const brandValue = prop('brand', values)
  const eq = values.number && brandValue && values.model && values.clientName

  return (
    <FieldsWrap>
      <Field
        component={TextField}
        name={'number'}
        type={'text'}
        label={'Номер авто'}
        fullWidth
        required
      />
      <Field
        component={UniversalSearchField}
        api={API.CARS_BRANDS_LIST}
        params={{parent: 0, page_size: '1000'}}
        name={'brand'}
        label={'Марка авто'}
        required
        fullWidth
      />
      {brandValue &&
      <Field
        component={UniversalSearchField}
        api={API.CARS_BRANDS_LIST}
        params={{page_size: '1000'}}
        parent={brandValue && {parent: brandValue}}
        name={'model'}
        label={'Модель авто'}
        required
        fullWidth
      />}
      <Field
        component={TextField}
        name={'clientName'}
        type={'text'}
        label={'Имя клиента'}
        fullWidth
        required
      />
      <div>
        <Label>Номер телефона</Label>
        <FieldArray
          name={'phones'}
          innerName={'number'}
          component={TelephonesField}
        />
      </div>
      <Field
        component={TextField}
        name={'engineNumber'}
        type={'text'}
        label={'Номер двигателя'}
        fullWidth
      />
      <Field
        component={TextField}
        name={'note'}
        type={'text'}
        label={'Заметка'}
        fullWidth
      />
      {modals}
      <BottomBar>
        <Button
          type={'button'}
          onClick={() => setConfirmModalOpen(true)}
          text={'Зарегистрировать'}
          disabled={!eq}
          fullWidth
        />
      </BottomBar>
    </FieldsWrap>
  )
}

const CarsCreateGrid = (props) => {
  const {
    goBack,
    goDetail,
    location,
    carsList,
    filterActions,
    carsCreate
  } = props

  // UseStates
  const [isCreate, setIsCreate] = useState(true)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // InitialValues
  const initialValuesObj = {
    number: prop('carsSearch', searchObj) || '',
    phones: [{}]
  }

  // Data
  const createLoading = prop('loading', carsCreate)

  // Preview
  if (isCreate) {
    return (
      <CarsPreview
        goBack={goBack}
        setIsCreate={setIsCreate}
        searchObj={searchObj}
        carsList={carsList}
        filterActions={filterActions}
        goDetail={goDetail}
      />
    )
  }

  // Submit
  const handleMainSubmit = (values) => {
    const phonesArr = prop('phones', values)
    const phonesCheck = reject(item => (item.number === '+998' || item.number === '+998 '), phonesArr)
    const phones = map((i) => {
      return {
        number: onlyNumsAndSymbol(i.number)
      }
    }, phonesCheck)
    const number = prop('number', values)
    const clientName = prop('clientName', values)
    const engineNumber = prop('engineNumber', values)
    const note = prop('note', values)
    const model = prop('model', values)

    carsCreate.onSubmit({
      carBrand: model,
      number,
      phones,
      clientName,
      engineNumber,
      note
    })
  }

  // Modals
  const modals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Подтвердите создание заказа'}
      loading={createLoading}
      type={'submit'}
    />

  return (
    <>
      <CreateBar
        title={'Регистрация авто'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        <Form
          onSubmit={handleMainSubmit}
          initialValues={initialValuesObj}
          keepDirtyOnReinitialize
          haveMutators
        >
          <FormFields
            modals={modals}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        </Form>
      </Container>
    </>
  )
}

export default CarsCreateGrid
