import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  productsTypesEditAction,
  productsTypesItemAction
} from 'containers/Products/actions/productsActions'

export default compose(
  withCreate({
    stateName: STATES.PRODUCTS_TYPES_EDIT,
    action: productsTypesEditAction,
    key: STATES.PRODUCTS_TYPES_EDIT,
    redirectToCreated: ROUTES.PRODUCTS_TYPES_URL,
    successMessage: 'Тип продукта изменён'
  }),
  withFetchList({
    action: productsTypesItemAction,
    key: STATES.PRODUCTS_TYPES_ITEM,
    stateName: STATES.PRODUCTS_TYPES_ITEM
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.PRODUCTS_TYPES_URL
  })
)(Grid)
