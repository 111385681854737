import React from 'react'
import moment from 'moment'

const Clock = (props) => {
  const {
    startTime,
    time
  } = props

  const diffH = time.diff(startTime, 'hours')
  const diffM = time.diff(startTime, 'minutes')
  const diffS = time.diff(startTime, 'seconds')
  const diffTime = `${(+diffH !== 0) ? diffH + 'ч' : ''} ${diffM % 60}м ${diffS % 60}с`

  return <>{diffTime}</>
}

class Timer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {time: moment()}
  }

  componentDidMount () {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    )
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  tick () {
    this.setState({
      time: moment()
    })
  }

  render () {
    return (
      <Clock
        startTime={this.props.startTime}
        time={this.state.time}
      />
    )
  }
}

export default Timer
