import React from 'react'
import Container from 'components/Container/Container'
import {isEmpty, path, prop} from 'ramda'
import {PhotoBar} from 'components/Navigation/TopBar'
import styled from 'styled-components'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import CardDescription from 'components/Cards/CardDescription'
import moment from 'moment'
import mapIndexed from 'helpers/mapIndexed'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import Map from 'components/Map'
import {ChevronRight as ArrowIcon} from 'react-feather'
import {TextStatus} from 'components/Status/TextStatus'
import EditIcon from '../../../../../components/SvgIcons/CustomIcons/EditIcon'
import {MARKETS_CREATE_URL} from '../../../../../constants/routes'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const Href = styled('span')`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  svg{
    font-size: 17px;
    margin-left: 2px;
  }
`
const PromotionsList = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
`
const PromotionsItem = styled('span')`
  background: #fefefe;
  color: ${({theme}) => theme.palette.blue};
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  border-radius: 10px;
  padding: 9px;
  width: calc(50% - 7px);
  margin-bottom: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Title = styled('h2')`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px;
`
const MapWrap = styled('div')`
  background: ${({theme}) => theme.palette.white};
  border-radius: ${({theme}) => theme.borderRadius.primary};
  overflow: hidden;
`
const MapInner = styled('div')`
  position: relative;
  height: 140px;
  background: #f9f9f9;
`
const Address = styled('div')`
  padding: 15px 30px 15px 12px;
  position: relative;
  min-height: 40px;
  transition: ${({theme}) => theme.transition.primary};
  &:hover{
    background: #f7f7f8;
    transition: ${({theme}) => theme.transition.primary};
  }
  h6{
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.016em;
    color: ${({theme}) => theme.palette.black};
    margin-bottom: 3px;
  }
  p{
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.016em;
    color: ${({theme}) => theme.palette.grey};
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`

const MarketsDescriptionGrid = props => {
  const {
    marketsItem,
    marketsItemPhoto,
    goBack,
    goMap,
    match,
    location,
    history
  } = props

  // Data
  const marketsItemData = prop('data', marketsItem)
  const marketsItemLoading = prop('loading', marketsItem)
  const marketsItemPhotoData = prop('data', marketsItemPhoto)
  const marketId = path(['params', 'id'], match)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const photoQuery = prop('photoId', searchObj)
  const fromAndroid = prop('fromAndroid', searchObj) && window.AndroidMaps

  // Main
  const marketName = prop('name', marketsItemData)
  const marketType = path(['marketType', 'name'], marketsItemData)

  const address = prop('address', marketsItemData)
  const guide = prop('guide', marketsItemData)

  const marketOptions = prop('marketOptions', marketsItemData) || []
  const regionName = path(['division', 'name'], marketsItemData)
  const createdByFirstName = path(['createdBy', 'firstName'], marketsItemData)
  const createdBySecondName = path(['createdBy', 'secondName'], marketsItemData)
  const createdByName = createdByFirstName + ' ' + createdBySecondName
  const responsibleAgentFirstName = path(['responsibleAgent', 'firstName'], marketsItemData)
  const responsibleAgentSecondName = path(['responsibleAgent', 'secondName'], marketsItemData)
  const responsibleAgentName = responsibleAgentFirstName + ' ' + responsibleAgentSecondName
  const createdDate = moment(prop('createdDate', marketsItemData)).format('DD MMM YYYY')

  const zone = path(['border', 'title'], marketsItemData) || <TextStatus status={'inactive'}>Не определена</TextStatus>
  const note = prop('note', marketsItemData)

  const contactName = prop('contactName', marketsItemData)
  const phonesArr = prop('phones', marketsItemData)

  const checkingAccount = prop('checkingAccount', marketsItemData)
  const bankAddress = prop('bankAddress', marketsItemData)
  const inn = prop('inn', marketsItemData)
  const okad = prop('okad', marketsItemData)
  const mfo = prop('mfo', marketsItemData)
  const autoDelivery = prop('autoDelivery', marketsItemData) ? 'Включёна' : 'Отключёна'
  const unlimitedDebt = prop('unlimitedDebt', marketsItemData) ? 'Включёна' : 'Отключёна'
  const promotions = path(['client', 'promotions'], marketsItemData)

  const lat = `${path(['location', 'lat'], marketsItemData)}`
  const lon = `${path(['location', 'lon'], marketsItemData)}`

  // Photo
  const coverPhoto = (photoQuery && (+photoQuery !== 0)) && prop('file', marketsItemPhotoData)

  // NavBar
  const editMarket =
    <Href>
      <EditIcon fontSize={'17px'} />
    </Href>

  const navButtons = [
    {
      icon: editMarket,
      withAlert: true,
      perms: ['add_market'],
      onClick:() => {
        return history.push({
          pathname: MARKETS_CREATE_URL,
          search: appendParamsToUrl({openUpdateDialog: marketId}, path(['location', 'search'], history))
        })
      }
    }
  ]

  const map =
    <Map height={'140px'} lat={lat} lon={lon} />

  const mapContent =
    <MapWrap>
      <MapInner>
        {map}
      </MapInner>
      <Address
        onClick={() => goMap(+marketId)}
      >
        {address && <h6>{address}</h6>}
        {guide && <p>Ориентир: {guide}</p>}
        <Arrow />
      </Address>
    </MapWrap>

  const descriptionContent =
    <TilesList
      title={'Описание клиента'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Организация'} is={regionName} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Создал'} is={createdByName} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Агент'} is={responsibleAgentName} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Дата создания'} is={createdDate} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Зона'} is={zone} />
      </TileItem>
      {marketOptions.map((i) => {
        const nameOption = path(['optionValue', 'option', 'name'], i)
        const idOption = path(['optionValue', 'option', 'name'], i)
        const valueOption = path(['optionValue', 'value'], i)
        return (
          <TileItem
            key={idOption}
            height={'auto'}
          >
            <CardDescription it={nameOption} is={valueOption} />
          </TileItem>
        )
      })}
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Заметка'} is={note} />
      </TileItem>
    </TilesList>

  const contactsContent =
    <TilesList
      title={'Контактные данные'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Имя контакта'} is={contactName} />
      </TileItem>
      {phonesArr && mapIndexed((i, index) => {
        const phone = prop('phone', i)

        return (
          <TileItem
            key={index}
            height={'auto'}
            // eslint-disable-next-line no-console
            onClick={() => console.log('Calling...')}
          >
            <CardDescription it={'Телефон'} is={phone} />
          </TileItem>
        )
      }, phonesArr)}
    </TilesList>

  const moreContent =
    <TilesList
      title={'Дополнительно'}
    >
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Р/С'} is={checkingAccount} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Адрес Банка'} is={bankAddress} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'ИНН'} is={inn} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'ОКЭД'} is={okad} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'МФО'} is={mfo} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Автоматическая доставка'} is={autoDelivery} />
      </TileItem>
      <TileItem
        height={'auto'}
      >
        <CardDescription it={'Неограниченный долг'} is={unlimitedDebt} />
      </TileItem>
    </TilesList>

  const promotionsContent =
    <div>
      <Title>Акции</Title>
      <PromotionsList>
        {promotions && mapIndexed((item, index) => {
          const name = prop('name', item)
          const id = prop('id', item)

          return (
            <PromotionsItem
              key={id}
            >
              {name}
            </PromotionsItem>
          )
        }, promotions)}
      </PromotionsList>
    </div>

  const mainContent =
    <>
      {mapContent}
      {descriptionContent}
      {contactsContent}
      {moreContent}
      {!isEmpty(promotions) && promotionsContent}
    </>

  const secondHeight = coverPhoto ? 125 : 70

  // Render
  return (
    <>
      <PhotoBar
        title={marketName || 'Клиент'}
        subTitle={marketType || ''}
        cover={coverPhoto}
        secondHeight={secondHeight}
        goBack={() => fromAndroid ? window.AndroidMaps.closeWebView(true) : goBack(+marketId)}
        buttons={navButtons}
      />
      <Container
        secondHeight={secondHeight}
      >
        <Wrap>
          {marketsItemLoading
            ? <Loader styles={{marginTop: '20px'}} />
            : mainContent || 'Нет данных'}
        </Wrap>
      </Container>
    </>
  )
}

export default MarketsDescriptionGrid
