import React, {useState} from 'react'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import Form from 'components/Forms/Form'
import {find, isEmpty, path, prop, propEq, reject} from 'ramda'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'
import {Modal} from 'components/Modal'
import {Trash2 as TrashIcon} from 'react-feather'
import {parseParams} from 'helpers/url'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import Button from 'components/Forms/Button'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {
  carsAddServicesSerializer
} from 'containers/Cars/actions/serializers/carsSerializer'
import InfiniteScroll from 'components/InfiniteScroll'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import ProductsItem from 'containers/Clients/components/ProductsItem/ProductsItem'
import BottomBar from 'components/Navigation/BottomBar'
import ModalConfirm from 'components/Modal/ModalConfirm'

const Wrap = styled('div')`
  padding: 14px 16px 30px;
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
  button{
    margin-top: 10px;
  }
`
const ProductsSearch = styled('div')`
  right: 14px;
  background: #fff;
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
`
const LineThrough = styled('span')`
  text-decoration: line-through;
`
const InfiniteWrap = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 165px);
`

const AddProductsFormFields = (props) => {
  const {
    productIdParam
  } = props

  const amountName = 'amount_' + productIdParam
  const customPriceName = 'customPrice_' + productIdParam

  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'number'}
        name={customPriceName}
        label={'Цена'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        name={amountName}
        label={'Количество'}
        required
        fullWidth
      />
      <Button
        text={'Применить'}
        fullWidth
      />
    </StyledWrap>
  )
}

const CarsAddServicesGrid = (props) => {
  const {
    goBack,
    match,
    location,
    filterActions,
    carsServicesList,
    addProductIdHandler,
    customStore,
    config,
    carsOrdersAddProducts
  } = props

  // UseStates
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = React.useState({})
  const [products, setProducts] = React.useState([])
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)
  const carId = path(['params', 'id'], match)
  const acceptorId = prop('acceptorId', searchObj)

  // Data
  const carsServicesListData = prop('data', carsServicesList)
  const customStoreData = prop('data', customStore)
  const configData = prop('data', config)
  const carsOrdersAddProductsLoading = prop('loading', carsOrdersAddProducts)
  const carsCurrencyId = prop('mOBCARSCURRENCYID', configData)
  const carsPriceListId = prop('mOBCARSPRICELISTID', configData)
  const currenciesList = prop('currencies', customStoreData)
  const realCurrency = currenciesList && find(propEq('id', carsCurrencyId), currenciesList)
  const realCurrencyName = prop('name', realCurrency)

  // Initial values
  const customPriceName = 'customPrice_' + productIdParam
  const productInitialCurrently = carsServicesListData && find(propEq('id', +productIdParam))(carsServicesListData)

  const initialValuesObj = {}
  const initialValuesProducts = {
    [customPriceName]: prop('cashPrice', productInitialCurrently) || 0
  }

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        placeholder={'Название'}
        query={'productsSearch'}
      />
    </ProductsSearch>

  // Delete product
  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('service', productIdParam), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  // Main submit
  const handleMainSubmit = () => {
    carsOrdersAddProducts.onSubmit({
      car_acceptor: acceptorId,
      currency: carsCurrencyId,
      price_list: carsPriceListId,
      services_list: products
    })
  }

  // Add products submit
  const handleAddProductsSubmit = (values) => {
    const amountPlus = 'amount_' + productIdParam
    const customPricePlus = 'customPrice_' + productIdParam
    const newObject = {
      service: productIdParam,
      amount: prop(amountPlus, values),
      custom_price: prop(customPricePlus, values)
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('amount', '0'), newProducts)
    setProducts(sortedProducts)

    filterActions.onChangeFilter({})
    setModalOpen(false)
  }

  // Modals
  const addProductsModal =
    <Modal
      open={modalOpen}
      isOpen={setModalOpen}
      title={prop('name', selectedProduct) || 'Добавление услуги'}
      secondBtn={<TrashIcon size={20} color={'#ff0000'} onClick={() => handleDeleteProduct()} />}
    >
      <Form
        onSubmit={handleAddProductsSubmit}
        initialValues={initialValuesProducts}
      >
        <AddProductsFormFields
          productIdParam={productIdParam}
        />
      </Form>
    </Modal>

  // Confirm modal
  const confirmModals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Для продолжения подтвердите возврат'}
      loading={carsOrdersAddProductsLoading}
      type={'submit'}
    />

  // Products list
  const productsList = carsServicesListData && mapIndexed((item) => {
    const productName = prop('name', item)
    const productId = prop('serviceId', item)
    const isSelected = find(propEq('service', `${productId}`))(products)
    const selectedCustomPrice = prop('custom_price', isSelected)
    const cashPrice = prop('cashPrice', item) || 0
    const formatedCustomPrice = numberFormat(selectedCustomPrice, realCurrencyName)
    const formatedCashPrice = numberFormat(cashPrice, realCurrencyName)
    const selectedAmount = prop('amount', isSelected)
    const newCashPrice =
      <>
        <TextStatus
          bold
          status={'active'}
          style={{marginRight: '5px'}}
        >
          {formatedCustomPrice}
        </TextStatus>
        <LineThrough>
          {formatedCashPrice}
        </LineThrough>
      </>
    const cashPriceExtra = ((selectedCustomPrice > cashPrice) || (selectedCustomPrice < cashPrice)) ? newCashPrice : formatedCashPrice
    const priceNumber = cashPrice
    const handleAddProductsOpen = () => {
      setModalOpen(true)
      setSelectedProduct({name: productName, price: cashPriceExtra, priceNumber: priceNumber})
      addProductIdHandler(productId)
    }

    return (
      <ProductsItem
        key={productId}
        name={productName}
        onClick={() => handleAddProductsOpen()}
        isSelected={isSelected}
        price={cashPriceExtra}
        amount={selectedAmount && <TextStatus>{numberFormat(selectedAmount, 'шт')}</TextStatus>}
      />
    )
  }, carsServicesListData)

  // MainContent
  const mainContent =
    <InfiniteWrap>
      <InfiniteScroll
        api={API.CARS_SERVICES_LIST}
        actionType={actionTypes.CARS_SERVICES_LIST}
        emptyData={<Wrap><NoResults /></Wrap>}
        params={carsAddServicesSerializer(search, props)}
        list={productsList || []}
        useWindow={false}
      />
      {confirmModals}
      <BottomBar>
        <Button
          type={'button'}
          onClick={() => setConfirmModalOpen(true)}
          text={'Применить'}
          fullWidth
          disabled={isEmpty(products)}
        />
      </BottomBar>
    </InfiniteWrap>

  // Return
  return (
    <>
      <CreateBar
        title={'Добавление услуг'}
        goExit={() => goBack(carId)}
      />
      <Container
        background={'#fff'}
        overflow={'hidden'}
        styles={{
          maxHeight: '100vh'
        }}
      >
        {debounceSearch}
        <Form
          onSubmit={handleMainSubmit}
          initialValues={initialValuesObj}
        >
          {mainContent}
        </Form>
        {addProductsModal}
      </Container>
    </>
  )
}

export default CarsAddServicesGrid
