import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import ProductsTypesCreateForm from 'containers/ProductsTypes/Grid/Create/Grid/Forms/ProductsTypesCreateForm'
import {isEmpty, path, prop} from 'ramda'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const ProductsTypesEditGrid = props => {
  const {
    productsTypesItem,
    productsTypesEdit,
    goBack,
    match
  } = props

  // Location
  const productsTypeId = path(['params', 'id'], match)

  // Data
  const productsTypesEditLoading = prop('loading', productsTypesEdit)
  const productsTypesItemData = prop('data', productsTypesItem)
  const productsTypesItemLoading = prop('loading', productsTypesItem)

  // Const
  const name = prop('name', productsTypesItemData)
  const parent = prop('parent', productsTypesItemData)
  const parentId = prop('id', parent)
  const order = prop('order', productsTypesItemData)
  const showInMarket = prop('showInMarket', productsTypesItemData)
  const showManyProducts = prop('showManyProducts', productsTypesItemData)

  // InitialValues
  const initialValues = {
    withParent: parent && !isEmpty(parent),
    name,
    type: `${parentId}`,
    order,
    showInMarket,
    showManyProducts
  }

  // HandleSubmit
  const handleSubmit = values => {
    const data = {
      name: prop('name', values),
      parent: prop('withParent', values) ? prop('type', values) : null,
      id: productsTypeId,
      order: prop('order', values),
      showInMarket: prop('showInMarket', values),
      showManyProducts: prop('showManyProducts', values)
    }

    productsTypesEdit.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        haveMutators
      >
        <ProductsTypesCreateForm
          productsTypesEditLoading={productsTypesEditLoading}
          isEdit
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Изменение типа продукта'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {productsTypesItemLoading ? <Loader /> : mainContent}
      </Container>
    </>
  )
}

export default ProductsTypesEditGrid
