import {filter, isEmpty, map, path, prop, propOr} from 'ramda'
import {onlyNumsAndSymbol} from 'helpers/onlyNumsAndSymbol'
import {storageData} from 'helpers/storage'
import checkPermissions from 'helpers/checkPermissions'

export const marketsItemPhotoSerializer = () => {
  return {
    thumbnail_type: 'large'
  }
}

export const marketsListSerializer = (params, mapLocations) => {
  const search = prop('search', params)
  const marketType = prop('marketTypeChild', params) || storageData('marketsFilterMarketTypeChild').getValue() || prop('marketType', params) || storageData('marketsFilterMarketsType').getValue() || null
  const ordering = storageData('ordering').getValue() || 'near_markets'
  const responsibleAgent = prop('responsibleAgent', params) || storageData('marketsFilterResponsibleAgent').getValue() || null
  const debtors = prop('debtors', params)
  const lat = prop('latitude', mapLocations)
  const lon = prop('longitude', mapLocations)
  const isActiveValue = prop('isActive', params) || storageData('marketsFilterIsActive').getValue() || null
  const isActive = +isActiveValue === 2 ? false : +isActiveValue === 1 ? true : null
  const zone = prop('zone', params) || storageData('marketsZone').getValue() || null
  const location = !isEmpty(mapLocations) ? `${lat + '|' + lon}` : null

  return {
    search,
    responsible_agent: responsibleAgent,
    is_active: isActive,
    border: zone,
    market_type: marketType,
    location: location,
    show_balance: true,
    debtors: debtors,
    ordering: ordering
  }
}

export const marketsCreateSerializer = (values) => {
  const address = prop('address', values)
  const name = prop('name', values)
  const guide = prop('guide', values)
  const imagesArr = propOr([], 'images', values)
  const images = map(i => path(['image', 'fileId'], i), imagesArr)
  const contactName = prop('contact_name', values)
  const location = prop('location', values)
  const marketType = prop(['market_type'], values)
  const marketTypeChild = prop(['market_type_child'], values)
  const phonesCheck = filter(item => !isEmpty(item), prop('telephones', values))
  const phones = map((i) => {
    return {
      phone: onlyNumsAndSymbol(i.phone)
    }
  }, phonesCheck)

  const realImages = {images}

  return {
    ...realImages,
    name,
    guide,
    address,
    location,
    phones,
    market_type: marketTypeChild || marketType,
    contact_name: contactName

  }
}

export const marketsPointsListSerializer = (params) => {
  const responsibleAgent = prop('responsibleAgent', params)
  const promotion = prop('promotion', params)
  return {
    responsible_agent: responsibleAgent,
    promotion
  }
}

export const marketsReturnsProductsListSerializer = (params, props, formValues) => {
  const market = path(['match', 'params', 'id'], props)
  const isOrder = prop('isOrder', formValues)
  const orderId = prop('order', formValues)
  const returnFromOldBaseValue = prop('returnFromOldBase', formValues)
  const order = isOrder ? (orderId || null) : null
  const currency = prop('currency', formValues)
  const priceList = prop('priceList', formValues)
  const search = prop('search', params)
  const selectAllProducts = !!prop('selectAllProducts', formValues)

  const defaultParams = {
    market,
    order,
    search,
    page_size: selectAllProducts ? 1000 : 10
  }
  const returnFromOldBaseParams = {
    currency,
    price_list: priceList,
    search
  }

  if (returnFromOldBaseValue) {
    return returnFromOldBaseParams
  } else {
    return defaultParams
  }
}

export const marketsSalesOrdersListSerializer = (params, props) => {
  const marketId = path(['match', 'params', 'id'], props)
  const userId = path(['match', 'params', 'userId'], props)
  const divisionId = path(['match', 'params', 'divisionId'], props)
  const product = prop('product', params)
  const status = prop('status', params)
  const beginDate = prop('beginDate', params)
  const endDate = prop('endDate', params)
  const beginDeliveryDate = prop('beginDeliveryDate', params)
  const endDeliveryDate = prop('endDeliveryDate', params)
  const search = prop('ordersSearch', params)
  const reloadUI = prop('reloadUI', params)
  const supervisor = prop('supervisor', params) || storageData('onlyMyStats').getValue()
  const isAdmin = prop('isAdmin', props)
  const hasPerms = checkPermissions(['can_see_total_stat_mobile'], prop('userPerms', props))
  const supervisorWithPerms = (hasPerms || isAdmin) ? (supervisor || '0') : (supervisor || '1')
  const isOnlyMyStats = !!(+supervisorWithPerms)

  const defaultParams = {
    exclude_cancelled: true,
    market: marketId || null,
    begin_date: beginDate || endDate,
    end_date: endDate || beginDate,
    delivery_date_0: beginDeliveryDate || endDeliveryDate,
    delivery_date_1: endDeliveryDate || beginDeliveryDate,
    product,
    status,
    search,
    division: divisionId || null,
    user: (isOnlyMyStats && userId) || null,
    reloadUI
  }

  return {...defaultParams}
}

export const deliveriesBranchesOrdersSerializer = (params, props) => {
  const search = prop('ordersSearch', params)
  const stock = path(['match', 'params', 'id'], props)

  return {
    status: 1,
    stock,
    search
  }
}

export const marketsReturnsListSerializer = (params, props) => {
  const market = path(['match', 'params', 'id'], props)
  const userId = path(['match', 'params', 'userId'], props)
  const divisionId = path(['match', 'params', 'divisionId'], props)
  const beginDate = prop('beginDate', params)
  const order = prop('order', params)
  const statuses = prop('statuses', params)
  const endDate = prop('endDate', params)
  const product = prop('product', params)
  const search = prop('returnsSearch', params)
  const supervisor = prop('supervisor', params) || storageData('onlyMyStats').getValue()
  const isAdmin = prop('isAdmin', props)
  const hasPerms = checkPermissions(['can_see_total_stat_mobile'], prop('userPerms', props))
  const supervisorWithPerms = (hasPerms || isAdmin) ? (supervisor || '0') : (supervisor || '1')
  const isOnlyMyStats = !!(+supervisorWithPerms)

  const defaultParams = {
    exclude_cancelled: true,
    begin_date: beginDate || endDate,
    end_date: endDate || beginDate,
    market,
    product,
    statuses,
    order,
    search,
    division: divisionId,
    created_by: (isOnlyMyStats && userId) || null
  }

  return {...defaultParams}
}

export const marketsTelegramsSerializer = (params, props) => {
  const market = path(['match', 'params', 'id'], props)

  const defaultParams = {
    market
  }

  return {...defaultParams}
}

export const marketsTransactionsListSerializer = (params, props) => {
  const marketId = path(['match', 'params', 'id'], props)
  const userId = path(['match', 'params', 'userId'], props)
  const divisionId = path(['match', 'params', 'divisionId'], props)
  const division = prop('division', params)
  const beginDate = prop('beginDate', params)
  const endDate = prop('endDate', params)
  const type = prop('type', params)
  const supervisor = prop('supervisor', params) || storageData('onlyMyStats').getValue()
  const reload = prop('reload', params)
  const isAdmin = prop('isAdmin', props)
  const hasPerms = checkPermissions(['can_see_total_stat_mobile'], prop('userPerms', props))
  const supervisorWithPerms = (hasPerms || isAdmin) ? (supervisor || '0') : (supervisor || '1')
  const isOnlyMyStats = !!(+supervisorWithPerms)

  return {
    market_id: marketId,
    division: division || divisionId,
    begin_date: beginDate,
    end_date: endDate,
    type: type || (userId ? 1 : null),
    user: (isOnlyMyStats && userId) || null,
    reloadUI: reload
  }
}

export const marketsContractsSerializer = (params, props) => {
  const market = path(['match', 'params', 'id'], props)

  return {
    debt: true,
    ordering: '-id',
    market
  }
}

export const marketsVisitsSerializer = (params, props) => {
  const marketId = path(['match', 'params', 'id'], props)

  const defaultParams = {
    market: marketId
  }

  return {...defaultParams}
}

export const marketsReportsSerializer = (params, props) => {
  const marketId = path(['match', 'params', 'id'], props)

  const defaultParams = {
    market: marketId
  }

  return {...defaultParams}
}

export const marketsReportCreateSerializer = (data) => {
  const beforeImgArr = propOr([], 'beforeImage', data)
  const afterImgArr = propOr([], 'afterImage', data)
  const marketId = prop('marketId', data)
  const taskIdQuery = prop('taskIdQuery', data)
  const comment = prop('comment', data)
  const products = prop('products', data)

  // type android or default
  const beforeImgDefault = beforeImgArr && map((i) => {
    return path(['image', 'fileId'], i)
  }, beforeImgArr)

  const afterImgDefault = afterImgArr && map((i) => {
    return path(['image', 'fileId'], i)
  }, afterImgArr)

  const params = {
    market: marketId,
    comment,
    plan: taskIdQuery && taskIdQuery,
    products
  }
  return {
    after_img: afterImgDefault,
    before_img: beforeImgDefault,
    ...params
  }
}

export const marketsReportEditSerializer = data => {
  const beforeImgArr = propOr([], 'beforeImage', data)
  const afterImgArr = propOr([], 'afterImage', data)
  const marketId = prop('marketId', data)
  const taskIdQuery = prop('taskIdQuery', data)
  const comment = prop('comment', data)
  const products = prop('products', data)
  const reportId = prop('reportId', data)

  // type android or default
  const beforeImgDefault = beforeImgArr && map((i) => {
    return path(['image', 'fileId'], i)
  }, beforeImgArr)

  const afterImgDefault = afterImgArr && map((i) => {
    return path(['image', 'fileId'], i)
  }, afterImgArr)

  const params = {
    market: marketId,
    comment,
    plan: taskIdQuery && taskIdQuery,
    products,
    reportId
  }
  return {
    after_img: afterImgDefault,
    before_img: beforeImgDefault,
    ...params
  }
}

export const marketsVisitCreateSerializer = (data) => {
  const photos = propOr([], 'photos', data)
  const comment = prop('comment', data)
  const marketId = prop('marketId', data)
  const userLatitude = prop('userLatitude', data)
  const userLongitude = prop('userLongitude', data)
  const realDistance = prop('realDistance', data)
  const accuracy = prop('accuracy', data)
  const radioComment = prop('radioComment', data)

  const imgDefault = photos && map((i) => {
    return path(['image', 'fileId'], i)
  }, photos)

  const params = {
    comment: radioComment === 'custom' ? comment : radioComment,
    market: +marketId,
    location: {
      lat: userLatitude,
      lon: userLongitude
    },
    distance: realDistance,
    accuracy
  }

  return {
    photo_id: imgDefault,
    ...params
  }
}

export const marketsEditProductsListSerializer = (params, props) => {
  const {
    marketsSalesOrdersDetail
  } = props

  const marketsSalesOrdersDetailData = prop('data', marketsSalesOrdersDetail)

  const search = prop('search', params)
  const currency = path(['currency', 'id'], marketsSalesOrdersDetailData)
  const stock = path(['stock', 'id'], marketsSalesOrdersDetailData)
  const division = path(['division', 'id'], marketsSalesOrdersDetailData)
  const priceList = path(['priceList', 'id'], marketsSalesOrdersDetailData)
  return {
    price_list: priceList,
    currency,
    stock,
    division,
    search
  }
}

export const marketsReportsProductsListSerializer = (params, props) => {
  const {
    marketsItemData,
    configData
  } = props

  const priceList = prop('dEFAULTPRICELIST', configData)
  const division = path(['division', 'id'], marketsItemData)
  const search = prop('search', params)

  return {
    price_list: priceList,
    division,
    search
  }
}
