import React, {useReducer} from 'react'
import {prop} from 'ramda'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField/TextField'
import LogoIcon from 'components/SvgIcons/CustomIcons/LogoIcon'
import Button from 'components/Forms/Button/Button'
import {storageData} from 'helpers/storage'
import Loader from 'components/Loader'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 10px;
  background: #242424;
  color: #fff;
  label{
    color: #fff !important;
  }
`
const Form = styled('form')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  width: 90%;
  & > div{
    width: 100%;
  }
  & > * {
    margin-bottom: 15px !important;
  }
  button {
    margin-top: 15px;
  }
  label{
    color: #fff !important;
  }
`
const Warning = styled('span')`
  color: ${props => props.theme.palette.red};
`
const H = styled('h1')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 32px !important;
  svg{
    margin-top: 3px;
  }
`
const StyledTextField = styled(TextField)`
  color: #fff;
  &:focus{
    color: #000;
  }
`
const LoaderWrap = styled('div')`
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px;
`

const initialState = {username: '', password: '', server: storageData('server').getValue() || ''}
const reducer = (state, action) => ({...state, ...action})

const SignInGrid = props => {
  const {auth, onSubmit} = props

  const [values, dispatch] = useReducer(reducer, initialState)

  const onSubmitForm = event => {
    storageData('server').setValue(values.server)
    event.preventDefault()
    onSubmit(values)
  }

  const authLoading = prop('loading', auth)
  const authFailed = prop('failed', auth)
  const loader = <Loader />
  const button =
    <Button
      text={'Войти в систему'}
      fullWidth
      disabled={authLoading}
    />

  return (
    <Wrap>
      <Form onSubmit={onSubmitForm}>
        <H><LogoIcon size={24} /><span>Ритм</span></H>
        {authFailed &&
        <Warning>Неправильный логин или пароль</Warning>}
        <StyledTextField
          type={'text'}
          placeholder={'Сервер'}
          value={values.server}
          onChange={event => dispatch({server: event.target.value})}
          fullWidth
          required
        />
        <StyledTextField
          type={'text'}
          placeholder={'Логин'}
          value={values.username}
          onChange={event => dispatch({username: event.target.value})}
          fullWidth
          required
        />
        <StyledTextField
          type={'password'}
          placeholder={'Пароль'}
          value={values.password}
          onChange={event => dispatch({password: event.target.value})}
          fullWidth
          required
        />
        <LoaderWrap>
          {
            authLoading
              ? loader
              : button
          }
        </LoaderWrap>
      </Form>
    </Wrap>
  )
}

SignInGrid.propTypes = {
  auth: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SignInGrid
