import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import {map, path, prop, propOr} from 'ramda'
import BranchesCreateForm from 'containers/Branches/Grid/Create/Grid/Forms/BranchesCreateForm'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const BranchesCreateGrid = props => {
  const {
    branchesCreate,
    goBack
  } = props

  // Data
  const branchesCreateLoading = prop('loading', branchesCreate)

  // InitialValues
  const initialValues = {
    phones: [{}]
  }

  // HandleSubmit
  const handleSubmit = values => {
    const imagesArr = propOr([], 'images', values)
    const image = path(['0', 'image', 'fileId'], imagesArr)

    const data = {
      name: prop('name', values),
      address: prop('address', values),
      stock_type: 'basic',
      stock_manager: prop('manager', values),
      lat: path(['location', 'lat'], values),
      lon: path(['location', 'lon'], values),
      image,
      comment: prop('comment', values),
      guide: prop('guide', values),
      show_in_market: prop('showInMarket', values),
      phones: map(item => {
        return {
          phone: +numberWithoutSpaces(prop('phone', item))
        }
      }, prop('phones', values))
    }

    branchesCreate.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        haveMutators
      >
        <BranchesCreateForm
          loading={branchesCreateLoading}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Создание филиала'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default BranchesCreateGrid
