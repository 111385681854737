import {prop} from 'ramda'

export const posChecksSerializer = (params) => {
  const search = prop('search', params)
  const beginDate = prop('beginDate', params)
  const endDate = prop('endDate', params)

  return {
    search,
    beginDate,
    endDate,
    ordering: '-end_date'
  }
}
