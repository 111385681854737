import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import moment from 'moment'
import {ChevronRight as ArrowIcon} from 'react-feather'
import {TextStatus} from 'components/Status/TextStatus'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
  p{
    font-size: 15px;
    line-height: 18px;
    color: ${({theme}) => theme.text.secondary};
  }
`
const Date = styled('p')`
  font-size: 15px;
  line-height: 18px;
  color: ${({theme}) => theme.text.secondary};
  display: flex;
  flex-flow: row wrap;
`
const RightBar = styled(Bar)`
  align-items: flex-end;
`
const Type = styled('p')`
  color: ${({theme}) => theme.palette.black};
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`

const BatchesItem = props => {
  const {
    onClick,
    equipment,
    endDate,
    id,
    product,
    status
  } = props

  const statusText = status === 'canceled' ? 'Отменён' : 'Открыт'

  return (
    <>
      <TileItem
        onClick={onClick}
        styles={styles}
        height={'auto'}
      >
        <LeftBar>
          <h3>{'Партия №' + id}</h3>
          <p>{product || 'Не известно'}</p>
        </LeftBar>
        <RightBar>
          <Date>
            {endDate
              ? moment(endDate).format('DD MMM YYYY HH:mm')
              : <TextStatus bold status={status === 'canceled' ? 'inactive' : 'active'}>{statusText}</TextStatus>}
          </Date>
          <Type>{equipment || 'Не известно'}</Type>
        </RightBar>
        <ArrowWrap>
          <Arrow />
        </ArrowWrap>
      </TileItem>
    </>
  )
}

export default BatchesItem
