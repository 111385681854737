import React from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import {filter, map, path, pipe, prop, propOr, toPairs} from 'ramda'
import UsersCreateForm from 'containers/Users/Grid/Create/Grid/Forms/UsersCreateForm'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const UsersCreateGrid = props => {
  const {
    usersCreate,
    goBack,
    stocksList,
    config
  } = props

  // Data
  const usersCreateLoading = prop('loading', usersCreate)
  const configData = prop('data', config)

  // Const
  const priceListDefault = prop('pRICELISTIDFORTELEGRAM', configData)
  const currencyDefault = prop('pRIMARYCURRENCYID', configData)

  // InitialValues
  const initialValues = {}

  // HandleSubmit
  const handleSubmit = values => {
    const imagesArr = propOr([], 'images', values)
    const photo = path(['0', 'image', 'fileId'], imagesArr)
    const trueStocks = pipe(
      filter((item) => !!item),
      toPairs,
      map((item) => {
        const key = prop('0', item)
        if (key.startsWith('stock_')) {
          return key
        }
        return false
      }),
      filter((item) => !!item),
      map((item) => {
        return item.split('stock_')[1]
      })
    )(values)

    const data = {
      username: prop('username', values),
      first_name: prop('firstName', values),
      second_name: prop('secondName', values),
      phone_number: prop('phoneNumber', values),
      track_the_plan: prop('trackThePlan', values),
      password: prop('password', values),
      position: prop('position', values),
      is_active: prop('isActive', values),
      stocks: trueStocks,
      photo,
      price_lists: [priceListDefault],
      currencies: [currencyDefault]
    }

    usersCreate.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        haveMutators
      >
        <UsersCreateForm
          loading={usersCreateLoading}
          stocksList={stocksList}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Регистрация сотрудника'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default UsersCreateGrid
