import React from 'react'
import {prop, propOr, map, pipe, mergeAll, filter, toPairs} from 'ramda'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import MainBar from 'components/Navigation/TopBar/MainBar'
import styled from 'styled-components'
import Loader from 'components/Loader'
import Form from 'components/Forms/Form'
import SettingsFormFields from './Forms/SettingsFormFields'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
  display: flex;
  flex-flow: column nowrap;
`

const SettingsBotGrid = props => {
  const {
    settingsBot,
    settingsBotEdit
  } = props

  // Data
  const settingsBotData = prop('data', settingsBotEdit) || prop('data', settingsBot)
  const settingsBotLoading = prop('loading', settingsBot)
  const settingsBotEditLoading = prop('loading', settingsBotEdit)

  // Const
  const canCash = prop('canCash', settingsBotData)
  const canTerminal = prop('canTerminal', settingsBotData)
  const canDelivery = prop('canDelivery', settingsBotData)
  const canSelf = prop('canSelf', settingsBotData)
  const orderChannelId = prop('orderChannelId', settingsBotData)
  const languages = propOr([], 'languages', settingsBotData)
  const languagesMap = pipe(
    map(item => {
      return {
        [`lang_${item.toLowerCase()}`]: true
      }
    }),
    mergeAll
  )(languages)
  const subtext = prop('subtext', settingsBotData)
  const ruAboutText = prop('ruAboutText', settingsBotData)
  const uzAboutText = prop('uzAboutText', settingsBotData)
  const uzKAboutText = prop('uzKAboutText', settingsBotData)
  const kkAboutText = prop('kkAboutText', settingsBotData)
  const enAboutText = prop('enAboutText', settingsBotData)
  const deliveryPriceType = prop('deliveryPriceType', settingsBotData)
  const canSendOrderNotification = prop('canSendOrderNotification', settingsBotData)
  const deliveryPrice = prop('deliveryPrice', settingsBotData)
  const fixedDeliveryPrice = prop('fixedDeliveryPrice', settingsBotData)

  // InitialValues
  const initialValues = {
    canCash,
    canTerminal,
    canDelivery,
    canSelf,
    orderChannelId,
    ...languagesMap,
    ruAboutText,
    uzAboutText,
    uzKAboutText,
    enAboutText,
    kkAboutText,
    deliveryPriceType: deliveryPriceType || 'custom',
    canSendOrderNotification,
    deliveryPrice: deliveryPrice ? +deliveryPrice : null,
    fixedDeliveryPrice: fixedDeliveryPrice ? +fixedDeliveryPrice : null,
    subtext
  }

  // Submit
  const handleSubmit = values => {
    // Values
    const langValues = pipe(
      toPairs,
      filter(item => item && item[0].slice(0, 5) === 'lang_'),
      map(item => item && item[0].slice(5))
    )(values)
    const deliveryPriceTypeValue = prop('deliveryPriceType', values)
    const deliveryPriceValue = prop('deliveryPrice', values)
    const fixedDeliveryPriceValue = prop('fixedDeliveryPrice', values)

    // Data
    const data = {
      can_cash: prop('canCash', values),
      can_delivery: prop('canDelivery', values),
      can_self: prop('canSelf', values),
      can_terminal: prop('canTerminal', values),
      can_send_order_notification: prop('canSendOrderNotification', values),
      delivery_price_type: deliveryPriceTypeValue,
      delivery_price: deliveryPriceTypeValue === 'custom' ? deliveryPriceValue : null,
      fixed_delivery_price: deliveryPriceTypeValue === 'fixed' ? fixedDeliveryPriceValue : null,
      order_channel_id: prop('orderChannelId', values),
      ru_about_text: prop('ruAboutText', values),
      uz_about_text: prop('uzAboutText', values),
      kk_about_text: prop('kkAboutText', values),
      uz_k_about_text: prop('uzKAboutText', values),
      en_about_text: prop('enAboutText', values),
      subtext: prop('subtext', values),
      languages: langValues
    }

    settingsBotEdit.onSubmit(data)
  }

  // MainContent
  const mainContent =
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <SettingsFormFields
          loading={settingsBotEditLoading}
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <MainBar
        title={'Настройки бота'}
        firstBtn={<MainMenu />}
      />
      <Container>
        {settingsBotLoading ? <Loader /> : mainContent}
      </Container>
    </>
  )
}

export default SettingsBotGrid
