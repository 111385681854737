import React, {useState} from 'react'
import {MainBar} from 'components/Navigation/TopBar'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import styled from 'styled-components'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from 'containers/Stocks/containers/Rests/Grid/StocksRestsFilter'
import mapIndexed from 'helpers/mapIndexed'
import {stocksRestsSerializer} from 'containers/Stocks/actions/serializers/stocksSerializers'
import StocksRestsItem from 'containers/Stocks/components/StocksRestsItem/StocksRestsItem'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'

const Content = styled('div')`
  min-height: ${({secondHeight}) => 'calc(100vh - ' + (60 + secondHeight) + 'px)'} ;
`

const StocksRestsGrid = (props) => {
  const {
    filterActions,
    location,
    customStore,
    stocksRestsList
  } = props

  // Data
  const customStoreData = prop('data', customStore)
  const stocks = prop('stocks', customStoreData)
  const stocksRestsListData = prop('data', stocksRestsList)

  // UseStates
  const [openFilter, setOpenFilter] = useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // SecondHeight
  const secondHeight = 100

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // Rests List
  const restsList = stocksRestsListData && mapIndexed((item, index) => {
    const name = prop('title', item)
    const measurementName = path(['measurement', 'name'], item)
    const balance = prop('balance', item)
    const balanceText = balance &&
      <>
        <span>На складе:&nbsp;</span>
        <TextStatus bold status={balance > 0 ? 'active' : 'inactive'}>{numberFormat(balance, measurementName)}</TextStatus>
      </>
    const reserved = prop('reserved', item)
    const reservedText = (reserved && +reserved !== 0) &&
      <>
        <span>Бронь:&nbsp;</span>
        <TextStatus bold status={reserved > 0 && 'active'}>{numberFormat(reserved, measurementName)}</TextStatus>
      </>
    const defects = prop('defects', item)
    const defectsText = (defects && +defects !== 0) &&
      <>
        <span>Брак:&nbsp;</span>
        <TextStatus bold status={defects > 0 && 'inactive'}>{numberFormat(defects, measurementName)}</TextStatus>
      </>
    const imgSrc = path(['image', 'file'], item)

    return (
      <StocksRestsItem
        name={name}
        balance={balanceText}
        reserved={reservedText}
        defects={defectsText}
        imgSrc={imgSrc}
        key={index}
      />
    )
  }, stocksRestsListData)

  // MainContent
  const outcomeTasks =
    <InfiniteScroll
      api={API.STOCKS_RESTS_LIST}
      actionType={actionTypes.STOCKS_RESTS_LIST}
      emptyData={<li><NoResults /></li>}
      params={stocksRestsSerializer(searchObj)}
      list={restsList || []}
    />

  // Render
  return (
    <>
      <MainBar
        title={'Остатки'}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
          >
            <Filter
              setOpenFilter={setOpenFilter}
              stocks={stocks}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
      >
        <Content secondHeight={secondHeight}>
          {outcomeTasks}
        </Content>
      </Container>
    </>
  )
}

export default StocksRestsGrid
