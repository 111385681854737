import React, {useState} from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from 'containers/Cars/Grid/CarsFilters'
import {MainBar} from 'components/Navigation/TopBar'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import Container from 'components/Container'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import * as serializers from 'containers/Cars/actions/serializers/carsSerializer'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import CarsItem from 'containers/Cars/components/CarsItem/CarsItem'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'

const CarsGrid = (props) => {
  const {
    carsList,
    location,
    filterActions,
    goCreate,
    goDetail
  } = props

  // useStates
  const [openFilter, setOpenFilter] = useState(false)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Data
  const carsListData = prop('data', carsList)

  // Debounce search
  const searchForm =
    <DebounceSearch
      filterActions={filterActions}
      query={'carsSearch'}
    />

  // Main content
  const list = carsListData && mapIndexed((item) => {
    const id = prop('id', item)
    const number = prop('number', item)
    const carBrand = path(['carBrand', 'name'], item)
    const carBrandParent = path(['carBrand', 'parent', 'name'], item)
    const name = carBrandParent ? (carBrandParent + ' ' + carBrand) : carBrand

    return (
      <CarsItem
        key={id}
        carBrand={name}
        number={number}
        status={'none'}
        onClick={() => goDetail(id)}
      />
    )
  }, carsListData)

  // Option
  const menusArr = [
    {
      title: 'Регистрация авто',
      onClick: () => goCreate()
    }
  ]

  // Return
  return (
    <>
      <MainBar
        title={'Авто'}
        secondHeight={100}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
        lastBtn={
          <FiltersBar
            isOpen={openFilter}
            setOpen={setOpenFilter}
          >
            <Filter
              setOpenFilter={setOpenFilter}
              {...filterActions}
            />
          </FiltersBar>
        }
      />
      <Container
        background={'#fff'}
        secondHeight={100}
      >
        <InfiniteScroll
          api={API.CARS_LIST}
          actionType={actionTypes.CARS_LIST}
          emptyData={<li><NoResults /></li>}
          params={serializers.carsListSerializer(searchObj)}
          list={list || []}
        />
      </Container>
      <SecondMenu
        title={'Дополнительные функции по работе с авто'}
        menusArr={menusArr}
        button={
          <Fab
            text={'Дополнительно'}
            withfixed={'true'}
          ><Plus size={20} color={'#fff'} />
          </Fab>
        }
      />
    </>
  )
}

export default CarsGrid
