import {prop} from 'ramda'
import {compose, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import {MARKETS_URL} from 'constants/routes'
import {storageData} from 'helpers/storage'
import {parseParams} from 'helpers/url'
import {getDataFromState} from 'helpers/get'
import {signInAction, customStoreAction, configAction} from './actions/signInAction'
import SignInGrid from 'containers/SignIn/Grid/SignInGrid'

const mapStateToProps = state => ({
  auth: getDataFromState('auth', state)
})

export default compose(
  connect(mapStateToProps, {signInAction, customStoreAction, configAction}),
  withHandlers({
    onSubmit: props => values => {
      const queries = parseParams(props.location.search)
      const queryRedirect = prop('redirect', queries) || MARKETS_URL
      const redirect = decodeURIComponent(queryRedirect)
      return props.signInAction(values)
        .then(({value}) => {
          const token = prop('token', value)
          storageData('token').setValue(token)
          props.history.replace(redirect)
        })
        .then(() => props.customStoreAction())
    }
  })
)(SignInGrid)
