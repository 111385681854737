import React from 'react'
import {Field} from 'react-final-form'
import Button, {Fixed} from 'components/Forms/Button/Button'
import Form from 'components/Forms/Form/Form'
import {prop} from 'ramda'
import styled from 'styled-components'
import TextField from 'components/Forms/TextField'
import Switcher from 'components/Forms/Switcher'
import {storageData} from 'helpers/storage'
import Perms from 'components/Perms/Perms'

const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const SwitchersWrap = styled('div')`
  padding-top: 10px;
  border-top: ${({theme}) => theme.border.primary};
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = () => {
  return (
    <StyledWrap>
      <Field
        component={TextField}
        type={'date'}
        name={'beginDate'}
        label={'Период до'}
        fullWidth
      />
      <Field
        component={TextField}
        type={'date'}
        name={'endDate'}
        label={'Период с'}
        fullWidth
      />
      <Perms perms={['can_see_total_stat_mobile']}>
        <SwitchersWrap>
          <Field
            component={Switcher}
            name={'supervisor'}
            type={'checkbox'}
            label={'Моя статистика'}
          />
        </SwitchersWrap>
      </Perms>
      <Fixed>
        <Button
          text={'Применить'}
          fullWidth
        />
      </Fixed>
    </StyledWrap>
  )
}

const Filter = (props) => {
  const {
    setFilterIsOpen,
    onChangeFilter,
    initialValues,
    searchObj,
    hasPerms,
    isAdmin
  } = props

  const isOnlyMyStats = (hasPerms || isAdmin) && (prop('supervisor', searchObj) || storageData('onlyMyStats').getValue() || null)

  // Submit
  const handleSubmit = (values) => {
    const supervisor = (hasPerms || isAdmin) ? prop('supervisor', values) : null
    const onlyMyStats = supervisor === true ? '1' : supervisor === false ? '0' : null
    !!(hasPerms || isAdmin) && storageData('onlyMyStats').setValue(onlyMyStats || '0')
    setTimeout(() => {
      onChangeFilter({
        beginDate: prop('beginDate', values),
        endDate: prop('endDate', values),
        supervisor: onlyMyStats
      })
      setFilterIsOpen(false)
    }, 0)
  }

  // Initial values
  const newInitialValues = {
    ...initialValues,
    supervisor: !!(+isOnlyMyStats)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={newInitialValues}
    >
      <FormFields />
    </Form>
  )
}

export default Filter
