import {compose} from 'recompose'
import Grid from './Grid'
import {withCreate, withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {
  branchesDetailAction,
  branchesEditAction
} from 'containers/Branches/actions/branchesActions'

export default compose(
  withCreate({
    stateName: STATES.BRANCHES_EDIT,
    action: branchesEditAction,
    key: STATES.BRANCHES_EDIT,
    redirectToCreated: ROUTES.BRANCHES_URL,
    successMessage: 'Продукт создан'
  }),
  withFetchList({
    stateName: STATES.BRANCHES_DETAIL,
    action: branchesDetailAction,
    key: STATES.BRANCHES_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.BRANCHES_URL
  })
)(Grid)
