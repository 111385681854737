import React, {useMemo} from 'react'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import Container from 'components/Container/Container'
import styled from 'styled-components'
import Form from 'components/Forms/Form'
import ProductsCreateForm from 'containers/Products/Grid/Create/Grid/Forms/ProductsCreateForm'
import {map, path, prop, propOr, range} from 'ramda'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`

const ProductsEditGrid = props => {
  const {
    productsDetail,
    productsEdit,
    goBack,
    match
  } = props
  // Location
  const productId = path(['params', 'id'], match)

  // Data
  const productsDetailData = prop('data', productsDetail)
  const productsDetailLoading = prop('loading', productsDetail)
  const productsEditLoading = prop('loading', productsEdit)
  // Const
  const productName = prop('name', productsDetailData)
  const showInMarket = prop('showInMarket', productsDetailData)
  const measurementId = path(['measurement', 'id'], productsDetailData)
  const price = prop('price', productsDetailData)
  const description = prop('description', productsDetailData)
  const image = prop('image', productsDetailData)
  const initialImages = useMemo(
    () => map(() => {
      return {
        image
      }
    }, [range(0, 1)]), [image])
  const stockNumber = prop('stockNumber', productsDetailData)
  const typeId = path(['type', 'id'], productsDetailData)

  // InitialValues
  const initialValues = !productsDetailLoading && {
    name: productName,
    measurement: measurementId,
    price: price ? +price : null,
    description,
    images: image ? initialImages : null,
    stockNumber,
    showInMarket: showInMarket,
    type: {value: `${typeId}`}
  }

  // HandleSubmit
  const handleSubmit = values => {
    const imagesArr = propOr([], 'images', values)
    const image = path(['0', 'image', 'fileId'], imagesArr) || path(['0', 'image', 'id'], imagesArr)
    const data = {
      id: productId,
      name: prop('name', values),
      type: path(['type', 'value'], values),
      measurement: prop('measurement', values),
      price: prop('price', values),
      description: prop('description', values),
      image,
      stockNumber: prop('stockNumber', values),
      showInMarket: prop('showInMarket', values)
    }

    productsEdit.onSubmit(data)
  }

  // MainContent
  const mainContent = !productsDetailLoading &&
    <Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        haveMutators
      >
        <ProductsCreateForm
          productsCreateLoading={productsEditLoading}
          isEdit
        />
      </Form>
    </Wrap>

  // Render
  return (
    <>
      <CreateBar
        title={'Редактирование продукта'}
        goExit={() => goBack(productId)}
      />
      <Container
        background={'#fff'}
      >
        {productsDetailLoading ? <Loader /> : productsDetailData ? mainContent : <NoResults text={'Проверьте соединение с интернетом'} />}
      </Container>
    </>
  )
}

export default ProductsEditGrid
