import {compose, withHandlers} from 'recompose'
import MarketsDetailGrid from 'containers/Clients/Grid/Detail/Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {
  marketsItemAction
} from 'containers/Clients/actions/marketsAction'
import {appendParamsToUrl} from 'helpers/url'
import {map, path, pathOr, pipe, prop} from 'ramda'
import * as STATES from 'constants/states'
import {connect} from 'react-redux'

export default compose(
  connect(state => ({
    customStore: prop('customStore', state),
    userPerms: pipe(pathOr([], ['customStore', 'data', 'permissions']), map(prop('codename')))(state),
    isAdmin: path(['customStore', 'data', 'isSuperuser'], state)
  })),
  withFetchList({
    stateName: STATES.MARKETS_ITEM,
    action: marketsItemAction,
    key: STATES.MARKETS_ITEM
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goMap',
    redirectUrl: ROUTES.MARKETS_ITEM_MAP_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrders',
    redirectUrl: ROUTES.MARKETS_ORDERS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goBalances',
    redirectUrl: ROUTES.MARKETS_ITEM_BALANCES_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goReturns',
    redirectUrl: ROUTES.MARKETS_RETURNS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goReturnsCreate',
    redirectUrl: ROUTES.MARKETS_RETURNS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goOrdersCreate',
    redirectUrl: ROUTES.MARKETS_ORDERS_CREATE_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goStatistics',
    redirectUrl: ROUTES.MARKETS_STATISTICS_MAIN_BY_PRODUCTS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goTaskCreate',
    redirectUrl: ROUTES.MARKETS_ITEM_BALANCES_NOTIFICATION_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goReports',
    redirectUrl: ROUTES.MARKETS_REPORTS_URL,
    withCurrentParams: true
  }),
  withHandlers({
    handleSetDivisionQuery: props => (division) => {
      const divisionId = division || ''
      props.history.push({
        search: appendParamsToUrl({division: divisionId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(MarketsDetailGrid)
