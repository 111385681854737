import React from 'react'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import styled from 'styled-components'
import UploadImages from 'components/Forms/UploadField/UploadImages'
import Switcher from 'components/Forms/Switcher'
import {map, prop, propOr} from 'ramda'
import * as API from 'constants/api'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import Loader from 'components/Loader'
import Label from 'components/Forms/Label'

const Wrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 15px;
  }
`

const UsersCreateForm = props => {
  const {
    stocksList,
    loading,
    isEdit,
    values
  } = props

  // Data
  const stocksListData = propOr([], 'results', stocksList)
  const stocksListLoading = prop('loading', stocksList)

  // StocksArr
  const stocksArr = map(item => {
    const name = prop('name', item)
    const id = prop('id', item)

    return (
      <Field
        component={Switcher}
        name={`stock_${id}`}
        type={'checkbox'}
        label={name}
        key={id}
      />
    )
  }, stocksListData)

  // StocksContent
  const stocksContent =
    <>
      <Label>Связанные филиалы</Label>
      {stocksArr}
    </>

  // Values
  const isActive = prop('isActive', values)
  const password = prop('password', values)
  const rePassword = prop('rePassword', values)

  const isEq = isActive ? (password !== rePassword || loading) : loading

  return (
    <Wrap>
      <Field
        component={TextField}
        type={'text'}
        label={'Имя'}
        name={'firstName'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'text'}
        label={'Фамилия'}
        name={'secondName'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        type={'number'}
        label={'Телефон'}
        name={'phoneNumber'}
        required
        fullWidth
      />
      <Field
        component={UploadImages}
        name={'images'}
        label={'Фото'}
        single
        required
        fullWidth
      />
      <Field
        component={Switcher}
        name={'isActive'}
        type={'checkbox'}
        label={'Доступ к системе'}
      />
      {isActive &&
        <>
          <Field
            component={TextField}
            type={'text'}
            label={'Логин'}
            name={'username'}
            required
            fullWidth
          />
          <Field
            component={TextField}
            type={'password'}
            label={'Пароль'}
            name={'password'}
            required
            fullWidth
          />
          <Field
            component={TextField}
            type={'password'}
            label={'Пароль'}
            name={'rePassword'}
            required
            fullWidth
          />
          <Field
            component={UniversalSearchField}
            label={'Права доступа'}
            name={'position'}
            api={API.POSITIONS_LIST}
            params={{page_size: '1000'}}
            required
            fullWidth
          />
        </>}
      <Field
        component={Switcher}
        name={'trackThePlan'}
        type={'checkbox'}
        label={'Отслеживать план'}
      />
      {stocksListLoading
        ? <Loader />
        : stocksContent}
      <BottomBar>
        <Button
          text={isEdit ? 'Редактировать' : 'Зарегистрировать'}
          disabled={isEq}
          fullWidth
        />
      </BottomBar>
    </Wrap>
  )
}

export default UsersCreateForm
