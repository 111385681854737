import React, {useMemo} from 'react'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import styled from 'styled-components'
import {Field} from 'react-final-form'
import Switcher from 'components/Forms/Switcher'
import TextField from 'components/Forms/TextField'
import {filter, map, prop} from 'ramda'
import checkPermissions from 'helpers/checkPermissions'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import MapField from 'components/Forms/MapField'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import * as API from 'constants/api'
import normalizePhone from 'helpers/normalizers/normalizePhone'

const Wrap = styled('div')`
  position: relative;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const MarketsOrdersEditForm = props => {
  const {
    isPointOrder,
    mapPerms,
    isAdmin,
    stocks,
    values,
    marketsOrdersEditLoading
  } = props

  // Values
  const deliveryType = prop('deliveryType', values)

  // Perms
  const hasPermsCanSelf = checkPermissions(['can_self'], mapPerms)

  const deliveryTypeOptions = [
    {value: 'delivery', label: 'Доставка'},
    (hasPermsCanSelf || isAdmin) && {value: 'self', label: 'Самовывоз'}
  ]

  const filteredDeliveryTypeOptions = useMemo(() => {
    return deliveryTypeOptions && filter((i) => !!i === true)(deliveryTypeOptions)
  }, [deliveryTypeOptions])
  const deliveryTypesLength = +prop('length', filteredDeliveryTypeOptions)

  const stocksListOptions = stocks && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, stocks)

  const paymentTypeOptions = [
    {value: 'cash', label: 'Наличные'},
    {value: 'bank', label: 'Терминал'},
    {value: 'payme', label: 'Payme'},
    {value: 'click', label: 'Click'}
  ]

  return (
    <Wrap>
      <Field
        name={'secondPhone'}
        label={'Дополнительный номер тел.'}
        component={TextField}
        placeholder={'Введите номер'}
        parse={normalizePhone}
        defaultValue={'+998'}
        type={'text'}
      />
      {!isPointOrder &&
      <Field
        component={Switcher}
        name={'isConfirmed'}
        type={'checkbox'}
        label={'Подтверждённый заказ'}
        fullWidth
      />}
      <Field
        label={'Локация'}
        component={MapField}
        name={'location'}
      />
      <Field
        component={TextField}
        name={'deliveryAddress'}
        label={'Адрес'}
        multiline
        required
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'paymentType'}
        label={'Тип оплаты'}
        options={paymentTypeOptions}
        required
        fullWidth
      />
      <Field
        component={StaticSearchField}
        name={'stock'}
        label={'Филиал'}
        type={'select'}
        options={stocksListOptions}
        required
        fullWidth
      />
      {!(deliveryTypesLength === 1) &&
      <Field
        component={StaticSearchField}
        name={'deliveryType'}
        label={'Тип доставки'}
        options={filteredDeliveryTypeOptions}
        required
        fullWidth
      />}
      {deliveryType === 'delivery' &&
      <>
        <Field
          component={UsersSearchField}
          api={API.USERS_LIST_FOR_SELECT}
          params={{group: 'delivery', own_division: 'true', page_size: 1000}}
          name={'deliveryMan'}
          label={'Доставщик'}
          fullWidth
        />
        <Field
          component={TextField}
          type={'number'}
          name={'deliveryPrice'}
          label={'Стоимость доставки'}
          fullWidth
        />
      </>}
      <Field
        component={TextField}
        name={'dateDelivery'}
        type={'datetime-local'}
        label={'Дата доставки'}
        required
        fullWidth
      />
      <Field
        component={TextField}
        name={'comment'}
        label={'Комментарий'}
        multiline
        fullWidth
      />
      <BottomBar>
        <Button
          text={'Редактировать'}
          type={'submit'}
          disabled={marketsOrdersEditLoading}
          fullWidth
        />
      </BottomBar>
    </Wrap>
  )
}

export default MarketsOrdersEditForm
