import React, {useEffect, useState} from 'react'
import {find, isEmpty, map, path, pathOr, prop, propEq, propOr, reject} from 'ramda'
import Container from 'components/Container/Container'
import MarketsOrdersEditTabOne from 'containers/Clients/Grid/OrdersEdit/Grid/Tabs/MarketsOrdersEditTabOne'
import styled from 'styled-components'
import MarketsOrdersEditTabTwo from 'containers/Clients/Grid/OrdersEdit/Grid/Tabs/MarketsOrdersEditTabTwo'
import CreateBar from 'components/Navigation/TopBar/CreateBar'
import AddProductsModal from 'containers/Clients/Grid/OrdersEdit/Grid/Modals/AddProductsModal'
import {parseParams} from 'helpers/url'

const TabWrap = styled('div')`
  overflow: ${({value, show}) => value !== show && 'hidden'};
  height: ${({value, show}) => value !== show && '0'};
  margin: ${({value, show}) => value !== show && '0'};
  padding: ${({value, show}) => value !== show && '0'};
  & button {
    display: ${({value, show}) => value !== show && 'none'};
  }
`

const MarketsOrdersEditGrid = props => {
  const {
    marketsSalesOrdersDetail,
    marketsProductsList,
    match,
    goBack,
    location,
    customStore,
    addProductIdHandler,
    config
  } = props

  // useState
  const [products, setProducts] = useState([])
  const [value, setValue] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState({})

  // Location
  const marketId = path(['params', 'id'], match)
  const orderId = path(['params', 'orderId'], match)
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const productIdParam = prop('productId', searchObj)

  // Data
  const marketsSalesOrdersDetailData = prop('data', marketsSalesOrdersDetail)
  const marketsProductsListData = propOr([], 'data', marketsProductsList)
  const configData = prop('data', config)
  const customStoreData = prop('data', customStore)

  // Const
  const canIsLimited = prop('cANISLIMITED', configData)
  const productsArr = propOr([], 'products', marketsSalesOrdersDetailData)
  const noProducts = isEmpty(productsArr)
  const customPriceName = 'customPrice_' + productIdParam
  const paymentType = prop('paymentType', marketsSalesOrdersDetailData)
  const productInitialCurrently = find(propEq('id', +productIdParam))(marketsProductsListData)
  const permissions = pathOr([], ['data', 'permissions'], customStore)

  const productsInitialPrice =
    paymentType === 'cash'
      ? prop('cashPrice', productInitialCurrently)
      : paymentType === 'bank'
        ? prop('transferPrice', productInitialCurrently)
        : prop('ball', productInitialCurrently)

  // Perms
  const isAdmin = prop('isSuperuser', customStoreData)
  const mapPerms = map((item) => {
    return prop('codename', item)
  }, permissions)

  // Initial values
  const initialValuesProducts = {
    [customPriceName]: productsInitialPrice && +productsInitialPrice
  }

  // useEffect
  useEffect(() => {
    if (!noProducts) {
      const newProducts = map((item) => {
        const images = pathOr([], ['product', 'images'], item)
        const primaryImage = find(propEq('isPrimary'), images)

        return {
          product: `${path(['product', 'id'], item)}`,
          product_name: path(['product', 'name'], item),
          total_price: prop('totalPrice', item),
          measurement: path(['product', 'measurement'], item),
          amount: prop('amount', item),
          custom_price: prop('price', item),
          cash_price: prop('cashPrice', item),
          transfer_price: prop('transferPrice', item),
          limit_balance: prop('limitBalance', item),
          image: primaryImage
        }
      }, productsArr)

      setProducts(newProducts)
    }
    // eslint-disable-next-line
  }, [noProducts])

  // HandleSubmits
  const handleAddProductsSubmit = values => {
    const amountPlus = 'amount_' + productIdParam
    const customPricePlus = 'customPrice_' + productIdParam
    const customPrice = prop(customPricePlus, values)
    const amount = prop(amountPlus, values)
    const newObject = {
      product: productIdParam,
      product_name: prop('name', selectedProduct),
      measurement: prop('measurement', selectedProduct),
      amount,
      custom_price: customPrice,
      total_price: customPrice * amount,
      image: prop('image', selectedProduct),
      boxes: prop('boxes', selectedProduct),
      transfer_price: prop('transferPrice', selectedProduct),
      cash_price: prop('cashPrice', selectedProduct)
    }
    const leftObject = propEq('product', productIdParam)
    const rejectedProducts = reject(leftObject, products)
    const newProducts = [...rejectedProducts, newObject]

    const sortedProducts = reject(propEq('amount', '0'), newProducts)
    setProducts(sortedProducts)

    setModalOpen(false)
  }
  const handleDeleteProduct = () => {
    const withoutDeletedProduct = reject(propEq('product', productIdParam), products)
    setProducts(withoutDeletedProduct)
    setModalOpen(false)
  }

  // Modals
  const modals =
    <>
      <AddProductsModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        selectedProduct={selectedProduct}
        initialValues={initialValuesProducts}
        handleSubmit={handleAddProductsSubmit}
        handleDelete={handleDeleteProduct}
        location={location}
        customStore={customStore}
      />
    </>

  // MainContent
  const mainContent =
    <>
      <TabWrap
        value={value}
        show={0}
      >
        <MarketsOrdersEditTabOne
          products={products}
          setValue={setValue}
          setModalOpen={setModalOpen}
          setSelectedProduct={setSelectedProduct}
          addProductIdHandler={addProductIdHandler}
          canIsLimited={canIsLimited}
          orderId={orderId}
          mapPerms={mapPerms}
          isAdmin={isAdmin}
          {...props}
        />
      </TabWrap>
      <TabWrap
        value={value}
        show={1}
      >
        <MarketsOrdersEditTabTwo
          setValue={setValue}
          products={products}
          value={value}
          setModalOpen={setModalOpen}
          setSelectedProduct={setSelectedProduct}
          {...props}
        />
      </TabWrap>
    </>

  // Render
  return (
    <>
      <CreateBar
        title={orderId ? ('Заказ № ' + orderId) : 'Подтвердить'}
        goBack={value === 1 && (() => setValue(0))}
        goExit={() => goBack(marketId, orderId)}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      {modals}
    </>
  )
}

export default MarketsOrdersEditGrid
