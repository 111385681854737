import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {filter, find, map, path, pathEq, prop, propEq} from 'ramda'
import Button from 'components/Forms/Button'
import BottomBar from 'components/Navigation/BottomBar'
import Form from 'components/Forms/Form'
import TextField from 'components/Forms/TextField'
import {Field} from 'react-final-form'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import mapIndexed from 'helpers/mapIndexed'
import numberFormat from 'helpers/numberFormat'
import Switcher from 'components/Forms/Switcher'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import {TextStatus} from 'components/Status/TextStatus'
import ModalConfirm from 'components/Modal/ModalConfirm'
import normalizeNumber from 'helpers/normalizers/normalizeNumber'
import numberWithoutSpaces from 'helpers/numberWithoutSpaces'
import {selectValueFrom, CURRENCY} from 'constants/backendConstant'
import Loader from 'components/Loader'

const StyledWrap = styled('div')`
  padding: 14px 16px 64px;
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`
const Wrap = styled('div')`
  position: relative;
  min-height: calc(100vh - 60px);
`

const P = styled('p')`
  color: ${({theme}) => theme.palette.black};
`

const TwoFields = styled('div')`
  display:flex;
  & > :first-child {
    width: 50%;
  }
    & > :last-child {
    width: 50%;
    margin-left: 10px;
  }
`
const HeadComment = styled('div')`
  margin-bottom: 15px;
  span {
    color: #000;
  }
`

const FormFields = props => {
  const {
    divisionPrice,
    totalPrice,
    customStoreLoading,
    marketsClientBalancesLoading,
    paymentsConvertData,
    handlePreSubmit,
    openConfirm,
    setOpenConfirm,
    createLoading,
    customStoreData,
    previewList,
    financesClientTransactionPreviewLoading,
    amountWithoutSpaces,
    formValues,
    currencyNameValue
  } = props

  // Options
  const currencies = prop('currencies', customStoreData)
  const currenciesOptions = currencies && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: `${value}`, label}
  }, currencies)

  const divisions = prop('divisions', customStoreData)
  const divisionsOptions = divisions && map((item) => {
    const value = prop('id', item)
    const label = prop('name', item)
    return {value: value, label}
  }, divisions)

  // Values
  const autoDistributionValue = prop('autoDistribution', formValues)
  const divisionValue = prop('division', formValues)

  // Const
  const realValues = prop('values', props)
  const realValuesDivision = prop('division', realValues)
  const realValuesAutoDistribution = prop('autoDistribution', realValues)
  const realValuesCurrency = path(['currency', 'value'], realValues) || prop('currency', realValues)
  const realSaleCurrency = path(['currencyDebt', 'value'], realValues) || prop('currencyDebt', realValues)
  const realCourse = prop('customRate', realValues)
  const realValuesAmount = numberWithoutSpaces(prop('amount', realValues)) || null
  const filteredDivisions = divisionPrice && filter(pathEq(['division', 'id'], realValuesDivision), divisionPrice)
  const filteredDivisionsByPaymentType = filteredDivisions && filter(propEq('paymentType', 'cash'), filteredDivisions)
  const foundDivision = filteredDivisionsByPaymentType && find(pathEq(['currency', 'id'], (+realSaleCurrency || +realValuesCurrency)), filteredDivisionsByPaymentType)
  const restConvert = () => {
    if (realValuesCurrency === '2' && realSaleCurrency === '1') {
      return (+realValuesAmount / +realCourse)
    } else if (realValuesCurrency === '1' && realSaleCurrency === '2') {
      return (+realValuesAmount * +realCourse)
    } else {
      return +realValuesAmount
    }
  }
  const restBalanceCalc = (+prop('totalAmount', foundDivision) + +restConvert())
  const foundBalanceByCurrency = totalPrice && find(propEq('currencyId', (+realSaleCurrency || +realValuesCurrency)), totalPrice)
  const restTotalBalanceCalc = (+prop('price', foundBalanceByCurrency) + +restConvert())
  const restBalanceStatus = (restBalanceCalc >= 0) ? 'active' : 'inactive'
  const restTotalBalanceStatus = (restTotalBalanceCalc >= 0) ? 'active' : 'inactive'
  const isConversion = prop('isConversion', realValues)

  const restBalance = (<p>Остатки: <TextStatus bold status={restBalanceStatus}>{numberFormat(restBalanceCalc, currencyNameValue)} {selectValueFrom(realSaleCurrency || realValuesCurrency, CURRENCY)}</TextStatus></p>)
  const restTotalBalance = (<p>Остатки: <TextStatus bold status={restTotalBalanceStatus}>{numberFormat(restTotalBalanceCalc, currencyNameValue)} {selectValueFrom(realSaleCurrency || realValuesCurrency, CURRENCY)}</TextStatus></p>)
  const filteredDivisionsContent = filteredDivisions &&
    mapIndexed((item, index) => {
      const totalAmount = prop('totalAmount', item)
      const currency = path(['currency', 'name'], item)
      const totalAmountStatus = totalAmount >= 0 ? 'active' : 'inactive'
      const paymentType = prop('paymentType', item)
      const paymentTypeText = paymentType === 'bank' ? 'Перечисление' : 'Наличные'

      return (
        <React.Fragment key={index}>
          {(paymentType === 'cash') && <P>{paymentTypeText}: <TextStatus bold status={totalAmountStatus}>{numberFormat(totalAmount, currency)}</TextStatus></P>}
        </React.Fragment>
      )
    }, filteredDivisions)

  const filteredTotalPrices = totalPrice && filter((item) => +prop('price', item) !== 0, totalPrice)
  const autoDistributionBalances = filteredTotalPrices &&
    mapIndexed((item, index) => {
      const totalAmount = prop('price', item)
      const currency = prop('currencyName', item)
      const totalAmountStatus = totalAmount >= 0 ? 'active' : 'inactive'

      return (
        <React.Fragment key={index}>
          <P>Баланс: <TextStatus bold status={totalAmountStatus}>{numberFormat(totalAmount, currency)}</TextStatus></P>
        </React.Fragment>
      )
    }, filteredTotalPrices)

  const vremennoOff = false
  const balancesInfo =
    <>
      {!realValuesAutoDistribution && filteredDivisionsContent}
      {realValuesAutoDistribution && autoDistributionBalances}
    </>
  const restBalancesInfo =
    <>
      {(!isNaN(restBalanceCalc) && !realValuesAutoDistribution) && restBalance}
      {(!isNaN(restTotalBalanceCalc) && realValuesAutoDistribution) && restTotalBalance}
    </>

  // Button
  const disabledButton = autoDistributionValue ? !amountWithoutSpaces : !(amountWithoutSpaces && divisionValue)

  // Render
  return (
    <Wrap>
      <StyledWrap>
        <Field
          component={Switcher}
          name={'autoDistribution'}
          type={'checkbox'}
          label={'Автораспределение'}
          fullWidth
        />
        {!realValuesAutoDistribution &&
        <Field
          component={StaticSearchField}
          name={'division'}
          label={'Организация'}
          loading={customStoreLoading}
          options={divisionsOptions}
          fullWidth
          required
        />}
        {marketsClientBalancesLoading
          ? <Loader size={0.6} />
          : balancesInfo}
        <TwoFields>
          <Field
            component={TextField}
            type={'text'}
            name={'amount'}
            label={'Сумма'}
            parse={normalizeNumber}
            required
            fullWidth
          />
          <Field
            component={StaticSearchField}
            name={'currency'}
            placeholder={' '}
            label={'Валюта'}
            loading={customStoreLoading}
            options={currenciesOptions}
            fullWidth
          />
        </TwoFields>
        {vremennoOff &&
        <Field
          component={Switcher}
          labelPadding={'0 10px'}
          name={'isConversion'}
          type={'checkbox'}
          label={'Конвертация'}
          fullWidth
        />}
        {isConversion &&
        <TwoFields>
          <Field
            component={TextField}
            type={'number'}
            name={'customRate'}
            defaultValue={paymentsConvertData}
            label={'Курс'}
            required
            fullWidth
          />
          <Field
            component={StaticSearchField}
            name={'currencyDebt'}
            label={'Валюта оплаты'}
            placeholder={'Выберите'}
            loading={customStoreLoading}
            options={currenciesOptions}
            required
            fullWidth
          />
        </TwoFields>}
        {realValuesCurrency && realSaleCurrency && realCourse && realValuesAmount &&
        <p>К оплате: <TextStatus bold status={restBalanceStatus}>{numberFormat(restConvert())} {selectValueFrom(prop('currencyDebt', realValues), CURRENCY)}</TextStatus></p>}
        {marketsClientBalancesLoading
          ? <Loader size={0.6} />
          : restBalancesInfo}
        <Field
          component={TextField}
          type={'text'}
          name={'comment'}
          label={'Комментарий'}
          fullWidth
        />
      </StyledWrap>
      <BottomBar>
        <Button
          type={'button'}
          text={'Подтвердить оплату'}
          fullWidth
          disabled={disabledButton}
          onClick={() => {
            setOpenConfirm(true)
            handlePreSubmit()
          }}
        />
      </BottomBar>
      <ModalConfirm
        open={openConfirm}
        isOpen={setOpenConfirm}
        message={financesClientTransactionPreviewLoading ? <Loader size={0.6} /> : previewList}
        loading={createLoading || financesClientTransactionPreviewLoading}
        type={'submit'}
      />
    </Wrap>
  )
}

const MarketsPaymentsCreateGrid = props => {
  const {
    goBack,
    query,
    customStore,
    marketsClientBalances,
    openConfirm,
    setOpenConfirm,
    paymentsConvert,
    financesClientTransactionCreate,
    financesClientTransactionPreview
  } = props

  // useStates
  const [formValues, setFormValues] = useState({})

  // useEffect
  useEffect(() => {
    const eventFunc = event => {
      if (event.keyCode === 13) {
        event.preventDefault()
      }
    }
    window.addEventListener('keydown', eventFunc)
    return () => {
      window.removeEventListener('keydown', eventFunc)
    }
  })

  // Data
  const customStoreData = prop('data', customStore)
  const marketsClientBalancesData = prop('data', marketsClientBalances)
  const marketsClientBalancesLoading = prop('loading', marketsClientBalances)
  const divisionPrice = prop('divisionPrice', marketsClientBalancesData)
  const totalPrice = prop('totalPrice', marketsClientBalancesData)
  const financesClientTransactionPreviewData = prop('data', financesClientTransactionPreview)
  const financesClientTransactionPreviewLoading = prop('loading', financesClientTransactionPreview)

  // Loading
  const customStoreLoading = prop('loading', customStore)
  const createLoading = prop('loading', financesClientTransactionCreate)

  // paymentsConvert
  const paymentsConvertData = path(['data', 'amount'], paymentsConvert)

  // Redirects
  const marketId = path(['match', 'params', 'id'], props)
  const contractNumber = prop('contractId', query)
  const fromAndroid = prop('fromAndroid', query) && window.AndroidMaps

  // Submit
  const amountWithoutSpaces = numberWithoutSpaces(prop('amount', formValues))
  const defaultData = {
    amount: amountWithoutSpaces,
    comment: prop('comment', formValues),
    currency: +(path(['currency', 'value'], formValues) || prop('currency', formValues)),
    customRate: prop('customRate', formValues),
    division: !prop('autoDistribution', formValues) ? prop('division', formValues) : null,
    currencyDebt: +(path(['currencyDebt', 'value'], formValues)),
    contractNumber: contractNumber,
    rateType: 2, // По умолчанию кастомная валюта
    market: marketId,
    autoDistribution: prop('autoDistribution', formValues)
  }

  const handleMainSubmit = () => {
    financesClientTransactionCreate.onSubmit(defaultData)
    fromAndroid && window.AndroidMaps.closeWebView(true)
  }
  const handlePreSubmit = () => {
    financesClientTransactionPreview.onSubmit(defaultData)
  }

  // InitialValues
  const currencies = prop('currencies', customStoreData)
  const initialValues = {
    division: path(['0', 'division', 'id'], divisionPrice),
    currency: `${path(['0', 'id'], currencies)}`,
    autoDistribution: true
  }
  const currencyValue = prop('currency', formValues)
  const currencyNameValue = currencies && prop('name', find(propEq('id', +currencyValue), currencies))

  // Preview list
  const previewList =
    <ul>
      {financesClientTransactionPreviewData && mapIndexed((item, index) => {
        const divisionName = prop('divisionName', item)
        const amount = prop('amount', item)
        return (
          <li style={{lineHeight: '23px'}} key={index}>
            <span>{divisionName + ': '}</span><TextStatus bold status={'active'}>{numberFormat(amount, currencyNameValue)}</TextStatus>
          </li>
        )
      }, financesClientTransactionPreviewData)}
    </ul>

  // MainContent
  const mainContent =
    <>
      {contractNumber &&
      <HeadComment>
        Договор №<span>{contractNumber}</span>
      </HeadComment>}
      <Form
        onSubmit={handleMainSubmit}
        initialValues={initialValues}
        getValues={setFormValues}
        keepDirtyOnReinitialize
      >
        <FormFields
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
          divisionPrice={divisionPrice}
          totalPrice={totalPrice}
          customStoreLoading={customStoreLoading}
          marketsClientBalancesLoading={marketsClientBalancesLoading}
          paymentsConvertData={paymentsConvertData}
          handlePreSubmit={handlePreSubmit}
          createLoading={createLoading}
          customStoreData={customStoreData}
          previewList={previewList}
          financesClientTransactionPreviewLoading={financesClientTransactionPreviewLoading}
          amountWithoutSpaces={amountWithoutSpaces}
          formValues={formValues}
          currencyNameValue={currencyNameValue}
        />
      </Form>
    </>

  // Render
  return (
    <>
      <CreateBar
        title={'Оплата'}
        goExit={() => {
          goBack(marketId)
          fromAndroid && window.AndroidMaps.closeWebView(false)
        }}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
    </>
  )
}

export default MarketsPaymentsCreateGrid
