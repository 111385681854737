import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {BasicBar} from 'components/Navigation/TopBar/BasicBar'
import {prop} from 'ramda'
import {ChevronLeft as BackIcon} from 'react-feather'
import Button from 'components/Navigation/components/Button'
import mapIndexed from 'helpers/mapIndexed'

const Wrap = styled(BasicBar)`
  height: 60px;
  padding: 0 15px 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`
const LeftBar = styled('button')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: ${({theme}) => theme.palette.black};
  svg:first-child{
    margin-left: -5px;
    color: ${({theme}) => theme.palette.black};
  }
`
const RightBar = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: 15px;
  }
`
const MiddleBar = styled('h3')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 220px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`

const DetailBar = (props) => {
  const {
    goBack,
    buttons,
    title
  } = props

  return (
    <Wrap>
      <LeftBar
        type={'button'}
        onClick={goBack}
      >
        <BackIcon size={24} />
      </LeftBar>
      <MiddleBar>
        {title}
      </MiddleBar>
      <RightBar>
        {buttons && mapIndexed((item, index) => {
          const icon = prop('icon', item)
          const onClick = prop('onClick', item)

          return (
            <Button
              key={index}
              title={icon}
              onClick={onClick}
            />
          )
        }, buttons)}
      </RightBar>
    </Wrap>
  )
}

DetailBar.propTypes = {
  goBack: PropTypes.func,
  title: PropTypes.string,
  buttons: PropTypes.array
}

export default DetailBar
