import React, {useState} from 'react'
import {Filter as FilterIcon} from 'react-feather'
import {isEmpty, path, prop} from 'ramda'
import mapIndexed from 'helpers/mapIndexed'
import NoResults from 'components/NoData/NoResults'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import {marketsTransactionsListSerializer} from 'containers/Clients/actions/serializers/marketsSerializer'
import Filter from 'containers/Clients/Grid/Balances/Grid/MarketsBalancesFilters'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import * as CONST from 'constants/constants'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import BalancesItem from 'containers/Clients/components/BalancesItem/BalancesItem'
import CardDescription from 'components/Cards/CardDescription'
import ClientBalanceFormat from 'containers/Clients/Grid/Balances/Grid/ClientBalanceFormat'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import Tab from '@material-ui/core/Tab'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Grid/Tile/TileItem'
import moment from 'moment'
import Button from 'components/Forms/Button'
import Loader from 'components/Loader'
import Perms from 'components/Perms/Perms'
import ModalConfirm from 'components/Modal/ModalConfirm'
import {PAYMENT_TYPE} from 'constants/backendConstant'

const TabsBar = styled('div')`
  position: fixed;
  background-color: ${({theme}) => theme.nav.primary.background};
  top: 60px;
  height: 44px;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #e4e4e6;
`
const StyledTabs = styled(Tabs)`
  color: ${({theme}) => theme.text.primary};
  .MuiTabs-indicator{
    height: 28px;
    background: ${({theme}) => theme.palette.white};
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.04), 0 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 6.93px;
    transform: translateY(-50%);
    bottom: -2px;
  }
  .MuiTabs-scroller.MuiTabs-fixed{
    padding: 6px 16px;
  }
  .MuiTab-root{
    text-transform: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: ${({theme}) => theme.palette.black};
    min-height: unset;
    height: 28px;
    padding: 5px;
    z-index: 100;
    &:not(:first-child){
      border-left: 1px solid #8e8e9347;
    }
    &.Mui-selected {
      border-left: none !important;
    }
    &.Mui-selected + * {
      border-left: none !important;
    }
  }
  .MuiTabs-flexContainer{
    background: rgba(118, 118, 128, 0.12);
    border-radius: 9px;
    padding: 2px;
  }
  .MuiTabs-root{
    min-height: 44px !important;
  }
  .MuiTouchRipple-root{
    display: none;
  }
`
const Content = styled('div')`
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 92px;
`
const Inner = styled('div')`
  padding: 0 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const Wrap = styled('div')`
  padding: 14px 0;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`
const Div = styled('div')`
  position: fixed;
  padding: 15px 16px;
  border-bottom: 1px solid #e0e4e8;
  top: 104px;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${({theme}) => theme.nav.primary.background};
`
const ButtonWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 7px 0 0;
  margin-bottom: 0 !important;
`
const buttonStyles = {
  height: '40px',
  width: '100%'
}
const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const MarketsBalancesGrid = (props) => {
  const {
    goBack,
    goBackStatistics,
    goOrdersDetail,
    goReturnsDetail,
    searchObj,
    financesClientTransactionList,
    marketsTotalStatistics,
    config,
    filterActions,
    divisionsList,
    handleSetBalancesId,
    handleDeleteBalance,
    openDelete,
    setOpenDelete
  } = props

  // Location
  const marketId = path(['match', 'params', 'id'], props)
  const fromAndroid = typeof window.AndroidMaps !== 'undefined'
  const isNotes = prop('notes', searchObj)
  const isNotesValue = isNotes === 'true' ? 2 : 0
  const userId = path(['match', 'params', 'userId'], props)
  const divisionId = path(['match', 'params', 'divisionId'], props)

  // UseState
  const [value, setValue] = useState(isNotesValue)
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // Data
  const financesClientTransactionListData = prop('data', financesClientTransactionList)
  const currenciesData = path(['data', 'currencies'], marketsTotalStatistics)
  const marketsTotalStatisticsLoading = prop('loading', marketsTotalStatistics)
  const configData = prop('data', config)

  // Constants
  const currentCurrencyId = prop('pRIMARYCURRENCYID', configData)
  const currentCurrency = prop('pRIMARYCURRENCY', configData)

  // Tabs configs
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }

  // Period
  const beginDate = prop('beginDate', searchObj)
  const endDate = prop('endDate', searchObj)
  const period =
    <CardDescription
      onClick={() => setFilterIsOpen(true)}
      styles={{
        '& > span': {
          fontSize: '14px',
          lineHeight: '17px'
        }
      }}
      it={'Период'}
      is={
        (beginDate && endDate)
          ? moment(beginDate).format('DD MMM YYYY') + ' - ' + moment(endDate).format('DD MMM YYYY')
          : 'За весь период'
      }
    />

  // Main list
  const mainList = financesClientTransactionListData &&
    mapIndexed((item) => {
      const id = prop('id', item)
      const createdDate = prop('createdDate', item)
      const order = prop('order', item)
      const type = prop('type', item)
      const orderReturn = prop('orderReturn', item)
      const orderNumberId = prop('orderNumberId', item)
      const amount = prop('amount', item)
      const currency = path(['currency', 'name'], item)
      const paymentType = prop('paymentType', item)
      const paymentTypeText = PAYMENT_TYPE[paymentType] || 'Не указано'
      const division = path(['division', 'name'], item)
      const clientConfirmation = prop('clientConfirmation', item)
      const statusText = (status) => {
        switch (status) {
        case CONST.CONFIRMED: return <p>Подтверждён</p>
        case CONST.REJECTED: return <p>Отменён</p>
        case CONST.REQUESTED: return <p>В ожидании</p>
        case CONST.AUTO: return <p>Автоматически подтверждён системой</p>
        default: return null
        }
      }
      const user = prop('user', item)
      const userFirstName = path(['user', 'firstName'], item)
      const userSecondName = path(['user', 'secondName'], item)
      const userName = user ? (userFirstName + ' ' + userSecondName) : 'Система'
      const marketName = path(['market', 'name'], item)
      const marketId = path(['market', 'id'], item)
      const comment = prop('comment', item)
      const currencyId = path(['currency', 'id'], item)
      const internal = prop('internal', item)
      const realCurrency = path(['realCurrency', 'name'], item)
      const realAmount = prop('realAmount', item)
      const realAmountStatus = realAmount >= 0 ? 'active' : 'inactive'
      const customRate = prop('customRate', item)
      const amountInternal = amount / internal
      const customRateTrue = customRate ? +customRate : Math.round(amountInternal)
      const isReturn = (+type === 3) || (+type === 8)
      const isOrder = +type === 4 || (+type === 7) || (+type === 10) || (+type === 11)

      return (
        <BalancesItem
          key={id}
          setQuery={handleSetBalancesId}
          id={id}
          searchObj={searchObj}
          date={createdDate}
          amount={amount}
          currency={currency}
          name={
            <ClientBalanceFormat
              item={item}
              type={type}
              order={order}
              orderReturn={orderReturn}
              orderNumberId={orderNumberId}
            />
          }
        >
          {marketName && <CardDescription it={'Клиент'} is={marketName} />}
          {division && <CardDescription it={'Организация'} is={division} />}
          <CardDescription it={'Тип оплаты'} is={paymentTypeText} />
          {(type === CONST.CLIENT_BALANCE_PAYMENT) && <CardDescription it={'Статус'} is={statusText(clientConfirmation)} />}
          <CardDescription it={'Кто'} is={userName} />
          {comment && <CardDescription it={'Комментарий'} is={comment} />}
          {(currencyId !== currentCurrencyId) &&
          <CardDescription
            it={'Курс'}
            is={<TextStatus>{customRateTrue} ({numberFormat(internal, currentCurrency)})</TextStatus>}
          />}
          {(currency !== realCurrency) &&
          <CardDescription
            it={'Получено в другой валюте'}
            is={<TextStatus status={realAmountStatus}>{numberFormat(realAmount, realCurrency)}</TextStatus>}
          />}
          {isReturn &&
          <ButtonWrap>
            <Button
              text={'Открыть возврат'}
              styles={buttonStyles}
              onClick={() => goReturnsDetail(marketId, orderReturn)}
            />
          </ButtonWrap>}
          {isOrder &&
          <ButtonWrap>
            <Button
              text={'Открыть заказ'}
              styles={buttonStyles}
              onClick={() => goOrdersDetail(marketId, order)}
            />
          </ButtonWrap>}
          <Perms perms={['delete_clienttransaction']}>
            <ButtonWrap>
              <Button
                text={'Удалить'}
                type={'button'}
                background={'#e66767'}
                styles={buttonStyles}
                onClick={() => setOpenDelete(true)}
              />
            </ButtonWrap>
          </Perms>
        </BalancesItem>
      )
    }, financesClientTransactionListData)

  // Nav buttons
  const navButtons = [
    {
      icon: <FilterIcon />,
      onClick: () => setFilterIsOpen(true)
    }
  ]

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
      withOutButton
    >
      <Filter
        setFilterIsOpen={setFilterIsOpen}
        divisionsList={divisionsList}
        userId={userId}
        searchObj={searchObj}
        divisionId={divisionId}
        {...filterActions}
      />
    </FiltersBar>

  // Content
  const tabContentOne =
    <Wrap>
      <Inner>
        <TilesList
          title={'Баланс на начало периода'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const beginBalance = prop('beginBalance', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={beginBalance < 0 ? 'inactive' : 'active'}>{numberFormat(beginBalance)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
      <Inner>
        <TilesList
          title={'Продажи'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const order = prop('order', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={order < 0 ? 'inactive' : 'active'}>{numberFormat(order)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
      <Inner>
        <TilesList
          title={'Возвраты'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const orderReturn = prop('orderReturn', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={orderReturn < 0 ? 'inactive' : 'active'}>{numberFormat(orderReturn)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
      <Inner>
        <TilesList
          title={'Оплаты'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const payment = prop('payment', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={payment < 0 ? 'inactive' : 'active'}>{numberFormat(payment)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
      <Inner>
        <TilesList
          title={'Прочие приходы'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const noneTypePlus = prop('noneTypePlus', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={noneTypePlus < 0 ? 'inactive' : 'active'}>{numberFormat(noneTypePlus)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
      <Inner>
        <TilesList
          title={'Прочие расходы'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const noneTypeMinus = prop('noneTypeMinus', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={noneTypeMinus < 0 ? 'inactive' : 'active'}>{numberFormat(noneTypeMinus)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
      <Inner>
        <TilesList
          title={'Баланс на конец периода'}
          background={'#f8f8f8'}
        >
          {currenciesData && mapIndexed((item, index) => {
            const name = prop('name', item)
            const endBalance = prop('endBalance', item)

            return (
              <TileItem
                key={index}
                height={'auto'}
              >
                <CardDescription it={name} is={<TextStatus bold status={endBalance < 0 ? 'inactive' : 'active'}>{numberFormat(endBalance)}</TextStatus>} />
              </TileItem>
            )
          }, currenciesData)}
        </TilesList>
      </Inner>
    </Wrap>

  const detailContent =
    <InfiniteScroll
      api={API.FINANCES_CLIENT_TRANSACTION_LIST}
      actionType={actionTypes.FINANCES_CLIENT_TRANSACTION_LIST}
      emptyData={<li><NoResults /></li>}
      params={marketsTransactionsListSerializer(searchObj, props)}
      useWindow={false}
      list={mainList || []}
    />

  // MainContent
  const mainContent =
    <>
      <TabsBar>
        <StyledTabs
          onChange={handleChange}
          value={value}
          variant={'fullWidth'}
          component={'div'}
        >
          <Tab label={'Описание'} {...a11yProps(0)} />
          <Tab label={'Итоги'} {...a11yProps(1)} />
        </StyledTabs>
      </TabsBar>
      <Div>
        {period}
      </Div>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Content value={value} index={0}>
          {detailContent}
        </Content>
        <Content value={value} index={1}>
          {marketsTotalStatisticsLoading ? <Loader styles={{marginTop: '20px'}} /> : (currenciesData && !isEmpty(currenciesData)) ? tabContentOne : <NoResults />}
        </Content>
      </SwipeableViews>
    </>

  // Modals
  const modals =
    <>
      <ModalConfirm
        open={openDelete}
        isOpen={setOpenDelete}
        type={'delete'}
        onClick={() => {
          handleDeleteBalance()
        }}
      />
    </>

  // Render
  return (
    <>
      <DetailBar
        title={'Баланс клиента'}
        goBack={() => fromAndroid ? window.AndroidMaps.closeWebView(false) : (marketId ? goBack(marketId) : goBackStatistics())}
        buttons={navButtons}
      />
      <Container
        background={'#fff'}
      >
        {mainContent}
      </Container>
      {filters}
      {modals}
    </>
  )
}

export default MarketsBalancesGrid
