export const theme = {
  palette: {
    primary: '#2c3a50',
    black: '#000',
    white: '#fff',
    blue: '#6770e6',
    red: '#e66767',
    green: '#38d06c',
    orange: '#fbb432',
    grey: '#8f8e94',
    lightGrey: '#c1c1c1',
    greyOpacity: 'rgba(140, 140, 140, 0.1)'
  },
  background: {
    primary: '#f3f3f3',
    secondary: '#fff',
    mask: 'rgba(0, 0, 0, 0.4)'
  },
  text: {
    primary: '#6c7079',
    secondary: '#8c8c8c'
  },
  button: {
    primary: {
      background: '#6770e6',
      color: '#fff'
    },
    secondary: {
      background: '#dedede',
      color: '#000'
    },
    disabled: {
      background: '#dedede',
      color: '#c1c1c1'
    }
  },
  nav: {
    primary: {
      background: '#f8f8f8', // #f8f8f8f5
      borderBottom: '1px solid #e4e4e6'
    }
  },
  input: {
    primary: {
      background: 'rgba(142, 142, 147, 0.12)',
      backgroundIndicator: '#808085',
      color: '#000',
      fontSize: '17px',
      lineHeight: '22px',
      letterSpacing: '-0.02em',
      padding: '7px 10px',
      border: 'none',
      borderRadius: '10px',
      borderFocus: '1px solid #6770e6',
      width: '100%',
      '&::placeholder': {
        color: '#8e8e93'
      }
    },
    secondary: {
      background: 'rgba(142, 142, 147, 0.12)',
      backgroundIndicator: '#808085',
      color: '#000',
      fontSize: '17px',
      lineHeight: '22px',
      letterSpacing: '-0.02em',
      padding: '7px 10px',
      border: 'none',
      borderRadius: '10px',
      borderFocus: '1px solid #6770e6',
      width: '100%',
      '&::placeholder': {
        color: '#8e8e93'
      }
    },
    disabled: {
      color: '#d6d6d6'
    }
  },
  border: {
    primary: '1px solid #e4e4e6'
  },
  fontSize: {
    primary: '15px'
  },
  borderRadius: {
    primary: '10px'
  },
  boxShadow: {
    primary: '0 0 1px rgba(0, 0, 0, 0.3)'
  },
  transition: {
    primary: 'all .2s ease-out'
  },
  skeleton: {
    primary: '#eee'
  }
}

export const palette = [
  '#2196f3',
  '#ffeb3b',
  '#f44336',
  '#4caf50',
  '#9c27b0',
  '#ffc107',
  '#03a9f4',
  '#00bcd4',
  '#ff9800',
  '#673ab7',
  '#009688',
  '#e91e63',
  '#607d8b',
  '#cddc39',
  '#8bc34a',
  '#ff5722',
  '#9e9e9e'
]
