import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <path d="M10 5.58321C8.59053 5.58321 7.44385 6.72992 7.44385 8.13938C7.44385 9.54883 8.59053 10.6955 10 10.6955C11.4095 10.6955 12.5562 9.54883 12.5562 8.13938C12.5562 6.72992 11.4095 5.58321 10 5.58321Z" />
      <path d="M3.60072 9.80038V19.1276C3.60072 19.6077 3.24068 20 2.80036 20C2.36004 20 2 19.6077 2 19.1276V9.80038H3.60072Z" />
      <path d="M17.4002 4.20027H14.7999V9.99966C14.7999 11.6543 13.4535 12.9995 11.8001 12.9995H8.00098V13.1999C8.00098 13.9716 8.62849 14.6003 9.40131 14.6003H17.4014C18.1731 14.6003 18.8017 13.9728 18.8017 13.1999V5.60061C18.8006 4.82779 18.1731 4.20027 17.4002 4.20027Z" />
      <path d="M11.8015 1H2.80183C2.35917 1 2.00146 1.35887 2.00146 1.80036V10.5996C2.00146 11.0411 2.35917 11.4 2.80183 11.4H11.8015C12.5743 11.4 13.2018 10.7737 13.2018 9.99966V2.40034C13.2006 1.62752 12.5743 1 11.8015 1Z" />
    </SvgIcon>

  )
}
