import {compose, mapProps, withHandlers} from 'recompose'
import {connect} from 'react-redux'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFetchList, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import AcceptCashClientTransactionCreateGrid from './Grid/AcceptCashClientTransactionCreateGrid'
import {parseParams} from 'helpers/url'
import {path, prop} from 'ramda'
import numberFormat from 'helpers/numberFormat'
import {
  acceptClientTransactionAction,
  transactionConvertAction,
  updateClientTransactionAction
} from '../../actions/financesAction'

export const DEFAULT_PICK_PARAMS = [
  'openUpdateDialog'
]

export default compose(
  connect((state, props) => {
    const query = parseParams(path(['location', 'search'], props))
    const acceptCashClientTransactionItem = prop('acceptCashClientTransactionItem', state)
    const customStore = prop('customStore', state)
    const config = prop('config', state)
    return {
      query,
      acceptCashClientTransactionItem,
      customStore,
      config
    }
  }),
  withCreate({
    stateName: [STATES.REQUEST_FUNDS_CREATE],
    action: acceptClientTransactionAction,
    key: [STATES.REQUEST_FUNDS_CREATE],
    redirectUrl: ROUTES.REQUEST_FUNDS_URL,
    withCurrentParams: true,
    successMessage: 'Успех'
  }),
  // Update
  withCreate({
    stateName: STATES.FINANCES_CLIENT_TRANSACTION_CREATE,
    action: updateClientTransactionAction,
    key: 'updateTransactionDialog',
    withCurrentParams: true,
    redirectUrl: ROUTES.FINANCES_ACCEPT_CASH_DETAIL_URL,
    propFromQuery: 'openUpdateDialog',
    successMessage: 'Успех'
  }),
  withFetchList({
    stateName: STATES.PAYMENTS_CONVERT,
    action: transactionConvertAction,
    key: [STATES.PAYMENTS_CONVERT]
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.FINANCES_ACCEPT_CASH_DETAIL_URL,
    withCurrentParams: true
  }),
  withHandlers({

  }),
  withFilter({
    fields: ['search']
  }),
  mapProps(({
    acceptCashClientTransactionItem,
    goDetail,
    updateTransactionDialog,
    ...props
  }) => {
    const detailData = prop('state', props.location)
    const currency = path(['realCurrency', 'id'], detailData)
    // update dialog\
    const updateDialog = {
      open: prop('openUpdateDialog', props.query),
      loading: prop('loading', acceptCashClientTransactionItem) || updateTransactionDialog.loading,
      initialValues: {
        user: path(['user', 'id'], detailData),
        paymentType: prop('paymentType', detailData),
        amount: numberFormat(prop('amount', detailData)),
        currency: currency && `${currency}`,
        division: path(['division', 'id'], detailData),
        comment: prop('comment', detailData)
      },
      onSubmit: updateTransactionDialog.onSubmit
    }

    return {
      updateDialog,
      ...props
    }
  })
)(AcceptCashClientTransactionCreateGrid)
