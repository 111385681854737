import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import styled, {withTheme} from 'styled-components'
import ReactSelect from 'react-select'
import {find, isEmpty, prop, propEq} from 'ramda'
import selectStyles from '../selectStyles'
import Loader from '../../../Loader'
import {isOpenToTop} from '../../../../constants/styles'
import Portal from '@material-ui/core/Portal'
import Fab from '../../Fab'
import {X as CloseIcon} from 'react-feather'

const SelectWrapper = styled('div')`
  width: ${(props) => props.fullWidth ? '100%' : '220px'};
  min-width: ${(props) => props.fullWidth ? '100%' : '220px'};
  max-width: 100%;
  color: ${({theme}) => theme.text.primary};
  font-size: 16px;
`

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  border-radius: ${({open}) => open && '12px 12px 0px 0px'};
  padding: ${({open}) => open && '16px 16px 4px 16px'};
`

const Close = styled('div')`
    position: fixed;
    top: ${({focus}) => focus ? '21px' : 'calc(24vh + 9px)'};
    right: 21px;
    transition: top .35s;
`

const LoadWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  & > div {
    margin: 0;
  }
`

const BgMask = styled('div')`
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.09);
  z-index: 2001;
  box-sizing: border-box;
`

const Label = styled('label')`
  text-transform: ${({open}) => open ? 'none' : 'uppercase'};
  font-weight: ${({open}) => open && '500'};
  margin-bottom: ${({open}) => open ? '20px' : '8px'};
  font-size: ${({open}) => open ? '17px' : '12px'};
  color: ${({theme, open}) => open ? '#000' : theme.text.secondary}
  :after{
    display: ${({required}) => required ? 'inline-block' : 'none'};
    content: '\u00A0*';
    color: ${({theme}) => theme.palette.red}
  }
`

const StaticSearchField = props => {
  const {
    theme,
    value,
    options = [],
    label,
    onChange,
    type,
    loading,
    onMenuOpen,
    onMenuClose,
    placeholder,
    fullWidth,
    input,
    meta,
    required,
    ...rest
  } = props

  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [focus, setFocus] = React.useState(false)
  // Fix for bug (select go into document)
  // eslint-disable-next-line no-unused-vars
  const [element, setElement] = React.useState(null)
  const loadingMessage = ({inputValue}) => {
    if (isEmpty(options)) {
      return <LoadWrapper><Loader size={0.5} /></LoadWrapper>
    }
  }
  const noOptionsMessage = ({inputValue}) => {
    if (inputValue) return `Не найдено "${inputValue}"`
    return 'Не найдено'
  }

  const handleMenuOpen = () => {
    document.body.style.overflow = 'hidden'
    setMenuIsOpen(true)
    if (typeof onMenuOpen === 'function') {
      onMenuOpen()
    }
  }

  const handleMenuClose = () => {
    setMenuIsOpen(false)
    setFocus(false)
    document.body.style = null
    if (typeof onMenuClose === 'function') {
      onMenuClose()
    }
  }
  const container = React.useRef()
  const selectedValue = find(propEq('value', prop('value', input)), options)
  useEffect(() => {
    setElement(container)
  }, [])
  return (
    <div ref={container}>
      {menuIsOpen &&
      <Wrap open={false}>
        <Label required={required} open={false}>{label}</Label>
        <SelectWrapper
          fullWidth={fullWidth}
        >
          <ReactSelect
            value={selectedValue || input.value.value}
            options={options}
            classNamePrefix={'select'}
            styles={selectStyles(theme, {
              menuIsOpen: false, focus:false
            })}
            name={'fakeNameForSelect'}
            placeholder={placeholder || 'Выберите из списка'}
          />
        </SelectWrapper>
      </Wrap>}
      <Portal container={menuIsOpen ? document.body : (container.current)}>
        <Wrap style={menuIsOpen ? isOpenToTop(focus) : null} open={menuIsOpen}>
          <Label required={required} open={menuIsOpen}>{label}</Label>
          {menuIsOpen &&
          <Close focus={focus}>
            <Fab size="small" background={'rgba(118, 118, 128, 0.12)'} onClick={handleMenuClose}>
              <CloseIcon size={18} color={'rgba(60, 60, 67, 0.6)'} />
            </Fab>
          </Close>}
          <SelectWrapper
            fullWidth={fullWidth}
          >
            <ReactSelect
              value={selectedValue || input.value.value}
              options={options}
              classNamePrefix={'select'}
              styles={selectStyles(theme, {
                menuIsOpen, focus
              })}
              onFocus={() => menuIsOpen && setFocus(true)}
              onBlur={() => setFocus(false)}
              defaultValue={input.value.value}
              closeMenuOnSelect={!rest.isMulti}
              menuIsOpen={menuIsOpen}
              openMenuOnClick={type === 'select'}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              isLoading={loading}
              loadingMessage={loadingMessage}
              noOptionsMessage={noOptionsMessage}
              name={input.name}
              onChange={option => {
                if (typeof onChange === 'function') {
                  onChange(prop('value', option))
                }
                if (typeof input.onChange === 'function') {
                  input.onChange(prop('value', option))
                }
              }}
              placeholder={placeholder || 'Выберите из списка'}
              {...rest}
            />
          </SelectWrapper>
        </Wrap>
      </Portal>
      {menuIsOpen &&
      <Portal>
        <BgMask onClick={handleMenuClose} />
      </Portal>}
    </div>
  )
}

StaticSearchField.propTypes = {
  theme: PropTypes.object,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.oneOf([
    'select',
    'autocomplete'
  ]),
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func
}

StaticSearchField.defaultProps = {
  type: 'select',
  placeholder: 'Выберите из списка',
  isClearable: true
}

export default withTheme(StaticSearchField)
