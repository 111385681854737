import React from 'react'
import Container from 'components/Container'
import {path, prop} from 'ramda'
import Button from 'components/Forms/Button'
import Form from 'components/Forms/Form'
import styled from 'styled-components'
import BottomBar from 'components/Navigation/BottomBar'
import {Field} from 'react-final-form'
import TextField from 'components/Forms/TextField'
import {CreateBar} from 'components/Navigation/TopBar'
import ModalConfirm from 'components/Modal/ModalConfirm'
import CardDescription from 'components/Cards/CardDescription'
import Label from 'components/Forms/Label'
import Radio from 'components/Forms/Radio'
import mapIndexed from 'helpers/mapIndexed'
import normalizePhone from 'helpers/normalizers/normalizePhone'
import {onlyNumsAndSymbol} from 'helpers/onlyNumsAndSymbol'

const Wrap = styled('div')`
  padding: 14px 16px 64px;
`
const Description = styled('ul')`
  margin-bottom: 15px;
  & > * {
    padding: 12px 0;
    min-height: 44px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
  }
  & > *:not(:last-child){
    border-bottom: ${({theme}) => theme.border.primary};
  }
`
const StyledWrap = styled('div')`
  & > *:not(:last-child){
    margin-bottom: 10px !important;
  }
`

// Forms
const FormFields = (props) => {
  const {
    setConfirmModalOpen,
    modals,
    marketsPhones
  } = props

  const values = prop('values', props)
  const radioNumberValue = prop('radioNumber', values)
  const customNumberValue = prop('customNumber', values)
  const isCustomNumber = radioNumberValue === 'customNumber'

  const eq = (radioNumberValue && !isCustomNumber) || (isCustomNumber && customNumberValue)

  return (
    <>
      <StyledWrap>
        <div>
          <Label
            styles={{marginBottom: '0'}}
          >
            Выберите номер телефона
          </Label>
          {marketsPhones && mapIndexed((item) => {
            const id = prop('id', item)
            const phone = prop('phone', item)

            return (
              <Field
                key={id}
                component={Radio}
                type={'radio'}
                name={'radioNumber'}
                label={phone}
                value={phone}
              />
            )
          }, marketsPhones)}
          <Field
            component={Radio}
            type={'radio'}
            name={'radioNumber'}
            label={'Другой номер'}
            value={'customNumber'}
          />
        </div>
        {isCustomNumber &&
        <Field
          component={TextField}
          parse={normalizePhone}
          defaultValue={'+998'}
          name={'customNumber'}
          placeholder={'Введите номер'}
        />}
      </StyledWrap>
      <BottomBar>
        <Button
          type={'button'}
          text={'Отправить'}
          onClick={() => setConfirmModalOpen(true)}
          fullWidth
          disabled={!eq}
        />
      </BottomBar>
      {modals}
    </>
  )
}

const MarketsTelegramCreateGrid = props => {
  const {
    goBack,
    confirmModalOpen,
    setConfirmModalOpen,
    match,
    marketsItem,
    marketsTelegramsCreateCreate
  } = props

  // Global constants
  const marketId = path(['params', 'id'], match)

  // Data
  const marketsItemData = prop('data', marketsItem)
  const marketsTelegramsCreateCreateLoading = prop('loading', marketsTelegramsCreateCreate)

  // Main data
  const marketsName = prop('name', marketsItemData)
  const marketType = path(['marketType', 'name'], marketsItemData)
  const marketsAddress = prop('address', marketsItemData)
  const marketsPhones = prop('phones', marketsItemData)
  const marketsInitialPhone = path(['0', 'phone'], marketsPhones)

  // InitialValues
  const initialValuesObj = {
    radioNumber: marketsInitialPhone
  }

  // Content
  const mainContent =
    <>
      <Description>
        <li><CardDescription it={'Клиент'} is={marketsName} /></li>
        <li><CardDescription it={'Категория'} is={marketType} /></li>
        <li><CardDescription it={'Адрес'} is={marketsAddress} /></li>
      </Description>
    </>

  const modals =
    <ModalConfirm
      open={confirmModalOpen}
      isOpen={setConfirmModalOpen}
      message={'Для продолжения подтвердите отправку'}
      loading={marketsTelegramsCreateCreateLoading}
      type={'submit'}
    />

  // Submits
  const handleMainSubmit = (props) => {
    const radioNumber = prop('radioNumber', props)
    const customNumber = prop('customNumber', props)
    const phoneNumber = radioNumber === 'customNumber' ? onlyNumsAndSymbol(customNumber) : radioNumber
    marketsTelegramsCreateCreate.onSubmit({
      phoneNumber,
      marketId
    })
  }

  // Render
  return (
    <>
      <CreateBar
        title={'Ссылка на клиент'}
        goExit={() => goBack(marketId)}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {mainContent}

          <Form
            onSubmit={handleMainSubmit}
            initialValues={initialValuesObj}
          >
            <FormFields
              setConfirmModalOpen={setConfirmModalOpen}
              modals={modals}
              marketsPhones={marketsPhones}
            />
          </Form>
        </Wrap>
      </Container>
    </>
  )
}

export default MarketsTelegramCreateGrid
