import React from 'react'
import SvgIcon from 'components/SvgIcons'
import {prop} from 'ramda'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <g clipPath="url(#clip0)">
        <path d="M17.9193 16.4927L17.0262 5.2384C16.9708 4.51328 16.3573 3.94551 15.6303 3.94551H13.866V6.55946C13.866 6.88317 13.604 7.1452 13.2803 7.1452C12.957 7.1452 12.6946 6.88317 12.6946 6.55946V3.94551H7.30585V6.55946C7.30585 6.88317 7.04342 7.1452 6.7201 7.1452C6.39639 7.1452 6.13436 6.88317 6.13436 6.55946V3.94551H4.37014C3.64307 3.94551 3.02959 4.51328 2.97417 5.23684L2.08073 16.4942C2.01163 17.3959 2.3244 18.2932 2.93862 18.9563C3.55288 19.6193 4.42366 20 5.32764 20H14.6728C15.5768 20 16.4476 19.6193 17.0618 18.9562C17.676 18.2932 17.9888 17.3958 17.9193 16.4927Z" />
        <path d="M10.0001 0C7.86842 0 6.13428 1.73453 6.13428 3.86621V3.94547H7.30576V3.86621C7.30576 2.38039 8.51432 1.17145 10.0001 1.17145C11.486 1.17145 12.6945 2.38039 12.6945 3.86621V3.94547H13.866V3.86621C13.8659 1.73453 12.1318 0 10.0001 0Z" />
        <path d="M12.8233 10.1767C12.1 9.45 11.1067 9 10 9C7.79 9 6 10.79 6 13C6 15.21 7.79 17 10 17C11.98 17 13.6167 15.5567 13.9367 13.6667H12.9233C12.62 15.0033 11.4267 16 10 16C8.34333 16 7 14.6567 7 13C7 11.3433 8.34333 10 10 10C10.83 10 11.57 10.3433 12.1133 10.8867L10.6667 12.3333H14V9L12.8233 10.1767Z" fill={prop('secondColor', props) || '#E66767'} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </SvgIcon>

  )
}
