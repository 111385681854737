import React, {useEffect, useState} from 'react'
import {path, prop, propOr} from 'ramda'
import {MainBar} from 'components/Navigation/TopBar'
import Container from 'components/Container/Container'
import MainMenu from 'components/Navigation/components/MainMenu'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from 'containers/Clients/Grid/MarketsFilters'
import {Navigation2} from 'react-feather'
import SecondMenu from '../../../components/Navigation/components/SecondMenu'
import DebounceSearch from '../../../components/Forms/DebounceSearch/DebounceSearch'
import {parseParams} from 'helpers/url'
import styled from 'styled-components'
import {storageData} from 'helpers/storage'
import Button from '../../../components/Forms/Button'
import Perms from '../../../components/Perms/Perms'
import MarketsPlansList from 'containers/Clients/Grid/MarketsPlansList'
import {isAndroid} from 'constants/constants'
import Fab from 'components/Forms/Fab'
import numberFormat from 'helpers/numberFormat'
import {TextStatus} from 'components/Status/TextStatus'
import Loader from 'components/Loader'
import RefreshIcon from 'components/SvgIcons/CustomIcons/RefreshIcon'

const ContentBuildRoute = styled('div')`
  display: flex;
  align-items: center;
  font-size: 17px;
  svg {
    fill: #ffffff;
  }
`
const ToBottom = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F8F8F8;
  border-top: 1px solid #E4E4E6;
  padding: 18px;
`
const Title = styled('span')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 42px;
  & > *:not(:last-child){
    margin-right: 5px;
  }
`
const SecondTitle = styled('span')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  i{
    font-size: unset;
  }
  & > *:not(:last-child){
    margin-right: 5px;
  }
`
const Count = styled('i')`
  color: ${({theme}) => theme.text.secondary};
  font-size: 26px;
`

const MarketsGrid = props => {
  const {
    customStore,
    location,
    history,
    goDetail,
    filterActions,
    marketsAgentPlans,
    goAppleMaps,
    goGoogleNav,
    goYandexNav,
    goYandexMaps,
    goReturnsCreate,
    goVisitsCreate,
    goOrdersCreate,
    goPaymentsCreate,
    handleSetRefresh,
    goOrdersDetail,
    gpsSender,
    notificationGetNotViewed
  } = props

  // UseStates
  const [openFilter, setOpenFilter] = useState(false)
  const [isOpenMapsMenu, setIsOpenMapsMenu] = useState(false)
  const [selectedMarket, setSelectedMarket] = useState({})
  const [planCount, setPlanCount] = useState(null)

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const refreshQuery = +prop('refresh', searchObj) || 0
  const handleGoDetail = marketId => goDetail(marketId)
  const date = prop('planDate', searchObj) || storageData('marketsPlanDate').getValue()

  // Data
  const customStoreUserId = path(['data', 'id'], customStore)
  const customStoreLoading = prop('loading', customStore)
  const marketsAgentPlansLoading = prop('loading', marketsAgentPlans)
  const marketsAgentPlansData = propOr([], 'data', marketsAgentPlans)
  const marketsAgentPlansLength = prop('length', marketsAgentPlansData)
  const gpsSenderData = prop('data', gpsSender)
  const gpsSenderLoading = prop('loading', gpsSender)
  const notificationGetNotViewedData = prop('data', notificationGetNotViewed)
  const notificationGetNotViewedLoading = prop('loading', notificationGetNotViewed)
  const notificationsLoading = gpsSenderLoading || notificationGetNotViewedLoading
  const notificationsData = gpsSenderData || notificationGetNotViewedData

  // Const
  const titleInner =
    <>
      <span>Доставка</span>
      {marketsAgentPlansLoading || customStoreLoading
        ? <Count><Loader size={0.7} /></Count>
        : <Count><TextStatus status={'primary'}>{numberFormat(marketsAgentPlansLength)}</TextStatus></Count>}
    </>
  const title =
    <Title>
      {titleInner}
    </Title>
  const secondTitle =
    <SecondTitle>
      {titleInner}
    </SecondTitle>

  const yourPlan = prop('yourPlan', notificationsData)

  // useEffect
  useEffect(() => {
    if ((yourPlan && planCount) && yourPlan !== planCount) {
      handleSetRefresh(refreshQuery + 1)
      setPlanCount(yourPlan)
    } else {
      setPlanCount(yourPlan)
    }
    // eslint-disable-next-line
  }, [notificationsLoading])

  // Search form
  const searchForm = <DebounceSearch filterActions={filterActions} />

  // SecondHeight
  const secondHeight = 100

  // BuildRoute
  const contentBuildRoute = (
    <ContentBuildRoute><span style={{marginRight: '5px'}}>Построить маршрут</span> <Navigation2 size={25} /></ContentBuildRoute>
  )
  const buildRoute = (
    <Perms perms={['can_see_new_mobile_navigator']}>
      <ToBottom>
        <Button
          text={contentBuildRoute}
          type={'button'}
          onClick={() => (typeof window.Android !== 'undefined') && window.Android.openTrackingMap(customStoreUserId, 'page_size=1000&fromAndroid=True')}
          fullWidth
        />
      </ToBottom>
    </Perms>
  )

  // IsVisibleBuildRoute
  const isVisibleBuildRoute = isAndroid && customStoreUserId

  // MapsMenuList
  const nativeMaps =
    ((navigator.platform.indexOf('iPhone') !== -1) ||
      (navigator.platform.indexOf('iPad') !== -1) ||
      (navigator.platform.indexOf('iPod') !== -1))
      ? {title: 'Apple Maps', onClick: () => goAppleMaps(prop('lat', selectedMarket), prop('lon', selectedMarket))}
      : {title: 'Google Карты', onClick: () => goGoogleNav(prop('lat', selectedMarket), prop('lon', selectedMarket))}

  const mapsMenuArr = [
    {
      title: 'Яндекс.Навигатор',
      onClick: () => goYandexNav(prop('lat', selectedMarket), prop('lon', selectedMarket))
    },
    {
      title: 'Яндекс.Карта',
      onClick: () => goYandexMaps(prop('lat', selectedMarket), prop('lon', selectedMarket))
    },
    nativeMaps
  ]

  // MapMenu
  const mapsMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с картами'}
      menusArr={mapsMenuArr}
      isOpen={isOpenMapsMenu}
      setIsOpen={setIsOpenMapsMenu}
    />

  // FiltersBar
  const filtersBar =
    <FiltersBar
      isOpen={openFilter}
      setOpen={setOpenFilter}
      initialValues={{...filterActions.initialValues, planHide: ''}}
    >
      <Filter
        setOpenFilter={setOpenFilter}
        {...filterActions}
      />
    </FiltersBar>

  // RefreshBtn
  const secondMenu =
    <Fab
      text={'Дополнительно'}
      withfixed={'true'}
      onClick={() => handleSetRefresh(refreshQuery + 1)}
      styles={isVisibleBuildRoute ? {bottom: '100px !important'} : {}}
    >
      <RefreshIcon fontSize={'20px'} />
    </Fab>

  // MainContent
  const mainContent =
    <>
      <MarketsPlansList
        marketsAgentPlans={marketsAgentPlans}
        date={date}
        history={history}
        search={search}
        handleGoDetail={handleGoDetail}
        setSelectedMarket={setSelectedMarket}
        setIsOpenMapsMenu={setIsOpenMapsMenu}
        goReturnsCreate={goReturnsCreate}
        goVisitsCreate={goVisitsCreate}
        goOrdersCreate={goOrdersCreate}
        goPaymentsCreate={goPaymentsCreate}
        customStoreLoading={customStoreLoading}
        goOrdersDetail={goOrdersDetail}
      />
    </>

  // Render
  return (
    <>
      <MainBar
        title={title}
        secondTitle={secondTitle}
        secondHeight={secondHeight}
        secondContent={searchForm}
        firstBtn={<MainMenu />}
        lastBtn={filtersBar}
      />
      <Container
        background={'#fff'}
        secondHeight={secondHeight}
        styles={isVisibleBuildRoute ? {paddingBottom: '87px'} : {}}
      >
        {mainContent}
      </Container>
      {mapsMenu}
      {!marketsAgentPlansLoading && secondMenu}
      {isVisibleBuildRoute && buildRoute}
    </>
  )
}

export default MarketsGrid
