import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Check as CheckIcon} from 'react-feather'
import PhotoWithStatus from 'components/PhotoWithStatus'
import {TextStatus} from 'components/Status/TextStatus'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
`
const RightBar = styled(Bar)`
  width: calc(100% - 57px);
`
const styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '22px'
  }
}
const StatusWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${({theme}) => theme.transition.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const ValidAmount = styled('span')`
   color: ${({status, theme}) => status && theme.palette.red};
   font-weight: ${({status}) => status && '500'};
   * {
     color: ${({status, theme}) => status && theme.palette.red};
     font-weight: ${({status}) => status && '500'};
   }
`
const CheckAll = styled('div')`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30px;
  height: 30px;
  & > div{
    background: ${({disabled}) => disabled ? '#ebebec' : 'transparent'};
    box-shadow: inset 0 0 3px 0 #ccc;
    border-radius: 5px;
    width: 24px;
    height: 24px;
    content: "";
    display: flex;
    justify-content: center;
    align-content: center;
  }
  svg{
    color: ${({theme, disabled}) => disabled ? theme.palette.lightGrey : theme.palette.blue};
    margin-top: 4px;
    stroke-width: 4px;
  }
`

const ProductsItem = props => {
  const {
    onClick,
    name,
    amount,
    defectAmount,
    isSelected,
    imageSrc,
    validAmount
  } = props

  return (
    <>
      <TileItem
        onClick={onClick}
        styles={styles}
        height={'auto'}
      >
        <LeftBar>
          <PhotoWithStatus
            imgSrc={imageSrc}
            status={'none'}
            type={'product'}
          />
        </LeftBar>
        <RightBar>
          <TextStatus bold>{name}</TextStatus>
          {defectAmount &&
            <span>Брак: {defectAmount}</span>}
          {amount &&
            <ValidAmount status={validAmount <= 3}>{amount}</ValidAmount>}
        </RightBar>
        <StatusWrap>
          <CheckAll>
            <div>
              {isSelected ? <CheckIcon size={16} /> : null}
            </div>
          </CheckAll>
        </StatusWrap>
      </TileItem>
    </>
  )
}

ProductsItem.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.any,
  amount: PropTypes.any,
  imageSrc: PropTypes.string,
  validAmount: PropTypes.number
}

export default ProductsItem
