import React from 'react'
import TileItem from 'components/Grid/Tile/TileItem'
import styled from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import {ChevronRight as ArrowIcon} from 'react-feather'
import PropTypes from 'prop-types'
import {prop} from 'ramda'
import {trimString} from 'helpers/trimString'

const Bar = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 65%;
  & > *:not(:last-child){
    margin-bottom: 4px;
  }
`
const LeftBar = styled(Bar)`
  h3{
    font-size: 14px;
  }
`
const RightBar = styled(Bar)`
  text-align: right;
`
const Price = styled('h4')`
  color: ${({theme, status}) =>
    status === 'active'
      ? theme.palette.green
      : status === 'inactive'
        ? theme.palette.red
        : theme.palette.black
};
`
const Date = styled('span')`

`

const ArrowWrap = styled('div')`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%) ${({eq}) => eq ? 'rotate(270deg)' : 'rotate(90deg)'};
  transition: ${({theme}) => theme.transition.primary};
  svg{
    max-width: 20px;
    max-height: 20px;
  }
`
const Arrow = styled(ArrowIcon)`
  color: #c7c7cc;
`
const Detail = styled('li')`
  padding: 12px 16px;
  background: ${({background}) => background && background};
  display: ${({eq}) => eq ? 'block' : 'none'};
  transition: ${({theme}) => theme.transition.primary};
  border-bottom: ${({theme}) => theme.border.primary};
  & > div > div > *:not(:last-child){
    margin-bottom: 5px;
  }
`

const FinancesTransactionsItem = props => {
  const {
    onClick,
    description,
    amount,
    setQuery,
    id,
    searchObj,
    currency,
    children,
    date,
    idName
  } = props

  const idQuery = prop('transId', searchObj)
  const eq = (+idQuery === +id)

  const handleClick = () => {
    !eq ? setQuery(id) : setQuery('')
    onClick && onClick()
  }
  const status = +amount > 0 ? 'active' : 'inactive'
  const background = eq ? '#f7f7f8' : ''

  const styles = {
    '& > div': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '22px'
    },
    background: background
  }
  return (
    <>
      <TileItem
        onClick={handleClick}
        styles={styles}
      >
        <LeftBar>
          {eq ? idName : trimString(description)}
        </LeftBar>
        <RightBar>
          <Price
            status={status}
          >{numberFormat(amount, currency)}
          </Price>
          <Date>
            {date}
          </Date>
        </RightBar>
        <ArrowWrap
          eq={eq}
        >
          <Arrow />
        </ArrowWrap>
      </TileItem>
      <Detail
        eq={eq}
        background={background}
      >
        {children}
      </Detail>
    </>
  )
}

FinancesTransactionsItem.propTypes = {
  onClick: PropTypes.func,
  setQuery: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  searchObj: PropTypes.object
}

export default FinancesTransactionsItem
