import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'

export const manufactureProductsListSerializer = (props) => {
  const id = path(['match', 'params', 'id'], props)

  return {
    manufacture: id
  }
}

export const batchesAddProductsSerializer = (params, props) => {
  const {
    location
  } = props

  const search = prop('search', location)
  const batchesId = path(['match', 'params', 'id'], props)
  const searchObj = parseParams(search)
  const searchQuery = prop('productsSearch', searchObj)

  const defaultParams = {
    search: searchQuery,
    shipment: batchesId,
    type: 'return'
  }
  return {
    ...defaultParams
  }
}

export const batchesAddMaterialsSerializer = (params, props) => {
  const {
    location
  } = props

  const search = prop('search', location)
  const searchObj = parseParams(search)
  const searchQuery = prop('productsSearch', searchObj)
  const batchesId = path(['match', 'params', 'id'], props)

  const defaultParams = {
    search: searchQuery,
    shipment: batchesId,
    type: 'writeoff'
  }
  return {
    ...defaultParams
  }
}
