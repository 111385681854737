import {compose, withHandlers} from 'recompose'
import FinancesTransactionsGrid from './Grid'
import {withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {appendParamsToUrl} from '../../../../helpers/url'
import {path, prop} from 'ramda'

export default compose(
  connect((state, props) => {
    const financesTransactionsList = prop('financesTransactionsList', state)
    return {
      financesTransactionsList
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.FINANCES_URL,
    withCurrentParams: false
  }),
  withHandlers({
    handleSetTransactionId: props => (transId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({transId}, path(['history', 'location', 'search'], props))
      })
    }
  })
)(FinancesTransactionsGrid)
