import {compose, lifecycle} from 'recompose'
import StocksSelectionsCreateGrid from 'containers/Stocks/containers/Selections/Grid/Create/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {withCreate, withFetchList, withFilter} from 'hocs'
import * as STATES from 'constants/states'
import {
  stocksSalesOrdersAction,
  stocksSelectionsCreateAction,
  stocksSelectionsCreateClearAction
} from 'containers/Stocks/actions/stocksAction'

export default compose(
  withFetchList({
    key: STATES.MARKETS_SALES_ORDERS_LIST,
    stateName: STATES.MARKETS_SALES_ORDERS_LIST,
    action: stocksSalesOrdersAction
  }),
  withCreate({
    stateName: [STATES.STOCKS_SELECTIONS_CREATE],
    action: stocksSelectionsCreateAction,
    key: [STATES.STOCKS_SELECTIONS_CREATE],
    withoutMessage: true
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.STOCKS_SELECTIONS_URL,
    withCurrentParams: false
  }),
  withRedirect({
    key: 'goNext',
    redirectUrl: ROUTES.STOCKS_SELECTIONS_DETAIL_URL,
    withCurrentParams: false
  }),
  withFilter({
    fields: ['ordersSearch']
  }),
  lifecycle({
    componentWillMount () {
      this.props.dispatch(stocksSelectionsCreateClearAction())
    }
  })
)(StocksSelectionsCreateGrid)
