import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'

export const stocksListAction = (params) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.STOCKS_LIST, params)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.STOCKS_LIST,
      payload
    })
  }
}
