import React, {useState} from 'react'
import Container from 'components/Container/Container'
import {CreateBar} from 'components/Navigation/TopBar'
import Form from 'components/Forms/Form'
import * as ROUTES from 'constants/routes'
import {Field} from 'react-final-form'
import styled from 'styled-components'
import Button from 'components/Forms/Button'
import ModalConfirm from 'components/Modal/ModalConfirm'
import BottomBar from 'components/Navigation/BottomBar'
import StaticSearchField from 'components/Forms/SearchField/StaticSearchField/StaticSearchField'
import {find, map, path, prop, propEq} from 'ramda'
import TextField from 'components/Forms/TextField'
import Switcher from 'components/Forms/Switcher'
import moment from 'moment'
import mapIndexed from 'helpers/mapIndexed'
import UniversalSearchField from 'components/Forms/SearchField/UniversalSearchField'
import * as API from 'constants/api'
import {FieldArray} from 'react-final-form-arrays'
import UsersSearchField from 'components/Forms/SearchField/Users/UsersSearchField'
import ExpenseSearchField from 'components/Forms/SearchField/Expense/ExpenseSearchField'
import SupplySearchField from 'components/Forms/SearchField/Supply/SupplySearchField'

const FormWrap = styled('div')`
  padding: 14px 16px 64px;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`
const SwitchersWrap = styled('div')`
  padding-top: 10px;
  border-top: ${({theme}) => theme.border.primary};
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`
const FieldsArrWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`
const FormInner = styled('div')`
  padding: 10px 0;
  border-top: ${({theme}) => theme.border.primary};
  border-bottom: ${({theme}) => theme.border.primary};
`
const FieldsWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child){
    margin-bottom: 10px;
  }
`

const FormFields = props => {
  const {
    modal,
    isDebit,
    customStoreLoading,
    customStoreData,
    financesCashierListData,
    financesCashierListLoading,
    financesDebitCategoryData,
    financesDebitCategoryLoading,
    financesCreditCategoryData,
    financesCreditCategoryLoading,
    values
  } = props

  // Data
  const divisionsListData = prop('divisions', customStoreData)

  // Values
  const cashboxValue = prop('cashbox', values)
  const debitCategoryValue = prop('incomeCategory', values)
  const creditCategoryValue = prop('expenseCategory', values)
  const selectedCashbox = financesCashierListData && find(propEq('id', +cashboxValue), financesCashierListData)
  const selectedCashboxCurrency = path(['currency', 'name'], selectedCashbox)
  const selectedDebitCategory = financesDebitCategoryData && find(propEq('id', +debitCategoryValue), financesDebitCategoryData)
  const selectedCreditCategory = financesCreditCategoryData && find(propEq('id', +creditCategoryValue), financesCreditCategoryData)

  const selectedDebitCategoryOptions = prop('options', selectedDebitCategory)
  const selectedCreditCategoryOptions = prop('options', selectedCreditCategory)

  // Options
  const divisionsOptions = divisionsListData && map((item) => {
    const value = `${prop('id', item)}`
    const label = prop('name', item)
    return {value, label}
  }, divisionsListData)
  const cashboxOptions = financesCashierListData && map((item) => {
    const value = `${prop('id', item)}`
    const label = prop('name', item)
    return {value, label}
  }, financesCashierListData)
  const debitCategoryOptions = financesDebitCategoryData && map((item) => {
    const value = `${prop('id', item)}`
    const label = prop('name', item)
    return {value, label}
  }, financesDebitCategoryData)
  const creditCategoryOptions = financesCreditCategoryData && map((item) => {
    const value = `${prop('id', item)}`
    const label = prop('name', item)
    return {value, label}
  }, financesCreditCategoryData)

  // Render Form
  return (
    <FormWrap>
      <Field
        component={StaticSearchField}
        label={'Кассы'}
        name={'cashbox'}
        loading={financesCashierListLoading}
        options={cashboxOptions}
        fullWidth
        required
      />
      <Field
        component={StaticSearchField}
        label={'Организация'}
        name={'division'}
        loading={customStoreLoading}
        options={divisionsOptions}
        fullWidth
        required
      />
      {isDebit &&
      <Field
        component={StaticSearchField}
        label={'Категория прихода'}
        name={'incomeCategory'}
        loading={financesDebitCategoryLoading}
        options={debitCategoryOptions}
        fullWidth
        required
      />}
      {(isDebit && debitCategoryValue && selectedDebitCategoryOptions) &&
      mapIndexed((item, index) => {
        const title = prop('title', item)
        const keyName = prop('keyName', item)
        const id = prop('id', item)
        const api =
          +id === 8
            ? API.CLIENTS_LIST
            : +id === 6
              ? API.PROVIDERS_LIST
              : false

        if (api) {
          return (
            <Field
              component={UniversalSearchField}
              label={title}
              name={keyName}
              api={api}
              parent={{page_size: '1000' + id}}
              key={index}
              fullWidth
              required
            />
          )
        } else {
          return false
        }
      }, selectedDebitCategoryOptions)}
      {!isDebit &&
      <Field
        component={StaticSearchField}
        label={'Категория расхода'}
        name={'expenseCategory'}
        loading={financesCreditCategoryLoading}
        options={creditCategoryOptions}
        fullWidth
        required
      />}
      {(!isDebit && creditCategoryValue && selectedCreditCategoryOptions) &&
      mapIndexed((item, index) => {
        const title = prop('title', item)
        const keyName = prop('keyName', item)
        const id = prop('id', item)
        const api =
          +id === 8
            ? API.CLIENTS_LIST
            : +id === 6
              ? API.PROVIDERS_LIST
              : +id === 1
                ? API.MARKETS_MINI_LIST
                : +id === 2
                  ? API.SUPPLY_EXPENSE_LIST
                  : +id === 4
                    ? API.SUPPLY_LIST
                    : false
        const params =
          +id === 4
            ? {exclude_cancelled: true}
            : {}

        if (+id === 2) {
          return (
            <Field
              component={ExpenseSearchField}
              label={title}
              name={keyName}
              api={api}
              params={params}
              parent={{page_size: '1000' + id}}
              key={index}
              fullWidth
              required
            />
          )
        } else if (+id === 4) {
          return (
            <Field
              component={SupplySearchField}
              label={title}
              name={keyName}
              api={api}
              params={params}
              parent={{page_size: '1000' + id}}
              key={index}
              fullWidth
              required
            />
          )
        } else if (api) {
          return (
            <Field
              component={UniversalSearchField}
              label={title}
              name={keyName}
              api={api}
              params={params}
              parent={{page_size: '1000' + id}}
              key={index}
              fullWidth
              required
            />
          )
        } else {
          return false
        }
      }, selectedCreditCategoryOptions)}
      {(+debitCategoryValue === 4 || +creditCategoryValue === 4) &&
      <FormInner>
        <FieldArray
          name={'transactionChild'}
        >
          {({fields}) => (
            <FieldsArrWrap>
              {fields.map((name, index) => {
                return (
                  <FieldsWrap key={index}>
                    <Field
                      label={isDebit ? 'Название прихода' : 'Название расхода'}
                      name={`${name}.name`}
                      component={TextField}
                      type={'text'}
                    />
                    <Field
                      label={selectedCashboxCurrency ? ('Сумма - ' + selectedCashboxCurrency) : 'Сумма'}
                      name={`${name}.amount`}
                      component={TextField}
                      type={'number'}
                    />
                    {fields.length !== 1 &&
                    <Button
                      text={'Удалить поле'}
                      type={'button'}
                      styles={{height: 'auto', background: '#e66767 !important'}}
                      onClick={() => fields.remove(index)}
                    />}
                  </FieldsWrap>
                )
              })}
              <Button
                text={'Добавить поле'}
                type={'button'}
                styles={{height: 'auto'}}
                onClick={() => fields.push({})}
              />
            </FieldsArrWrap>
          )}
        </FieldArray>
      </FormInner>}
      {(+debitCategoryValue === 2 || +creditCategoryValue === 2) &&
      <FormInner>
        <FieldArray
          name={'staff'}
        >
          {({fields}) => (
            <FieldsArrWrap>
              {fields.map((name, index) => {
                return (
                  <FieldsWrap key={index}>
                    <Field
                      component={UsersSearchField}
                      api={API.USERS_LIST_FOR_SELECT}
                      params={{page_size: '1000'}}
                      name={`${name}.staff`}
                      label={'Сотрудник'}
                      fullWidth
                    />
                    <Field
                      label={selectedCashboxCurrency ? ('Сумма - ' + selectedCashboxCurrency) : 'Сумма'}
                      name={`${name}.amount`}
                      component={TextField}
                      type={'number'}
                    />
                    {fields.length !== 1 &&
                    <Button
                      text={'Удалить поле'}
                      type={'button'}
                      styles={{height: 'auto', background: '#e66767 !important'}}
                      onClick={() => fields.remove(index)}
                    />}
                  </FieldsWrap>
                )
              })}
              <Button
                text={'Добавить поле'}
                type={'button'}
                styles={{height: 'auto'}}
                onClick={() => fields.push({})}
              />
            </FieldsArrWrap>
          )}
        </FieldArray>
      </FormInner>}
      {!(+debitCategoryValue === 4 || +creditCategoryValue === 4 || +debitCategoryValue === 2 || +creditCategoryValue === 2) &&
      <Field
        component={TextField}
        name={'amount'}
        type={'text'}
        label={selectedCashboxCurrency ? ('Сумма - ' + selectedCashboxCurrency) : 'Сумма'}
        fullWidth
        required
      />}
      <Field
        component={TextField}
        name={'date'}
        type={'date'}
        label={'Дата создания'}
        fullWidth
        required
      />
      <Field
        component={TextField}
        name={'comment'}
        type={'text'}
        label={'Комментарий'}
        fullWidth
      />
      <SwitchersWrap>
        <Field
          component={Switcher}
          name={'isVerified'}
          type={'checkbox'}
          label={isDebit ? 'Подтверждённый приход' : 'Подтверждённый расход'}
        />
        {!isDebit &&
        <Field
          component={Switcher}
          name={'internalTransfer'}
          type={'checkbox'}
          label={'Внутренний расход'}
        />}
      </SwitchersWrap>
      {modal}
    </FormWrap>
  )
}

const FinancesCreditsDebitsCreateGrid = props => {
  const {
    goBack,
    customStore,
    financesTransactionsCreate,
    cashiersList,
    financesDebitCategory,
    financesCreditCategory
  } = props

  // Data
  const financesCashierListData = prop('results', cashiersList)
  const financesCashierListLoading = prop('loading', cashiersList)
  const financesTransactionsCreateLoading = prop('loading', financesTransactionsCreate)
  const customStoreData = prop('data', customStore)
  const customStoreLoading = prop('loading', customStore)
  const financesDebitCategoryData = prop('results', financesDebitCategory)
  const financesDebitCategoryLoading = prop('loading', financesDebitCategory)
  const financesCreditCategoryData = prop('results', financesCreditCategory)
  const financesCreditCategoryLoading = prop('loading', financesCreditCategory)

  // useStates
  const [openModal, setOpenModal] = useState(false)

  // Const
  const isDebit = window.location.pathname === ROUTES.FINANCES_DEBITS_CREATE_PATH
  const today = moment().format('YYYY-MM-DD')

  // InitialValues
  const initialValues = {
    date: today,
    transactionChild: [{}],
    staff: [{}]
  }

  // MainSubmit
  const handleSubmit = values => {
    const incomeCategory = prop('incomeCategory', values)
    const expenseCategory = prop('expenseCategory', values)
    const debugOne = +incomeCategory === 2 || +expenseCategory === 2 || +incomeCategory === 4 || +expenseCategory === 4
    const cashbox = prop('cashbox', values)
    const division = prop('division', values)
    const amount = prop('amount', values) || 0
    const date = prop('date', values) + ' 00:00'
    const comment = prop('comment', values)
    const isVerified = prop('isVerified', values)
    const internalTransfer = !isDebit ? prop('internalTransfer', values) : undefined
    const client = !debugOne ? prop('client', values) : undefined
    const provider = !debugOne ? prop('provider', values) : undefined
    const creditCategory = !debugOne ? prop('creditCategory', values) : undefined
    const staff = (+incomeCategory === 2 || +expenseCategory === 2) ? prop('staff', values) : []
    const transactionChild = (+incomeCategory === 4 || +expenseCategory === 4) ? prop('transactionChild', values) : []

    const defaultData = {
      cashbox,
      division,
      amount: isDebit ? amount : (amount * -1),
      comment,
      date,
      is_verified: isVerified,
      income_category: incomeCategory,
      expense_category: expenseCategory,
      internal_transfer: internalTransfer,
      rate_type: 0,
      client,
      provider,
      staff,
      credit_category: creditCategory,
      transaction_child: transactionChild
    }

    financesTransactionsCreate.onSubmit(defaultData)
  }

  // Modals
  const modal =
    <ModalConfirm
      type={'submit'}
      open={openModal}
      isOpen={setOpenModal}
      loading={financesTransactionsCreateLoading}
      message={'Подтвердите создание'}
    />

  // Render
  return (
    <>
      <CreateBar
        title={isDebit ? 'Создание приходов' : 'Создание расходов'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          haveMutators
        >
          <FormFields
            modal={modal}
            isDebit={isDebit}
            customStoreData={customStoreData}
            customStoreLoading={customStoreLoading}
            financesCashierListData={financesCashierListData}
            financesCashierListLoading={financesCashierListLoading}
            financesDebitCategoryData={financesDebitCategoryData}
            financesDebitCategoryLoading={financesDebitCategoryLoading}
            financesCreditCategoryData={financesCreditCategoryData}
            financesCreditCategoryLoading={financesCreditCategoryLoading}
          />
        </Form>
        <BottomBar>
          <Button
            text={'Сохранить'}
            type={'button'}
            onClick={() => setOpenModal(true)}
            fullWidth
          />
        </BottomBar>
      </Container>
    </>
  )
}

export default FinancesCreditsDebitsCreateGrid
