import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'

export const userUpdatePasswordAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.USER_UPDATE_PASSWORD, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.USER_UPDATE_PASSWORD,
      payload
    })
  }
}
