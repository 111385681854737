import {compose} from 'recompose'
import MarketsReturnsDetailGrid from './Grid'
import {withFetchList, withRedirect} from 'hocs'
import * as STATES from 'constants/states'
import {marketsReturnsItemAction} from 'containers/Clients/actions/marketsAction'
import * as ROUTES from 'constants/routes'

export default compose(
  withFetchList({
    stateName: STATES.MARKETS_RETURNS_ITEM,
    action: marketsReturnsItemAction,
    key: [STATES.MARKETS_RETURNS_ITEM]
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_RETURNS_URL,
    withCurrentParams: true
  })
)(MarketsReturnsDetailGrid)
