import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default (props) => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <g clipPath="url(#clip0)">
        <path d="M11.5217 0C11.1617 0 10.8695 0.291914 10.8695 0.652187V8.47828C10.8695 8.83856 11.1617 9.13047 11.5217 9.13047H19.3478C19.7078 9.13047 20 8.83856 20 8.47828C19.9999 3.80332 16.1965 0 11.5217 0Z" />
        <path d="M16.3043 10.8696H9.13043V3.69568C9.13043 3.3354 8.83828 3.04349 8.47824 3.04349C3.80348 3.04349 0 6.84681 0 11.5218C0 16.1967 3.80348 20 8.47824 20C13.153 20 16.9565 16.1967 16.9565 11.5218C16.9565 11.1615 16.6644 10.8696 16.3043 10.8696Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </SvgIcon>

  )
}
