import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const BottomNav = styled('div')`
  position: ${({fixed}) => fixed && 'fixed'};
  bottom: ${({fixed}) => fixed && 0};
  left: ${({fixed}) => fixed && 0};
  right: ${({fixed}) => fixed && 0};
  width: 100%;
  z-index: 100;
  border: none;
  transition: position .3s;
  ${({styles}) => styles};
  button{
    border-top: 1px solid #fff;
    height: 50px;
    border-radius: 0 !important;
  }
`

const BottomBar = (props) => {
  const {
    children,
    fixed = true,
    styles,
    absolute
  } = props

  return (
    <BottomNav
      fixed={fixed}
      styles={styles}
      absolute={absolute}
    >
      {children}
    </BottomNav>
  )
}

BottomBar.propTypes = {
  children: PropTypes.any,
  fixed: PropTypes.bool,
  styles: PropTypes.object,
  absolute: PropTypes.bool
}

export default BottomBar
