import React, {useState} from 'react'
import {Filter as FilterIcon} from 'react-feather'
import {isEmpty, path, prop} from 'ramda'
import Filter from 'containers/Clients/Grid/StatisticsByProducts/Grid/MarketsStatisticsByProductsFilters'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import {DetailBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import styled from 'styled-components'
import moment from 'moment'
import {parseParams} from 'helpers/url'
import mapIndexed from 'helpers/mapIndexed'
import ChartBar from 'components/Charts/ChartBar'
import ProgressBar from 'components/Charts/ProgressBar'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import NoResults from 'components/NoData/NoResults'
import Loader from 'components/Loader'
import CardDescription from 'components/Cards/CardDescription'

const Wrap = styled('div')`
  padding: 56px 16px 0;
  display: flex;
  flex-flow: column nowrap;
`
const ChartOptions = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.35px;
  color: #000;
  padding: 15px 0;
  &:not(:first-child){
    border-top: 1px solid #e0e4e8;
  }
`
const Option = styled('span')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px 0 16px;
  text-transform: lowercase;
  &:before{
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${props => props.color || '#ccc'};
    border-radius: 50%;
  }
`
const Title = styled('span')`
  display: block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.016em;
  color: ${({theme}) => theme.text.secondary};
`
const ProductsSearch = styled('div')`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: #f8f8f8;
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  transition: height .3s, opacity .3s, padding .3s;
`

const MarketsStatisticsByProductsGrid = (props) => {
  const {
    goBack,
    goBackStats,
    filterActions,
    location,
    marketsStatisticsByProducts
  } = props

  // useStates
  const [filterIsOpen, setFilterIsOpen] = useState(false)

  // Constants
  const firstDayOfMonth = moment().format('YYYY-MM-01')
  const lastDay = moment().daysInMonth()
  const lastDayOfMonth = moment().format('YYYY-MM-' + lastDay)

  // Data
  const marketsStatisticsByProductsData = prop('results', marketsStatisticsByProducts)
  const marketsStatisticsByProductsLoading = prop('loading', marketsStatisticsByProducts)
  const maxProgress = path(['0', 'maxPlanOrSales'], marketsStatisticsByProductsData)

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const marketId = path(['match', 'params', 'id'], props)
  const userId = path(['match', 'params', 'userId'], props)
  const searchQuery = prop('search', searchObj)

  // InitialValues
  filterActions.initialValues = {
    beginDate: prop('beginDate', searchObj) || firstDayOfMonth,
    endDate: prop('endDate', searchObj) || lastDayOfMonth,
    search: prop('search', searchObj)
  }

  // Nav buttons
  const navButtons = [
    {
      icon: <FilterIcon />,
      onClick: () => setFilterIsOpen(true)
    }
  ]

  // Filters
  const filters =
    <FiltersBar
      isOpen={filterIsOpen}
      setOpen={setFilterIsOpen}
      withOutButton
    >
      <Filter
        setFilterIsOpen={setFilterIsOpen}
        {...filterActions}
      />
    </FiltersBar>

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch filterActions={filterActions} placeholder={'Название'} />
    </ProductsSearch>

  // Chart
  const chartList = marketsStatisticsByProductsData && mapIndexed((item) => {
    const id = prop('id', item)
    const productName = prop('name', item)
    const salesCount = prop('salesCount', item)
    const salesPlan = prop('salesPlan', item)
    const returnsCount = prop('orderReturnsCount', item)
    const actualSalesCount = prop('actualSalesCount', item)

    const salesProgress =
      <ProgressBar
        maxProgress={+maxProgress}
        progressText={+salesPlan}
        color={'#fbb432'}
      />

    return (
      <ChartBar key={id}>
        <Title>{productName}</Title>
        <ProgressBar
          maxProgress={+maxProgress}
          progressText={+salesCount}
          color={'#69768D'}
        />
        {(+returnsCount > 0) &&
          <>
            <ProgressBar
              maxProgress={+maxProgress}
              progressText={+returnsCount}
              color={'#f83b7f'}
            />
            <ProgressBar
              maxProgress={+maxProgress}
              progressText={+actualSalesCount}
              color={'#6770e6'}
            />
          </>}
        {salesPlan ? salesProgress : null}
      </ChartBar>
    )
  }, marketsStatisticsByProductsData)

  const chart =
    <>
      <ChartOptions>
        <Option color={'#69768d'}>продажи</Option>
        <Option color={'#f83b7f'}>возвраты</Option>
        <Option color={'#6770e6'}>факт. продажи</Option>
        <Option color={'#fbb432'}>план</Option>
      </ChartOptions>
      {chartList}
    </>

  // Content
  const mainContent =
    <>
      {marketsStatisticsByProductsLoading ? <Loader styles={{marginTop: '30px'}} /> : chart}
    </>

  // Period
  const period =
    <ChartOptions
      onClick={() => setFilterIsOpen(true)}
    >
      <CardDescription
        styles={{
          '& > span': {
            fontSize: '14px',
            lineHeight: '17px'
          }
        }}
        it={'Период'}
        is={
          moment(filterActions.initialValues.beginDate).format('DD MMM YYYY') + ' - ' +
          moment(filterActions.initialValues.endDate).format('DD MMM YYYY')
        }
      />
    </ChartOptions>

  // Render
  return (
    <>
      <DetailBar
        title={'Статистика по товарам'}
        goBack={() => userId ? goBackStats() : goBack(marketId)}
        buttons={navButtons}
      />
      <Container
        background={'#fff'}
      >
        <Wrap>
          {marketsStatisticsByProductsLoading && !searchQuery
            ? <Loader styles={{marginTop: '20px'}} />
            : isEmpty(marketsStatisticsByProductsData)
              ? <NoResults text={'Нет данных за этот период'} />
              : <>{period}{mainContent}</>}
          {debounceSearch}
        </Wrap>
      </Container>
      {filters}
    </>
  )
}

export default MarketsStatisticsByProductsGrid
