import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'

export const gprSenderCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.GPS_SENDER, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.GPS_SENDER,
      payload
    })
  }
}

export const getAddressYandexAction = (lat, lon) => {
  const requestData = {
    longitude: lon,
    latitude: lat
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.GET_ADDRESS, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.GET_ADDRESS,
      payload
    })
  }
}
