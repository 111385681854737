import React from 'react'
import styled from 'styled-components'
import TileItem from 'components/Grid/Tile/TileItem'
import numberFormat from 'helpers/numberFormat'
import PropTypes from 'prop-types'
import {isEmpty, prop} from 'ramda'
import {Navigation} from 'react-feather'
import mapIndexed from 'helpers/mapIndexed'
import CheckedTask from 'components/CheckedTask'

const Wrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`
const Title = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  h3{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    color: ${({theme}) => theme.palette.blue};
  }
  h5{
    max-width: 85px;
    max-height: 26px;
    text-align: center;
    background: ${({theme}) => theme.palette.blue};
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.016em;
    border-radius: 34px;
    padding: 4px 23px 4px 9px;
    font-weight: 400;
    position: relative;
    svg{
      color: #cbcef6;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      fill: #cbcef6;
    }
  }
`
const Address = styled('p')`
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({theme}) => theme.text.secondary};
`
const TasksWrap = styled('div')`
  margin-top: 7px;
  background: #f9f9f9;
  border: 1px solid #e6e6e8;
  border-radius: ${({theme}) => theme.borderRadius.primary};
  padding: 0 12px;
`

const MarketsPlanItem = props => {
  const {
    title,
    address,
    onClick,
    planTasks,
    mainOnClick,
    navClick,
    taskClick,
    // marketId,
    // goReturnsCreate,
    // goVisitsCreate,
    // goOrdersCreate,
    // goPaymentsCreate,
    // history,
    // search,
    distance
  } = props

  // Distance
  const formatedDist = Math.round(distance * 10) / 10000
  const distanceText = formatedDist < 100 ? numberFormat(formatedDist, 'км') : <i style={{fontSize: '13px'}}>Далеко</i>

  return (
    <TileItem
      onClick={onClick}
      height={'auto'}
      styles={{'& > div': {border: 'none !important'}}}
    >
      <Wrap>
        <Title>
          <h3 onClick={mainOnClick}>{title}</h3>
          <h5 onClick={navClick}>{distance ? distanceText : 'GPS'}<Navigation size={'12px'} /></h5>
        </Title>
        <Address onClick={mainOnClick}>{address || 'Адрес не указан'}</Address>
        <TasksWrap>
          {(planTasks && !isEmpty(planTasks))
            ? mapIndexed((item, index) => {
              const text = prop('text', item)
              const id = prop('id', item)
              const type = prop('type', item)
              const status = prop('status', item)
              const orderId = prop('orderId', item)
              const currStatus = status === 'done' ? 'active' : status === 'undone' ? 'inactive' : null
              // const currentParams = parseParams(search)
              // const allParams = {...currentParams, ...{taskId: id}}
              // const onClickObj = {
              //   order_return: () => goReturnsCreate(+marketId),
              //   visit: () => goVisitsCreate(+marketId),
              //   order: () => goOrdersCreate(+marketId),
              //   payment: () => goPaymentsCreate(+marketId)
              //   delivery: () => {
              //     history.push({
              //       pathname: sprintf(ROUTES.MARKETS_ORDERS_DETAIL_URL, +marketId, +orderId),
              //       search: paramsToSearch(allParams)
              //     })
              //   },
              //   custom: () => {
              //     history.push({
              //       pathname: sprintf(ROUTES.MARKETS_REPORTS_CREATE_URL, +marketId),
              //       search: paramsToSearch(allParams)
              //     })
              //   }
              // }

              return (
                <CheckedTask
                  type={type}
                  onClick={() => taskClick(id, orderId)}
                  // onClickObj={onClickObj}
                  key={index}
                  text={text}
                  status={currStatus}
                />
              )
            }, planTasks)
            : <p style={{padding: '10px 0'}}>Нет задач по плану</p>}
        </TasksWrap>
      </Wrap>
    </TileItem>
  )
}

MarketsPlanItem.propTypes = {
  planTasks: PropTypes.array,
  distance: PropTypes.number,
  title: PropTypes.any,
  address: PropTypes.any,
  onClick: PropTypes.func
}

export default MarketsPlanItem
