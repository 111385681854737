import React, {useState} from 'react'
import Container from 'components/Container'
import {prop, path} from 'ramda'
import {DetailBar} from 'components/Navigation/TopBar'
import {parseParams} from 'helpers/url'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import * as serializers from 'containers/Clients/actions/serializers/marketsSerializer'
import NoResults from 'components/NoData/NoResults'
import mapIndexed from 'helpers/mapIndexed'
import ReturnsItem from 'containers/Clients/components/ReturnsItem/ReturnsItem'
import * as CONST from 'constants/constants'
import FiltersBar from 'components/Navigation/components/FiltersBar'
import Filter from 'containers/Clients/Grid/Returns/Grid/MarketsReturnsFilters'
import {Filter as FilterIcon} from 'react-feather'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import styled from 'styled-components'

const ProductsSearch = styled('div')`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.nav.primary.background};
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  transition: height .3s, opacity .3s, padding .3s;
`

const MarketsReturnsGrid = (props) => {
  const {
    location,
    match,
    goBack,
    goBackStatistics,
    goDetail,
    filterActions,
    marketsReturnsList
  } = props

  const [openFilter, setOpenFilter] = useState(false)

  // Data
  const marketsSalesOrdersListDataProp = prop('data', marketsReturnsList)
  const marketsReturnsListData = Array.isArray(marketsSalesOrdersListDataProp) ? marketsSalesOrdersListDataProp : []

  // Redirects
  const search = prop('search', location)
  const searchObj = parseParams(search)

  // Redirect
  const marketId = path(['params', 'id'], match)

  // Main List
  const mainList = marketsReturnsListData && mapIndexed((item) => {
    const id = prop('id', item)
    const createdDate = prop('createdDate', item)
    const currency = path(['currency', 'name'], item)
    const totalPrice = prop('totalPrice', item)
    const status = +prop('status', item)
    const statusFunc = () => {
      switch (status) {
      case CONST.ORDER_RETURN_PENDING: return 'pending'
      case CONST.ORDER_RETURN_IN_PROGRESS: return 'pending'
      case CONST.ORDER_RETURN_COMPLETED: return 'active'
      case CONST.ORDER_RETURN_CANCELED: return 'inactive'
      default: return 'pending'
      }
    }
    const market = path(['market', 'id'], item)

    return (
      <ReturnsItem
        key={id}
        orderNumber={id}
        date={createdDate}
        currency={currency}
        totalPrice={totalPrice}
        status={statusFunc()}
        onClick={() => goDetail(market, id)}
      />
    )
  }, marketsReturnsListData)

  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_RETURNS_LIST}
        actionType={actionTypes.MARKETS_RETURNS_LIST}
        emptyData={<li><NoResults /></li>}
        params={serializers.marketsReturnsListSerializer(searchObj, props)}
        style={{paddingTop: '56px'}}
        list={mainList || []}
      />
    </>

  // Nav
  const navButtons = [
    {
      icon: <FilterIcon />,
      onClick: () => setOpenFilter(true)
    }
  ]

  // Filters
  const filters =
    <FiltersBar
      isOpen={openFilter}
      setOpen={setOpenFilter}
      withOutButton
    >
      <Filter
        setFilterIsOpen={setOpenFilter}
        {...filterActions}
      />
    </FiltersBar>

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        query={'returnsSearch'}
        placeholder={'Название'}
      />
    </ProductsSearch>

  // Render
  return (
    <>
      <DetailBar
        goBack={() => marketId ? goBack(marketId) : goBackStatistics()}
        title={'Возвраты'}
        buttons={navButtons}
      />
      <Container
        background={'#fff'}
      >
        {debounceSearch}
        {mainContent}
      </Container>
      {filters}
    </>
  )
}

export default MarketsReturnsGrid
