import {compose} from 'recompose'
import DebtsDetailGrid from './Grid'
import {withRedirect} from 'hocs'
import * as ROUTES from 'constants/routes'
import {connect} from 'react-redux'
import {prop} from 'ramda'

export default compose(
  connect((state) => {
    const config = prop('config', state)
    const posDebtsDetail = prop('posDebtsDetail', state)
    return {
      config,
      posDebtsDetail
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.DEBTS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goCheckDetail',
    redirectUrl: ROUTES.CHECKS_DETAIL_URL,
    withCurrentParams: false
  })
)(DebtsDetailGrid)
