import {compose, mapPropsStream} from 'recompose'
import {connect} from 'react-redux'
import {sendNotification} from './contentNotification'
import {path, prop} from 'ramda'
import {storageData} from '../helpers/storage'
import {filter} from 'rxjs/operators'

const enhance = compose(
  connect((state) => {
    const token = storageData('token').getValue() || path(['auth', 'data', 'token'], state)
    return {
      token
    }
  }),
  mapPropsStream(props$ => {
    props$
      .pipe(
        filter(prop('token'))
      )
      .subscribe(props => {
        return sendNotification(props.dispatch)
      })
    return props$
  })
)

const PushNotification = enhance(() => {
  return null
})

export default PushNotification
