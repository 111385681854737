import {prop} from 'ramda'
import moment from 'moment'

export const plansListSerializers = (params) => {
  const today = moment().format('YYYY-MM-DD')
  const search = prop('search', params)
  const date = prop('date', params) || today

  return {
    search,
    date
  }
}
