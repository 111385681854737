import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {sprintf} from 'sprintf-js'
import {path} from 'ramda'

export const carsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.CARS_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_CREATE,
      payload
    })
  }
}

export const carsDetailAction = (params, props) => {
  const carId = path(['match', 'params', 'id'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.CARS_DETAIL, +carId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_DETAIL,
      payload
    })
  }
}

export const carsOrdersCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.CARS_ORDERS_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_ORDERS_CREATE,
      payload
    })
  }
}

export const carsOrdersAddProductsAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.CARS_ORDERS_ADD_PRODUCTS, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_ORDERS_ADD_PRODUCTS,
      payload
    })
  }
}

export const carsOrdersFinishCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.CARS_ORDERS_FINISH_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_ORDERS_FINISH_CREATE,
      payload
    })
  }
}

export const carsOrdersDetailAction = (params, props) => {
  const orderId = path(['match', 'params', 'orderId'], props)
  const defaultParams = {}

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(sprintf(API.CARS_ORDERS_DETAIL, +orderId), {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_ORDERS_DETAIL,
      payload
    })
  }
}

export const carsOrdersDeleteAction = (values, props) => {
  const orderId = path(['match', 'params', 'orderId'], props)

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.CARS_ORDERS_CANCEL, +orderId), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_ORDERS_CANCEL,
      payload
    })
  }
}

export const carsProductsItemUpdateAction = (values, props) => {
  // console.warn(values, 'values')
  // console.warn(props, 'props')

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.CARS_PRODUCTS_ITEM_UPDATE, 69), values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CARS_PRODUCTS_ITEM_UPDATE,
      payload
    })
  }
}
