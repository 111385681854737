import React from 'react'
import {path, prop} from 'ramda'
import Container from 'components/Container/Container'
import mapIndexed from 'helpers/mapIndexed'
import CardDescription from 'components/Cards/CardDescription'
import * as CONST from 'constants/constants'
import moment from 'moment'
import NoResults from '../../../../../components/NoData/NoResults'
import InfiniteScroll from '../../../../../components/InfiniteScroll/InfiniteScroll'
import * as API from '../../../../../constants/api'
import * as actionTypes from '../../../../../constants/actionTypes'
import * as serializers from '../../../actions/serializers/financesSerializer'
import FinancesTransactionsItem from '../components/FinancesTransactionsItem'
import {parseParams} from '../../../../../helpers/url'
import {DetailBar} from '../../../../../components/Navigation/TopBar'

const FinancesTransactionsGrid = (props) => {
  const {
    financesTransactionsList,
    handleSetTransactionId,
    location,
    goBack
  } = props

  // Redirects
  const searchHistory = prop('search', location)
  const searchObj = parseParams(searchHistory)

  // Data
  const loading = prop('loading', financesTransactionsList)
  const financesTransactionsListData = prop('data', financesTransactionsList) || []
  let cashboxName = 'Список транзакций'

  // MainList
  const contentList = mapIndexed((item) => {
    const id = prop('id', item)
    const order = prop('order', item)
    const firstName = path(['user', 'firstName'], item)
    cashboxName = path(['cashbox', 'name'], item) || null
    const secondName = path(['user', 'secondName'], item)
    const userName = firstName + ' ' + secondName
    const supply = prop('supply', item)
    const supplyExpenseId = prop('supplyExpenseId', item)
    const clientContract = prop('clientContract', item)
    const fromCashboxName = path(['transfer', 'fromCashbox', 'name'], item)
    const transType = prop('type', item)
    const division = path(['division', 'name'], item)
    const comment = prop('comment', item)
    const date = prop('date', item)
    const formatDate = moment(date).format('DD\u00A0MMM\u00A0YYYY\u00A0HH:mm')
    const amount = prop('amount', item)
    const currency = path(['currency', 'name'], item)
    const clientName = path(['client', 'name'], item)
    const type = () => {
      switch (transType) {
      case CONST.FROM_TRANSFER: return prop(CONST.FROM_TRANSFER, CONST.formattedType)
      case CONST.TO_TRANSFER: return prop(CONST.TO_TRANSFER, CONST.formattedType) + ' с ' + fromCashboxName
      case CONST.ORDER: return prop(CONST.ORDER, CONST.formattedType) + ' № ' + order
      case CONST.INCOME: return prop(CONST.INCOME, CONST.formattedType)
      case CONST.OUTCOME: return prop(CONST.OUTCOME, CONST.formattedType)
      case CONST.CLIENT_CONTRACT_OUT: return prop(CONST.CLIENT_CONTRACT_OUT, CONST.formattedType) + ' № ' + clientContract
      case CONST.INCOME_TO_CLIENT: return prop(CONST.INCOME_TO_CLIENT, CONST.formattedType) + ' ' + clientName
      case CONST.OUTCOME_FROM_CLIENT: return prop(CONST.OUTCOME_FROM_CLIENT, CONST.formattedType)
      case CONST.INCOME_FROM_AGENT: return prop(CONST.INCOME_FROM_AGENT, CONST.formattedType) + userName
      case CONST.OUTCOME_FOR_SUPPLY_EXPENSE: return prop(CONST.OUTCOME_FOR_SUPPLY_EXPENSE, CONST.formattedType) + ' № ' + supply
      case CONST.SUPPLY_EXPENSE: return 'Доп. расход' + (supplyExpenseId && (' № ' + supplyExpenseId)) + 'на поставку № ' + supply
      default: return null
      }
    }

    return (
      <FinancesTransactionsItem
        key={id}
        id={id}
        setQuery={handleSetTransactionId}
        idName={`№ ${id}`}
        searchObj={searchObj}
        amount={amount}
        currency={currency}
        date={formatDate}
        description={type()}
      >
        <CardDescription it={'Описание'} is={type()} />
        <CardDescription it={'Организация'} is={division} />
        <CardDescription it={'Комментарий'} is={comment} />
      </FinancesTransactionsItem>
    )
  }, financesTransactionsListData)

  return (
    <>
      <DetailBar
        goBack={() => goBack()}
        title={loading ? 'Транзакции по кассе' : cashboxName}
      />
      <Container
        background={'#fff'}
      >
        <InfiniteScroll
          api={API.FINANCES_TRANSACTIONS_LIST}
          actionType={actionTypes.FINANCES_TRANSACTIONS_LIST}
          params={serializers.financesCashierTransactionsListSerializer(searchObj, props)}
          emptyData={<li><NoResults /></li>}
          list={contentList || []}
        />
      </Container>
    </>
  )
}

export default FinancesTransactionsGrid
