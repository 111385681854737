import {compose, withHandlers, withState} from 'recompose'
import MarketsPointsGrid from 'containers/Clients/Grid/Points/Grid'
import withRedirect from 'hocs/withRedirect'
import * as ROUTES from 'constants/routes'
import {path, prop} from 'ramda'
import {appendParamsToUrl} from 'helpers/url'
import {connect} from 'react-redux'
import {withFilter} from 'hocs'

export default compose(
  connect((state) => {
    const marketsPointsList = prop('marketsPointsList', state)
    return {
      marketsPointsList
    }
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.MARKETS_DETAIL_URL,
    withCurrentParams: true
  }),
  withFilter({
    fields: ['responsibleAgent']
  }),
  withHandlers({
    handleSetPointsId: props => (pointId) => {
      props.history.push({
        pathname: path(['location', 'pathname'], props),
        search: appendParamsToUrl({pointId}, path(['history', 'location', 'search'], props))
      })
    }
  }),
  withState('filterIsOpen', 'setFilterIsOpen', false)
)(MarketsPointsGrid)
