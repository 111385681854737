import React, {useEffect, useState} from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import MainBar from 'components/Navigation/TopBar/MainBar'
import Container from 'components/Container/Container'
import {path, prop} from 'ramda'
import {parseParams} from 'helpers/url'
import styled from 'styled-components'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import InfiniteScroll from 'components/InfiniteScroll'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import {marketsSalesOrdersListSerializer} from 'containers/Clients/actions/serializers/marketsSerializer'
import mapIndexed from 'helpers/mapIndexed'
import moment from 'moment'
import * as CONST from 'constants/constants'
import OrdersItem from 'containers/Clients/components/OrdersItem/OrdersItem'
import {TextStatus} from 'components/Status/TextStatus'
import Fab from 'components/Forms/Fab'
import {Plus} from 'react-feather'
import SecondMenu from 'components/Navigation/components/SecondMenu'

const ProductsSearch = styled('div')`
  position: fixed;
  top: 109px;
  left: 0;
  right: 0;
  background: ${({theme}) => theme.nav.primary.background};
  z-index: 100;
  height: 56px;
  padding: 10px 12px;
  border-bottom: 1px solid #e4e4e6;
  transition: height .3s, opacity .3s, padding .3s;
`
const TabsFilters = styled('div')`
  position: fixed;
  height: 49px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e4e8;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 100;
  background: ${({theme}) => theme.nav.primary.background};
  & > *:not(:last-child) {
    border-right: ${({theme}) => theme.border.primary};
  }
`
const CustomButton = styled('div')`
  width: calc(100% / 4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({active}) => active ? 500 : 400};
  background: ${({active}) => active ? '#ffffff9e' : 'inherit'};
  cursor: pointer;
`

const Orders = props => {
  const {
    marketsSalesOrdersList,
    notificationGetNotViewed,
    gpsSender,
    location,
    goDetail,
    goCreate,
    filterActions,
    handleSetReloadUI,
    handleSetStatus
  } = props

  // useState
  const [count, setCount] = useState(null)
  const [realCount, setRealCount] = useState(null)

  // Data
  const marketsSalesOrdersListData = prop('data', marketsSalesOrdersList)
  const gpsSenderData = prop('data', gpsSender)
  const notificationGetNotViewedData = prop('data', notificationGetNotViewed)
  const countersData = gpsSenderData || notificationGetNotViewedData

  // Const
  const checkData = Array.isArray(marketsSalesOrdersListData) ? marketsSalesOrdersListData : []
  const unconfirmedOrdersCount = prop('unconfirmedOrdersCount', countersData)

  const seconds = moment().format('ss')
  const remainderSeconds = seconds % 60

  // Location
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const statusQuery = prop('status', searchObj)

  // useEffect
  useEffect(() => {
    if (remainderSeconds === 0) {
      handleSetReloadUI(Math.random())
    }
  // eslint-disable-next-line
  }, [remainderSeconds])

  useEffect(() => {
    setRealCount(unconfirmedOrdersCount)
  // eslint-disable-next-line
  }, [unconfirmedOrdersCount])

  useEffect(() => {
    if (count && +statusQuery === 5) {
      setRealCount(count)
    }
  // eslint-disable-next-line
  }, [unconfirmedOrdersCount, count])

  // DebounceSearch
  const debounceSearch =
    <ProductsSearch>
      <DebounceSearch
        filterActions={filterActions}
        query={'ordersSearch'}
        placeholder={'Название'}
      />
    </ProductsSearch>

  // TabsFilters
  const tabsFilters =
    <TabsFilters>
      <CustomButton
        active={+statusQuery === 5}
        onClick={() => handleSetStatus(5)}
      >
        {!realCount || realCount === 0
          ? 'Новые'
          : <TextStatus bold status={'inactive'}>{`Новые: ${realCount}`}</TextStatus>}
      </CustomButton>
      <CustomButton
        active={+statusQuery === 1}
        onClick={() => handleSetStatus(1)}
      >
        В работе
      </CustomButton>
      <CustomButton
        active={+statusQuery === 2}
        onClick={() => handleSetStatus(2)}
      >
        В пути
      </CustomButton>
      <CustomButton
        active={!statusQuery}
        onClick={() => handleSetStatus('')}
      >
        Все
      </CustomButton>
    </TabsFilters>

  // Main List
  const mainList = mapIndexed(item => {
    const id = prop('id', item)
    const market = path(['market', 'id'], item)
    const orderNumber = prop('orderNumber', item)
    const totalPrice = prop('totalPrice', item)
    const currency = path(['currency', 'name'], item)
    const createdDate = prop('createdDate', item)
    const status = +prop('status', item)
    const paymentStatus = status === 3 ? 'active' : status === 4 ? 'inactive' : 'pending'
    const deliveryStatus = (status === CONST.ORDER_DELIVERED)
      ? 'active'
      : (status === CONST.ORDER_CANCELED)
        ? 'inactive'
        : 'pending'
    const handleGoDetail = (market, id) => {
      goDetail(market, id)
    }
    const statusText =
      status === 5
        ? 'Новый'
        : status === 1
          ? 'В работе'
          : status === 2
            ? 'В пути'
            : null
    const deliveryManFN = path(['deliveryMan', 'firstName'], item)
    const deliveryManSN = path(['deliveryMan', 'secondName'], item)
    const deliveryMan = +statusQuery === 2 && (deliveryManFN + ' ' + deliveryManSN)

    return (
      <OrdersItem
        key={id}
        orderNumber={orderNumber}
        totalPrice={totalPrice}
        currency={currency}
        paymentStatus={paymentStatus}
        date={createdDate}
        deliveryStatus={deliveryStatus}
        onClick={() => handleGoDetail(market, id)}
        statusText={!statusQuery && statusText}
        deliveryMan={deliveryMan}
      />
    )
  }, checkData)

  // MainContent
  const mainContent =
    <>
      <InfiniteScroll
        api={API.MARKETS_SALES_ORDERS_LIST}
        actionType={actionTypes.MARKETS_SALES_ORDERS_LIST}
        emptyData={<li><NoResults /></li>}
        params={marketsSalesOrdersListSerializer(searchObj, props)}
        getCount={setCount}
        list={mainList || []}
      />
    </>

  // SecondMenuList
  const menusArr = [
    {
      title: 'Создать заказ',
      onClick: goCreate,
      withAlert: true,
      perms: ['add_order']
    }
  ]

  // SecondMenuButton
  const secondMenuButton =
    <Fab
      text={'Дополнительно'}
      withfixed={'true'}
    >
      <Plus size={20} />
    </Fab>

  // SecondMenu
  const secondMenu =
    <SecondMenu
      title={'Дополнительные функции по работе с заказами'}
      menusArr={menusArr}
      button={secondMenuButton}
    />

  // Render
  return (
    <>
      <MainBar
        title={'Заказы'}
        firstBtn={
          <MainMenu />
        }
      />
      {tabsFilters}
      {debounceSearch}
      <Container
        secondHeight={105}
        background={'#fff'}
      >
        {mainContent}
      </Container>
      {secondMenu}
    </>
  )
}

export default Orders
