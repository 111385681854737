import axios, {getPayloadFromError, getPayloadFromSuccess} from 'helpers/axios'
import {sprintf} from 'sprintf-js'
import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import {path, prop} from 'ramda'
import * as serializers from './serializers/financesSerializer'

export const financesCashierListAction = params => {
  const defaultParams = {
    ...params
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.FINANCES_CLIENT_TRANSACTION_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_CLIENT_TRANSACTION_LIST,
      payload
    })
  }
}

export const financesTransitionsListAction = (params, props) => {
  const matchParams = path(['match', 'params'], props)
  const cashboxParam = prop('d', matchParams)
  const defaultParams = {
    ...params,
    cashbox: cashboxParam
  }
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.FINANCES_TRANSACTIONS_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_TRANSACTIONS_LIST,
      payload
    })
  }
}

// Finances Accept-cash
export const deleteClientTransactionAction = id => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(sprintf(API.FINANCES_CLIENT_TRANSACTION_DELETE, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_TRANSACTIONS_LIST,
      payload
    })
  }
}

export const acceptClientTransactionAction = (requestData) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FINANCES_ACCEPT_CLIENT_TRANSACTION, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_ACCEPT_CLIENT_TRANSACTION,
      payload
    })
  }
}

export const updateClientTransactionAction = ({id, ...requestData}) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .put(sprintf(API.FINANCES_CLIENT_TRANSACTION_DETAIL, id), requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_CLIENT_TRANSACTION_CREATE,
      payload
    })
  }
}

// Finances

export const transferCashCreateAction = (requestData) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FINANCES_TRANSFER_CASH_CREATE, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_TRANSFER_CASH_CREATE,
      payload
    })
  }
}

export const financesClientTransactionPreviewAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FINANCES_CLIENT_TRANSACTION_PREVIEW, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_CLIENT_TRANSACTION_PREVIEW,
      payload
    })
  }
}

export const financesClientTransactionCreate = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FINANCES_CLIENT_TRANSACTION_CREATE, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_CLIENT_TRANSACTION_CREATE,
      payload
    })
  }
}

export const transactionConvertAction = (param) => {
  const requestData = serializers.convertSerializer()
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.PAYMENTS_CONVERT, requestData)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.PAYMENTS_CONVERT,
      payload
    })
  }
}

export const financesTransactionsCreateAction = (values) => {
  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .post(API.FINANCES_TRANSACTIONS_LIST, values)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_TRANSACTIONS_CREATE,
      payload
    })
  }
}

export const financesCashierAction = () => {
  const defaultParams = {
    page_size: 1000
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.FINANCES_CASHIERS_LIST, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CASHIERS_LIST,
      payload
    })
  }
}

export const financesDebitCategoryAction = () => {
  const defaultParams = {
    page_size: 1000
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.FINANCES_DEBIT_CATEGORY, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_DEBIT_CATEGORY,
      payload
    })
  }
}

export const financesCreditCategoryAction = () => {
  const defaultParams = {
    page_size: 1000
  }

  return (dispatch, getState) => {
    const payload = axios({dispatch, getState})
      .get(API.FINANCES_CREDIT_CATEGORY, {params: defaultParams})
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FINANCES_CREDIT_CATEGORY,
      payload
    })
  }
}
