import React from 'react'
import styled, {keyframes} from 'styled-components'
import numberFormat from 'helpers/numberFormat'
import PropTypes from 'prop-types'

const animation = props => {
  const width = (100 - props.progress) + '%'
  return keyframes`
    from {
      width: 0;
    }
    to {
      width: ${width};
    }
  `
}
const textAnimation = props => {
  const color = (props.progress >= 30) ? '#fff' : props.color ? props.color : '#2C3A50'
  return keyframes`
    from{
    color: transparent;
  }
  to{
    color: ${color};
  }
  `
}
const Progress = styled('div')`
  position: relative;
  height: 20px;
  width: 100%;
  &:before{
    animation: ${props => animation(props)} 1s ease-out forwards;
    position: absolute;
    content: '';
    height: 100%;
    background-color: ${props => props.color};
    left: 0;
    right: ${props => props.progress}%;
    max-width: 100%;
    border-radius: 4px;
  }
`
const Text = styled('span')`
  padding: 0 5px;
  font-weight: 500;
  position: absolute;
  right: calc(${({textPosition}) => textPosition > 0 ? textPosition : 0}%);
  transform: ${({progress}) => progress >= 30 ? 'translateX(0)' : 'translateX(100%)'};
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.35px;
  animation: ${props => textAnimation(props)} 1s ease-out forwards;
`

const ProgressBar = props => {
  const {
    maxProgress,
    progressText,
    progressSuffix,
    color = '#69768D'
  } = props

  const progressTextValid = +progressText < 0 ? 0 : progressText
  const progress = (progressTextValid * 100) / (+maxProgress === 0 ? 1 : maxProgress)

  const progressValue = (100 - progress)

  return (
    <Progress
      color={color}
      progress={progressValue}
    >
      <Text
        progress={progress}
        textPosition={progressValue}
        color={color}
      >
        {numberFormat(progressText, progressSuffix)}
      </Text>
    </Progress>
  )
}

ProgressBar.propTypes = {
  maxProgress: PropTypes.number,
  progressText: PropTypes.number,
  progressSuffix: PropTypes.string,
  color: PropTypes.string
}

export default ProgressBar
