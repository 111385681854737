import React from 'react'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container'
import mapIndexed from 'helpers/mapIndexed'
import {path, prop} from 'ramda'
import numberFormat from 'helpers/numberFormat'
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll'
import * as API from '../../../constants/api'
import * as actionTypes from '../../../constants/actionTypes'
import NoResults from '../../../components/NoData/NoResults'
import * as serializers from '../actions/serializers/financesSerializer'
import SecondMenu from '../../../components/Navigation/components/SecondMenu'
import * as ROUTES from '../../../constants/routes'
import FinancesTransferCashCreateDialog from '../components/FinancesTransferCashCreateDialog'
import Fab from '../../../components/Forms/Fab'
import {Plus} from 'react-feather'
import {MainBar} from '../../../components/Navigation/TopBar'
import DebounceSearch from '../../../components/Forms/DebounceSearch/DebounceSearch'
import CashboxItem from '../components/CashboxItem'
import {parseParams} from 'helpers/url'
import {TextStatus} from 'components/Status/TextStatus'

const FinancesGrid = (props) => {
  const {
    financesCashierList,
    goDetail,
    filterActions,
    config,
    location,
    transferCashDialog,
    goCreditsCreate,
    goDebitsCreate
  } = props

  // Data
  const financesCashierListData = prop('data', financesCashierList) || []

  // Redirects
  const searchForm = <DebounceSearch filterActions={filterActions} />
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const onRedirect = (id) => {
    goDetail(id)
  }

  // MainList
  const contentList = mapIndexed((item) => {
    const name = prop('name', item)
    const balance = prop('balance', item)
    const currency = path(['currency', 'name'], item)
    const type = prop('type', item)
    const id = prop('id', item)

    return (
      <CashboxItem
        type={type}
        key={id}
        cashbox={name}
        number={<TextStatus bold status={balance < 0 && 'inactive'}>{numberFormat(balance) + ' ' + currency}</TextStatus>}
        status={'none'}
        onClick={() => onRedirect(id)}
      />
    )
  }, financesCashierListData)

  // Second menu list
  const menusArr = [
    {
      title: 'Принять наличные',
      onClick: () => props.history.push({
        pathname: ROUTES.FINANCES_ACCEPT_CASH_URL,
        search: search
      }),
      withAlert: true,
      perms: ['frontend_transactions']
    },
    {
      title: 'Перевод',
      onClick: () => props.history.push({
        pathname: ROUTES.FINANCES_TRANSFER_CASH_CREATE_URL
      }),
      withAlert: true,
      perms: ['frontend_transactions']
    },
    {
      title: 'Приход',
      onClick: () => goDebitsCreate(),
      withAlert: true,
      perms: ['frontend_transactions']
    },
    {
      title: 'Расход',
      onClick: () => goCreditsCreate(),
      withAlert: true,
      perms: ['frontend_transactions']
    }
  ]

  // Render
  return (
    <>
      <MainBar
        title={'Финансы'}
        secondHeight={100}
        secondContent={searchForm}
        firstBtn={
          <MainMenu />
        }
      />
      <Container
        secondHeight={100}
        background={'#fff'}
      >
        <InfiniteScroll
          api={API.FINANCES_CASHIERS_LIST}
          actionType={actionTypes.FINANCES_CASHIERS_LIST}
          emptyData={<li><NoResults /></li>}
          params={serializers.financesCashierListSerializer(searchObj)}
          list={contentList || []}
        />
        <FinancesTransferCashCreateDialog
          config={config}
          transferCashDialog={transferCashDialog}
        />
      </Container>
      <SecondMenu
        title={'Дополнительные функции по работе с кассами'}
        menusArr={menusArr}
        button={
          <Fab
            text={'Дополнительно'}
            withfixed={'true'}
          ><Plus size={20} color={'#fff'} />
          </Fab>
        }
      />
    </>
  )
}

export default FinancesGrid
