import React from 'react'
import Container from 'components/Container'
import {isEmpty, path, prop} from 'ramda'
import styled from 'styled-components'
import CardDescription from 'components/Cards/CardDescription'
import numberFormat from 'helpers/numberFormat'
import mapIndexed from 'helpers/mapIndexed'
import NoResults from 'components/NoData/NoResults'
import {Table} from 'components/Table'
import {DetailBar} from 'components/Navigation/TopBar'
import TileItem from 'components/Grid/Tile/TileItem'
import TilesList from 'components/Cards/Tile/TilesList'
import {TextStatus} from 'components/Status/TextStatus'
import moment from 'moment'
import Loader from 'components/Loader'

const Inner = styled('div')`
  padding: 15px 16px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`

const ChecksDetailGrid = (props) => {
  const {
    posChecksDetail,
    goDebtsDetail,
    goBack,
    config
  } = props

  // Data
  const posChecksDetailData = prop('data', posChecksDetail)
  const configData = prop('data', config)

  // Loading
  const posChecksDetailLoading = prop('loading', posChecksDetail)

  // Main constants
  const defaultCurrency = prop('dEFAULTCURRENCYNAMEFOROPERATIONS', configData)
  const products = prop('products', posChecksDetailData)
  const payments = prop('payments', posChecksDetailData)
  const totalPrice = prop('totalPrice', posChecksDetailData)
  const totalDiscount = prop('totalDiscount', posChecksDetailData)
  const totalNdsPrice = prop('totalNdsPrice', posChecksDetailData)
  const startDate = prop('startDate', posChecksDetailData)
  const endDate = prop('endDate', posChecksDetailData)
  const checkId = prop('invoiceNumber', posChecksDetailData)
  const createdByFN = path(['createdBy', 'firstName'], posChecksDetailData)
  const createdBySN = path(['createdBy', 'secondName'], posChecksDetailData)
  const createdBy = createdByFN + ' ' + createdBySN

  const content =
    <Inner>
      <TilesList
        title={'Описание'}
      >
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата создания'} is={moment(startDate).format('DD MMM YYYY, HH:mm:ss')} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Дата закрытия'} is={moment(endDate).format('DD MMM YYYY, HH:mm:ss')} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Кассир'} is={createdBy} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Скидка'} is={<TextStatus bold status={'primary'}>{numberFormat(totalDiscount, defaultCurrency)}</TextStatus>} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Итого без НДС'} is={<TextStatus bold status={'primary'}>{numberFormat((totalPrice - totalNdsPrice), defaultCurrency)}</TextStatus>} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'НДС'} is={<TextStatus bold status={'primary'}>{numberFormat(totalNdsPrice, defaultCurrency)}</TextStatus>} />
        </TileItem>
        <TileItem
          height={'auto'}
        >
          <CardDescription it={'Итого с НДС'} is={<TextStatus bold status={'primary'}>{numberFormat(totalPrice, defaultCurrency)}</TextStatus>} />
        </TileItem>
      </TilesList>
      <TilesList
        title={'Оплаты'}
      >
        {(payments && !isEmpty(payments))
          ? mapIndexed((item, index) => {
            const amount = prop('amount', item)
            const cashBoxName = path(['cashBox', 'name'], item)
            const debtor = prop('debtor', item)
            const debtorId = prop('id', debtor)
            const currency = debtor ? defaultCurrency : path(['cashBox', 'currency', 'name'], item)
            const debtorName = prop('clientName', debtor)
            const debtorPhone = prop('phone', debtor)
            const name = debtor ? <>{debtorName}<br />{debtorPhone}</> : cashBoxName
            return (
              <TileItem
                onClick={() => goDebtsDetail(debtorId)}
                height={'auto'}
                key={index}
              >
                <CardDescription it={name} is={<TextStatus bold status={'primary'}>{numberFormat(amount, currency)}</TextStatus>} />
              </TileItem>
            )
          }, payments)
          : <TileItem height={'auto'}>Оплат не было</TileItem>}
      </TilesList>
      <TilesList title={'Список товаров'}>
        <TileItem
          height={'auto'}
          styles={{'& > div': {padding: '2px 0'}}}
        >
          <Table>
            <tbody>
              <tr>
                <th colSpan={'2'}>Название</th>
                <th>Кол&nbsp;-&nbsp;во</th>
                <th>Сумма</th>
              </tr>
              {products && mapIndexed((item, index) => {
                const productName = path(['product', 'name'], item)
                const quantity = prop('quantity', item)
                const measurement = path(['product', 'measurement', 'name'], item)
                const totalPriceProduct = prop('totalPrice', item)

                return (
                  <tr key={index}>
                    <td colSpan={'2'}>{productName}</td>
                    <td style={{textAlign: 'center'}}>{numberFormat(quantity, measurement.slice(0, 3))}</td>
                    <td>{numberFormat(totalPriceProduct, defaultCurrency)}</td>
                  </tr>
                )
              }, products)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>Итого:</TextStatus></td>
                <td> </td>
                <td colSpan={'2'}><TextStatus bold status={'primary'}>{numberFormat(totalPrice, defaultCurrency)}</TextStatus></td>
              </tr>
            </tfoot>
          </Table>
        </TileItem>
      </TilesList>
    </Inner>

  // Loader
  const loader = <Loader styles={{marginTop: '20px'}} />

  const mainContent =
    posChecksDetailLoading
      ? loader
      : posChecksDetailData
        ? content
        : <NoResults />

  // Render
  return (
    <>
      <DetailBar
        title={checkId ? ('Чек № ' + checkId) : 'Чек'}
        goBack={() => goBack(checkId)}
      />
      <Container>
        {posChecksDetailLoading ? loader : posChecksDetailData ? mainContent : <NoResults />}
      </Container>
    </>
  )
}

export default ChecksDetailGrid
