import {compose} from 'recompose'
import {withFetchList, withRedirect} from 'hocs'
import CarsOrdersDetailGrid from './Grid'
import * as ROUTES from 'constants/routes'
import * as STATES from 'constants/states'
import {carsOrdersDetailAction} from 'containers/Cars/actions/carsAction'

export default compose(
  withFetchList({
    stateName: STATES.CARS_ORDERS_DETAIL,
    action: carsOrdersDetailAction,
    key: STATES.CARS_ORDERS_DETAIL
  }),
  withRedirect({
    key: 'goBack',
    redirectUrl: ROUTES.CARS_ORDERS_URL,
    withCurrentParams: true
  }),
  withRedirect({
    key: 'goDelete',
    redirectUrl: ROUTES.CARS_ORDERS_CANCEL_URL,
    withCurrentParams: true
  })
)(CarsOrdersDetailGrid)
