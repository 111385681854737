import React from 'react'
import {path, prop} from 'ramda'
import MainMenu from 'components/Navigation/components/MainMenu'
import Container from 'components/Container/Container'
import MainBar from 'components/Navigation/TopBar/MainBar'
import styled from 'styled-components'
import Loader from 'components/Loader'
import TilesList from 'components/Cards/Tile/TilesList'
import TileItem from 'components/Cards/Tile/TileItem'
import PhotoWithStatus from 'components/PhotoWithStatus'
import Form from 'components/Forms/Form'
import PasswordFormFields from 'containers/Profile/Grid/Forms/PasswordFormFields'
import Label from 'components/Forms/Label'
import Button from 'components/Forms/Button'
import {LogOut} from 'react-feather'
import {appVersion} from 'constants/versions'

const Wrap = styled('div')`
  padding: 14px 16px;
  display: flex;
  flex-flow: column nowrap;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
  h2{
    margin-bottom: 0;
  }
`
const descriptionStyles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  }
}
const RightBar = styled('div')`
  margin-left: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 50px);
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
  p{
    color: ${({theme}) => theme.text.secondary};
  }
`
const LogoutBtn = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    stroke-width: 3px;
  }
`

const ProfileGrid = props => {
  const {
    customStore,
    userUpdatePassword,
    onRefreshPage,
    onLogout
  } = props

  // InitialValues
  const initialValues = {}

  // Data
  const customStoreData = prop('data', customStore)
  const customStoreLoading = prop('loading', customStore)
  const userUpdatePasswordLoading = prop('loading', userUpdatePassword)

  // Const
  const photoSrc = path(['photo', 'file'], customStoreData)
  const isActive = prop('isActive', customStoreData)
  const fName = prop('firstName', customStoreData)
  const lName = prop('secondName', customStoreData)
  const fullName = fName + ' ' + lName
  const positionName = path(['position', 'name'], customStoreData)

  // Description
  const description =
    <TilesList>
      <TileItem
        styles={descriptionStyles}
      >
        <PhotoWithStatus
          status={isActive ? 'active' : 'inactive'}
          size={'62px'}
          statusSize={'13px'}
          imgSrc={photoSrc}
          type={'user'}
        />
        <RightBar>
          <h3>{fullName}</h3>
          <p>{positionName}</p>
        </RightBar>
      </TileItem>
    </TilesList>

  // Submit
  const handleSubmit = values => {
    userUpdatePassword.onSubmit({
      currentPassword: prop('currentPassword', values),
      newPassword: prop('password', values)
    })
  }

  // Password
  const password =
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <PasswordFormFields
        userUpdatePasswordLoading={userUpdatePasswordLoading}
      />
    </Form>

  // App version
  const version =
    <TilesList>
      <TileItem>
        <Label>Версия приложения {appVersion}</Label>
        <Button
          text={'Обновить'}
          onClick={onRefreshPage}
          fullWidth
        />
      </TileItem>
    </TilesList>

  // MainContent
  const mainContent =
    <Wrap>
      {description}
      {password}
      {version}
    </Wrap>

  // Logout
  const logoutBtn =
    <LogoutBtn onClick={onLogout}>
      <LogOut size={18} color={'#e66767'} />
    </LogoutBtn>

  // Render
  return (
    <>
      <MainBar
        title={'Профиль'}
        firstBtn={<MainMenu />}
        lastBtn={logoutBtn}
      />
      <Container>
        {customStoreLoading ? <Loader /> : mainContent}
      </Container>
    </>
  )
}

export default ProfileGrid
