import React from 'react'
import styled from 'styled-components'
import {path, prop} from 'ramda'
import * as API from 'constants/api'
import {CreateBar} from 'components/Navigation/TopBar'
import Container from 'components/Container'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import * as actionTypes from 'constants/actionTypes'
import NoResults from 'components/NoData/NoResults'
import mapIndexed from 'helpers/mapIndexed'
import DebounceSearch from 'components/Forms/DebounceSearch/DebounceSearch'
import BottomBar from 'components/Navigation/BottomBar'
import Button from 'components/Forms/Button'
import CarsItem from 'containers/Cars/components/CarsItem/CarsItem'
import * as serializers from 'containers/Cars/actions/serializers/carsSerializer'

const Wrap = styled('div')`
  padding: 64px 0 64px;
`

const FixedSearch = styled('div')`
    position: fixed;
    top: 60px;
    left: 14px;
    padding: 10px 0;
    right: 14px;
    background: #fff;
    z-index: 100;
    opacity: 0.96
`

const CarsPreview = props => {
  const {
    carsList,
    filterActions,
    setIsCreate,
    searchObj,
    goBack,
    goDetail
  } = props

  // Data
  const carsListData = prop('data', carsList)

  // Func
  const handleGoDetail = (id) => {
    goDetail(id)
  }

  // MainList
  const list = carsListData && mapIndexed((item) => {
    const id = prop('id', item)
    const carBrand = path(['carBrand', 'name'], item)
    const carBrandParent = path(['carBrand', 'parent', 'name'], item)
    const number = prop('number', item)
    const name = carBrandParent ? (carBrandParent + ' ' + carBrand) : carBrand

    return (
      <CarsItem
        key={id}
        carBrand={name}
        number={number}
        status={'none'}
        onClick={() => handleGoDetail(id)}
      />
    )
  }, carsListData)

  return (
    <>
      <CreateBar
        title={'Регистрация авто'}
        goExit={() => goBack()}
      />
      <Container
        background={'#fff'}
      >
        <FixedSearch>
          <DebounceSearch
            filterActions={filterActions}
            placeholder={'Название'}
            query={'carsSearch'}
          />
        </FixedSearch>
        <Wrap>
          <InfiniteScroll
            api={API.CARS_LIST}
            actionType={actionTypes.CARS_LIST}
            emptyData={<li><NoResults /></li>}
            params={serializers.carsListSerializer(searchObj)}
            list={list || []}
          />
          <BottomBar>
            <Button
              text={'Далее'}
              fullWidth
              onClick={() => setIsCreate(false)}
            />
          </BottomBar>
        </Wrap>
      </Container>
    </>
  )
}

export default CarsPreview
