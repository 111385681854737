import {compose, withHandlers} from 'recompose'
import Grid from 'containers/Profile/Grid'
import {connect} from 'react-redux'
import {prop} from 'ramda'
import {withCreate} from 'hocs'
import * as STATES from 'constants/states'
import {userUpdatePasswordAction} from 'containers/Profile/actions/profileActions'
import * as ROUTES from 'constants/routes'
import {customStoreClearAction} from 'containers/SignIn/actions/signInAction'

export default compose(
  connect((state) => {
    const customStore = prop('customStore', state)
    return {
      customStore
    }
  }, {
    customStoreClearAction
  }),
  withCreate({
    stateName: STATES.USER_UPDATE_PASSWORD,
    action: userUpdatePasswordAction,
    key: STATES.USER_UPDATE_PASSWORD,
    successMessage: 'Пароль успешно изменён'
  }),
  withHandlers({
    onRefreshPage: () => () => {
      caches.keys().then(items => items.forEach((name) => caches.delete(name)))
      window.location.reload()
    },
    onLogout: props => () => {
      props.customStoreClearAction()
      if (typeof window.Android !== 'undefined') {
        window.Android.logOut()
      }
      localStorage.clear()
      props.history.replace(ROUTES.SIGN_IN_PATH)
    }
  })
)(Grid)
